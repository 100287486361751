// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import { IParamsHomeSafetyAssessment, IPayloadEditAnswer } from '~/utils/interface/homeSafetyAssessment';
import { EDIT_ANSWER, GET_HOME_SAFETY_ASSESSMENT } from '~/utils/constants/actionType';
import homeSafetyAssessmentApi from '~/apis/homeSafetyAssessment';

export const getListHomeSafetyAssessment = createAsyncThunk(
  GET_HOME_SAFETY_ASSESSMENT,
  async (params: IParamsHomeSafetyAssessment, { rejectWithValue }) => {
    try {
      const res = await homeSafetyAssessmentApi.getListHomeSafetyAssessment(params);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editAnswer = createAsyncThunk(EDIT_ANSWER, async (payload: IPayloadEditAnswer, { rejectWithValue }) => {
  try {
    const res = await homeSafetyAssessmentApi.editAnswer(payload);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
