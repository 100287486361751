// Libs
import classNames from 'classnames/bind';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TFunction } from 'i18next';
import { useParams } from 'react-router-dom';

// Components, Layouts, Pages
import {
  BaseButton,
  BaseDatePicker,
  BaseInput,
  BaseSelect,
  BaseTextarea,
  Loading,
  Modal,
  ModalUnderDevelopment,
} from '~/components';

// Others
import { EMPTY_STRING, OPTION_STATUS_EMPLOYMENT } from '~/utils/constants/common';
import {
  IBackgroundReport,
  IFormAddEmploymentScreening,
  IPayloadUpdateEmploymentScreening,
} from '~/utils/interface/backgroundReports';
import { ButtonTypeEnum } from '~/utils/enum';
import { createBackgroundReport, editBackgroundReport } from '~/thunks/backgroundReport/backgroundReportThunk';
import { useAppDispatch } from '~/redux/hooks';
import { removeEmptyObjects } from '~/utils/helper';

// Styles, images, icons
import styles from './FormAddEmploymentScreeningModal.module.scss';

type Props = {
  employment?: IBackgroundReport | null;
  isOpen: boolean;
  onClose: () => void;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup.object().shape({
    status: yup.string().optional(),
    completedDate: yup.string().trim().required(t('common_required_field')),
    backgroundCheck: yup.string().trim().required(t('common_required_field')),
    notes: yup.string().optional().nullable(),
  });
};

const defaultValues: IFormAddEmploymentScreening = {
  completedDate: EMPTY_STRING,
  backgroundCheck: EMPTY_STRING,
  status: EMPTY_STRING,
};

const FormAddEmploymentScreeningModal = (props: Props) => {
  //#region Destructuring Props
  const { employment, isOpen, onClose } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const { requestApplicantId, caregiverId } = useParams();
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<IFormAddEmploymentScreening>({
    resolver: yupResolver(schema(t)),
    defaultValues: defaultValues,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isDevelopment, setIsDevelopment] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!employment) return;

    reset({ ...employment });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleClose = () => {
    reset(defaultValues);
    onClose();
  };

  const handleSubmitForm = (data: IFormAddEmploymentScreening) => {
    if (employment?.id) {
      handleEditEmployment(data);
    } else {
      handleCreateEmployment(data);
    }
  };

  const handleEditEmployment = (data: IFormAddEmploymentScreening) => {
    setIsLoading(true);

    const newData = {
      completedDate: data.completedDate || EMPTY_STRING,
      backgroundCheck: data.backgroundCheck || EMPTY_STRING,
      status: data.status || EMPTY_STRING,
      notes: data.notes || EMPTY_STRING,
    };

    const payload: IPayloadUpdateEmploymentScreening = {
      backgroundReportId: employment?.id || EMPTY_STRING,
      body: newData,
    };

    dispatch(editBackgroundReport(payload))
      .unwrap()
      .then((res) => {
        handleClose();
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCreateEmployment = (data: IFormAddEmploymentScreening) => {
    setIsLoading(true);

    const newData: IFormAddEmploymentScreening = {
      ...data,
      ...(requestApplicantId && { applicantId: requestApplicantId }),
      ...(caregiverId && { accountId: caregiverId }),
    };

    const dataClean = removeEmptyObjects(newData) as IFormAddEmploymentScreening;

    dispatch(createBackgroundReport(dataClean))
      .unwrap()
      .then((res) => {
        handleClose();
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCloseDevelopment = () => {
    setIsDevelopment(false);
  };
  //#endregion Handle Function

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={employment?.id ? t('form_employment_screening_edit_title') : t('form_employment_screening_title')}
    >
      <form
        id='formAddEmploymentScreeningModalComponent'
        className={cx('formAddEmploymentScreeningModalComponent')}
        onSubmit={handleSubmit(handleSubmitForm)}
      >
        <div className={cx('contentModal')}>
          <div className={cx('twoCol')}>
            <Controller
              name='completedDate'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseDatePicker
                  name={'completedDate'}
                  label={t('form_employment_screening_completed_date_title')}
                  placeholderText={t('common_placeholder_select')}
                  onDateSelected={onChange}
                  value={value}
                  errorMessage={errors.completedDate?.message}
                  required
                />
              )}
            />
            {employment?.id && (
              <Controller
                name='status'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseSelect
                    value={value}
                    options={OPTION_STATUS_EMPLOYMENT ?? []}
                    label={t('form_employment_screening_status_title')}
                    placeholder={t('common_select_placeholder')}
                    onChange={({ value }) => {
                      onChange(value);
                    }}
                  />
                )}
              />
            )}
          </div>

          <Controller
            name='backgroundCheck'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='backgroundCheck'
                label={t('form_employment_screening_background_check_title')}
                value={value || EMPTY_STRING}
                onChange={onChange}
                messageError={errors.backgroundCheck?.message}
                required
              />
            )}
          />

          <Controller
            name='notes'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseTextarea
                id='notes'
                label={t('form_employment_screening_note_title')}
                value={value || EMPTY_STRING}
                onChange={onChange}
                height={100}
              />
            )}
          />
        </div>

        <div className={cx('footerButton')}>
          <BaseButton type='button' text={t('common_cancel_label')} width={65} onClick={handleClose} />

          <BaseButton
            type='submit'
            text={employment?.id ? t('common_update_label') : t('common_save_label')}
            typeStyle={ButtonTypeEnum.PRIMARY}
            width={80}
            disabled={employment?.id ? !isDirty : false}
          />
        </div>
      </form>

      {isLoading && <Loading />}

      {isDevelopment && <ModalUnderDevelopment onClose={handleCloseDevelopment} />}
    </Modal>
  );
};

export default FormAddEmploymentScreeningModal;
