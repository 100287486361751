// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useSearchParams } from 'react-router-dom';
import { useState, useEffect, useContext, useMemo } from 'react';
// Components, Layouts, Pages
import {
  BasePagination,
  BaseTable,
  ConfirmModal,
  InputSearch,
  ModalAssignToContact,
  ModalUnderDevelopment,
  ThreeDotTableOptions,
} from '~/components';
// Others
import useDebounce from '~/utils/customHook';
import { TimeFormatEnum, TranslationEnum, TypeFilesDocumentEnum } from '~/utils/enum';
import { BaseResponse, ColumnTableType, IListDataResponse } from '~/utils/interface/common';
import { IListDocument, IListQueryParamsDocument } from '~/utils/interface/file';
import { convertDateToFormatTime, formatMimeType, formatSizeFile } from '~/utils/helper';
import {
  A_ELEMENT,
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  OBJECT,
} from '~/utils/constants/common';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { deleteDocument, downloadDocument, getListDocuments } from '~/thunks/file/fileThunk';
import { LoadingData } from '~/context';
import { fileActions, selectIsRefreshFileList } from '~/thunks/file/fileSlice';
// Styles, images, icons
import styles from './DocumentsTab.module.scss';
import { icons } from '~/assets';

type Props = {
  clientId: string;
};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction<TranslationEnum.TRANSLATION>,
  onDelete: (record: IListDocument) => void,
  onDownload: (record: IListDocument) => void,
  onAssigneeToContact: (record: IListDocument) => void
): ColumnTableType<IListDocument>[] => {
  return [
    {
      key: 'name',
      title: t('document_tab_crm_table_name_label'),
      dataIndex: 'name',
      render(value, record, index) {
        return <p>{record?.name || EMPTY_STRING}</p>;
      },
      width: '30%',
    },
    {
      key: 'updatedAt',
      title: t('document_tab_crm_table_updated_time_label'),
      dataIndex: 'updatedAt',
      render(value, record, index) {
        return (
          <>
            {record.updatedAt
              ? convertDateToFormatTime(record.updatedAt, TimeFormatEnum.MM_DD_YYYY_HH_AM_PM)
              : EMPTY_STRING}
          </>
        );
      },
    },
    {
      key: 'expirationDate',
      title: t('document_tab_crm_table_expiration_date_label'),
      dataIndex: 'expirationDate',
      render(value, record, index) {
        return (
          <>
            {record.expirationDate
              ? convertDateToFormatTime(record.expirationDate, TimeFormatEnum.MM_DD_YYYY)
              : EMPTY_STRING}
          </>
        );
      },
    },
    {
      key: 'type',
      title: t('document_tab_crm_table_type_label'),
      dataIndex: 'mimetype',
      render(value, record, index) {
        return <>{formatMimeType(record?.mimetype)}</>;
      },
    },
    {
      key: 'size',
      title: t('document_tab_crm_table_size_label'),
      dataIndex: 'size',
      render(value, record, index) {
        return <>{formatSizeFile(Number(record?.size))}</>;
      },
    },
    {
      key: 'action',
      title: t('document_tab_crm_table_action_label'),
      render(value, record, index) {
        return (
          <div className={cx('btnAction')}>
            <button onClick={() => onDownload(record)}>
              <img src={icons.commonIconDownload} alt={t('common_img_text_alt')} />
            </button>
            <ThreeDotTableOptions
              data={record}
              permissions={{
                isView: false,
                isEdit: false,
                isAssignToContact: true,
              }}
              onAssigneeToContact={() => onAssigneeToContact(record)}
              onDelete={() => onDelete(record)}
            />
          </div>
        );
      },
      width: 50,
    },
  ];
};

const DocumentTab = (props: Props) => {
  //#region Destructuring Props
  const { clientId } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);
  //#endregion Declare Hook

  //#region Selector
  const isRefreshListDocument = useAppSelector(selectIsRefreshFileList);
  //#endregion Selector

  //#region Declare State
  const [isDevelopment, setIsDevelopment] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(() => Number(params?.page ?? DEFAULT_CURRENT_PAGE), [params?.page]);
  const [listDocumentResp, setListDocumentResp] = useState<BaseResponse<IListDataResponse<IListDocument[]>>>();
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);
  const [documentTableRecord, setDocumentTableRecord] = useState<IListDocument>({ id: EMPTY_STRING });
  const textSearchParams = useMemo<string>(() => String(params?.textSearch || EMPTY_STRING), [params?.textSearch]);
  const [searchKey, setSearchKey] = useState<string>(textSearchParams || EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey.trim() || EMPTY_STRING, DEFAULT_DELAY_TIME);
  const [isShowModalAssignToContact, setIsShowModalAssignToContact] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!textSearchParams) setSearchKey(EMPTY_STRING);
  }, [textSearchParams]);

  useEffect(() => {
    if (!clientId) return;

    const { tab, ...restParams } = params;

    const newParams = {
      ...restParams,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      businessId: clientId,
      businessTypes: [TypeFilesDocumentEnum.CLIENT_DOCUMENT],
    };

    loading?.show();
    handleGetListDocument(newParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!listDocumentResp?.data.pagination) return;

    if (debouncedSearchKey) {
      setSearchParams({
        ...params,
        page: DEFAULT_CURRENT_PAGE.toString(),
        limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
        textSearch: debouncedSearchKey,
      });
    } else {
      const { textSearch, ...rest } = params;
      setSearchParams(rest);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);

  useEffect(() => {
    if (!isRefreshListDocument || !clientId) return;

    const { status, tab, ...restParams } = params;

    const newParams = {
      ...restParams,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      ...(debouncedSearchKey ? { textSearch: debouncedSearchKey } : {}),
      businessId: clientId,
      businessTypes: [TypeFilesDocumentEnum.CLIENT_DOCUMENT],
    };

    handleGetListDocument(newParams);
    dispatch(fileActions.setRefreshList(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshListDocument]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleDelete = () => {
    if (documentTableRecord) {
      return dispatch(deleteDocument(documentTableRecord?.id))
        .unwrap()
        .then((res) => {
          dispatch(fileActions.setRefreshList(true));
          setIsOpenDelete(false);
        })
        .catch((error) => {})
        .finally(() => loading?.hide());
    }
  };

  const handleDownLoad = (fileInfo: IListDocument) => {
    loading?.show();

    dispatch(downloadDocument(fileInfo.id))
      .unwrap()
      .then((response) => {
        const data: ArrayBuffer | undefined = response.data;
        const fileExtension = fileInfo.mimetype;

        if (data && typeof data === OBJECT && Object.keys(data).length > DEFAULT_NUMBER_ZERO) {
          const array: number[] = Object.values(data);
          const uint8Array: Uint8Array = new Uint8Array(array);
          const blob: Blob = new Blob([uint8Array], { type: fileExtension });

          const url: string = window.URL.createObjectURL(blob);
          const link: HTMLAnchorElement = document.createElement(A_ELEMENT);
          link.href = url;
          link.download = fileInfo.name || EMPTY_STRING;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          window.URL.revokeObjectURL(url);
        }

        dispatch(fileActions.setRefreshList(true));
        setIsOpenDelete(false);
      })
      .catch((error) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const handleOpenDevelopment = () => {
    setIsDevelopment(!isDevelopment);
  };

  const handlePaginationChange = (page: number) => {
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleGetListDocument = (params: IListQueryParamsDocument) => {
    dispatch(getListDocuments(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        setListDocumentResp(res);
      })
      .catch((err) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const handleShowDelete = (record?: IListDocument) => {
    if (record) {
      setDocumentTableRecord(record);
    }
    setIsOpenDelete(!isOpenDelete);
  };

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value);
  };

  const handleAssignToContact = (record: IListDocument) => {
    setDocumentTableRecord(record);
    setIsShowModalAssignToContact(true);
  };

  const handleCloseModalAssignToContact = () => {
    setDocumentTableRecord({ id: EMPTY_STRING });
    setIsShowModalAssignToContact(false);
  };
  //#endregion Handle Function

  return (
    <div id='documentsTabComponent' className={cx('documentsTabComponent')}>
      <div className={cx('searchContainer')}>
        <InputSearch
          height={36}
          width={204}
          placeholder={t('common_placeholder_search_by_name')}
          onChange={handleChangeSearch}
          value={searchKey || EMPTY_STRING}
        />
      </div>
      <div className={cx('tableContainer')}>
        <BaseTable
          columns={columns(t, handleShowDelete, handleDownLoad, handleAssignToContact)}
          dataSource={listDocumentResp?.data.responses || []}
        />
      </div>
      <div className={cx('paginationTable')}>
        <BasePagination
          onChange={handlePaginationChange}
          defaultCurrentPage={pageSelected}
          totalItems={listDocumentResp?.data.pagination.totalItems}
          totalPages={listDocumentResp?.data.pagination.totalPages}
        />
      </div>

      {isShowModalAssignToContact && (
        <ModalAssignToContact
          isOpen={isShowModalAssignToContact}
          fileId={documentTableRecord.id}
          onClose={handleCloseModalAssignToContact}
        />
      )}

      {isDevelopment && <ModalUnderDevelopment onClose={handleOpenDevelopment} />}

      <ConfirmModal
        isOpen={isOpenDelete}
        title={t('common_confirm_delete_title', {
          name: documentTableRecord?.name,
        })}
        description={t('modal_confirm_delete_description')}
        titleAction={t('common_delete_label')}
        onCancel={handleShowDelete}
        onAction={handleDelete}
        type='danger'
      />
    </div>
  );
};

export default DocumentTab;
