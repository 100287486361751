// Libs
import { TFunction } from 'i18next';
import * as yup from 'yup';
import { DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
// Others
import {
  ICertificationsCredentials,
  IDemographics,
  IFormSkill,
  IMatchCriteria,
} from '~/utils/interface/caregivers/skill';

export const skillSchema = (t: TFunction): yup.ObjectSchema<IFormSkill> => {
  return yup
    .object()
    .shape({
      businessId: yup.string().trim().optional(),
      businessType: yup.string().trim().optional(),
      matchCriteriaSkill: matchCriteriaSchema(t),
      demographicsSkill: demographicsSchema(t),
      emergencyContactsSkill: emergencyContactsSchema(t),
      certificationsSkill: certificationsCredentialsSchema(t),
      educationAndTrainingSkill: educationTrainingSchema(t),
      employmentHistorySkill: employmentHistorySchema(t),
      professionalReferencesSkill: professionalReferencesSchema(t),
      // disclaimersSignatures: disclaimersSignaturesSchema(t),
      humanResourcesRecordsSkill: humanResourcesRecordsSchema(t),
    })
    .required();
};

export const initialCertificationsCredentials: ICertificationsCredentials[] = [
  {
    type: 'Abuse Policy',
    active: false,
    alertCaregiver: false,
    alertDate: null,
    expirationDate: null,
    notes: EMPTY_STRING,
  },
  {
    type: 'Background',
    active: false,
    alertCaregiver: false,
    alertDate: null,
    expirationDate: null,
    notes: EMPTY_STRING,
  },
  {
    type: 'Car Insurance',
    active: false,
    alertCaregiver: false,
    alertDate: null,
    expirationDate: null,
    notes: EMPTY_STRING,
  },
  {
    type: 'Driver’s License',
    active: false,
    alertCaregiver: false,
    alertDate: null,
    expirationDate: null,
    notes: EMPTY_STRING,
  },
  {
    type: 'Health Assessment',
    active: false,
    alertCaregiver: false,
    alertDate: null,
    expirationDate: null,
    notes: EMPTY_STRING,
  },
  {
    type: 'MVR',
    active: false,
    alertCaregiver: false,
    alertDate: null,
    expirationDate: null,
    notes: EMPTY_STRING,
  },
  {
    type: 'OIG, CNA, Sex Offender Checks',
    active: false,
    alertCaregiver: false,
    alertDate: null,
    expirationDate: null,
    notes: EMPTY_STRING,
  },
  {
    type: 'Online Training',
    active: false,
    alertCaregiver: false,
    alertDate: null,
    expirationDate: null,
    notes: EMPTY_STRING,
  },
  {
    type: 'Performance Evaluation',
    active: false,
    alertCaregiver: false,
    alertDate: null,
    expirationDate: null,
    notes: EMPTY_STRING,
  },
];

export const initialValues: IFormSkill = {
  matchCriteriaSkill: {
    general: [],
    maxClientWeight: DEFAULT_NUMBER_ZERO,
    otherMisc: [],
    pets: [],
    transfers: [],
  },
  demographicsSkill: {
    dob: null,
    gender: EMPTY_STRING,
    height: DEFAULT_NUMBER_ZERO,
    languages: [],
    weight: DEFAULT_NUMBER_ZERO,
  },
  emergencyContactsSkill: [
    {
      name: EMPTY_STRING,
      notes: EMPTY_STRING,
      phoneNumber: EMPTY_STRING,
      relationship: EMPTY_STRING,
      countryCode: EMPTY_STRING,
    },
  ],
  certificationsSkill: initialCertificationsCredentials,
  educationAndTrainingSkill: {
    degreeReceived: EMPTY_STRING,
    isCollege: false,
    isHighSchool: false,
    school: EMPTY_STRING,
  },
  employmentHistorySkill: [
    {
      address: EMPTY_STRING,
      city: EMPTY_STRING,
      dateEmployed: null,
      employer: EMPTY_STRING,
      phoneNumber: EMPTY_STRING,
      postalCode: EMPTY_STRING,
      secondaryAddress: EMPTY_STRING,
      state: EMPTY_STRING,
      supervisor: EMPTY_STRING,
      to: null,
      countryCode: EMPTY_STRING,
    },
  ],
  professionalReferencesSkill: [
    {
      name: EMPTY_STRING,
      phoneNumber: EMPTY_STRING,
      countryCode: EMPTY_STRING,
    },
  ],
  // disclaimersSignatures: {},
  humanResourcesRecordsSkill: {
    hireDate: null,
    hoursWantedWeekly: DEFAULT_NUMBER_ZERO,
    notes: EMPTY_STRING,
    terminationDate: null,
  },
};

const matchCriteriaSchema = (t: TFunction): yup.ObjectSchema<IMatchCriteria> => {
  return yup
    .object()
    .shape({
      general: yup.array().of(yup.string().trim().required()).nullable().optional(),
      transfers: yup.array().of(yup.string().trim().required()).nullable().optional(),
      pets: yup.array().of(yup.string().trim().required()).nullable().optional(),
      otherMisc: yup.array().of(yup.string().trim().required()).nullable().optional(),
      maxClientWeight: yup.number().nullable().optional(),
    })
    .required();
};

const demographicsSchema = (t: TFunction): yup.ObjectSchema<IDemographics> => {
  return yup
    .object()
    .shape({
      dob: yup.string().trim().nullable().optional(),
      languages: yup.array().of(yup.string().trim().required()).nullable().optional(),
      gender: yup.string().trim().nullable().optional(),
      height: yup.number().nullable().optional(),
      weight: yup.number().nullable().optional(),
    })
    .required();
};

const emergencyContactsSchema = (t: TFunction) => {
  return yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().trim().nullable().optional(),
        relationship: yup.string().trim().nullable().optional(),
        phoneNumber: yup.string().trim().trim().nullable().optional(),
        notes: yup.string().trim().nullable().optional(),
      })
    )
    .required();
};

const certificationsCredentialsSchema = (t: TFunction) => {
  return yup
    .array()
    .of(
      yup.object().shape({
        type: yup.string().trim().nullable().optional(),
        expirationDate: yup.string().trim().nullable().optional(),
        alertDate: yup.string().trim().nullable().optional(),
        notes: yup.string().trim().nullable().optional(),
        isActive: yup.boolean().optional(),
        isAlertCaregiver: yup.boolean().optional(),
      })
    )
    .required();
};

const educationTrainingSchema = (t: TFunction) => {
  return yup
    .object()
    .shape({
      isHighSchool: yup.boolean().optional(),
      isCollege: yup.boolean().optional(),
      school: yup.string().trim().nullable().optional(),
      degreeReceived: yup.string().trim().nullable().optional(),
    })
    .required();
};

const employmentHistorySchema = (t: TFunction) => {
  return yup
    .array()
    .of(
      yup.object().shape({
        employer: yup.string().trim().nullable().optional(),
        supervisor: yup.string().trim().nullable().optional(),
        phoneNumber: yup.string().trim().nullable().optional(),
        address: yup.string().trim().nullable().optional(),
        secondaryAddress: yup.string().trim().nullable().optional(),
        city: yup.string().trim().nullable().optional(),
        state: yup.string().trim().nullable().optional(),
        postalCode: yup.string().trim().nullable().optional(),
        dateEmployed: yup.string().trim().nullable().optional(),
        to: yup.string().trim().nullable().optional(),
      })
    )
    .required();
};

const professionalReferencesSchema = (t: TFunction) => {
  return yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().trim().nullable().optional(),
        countryCode: yup.string().trim().nullable().optional(),
        phoneNumber: yup.string().trim().nullable().optional(),
      })
    )
    .required();
};

// const disclaimersSignaturesSchema = (t: TFunction) => {
//   return yup.mixed().required();
// };

const humanResourcesRecordsSchema = (t: TFunction) => {
  return yup
    .object()
    .shape({
      hireDate: yup.string().trim().nullable().optional(),
      terminationDate: yup.string().trim().nullable().optional(),
      hoursWantedWeekly: yup.number().nullable().optional(),
      notes: yup.string().trim().nullable().optional(),
    })
    .required();
};
