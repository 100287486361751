// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { TFunction } from 'i18next';
import moment from 'moment';

// Components, Layouts, Pages
import { BaseButton, BaseTable, ImageCircle, StatusLabel } from '~/components';

// Others
import { DEFAULT_CURRENCY, EMPTY_STRING, NAVIGATE_BACK } from '~/utils/constants/common';
import { ButtonTypeEnum, CurrencyEnum, DateFormatEnum, TimeFormatEnum, TranslationEnum } from '~/utils/enum';
import {
  convertDateToFormatTime,
  convertEnumToString,
  formatCurrency,
  generateInvoiceId,
  getUserName,
} from '~/utils/helper';
import { ColumnTableType } from '~/utils/interface/common';
import { IInvoiceDetail, IShiftInvoice } from '~/utils/interface/invoice';
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { getDetailInvoice } from '~/thunks/invoice/invoiceThunk';

// Styles, images, icons
import styles from './InvoiceDetail.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const columns = (t: TFunction<TranslationEnum.TRANSLATION>): ColumnTableType<IShiftInvoice>[] => {
  return [
    {
      key: 'date',
      title: t('invoice_detail_date_title'),
      render: (_, record) => {
        return (
          <>{record.startDate ? convertDateToFormatTime(record.startDate, TimeFormatEnum.MM_DD_YYYY) : EMPTY_STRING}</>
        );
      },
    },
    {
      key: 'caregiver',
      title: t('invoice_detail_caregiver_title'),
      render: (_, record) => {
        return !record.caregiver?.firstName && !record.caregiver?.lastName ? (
          <>{EMPTY_STRING}</>
        ) : (
          <>
            <ImageCircle
              imageUrl={record?.caregiver?.avatarUrl}
              firstName={record?.caregiver?.firstName}
              lastName={record?.caregiver?.lastName}
              width={24}
              height={24}
              fontSize={10}
              margin={'0 6px 0 0'}
            />
            <span>{getUserName(record?.caregiver?.firstName, record?.caregiver?.lastName)}</span>
          </>
        );
      },
    },
    {
      key: 'type',
      title: t('invoice_detail_type_title'),
      render: (_, record) => {
        return <>{record.rate?.rateType ? convertEnumToString(record.rate?.rateType) : EMPTY_STRING}</>;
      },
    },
    {
      key: 'hours',
      title: t('invoice_detail_hours_title'),
      render: (_, record) => {
        return <>{record.hours || EMPTY_STRING}</>;
      },
    },
    {
      key: 'rate',
      title: t('invoice_detail_rate_title'),
      render: (_, record) => {
        return <>{record?.rate?.price ? formatCurrency(CurrencyEnum.USD, record?.rate?.price) : DEFAULT_CURRENCY}</>;
      },
    },
    {
      key: 'price',
      title: t('invoice_detail_price_title'),
      render: (_, record) => {
        return <>{record?.totalClient ? formatCurrency(CurrencyEnum.USD, record?.totalClient) : DEFAULT_CURRENCY}</>;
      },
    },
  ];
};

const InvoiceDetail = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const { invoiceId } = useParams();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [invoiceDetail, setInvoiceDetail] = useState<IInvoiceDetail>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!invoiceId) return;
    handleGetDetailInvoice(invoiceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetDetailInvoice = (id: string) => {
    loading?.show();
    dispatch(getDetailInvoice({ id, currentDate: moment().format(DateFormatEnum.YYYY_MM_DD) }))
      .unwrap()
      .then((res) => {
        const { data } = res;
        setInvoiceDetail(data);
      })
      .catch(() => {})
      .finally(() => loading?.hide());
  };

  const handleBack = () => {
    navigate(NAVIGATE_BACK);
  };

  const getBillingPeriod = () => {
    if (invoiceDetail?.startDate && !invoiceDetail?.endDate) {
      return convertDateToFormatTime(invoiceDetail?.startDate, TimeFormatEnum.MM_DD_YYYY);
    }

    if (invoiceDetail?.endDate && !invoiceDetail?.startDate) {
      return convertDateToFormatTime(invoiceDetail?.endDate, TimeFormatEnum.MM_DD_YYYY);
    }

    if (invoiceDetail?.startDate && invoiceDetail?.endDate) {
      return `${convertDateToFormatTime(
        invoiceDetail?.startDate,
        TimeFormatEnum.MM_DD_YYYY
      )} - ${convertDateToFormatTime(invoiceDetail?.endDate, TimeFormatEnum.MM_DD_YYYY)}`;
    }
  };
  //#endregion Handle Function

  return (
    <div id='invoiceDetailTemplate' className={cx('container')}>
      <BaseButton
        onClick={handleBack}
        text={t('common_text_back')}
        typeStyle={ButtonTypeEnum.ONLY_TEXT}
        iconLeft={icons.commonIconPrev}
        height={17}
      />

      <div className={cx('headerToolBar')}>
        <span className={cx('invoiceIdLabel')}>{`${t('invoice_detail_invoice_title')} ${
          invoiceDetail?.id ? generateInvoiceId(+invoiceDetail?.id) : ''
        }`}</span>
      </div>

      <div className={cx('bodyWrap')}>
        <div className={cx('threeCol')}>
          <div className={cx('groupInfo')}>
            <span className={cx('textLabel')}>{t('invoice_detail_client_title')}</span>
            <span className={cx('textValue')}>
              {getUserName(
                invoiceDetail?.client?.firstName,
                invoiceDetail?.client?.middleName,
                invoiceDetail?.client?.lastName
              )}
            </span>
          </div>

          <div className={cx('groupInfo')}>
            <span className={cx('textLabel')}>{t('invoice_detail_billing_period_title')}</span>
            <span className={cx('textValue')}>{getBillingPeriod()}</span>
          </div>

          <div className={cx('groupInfo')}>
            <span className={cx('textLabel')}>{t('invoice_detail_due_date_title')}</span>
            <span className={cx('textValue')}>
              {invoiceDetail?.dueDate
                ? convertDateToFormatTime(invoiceDetail?.dueDate, TimeFormatEnum.MM_DD_YYYY)
                : EMPTY_STRING}
            </span>
          </div>
        </div>

        <div className={cx('threeCol')}>
          <div className={cx('groupInfo')}>
            <span className={cx('textLabel')}>{t('invoice_detail_invoice_amount_title')}</span>
            <span className={cx('textValue')}>
              {invoiceDetail?.total ? formatCurrency(CurrencyEnum.USD, +invoiceDetail?.total) : DEFAULT_CURRENCY}
            </span>
          </div>

          <div className={cx('groupInfo')}>
            <span className={cx('textLabel')}>{t('invoice_detail_create_date_title')}</span>
            <span className={cx('textValue')}>
              {invoiceDetail?.createdAt
                ? convertDateToFormatTime(invoiceDetail?.createdAt, TimeFormatEnum.MM_DD_YYYY)
                : EMPTY_STRING}
            </span>
          </div>

          <div className={cx('groupInfo')}>
            <span className={cx('textLabel')}>{t('invoice_detail_status_title')}</span>
            <span className={cx('textValue')}>
              {invoiceDetail?.status ? (
                <StatusLabel type='text' label={invoiceDetail?.status} height={24} />
              ) : (
                EMPTY_STRING
              )}
            </span>
          </div>
        </div>

        <div className={cx('content')}>
          <div className={cx('tableWrap')}>
            <BaseTable columns={columns(t)} dataSource={invoiceDetail?.shifts ?? []} />
          </div>
        </div>

        <div className={cx('rightContent')}>
          <span className={cx('invoiceIdLabel')}>{`${t('invoice_detail_price_total')} ${
            invoiceDetail?.total ? formatCurrency(CurrencyEnum.USD, +invoiceDetail?.total) : DEFAULT_CURRENCY
          }`}</span>
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetail;
