// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { RootState } from '~/redux/store';
import { createEmailTemplate, editEmailTemplate, getListEmailTemplate } from './emailThunk';

export interface EmailTemplateState {
  isRefreshEmailTemplateList: boolean;
}

const initialState: EmailTemplateState = {
  isRefreshEmailTemplateList: false,
};

const emailTemplateSlice = createSlice({
  name: 'emailTemplateState',
  initialState,
  reducers: {},
  extraReducers(builder) {
    // Get list Email Template
    builder
      .addCase(getListEmailTemplate.pending, (state) => {})
      .addCase(getListEmailTemplate.fulfilled, (state) => {
        state.isRefreshEmailTemplateList = false;
      })
      .addCase(getListEmailTemplate.rejected, (state) => {
        state.isRefreshEmailTemplateList = false;
      });

    // Create Email Template
    builder
      .addCase(createEmailTemplate.pending, (state) => {})
      .addCase(createEmailTemplate.fulfilled, (state) => {
        state.isRefreshEmailTemplateList = true;
      })
      .addCase(createEmailTemplate.rejected, (state) => {});

    // Edit Email Template
    builder
      .addCase(editEmailTemplate.pending, (state) => {})
      .addCase(editEmailTemplate.fulfilled, (state) => {
        state.isRefreshEmailTemplateList = true;
      })
      .addCase(editEmailTemplate.rejected, (state) => {});
  },
});

// Actions
export const emailTemplateActions = emailTemplateSlice.actions;

// Selectors

// Reducer
const emailTemplateReducer = emailTemplateSlice.reducer;
export default emailTemplateReducer;
