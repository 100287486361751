// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TFunction } from 'i18next';
// Components, Layouts, Pages
import {
  BaseButton,
  BaseDrawerModal,
  BaseFilter,
  BasePagination,
  BaseSelect,
  BaseSelectMultiple,
  BaseTable,
  EmployeeDetailModalItem,
  ToolBar,
} from '~/components';
// Others
import { LoadingData } from '~/context';
import {
  AccountRoleCodesEnum,
  ButtonTypeEnum,
  StorageEnum,
  TimeFormatEnum,
  TimelineAlertEnum,
  TranslationEnum,
  TypeCareAlertEnum,
} from '~/utils/enum';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  NAVIGATE_BACK,
  optionFilterTypeCareAlert,
  optionTimeCareAlert,
  POSSESSIVE_SUFFIX,
} from '~/utils/constants/common';
import { useAppDispatch } from '~/redux/hooks';
import { ColumnTableType, IBaseOption, IPaginationResponse } from '~/utils/interface/common';
import { selectIsRefreshCareAlertList } from '~/thunks/careAlert/careAlertSlice';
import { convertDateToFormatTime, getRoutesByRole, getUserName } from '~/utils/helper';
import { mockOptionAlert, mockOptionTimeCareAlert } from '~/mockData';
import {
  ICaregiver,
  IClient,
  IFilterCareAlert,
  IListCareAlert,
  IListQueryParamsCareAlert,
} from '~/utils/interface/careAlert';
import { getListCareAlert } from '~/thunks/careAlert/careAlertThunk';
import {
  adminRoute,
  adminRouteAbsolute,
  caregiverRouteAbsolute,
  staffRoute,
  staffRouteAbsolute,
  superAdminRoute,
  superAdminRouteAbsolute,
} from '~/utils/constants/route';
// Styles, images, icons
import styles from './CareAlertsShowAll.module.scss';
import { icons } from '~/assets';

type Props = {
  role: string;
};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction<TranslationEnum.TRANSLATION>,
  onViewDetailCaregiver: (record: ICaregiver) => void,
  onViewDetailClient: (record: IClient) => void,
  onViewDetailEmployee: (record: IClient) => void
): ColumnTableType<IListCareAlert>[] => {
  return [
    {
      key: 'date',
      title: t('care_alert_date_label'),
      render(value, record, index) {
        return (
          <>{record.createdAt ? convertDateToFormatTime(record.createdAt, TimeFormatEnum.MM_DD_YYYY) : EMPTY_STRING}</>
        );
      },
      width: '20%',
    },
    {
      key: 'time',
      title: t('care_alert_time_label'),
      render(value, record, index) {
        return (
          <>
            {record.createdAt
              ? convertDateToFormatTime(record.createdAt, TimeFormatEnum.HOUR_MINUTE_AM_PM)
              : EMPTY_STRING}
          </>
        );
      },
      width: '20%',
    },
    {
      key: 'alert',
      title: t('care_alert_alert_label'),
      render(value, record, index) {
        switch (record.type) {
          case TypeCareAlertEnum.MISSED_CLOCK_INS:
            return (
              <div>
                <span
                  className={cx('tableLink')}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!record.detail.caregiver) return;
                    onViewDetailCaregiver(record.detail.caregiver);
                  }}
                >
                  {getUserName(record.detail.caregiver?.firstName, record.detail.caregiver?.lastName)}
                </span>

                <span className={cx('contentItem')}>{t('care_alert_missed_clock_in_label')}</span>

                <span
                  className={cx('tableLink')}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!record.detail.client) return;
                    onViewDetailClient(record.detail.client);
                  }}
                >
                  {`${getUserName(record.detail.client?.firstName, record.detail.client?.lastName)}.`}
                </span>
              </div>
            );

          case TypeCareAlertEnum.NOTE_SHIFT:
            return (
              <div className={cx('noteCol')}>
                <div className={cx('contentItem')}>
                  <span
                    className={cx('tableLink')}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!record.detail.updatedBy) return;
                      onViewDetailEmployee(record.detail.updatedBy);
                    }}
                  >
                    {`${getUserName(record.detail.updatedBy?.firstName, record.detail.updatedBy?.lastName)}`}
                  </span>

                  <span>{t('care_alert_note_shift_label')}</span>

                  <div>
                    <span
                      className={cx('tableLink')}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!record.detail.client) return;
                        onViewDetailClient(record.detail.client);
                      }}
                    >
                      {`${getUserName(record.detail.client?.firstName, record.detail.client?.lastName)}`}
                    </span>

                    {t('care_alert_note_shift_weekend_label')}
                  </div>
                </div>
                <span className={cx('contentNote')}>{`"${record.detail.note?.description}"`}</span>
              </div>
            );

          case TypeCareAlertEnum.UPDATE_SHIFT:
            return (
              <div className={cx('contentItem')}>
                <span
                  className={cx('tableLink')}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!record.detail.updatedBy) return;
                    onViewDetailEmployee(record.detail.updatedBy);
                  }}
                >
                  {`${getUserName(record.detail.updatedBy?.firstName, record.detail.updatedBy?.lastName)}`}
                </span>

                {t('care_alert_update_shift_label')}

                <div>
                  <span
                    className={cx('tableLink')}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!record.detail.client) return;
                      onViewDetailClient(record.detail.client);
                    }}
                  >
                    {`${getUserName(record.detail.client?.firstName, record.detail.client?.lastName)}`}
                  </span>

                  {t('care_alert_note_shift_weekend_label')}
                </div>
              </div>
            );

          case TypeCareAlertEnum.LATE_CLOCK_INS:
            return (
              <div className={cx('contentItem')}>
                <span
                  className={cx('tableLink')}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!record.detail.caregiver) return;
                    onViewDetailCaregiver(record.detail.caregiver);
                  }}
                >
                  {getUserName(record.detail.caregiver?.firstName, record.detail.caregiver?.lastName)}
                </span>

                {t('care_alert_late_clock_in_label', {
                  time: record.detail.caregiver?.late,
                })}

                <div>
                  <span
                    className={cx('tableLink')}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!record.detail.client) return;
                      onViewDetailClient(record.detail.client);
                    }}
                  >
                    {`${getUserName(record.detail.client?.firstName, record.detail.client?.lastName)}`}
                  </span>

                  {t('care_alert_note_shift_weekend_label')}
                </div>
              </div>
            );

          case TypeCareAlertEnum.BIRTHDAY_CLIENT:
            return (
              <div className={cx('contentItem')}>
                {t('care_alert_to_day_is_label')}
                <span
                  className={cx('tableLink')}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!record.detail.client) return;
                    onViewDetailClient(record.detail.client);
                  }}
                >
                  {getUserName(record.detail.client?.firstName, record.detail.client?.lastName)}
                  {POSSESSIVE_SUFFIX}
                </span>
                {t('care_alert_birthday_label')}
              </div>
            );

          case TypeCareAlertEnum.BIRTHDAY_CAREGIVER:
            return (
              <div className={cx('contentItem')}>
                {t('care_alert_to_day_is_label')}
                <span
                  className={cx('tableLink')}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!record.detail.caregiver) return;
                    onViewDetailCaregiver(record.detail.caregiver);
                  }}
                >
                  {getUserName(record.detail.caregiver?.firstName, record.detail.caregiver?.lastName)}
                  {POSSESSIVE_SUFFIX}
                </span>
                {t('care_alert_birthday_label')}
              </div>
            );

          case TypeCareAlertEnum.CLIENT_NO_SHIFT:
            return (
              <div className={cx('contentItem')}>
                <span
                  className={cx('tableLink')}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!record.detail.client) return;
                    onViewDetailClient(record.detail.client);
                  }}
                >
                  {getUserName(record.detail.client?.firstName, record.detail.client?.lastName)}
                </span>
                {t('care_alert_client_no_shift_is_label', {
                  days: record.detail.days || DEFAULT_NUMBER_ZERO,
                })}
              </div>
            );

          default:
            break;
        }
      },
    },
  ];
};

const CareAlertsShowAll = (props: Props) => {
  //#region Destructuring Props
  const { role } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(() => Number(params?.page ?? DEFAULT_CURRENT_PAGE), [params?.page]);
  const typeParams = useMemo<string>(() => String(params?.type || EMPTY_STRING), [params?.type]);
  const timeParams = useMemo<string>(() => String(params?.time || EMPTY_STRING), [params?.time]);
  //#endregion Declare Hook

  //#region Selector
  const isRefreshCareAlertList = useSelector(selectIsRefreshCareAlertList);
  //#endregion Selector

  //#region Declare State
  const [careAlertList, setCareAlertList] = useState<IListCareAlert[]>();
  const [pagination, setPagination] = useState<IPaginationResponse>();
  const [careAlert, setCareAlert] = useState<IBaseOption>(mockOptionTimeCareAlert[0]);
  const [optionAlert, setOptionAlert] = useState<string[]>([mockOptionAlert[0].value]);
  const [accountId, setAccountId] = useState<string>(EMPTY_STRING);
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);

  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const { type, time, ...restParams } = params;

    const newParams = {
      ...restParams,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      ...(params?.type === TypeCareAlertEnum.ALL_ALERTS ? {} : { type: [type] }),
      ...(params?.time
        ? { lastHours: Number(params?.time) }
        : {
            lastHours: Number(TimelineAlertEnum.LAST_TWELVE_FOURS_HOURS),
          }),
    };

    handleGetListCareAlert(newParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListCareAlert = (params: IListQueryParamsCareAlert) => {
    loading?.show();

    dispatch(getListCareAlert(params))
      .unwrap()
      .then((res) => {
        const { responses, pagination } = res?.data;
        setCareAlertList(responses);
        setPagination(pagination);
      })
      .catch((error) => {})
      .finally(() => loading?.hide());
  };

  const handleBack = () => {
    navigate(getRoutesByRole(role)?.caregiver ?? EMPTY_STRING);
  };

  const handlePaginationChange = (page: number) => {
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleChangeDateTime = (value: IBaseOption) => {
    // TODO: Handle Logic Later
  };

  const handleChangeOptionAlert = (value: string[]) => {
    setOptionAlert(value);
  };

  const handleGoBackHome = () => {
    navigate(NAVIGATE_BACK);
  };

  const handleVisibleDrawer = () => {
    setIsOpenDrawer(!isOpenDrawer);
  };

  const handleViewDetailCaregiver = (caregiver: ICaregiver) => {
    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(
          `${superAdminRoute.base}${superAdminRoute.caregiver}/${superAdminRoute.caregiverShowAll}/${caregiver?.id}`
        );
        break;
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRoute.base}${adminRoute.caregiver}/${adminRoute.caregiverShowAll}/${caregiver?.id}`);
        break;
      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(`${staffRoute.base}${staffRoute.caregiver}/${staffRoute.caregiverShowAll}/${caregiver?.id}`);
        break;
    }
  };

  const handleViewDetailClient = (client: IClient) => {
    if (!client.id) return;

    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRouteAbsolute.crmClient}/${client.id}`);
        return;
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.crmClient}/${client.id}`);
        return;

      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(`${staffRouteAbsolute.crmClient}/${client.id}`);
        return;

      case AccountRoleCodesEnum.CAREGIVER:
        navigate(`${caregiverRouteAbsolute.crmClient}/${client.id}`);
        return;

      default:
        return;
    }
  };

  const handleViewDetailEmployment = (employee: IClient) => {
    setAccountId(employee.id);
    handleVisibleDrawer();
  };

  const handleEditInfo = (id: string) => {
    handleVisibleDrawer();
    handleEdit(id);
  };

  const handleEdit = (id: string) => {
    if (!id) return;
    const role = localStorage.getItem(StorageEnum.ROLE);

    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRoute.base}${superAdminRoute.employees}${superAdminRoute.editEmployees}/${id}`);
        break;

      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRoute.base}${adminRoute.employees}${adminRoute.editEmployees}/${id}`);
        break;
    }
  };

  const handleSubmitFilter = (valueFilter: IFilterCareAlert | undefined) => {
    if (!valueFilter) {
      return;
    }

    const { type, time, ...restParams } = params;

    setSearchParams({
      ...restParams,
      page: DEFAULT_NUMBER_ONE.toString(),
      limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
      ...(valueFilter?.type === TypeCareAlertEnum.ALL_ALERTS ? {} : { type: valueFilter.type }),
      ...(valueFilter?.lastHours ? { time: valueFilter?.lastHours } : {}),
    });
  };
  //#endregion Handle Function

  return (
    <div id='careAlertsShowAllPage' className={cx('careAlertsShowAllContainer')}>
      <BaseButton
        onClick={handleGoBackHome}
        typeStyle={ButtonTypeEnum.ONLY_TEXT}
        iconLeft={icons.commonIconPrev}
        text={t('common_text_back')}
      />

      <div className={cx('headerToolBar')}>
        <ToolBar title={t('care_alerts_show_all_care_alerts_title')}>
          <BaseFilter<IFilterCareAlert>
            defaultValue={{}}
            onApply={handleSubmitFilter}
            valueFilter={{
              type: typeParams || TypeCareAlertEnum.ALL_ALERTS,
              lastHours: timeParams || String(TimelineAlertEnum.LAST_TWELVE_FOURS_HOURS),
            }}
          >
            {({ valueFilter, onChange }) => {
              return (
                <div className={cx('filterWrap')}>
                  <div className={cx('contentFilter')}>
                    <span className={cx('filterLabel')}>{t('care_alert_type_label')}</span>
                    <BaseSelect
                      options={optionFilterTypeCareAlert || []}
                      placeholder={t('common_select_placeholder')}
                      onChange={({ value }) => {
                        onChange({
                          name: 'type',
                          value: value.toString(),
                        });
                      }}
                      value={String(valueFilter?.type)}
                      width={200}
                    />
                  </div>

                  <div className={cx('contentFilter')}>
                    <span className={cx('filterLabel')}>{t('care_alert_timeline_label')}</span>

                    <BaseSelect
                      options={optionTimeCareAlert || []}
                      value={Number(valueFilter?.lastHours)}
                      placeholder={t('common_select_placeholder')}
                      onChange={({ value }) =>
                        onChange({
                          name: 'lastHours',
                          value: value.toString(),
                        })
                      }
                      width={200}
                    />
                  </div>
                </div>
              );
            }}
          </BaseFilter>
        </ToolBar>
      </div>

      <div className={cx('bodyContent')}>
        <BaseTable
          columns={columns(t, handleViewDetailCaregiver, handleViewDetailClient, handleViewDetailEmployment)}
          dataSource={careAlertList ?? []}
        />
      </div>

      <div className={cx('paginationTable')}>
        <BasePagination
          onChange={handlePaginationChange}
          defaultCurrentPage={pageSelected}
          totalItems={pagination?.totalItems}
          totalPages={pagination?.totalPages}
        />
      </div>

      <BaseDrawerModal isOpen={isOpenDrawer} onClose={handleVisibleDrawer}>
        <EmployeeDetailModalItem accountId={accountId} onEditInfo={handleEditInfo} />
      </BaseDrawerModal>
    </div>
  );
};

export default CareAlertsShowAll;
