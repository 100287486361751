// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { RootState } from '~/redux/store';
import {
  createClients,
  getListClients,
  getDetailClients,
  updateClient,
  getListRelates,
  sendEmailToClient,
  changeStatusClient,
  getDetailCarePlan,
  createCarePlan,
  getListClientByContact,
  getListClientByLocations,
  changeStageClient,
} from '~/thunks/crm/clients/clientsThunk';
import { IClientDetail } from '~/utils/interface/crm/clients';

export interface ClientState {
  isRefreshClientList: boolean;
  isRefreshActivityList: boolean;
  clientDetail?: IClientDetail;
  isFetchingClientDetail: boolean;
}

const initialState: ClientState = {
  isRefreshClientList: false,
  isRefreshActivityList: false,
  isFetchingClientDetail: false,
};

const clientsSlice = createSlice({
  name: 'clientState',
  initialState,
  reducers: {
    setRefreshList(state, action) {
      state.isRefreshClientList = action.payload;
    },
    resetClientDetail(state) {
      state.clientDetail = initialState.clientDetail;
    },
    setRefreshActivityList(state, action) {
      state.isRefreshActivityList = action.payload;
    },
  },
  extraReducers(builder) {
    // Get List Clients
    builder
      .addCase(getListClients.pending, (state) => {})
      .addCase(getListClients.fulfilled, (state) => {})
      .addCase(getListClients.rejected, (state) => {});

    // Create Clients
    builder
      .addCase(createClients.pending, (state) => {})
      .addCase(createClients.fulfilled, (state) => {})
      .addCase(createClients.rejected, (state) => {});

    // Get Detail Clients
    builder
      .addCase(getDetailClients.pending, (state) => {
        state.isFetchingClientDetail = true;
      })
      .addCase(getDetailClients.fulfilled, (state, action) => {
        state.clientDetail = action.payload.data;
        state.isFetchingClientDetail = false;
      })
      .addCase(getDetailClients.rejected, (state) => {
        state.isFetchingClientDetail = false;
      });

    // Update Client
    builder
      .addCase(updateClient.pending, (state) => {})
      .addCase(updateClient.fulfilled, (state) => {})
      .addCase(updateClient.rejected, (state) => {});

    // Get List Relates
    builder
      .addCase(getListRelates.pending, (state) => {})
      .addCase(getListRelates.fulfilled, (state) => {})
      .addCase(getListRelates.rejected, (state) => {});

    // Send email to client
    builder
      .addCase(sendEmailToClient.pending, (state) => {})
      .addCase(sendEmailToClient.fulfilled, (state) => {})
      .addCase(sendEmailToClient.rejected, (state) => {});

    // Change Status Client
    builder
      .addCase(changeStatusClient.pending, (state) => {})
      .addCase(changeStatusClient.fulfilled, (state) => {})
      .addCase(changeStatusClient.rejected, (state) => {});

    // Get Detail Care Plan
    builder
      .addCase(getDetailCarePlan.pending, (state) => {})
      .addCase(getDetailCarePlan.fulfilled, (state) => {})
      .addCase(getDetailCarePlan.rejected, (state) => {});

    // Create/Update care plan
    builder
      .addCase(createCarePlan.pending, (state) => {})
      .addCase(createCarePlan.fulfilled, (state) => {})
      .addCase(createCarePlan.rejected, (state) => {});

    // Get List Client By Contact
    builder
      .addCase(getListClientByContact.pending, (state) => {})
      .addCase(getListClientByContact.fulfilled, (state) => {})
      .addCase(getListClientByContact.rejected, (state) => {});

    // Get List Client By Locations
    builder
      .addCase(getListClientByLocations.pending, (state) => {})
      .addCase(getListClientByLocations.fulfilled, (state) => {})
      .addCase(getListClientByLocations.rejected, (state) => {});

    // Change Stage Client
    builder
      .addCase(changeStageClient.pending, (state) => {})
      .addCase(changeStageClient.fulfilled, (state) => {})
      .addCase(changeStageClient.rejected, (state) => {});
  },
});

// Actions
export const clientActions = clientsSlice.actions;

// Selectors
export const selectIsRefreshClientList = (state: RootState) => state.clientState.isRefreshClientList;

// Reducer
const clientReducer = clientsSlice.reducer;
export default clientReducer;
