// Libs
// Others
import axiosClient from '~/apis/axiosClient';
import { BaseResponse, IListDataResponse } from '~/utils/interface/common';
import { urlApiCampaign } from '~/utils/constants/api';
import {
  ICampaign,
  IQueryParamsCampaign,
  IFormCreateCampaign,
  ICampaignDetail,
  IFormUpdateCampaign,
} from '~/utils/interface/crm/campaign';

const campaignApi = {
  getListCampaign(params: IQueryParamsCampaign) {
    const url = `${urlApiCampaign.getList}`;
    return axiosClient.get<BaseResponse<IListDataResponse<ICampaign[]>>>(url, { params });
  },

  createCampaign(body: IFormCreateCampaign) {
    const url = `${urlApiCampaign.createCampaign}`;
    return axiosClient.post<BaseResponse>(url, body);
  },

  getDetailCampaign(id: string) {
    const url = urlApiCampaign.getDetailCampaign(id);
    return axiosClient.get<BaseResponse<ICampaignDetail>>(url);
  },

  editCampaign(data: IFormUpdateCampaign) {
    const { id, body } = data;
    const url = urlApiCampaign.editCampaign(id);
    return axiosClient.put<BaseResponse>(url, body);
  },
};

export default campaignApi;
