import { urlApiActivity } from '~/utils/constants/api';
import axiosClient from '~/apis/axiosClient';
import { BaseResponse, IListDataResponse } from '~/utils/interface/common';
import { IActivityByUser, IParamsGetListActivity } from '~/utils/interface/activity';

const activityApi = {
  getListActivityByClient({ userId, params }: IParamsGetListActivity) {
    const url = `${urlApiActivity.getListActivityByClient(userId)}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IActivityByUser[]>>>(url, { params });
  },

  getListActivityByCaregiver({ userId, params }: IParamsGetListActivity) {
    const url = `${urlApiActivity.getListActivityByCaregiver(userId)}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IActivityByUser[]>>>(url, { params });
  },

  getListActivityByApplicant({ userId, params }: IParamsGetListActivity) {
    const url = `${urlApiActivity.getListActivityByApplicant(userId)}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IActivityByUser[]>>>(url, { params });
  },

  getListActivityByProspect({ userId, params }: IParamsGetListActivity) {
    const url = `${urlApiActivity.getListActivityByProspect(userId)}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IActivityByUser[]>>>(url, { params });
  },
};

export default activityApi;
