// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useLoadScript } from '@react-google-maps/api';
// Components, Layouts, Pages
import { BaseGoogleMap, LabelValueField } from '~/components';
// Others
import { ICaregiverDetail } from '~/utils/interface/caregiver';
import { DEFAULT_GG_MAP_LOAD_SCRIPT_LIB, EMPTY_STRING } from '~/utils/constants/common';
import { formatAddress, formatPhoneNumber, getUserName } from '~/utils/helper';
import { googleMapApiKey } from '~/utils/constants/env';
// Styles, images, icons
import styles from './InfoTab.module.scss';

type Props = {
  caregiverInfo?: ICaregiverDetail;
};

const cx = classNames.bind(styles);

const InfoTab = (props: Props) => {
  //#region Destructuring Props
  const { caregiverInfo } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapApiKey ?? EMPTY_STRING,
    libraries: DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='infoTabComponent' className={cx('infoTabComponent')}>
      <div className={cx('body')}>
        <div className={cx('infoDetailsWrap')}>
          <LabelValueField label={t('info_tab_title_email')} value={caregiverInfo?.email || EMPTY_STRING} />

          <LabelValueField
            label={t('info_tab_title_location')}
            value={caregiverInfo?.locations.map((location) => location.name).join(', ') || EMPTY_STRING}
          />

          <LabelValueField
            label={t('info_tab_title_work_phone')}
            value={caregiverInfo?.workPhone ? formatPhoneNumber(caregiverInfo?.workPhone) : EMPTY_STRING}
          />
          <LabelValueField
            label={t('info_tab_title_home_phone')}
            value={caregiverInfo?.homePhone ? formatPhoneNumber(caregiverInfo?.homePhone) : EMPTY_STRING}
          />
          <LabelValueField
            label={t('info_tab_title_mobile_phone')}
            value={caregiverInfo?.mobilePhone ? formatPhoneNumber(caregiverInfo?.mobilePhone) : EMPTY_STRING}
          />

          <LabelValueField
            label={t('info_tab_title_referrer')}
            value={
              caregiverInfo?.referrer?.organizationName ||
              getUserName(
                caregiverInfo?.referrer?.firstName,
                caregiverInfo?.referrer?.middleName,
                caregiverInfo?.referrer?.lastName
              )
            }
          />

          <LabelValueField label={t('info_tab_title_caregiver_npi')} value={caregiverInfo?.npi || EMPTY_STRING} />

          <LabelValueField label={t('info_tab_title_external_id')} value={caregiverInfo?.externalId || EMPTY_STRING} />

          <LabelValueField label={t('info_tab_title_ssn')} value={caregiverInfo?.ssn || EMPTY_STRING} />

          <LabelValueField label={t('info_tab_title_notes')} value={caregiverInfo?.notes || EMPTY_STRING} isLongValue />
        </div>

        <div className={cx('mapSection')}>
          <div className={cx('viewAddress')}>
            <span className={cx('textLabel')}>{t('info_tab_title_address')}</span>
            <span className={cx('valueContent')}>
              <span className={cx('valueContent')}>{formatAddress({ ...caregiverInfo })}</span>
            </span>
          </div>

          <div className={cx('mapViewContainer')}>
            {isLoaded && (
              <BaseGoogleMap
                position={
                  caregiverInfo?.lat && caregiverInfo?.lng
                    ? { lat: caregiverInfo.lat, lng: caregiverInfo.lng }
                    : undefined
                }
                triggerLocate
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoTab;
