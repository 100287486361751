// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useLoadScript } from '@react-google-maps/api';
import { Link } from 'react-router-dom';
// Components, Layouts, Pages
import { BaseGoogleMap, LabelValueField } from '~/components';
// Others
import { DEFAULT_GG_MAP_LOAD_SCRIPT_LIB, EMPTY_STRING } from '~/utils/constants/common';
import { formatAddress, formatPhoneNumber } from '~/utils/helper';
import { googleMapApiKey } from '~/utils/constants/env';
import { useAppSelector } from '~/redux/hooks';
// Styles, images, icons
import styles from './ReferralSourceInfoTab.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const ReferralSourceInfoTab = (props: Props) => {
  //#region Declare Hook
  const { t } = useTranslation();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapApiKey ?? EMPTY_STRING,
    libraries: DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  });
  //#endregion Declare Hook

  //#region Selector
  const { clientDetail } = useAppSelector((state) => state.clientState);
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='=referralSourceInfoTabComponent' className={cx('referralSourceInfoTabComponent')}>
      <div className={cx('body')}>
        <div className={cx('infoDetailsWrap')}>
          <LabelValueField label={t('info_tab_title_email')} value={clientDetail?.email || EMPTY_STRING} />

          <LabelValueField
            label={t('info_tab_title_home_phone')}
            value={clientDetail?.homePhone ? formatPhoneNumber(clientDetail?.homePhone) : EMPTY_STRING}
          />

          <LabelValueField
            label={t('info_tab_title_mobile_phone')}
            value={clientDetail?.mobilePhone ? formatPhoneNumber(clientDetail?.mobilePhone) : EMPTY_STRING}
          />

          <LabelValueField
            label={t('info_tab_title_work_phone')}
            value={clientDetail?.workPhone ? formatPhoneNumber(clientDetail?.workPhone) : EMPTY_STRING}
          />

          <LabelValueField
            label={t('info_tab_title_fax_number')}
            value={clientDetail?.faxNumber ? formatPhoneNumber(clientDetail?.faxNumber) : EMPTY_STRING}
          />

          <LabelValueField
            label={t('info_tab_title_secondary_address')}
            value={clientDetail?.secondaryAddress || EMPTY_STRING}
          />

          <LabelValueField
            label={t('info_tab_title_web_address')}
            value={<Link to={clientDetail?.webAddress || EMPTY_STRING} target='_blank' />}
          />
        </div>

        <div className={cx('mapSection')}>
          <div className={cx('viewAddress')}>
            <span className={cx('textLabel')}>{t('info_tab_title_address')}</span>
            <span className={cx('valueContent')}>
              <span className={cx('valueContent')}>{formatAddress({ ...clientDetail })}</span>
            </span>
          </div>

          <div className={cx('mapViewContainer')}>
            {isLoaded && (
              <BaseGoogleMap
                position={
                  clientDetail?.lat && clientDetail?.lng
                    ? { lat: clientDetail?.lat, lng: clientDetail?.lng }
                    : undefined
                }
                triggerLocate
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralSourceInfoTab;
