// Libs
import classNames from 'classnames/bind';
import { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// Components, Layouts, Pages
import { CheckboxSingle, BaseInput } from '~/components';

// Others
import { IFormBillingPolicy } from '~/utils/interface/rate';
import { useAppSelector } from '~/redux/hooks';
import { getUserName } from '~/utils/helper';
import { DOLLAR, EMPTY_STRING } from '~/utils/constants/common';
import { InputTypeEnum } from '~/utils/enum';

// Styles, images, icons
import styles from './TravelReimbursementBillingPolicy.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const TravelReimbursementSetting = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IFormBillingPolicy>();
  //#endregion Declare Hook

  //#region Selector
  const { clientDetail } = useAppSelector((state) => state.clientState);
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='travelReimbursementBillingPolicyComponent' className={cx('travelReimbursementBillingPolicyComponent')}>
      <div className={cx('content')}>
        <div className={cx('groupItem')}>
          <h1 className={cx('title')}>
            {t('billing_policy_travel_reimbursement_mileage_traveled_title', {
              userName: getUserName(clientDetail?.firstName, clientDetail?.lastName),
            })}
          </h1>

          <Controller
            name='travelRate.isDefaultTravelMileage'
            control={control}
            render={({ field: { value, onChange } }) => (
              <CheckboxSingle
                label={t('billing_policy_travel_reimbursement_company_default_rate_title')}
                value={value}
                onChange={(checked: boolean, name?: string) => {
                  onChange(checked);
                }}
              />
            )}
          />
        </div>

        <div className={cx('groupCol')}>
          <span>{DOLLAR}</span>
          <Controller
            name='travelRate.travelMileageRate'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='travelRate.travelMileageRate'
                value={value || EMPTY_STRING}
                onChange={onChange}
                width={300}
                type={InputTypeEnum.NUMBER}
                textAlign='right'
              />
            )}
          />
          <span>{t('billing_policy_invoicing_mile_title')}</span>
        </div>

        <div className={cx('groupItem')}>
          <h1 className={cx('title')}>
            {t('billing_policy_travel_reimbursement_holiday_rate_title', {
              userName: getUserName(clientDetail?.firstName, clientDetail?.lastName),
            })}
          </h1>

          <Controller
            name='travelRate.isHolidayRateApplied'
            control={control}
            render={({ field: { value, onChange } }) => (
              <CheckboxSingle
                label={t('billing_policy_travel_reimbursement_use_holiday_rate_title')}
                value={value}
                onChange={(checked: boolean, name?: string) => {
                  onChange(checked);
                }}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(TravelReimbursementSetting);
