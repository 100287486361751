// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { getListCaregiverTraining } from './trainingThunk';

export interface TrainingState {
  isRefreshCaregiverTrainingList: boolean;
  isFetchingCaregiverTrainingList: boolean;
}

const initialState: TrainingState = {
  isRefreshCaregiverTrainingList: false,
  isFetchingCaregiverTrainingList: false,
};

const trainingSlice = createSlice({
  name: 'trainingState',
  initialState,
  reducers: {},
  extraReducers(builder) {
    // Get list caregiver training
    builder
      .addCase(getListCaregiverTraining.pending, (state) => {
        state.isFetchingCaregiverTrainingList = true;
      })
      .addCase(getListCaregiverTraining.fulfilled, (state) => {
        state.isRefreshCaregiverTrainingList = false;
        state.isFetchingCaregiverTrainingList = false;
      })
      .addCase(getListCaregiverTraining.rejected, (state) => {
        state.isRefreshCaregiverTrainingList = false;
        state.isFetchingCaregiverTrainingList = false;
      });
  },
});

// Actions
export const trainingActions = trainingSlice.actions;

// Reducer
const trainingReducer = trainingSlice.reducer;
export default trainingReducer;
