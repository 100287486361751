//Libs
import { createSlice } from '@reduxjs/toolkit';

import {
  createSchedule,
  getListSchedule,
  deleteSchedule,
  getDetailSchedule,
  updateSchedule,
  getScheduleClient,
  getOfficeSchedule,
  getListShiftSchedule,
  getSchedulesLiveView,
  createShiftSchedule,
  getDetailShift,
  getListNoteSchedule,
  createShiftNoteSchedule,
  getScheduleCaregiver,
  createUnavailabilityNote,
  getDetailUnavailability,
  changeStatusShiftSchedule,
  updateShiftSchedule,
  deleteShift,
  createOfficeCalendar,
  updateOfficeCalendar,
  getDetailOfficeCalendar,
  deleteOfficeCalendar,
  getDetailOfficeCalendarEvent,
} from '~/thunks/schedule/scheduleThunk';
import { RootState } from '~/redux/store';
import { IDetailShift } from '~/utils/interface/schedule';

export interface ScheduleState {
  isRefreshScheduleList: boolean;
  isRefreshOfficeSchedule: boolean;
  isRefreshShiftSchedules: boolean;
  isRefreshDetailShift: boolean;
  detailShift?: IDetailShift;
  isRefreshShiftNote: boolean;
  isFetchingShiftNote: boolean;
  isRefreshUnavailabilityNote: boolean;
}

const initialState: ScheduleState = {
  isRefreshScheduleList: false,
  isRefreshOfficeSchedule: false,
  isRefreshShiftSchedules: false,
  isRefreshDetailShift: false,
  isRefreshShiftNote: false,
  isFetchingShiftNote: false,
  isRefreshUnavailabilityNote: false,
};

const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    setRefreshScheduleList(state, action) {
      state.isRefreshScheduleList = action.payload;
    },
    setRefreshOfficeSchedule(state, action) {
      state.isRefreshOfficeSchedule = action.payload;
    },
    setRefreshShiftSchedules(state, action) {
      state.isRefreshShiftSchedules = action.payload;
    },
    resetShiftDetail(state) {
      state.detailShift = initialState.detailShift;
    },
  },
  extraReducers(builder) {
    // Create Schedule
    builder
      .addCase(createSchedule.pending, (state) => {})
      .addCase(createSchedule.fulfilled, (state) => {})
      .addCase(createSchedule.rejected, (state) => {});

    // Get List Shift Schedule
    builder
      .addCase(getListShiftSchedule.pending, (state) => {})
      .addCase(getListShiftSchedule.fulfilled, (state) => {})
      .addCase(getListShiftSchedule.rejected, (state) => {});

    // Get: list Schedule
    builder
      .addCase(getListSchedule.pending, (state, action) => {})
      .addCase(getListSchedule.fulfilled, (state, action) => {})
      .addCase(getListSchedule.rejected, (state, action) => {});

    // Delete Schedule
    builder
      .addCase(deleteSchedule.pending, (state) => {})
      .addCase(deleteSchedule.fulfilled, (state) => {})
      .addCase(deleteSchedule.rejected, (state) => {});

    // Detail Schedule
    builder
      .addCase(getDetailSchedule.pending, (state) => {})
      .addCase(getDetailSchedule.fulfilled, (state) => {})
      .addCase(getDetailSchedule.rejected, (state) => {});

    // Update Schedule
    builder
      .addCase(updateSchedule.pending, (state) => {})
      .addCase(updateSchedule.fulfilled, (state) => {})
      .addCase(updateSchedule.rejected, (state) => {});

    // Get Schedule Client
    builder
      .addCase(getScheduleClient.pending, (state) => {})
      .addCase(getScheduleClient.fulfilled, (state) => {})
      .addCase(getScheduleClient.rejected, (state) => {});

    // Get Office Schedule
    builder
      .addCase(getOfficeSchedule.pending, (state) => {})
      .addCase(getOfficeSchedule.fulfilled, (state) => {})
      .addCase(getOfficeSchedule.rejected, (state) => {});

    // Get Schedules Live View
    builder
      .addCase(getSchedulesLiveView.pending, (state) => {})
      .addCase(getSchedulesLiveView.fulfilled, (state, action) => {
        state.isRefreshShiftSchedules = false;
      })
      .addCase(getSchedulesLiveView.rejected, (state) => {});

    // Create Shift Schedule
    builder
      .addCase(createShiftSchedule.pending, (state) => {})
      .addCase(createShiftSchedule.fulfilled, (state) => {
        state.isRefreshShiftSchedules = true;
      })
      .addCase(createShiftSchedule.rejected, (state) => {});

    // Change Status Shift Schedule
    builder
      .addCase(changeStatusShiftSchedule.pending, (state) => {})
      .addCase(changeStatusShiftSchedule.fulfilled, (state) => {
        state.isRefreshShiftSchedules = true;
      })
      .addCase(changeStatusShiftSchedule.rejected, (state) => {});

    // Get Detail Shift
    builder
      .addCase(getDetailShift.pending, (state) => {})
      .addCase(getDetailShift.fulfilled, (state, action) => {
        state.isRefreshDetailShift = false;
        state.detailShift = action.payload;
      })
      .addCase(getDetailShift.rejected, (state) => {});

    // Get List Notes Schedule
    builder
      .addCase(getListNoteSchedule.pending, (state) => {
        state.isFetchingShiftNote = true;
      })
      .addCase(getListNoteSchedule.fulfilled, (state) => {
        state.isRefreshShiftNote = false;
        state.isFetchingShiftNote = false;
      })
      .addCase(getListNoteSchedule.rejected, (state) => {
        state.isFetchingShiftNote = false;
      });

    // Create Shift Note
    builder
      .addCase(createShiftNoteSchedule.pending, (state) => {})
      .addCase(createShiftNoteSchedule.fulfilled, (state) => {
        state.isRefreshShiftNote = true;
      })
      .addCase(createShiftNoteSchedule.rejected, (state) => {});

    // Get Schedule Caregiver
    builder
      .addCase(getScheduleCaregiver.pending, (state) => {})
      .addCase(getScheduleCaregiver.fulfilled, (state) => {})
      .addCase(getScheduleCaregiver.rejected, (state) => {});

    // Get Detail Unavailability
    builder
      .addCase(getDetailUnavailability.pending, (state) => {})
      .addCase(getDetailUnavailability.fulfilled, (state) => {
        state.isRefreshUnavailabilityNote = false;
      })
      .addCase(getDetailUnavailability.rejected, (state) => {});

    // Create Note Unavailability
    builder
      .addCase(createUnavailabilityNote.pending, (state) => {})
      .addCase(createUnavailabilityNote.fulfilled, (state) => {
        state.isRefreshUnavailabilityNote = true;
      })
      .addCase(createUnavailabilityNote.rejected, (state) => {});

    // Update Shift Schedule
    builder
      .addCase(updateShiftSchedule.pending, (state) => {})
      .addCase(updateShiftSchedule.fulfilled, (state) => {})
      .addCase(updateShiftSchedule.rejected, (state) => {});

    // Delete Shift
    builder
      .addCase(deleteShift.pending, (state) => {})
      .addCase(deleteShift.fulfilled, (state) => {})
      .addCase(deleteShift.rejected, (state) => {});

    //  Create Office Calendar
    builder
      .addCase(createOfficeCalendar.pending, (state) => {})
      .addCase(createOfficeCalendar.fulfilled, (state) => {})
      .addCase(createOfficeCalendar.rejected, (state) => {});

    //  Get Detail Office Calendar
    builder
      .addCase(getDetailOfficeCalendar.pending, (state) => {})
      .addCase(getDetailOfficeCalendar.fulfilled, (state) => {})
      .addCase(getDetailOfficeCalendar.rejected, (state) => {});

    //  Update Office Calendar
    builder
      .addCase(updateOfficeCalendar.pending, (state) => {})
      .addCase(updateOfficeCalendar.fulfilled, (state) => {})
      .addCase(updateOfficeCalendar.rejected, (state) => {});

    //  Delete Office Calendar
    builder
      .addCase(deleteOfficeCalendar.pending, (state) => {})
      .addCase(deleteOfficeCalendar.fulfilled, (state) => {})
      .addCase(deleteOfficeCalendar.rejected, (state) => {});

    //  Get Detail Office Calendar Event
    builder
      .addCase(getDetailOfficeCalendarEvent.pending, (state) => {})
      .addCase(getDetailOfficeCalendarEvent.fulfilled, (state) => {})
      .addCase(getDetailOfficeCalendarEvent.rejected, (state) => {});
  },
});

export const scheduleActions = scheduleSlice.actions;

export const selectIsRefreshScheduleList = (state: RootState) => state.scheduleState.isRefreshScheduleList;

const scheduleReducer = scheduleSlice.reducer;

export default scheduleReducer;
