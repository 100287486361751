// Libs
import classNames from 'classnames/bind';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// Components, Layouts, Pages
import { ImageCircle } from '~/components';
// Others
import { ImageCircleTypeEnum, MessageTypeEnum, StorageEnum, TimeFormatEnum, MessageFileTypeEnum } from '~/utils/enum';
import { convertToRelativeTime, convertUTCTimeToLocalTime, getFileExtension, getUserName } from '~/utils/helper';
import { IFileMessage, IMessage } from '~/utils/interface/message';
import { EMPTY_STRING, DEFAULT_NUMBER_ZERO } from '~/utils/constants/common';
import { useAppSelector } from '~/redux/hooks';
// Styles, images, icons
import styles from './ConversationMessage.module.scss';
import { icons } from '~/assets';

type Props = {
  data: IMessage;
};

const cx = classNames.bind(styles);

const ConversationMessage = (props: Props) => {
  //#region Destructuring Props
  const { data } = props;
  const accountId = localStorage.getItem(StorageEnum.USER_ID);
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const [isMyMessage] = useState<boolean>(() => {
    if (!accountId) {
      return false;
    }
    return data.senderAccountId === accountId;
  });
  //#endregion Declare Hook

  //#region Selector
  const { tempIdSendFile, typeFileLoading } = useAppSelector((state) => state.conversationState);
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const renderContentMessage = () => {
    switch (data.type) {
      case MessageTypeEnum.TEXT:
        return (
          <div className={cx('textMessageContent')}>
            <p>{data.content}</p>
            <p className={cx('messageTimeWrap', 'conversationMessageTime')}>
              {convertUTCTimeToLocalTime(data?.createdAt as string, TimeFormatEnum.HOUR_MINUTE_AM_PM)}
            </p>
          </div>
        );

      case MessageTypeEnum.FILE:
        const firstFile = data?.files?.[DEFAULT_NUMBER_ZERO];

        return tempIdSendFile === data.id ? (
          <div
            className={cx('loadingContainer', typeFileLoading && !typeFileLoading.includes('image') && 'loadingFile')}
          >
            <span className={cx('loadingContent')} />
          </div>
        ) : (
          renderFileItem(firstFile)
        );
    }
  };

  const renderFileItem = (firstFile: IFileMessage | undefined) => {
    if (!firstFile) return;

    switch (firstFile?.type) {
      case MessageFileTypeEnum.IMAGE:
        return (
          <Link to={firstFile?.url} target='_blank'>
            <img src={firstFile?.url} alt={t('common_img_text_alt')} className={cx('photoMessage')} />
          </Link>
        );

      case MessageFileTypeEnum.FILE:
        return (
          <Link to={firstFile?.url} className={cx('viewFile')} target='_blank'>
            <img src={icons.commonIconDocsFile} alt={t('common_img_text_alt')} className={cx('fileImg')} />
            <div className={cx(isMyMessage, 'nameFile')}>
              <div className={cx('file')}>{`${firstFile.name || EMPTY_STRING}`}</div>
              <div>{`.${getFileExtension(firstFile?.url)}`}</div>
            </div>
          </Link>
        );

      case MessageFileTypeEnum.MEDIA:
        return (
          <Link to={firstFile?.url} className={cx('viewFile')} target='_blank'>
            <img src={icons.commonIconVideo} alt={t('common_img_text_alt')} className={cx('fileImg')} />
          </Link>
        );
    }
  };
  //#endregion Handle Function

  return (
    <div
      id='conversationMessageComponent'
      className={cx(isMyMessage ? 'messageIsMe' : 'message')}
      data-message-id={data.id.toString()}
    >
      <div className={cx('conversationMessageContent')}>
        <div className={cx('avatarFirstLastName')}>
          <ImageCircle
            type={data.senderAvatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
            imageUrl={data.senderAvatarUrl}
            width={40}
            height={40}
            fontSize={20}
            firstName={data.senderFirstName}
          />

          <div className={cx('firstLastName')}>
            <span className={cx('memberUserName')}>{getUserName(data?.senderFirstName, data?.senderLastName)}</span>
            <p className={cx('dateTime')}>{convertToRelativeTime(data?.createdAt)}</p>
          </div>
        </div>
        {renderContentMessage()}
      </div>
    </div>
  );
};

export default ConversationMessage;
