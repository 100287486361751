// Libs
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Controller, FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useState } from 'react';
// Components, Layouts, Pages
import { BaseButton, BaseInput, BaseSelect, FieldClientContact, PlacesAutocomplete, RadioCrm } from '~/components';
// Others
import { ButtonTypeEnum, ContactFormEnum, ReferralRoleEnum, StatusEnum } from '~/utils/enum';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_LIMIT_MAX_ITEM,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  NAVIGATE_BACK,
  RegExp,
  selectContactOption,
} from '~/utils/constants/common';
import { contactSchema, initialValues } from '../helper';
import {
  IClientContactDetail,
  IFormContact,
  IListQueryParamsContact,
  IPayloadUpdateContact,
} from '~/utils/interface/crm/contact';
import { IAddress, IBaseOption } from '~/utils/interface/common';
import { formatPhoneNumber, getUserName, removeEmptyObjects } from '~/utils/helper';
import { useAppDispatch } from '~/redux/hooks';
import { createContact, getDetailContact, getListContact, updateContact } from '~/thunks/crm/contact/contactThunk';
import { LoadingData } from '~/context';
// Styles, images, icons
import styles from './FormContact.module.scss';

type Props = {
  contactId?: string;
  clientId?: string;
};

const cx = classNames.bind(styles);

const FormContact = (props: Props) => {
  //#region Destructuring Props
  const { contactId, clientId } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);
  const {
    control,
    handleSubmit,
    watch,
    trigger,
    setValue,
    reset,
    formState: { errors, isDirty },
  } = useForm<IFormContact>({
    resolver: yupResolver(contactSchema(t)),
    defaultValues: initialValues,
  });

  const addType = watch('addType');
  const type = watch('type');
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [existingContact, setExistingContact] = useState<IBaseOption[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!contactId) return;

    handleGetDetailContact(contactId);
  }, [contactId]);

  useEffect(() => {
    if (contactId) return;

    if (addType === ContactFormEnum.SELECT_EXISTING && existingContact.length === DEFAULT_NUMBER_ZERO) {
      handleGetExistingContact();
    }

    reset((prevState) => ({
      addType: addType,
      type: addType === ContactFormEnum.SELECT_EXISTING ? EMPTY_STRING : ReferralRoleEnum.INDIVIDUAL,
      clients: prevState.clients,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addType]);

  useEffect(() => {
    if (contactId) return;

    if (type === ReferralRoleEnum.INDIVIDUAL) {
      setValue('organization', EMPTY_STRING, {
        shouldDirty: true,
      });
    }

    if (type === ReferralRoleEnum.ORGANIZATION) {
      setValue('firstName', EMPTY_STRING, {
        shouldDirty: true,
      });
      setValue('lastName', EMPTY_STRING, {
        shouldDirty: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleSubmitForm = (data: IFormContact) => {
    if (contactId) {
      handleEditContact(data);
    } else {
      handleCreateContact(data);
    }
  };

  const handleCreateContact = (data: IFormContact) => {
    const newData: IFormContact = {
      ...data,
      homePhone: data?.homePhone?.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING),
      mobilePhone: data?.mobilePhone?.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING),
      workPhone: data?.homePhone?.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING),
      faxNumber: data?.faxNumber?.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING),
    };
    const dataClean = removeEmptyObjects(newData) as IFormContact;

    loading?.show();
    dispatch(createContact(dataClean))
      .unwrap()
      .then((res) => {
        handleGoBack();
      })
      .catch((err) => {})
      .finally(() => loading?.hide());
  };

  const handleEditContact = (data: IFormContact) => {
    if (!contactId) return;

    loading?.show();

    const { firstName, lastName, organization, ...restData } = data;
    const newData: IFormContact = {
      ...restData,
      ...(type === ReferralRoleEnum.INDIVIDUAL
        ? { firstName: firstName, lastName: lastName }
        : { organization: organization }),
      ...(data?.homePhone && { homePhone: data?.homePhone?.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING) }),
      ...(data?.mobilePhone && { mobilePhone: data?.mobilePhone?.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING) }),
      ...(data?.workPhone && { workPhone: data?.workPhone?.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING) }),
      ...(data?.faxNumber && { faxNumber: data?.faxNumber?.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING) }),
    };

    const payload: IPayloadUpdateContact = {
      contactId: contactId,
      body: newData,
    };

    dispatch(updateContact(payload))
      .unwrap()
      .then((res) => {
        handleGoBack();
      })
      .catch((error) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const handleGetDetailContact = (id: string) => {
    loading?.show();
    dispatch(getDetailContact(id))
      .unwrap()
      .then((res) => {
        if (!res || !res.data) return;
        const data = res.data;

        const dataUpdate = {
          id: data.id,
          type: data.type || EMPTY_STRING,
          addType: ContactFormEnum.ADD_NEW,
          organization: data.organization || EMPTY_STRING,
          firstName: data.firstName || EMPTY_STRING,
          lastName: data.lastName || EMPTY_STRING,
          address: data.address || EMPTY_STRING,
          secondaryAddress: data.secondaryAddress || EMPTY_STRING,
          city: data.city || EMPTY_STRING,
          state: data.state || EMPTY_STRING,
          postalCode: data.postalCode || EMPTY_STRING,
          ssn: data.ssn || EMPTY_STRING,
          homePhone: data.homePhone || EMPTY_STRING,
          workPhone: data.workPhone || EMPTY_STRING,
          mobilePhone: data.mobilePhone || EMPTY_STRING,
          faxNumber: data.faxNumber || EMPTY_STRING,
          email: data.email || EMPTY_STRING,
          clients:
            data.clients?.map((client: IClientContactDetail) => ({
              id: client.id,
              relationship: client.relationship || EMPTY_STRING,
              permissionClient: client.permissionClient || [],
            })) || [],
        };

        reset(dataUpdate);
      })
      .catch((err) => {})
      .finally(() => loading?.hide());
  };

  const handleGetExistingContact = () => {
    const params: IListQueryParamsContact = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
      status: StatusEnum.ACTIVE,
    };
    dispatch(getListContact(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        const { responses } = res.data;
        const optionExistingContact: IBaseOption[] = responses.map((res) => {
          return {
            label: res.organization || getUserName(res.firstName, res.lastName),
            value: res.id,
          };
        });

        setExistingContact(optionExistingContact);
      })
      .catch((err) => {})
      .finally(() => {});
  };

  const handleChangeAddress = (address: IAddress) => {
    const fieldsToUpdate = [
      { key: 'address', value: address?.address || EMPTY_STRING },
      { key: 'city', value: address?.city || EMPTY_STRING },
      { key: 'state', value: address?.state || EMPTY_STRING },
      { key: 'postalCode', value: address?.zipCode || EMPTY_STRING },
    ] as const;

    fieldsToUpdate.forEach(({ key, value }) => {
      if (value) {
        setValue(key, value, { shouldDirty: true });
      }
    });

    setValue('lat', Number(address?.lat));
    setValue('lng', Number(address?.lng));
  };

  const handleGoBack = () => {
    navigate(NAVIGATE_BACK);
  };
  //#endregion Handle Function

  return (
    <FormProvider
      {...({
        control,
        watch,
        setValue,
        trigger,
        reset,
        formState: { errors },
      } as UseFormReturn<IFormContact>)}
    >
      <form id='formContact' className={cx('formContact')} onSubmit={handleSubmit(handleSubmitForm)}>
        <div className={cx('formBody')}>
          {clientId && !contactId && (
            <div className={cx('twoCol')}>
              <Controller
                control={control}
                name='addType'
                render={({ field: { value, onChange } }) => (
                  <BaseSelect
                    label={t('form_contact_select_contact_label')}
                    placeholder={t('common_select_placeholder')}
                    options={selectContactOption || []}
                    value={value || ContactFormEnum.ADD_NEW}
                    onChange={({ value }) => onChange(value)}
                    mode='search'
                  />
                )}
              />

              {addType === ContactFormEnum.SELECT_EXISTING && (
                <Controller
                  control={control}
                  name='id'
                  render={({ field: { value, onChange } }) => (
                    <BaseSelect
                      label={t('form_contact_existing_contact_label')}
                      placeholder={t('common_select_placeholder')}
                      options={existingContact || []}
                      value={value || EMPTY_STRING}
                      onChange={({ value }) => onChange(value)}
                      errorMessage={errors.id?.message}
                      required
                      mode='search'
                    />
                  )}
                />
              )}
            </div>
          )}

          {(!addType || addType === ContactFormEnum.ADD_NEW || contactId) && (
            <>
              {!contactId && (
                <Controller
                  control={control}
                  name='type'
                  render={({ field: { value, onChange } }) => (
                    <div className={cx('referralRoleWrap')}>
                      <RadioCrm
                        fontSize={12}
                        labelClassName={cx('radioLabel')}
                        id='individual'
                        name='type'
                        value={ReferralRoleEnum.INDIVIDUAL}
                        checked={value === ReferralRoleEnum.INDIVIDUAL}
                        onChange={onChange}
                        label={t('form_initial_call_referral_section_individual_label')}
                      />

                      <RadioCrm
                        fontSize={12}
                        labelClassName={cx('radioLabel')}
                        id='organization'
                        name='type'
                        value={ReferralRoleEnum.ORGANIZATION}
                        checked={value === ReferralRoleEnum.ORGANIZATION}
                        onChange={onChange}
                        label={t('form_initial_call_referral_section_organization_label')}
                      />
                    </div>
                  )}
                />
              )}

              {type === ReferralRoleEnum.INDIVIDUAL ? (
                <div className={cx('twoCol')}>
                  <Controller
                    control={control}
                    name='firstName'
                    render={({ field: { value, onChange } }) => (
                      <BaseInput
                        id='firstName'
                        label={t('form_contact_first_name_label')}
                        value={value || EMPTY_STRING}
                        onChange={onChange}
                        required
                        messageError={errors.firstName?.message}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name='lastName'
                    render={({ field: { value, onChange } }) => (
                      <BaseInput
                        id='lastName'
                        label={t('form_contact_last_name_label')}
                        value={value || EMPTY_STRING}
                        onChange={onChange}
                        required
                        messageError={errors.lastName?.message}
                      />
                    )}
                  />
                </div>
              ) : (
                <Controller
                  control={control}
                  name='organization'
                  render={({ field: { value, onChange } }) => (
                    <BaseInput
                      id='organization'
                      label={t('form_contact_organization_label')}
                      value={value || EMPTY_STRING}
                      onChange={onChange}
                      required
                      messageError={errors.organization?.message}
                    />
                  )}
                />
              )}

              <div className={cx('twoCol')}>
                <Controller
                  name='address'
                  control={control}
                  render={({ field: { value } }) => (
                    <PlacesAutocomplete
                      id='address'
                      value={value || EMPTY_STRING}
                      label={t('form_contact_address_label')}
                      onChange={(address) => handleChangeAddress(address)}
                      messageError={errors?.address?.message}
                    />
                  )}
                />

                <Controller
                  name='secondaryAddress'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <BaseInput
                      id='secondaryAddress'
                      value={value || EMPTY_STRING}
                      label={t('form_contact_secondary_address_label')}
                      onChange={onChange}
                      messageError={errors?.secondaryAddress?.message}
                    />
                  )}
                />
              </div>

              <div className={cx('threeCol')}>
                <Controller
                  name='city'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <BaseInput
                      id='city'
                      value={value || EMPTY_STRING}
                      label={t('form_contact_city_label')}
                      onChange={onChange}
                      messageError={errors?.city?.message}
                    />
                  )}
                />

                <Controller
                  name='state'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <BaseInput
                      id='state'
                      value={value || EMPTY_STRING}
                      label={t('form_contact_state_label')}
                      onChange={onChange}
                      messageError={errors?.state?.message}
                    />
                  )}
                />

                <Controller
                  name='postalCode'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <BaseInput
                      id='postalCode'
                      value={value || EMPTY_STRING}
                      label={t('form_contact_postal_code_label')}
                      onChange={onChange}
                      messageError={errors?.postalCode?.message}
                    />
                  )}
                />
              </div>

              <Controller
                name='ssn'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='ssn'
                    value={value || EMPTY_STRING}
                    label={t('form_contact_ssn_label')}
                    onChange={onChange}
                    messageError={errors?.ssn?.message}
                  />
                )}
              />

              <div className={cx('twoCol')}>
                <Controller
                  name='homePhone'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <BaseInput
                      id='homePhone'
                      label={t('form_contact_home_phone_label')}
                      value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                      onChange={(event) => {
                        const { value } = event.target;
                        const formattedValue = formatPhoneNumber(value);
                        onChange(formattedValue);
                      }}
                      messageError={errors?.homePhone?.message}
                    />
                  )}
                />

                <Controller
                  name='mobilePhone'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <BaseInput
                      id='mobilePhone'
                      label={t('form_contact_mobile_phone_label')}
                      value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                      onChange={(event) => {
                        const { value } = event.target;
                        const formattedValue = formatPhoneNumber(value);
                        onChange(formattedValue);
                      }}
                      messageError={errors?.mobilePhone?.message}
                    />
                  )}
                />

                <Controller
                  name='workPhone'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <BaseInput
                      id='workPhone'
                      label={t('form_contact_work_phone_label')}
                      value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                      onChange={(event) => {
                        const { value } = event.target;
                        const formattedValue = formatPhoneNumber(value);
                        onChange(formattedValue);
                      }}
                      messageError={errors?.workPhone?.message}
                    />
                  )}
                />

                <Controller
                  name='faxNumber'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <BaseInput
                      id='faxNumber'
                      label={t('form_contact_fax_number_label')}
                      value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                      onChange={(event) => {
                        const { value } = event.target;
                        const formattedValue = formatPhoneNumber(value);
                        onChange(formattedValue);
                      }}
                      messageError={errors?.faxNumber?.message}
                    />
                  )}
                />
              </div>

              <Controller
                name='email'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='email'
                    label={t('form_contact_email_label')}
                    value={value || EMPTY_STRING}
                    onChange={onChange}
                    messageError={errors?.email?.message}
                  />
                )}
              />
            </>
          )}

          <FieldClientContact clientId={clientId} />
        </div>

        <div className={cx('formActions')}>
          <BaseButton text={t('common_go_back_label')} width={80} onClick={handleGoBack} type='button' />

          <BaseButton
            text={contactId ? t('common_update_label') : t('common_save_label')}
            typeStyle={ButtonTypeEnum.PRIMARY}
            width={80}
            type='submit'
            disabled={contactId ? !isDirty : false}
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default FormContact;
