import { BaseResponse, IListDataResponse } from '~/utils/interface/common';
import axiosClient from '../axiosClient';
import { urlApiReferral } from '~/utils/constants/api';
import {
  ICaregiverReferralDetail,
  IFormUpdateCaregiverReferral,
  IListCaregiverReferral,
  IQueryCaregiverReferral,
  IParamsGetCaregiverApplicant,
  IListCaregiverApplicant,
} from '~/utils/interface/caregivers/referral';
import { CONTENT_TYPE_FORM_DATA } from '~/utils/constants/common';
import { IChangeStatus } from '~/utils/interface/crm/clients';

const caregiverReferralApi = {
  getListCaregiverReferral(params: IQueryCaregiverReferral) {
    const url = `${urlApiReferral.getListReferrals}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IListCaregiverReferral[]>>>(url, { params });
  },

  createCaregiverReferral(body: FormData) {
    const url = `${urlApiReferral.createReferrals}`;
    return axiosClient.post<BaseResponse>(url, body, { headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA } });
  },

  getDetailReferrals(referralId: string) {
    const url = `${urlApiReferral.getDetailReferrals(referralId)}`;
    return axiosClient.get<BaseResponse<ICaregiverReferralDetail>>(url);
  },

  updateCaregiverReferral({ caregiverReferralId, body }: IFormUpdateCaregiverReferral) {
    const url = `${urlApiReferral.updateReferrals(caregiverReferralId)}`;
    return axiosClient.put<BaseResponse>(url, body, { headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA } });
  },

  getListCaregiverApplicantReferred({ referralId, params }: IParamsGetCaregiverApplicant) {
    const url = `${urlApiReferral.getListCaregiverApplicantReferred(referralId)}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IListCaregiverApplicant[]>>>(url, { params });
  },

  changeStatusReferrals({ referralId, body }: { referralId: string; body: IChangeStatus }) {
    const url = `${urlApiReferral.changeStatusReferrals(referralId)}`;
    return axiosClient.put<BaseResponse>(url, body);
  },
};

export default caregiverReferralApi;
