// Libs
import { createSlice } from '@reduxjs/toolkit';

// Others
import {
  createMileage,
  createVisitNote,
  getMileageList,
  getListVisitNote,
  getTaskListDailyCare,
  createExpenseCredits,
  getListExpenseCredits,
} from './dailyCareThunk';

export interface dailyCareState {
  isRefreshVisitNote: boolean;
  isFetchingListVisitNote: boolean;
  isFetchingMileage: boolean;
  isRefreshMileage: boolean;
  isFetchingListExpenseCredits: boolean;
  isRefreshExpenseCredits: boolean;
  isFetchingTaskDailyCare: boolean;
}

const initialState: dailyCareState = {
  isRefreshVisitNote: false,
  isFetchingListVisitNote: false,
  isRefreshMileage: false,
  isFetchingMileage: false,
  isRefreshExpenseCredits: false,
  isFetchingListExpenseCredits: false,
  isFetchingTaskDailyCare: false,
};

const dailyCareSlice = createSlice({
  name: 'dailyCareState',
  initialState,
  reducers: {},
  extraReducers(builder) {
    // Create Visit Note
    builder
      .addCase(createVisitNote.pending, (state) => {})
      .addCase(createVisitNote.fulfilled, (state) => {
        state.isRefreshVisitNote = true;
      })
      .addCase(createVisitNote.rejected, (state) => {});

    // Get List Visit Note
    builder
      .addCase(getListVisitNote.pending, (state) => {
        state.isFetchingListVisitNote = true;
      })
      .addCase(getListVisitNote.fulfilled, (state) => {
        state.isFetchingListVisitNote = false;
        state.isRefreshVisitNote = false;
      })
      .addCase(getListVisitNote.rejected, (state) => {
        state.isFetchingListVisitNote = false;
        state.isRefreshVisitNote = false;
      });

    // Create Mileage
    builder
      .addCase(createMileage.pending, (state) => {})
      .addCase(createMileage.fulfilled, (state) => {
        state.isRefreshMileage = true;
      })
      .addCase(createMileage.rejected, (state) => {});

    // Get List Mileage
    builder
      .addCase(getMileageList.pending, (state) => {
        state.isFetchingMileage = true;
      })
      .addCase(getMileageList.fulfilled, (state) => {
        state.isFetchingMileage = false;
        state.isRefreshMileage = false;
      })
      .addCase(getMileageList.rejected, (state) => {
        state.isFetchingMileage = false;
        state.isRefreshMileage = false;
      });

    // Create Expense Credits
    builder
      .addCase(createExpenseCredits.pending, (state) => {})
      .addCase(createExpenseCredits.fulfilled, (state) => {
        state.isRefreshExpenseCredits = true;
      })
      .addCase(createExpenseCredits.rejected, (state) => {});

    // Get List Expense Credits
    builder
      .addCase(getListExpenseCredits.pending, (state) => {
        state.isFetchingListExpenseCredits = true;
      })
      .addCase(getListExpenseCredits.fulfilled, (state) => {
        state.isFetchingListExpenseCredits = false;
        state.isRefreshExpenseCredits = false;
      })
      .addCase(getListExpenseCredits.rejected, (state) => {
        state.isRefreshExpenseCredits = false;
        state.isFetchingListExpenseCredits = false;
      });

    // Get Task List Daily Care
    builder
      .addCase(getTaskListDailyCare.pending, (state) => {
        state.isFetchingTaskDailyCare = true;
      })
      .addCase(getTaskListDailyCare.fulfilled, (state) => {
        state.isFetchingTaskDailyCare = false;
      })
      .addCase(getTaskListDailyCare.rejected, (state) => {
        state.isFetchingTaskDailyCare = false;
      });
  },
});

// Actions
export const dailyCareActions = dailyCareSlice.actions;

// Reducer
const dailyCareReducer = dailyCareSlice.reducer;
export default dailyCareReducer;
