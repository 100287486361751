import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '~/redux/store';
import { createContact, getDetailContact, changeStatusContact, getListContact, updateContact } from './contactThunk';
import { IContactDetail } from '~/utils/interface/crm/contact';

export interface ContactState {
  isRefreshListContact: boolean;
  contactDetail?: IContactDetail;
}

const initialState: ContactState = {
  isRefreshListContact: false,
};

const contactSlice = createSlice({
  name: 'contactState',
  initialState,
  reducers: {
    setRefreshList(state, action) {
      state.isRefreshListContact = action.payload;
    },
    setDataDetailContact(state, action) {
      state.contactDetail = action.payload;
    },
  },

  extraReducers(builder) {
    // Get List Contact
    builder
      .addCase(getListContact.pending, (state) => {})
      .addCase(getListContact.fulfilled, (state) => {
        state.isRefreshListContact = false;
      })
      .addCase(getListContact.rejected, (state) => {});

    // Create Contact
    builder
      .addCase(createContact.pending, (state) => {})
      .addCase(createContact.fulfilled, (state) => {})
      .addCase(createContact.rejected, (state) => {});

    // Get Contact Detail
    builder
      .addCase(getDetailContact.pending, (state) => {})
      .addCase(getDetailContact.fulfilled, (state, action) => {
        state.contactDetail = action.payload.data;
      })
      .addCase(getDetailContact.rejected, (state) => {});

    // Change Status Contact
    builder
      .addCase(changeStatusContact.pending, (state) => {})
      .addCase(changeStatusContact.fulfilled, (state) => {})
      .addCase(changeStatusContact.rejected, (state) => {});

    // Update Contact
    builder
      .addCase(updateContact.pending, (state) => {})
      .addCase(updateContact.fulfilled, (state) => {})
      .addCase(updateContact.rejected, (state) => {});
  },
});

// Actions
export const contactActions = contactSlice.actions;

// Selectors
export const selectIsRefreshContact = (state: RootState) => state.contactState.isRefreshListContact;

// Reducer
const contactReducer = contactSlice.reducer;
export default contactReducer;
