// Libs
import classNames from 'classnames/bind';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

// Components, Layouts, Pages
import { CheckboxSingle, BaseSelect } from '~/components';

// Others
import { IFormPayrollRate } from '~/utils/interface/rate';
import { useAppSelector } from '~/redux/hooks';
import { getUserName } from '~/utils/helper';
import { DOLLAR, EMPTY_STRING } from '~/utils/constants/common';
import { IBaseOption } from '~/utils/interface/common';

// Styles, images, icons
import styles from './TravelReimbursementSettings.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const TravelReimbursementSetting = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IFormPayrollRate>();
  //#endregion Declare Hook

  //#region Selector
  const { caregiverDetail } = useAppSelector((state) => state.caregiverState);
  //#endregion Selector

  //#region Declare State
  const payrollRatesHourly = useWatch({
    control,
    name: 'payRate.payrollRatesHourly',
    defaultValue: [],
  });
  const [optionTravelTimePayRate, setOptionTravelTimePayRate] = useState<IBaseOption[]>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!payrollRatesHourly) return;

    const newRateOption = payrollRatesHourly.map((data) => ({
      label: `${data.name} - ${DOLLAR}${data.price}` || EMPTY_STRING,
      value: data.id,
    }));

    setOptionTravelTimePayRate(newRateOption);
  }, [payrollRatesHourly]);
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='travelReimbursementBillingSettingsComponent' className={cx('travelReimbursementBillingSettingsComponent')}>
      <div className={cx('content')}>
        <div className={cx('groupItem')}>
          <h1 className={cx('title')}>
            {t('settings_payroll_rate_traveled_reimbursement_title', {
              userName: getUserName(caregiverDetail?.firstName, caregiverDetail?.lastName),
            })}
          </h1>

          <Controller
            name='travelRate.isDefaultTravelMileage'
            control={control}
            render={({ field: { value, onChange } }) => (
              <CheckboxSingle
                label={t('settings_payroll_rate_company_default_rate_label')}
                value={value}
                onChange={(checked: boolean, name?: string) => {
                  onChange(checked);
                }}
              />
            )}
          />
        </div>

        <div className={cx('groupItem')}>
          <h1 className={cx('title')}>
            {t('settings_payroll_rate_travel_time_title', {
              userName: getUserName(caregiverDetail?.firstName, caregiverDetail?.lastName),
            })}
          </h1>

          <Controller
            name='travelRate.travelMileageRate'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseSelect
                value={value?.toString()}
                options={optionTravelTimePayRate ?? []}
                placeholder={t('common_select_placeholder')}
                width={300}
                onChange={({ value }) => {
                  const travelPrice =
                    payrollRatesHourly && payrollRatesHourly.find((payroll) => payroll.id === value)?.price;
                  onChange(travelPrice);
                }}
              />
            )}
          />
        </div>

        <div className={cx('groupItem')}>
          <h1 className={cx('title')}>
            {t('settings_payroll_rate_holiday_rate_title', {
              userName: getUserName(caregiverDetail?.firstName, caregiverDetail?.lastName),
            })}
          </h1>

          <Controller
            name='travelRate.isHolidayRateApplied'
            control={control}
            render={({ field: { value, onChange } }) => (
              <CheckboxSingle
                label={t('settings_payroll_rate_holiday_rate_label')}
                value={value}
                onChange={(checked: boolean, name?: string) => {
                  onChange(checked);
                }}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(TravelReimbursementSetting);
