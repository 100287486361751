// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import {
  CHANGE_STATUS_INVOICE,
  CREATE_INVOICE,
  GET_DETAIL_INVOICE,
  GET_LIST_INVOICE,
  GET_LIST_INVOICE_BY_CLIENT,
  SEND_INVOICE,
} from '~/utils/constants/actionType';
import {
  IBodyChangeStatusInvoice,
  IFormInvoice,
  IPayloadListInvoiceByClient,
  IQueryParamsInvoice,
} from '~/utils/interface/invoice';
import invoiceApi from '~/apis/invoice';

export const createInvoice = createAsyncThunk(CREATE_INVOICE, async (body: IFormInvoice, { rejectWithValue }) => {
  try {
    const res = await invoiceApi.createInvoice(body);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getListInvoice = createAsyncThunk(
  GET_LIST_INVOICE,
  async (payload: IQueryParamsInvoice, { rejectWithValue }) => {
    try {
      const res = await invoiceApi.getListInvoice(payload);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDetailInvoice = createAsyncThunk(
  GET_DETAIL_INVOICE,
  async ({ id, currentDate }: { id: string; currentDate: string }, { rejectWithValue }) => {
    try {
      const res = await invoiceApi.getDetailInvoice({ id, currentDate });
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendInvoice = createAsyncThunk(SEND_INVOICE, async (body: FormData, { rejectWithValue }) => {
  try {
    const res = await invoiceApi.sendInvoice(body);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const changeStatusInvoice = createAsyncThunk(
  CHANGE_STATUS_INVOICE,
  async ({ invoiceId, body }: { invoiceId: string; body: IBodyChangeStatusInvoice }, { rejectWithValue }) => {
    try {
      const res = await invoiceApi.changeStatus({ invoiceId, body });
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListInvoiceByClient = createAsyncThunk(
  GET_LIST_INVOICE_BY_CLIENT,
  async (payload: IPayloadListInvoiceByClient, { rejectWithValue }) => {
    try {
      const res = await invoiceApi.getListInvoiceByClient(payload);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
