// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
// Components, Layouts, Pages
import {
  BaseButton,
  BasePagination,
  BaseTable,
  ConfirmModal,
  FormAddEmploymentScreeningModal,
  ModalUnderDevelopment,
  StatusLabel,
  ThreeDotTableOptions,
  ToolBar,
} from '~/components';

// Others
import { ColumnTableType, IPaginationResponse } from '~/utils/interface/common';
import { DEFAULT_CURRENT_PAGE, DEFAULT_NUMBER_RECORD_TO_FETCH, EMPTY_STRING } from '~/utils/constants/common';
import { IBackgroundReport, IQueryGetListBackgroundReport } from '~/utils/interface/backgroundReports';
import { ButtonTypeEnum, TimeFormatEnum, TranslationEnum, TypeCaregiverEnum } from '~/utils/enum';
import { convertDateToFormatTime, getUserName } from '~/utils/helper';
import { useAppDispatch } from '~/redux/hooks';
import { getListBackgroundReport } from '~/thunks/backgroundReport/backgroundReportThunk';
import { LoadingData } from '~/context';
import { RootState } from '~/redux/store';
import { deleteBackgroundReport } from '~/thunks/backgroundReport/backgroundReportThunk';

// Styles, images, icons
import styles from './CheckTab.module.scss';
import { icons } from '~/assets';

type Props = {
  typeRole: TypeCaregiverEnum;
};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction<TranslationEnum.TRANSLATION>,
  handleEdit: (data: IBackgroundReport) => void,
  handleDelete: (data: IBackgroundReport) => void
): ColumnTableType<IBackgroundReport>[] => {
  return [
    {
      key: 'lastUpdated',
      title: t('check_tab_last_updated_title'),
      render: (_, record) => {
        return (
          <>{record.updatedAt ? convertDateToFormatTime(record.updatedAt, TimeFormatEnum.MM_DD_YYYY) : EMPTY_STRING}</>
        );
      },
      width: '15%',
    },

    {
      key: 'status',
      title: t('check_tab_status_title'),
      dataIndex: 'status',
      render(value, record, index) {
        return <>{record.status ? <StatusLabel bgOpacity={0.1} label={record.status} /> : EMPTY_STRING}</>;
      },
      width: '15%',
    },

    {
      key: 'backgroundCheck',
      title: t('check_tab_background_check_title'),
      render(value, record, index) {
        return <>{record?.backgroundCheck || EMPTY_STRING}</>;
      },
      width: '40%',
    },

    {
      key: 'orderedBy',
      title: t('check_tab_ordered_by_title'),
      render(value, record, index) {
        return <>{getUserName(record?.orderedBy?.firstName, record?.orderedBy?.lastName)}</>;
      },
      width: '20%',
    },

    {
      key: 'action',
      title: t('common_text_action'),
      render(value, record, index) {
        return (
          <ThreeDotTableOptions
            data={record}
            permissions={{
              isView: false,
            }}
            onEdit={() => handleEdit(record)}
            onDelete={() => handleDelete(record)}
          />
        );
      },
      width: 50,
    },
  ];
};

const CheckTab = (props: Props) => {
  //#region Destructuring Props
  const { typeRole } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);
  const { t } = useTranslation();
  const { caregiverId, requestApplicantId } = useParams();
  //#endregion Declare Hook

  //#region Selector
  const { isRefreshBackgroundReportList } = useSelector((state: RootState) => state.backgroundReportState);
  //#endregion Selector

  //#region Declare State
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  const [isShowFormAddEmployment, setIsShowFormAddEmployment] = useState<boolean>(false);
  const [employmentScreenSelect, setEmploymentScreenSelect] = useState<IBackgroundReport | null>();
  const [listBackgroundReport, setListBackgroundReport] = useState<IBackgroundReport[]>([]);
  const [pagination, setPagination] = useState<IPaginationResponse>();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(() => Number(params?.page ?? DEFAULT_CURRENT_PAGE), [params?.page]);
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const newParams = {
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      ...(typeRole === TypeCaregiverEnum.CAREGIVER ? { accountId: caregiverId } : { applicantId: requestApplicantId }),
    };

    handleGetListBackgroundReport(newParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!isRefreshBackgroundReportList) return;
    const newParams = {
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      ...(typeRole === TypeCaregiverEnum.CAREGIVER ? { accountId: caregiverId } : { applicantId: requestApplicantId }),
    };

    handleGetListBackgroundReport(newParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshBackgroundReportList]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListBackgroundReport = (params: IQueryGetListBackgroundReport) => {
    loading?.show();

    dispatch(getListBackgroundReport(params))
      .unwrap()
      .then((res) => {
        const { pagination, responses } = res;

        setListBackgroundReport(responses);
        setPagination(pagination);
      })
      .catch(() => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const handleAddManualBackgroundReport = () => {
    setIsShowFormAddEmployment(true);
  };

  const handleCloseManualBackgroundReport = () => {
    setIsShowFormAddEmployment(false);
    setEmploymentScreenSelect(null);
  };

  const handleEditEmployment = (data: IBackgroundReport) => {
    if (!data) return;

    setEmploymentScreenSelect(data);
    setIsShowFormAddEmployment(true);
  };

  const handleShowDelete = (data: IBackgroundReport) => {
    if (!data) return;

    setEmploymentScreenSelect(data);
    setIsShowConfirmDelete(true);
  };

  const handleCloseModalConfirmDelete = () => {
    setEmploymentScreenSelect(null);
    setIsShowConfirmDelete(false);
  };

  const handleDeleteBackgroundReport = () => {
    if (!employmentScreenSelect?.id) return;
    loading?.show();

    dispatch(deleteBackgroundReport(employmentScreenSelect?.id))
      .unwrap()
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        handleCloseModalConfirmDelete();
        loading?.hide();
      });
  };

  const handlePaginationChange = (page: number) => {
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleClickUnderDevelop = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };
  //#endregion Handle Function

  return (
    <div id='checkTabComponent' className={cx('checkTabComponent')}>
      <ToolBar title={t('check_tab_employment_screening_title')} fontSize={20}>
        <BaseButton
          typeStyle={ButtonTypeEnum.PRIMARY}
          iconLeft={icons.commonIconPlus}
          text={t('check_tab_add_manual_background_report_title')}
          height={31}
          onClick={handleAddManualBackgroundReport}
        />
      </ToolBar>

      <div className={cx('content')}>
        <div className={cx('tableWrap')}>
          <BaseTable
            columns={columns(t, handleEditEmployment, handleShowDelete)}
            dataSource={listBackgroundReport}
            onClickRow={handleEditEmployment}
          />
        </div>

        <div className={cx('paginationTable')}>
          <BasePagination
            onChange={handlePaginationChange}
            defaultCurrentPage={pageSelected}
            totalItems={pagination?.totalItems}
            totalPages={pagination?.totalPages}
          />
        </div>
      </div>

      {isShowFormAddEmployment && (
        <FormAddEmploymentScreeningModal
          employment={employmentScreenSelect}
          isOpen={isShowFormAddEmployment}
          onClose={handleCloseManualBackgroundReport}
        />
      )}

      <ConfirmModal
        isOpen={isShowConfirmDelete}
        title={t('common_confirm_delete_title', {
          name: t('check_tab_background_report_title'),
        })}
        titleAction={t('common_delete_label')}
        onCancel={handleCloseModalConfirmDelete}
        onAction={handleDeleteBackgroundReport}
        type='danger'
      />

      {isShowModalUnderDevelopment && <ModalUnderDevelopment onClose={handleClickUnderDevelop} />}
    </div>
  );
};

export default CheckTab;
