// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { ComponentType, ReactNode, useEffect, useMemo, useState } from 'react';
import { Event, EventProps, Views } from 'react-big-calendar';
import moment from 'moment';
import { ReactCalendarGroupRendererProps, ReactCalendarItemRendererProps } from 'react-calendar-timeline';
// Components, Layouts, Pages
import { BaseScheduleNew, GroupTimeline, ScheduleTimeline } from '~/components';
// Others
import { TCalendarViewMode } from '~/utils/type/schedule';
import { DateFormatEnum, TimeFormatEnum } from '~/utils/enum';
import { CommonIconNext, CommonIconPrev } from '~/assets/svgComponents';
import { WHITE } from '~/utils/constants/color';
import { ITimelineEvent, ITimelineGroup, ITimelinePreparedData } from '~/utils/interface/schedule';
import { capitalizeFirstLetter } from '../helper';
import { dateControl } from '~/mockData';
// Styles, images, icons
import styles from './NewFullSchedule.module.scss';

type Props = {
  view: TCalendarViewMode;
  events?: Event[] | ITimelinePreparedData;
  monthEventItem?: ComponentType<EventProps<Event>>;
  rightHeaderComponent?: ReactNode;
  timelineEventItem?: (props: ReactCalendarItemRendererProps<ITimelineEvent>) => React.ReactNode;
  onTimeChange?: (time: string) => void;
  onModeChange?: (mode: string) => void;
};

const cx = classNames.bind(styles);

const NewFullSchedule = (props: Props) => {
  //#region Destructuring Props
  const { view, events, monthEventItem, rightHeaderComponent, timelineEventItem, onTimeChange, onModeChange } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [currentTime, setCurrentTime] = useState<string>(moment().format(DateFormatEnum.YYYY_MM_DD));
  const timeValue = useMemo(() => {
    switch (view) {
      case Views.WEEK:
        const startOfWeek = moment(currentTime).startOf('week');
        const endOfWeek = moment(currentTime).endOf('week');
        const formattedWeek = `${startOfWeek.format(TimeFormatEnum.MMMM_DD)} - ${endOfWeek.format(
          TimeFormatEnum.DD_YYYY
        )}`;
        return formattedWeek;
      case Views.DAY:
        return moment(currentTime).format(TimeFormatEnum.DDDD_MMMM_DD_YYYY);
      default:
        return moment(currentTime).format(TimeFormatEnum.MMMM_YYYY);
    }
  }, [currentTime]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    onTimeChange && onTimeChange(currentTime);
  }, [currentTime]);
  //#endregion Implement Hook

  //#region Handle Function
  const goPreviousTime = () => {
    const previousTime = moment(currentTime).subtract(1, view);
    setCurrentTime(previousTime.format(DateFormatEnum.YYYY_MM_DD));
  };

  const goNextTime = () => {
    const nextTime = moment(currentTime).add(1, view);
    setCurrentTime(nextTime.format(DateFormatEnum.YYYY_MM_DD));
  };

  const goToday = () => {
    setCurrentTime(moment().format(DateFormatEnum.YYYY_MM_DD));
  };
  //#endregion Handle Function

  return (
    <div id='dailyScheduleComponent' className={cx('dailyScheduleComponent')}>
      <div className={cx('bodyWrap')}>
        <div className={cx('toolbarWrapper')}>
          <div className={cx('toolbarLeft')}>
            <div className={cx('dateControl')}>
              <button className={cx('dateControlBtn')} onClick={goPreviousTime}>
                <CommonIconPrev strokePath={WHITE} />
              </button>
              <button className={cx('dateControlBtn')} onClick={goNextTime}>
                <CommonIconNext strokePath={WHITE} />
              </button>
            </div>
            <button className={cx('dateControlBtnToday')} onClick={goToday}>
              {t('common_text_today')}
            </button>
          </div>

          <div className={cx('label')}>{timeValue}</div>

          <div className={cx('toolbarRight')}>
            {rightHeaderComponent ?? (
              <div className={cx('modeViewCalendar')}>
                {dateControl.map((date, index) => {
                  return (
                    <button
                      key={index}
                      className={cx('btnCalendar', view === date && 'active')}
                      onClick={() => onModeChange && onModeChange(date)}
                    >
                      {capitalizeFirstLetter(date)}
                    </button>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        <div id='viewScheduleComponent' className={cx('scheduleWrap')}>
          {view === Views.MONTH && (
            <div className={cx({ 'h-[780px]': view === Views.MONTH })}>
              {
                <BaseScheduleNew
                  events={events as Event[]}
                  selectable={true}
                  toolbar={false}
                  date={currentTime}
                  view={view}
                  components={{
                    event: monthEventItem,
                  }}
                />
              }
            </div>
          )}

          {view !== Views.MONTH && (
            <ScheduleTimeline
              date={currentTime}
              view={view}
              items={(events as ITimelinePreparedData)?.event ?? []}
              groups={(events as ITimelinePreparedData)?.group ?? []}
              itemRenderer={timelineEventItem}
              groupRenderer={(props: ReactCalendarGroupRendererProps<ITimelineGroup>) => (
                <GroupTimeline {...props} groupData={props?.group} />
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NewFullSchedule;
