// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { RootState } from '~/redux/store';
import { convertToClient, getListProspect } from './prospectThunk';

export interface ProspectState {
  isRefreshProspectList: boolean;
}

const initialState: ProspectState = {
  isRefreshProspectList: false,
};

const prospectSlice = createSlice({
  name: 'prospectState',
  initialState,
  reducers: {
    setRefreshList(state, action) {
      state.isRefreshProspectList = action.payload;
    },
  },
  extraReducers(builder) {
    // Get List Prospect
    builder
      .addCase(getListProspect.pending, (state) => {})
      .addCase(getListProspect.fulfilled, (state) => {})
      .addCase(getListProspect.rejected, (state) => {});

    // Convert To Client
    builder
      .addCase(convertToClient.pending, (state) => {})
      .addCase(convertToClient.fulfilled, (state) => {})
      .addCase(convertToClient.rejected, (state) => {});
  },
});

// Actions
export const clientActions = prospectSlice.actions;

// Selectors
export const selectIsRefreshClientProspect = (state: RootState) => state.prospectState.isRefreshProspectList;

// Reducer
const prospectReducer = prospectSlice.reducer;
export default prospectReducer;
