// Libs
import classNames from 'classnames/bind';
import { TFunction } from 'i18next';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
// Components, Layouts, Pages
import { BaseTable } from '~/components';
// Others
import { BusinessTypeEnum, TranslationEnum } from '~/utils/enum';
import { ColumnTableType } from '~/utils/interface/common';
import { convertEnumToString, getUserName } from '~/utils/helper';
import { IGetVisitNotesParams, IVisitNote } from '~/utils/interface/visitNote';
import { DEFAULT_CURRENT_PAGE, DEFAULT_LIMIT_MAX_ITEM, EMPTY_STRING, MIN } from '~/utils/constants/common';
import { TBusinessType } from '~/utils/type/common';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { getListVisitNote } from '~/thunks/dailyCare/dailyCareThunk';
// Styles, images, icons
import styles from './VisitNoteTableDailyCare.module.scss';

type Props = {
  businessType: TBusinessType;
  dateSelected: string;
};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction<TranslationEnum.TRANSLATION>,
  businessType: TBusinessType
): ColumnTableType<IVisitNote>[] => {
  return [
    {
      key: 'careManager',
      title: t('caregiver_profile_daily_care_table_label_care_manager'),
      render(value, record, index) {
        return (
          <>
            {businessType === BusinessTypeEnum.CAREGIVER
              ? getUserName(record?.client?.firstName, record?.client?.lastName)
              : getUserName(record?.caregiver?.firstName, record?.caregiver?.lastName)}
          </>
        );
      },
    },
    {
      key: 'totalTime',
      title: t('caregiver_profile_daily_care_table_label_total_time'),
      render(value, record, index) {
        return <>{record?.totalTime ? `${record.totalTime} ${MIN}` : EMPTY_STRING}</>;
      },
    },
    {
      key: 'service',
      title: t('caregiver_profile_daily_care_table_label_service'),
      render(value, record, index) {
        return <>{convertEnumToString(record.service) || EMPTY_STRING}</>;
      },
    },
    {
      key: 'location',
      title: t('caregiver_profile_daily_care_table_label_location'),
      width: '20%',
      render(value, record, index) {
        return <>{convertEnumToString(record?.location) || EMPTY_STRING}</>;
      },
    },
    {
      key: 'payRate',
      title: t('caregiver_profile_daily_care_table_label_pay_rate'),
      render(value, record, index) {
        return <>{convertEnumToString(record.payRate) || EMPTY_STRING}</>;
      },
    },
  ];
};

const VisitNoteTableDailyCare = (props: Props) => {
  //#region Destructuring Props
  const { businessType, dateSelected } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { clientId, caregiverId } = useParams();
  //#endregion Declare Hook

  //#region Selector
  const { isRefreshVisitNote } = useAppSelector((state) => state.dailyCareState);
  //#endregion Selector

  //#region Declare State
  const [isExpandTable, setIsExpandTable] = useState<boolean>(false);
  const [visitNoteDailyCare, setVisitNoteDailyCare] = useState<IVisitNote[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetListVisitNote(clientId || caregiverId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateSelected, clientId, caregiverId]);

  useEffect(() => {
    if (!isRefreshVisitNote) return;

    handleGetListVisitNote(clientId || caregiverId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshVisitNote]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListVisitNote = (businessId?: string) => {
    if (!businessId) return;

    const params: IGetVisitNotesParams = {
      businessId: businessId,
      businessType: businessType,
      date: dateSelected,
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
    };

    dispatch(getListVisitNote(params))
      .unwrap()
      .then((res) => {
        if (!res.responses) return;

        setVisitNoteDailyCare(res.responses);
      })
      .catch((err) => {})
      .finally();
  };

  const handleExpandTable = () => {
    setIsExpandTable(!isExpandTable);
  };
  //#endregion Handle Function

  return (
    <div id='visitNoteTableDailyCareComponent' className={cx('container')}>
      <div className={cx('bodyWrap')}>
        <div className={cx('bodyTable')}>
          <BaseTable
            tableExpand
            title={t('caregiver_profile_daily_care_visit_note_title')}
            columns={columns(t, businessType)}
            dataSource={visitNoteDailyCare}
            isExpand={isExpandTable}
            handleExpandTable={handleExpandTable}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(VisitNoteTableDailyCare);
