// Libs
import { useCallback, useState } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
  timelineOppositeContentClasses,
} from '@mui/lab';
import { useNavigate, useParams } from 'react-router-dom';

// Components, Layouts, Pages
import {
  ModalUnderDevelopment,
  DetailActivityModal,
  BaseDrawerModal,
  EmployeeDetailModalItem,
  InfinityScroll,
  StatusLabel,
} from '~/components';

// Others;
import { EMPTY_STRING, DEFAULT_NUMBER_ONE, DEFAULT_NUMBER_ZERO, DEFAULT_LIMIT_PAGE } from '~/utils/constants/common';
import { useAppDispatch } from '~/redux/hooks';
import { convertEnumToString, convertTime, getUserName } from '~/utils/helper';
import {
  AccountRoleCodesEnum,
  ActivityDetailEMAILEnum,
  ActivityTypeEnum,
  BusinessModelEnum,
  CRMEnum,
  StatusEnum,
  StorageEnum,
  TimeFormatEnum,
} from '~/utils/enum';
import { GREEN_PEA700 } from '~/utils/constants/color';
import { IActivityByUser, IRelates } from '~/utils/interface/activity';
import {
  getListActivityByApplicant,
  getListActivityByCaregiver,
  getListActivityByClient,
  getListActivityByProspect,
} from '~/thunks/activity/activityThunk';
import {
  adminRoute,
  adminRouteAbsolute,
  staffRoute,
  staffRouteAbsolute,
  superAdminRoute,
  superAdminRouteAbsolute,
} from '~/utils/constants/route';
import { TActivityMode } from '~/utils/type/common';

// Styles, images, icons
import styles from './DetailActivities.module.scss';
import { icons } from '~/assets';

const cx = classNames.bind(styles);

type Props = {
  typeActivity?: TActivityMode;
};

const DetailActivities = (props: Props) => {
  //#region Destructuring Props
  const { typeActivity } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { clientId, caregiverId, requestApplicantId } = useParams();
  const role = localStorage.getItem(StorageEnum.ROLE);
  const navigate = useNavigate();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isShowModalViewDetails, setIsShowModalViewDetails] = useState<boolean>(false);
  const [itemActivity, setItemActivity] = useState<IActivityByUser>();
  const [isShowUnderDevelopment, setIsShowUnderDevelopment] = useState<boolean>(false);
  const [assigneeSelected, setAssigneeSelected] = useState<string>(EMPTY_STRING);
  const [isShowAssigneeDetail, setIsShowAssigneeDetail] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleGroupedActivities = (activities: IActivityByUser[]) => {
    const grouped: Record<string, typeof activities> = {};

    activities.forEach((activity) => {
      const activityDate = convertTime(activity?.createdAt, TimeFormatEnum.YYYY_MM_DD);
      if (!grouped[activityDate]) {
        grouped[activityDate] = [];
      }
      grouped[activityDate].push(activity);
    });

    return Object.entries(grouped).map(([date, activities]) => ({
      date,
      activities,
    }));
  };

  const handleToggleModalUnderDevelopment = () => {
    setIsShowUnderDevelopment(!isShowUnderDevelopment);
  };

  const renderIcon = (activity: IActivityByUser) => {
    switch (activity.businessModel) {
      case BusinessModelEnum.TASK:
        return (
          <div className={cx('iconContent')}>
            <img src={icons.commonIconNote} alt={t('common_img_alt')} className={cx('datePickerIcon')} />
          </div>
        );
      case BusinessModelEnum.EMAIL:
        return (
          <div className={cx('iconContent')}>
            <img src={icons.commonIconEmailActivities} alt={t('common_img_alt')} className={cx('datePickerIcon')} />
          </div>
        );
      case BusinessModelEnum.DOCUMENT:
        return (
          <div className={cx('iconContent')}>
            <img src={icons.commonIconDocsActivities} alt={t('common_img_alt')} className={cx('datePickerIcon')} />
          </div>
        );

      case BusinessModelEnum.STATUS:
        return (
          <div className={cx('iconContent')}>
            <img src={icons.commonIconChangeStatus} alt={t('common_img_alt')} className={cx('businessStatusIcon')} />
          </div>
        );

      case BusinessModelEnum.STAGE:
        if (
          activity.actionType === ActivityTypeEnum.CREATE_APPLICANT ||
          activity.actionType === ActivityTypeEnum.CREATE_PROSPECT
        ) {
          return (
            <div className={cx('iconContent')}>
              <img src={icons.crmIconPlus} alt={t('common_img_alt')} className={cx('iconAddProspect')} />
            </div>
          );
        }

        return (
          <div className={cx('iconContent')}>
            <img src={icons.commonIconChangeStatus} alt={t('common_img_alt')} className={cx('businessStatusIcon')} />
          </div>
        );

      case BusinessModelEnum.SHIFT_NOTE:
        return (
          <div className={cx('iconContent')}>
            <img src={icons.commonIconNoteActivity} alt={t('common_img_alt')} className={cx('businessNoteIcon')} />
          </div>
        );

      case BusinessModelEnum.SHIFT_NOTE:
        return (
          <div className={cx('iconContent')}>
            <img src={icons.commonIconNoteActivity} alt={t('common_img_alt')} className={cx('businessNoteIcon')} />
          </div>
        );

      case BusinessModelEnum.SHIFT:
        if (activity.actionType === ActivityTypeEnum.CHANGE_STATUS) {
          return (
            <div className={cx('iconContentChangeStatus')}>
              <img src={icons.commonIconChangeStatus} alt={t('common_img_alt')} className={cx('businessNoteIcon')} />
            </div>
          );
        } else if (activity.actionType === ActivityTypeEnum.CREATE_SHIFT) {
          return (
            <div className={cx('iconContent')}>
              <img src={icons.commonIconSchedule} alt={t('common_img_alt')} className={cx('businessNoteIcon')} />
            </div>
          );
        }
        return (
          <div className={cx('iconContent')}>
            <TimelineDot style={{ background: GREEN_PEA700, width: 10, height: 10 }} />
          </div>
        );

      case BusinessModelEnum.TIME_CLOCK:
        return (
          <div className={cx('iconContent')}>
            <img src={icons.commonIconSchedule} alt={t('common_img_alt')} className={cx('businessNoteIcon')} />
          </div>
        );

      case BusinessModelEnum.CAREGIVER:
        if (activity.actionType === ActivityTypeEnum.CREATE_CAREGIVER) {
          return (
            <div className={cx('iconContent')}>
              <img src={icons.crmIconPlus} alt={t('common_img_alt')} className={cx('businessNoteIcon')} />
            </div>
          );
        }

        return (
          <div className={cx('iconContent')}>
            <TimelineDot style={{ background: GREEN_PEA700, width: 10, height: 10 }} />
          </div>
        );

      case BusinessModelEnum.OPEN_SHIFT:
        return (
          <div className={cx('iconContent')}>
            <img src={icons.commonIconCalendarRepo} alt={t('common_img_alt')} className={cx('businessNoteIcon')} />
          </div>
        );

      case BusinessModelEnum.CLIENT:
        return (
          <div className={cx('iconContent')}>
            <img src={icons.crmIconPlus} alt={t('common_img_alt')} className={cx('businessNoteIcon')} />
          </div>
        );

      default:
        return (
          <div className={cx('iconContent')}>
            <TimelineDot style={{ background: GREEN_PEA700, width: 10, height: 10 }} />
          </div>
        );
    }
  };

  const renderTitle = (activityType: string) => {
    switch (activityType) {
      case ActivityTypeEnum.UPLOAD_DOCUMENT:
        return <span className={cx('contentName')}>{t('activities_upload_document_title')}</span>;

      default:
        return <div className={cx('contentName')}>{EMPTY_STRING}</div>;
    }
  };

  const renderContent = (activity: IActivityByUser) => {
    switch (activity.actionType) {
      case ActivityTypeEnum.SEND_EMAIL:
        return (
          <div className={cx('actions')}>
            <div className={cx('contentNameGroup')}>
              <span className={cx('contentName')}>
                {t('activities_sent_email_subject_title', {
                  emailSubject: activity.detail?.find((item) => item.name === ActivityDetailEMAILEnum.SUBJECT)
                    ?.newValue,
                })}
              </span>
              <span className={cx('contentCreatedBy')}>
                {t('common_by_title', {
                  name: getUserName(activity.createdBy?.firstName, activity.createdBy?.lastName),
                })}
              </span>
            </div>

            <div className={cx('details')} onClick={() => handleToggleViewDetails(activity)}>
              {t(`common_view_detail_title`)}
            </div>
          </div>
        );

      case ActivityTypeEnum.CREATE_TASK:
        return (
          <div className={cx('actions')}>
            <div className={cx('contentNameGroup')}>
              <span className={cx('contentName')}>
                {t('activities_created_task_title')}
                {activity?.isDeleted && <span className={cx('contentNameDeleted')}> {t('activity_task_deleted')}</span>}
              </span>

              <div className={cx('contentText')}>
                {t('activities_assigned_to_title')}
                <span>
                  {activity.task?.assignees?.map((assignee, index) => {
                    return (
                      <span
                        key={index}
                        onClick={() => handleViewAssigneeDetail(assignee?.id)}
                        className={cx('textLink')}
                      >
                        {getUserName(assignee?.firstName, assignee?.middleName, assignee?.lastName)}
                        {index < activity.task?.assignees?.length - DEFAULT_NUMBER_ONE && ', '}
                      </span>
                    );
                  })}
                </span>
              </div>

              {activity.task?.description && (
                <span className={cx('contentCreatedBy')}>{activity.task?.description}</span>
              )}

              <div className={cx('contentText')}>
                {t('activities_relates_to_title')}
                <span>
                  {activity.task?.relates?.map((relate, index) => {
                    return (
                      <span key={index} onClick={() => handleViewRelatesDetail(relate)} className={cx('textLink')}>
                        {getUserName(relate?.firstName, relate?.middleName, relate?.lastName)}
                        {index < activity.task?.relates?.length - DEFAULT_NUMBER_ONE && ', '}
                      </span>
                    );
                  })}
                </span>
              </div>

              <span className={cx('contentCreatedBy')}>
                {t('common_by_title', {
                  name: getUserName(activity.createdBy?.firstName, activity.createdBy?.lastName),
                })}
              </span>
            </div>

            <div className={cx('details')} onClick={() => handleToggleViewDetails(activity)}>
              {t(`common_view_detail_title`)}
            </div>
          </div>
        );

      case ActivityTypeEnum.CREATE_SHIFT_NOTE:
        return (
          <div className={cx('actions')}>
            <div className={cx('contentNameGroup')}>
              <span className={cx('contentName')}>{t('activities_note_shift_created_title')}</span>
              <span className={cx('contentCreatedBy')}>
                {t('common_by_title', {
                  name: getUserName(activity.createdBy?.firstName, activity.createdBy?.lastName),
                })}
              </span>
            </div>

            <div className={cx('details')} onClick={() => handleToggleViewDetails(activity)}>
              {t(`common_view_detail_title`)}
            </div>
          </div>
        );

      case ActivityTypeEnum.CHANGE_STATUS:
        if (activity.businessModel === BusinessModelEnum.SHIFT) {
          return (
            <div className={cx('actions')}>
              <div className={cx('contentNameGroup')}>
                <span className={cx('contentName')}>{t('activities_status_changed_shift_title')}</span>
                <span className={cx('contentCreatedBy')}>
                  {t('common_by_title', {
                    name: getUserName(activity.createdBy?.firstName, activity.createdBy?.lastName),
                  })}
                </span>
              </div>

              <div className={cx('details')} onClick={() => handleToggleViewDetails(activity)}>
                {t(`common_view_detail_title`)}
              </div>
            </div>
          );
        }

        return (
          <div className={cx('actions')}>
            <div className={cx('contentNameGroup')}>
              <span className={cx('contentName')}>{t('activities_status_changed_title')}</span>
              {/* <span className={cx('contentCreatedBy')}>
                {activity?.detail?.[0]?.newValue ? (
                  <StatusLabel
                    height={16}
                    type='text'
                    label={
                      activity?.detail?.[0]?.newValue === StatusEnum.INACTIVE
                        ? t('common_status_deactive_title')
                        : activity?.detail?.[0]?.newValue
                    }
                  />
                ) : (
                  EMPTY_STRING
                )}
              </span> */}
              <span className={cx('contentCreatedBy')}>
                {t('common_by_title', {
                  name: getUserName(activity.createdBy?.firstName, activity.createdBy?.lastName),
                })}
              </span>
            </div>

            <div className={cx('details')} onClick={() => handleToggleViewDetails(activity)}>
              {t(`common_view_detail_title`)}
            </div>
          </div>
        );

      case ActivityTypeEnum.CREATE_APPLICANT:
        return (
          <div className={cx('actions')}>
            <div className={cx('contentNameGroup')}>
              <span className={cx('contentName')}>{t('activities_applicant_created_title')}</span>
              {/* <span className={cx('contentCreatedBy')}>{convertEnumToString(activity?.detail?.[0]?.newValue)}</span> */}
              <span className={cx('contentCreatedBy')}>
                {t('common_by_title', {
                  name: getUserName(activity.createdBy?.firstName, activity.createdBy?.lastName),
                })}
              </span>
            </div>

            <div className={cx('details')} onClick={() => handleToggleViewDetails(activity)}>
              {t(`common_view_detail_title`)}
            </div>
          </div>
        );

      case ActivityTypeEnum.CREATE_PROSPECT:
        return (
          <div className={cx('actions')}>
            <div className={cx('contentNameGroup')}>
              <span className={cx('contentName')}>{t('activities_prospect_created_title')}</span>
              {/* <span className={cx('contentCreatedBy')}>{convertEnumToString(activity?.detail?.[0]?.newValue)}</span> */}
              <span className={cx('contentCreatedBy')}>
                {t('common_by_title', {
                  name: getUserName(activity.createdBy?.firstName, activity.createdBy?.lastName),
                })}
              </span>
            </div>

            <div className={cx('details')} onClick={() => handleToggleViewDetails(activity)}>
              {t(`common_view_detail_title`)}
            </div>
          </div>
        );

      case ActivityTypeEnum.CHANGE_STAGE:
        return (
          <div className={cx('actions')}>
            <div className={cx('contentNameGroup')}>
              <span className={cx('contentName')}>{t('activities_stage_changed_title')}</span>
              {/* <span className={cx('contentCreatedBy')}>{convertEnumToString(activity?.detail?.[0]?.newValue)}</span> */}
              <span className={cx('contentCreatedBy')}>
                {t('common_by_title', {
                  name: getUserName(activity.createdBy?.firstName, activity.createdBy?.lastName),
                })}
              </span>
            </div>

            <div className={cx('details')} onClick={() => handleToggleViewDetails(activity)}>
              {t(`common_view_detail_title`)}
            </div>
          </div>
        );

      case ActivityTypeEnum.CREATE_SHIFT:
        return (
          <>
            <div className={cx('actions')}>
              <div className={cx('contentNameGroup')}>
                <span className={cx('contentName')}>{t('activities_created_shift_title')}</span>
                <span className={cx('contentCreatedBy')}>
                  {t('common_by_title', {
                    name: getUserName(activity.createdBy?.firstName, activity.createdBy?.lastName),
                  })}
                </span>
              </div>

              <div className={cx('details')} onClick={() => handleToggleViewDetails(activity)}>
                {t(`common_view_detail_title`)}
              </div>
            </div>
          </>
        );

      case ActivityTypeEnum.CREATE_CLIENT:
        return (
          <>
            <div className={cx('actions')}>
              <div className={cx('contentNameGroup')}>
                <span className={cx('contentName')}>{t('activities_created_client_title')}</span>
                <span className={cx('contentCreatedBy')}>
                  {t('common_by_title', {
                    name: getUserName(activity.createdBy?.firstName, activity.createdBy?.lastName),
                  })}
                </span>
              </div>

              <div className={cx('details')} onClick={() => handleToggleViewDetails(activity)}>
                {t(`common_view_detail_title`)}
              </div>
            </div>
          </>
        );

      case ActivityTypeEnum.CREATE_CAREGIVER:
        return (
          <>
            <div className={cx('actions')}>
              <div className={cx('contentNameGroup')}>
                <span className={cx('contentName')}>{t('activities_created_caregiver_title')}</span>
                <span className={cx('contentCreatedBy')}>
                  {t('common_by_title', {
                    name: getUserName(activity.createdBy?.firstName, activity.createdBy?.lastName),
                  })}
                </span>
              </div>

              <div className={cx('details')} onClick={() => handleToggleViewDetails(activity)}>
                {t(`common_view_detail_title`)}
              </div>
            </div>
          </>
        );

      case ActivityTypeEnum.CHECK_OUT:
        return (
          <>
            <div className={cx('actions')}>
              <div className={cx('contentNameGroup')}>
                <span className={cx('contentName')}>{t('activities_check_out_title')}</span>
                <span className={cx('contentCreatedBy')}>
                  {t('common_by_title', {
                    name: getUserName(activity.createdBy?.firstName, activity.createdBy?.lastName),
                  })}
                </span>
              </div>

              <div className={cx('details')} onClick={() => handleToggleViewDetails(activity)}>
                {t(`common_view_detail_title`)}
              </div>
            </div>
          </>
        );

      case ActivityTypeEnum.CHECK_IN:
        return (
          <>
            <div className={cx('actions')}>
              <div className={cx('contentNameGroup')}>
                <span className={cx('contentName')}>{t('activities_check_in_title')}</span>
                <span className={cx('contentCreatedBy')}>
                  {t('common_by_title', {
                    name: getUserName(activity.createdBy?.firstName, activity.createdBy?.lastName),
                  })}
                </span>
              </div>

              <div className={cx('details')} onClick={() => handleToggleViewDetails(activity)}>
                {t(`common_view_detail_title`)}
              </div>
            </div>
          </>
        );

      case ActivityTypeEnum.CREATE_OPEN_SHIFT:
        return (
          <>
            <div className={cx('actions')}>
              <div className={cx('contentNameGroup')}>
                <span className={cx('contentName')}>{t('activities_create_open_shift_title')}</span>
                <span className={cx('contentCreatedBy')}>
                  {t('common_by_title', {
                    name: getUserName(activity.createdBy?.firstName, activity.createdBy?.lastName),
                  })}
                </span>
              </div>

              <div className={cx('details')} onClick={() => handleToggleViewDetails(activity)}>
                {t(`common_view_detail_title`)}
              </div>
            </div>
          </>
        );

      case ActivityTypeEnum.APPROVED_OPEN_SHIFT:
        return (
          <>
            <div className={cx('actions')}>
              <div className={cx('contentNameGroup')}>
                <span className={cx('contentName')}>{t('activities_accepted_shift_title')}</span>
                <span className={cx('contentCreatedBy')}>
                  {t('common_by_title', {
                    name: getUserName(activity.createdBy?.firstName, activity.createdBy?.lastName),
                  })}
                </span>
              </div>

              <div className={cx('details')} onClick={() => handleToggleViewDetails(activity)}>
                {t(`common_view_detail_title`)}
              </div>
            </div>
          </>
        );

      case ActivityTypeEnum.DECLINED_OPEN_SHIFT:
        return (
          <>
            <div className={cx('actions')}>
              <div className={cx('contentNameGroup')}>
                <span className={cx('contentName')}>{t('activities_declined_shift_title')}</span>
                <span className={cx('contentCreatedBy')}>
                  {t('common_by_title', {
                    name: getUserName(activity.createdBy?.firstName, activity.createdBy?.lastName),
                  })}
                </span>
              </div>

              <div className={cx('details')} onClick={() => handleToggleViewDetails(activity)}>
                {t(`common_view_detail_title`)}
              </div>
            </div>
          </>
        );

      default:
        return (
          <>
            <div className={cx('actions')}>
              <div className={cx('contentNameGroup')}>
                {renderTitle(activity.actionType)}
                <span className={cx('contentCreatedBy')}>
                  {t('common_by_title', {
                    name: getUserName(activity.createdBy?.firstName, activity.createdBy?.lastName),
                  })}
                </span>
              </div>

              <div className={cx('details')} onClick={() => handleToggleViewDetails(activity)}>
                {t(`common_view_detail_title`)}
              </div>
            </div>
          </>
        );
    }
  };

  const handleToggleViewDetails = (item?: IActivityByUser) => {
    setIsShowModalViewDetails(true);
    setItemActivity(item);
  };

  const handleCloseViewDetail = () => {
    setIsShowModalViewDetails(false);
  };

  const handleCloseAssigneeDetail = () => {
    setIsShowAssigneeDetail(false);
    setAssigneeSelected(EMPTY_STRING);
  };

  const handleViewAssigneeDetail = (id: string) => {
    if (!id) return;

    setIsShowAssigneeDetail(true);
    setAssigneeSelected(id);
  };

  const handleViewRelatesDetail = (data: IRelates) => {
    if (!data || !role) return;

    if (data?.userType === CRMEnum.CLIENT) {
      switch (role) {
        case AccountRoleCodesEnum.SUPER_ADMIN:
          navigate(`${superAdminRouteAbsolute.crmClient}/${data?.id}`);
          return;
        case AccountRoleCodesEnum.ADMIN:
          navigate(`${adminRouteAbsolute.crmClient}/${data?.id}`);
          return;

        case AccountRoleCodesEnum.EMPLOYEE:
          navigate(`${staffRouteAbsolute.crmClient}/${data?.id}`);
          return;

        default:
          return;
      }
    }

    if (data?.userType === CRMEnum.PROSPECT) {
      switch (role) {
        case AccountRoleCodesEnum.SUPER_ADMIN:
          navigate(`${superAdminRouteAbsolute.crmProspect}/${data?.id}`);
          return;
        case AccountRoleCodesEnum.ADMIN:
          navigate(`${adminRouteAbsolute.crmProspect}/${data?.id}`);
          return;

        case AccountRoleCodesEnum.EMPLOYEE:
          navigate(`${staffRouteAbsolute.crmProspect}/${data?.id}`);
          return;

        default:
          return;
      }
    }

    if (data?.userType === CRMEnum.REFERRAL) {
      switch (role) {
        case AccountRoleCodesEnum.SUPER_ADMIN:
          navigate(`${superAdminRouteAbsolute.crmReferralSource}/${data?.id}`);
          return;
        case AccountRoleCodesEnum.ADMIN:
          navigate(`${adminRouteAbsolute.crmReferralSource}/${data?.id}`);
          return;

        case AccountRoleCodesEnum.EMPLOYEE:
          navigate(`${staffRouteAbsolute.crmReferralSource}/${data?.id}`);
          return;

        default:
          return;
      }
    }

    if (data?.userType === CRMEnum.USER) {
      setIsShowAssigneeDetail(true);
      setAssigneeSelected(data.id);
    }
  };

  const handleEditInfo = (id: string) => {
    if (!id) return;
    setIsShowAssigneeDetail(false);

    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRoute.base}${superAdminRoute.employees}${superAdminRoute.editEmployees}/${id}`);
        break;

      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRoute.base}${adminRoute.employees}${adminRoute.editEmployees}/${id}`);
        break;

      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(`${staffRoute.base}${staffRoute.employees}${staffRoute.editEmployees}/${id}`);
        break;
    }
  };

  const fetchData = useCallback(async (page: number) => {
    try {
      let listActivities;

      if (typeActivity !== 'prospect' && clientId) {
        listActivities = await dispatch(
          getListActivityByClient({
            userId: clientId,
            params: {
              page: page,
              limit: DEFAULT_LIMIT_PAGE,
            },
          })
        ).unwrap();
      }

      if (caregiverId) {
        listActivities = await dispatch(
          getListActivityByCaregiver({
            userId: caregiverId,
            params: {
              page: page,
              limit: DEFAULT_LIMIT_PAGE,
            },
          })
        ).unwrap();
      }

      if (requestApplicantId) {
        listActivities = await dispatch(
          getListActivityByApplicant({
            userId: requestApplicantId,
            params: {
              page: page,
              limit: DEFAULT_LIMIT_PAGE,
            },
          })
        ).unwrap();
      }

      if (typeActivity === 'prospect' && clientId) {
        listActivities = await dispatch(
          getListActivityByProspect({
            userId: clientId,
            params: {
              page: page,
              limit: DEFAULT_LIMIT_PAGE,
            },
          })
        ).unwrap();
      }

      if (listActivities) {
        return {
          data: listActivities?.data.responses,
          hasMore: listActivities?.data?.pagination?.page < listActivities?.data?.pagination?.totalPages || false,
        };
      } else {
        return {
          data: [],
          hasMore: false,
        };
      }
    } catch (error) {
      return {
        data: [],
        hasMore: false,
      };
    }
  }, []);
  //#endregion Handle Function

  return (
    <div id='detailActivitiesComponent' className={cx('container')}>
      <InfinityScroll fetchData={fetchData}>
        {({ data }) => {
          const groupedActivities = handleGroupedActivities(data);
          return (
            <>
              <Timeline
                sx={{
                  [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0.1,
                  },
                }}
                className={cx('containerContent')}
              >
                {groupedActivities && groupedActivities.length > DEFAULT_NUMBER_ZERO ? (
                  <>
                    {groupedActivities.map(({ date, activities }) => (
                      <div key={date}>
                        <TimelineItem>
                          <TimelineOppositeContent>
                            <div className={cx('containerDay')}>{convertTime(date, TimeFormatEnum.MM_DD_YYYY)}</div>
                          </TimelineOppositeContent>
                          <TimelineSeparator className={cx('timelineSeparator')}>
                            <TimelineDot style={{ background: GREEN_PEA700 }} />
                            <TimelineConnector
                              className={cx('timelineConnector')}
                              style={{ background: GREEN_PEA700, width: DEFAULT_NUMBER_ONE }}
                            />
                          </TimelineSeparator>
                          <TimelineContent></TimelineContent>
                        </TimelineItem>

                        {activities.map((activity, index) => (
                          <TimelineItem key={activity.id}>
                            <TimelineOppositeContent>
                              <div className={cx('contentDate')}>
                                {convertTime(activity.createdAt, TimeFormatEnum.HOUR_MINUTE_AM_PM)}
                              </div>
                            </TimelineOppositeContent>

                            <TimelineSeparator>
                              {renderIcon(activity)}

                              {index !== activities.length - DEFAULT_NUMBER_ONE && (
                                <TimelineConnector
                                  className={cx('timelineConnector')}
                                  style={{ background: GREEN_PEA700, width: DEFAULT_NUMBER_ONE }}
                                />
                              )}
                            </TimelineSeparator>

                            <TimelineContent>{renderContent(activity)}</TimelineContent>
                          </TimelineItem>
                        ))}
                      </div>
                    ))}
                  </>
                ) : (
                  <div className={cx('noDataAvailable')}>{t('common_empty_data')}</div>
                )}
              </Timeline>
            </>
          );
        }}
      </InfinityScroll>
      {isShowModalViewDetails && itemActivity && (
        <DetailActivityModal
          detailActivity={itemActivity}
          isOpen={isShowModalViewDetails}
          onClose={handleCloseViewDetail}
        />
      )}
      {isShowUnderDevelopment && <ModalUnderDevelopment onClose={handleToggleModalUnderDevelopment} />}

      <BaseDrawerModal isOpen={isShowAssigneeDetail} onClose={handleCloseAssigneeDetail}>
        <EmployeeDetailModalItem accountId={assigneeSelected} onEditInfo={handleEditInfo} />
      </BaseDrawerModal>
    </div>
  );
};

export default DetailActivities;
