// Libs
import classNames from 'classnames/bind';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
// Components, Layouts, Pages
import { Modal, BaseSelect, BaseButton, Loading } from '~/components';
// Others
import { ButtonTypeEnum, CLientStatusEnum } from '~/utils/enum';
import { IChangeStatus, IListClient } from '~/utils/interface/crm/clients';
import { IBaseOption } from '~/utils/interface/common';
// Styles, images, icons
import styles from './ModalChangeStatus.module.scss';

type Props = {
  dataDefault: string;
  dataOption: IBaseOption[];
  onSubmit: (status: string) => void;
  isOpen: boolean;
  onClose: () => void;
  isLoading?: boolean;
};

const cx = classNames.bind(styles);

const schema = yup.object().shape({
  status: yup.string().optional(),
});

const defaultValues: IChangeStatus = {
  status: CLientStatusEnum.ACTIVE,
};

const ModalChangeStatus = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, onSubmit, onClose, dataDefault, dataOption, isLoading } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<IChangeStatus>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (isOpen && dataDefault) {
      reset({
        status: dataDefault,
      });
    }
  }, [isOpen, dataDefault]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleClose = () => {
    reset();
    onClose();
  };

  const handleSaveStatus = (data: IChangeStatus) => {
    if (!data.status) return;

    onSubmit(data.status);
  };
  //#endregion Handle Function

  return (
    <div id='modalChangeStatus' className={cx('container')}>
      <Modal width={450} isOpen={isOpen} onClose={handleClose} title={t('common_action_change_status')}>
        <form className={cx('formContainer')} onSubmit={handleSubmit(handleSaveStatus)}>
          <div className={cx('contentModal')}>
            <Controller
              name='status'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseSelect
                  value={value}
                  options={dataOption || []}
                  placeholder={t('common_select_placeholder')}
                  onChange={({ value }) => {
                    onChange(value);
                  }}
                />
              )}
            />
          </div>

          <div className={cx('footerModal')}>
            <BaseButton type='button' text={t('common_cancel_label')} onClick={handleClose} />
            <BaseButton
              type='submit'
              text={t('common_save_label')}
              typeStyle={ButtonTypeEnum.PRIMARY}
              width={80}
              disabled={!isDirty}
            />
          </div>
        </form>
        {isLoading && <Loading />}
      </Modal>
    </div>
  );
};

export default ModalChangeStatus;
