// Libs
import classNames from 'classnames/bind';
import { useParams } from 'react-router-dom';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Event } from 'react-big-calendar';
import { Tooltip } from 'react-tooltip';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import {
  EventScheduleModal,
  NewEventPopup,
  NewMySchedule,
  NewScheduleAssignee,
  ReactPortal,
  SummaryEventTab,
} from '~/components';
// Others
import { LoadingData } from '~/context';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { TCalendarViewMode } from '~/utils/type/schedule';
import { DEFAULT_VIEW_MODE, eventPopupUnavailableTabs } from '~/utils/constants/common';
import { BusinessTypeScheduleEnum, DateFormatEnum, TabQueryEventEnum } from '~/utils/enum';
import { IDetailUnavailability, IParamsGetScheduleCaregiver, IScheduleCaregiver } from '~/utils/interface/schedule';
import { getDetailUnavailability, getScheduleCaregiver } from '~/thunks/schedule/scheduleThunk';
import { prepareMonthEventsDataCaregiver, prepareTimeEventsData } from './helper';
import { handleEventStatusColor } from '~/utils/helpers/common';
import { getUserName } from '~/utils/helper';
import { RootState } from '~/redux/store';
import { scheduleActions } from '~/thunks/schedule/scheduleSlice';
// Styles, images, icons
import styles from './CaregiverScheduleTab.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const EventScheduleCaregiver = (props: { event: IScheduleCaregiver; view: TCalendarViewMode; t: TFunction }) => {
  const { event, view, t } = props;
  const { startTime, endTime, data, businessType } = event;
  const formattedStartTime = moment(startTime, 'HH:mm:ss').format('h:mm A');
  const formattedEndTime = moment(endTime, 'HH:mm:ss').format('h:mm A');

  return businessType === BusinessTypeScheduleEnum.UNAVAILABILITY ? (
    <div
      id='eventContainer'
      className={cx(
        'eventContainer',
        view === 'month' && 'monthEvent',
        businessType === BusinessTypeScheduleEnum.UNAVAILABILITY && 'eventUnavailable'
      )}
      style={{
        borderWidth: 1,
      }}
    >
      <div className={cx('eventWrapUnavailable')}>
        <div className={cx('eventUnavailableTitle')} data-tooltip-id={`client-${data.id}`} data-tooltip-place='left'>
          {t('caregiver_schedule_tab_event_unavailable_title')}
        </div>
        <div className={cx('eventUnavailableTime')}>
          {startTime && endTime && `${formattedStartTime} - ${formattedEndTime}`}
        </div>
      </div>

      <ReactPortal wrapperId='clientTooltip'>
        <Tooltip id={`client-${data.id}`} className={'tooltipWrap'}>
          <div className='tooltip'>{t('caregiver_schedule_tab_event_unavailable_title')}</div>
        </Tooltip>
      </ReactPortal>
    </div>
  ) : (
    <div
      id='eventContainer'
      className={cx('eventContainer', view === 'month' && 'monthEvent')}
      style={{
        borderWidth: 1,
        backgroundColor: handleEventStatusColor(data.status),
      }}
    >
      <div className={cx('eventWrap')}>
        <div className={cx('assignees')}>
          {data.client && (
            <NewScheduleAssignee
              data={[data.client]}
              eventColor={handleEventStatusColor(data.status)}
              width={32}
              height={32}
            />
          )}
        </div>

        <div className={cx('firstLastName')}>
          <div className={cx('eventName')} data-tooltip-id={`client-${data.id}`} data-tooltip-place='left'>
            {getUserName(data.client?.firstName, data.client?.lastName)}
          </div>

          <div className={cx('eventTime')}>{startTime && endTime && `${formattedStartTime} - ${formattedEndTime}`}</div>
        </div>
      </div>

      <ReactPortal wrapperId='clientTooltip'>
        <Tooltip id={`client-${data.id}`} className={'tooltipWrap'}>
          <div className='tooltip'>
            {data.client && (
              <span className='tooltipText'>{getUserName(data.client?.firstName, data.client?.lastName)}</span>
            )}
          </div>
        </Tooltip>
      </ReactPortal>
    </div>
  );
};

const CaregiverScheduleTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const { caregiverId } = useParams();
  const { isRefreshShiftSchedules } = useAppSelector((state: RootState) => state.scheduleState);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [scheduleEvents, setScheduleEvents] = useState<IScheduleCaregiver[]>([]);
  const [calendarViewMode, setCalendarViewMode] = useState<TCalendarViewMode>(DEFAULT_VIEW_MODE);
  const [currentTime, setCurrentTime] = useState<string>(moment().format(DateFormatEnum.YYYY_MM_DD));
  const [isShowEvent, setIsShowEvent] = useState<boolean>(false);
  const [dataEvent, setDataEvent] = useState<IScheduleCaregiver | null>(null);
  const [isShowEventUnavailable, setIsShowEventUnavailable] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    loading?.show();
    handleGetListScheduleCaregiver();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime, calendarViewMode]);

  useEffect(() => {
    if (!isRefreshShiftSchedules) return;

    handleGetListScheduleCaregiver();
    dispatch(scheduleActions.setRefreshShiftSchedules(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshShiftSchedules]);

  const events: Event[] = useMemo(() => {
    switch (calendarViewMode) {
      case 'month':
        const preparedEvents = prepareMonthEventsDataCaregiver(scheduleEvents);
        return preparedEvents;

      case 'week':
      case 'day':
        const preparedEventWeekDay = prepareTimeEventsData(scheduleEvents);

        return preparedEventWeekDay;
    }
  }, [scheduleEvents, calendarViewMode]);

  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListScheduleCaregiver = () => {
    if (!caregiverId) return;

    const startDate = moment(currentTime).startOf(calendarViewMode).format(DateFormatEnum.YYYY_MM_DD);
    const endDate = moment(currentTime).endOf(calendarViewMode).format(DateFormatEnum.YYYY_MM_DD);

    const params: IParamsGetScheduleCaregiver = {
      accountId: caregiverId,
      endDate: endDate,
      startDate: startDate,
    };

    dispatch(getScheduleCaregiver(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        setScheduleEvents(res.data);
      })
      .catch((_err) => {})
      .finally(() => loading?.hide());
  };

  const handleTimeChange = (timeValue: string) => {
    if (!timeValue) return;
    setCurrentTime(timeValue);
  };

  const handleCalendarViewMode = (view: TCalendarViewMode) => {
    if (!view) return;
    setCalendarViewMode(view);
    setCurrentTime(moment().format(DateFormatEnum.YYYY_MM_DD));
  };

  const handleSelectEvent = (event: IScheduleCaregiver) => {
    if (!event) return;

    if (event.businessType === BusinessTypeScheduleEnum.UNAVAILABILITY) {
      setIsShowEventUnavailable(true);
      setDataEvent(event);
      return;
    }
    setIsShowEvent(true);
    setDataEvent(event);
  };

  const handleShowEvent = () => {
    setIsShowEvent(!isShowEvent);
  };

  const handleShowEventUnavailable = () => {
    setIsShowEventUnavailable(!isShowEventUnavailable);
  };

  const handleGetDetailShift = useCallback(async (id: string) => {
    try {
      const detailShift = await dispatch(getDetailUnavailability(id)).unwrap();

      return {
        items: detailShift,
      };
    } catch (error) {
      return {
        items: null,
      };
    }
  }, []);

  const renderTabUnavailability = (data: IDetailUnavailability | null, newTab: string) => {
    if (!data) return;
    switch (newTab) {
      case TabQueryEventEnum.SUMMARY:
        return <SummaryEventTab detailUnavailability={data} />;
      // case TabQueryEventEnum.NOTES:
      //   return <NotesEventTab detailUnavailability={data} />;

      default:
        return <div className={cx('noDataAvailable')}>{t('common_empty_data')}</div>;
    }
  };
  //#endregion Handle Function

  return (
    <div id='caregiverScheduleTab' className={cx('caregiverScheduleTab')}>
      <NewMySchedule<IScheduleCaregiver>
        events={events || []}
        date={currentTime}
        onTimeChange={handleTimeChange}
        childrenEvent={(event) => <EventScheduleCaregiver event={event} view={calendarViewMode} t={t} />}
        onSelectEvent={handleSelectEvent}
        onCalendarViewMode={handleCalendarViewMode}
      />

      <NewEventPopup isOpen={isShowEvent} onClose={handleShowEvent} data={dataEvent?.data} />

      <EventScheduleModal
        isOpen={isShowEventUnavailable}
        onClose={handleShowEventUnavailable}
        id={dataEvent?.data.id}
        fetchData={handleGetDetailShift}
        headerGroup={() => {
          return <div className={cx('eventModalTitle')}>{t('caregiver_schedule_tab_event_unavailable_title')}</div>;
        }}
        tab={{
          isShowTab: true,
          itemsTab: eventPopupUnavailableTabs,
          renderTab: renderTabUnavailability,
        }}
      />
    </div>
  );
};

export default CaregiverScheduleTab;
