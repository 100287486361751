// Libs
import classNames from 'classnames/bind';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
// Components, Layouts, Pages
import { ImageCircle, ModalUnderDevelopment, MenuItem, ProfileHeader, Notifications } from '~/components';
// Others
import {
  privateAdminRoutes,
  adminRoute,
  authRouteAbsolute,
  privateStaffRoutes,
  privateCaregiverRoutes,
  staffRoute,
  caregiverRoute,
  privateSuperAdminRoutes,
  superAdminRoute,
} from '~/utils/constants/route';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { getDetailProfile } from '~/thunks/user/userThunk';
import { IUserAccount } from '~/utils/interface/user';
import { selectUserProfile, userActions } from '~/thunks/user/userSlice';
import { AccountRoleCodesEnum, ImageCircleTypeEnum, StorageEnum } from '~/utils/enum';
import { RingCentralContext } from '~/context';
// Styles, images, icons
import styles from './NavBar.module.scss';
import { icons, images } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const NavBar = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userId = localStorage.getItem(StorageEnum.USER_ID);
  const role = localStorage.getItem(StorageEnum.ROLE);
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  const userInfo: IUserAccount = useSelector(selectUserProfile);
  const isRefresh = useAppSelector((state) => state.userState.isRefresh);
  const { setIsShowWidget, setMinimizedWidget } = useContext(RingCentralContext);
  //#endregion Selector

  //#region Declare State
  const [searchValue, setSearchValue] = useState<string>('');
  const [isOpenProfileHeader, setIsOpenProfileHeader] = useState<boolean>(false);
  const [isSearchExpanded, setIsSearchExpanded] = useState<boolean>(false);
  const [isOpenModalUnderDevelop, setIsOpenModalUnderDevelop] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetProfileUser();
  }, [isRefresh, userId]);
  //#endregion Implement Hook

  //#region Handle Function
  const toggleSearch = () => {
    setIsSearchExpanded(!isSearchExpanded);
  };

  const handleLogoClick = () => {
    navigate(getRouteBaseByRole(role).base);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const handleOpenProfileHeader = () => {
    setIsOpenProfileHeader(!isOpenProfileHeader);
  };

  const handleGetProfileUser = () => {
    dispatch(getDetailProfile())
      .unwrap()
      .then((res) => {
        dispatch(userActions.setIsRefresh(false));
      })
      .catch((error) => {
        dispatch(userActions.setIsRefresh(false));
      });
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
    setIsShowWidget && setIsShowWidget(false);
    setMinimizedWidget && setMinimizedWidget(false);
    // navigate(`${authRouteAbsolute.signIn}`);
  };

  const getPrivateRoutesByRole = (role: string | null) => {
    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        return privateSuperAdminRoutes;
      case AccountRoleCodesEnum.ADMIN:
        return privateAdminRoutes;
      case AccountRoleCodesEnum.EMPLOYEE:
        return privateStaffRoutes;
      case AccountRoleCodesEnum.CAREGIVER:
        return privateCaregiverRoutes;
      default:
        return [];
    }
  };

  const getRouteBaseByRole = (role: string | null) => {
    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        return superAdminRoute;
      case AccountRoleCodesEnum.ADMIN:
        return adminRoute;
      case AccountRoleCodesEnum.EMPLOYEE:
        return staffRoute;
      case AccountRoleCodesEnum.CAREGIVER:
        return caregiverRoute;
      default:
        return { base: '/' };
    }
  };

  const handleNotification = () => {
    //TODO: Handle logic here
    setIsOpenModalUnderDevelop(true);
  };

  const handleMessage = () => {
    setIsOpenModalUnderDevelop(true);
  };

  const handleNewMessage = () => {
    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRoute.base}${superAdminRoute.messages}`);
        break;
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRoute.base}${adminRoute.messages}`);
        break;
      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(`${staffRoute.base}${staffRoute.messages}`);
        break;
      default:
        return;
    }
  };

  const handleCloseModalUnderDevelop = () => {
    setIsOpenModalUnderDevelop(!isOpenModalUnderDevelop);
  };
  //#endregion Handle Function

  return (
    <div id='navBarComponent' className={cx('container')}>
      <img
        src={images.commonLogoFull}
        className={cx('logo')}
        alt={t('common_img_text_alt')}
        onClick={handleLogoClick}
      />

      <ul className={cx('menuWrap')}>
        {/* // TODO: SonVuGRF-ABD - Create component MenuItem */}
        {getPrivateRoutesByRole(role).map((nav, index) => {
          if (!nav.name) return;

          return (
            <MenuItem
              key={index}
              menu={nav}
              isSearchExpanded={isSearchExpanded}
              depthLevel={0}
              isDropdownMenu={false}
            />
          );
        })}
      </ul>

      <div className={cx('groupActionWrap')}>
        <div className={cx('message')} onClick={handleNewMessage}>
          <img src={icons.commonIconNewMessage} alt={t('common_img_text_alt')} />
        </div>

        {/* <div className={cx('notification')} onClick={handleNotification}>
          <img src={icons.commonNotification} alt={t('common_img_text_alt')} />
        </div> */}

        <Notifications />

        <Popover>
          <PopoverButton className={cx('avatar')}>
            <ImageCircle
              type={userInfo.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
              width={40}
              height={40}
              fontSize={20}
              firstName={userInfo.firstName}
              lastName={userInfo.lastName}
              imageUrl={userInfo.avatarUrl}
              margin={'0px 10px 0px 0px'}
              onClick={handleOpenProfileHeader}
            />
          </PopoverButton>

          <PopoverPanel transition anchor={{ to: 'bottom end', gap: '8px' }} className={cx('popupContainer')}>
            {({ close }) => <ProfileHeader data={userInfo} onLogout={handleLogout} onClose={close} />}
          </PopoverPanel>
        </Popover>
      </div>

      {isOpenModalUnderDevelop && <ModalUnderDevelopment onClose={handleCloseModalUnderDevelop} />}
    </div>
  );
};

export default NavBar;
