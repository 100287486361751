// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { getAuthUrl, getListQuickBookCustomer } from './quickBookThunk';

export interface QuickBookState {}

const initialState: QuickBookState = {};

const quickBookSlice = createSlice({
  name: 'quickBookState',
  initialState,
  reducers: {},
  extraReducers(builder) {
    // Get Auth Url
    builder
      .addCase(getAuthUrl.pending, (state) => {})
      .addCase(getAuthUrl.fulfilled, (state, action) => {})
      .addCase(getAuthUrl.rejected, (state) => {});

    // Get list quick book customers
    builder
      .addCase(getListQuickBookCustomer.pending, (state) => {})
      .addCase(getListQuickBookCustomer.fulfilled, (state, action) => {})
      .addCase(getListQuickBookCustomer.rejected, (state) => {});
  },
});

export const quickBookActions = quickBookSlice.actions;

const quickBookReducer = quickBookSlice.reducer;

export default quickBookReducer;
