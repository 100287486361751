// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useContext, useEffect, useMemo, useState } from 'react';
// Components, Layouts, Pages
import {
  BaseButton,
  ClientsProspectsReferredTab,
  ImageCircle,
  RelateDeletedModal,
  Tab,
  ReferralSourceInfoTab,
  StatusLabel,
} from '~/components';
// Others
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { LoadingData } from '~/context';
import { getDetailClients } from '~/thunks/crm/clients/clientsThunk';
import {
  CODE_MESSAGE_CLIENT_DELETED,
  EMPTY_STRING,
  NAVIGATE_BACK,
  QUERY_KEY_TAB,
  referralSourceDetailTabs,
} from '~/utils/constants/common';
import {
  ButtonTypeEnum,
  CLientStatusEnum,
  ImageCircleTypeEnum,
  ReferralRoleEnum,
  TabQueryReferralSourceDetailEnum,
  ViewCLientStatusEnum,
} from '~/utils/enum';
import { convertEnumToString, getUserName } from '~/utils/helper';
import { ErrorData } from '~/utils/interface/common';
import { clientActions } from '~/thunks/crm/clients/clientsSlice';
// Styles, images, icons
import styles from './CrmReferralResourceDetail.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const CrmReferralResourceDetail = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const currentTab = searchParams.get(QUERY_KEY_TAB);
  const loading = useContext(LoadingData);
  const { clientId } = useParams();
  //#endregion Declare Hook

  //#region Selector
  const { clientDetail } = useAppSelector((state) => state.clientState);
  //#endregion Selector

  //#region Declare State
  const [isShowClientDeleted, setIsShowClientDeleted] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    return () => {
      dispatch(clientActions.resetClientDetail());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!clientId) return;

    handleGetDetailClient(clientId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleBack = () => {
    navigate(NAVIGATE_BACK);
  };

  const handleGetDetailClient = (clientId: string) => {
    loading?.show();

    dispatch(getDetailClients(clientId))
      .unwrap()
      .then((res) => {})
      .catch((error) => {
        const { code } = error?.response?.data as ErrorData;

        if (code === CODE_MESSAGE_CLIENT_DELETED) setIsShowClientDeleted(!isShowClientDeleted);
      })
      .finally(() => {
        loading?.hide();
      });
  };

  const handleShowClientDeleted = () => {
    setIsShowClientDeleted(!isShowClientDeleted);

    navigate(NAVIGATE_BACK);
  };

  const renderTabContent = useMemo(() => {
    if (!clientId) return <></>;

    const tabToRender = currentTab || TabQueryReferralSourceDetailEnum.INFO;
    switch (tabToRender) {
      case TabQueryReferralSourceDetailEnum.INFO:
        return <ReferralSourceInfoTab />;

      case TabQueryReferralSourceDetailEnum.CLIENTS_PROSPECTS_REFERRED:
        return <ClientsProspectsReferredTab clientId={clientId} />;

      default:
        // Handle Return Tab Later
        return <div className={cx('noDataAvailable')}>{t('common_empty_data')}</div>;
    }
  }, [currentTab, clientId, clientDetail, t]);
  //#endregion Handle Function

  return (
    <div id='crmReferralResourceDetailPage' className={cx('container')}>
      <div className={cx('mainBody')}>
        {clientDetail && (
          <>
            <BaseButton
              onClick={handleBack}
              text={t('common_text_back')}
              typeStyle={ButtonTypeEnum.ONLY_TEXT}
              iconLeft={icons.commonIconPrev}
              height={17}
            />

            <section className={cx('headGroup')}>
              <div className={cx('basicInfoGroup')}>
                <ImageCircle
                  type={clientDetail?.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
                  width={91}
                  height={91}
                  imageUrl={clientDetail?.avatarUrl}
                  fontSize={45}
                  firstName={clientDetail?.firstName || clientDetail.organizationName}
                  lastName={clientDetail?.lastName}
                />

                <div className={cx('basicInfo')}>
                  <div className={cx('nameGroup')}>
                    <div className={cx('fullName')}>
                      {getUserName(clientDetail?.firstName, clientDetail?.middleName, clientDetail?.lastName) ||
                        clientDetail?.organizationName}
                    </div>
                    <p className={cx('valueGroup')}>
                      {t('info_tab_title_status')}
                      <span className={cx('value')}>
                        {clientDetail?.status ? (
                          <StatusLabel
                            label={
                              clientDetail?.status === CLientStatusEnum.ACTIVE
                                ? t('common_status_active_title')
                                : clientDetail?.status === CLientStatusEnum.INACTIVE
                                ? t('common_status_deactive_title')
                                : clientDetail?.status
                            }
                            type='text'
                          />
                        ) : (
                          EMPTY_STRING
                        )}
                      </span>
                    </p>
                    {clientDetail.referralRole === ReferralRoleEnum.INDIVIDUAL && (
                      <p className={cx('valueGroup')}>
                        {t('info_tab_title_organization')}
                        <span className={cx('value')}>{clientDetail?.organization?.name}</span>
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className={cx('navTabGroup')}>
                <Tab items={referralSourceDetailTabs} paddingBottom={30} />
              </div>
            </section>

            <section className={cx('tabContent')}>{renderTabContent}</section>
          </>
        )}
      </div>

      <RelateDeletedModal isOpen={isShowClientDeleted} onClose={handleShowClientDeleted} />
    </div>
  );
};

export default CrmReferralResourceDetail;
