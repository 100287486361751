// Libs
import classNames from 'classnames/bind';
import { Controller, useWatch, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useEffect, useState } from 'react';
// Components, Layouts, Pages
import { BaseInput, BaseSelect, PlacesAutocomplete, RadioCrm } from '~/components';
// Others
import { IFormInitialCall } from '~/utils/interface/crm/initialCall';
import { CLientStatusEnum, CRMEnum, InitialCallFormEnum, ReferralRoleEnum } from '~/utils/enum';
import {
  DEFAULT_LIMIT_MAX_ITEM,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  referralTypeInitialCallOptions,
} from '~/utils/constants/common';
import { formatPhoneNumber, getUserName } from '~/utils/helper';
import { IAddress, IBaseOption } from '~/utils/interface/common';
import { IListQueryParamsClients } from '~/utils/interface/crm/clients';
import { getListClients } from '~/thunks/crm/clients/clientsThunk';
import { useAppDispatch } from '~/redux/hooks';
// Styles, images, icons
import styles from './ReferralSource.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const ReferralSourceInitialCall = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const {
    control,
    setValue,
    resetField,
    formState: { errors },
  } = useFormContext<IFormInitialCall>();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [existingReferrals, setExistingReferrals] = useState<IBaseOption[]>([]);
  const referralTypeState = useWatch({
    control,
    name: 'referralSource.addType',
  });
  const referralRoleTypeState = useWatch({
    control,
    name: 'referralSource.type',
  });
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (referralTypeState === InitialCallFormEnum.SELECT_EXISTING && existingReferrals.length === DEFAULT_NUMBER_ZERO) {
      handleGetReferralsExisting();
    }

    resetField('referralSource', {
      defaultValue: {
        addType: referralTypeState,
        type: ReferralRoleEnum.INDIVIDUAL,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralTypeState]);

  useEffect(() => {
    if (referralRoleTypeState === ReferralRoleEnum.INDIVIDUAL) {
      setValue('referralSource.organization', EMPTY_STRING, { shouldDirty: true });
    } else {
      setValue('referralSource.firstName', EMPTY_STRING, { shouldDirty: true });
      setValue('referralSource.lastName', EMPTY_STRING, { shouldDirty: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralRoleTypeState]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('referralSource.type', event.target.value);
  };

  const handleChangeAddress = (address: IAddress) => {
    setValue('referralSource.address', address?.address ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    address?.city &&
      setValue('referralSource.city', address?.city ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    address?.state &&
      setValue('referralSource.state', address?.state ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    address?.zipCode &&
      setValue('referralSource.postalCode', address?.zipCode ?? EMPTY_STRING, {
        shouldDirty: true,
        shouldValidate: true,
      });

    setValue('referralSource.lat', Number(address?.lat));
    setValue('referralSource.lng', Number(address?.lng));
  };

  const handleGetReferralsExisting = () => {
    const params: IListQueryParamsClients = {
      page: DEFAULT_NUMBER_ONE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
      type: [CRMEnum.REFERRAL],
      status: CLientStatusEnum.ACTIVE,
    };

    dispatch(getListClients(params))
      .unwrap()
      .then((res) => {
        const { responses } = res?.data;

        if (responses.length === DEFAULT_NUMBER_ZERO) return;

        const listReferer = responses?.map((data) => ({
          label: data?.organizationName || getUserName(data?.firstName, data?.lastName),
          value: data.id,
        }));
        setExistingReferrals(listReferer);
      })
      .catch((error) => {});
  };

  const renderAddNew = () => {
    return (
      <>
        {referralRoleTypeState === ReferralRoleEnum.INDIVIDUAL ? (
          <div className={cx('twoCol')}>
            <Controller
              name='referralSource.firstName'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='referralSource.firstName'
                  label={t('form_initial_call_referral_section_first_name_label')}
                  onChange={onChange}
                  value={value || EMPTY_STRING}
                  messageError={errors.referralSource?.firstName?.message}
                  required
                />
              )}
            />

            <Controller
              name='referralSource.lastName'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='referralSource.lastName'
                  label={t('form_initial_call_referral_section_last_name_label')}
                  value={value || EMPTY_STRING}
                  onChange={onChange}
                  messageError={errors.referralSource?.lastName?.message}
                  required
                />
              )}
            />
          </div>
        ) : (
          <Controller
            name='referralSource.organization'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='referralSource.organization'
                label={t('form_initial_call_referral_section_organization_label')}
                value={value || EMPTY_STRING}
                onChange={onChange}
                messageError={errors.referralSource?.organization?.message}
                required
              />
            )}
          />
        )}

        <div className={cx('threeCol')}>
          <Controller
            name='referralSource.homePhone'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='referralSource.homePhone'
                label={t('form_initial_call_referral_section_home_phone_label')}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const { value } = event.target;
                  const formattedValue = formatPhoneNumber(value);
                  onChange(formattedValue);
                }}
                value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                messageError={errors.referralSource?.homePhone?.message}
              />
            )}
          />

          <Controller
            name='referralSource.workPhone'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='referralSource.workPhone'
                label={t('form_initial_call_referral_section_work_phone_label')}
                value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const { value } = event.target;
                  const formattedValue = formatPhoneNumber(value);
                  onChange(formattedValue);
                }}
                messageError={errors.referralSource?.workPhone?.message}
              />
            )}
          />

          <Controller
            name='referralSource.mobilePhone'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='referralSource.mobilePhone'
                label={t('form_initial_call_referral_section_mobile_phone_label')}
                value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const { value } = event.target;
                  const formattedValue = formatPhoneNumber(value);
                  onChange(formattedValue);
                }}
                messageError={errors.referralSource?.mobilePhone?.message}
                required
              />
            )}
          />
        </div>

        <Controller
          name='referralSource.email'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseInput
              id='referralSource.email'
              label={t('form_initial_call_referral_section_email_label')}
              value={value || EMPTY_STRING}
              onChange={onChange}
              messageError={errors.referralSource?.email?.message}
            />
          )}
        />

        <div className={cx('twoCol')}>
          <Controller
            name='referralSource.address'
            control={control}
            render={({ field: { value, onChange } }) => (
              <PlacesAutocomplete
                id='referralSource.address'
                label={t('form_initial_call_referral_section_address_label')}
                value={value || EMPTY_STRING}
                onChange={(address) => handleChangeAddress(address)}
                messageError={errors.referralSource?.address?.message}
                required
              />
            )}
          />

          <Controller
            name='referralSource.secondaryAddress'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='referralSource.secondaryAddress'
                label={t('form_initial_call_referral_section_address_line_2_label')}
                value={value || EMPTY_STRING}
                onChange={onChange}
                messageError={errors.referralSource?.secondaryAddress?.message}
              />
            )}
          />
        </div>

        <div className={cx('threeCol')}>
          <Controller
            name='referralSource.city'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='referralSource.city'
                label={t('form_initial_call_referral_section_city_label')}
                value={value || EMPTY_STRING}
                onChange={onChange}
                messageError={errors.referralSource?.city?.message}
                required
              />
            )}
          />

          <Controller
            name='referralSource.state'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='referralSource.state'
                label={t('form_initial_call_referral_section_state_label')}
                value={value || EMPTY_STRING}
                onChange={onChange}
                messageError={errors.referralSource?.state?.message}
                required
              />
            )}
          />

          <Controller
            name='referralSource.postalCode'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='referralSource.postalCode'
                label={t('form_initial_call_referral_section_postal_code_label')}
                value={value || EMPTY_STRING}
                onChange={onChange}
                messageError={errors.referralSource?.postalCode?.message}
                required
              />
            )}
          />
        </div>
      </>
    );
  };

  const renderAddExisting = () => {
    return (
      <Controller
        name={'referralSource.id'}
        control={control}
        render={({ field: { value, onChange } }) => (
          <BaseSelect
            name='referralSource.id'
            options={existingReferrals}
            value={value}
            onChange={({ value }) => onChange(value)}
            label={t('form_initial_call_referral_section_existing_referral_label')}
            placeholder={t('common_select_placeholder')}
            mode='search'
            errorMessage={errors.referralSource?.id?.message}
            required
          />
        )}
      />
    );
  };
  //#endregion Handle Function

  return (
    <div id='referralSourceInitialCall' className={cx('referralSourceInitialCall')}>
      <div className={cx('headTitle')}>{t('form_initial_call_referral_section_title')}</div>

      <div className={cx('bodyWrap')}>
        <div className={cx('twoCol')}>
          <Controller
            name={'referralSource.addType'}
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseSelect
                name='referralSource.referralType'
                options={referralTypeInitialCallOptions}
                value={value}
                onChange={({ value }) => onChange(value)}
                label={t('form_initial_call_referral_section_referral_type_label')}
                required
                errorMessage={errors.referralSource?.addType?.message}
              />
            )}
          />

          {referralTypeState === InitialCallFormEnum.ADD_NEW && (
            <Controller
              name={'referralSource.type'}
              control={control}
              render={({ field: { value } }) => (
                <div className={cx('referralRoleWrap')}>
                  <RadioCrm
                    labelClassName={cx('radioLabel')}
                    id='individual'
                    name='referralSource.type'
                    value={ReferralRoleEnum.INDIVIDUAL}
                    checked={value === ReferralRoleEnum.INDIVIDUAL}
                    onChange={handleRadioChange}
                    label={t('form_initial_call_referral_section_individual_label')}
                  />

                  <RadioCrm
                    labelClassName={cx('radioLabel')}
                    id='organization'
                    name='referralSource.type'
                    value={ReferralRoleEnum.ORGANIZATION}
                    checked={value === ReferralRoleEnum.ORGANIZATION}
                    onChange={handleRadioChange}
                    label={t('form_initial_call_referral_section_organization_label')}
                  />
                </div>
              )}
            />
          )}

          {referralTypeState === InitialCallFormEnum.SELECT_EXISTING && <>{renderAddExisting()}</>}
        </div>

        {referralTypeState === InitialCallFormEnum.ADD_NEW && <>{renderAddNew()}</>}
      </div>
    </div>
  );
};

export default ReferralSourceInitialCall;
