// Libs
import classNames from 'classnames/bind';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm, UseFormReturn, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TFunction } from 'i18next';
// Components, Layouts, Pages
import {
  AssigneePopup,
  BaseButton,
  BaseSelect,
  BaseTextarea,
  CheckboxSingle,
  ConfirmModal,
  Loading,
  Modal,
  ModalUnderDevelopment,
  RecurrenceOfficeCalendar,
  UnavailabilityForm,
} from '~/components';
// Others
import { useAppDispatch } from '~/redux/hooks';
import {
  CODE_MESSAGE_TASK_DELETED,
  DEFAULT_CURRENT_PAGE,
  DEFAULT_LIMIT_MAX_ITEM,
  DEFAULT_NUMBER_ONE,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { ErrorData, IAddAssignee, IBaseOption, IListQueryParams } from '~/utils/interface/common';
import {
  AccountRoleCodesEnum,
  ButtonTypeEnum,
  EventStatusEnum,
  FormTaskDateTypeEnum,
  RecurrenceEnum,
} from '~/utils/enum';
import { optionsMockProjectStatus, optionsTaskType } from '~/mockData';
import { getListAssignee } from '~/thunks/user/userThunk';
import { getListRelates } from '~/thunks/crm/clients/clientsThunk';
import { IRelatesQueryParams } from '~/utils/interface/crm/clients';
import { IFormOfficeCalendar, IPayloadUpdateOfficeCalendar, IUnavailability } from '~/utils/interface/schedule';
import {
  createOfficeCalendar,
  deleteOfficeCalendar,
  getDetailOfficeCalendarEvent,
  updateOfficeCalendar,
} from '~/thunks/schedule/scheduleThunk';
import { createUnavailabilityEvent } from '~/thunks/hr/hrThunk';
import { scheduleActions } from '~/thunks/schedule/scheduleSlice';
import { removeEmptyObjects } from '~/utils/helper';
// Styles, images, icons
import styles from './FormOfficeCalendar.module.scss';

type Props = {
  scheduleId?: string;
  taskOfficeId?: string;
  isOpen: boolean;
  onClose: () => void;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup
    .object()
    .shape({
      type: yup.string().optional(),
      allDay: yup.boolean().optional(),
      startDate: yup
        .string()
        .optional()
        .when('type', {
          is: (type: string) => type === EventStatusEnum.UNAVAILABILITY,
          then: (schema) => schema.required(t('common_message_required_error')),
          otherwise: (schema) => schema.optional(),
        }),
      endDate: yup.string().when('type', {
        is: (type: string) => type === EventStatusEnum.UNAVAILABILITY,
        then: (schema) => schema.required(t('common_message_required_error')),
        otherwise: (schema) => schema.optional(),
      }),
      startTime: yup.string().when('type', {
        is: (type: string) => type === EventStatusEnum.UNAVAILABILITY,
        then: (schema) => schema.required(t('common_message_required_error')),
        otherwise: (schema) => schema.optional(),
      }),
      endTime: yup.string().when('type', {
        is: (type: string) => type === EventStatusEnum.UNAVAILABILITY,
        then: (schema) => schema.required(t('common_message_required_error')),
        otherwise: (schema) => schema.optional(),
      }),
      reason: yup.string().when('type', {
        is: (type: string) => type === EventStatusEnum.UNAVAILABILITY,
        then: (schema) => schema.required(t('common_message_required_error')),
        otherwise: (schema) => schema.optional(),
      }),
      note: yup.string().trim().optional(),

      recurrence: yup.string().when('type', {
        is: (type: string) => type === EventStatusEnum.TASK,
        then: (schema) => schema.required(t('common_message_required_error')),
        otherwise: (schema) => schema.optional(),
      }),
      repeatEvery: yup
        .number()
        .nullable()
        .min(DEFAULT_NUMBER_ONE, t('error_message_repeat_every_min_valid'))
        .typeError(t('error_message_repeat_every_number_valid'))
        .when('recurrence', {
          is: (recurrence: string) =>
            recurrence === RecurrenceEnum.DAILY ||
            recurrence === RecurrenceEnum.WEEKLY ||
            recurrence === RecurrenceEnum.MONTHLY,
          then: (schema) => schema.required(t('common_message_required_error')),
          otherwise: (schema) => schema.optional(),
        }),
      fromDate: yup
        .string()
        .nullable()
        .when('recurrence', {
          is: (recurrence: string) =>
            recurrence === RecurrenceEnum.DAILY ||
            recurrence === RecurrenceEnum.WEEKLY ||
            recurrence === RecurrenceEnum.MONTHLY,
          then: (schema) => schema.required(t('common_message_required_error')),
          otherwise: (schema) => schema.optional(),
        }),
      toDate: yup
        .string()
        .nullable()
        .when('recurrence', {
          is: (recurrence: string) =>
            recurrence === RecurrenceEnum.DAILY ||
            recurrence === RecurrenceEnum.WEEKLY ||
            recurrence === RecurrenceEnum.MONTHLY,
          then: (schema) => schema.required(t('common_message_required_error')),
          otherwise: (schema) => schema.optional(),
        }),
      repeatWeekOn: yup
        .array()
        .nullable()
        .when('recurrence', {
          is: (recurrence: string) => recurrence === RecurrenceEnum.WEEKLY,
          then: (schema) =>
            schema
              .of(yup.number())
              .min(DEFAULT_NUMBER_ONE, t('error_message_repeat_week_on_required'))
              .required(t('common_message_required_error')),
          otherwise: (schema) => schema.optional(),
        }),
      repeatMonthOn: yup
        .string()
        .nullable()
        .when('recurrence', {
          is: (recurrence: string) => recurrence === RecurrenceEnum.MONTHLY,
          then: (schema) => schema.required(t('common_message_required_error')),
          otherwise: (schema) => schema.optional(),
        }),
      endType: yup
        .string()
        .nullable()
        .when('recurrence', {
          is: (recurrence: string) =>
            recurrence === RecurrenceEnum.DAILY ||
            recurrence === RecurrenceEnum.WEEKLY ||
            recurrence === RecurrenceEnum.MONTHLY,
          then: (schema) => schema.required(t('common_message_required_error')),
          otherwise: (schema) => schema.optional(),
        }),
      untilDate: yup
        .string()
        .nullable()
        .when('endType', {
          is: (endType: string) => endType === FormTaskDateTypeEnum.UNTIL,
          then: (schema) => schema.required(t('common_message_required_error')),
          otherwise: (schema) => schema.optional(),
        }),
      dueDate: yup
        .string()
        .nullable()
        .when(['recurrence', 'type'], {
          is: (recurrence: string, type: string) => recurrence === RecurrenceEnum.NONE && type === EventStatusEnum.TASK,
          then: (schema) => schema.required(t('common_message_required_error')),
          otherwise: (schema) => schema.optional(),
        }),
      status: yup.string().optional(),
      assignee: yup.array().nullable().optional(),
      relates: yup.array().nullable().optional(),
      description: yup.string().when('type', {
        is: (type: string) => type === EventStatusEnum.TASK,
        then: (schema) => schema.required(t('common_message_required_error')),
        otherwise: (schema) => schema.optional(),
      }),
      isSendReminderEmail: yup.boolean().optional(),
    })
    .required();
};

const defaultValues: IFormOfficeCalendar = {
  type: EventStatusEnum.TASK,
  recurrence: RecurrenceEnum.NONE,
  repeatEvery: DEFAULT_NUMBER_ONE,
  fromDate: null,
  toDate: null,
  repeatWeekOn: null,
  repeatMonthOn: null,
  endType: null,
  untilDate: null,
  dueDate: null,
  status: optionsMockProjectStatus[DEFAULT_NUMBER_ONE].value,
  assignee: null,
  relates: null,
  description: EMPTY_STRING,
  isSendReminderEmail: false,
};

const FormOfficeCalendar = (props: Props) => {
  //#region Destructuring Props
  const { scheduleId, taskOfficeId, isOpen, onClose } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    trigger,
    formState: { errors, isDirty },
  } = useForm<IFormOfficeCalendar>({
    resolver: yupResolver(schema(t)),
    defaultValues: defaultValues,
  });

  const watchType = useWatch({ control: control, name: 'type' });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isDevelopment, setIsDevelopment] = useState<boolean>(false);
  const [listAssignee, setListAssignee] = useState<IAddAssignee[]>([]);
  const [listRelates, setListRelates] = useState<IAddAssignee[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTaskDeleted, setIsTaskDeleted] = useState<boolean>(false);
  const [isShowConfirmModal, setIsShowConfirmModal] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    switch (watchType) {
      case EventStatusEnum.UNAVAILABILITY:
        reset({ type: watchType });
        break;

      default:
        reset({ ...defaultValues, type: watchType });
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchType]);

  useEffect(() => {
    const payload: IListQueryParams = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
      roles: [AccountRoleCodesEnum.ADMIN, AccountRoleCodesEnum.EMPLOYEE],
    };

    const newParamObject: IRelatesQueryParams = {};

    handleGetListRelates(newParamObject);
    handleGetListAssignee(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!scheduleId) return;

    handleGetOfficeCalendarDetail(scheduleId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListAssignee = (params: IListQueryParams) => {
    dispatch(getListAssignee(params))
      .unwrap()
      .then((response) => {
        if (!response) return;

        const { responses } = response.data;
        setListAssignee(responses);
      })
      .catch((_error) => {});
  };

  const handleGetListRelates = (params: IRelatesQueryParams) => {
    dispatch(getListRelates(params))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        const newData = [...res?.data.clients, ...res?.data.users];

        setListRelates(newData);
      })
      .catch((_error) => {})
      .finally(() => {});
  };

  const handleGetOfficeCalendarDetail = (scheduleId: string) => {
    setIsLoading(true);

    dispatch(getDetailOfficeCalendarEvent(scheduleId))
      .unwrap()
      .then((_res) => {
        if (!_res) return;

        const officeCalendarDetail = _res?.data;

        reset({
          type: officeCalendarDetail.type,
          recurrence: officeCalendarDetail?.recurrence,
          repeatEvery: officeCalendarDetail?.repeatEvery,
          fromDate: officeCalendarDetail?.fromDate,
          toDate: officeCalendarDetail?.toDate,
          endType: officeCalendarDetail?.endType,
          untilDate: officeCalendarDetail?.untilDate,
          repeatWeekOn: officeCalendarDetail?.repeatWeekOn,
          repeatMonthOn: officeCalendarDetail?.repeatMonthOn,
          dueDate: officeCalendarDetail?.dueDate,
          status: officeCalendarDetail?.status,
          assignee: officeCalendarDetail?.assignees?.map((item) => item?.id),
          relates: officeCalendarDetail?.relates?.map((item) => item?.id),
          description: officeCalendarDetail?.description,
          isSendReminderEmail: officeCalendarDetail?.isSendReminderEmail,
          startDate: officeCalendarDetail.startDate,
          endDate: officeCalendarDetail.endDate,
          startTime: officeCalendarDetail.startTime,
          endTime: officeCalendarDetail.endTime,
          reason: officeCalendarDetail.reason,
          note: officeCalendarDetail.lastNote,
        });
      })
      .catch((error) => {
        const { code } = error?.response?.data as ErrorData;

        if (code === CODE_MESSAGE_TASK_DELETED) setIsTaskDeleted(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCloseDevelopment = () => {
    setIsDevelopment(!isDevelopment);
  };

  const handleSubmitForm = (data: IFormOfficeCalendar) => {
    if (watchType === EventStatusEnum.UNAVAILABILITY) {
      handleCreateUnavailabilityEvent(data);
    } else {
      handleSubmitOfficeCalendar(data);
    }
  };

  const handleSubmitOfficeCalendar = (data: IFormOfficeCalendar) => {
    if (!data) return;

    const { type, ...dataBody } = data;

    const newDataTask = {
      ...dataBody,
      relates: dataBody?.relates?.map((id) => {
        const relateItem = listRelates.find((item) => item.id === id);
        return {
          id,
          userType: relateItem?.type,
        };
      }),
    };

    if (taskOfficeId) {
      const payload: IPayloadUpdateOfficeCalendar = {
        scheduleId: taskOfficeId,
        body: newDataTask as IFormOfficeCalendar,
      };

      handleEditOfficeCalendar(payload);
    } else {
      handleCreateOfficeCalendar(newDataTask as IFormOfficeCalendar);
    }
  };

  const handleEditOfficeCalendar = (payload: IPayloadUpdateOfficeCalendar) => {
    setIsLoading(true);

    dispatch(updateOfficeCalendar(payload))
      .unwrap()
      .then((_res) => {
        handleCloseFormOfficeCalendar();

        return dispatch(scheduleActions.setRefreshOfficeSchedule(true));
      })
      .catch((_error) => {})
      .finally(() => setIsLoading(false));
  };

  const handleCreateOfficeCalendar = (data: IFormOfficeCalendar) => {
    setIsLoading(true);

    dispatch(createOfficeCalendar(data))
      .unwrap()
      .then((_res) => {
        handleCloseFormOfficeCalendar();

        return dispatch(scheduleActions.setRefreshOfficeSchedule(true));
      })
      .catch((_error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCreateUnavailabilityEvent = (data: IFormOfficeCalendar) => {
    if (!data) return;
    setIsLoading(true);

    const body: IUnavailability = {
      allDay: data.allDay,
      startDate: data.startDate,
      endDate: data.endDate,
      startTime: data.startTime,
      endTime: data.endTime,
      note: data.note,
      reason: data.reason,
    };

    dispatch(createUnavailabilityEvent(removeEmptyObjects(body)))
      .unwrap()
      .then((_res) => {
        handleCloseFormOfficeCalendar();

        return dispatch(scheduleActions.setRefreshOfficeSchedule(true));
      })
      .catch((_error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCloseFormOfficeCalendar = () => {
    reset(defaultValues);
    setIsTaskDeleted(false);
    onClose();
  };

  const renderTitleModal = () => {
    if (isTaskDeleted) return EMPTY_STRING;

    return scheduleId ? t('modal_edit_form_office_calendar_title') : t('modal_create_form_office_calendar_title');
  };

  const handleShowModalConfirm = () => {
    setIsShowConfirmModal(!isShowConfirmModal);
  };

  const handleDeleteOfficeCalendar = () => {
    if (!scheduleId) return Promise.resolve();

    return dispatch(deleteOfficeCalendar(scheduleId))
      .unwrap()
      .then(() => {
        handleCloseFormOfficeCalendar();

        dispatch(scheduleActions.setRefreshOfficeSchedule(true));
      })
      .catch(() => {});
  };
  //#endregion Handle Function

  return (
    <Modal isOpen={isOpen} onClose={handleCloseFormOfficeCalendar} title={renderTitleModal()}>
      {isTaskDeleted ? (
        <div className={cx('textDevelopment')}>{`${t('task_modal_task_deleted_label')}`}</div>
      ) : (
        <FormProvider
          {...({
            control,
            setValue,
            trigger,
            formState: { errors },
          } as UseFormReturn<IFormOfficeCalendar>)}
        >
          <form id='formOfficeCalendar' className={cx('formOfficeCalendar')} onSubmit={handleSubmit(handleSubmitForm)}>
            <div className={cx('contentModal')}>
              {!scheduleId && (
                <div className={cx('twoCol')}>
                  <Controller
                    name='type'
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <BaseSelect
                        options={optionsTaskType || []}
                        value={value || EMPTY_STRING}
                        label={t('form_shift_schedule_modal_type_label')}
                        placeholder={t('common_select_placeholder')}
                        onChange={({ value }) => {
                          onChange(value);
                        }}
                        errorMessage={errors.type?.message}
                        required
                      />
                    )}
                  />
                </div>
              )}

              {watchType === EventStatusEnum.UNAVAILABILITY && <UnavailabilityForm />}

              {watchType === EventStatusEnum.TASK && (
                <>
                  <RecurrenceOfficeCalendar />

                  <div className={cx('twoCol')}>
                    <Controller
                      name='assignee'
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <AssigneePopup
                          label={t('form_office_calendar_assignees_label')}
                          placeholder={t('common_placeholder_select')}
                          value={value || []}
                          assigneeList={listAssignee}
                          onChange={(value) => onChange(value)}
                          errorMessage={errors.assignee?.message}
                        />
                      )}
                    />

                    <Controller
                      name='status'
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <BaseSelect
                          value={value}
                          options={optionsMockProjectStatus}
                          label={t('form_office_calendar_status_label')}
                          placeholder={t('common_select_placeholder')}
                          onChange={(optionSelected: IBaseOption) => {
                            onChange(optionSelected?.value);
                          }}
                        />
                      )}
                    />
                  </div>

                  <Controller
                    name='relates'
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <AssigneePopup
                        label={t('form_office_calendar_related_to_label')}
                        value={value || []}
                        assigneeList={listRelates}
                        placeholder={t('common_placeholder_select')}
                        onChange={(value) => onChange(value)}
                        errorMessage={errors.relates?.message}
                      />
                    )}
                  />

                  <Controller
                    name='description'
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <BaseTextarea
                        id='description'
                        label={t('form_office_calendar_label_description')}
                        value={value}
                        onChange={onChange}
                        height={100}
                        required
                        messageError={errors.description?.message}
                      />
                    )}
                  />

                  <Controller
                    name='isSendReminderEmail'
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <CheckboxSingle
                        label={t('form_office_calendar_send_reminder_email')}
                        value={value}
                        onChange={(checked: boolean, name?: string) => {
                          onChange(checked);
                        }}
                      />
                    )}
                  />
                </>
              )}
            </div>

            <div className={cx('footerButton')}>
              {scheduleId && (
                <BaseButton
                  type='button'
                  onClick={handleShowModalConfirm}
                  typeStyle={ButtonTypeEnum.DELETE}
                  text={t('common_delete_label')}
                  minWidth={80}
                />
              )}

              <BaseButton
                type='button'
                text={t('common_cancel_label')}
                width={65}
                onClick={handleCloseFormOfficeCalendar}
              />

              <BaseButton
                type='submit'
                text={scheduleId ? t('common_update_label') : t('common_save_label')}
                typeStyle={ButtonTypeEnum.PRIMARY}
                width={80}
                disabled={scheduleId ? !isDirty : false}
              />
            </div>
          </form>
        </FormProvider>
      )}

      {isDevelopment && <ModalUnderDevelopment onClose={handleCloseDevelopment} />}

      <ConfirmModal
        isOpen={isShowConfirmModal}
        title={t('common_confirm_delete_title', {
          name: t('common_text_this_schedule'),
        })}
        titleAction={t('common_delete_label')}
        onCancel={handleShowModalConfirm}
        onAction={handleDeleteOfficeCalendar}
        type='danger'
      />

      {isLoading && <Loading />}
    </Modal>
  );
};

export default FormOfficeCalendar;
