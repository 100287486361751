export const LOGIN = 'LOGIN';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const CREATE_NEW_PASSWORD = 'CREATE_NEW_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const VERIFY_CODE = 'VERIFY_CODE';

// Employee
export const GET_LIST_EMPLOYEE = 'GET_LIST_EMPLOYEE';
export const CREATE_EMPLOYEE = 'CREATE_EMPLOYEE';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';

// Caregiver
export const GET_LIST_CAREGIVER = 'GET_LIST_CAREGIVER';
export const CREATE_CAREGIVER = 'CREATE_CAREGIVER';
export const EDIT_CAREGIVER = 'EDIT_CAREGIVER';
export const GET_DETAIL_CAREGIVER = 'GET_DETAIL_CAREGIVER';
export const DELETE_CAREGIVER = 'DELETE_CAREGIVER';
export const GET_PROFILE_TAGS = 'GET_PROFILE_TAGS';
export const GET_CAREGIVERS_BY_LOCATION = 'GET_CAREGIVERS_BY_LOCATION';
export const CREATE_CAREGIVER_REFERRAL = 'CREATE_CAREGIVER_REFERRAL';
export const GET_CAREGIVER_REFERRAL_DETAIL = 'GET_CAREGIVER_REFERRAL_DETAIL';
export const SEND_EMAIL_CAREGIVER = 'SEND_EMAIL_CAREGIVER';

// User
export const GET_DETAIL_USER = 'GET_DETAIL_USER';
export const GET_DETAIL_PROFILE = 'GET_DETAIL_PROFILE';
export const CHANGE_STATUS_ACCOUNT = 'CHANGE_STATUS_ACCOUNT';
export const GET_LIST_ASSIGNEE = 'GET_LIST_ASSIGNEE';
export const DELETE_USER = 'DELETE_USER';
export const GET_LIST_USER_BY_ROLES = 'GET_LIST_USER_BY_ROLES';

// Applicant
export const GET_LIST_APPLICANT = 'GET_LIST_APPLICANT';
export const CREATE_APPLICANT = 'CREATE_APPLICANT';
export const CONVERT_TO_CAREGIVER = 'CONVERT_TO_CAREGIVER';
export const CHANGE_STAGE_APPLICANT = 'CHANGE_STAGE_APPLICANT';
export const GET_DETAIL_APPLICANT = 'GET_DETAIL_APPLICANT';
export const EDIT_APPLICANT = 'EDIT_APPLICANT';
export const CHANGE_STATUS_APPLICANT = 'CHANGE_STATUS_APPLICANT';

// Conversation
export const GET_LIST_CONVERSATION = 'GET_LIST_CONVERSATION';
export const CREATE_CONVERSATION = 'CREATE_CONVERSATION';
export const GET_DETAIL_CONVERSATION = 'GET_DETAIL_CONVERSATION';
export const CREATE_MESSAGE = 'CREATE_MESSAGE';

// Care Alert
export const GET_LIST_CARE_ALERT = 'GET_LIST_CARE_ALERT';

// Clients
export const CREATE_CLIENT = 'CREATE_CLIENT';
export const GET_LIST_CLIENT = 'GET_LIST_CLIENT';
export const GET_DETAIL_CLIENT = 'GET_DETAIL_CLIENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const UPLOAD_FILE_CLIENT = 'UPLOAD_FILE_CLIENT';
export const GET_LIST_RELATES = 'GET_LIST_RELATES';
export const SEND_EMAIL_TO_CLIENT = 'SEND_EMAIL_TO_CLIENT';
export const CHANGE_STATUS_CLIENT = 'CHANGE_STATUS_CLIENT';
export const CHANGE_STAGE_CLIENT = 'CHANGE_STAGE_CLIENT';
export const GET_DETAIL_CARE_PLAN = 'GET_DETAIL_CARE_PLAN';
export const CREATE_CARE_PLAN = 'CREATE_CARE_PLAN';
export const GET_LIST_CLIENT_BY_CONTACT = 'GET_LIST_CLIENT_BY_CONTACT';
export const GET_LIST_CLIENT_BY_LOCATIONS = 'GET_LIST_CLIENT_BY_LOCATIONS';
export const SEND_TO_QUICK_BOCKS_CLIENT = 'SEND_TO_QUICK_BOCKS_CLIENT';

// Prospect
export const GET_LIST_PROSPECT = 'GET_LIST_PROSPECT';
export const CONVERT_TO_CLIENT = 'CONVERT_TO_CLIENT';

// Referral
export const GET_LIST_REFERRALS = 'GET_LIST_REFERRALS';
export const CREATE_REFERRALS = 'CREATE_REFERRALS';
export const UPDATE_REFERRALS = 'UPDATE_REFERRALS';
export const GET_DETAIL_REFERRALS = 'GET_DETAIL_REFERRALS';
export const GET_LIST_CLIENT_PROSPECT_REFERRALS = 'GET_LIST_CLIENT_PROSPECT_REFERRALS';

// File
export const GET_LIST_DOCUMENTS = 'GET_LIST_DOCUMENTS';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const DOWNLOAD_DOCUMENT = 'DOWNLOAD_DOCUMENT';
export const UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT';
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const GET_DETAIL_DOCUMENT = 'GET_DETAIL_DOCUMENT';
export const ASSIGN_TO_CONTACT = 'ASSIGN_TO_CONTACT';

// Task
export const GET_LIST_TASK = 'GET_LIST_TASK';
export const GET_SELECT_TASK = 'GET_SELECT_TASK';
export const DELETE_TASK = 'DELETE_TASK';
export const GET_TASK_DETAIL = 'GET_TASK_DETAIL';
export const CREATE_TASK = 'CREATE_TASK';
export const UPDATE_TASK = 'UPDATE_TASK';

// Timeclock
export const GET_LIST_TIME_CLOCK = 'GET_LIST_TIME_CLOCK';
export const CREATE_TIME_CLOCK = 'CREATE_TIME_CLOCK';
export const GET_DETAIL_TIME_CLOCK = 'GET_DETAIL_TIME_CLOCK';
export const UPDATE_TIME_CLOCK = 'UPDATE_TIME_CLOCK';
export const DELETE_TIME_CLOCK = 'DELETE_TIME_CLOCK';
export const CHANGE_STATUS_TIME_CLOCK = 'CHANGE_STATUS_TIME_CLOCK';

// Shift Schedule
export const CREATE_SCHEDULE = 'CREATE_SCHEDULE';
export const GET_LIST_SHIFT_SCHEDULE = 'GET_LIST_SHIFT_SCHEDULE';
export const GET_LIST_SCHEDULE = 'GET_LIST_SCHEDULE';
export const DELETE_SCHEDULE = 'DELETE_SCHEDULE';
export const GET_DETAIL_SCHEDULE = 'GET_DETAIL_SCHEDULE';
export const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE';
export const GET_SCHEDULE_CLIENT = 'GET_SCHEDULE_CLIENT';
export const GET_SCHEDULE_CAREGIVER = 'GET_SCHEDULE_CAREGIVER';
export const GET_SHIFT_SCHEDULE_CLIENT = 'GET_SHIFT_SCHEDULE_CLIENT';
export const GET_OFFICE_SCHEDULE = 'GET_OFFICE_SCHEDULE';
export const GET_SCHEDULES_LIVE_VIEW = 'GET_SCHEDULES_LIVE_VIEW';
export const CREATE_SHIFT_SCHEDULE = 'CREATE_SHIFT_SCHEDULE';
export const UPDATE_SHIFT_SCHEDULE = 'UPDATE_SHIFT_SCHEDULE';
export const CHANGE_STATUS_SHIFT_SCHEDULE = 'CHANGE_STATUS_SHIFT_SCHEDULE';
export const GET_DETAIL_SHIFT = 'GET_DETAIL_SHIFT';
export const GET_LIST_NOTE_SCHEDULE = 'GET_LIST_NOTE_SCHEDULE';
export const CREATE_SHIFT_NOTE_SCHEDULE = 'CREATE_SHIFT_NOTE_SCHEDULE';
export const GET_DETAIL_UNAVAILABILITY = 'GET_DETAIL_UNAVAILABILITY';
export const CREATE_UNAVAILABILITY_NOTE = 'CREATE_UNAVAILABILITY_NOTE';
export const DELETE_SHIFT = 'DELETE_SHIFT';
export const GET_OPEN_SHIFTS = 'GET_OPEN_SHIFTS';
export const CREATE_OFFICE_CALENDAR = 'CREATE_OFFICE_CALENDAR';
export const DETAIL_OFFICE_CALENDAR = 'DETAIL_OFFICE_CALENDAR';
export const UPDATE_OFFICE_CALENDAR = 'UPDATE_OFFICE_CALENDAR';
export const DELETE_OFFICE_CALENDAR = 'DELETE_OFFICE_CALENDAR';
export const DETAIL_OFFICE_CALENDAR_EVENT = 'DETAIL_OFFICE_CALENDAR_EVENT';

// Dashboard
export const GET_STATISTIC_TOTAL = 'GET_STATISTIC_TOTAL';

// Activity
export const GET_LIST_ACTIVITY_BY_CLIENT = 'GET_LIST_ACTIVITY_BY_CLIENT';
export const GET_LIST_ACTIVITY_BY_CAREGIVER = 'GET_LIST_ACTIVITY_BY_CAREGIVER';
export const GET_LIST_ACTIVITY_BY_APPLICANT = 'GET_LIST_ACTIVITY_BY_APPLICANT';
export const GET_LIST_ACTIVITY_BY_PROSPECT = 'GET_LIST_ACTIVITY_BY_PROSPECT';

// QuickBooks
export const GET_AUTH_URL = 'GET_AUTH_URL';
export const GET_TOKEN_QUICK_BOOK = 'GET_TOKEN_QUICK_BOOK';
export const DISCONNECT_QUICK_BOOK = 'DISCONNECT_QUICK_BOOK';
export const GET_LIST_QUICK_BOOK_CUSTOMER = 'GET_LIST_QUICK_BOOK_CUSTOMER';

// Notifications
export const GET_LIST_NOTIFICATIONS = 'GET_LIST_NOTIFICATIONS';
export const MARKED_READ_NOTIFICATION = 'MARKED_READ_NOTIFICATION';

// Report
export const GET_LOCATIONS_CAREGIVER_IN_REPORT = 'GET_LOCATIONS_CAREGIVER_IN_REPORT';
export const GET_ALL_CAREGIVERS_BY_LOCATION = 'GET_ALL_CAREGIVERS_BY_LOCATION';
export const GET_ALL_CLIENTS_BY_LOCATION = 'GET_ALL_CLIENTS_BY_LOCATION';
export const GET_REPORT_INVOICE = 'GET_REPORT_INVOICE';

// Rate
export const GET_LIST_RATE = 'GET_LIST_RATE';
export const GET_LIST_PAYROLL = 'GET_LIST_PAYROLL';
export const CREATE_RATE = 'CREATE_RATE';
export const UPDATE_PAYROLL_RATES = 'UPDATE_PAYROLL_RATES';
export const GET_LIST_AUTHORIZATION = 'GET_LIST_AUTHORIZATION';
export const CREATE_AUTHORIZATION = 'CREATE_AUTHORIZATION';
export const GET_DETAIL_AUTHORIZATION = 'GET_DETAIL_AUTHORIZATION';
export const EDIT_AUTHORIZATION = 'EDIT_AUTHORIZATION';

// Location
export const CREATE_LOCATION = 'CREATE_LOCATION';
export const GET_LIST_LOCATION = 'GET_LIST_LOCATION';
export const GET_DETAIL_LOCATION = 'GET_DETAIL_LOCATION';

// Initial Call
export const GET_LIST_INITIAL_CALL = 'GET_LIST_INITIAL_CALL';
export const CREATE_INITIAL_CALL = 'CREATE_INITIAL_CALL';
export const GET_DETAIL_INITIAL_CALL = 'GET_DETAIL_INITIAL_CALL';

// Contacts
export const GET_LIST_CONTACT = 'GET_LIST_CONTACT';
export const GET_DETAIL_CONTACT = 'GET_DETAIL_CONTACT';
export const CREATE_CONTACT = 'CREATE_CONTACT';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const CHANGE_STATUS_CONTACT = 'CHANGE_STATUS_CONTACT';

// Stage
export const GET_LIST_STAGE = 'GET_LIST_STAGE';
export const CREATE_STAGE = 'CREATE_STAGE';
export const UPDATE_STAGE = 'UPDATE_STAGE';

// Email Template
export const GET_LIST_EMAIL_TEMPLATE = 'GET_LIST_EMAIL_TEMPLATE';
export const CREATE_EMAIL_TEMPLATE = 'CREATE_EMAIL_TEMPLATE';

// Campaign
export const GET_LIST_CAMPAIGN = 'GET_LIST_CAMPAIGN';
export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN';
export const GET_DETAIL_CAMPAIGN = 'GET_DETAIL_CAMPAIGN';
export const EDIT_CAMPAIGN = 'EDIT_CAMPAIGN';

// Caregiver Referral
export const GET_LIST_CAREGIVER_REFERRAL = 'GET_LIST_CAREGIVER_REFERRAL';
export const EDIT_CAREGIVER_REFERRAL = 'EDIT_CAREGIVER_REFERRAL';
export const EDIT_EMAIL_TEMPLATE = 'EDIT_EMAIL_TEMPLATE';
export const GET_CAREGIVERS_APPLICANTS = 'GET_CAREGIVERS_APPLICANTS';
export const CHANGE_STATUS_CAREGIVERS_REFERRAL = 'CHANGE_STATUS_CAREGIVERS_REFERRAL';

// Invoice
export const CREATE_INVOICE = 'CREATE_INVOICE';
export const GET_LIST_INVOICE = 'GET_LIST_INVOICE';
export const GET_DETAIL_INVOICE = 'GET_DETAIL_INVOICE';
export const SEND_INVOICE = 'SEND_INVOICE';
export const CHANGE_STATUS_INVOICE = 'CHANGE_STATUS_INVOICE';
export const GET_LIST_INVOICE_BY_CLIENT = 'GET_LIST_INVOICE_BY_CLIENT';

//BackgroundReports
export const CREATE_BACKGROUND_REPORT = 'CREATE_BACKGROUND_REPORT';
export const GET_LIST_BACKGROUND_REPORT = 'GET_LIST_BACKGROUND_REPORT';
export const EDIT_BACKGROUND_REPORT = 'EDIT_BACKGROUND_REPORT';
export const DELETE_BACKGROUND_REPORT = 'DELETE_BACKGROUND_REPORT';

// Skill
export const CREATE_SKILL = 'CREATE_SKILL';
export const GET_DETAIL_SKILL = 'GET_DETAIL_SKILL';
export const EDIT_SKILL = 'EDIT_SKILL';

// Caregiver List
export const GET_CAREGIVER_LIST = 'GET_CAREGIVER_LIST';

// Training
export const GET_LIST_CAREGIVER_TRAINING = 'GET_LIST_CAREGIVER_TRAINING';

// HR
export const GET_LIST_HR = 'GET_LIST_HR';
export const CHANGE_STATUS_UNAVAILABILITY = 'CHANGE_STATUS_UNAVAILABILITY';
export const CREATE_UNAVAILABILITY_EVENT = 'CREATE_UNAVAILABILITY_EVENT';
export const DELETE_UNAVAILABILITY = 'DELETE_UNAVAILABILITY';
export const GET_DETAIL_HR_UNAVAILABILITY = 'GET_DETAIL_HR_UNAVAILABILITY';

// Employee
export const GET_HOME_SAFETY_ASSESSMENT = 'GET_HOME_SAFETY_ASSESSMENT';
export const EDIT_ANSWER = 'EDIT_ANSWER';

// Daily Care
export const CREATE_VISIT_NOTE = 'CREATE_VISIT_NOTE';
export const GET_LIST_VISIT_NOTE = 'GET_LIST_VISIT_NOTE';
export const CREATE_MILEAGE = 'CREATE_MILEAGE';
export const GET_MILEAGE_LIST = 'GET_MILEAGE_LIST';
export const CREATE_EXPENSE_CREDITS = 'CREATE_EXPENSE_CREDITS';
export const GET_LIST_EXPENSE_CREDITS = 'GET_LIST_EXPENSE_CREDITS';
export const GET_TASK_LIST_DAILY_CARE = 'GET_TASK_LIST_DAILY_CARE';
