// Libs
import classNames from 'classnames/bind';
import * as yup from 'yup';
import moment from 'moment';
import { useEffect, useState, useMemo } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
// Components, Layouts, Pages
import {
  BaseButton,
  BaseDatePicker,
  BaseInput,
  BaseSelect,
  BaseTextarea,
  Loading,
  Modal,
  SelectTime,
} from '~/components';
// Others
import { IBaseOption, IListDataResponse, IListQueryParams } from '~/utils/interface/common';
import { calculateTotalMinutes, getUserName, removeEmptyObjects } from '~/utils/helper';
import { IFormVisitNote } from '~/utils/interface/visitNote';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_LIMIT_MAX_ITEM,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  MIN,
} from '~/utils/constants/common';
import { BusinessTypeEnum, ButtonTypeEnum, TimeFormatEnum } from '~/utils/enum';
import { IListQueryParamsClients } from '~/utils/interface/crm/clients';
import { useAppDispatch } from '~/redux/hooks';
import { getListClients } from '~/thunks/crm/clients/clientsThunk';
import { getListCaregiver } from '~/thunks/caregiver/caregiverThunk';
import { ICaregiver } from '~/mockData/mockInterface';
import {
  optionsBillingRateVisitNote,
  optionsLocationVisitNote,
  optionsPayRateVisitNote,
  optionsServiceVisitNote,
} from '~/mockData';
import { createVisitNote } from '~/thunks/dailyCare/dailyCareThunk';
// Styles, images, icons
import styles from './FormVisitNote.module.scss';

type Props = {
  businessType: BusinessTypeEnum;
  dateSelected: string;
  onClose?: () => void;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction, businessType: BusinessTypeEnum) => {
  return yup.object().shape({
    businessId: yup.string().trim().required(t('common_required_field')),
    businessType: yup.string().trim().required(t('common_required_field')),
    clientId: yup
      .string()
      .trim()
      .when([], {
        is: () => businessType === BusinessTypeEnum.CAREGIVER,
        then: (schema) => schema.required(t('common_required_field')),
        otherwise: (schema) => schema.optional(),
      }),
    accountId: yup
      .string()
      .trim()
      .when([], {
        is: () => businessType === BusinessTypeEnum.CLIENT,
        then: (schema) => schema.required(t('common_required_field')),
        otherwise: (schema) => schema.optional(),
      }),
    date: yup.string().trim().required(t('common_required_field')),
    startTime: yup.string().trim().required(t('common_required_field')),
    endTime: yup
      .string()
      .required(t('common_required_field'))
      .test('is-after-start-time', t('common_error_message_end_time'), function (value) {
        const { startTime } = this.parent;
        if (!startTime || !value) return true;

        const start = moment(startTime, TimeFormatEnum.HH_MM);
        const end = moment(value, TimeFormatEnum.HH_MM);

        return end.isAfter(start);
      }),
    totalTime: yup.number().optional(),
    service: yup.string().trim().optional(),
    location: yup.string().trim().optional(),
    billingRate: yup.string().trim().optional(),
    payRate: yup.string().trim().optional(),
    notes: yup.string().trim().optional(),
  });
};

const defaultValues: IFormVisitNote = {
  businessId: EMPTY_STRING,
  businessType: EMPTY_STRING,
  date: EMPTY_STRING,
  startTime: EMPTY_STRING,
  endTime: EMPTY_STRING,
  totalTime: DEFAULT_NUMBER_ZERO,
};

const FormVisitNote = (props: Props) => {
  //#region Destructuring Props
  const { businessType, dateSelected, onClose } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<IFormVisitNote>({
    resolver: yupResolver(schema(t, businessType)),
    defaultValues: defaultValues,
  });
  const { clientId, caregiverId } = useParams();
  //#endregion Declare Hook

  //#region Declare Memo
  //#endregion Declare Memo

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const startTime = useWatch({ control, name: 'startTime' });
  const endTime = useWatch({ control, name: 'endTime' });
  const [optionClient, setOptionClient] = useState<IBaseOption[]>([]);
  const [optionCaregiver, setOptionCaregiver] = useState<IBaseOption[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    setValue('businessType', businessType);

    if (businessType === BusinessTypeEnum.CAREGIVER) {
      handleGetClientList();
    } else {
      handleGetCaregiverList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessType]);

  useEffect(() => {
    if (clientId) {
      setValue('businessId', clientId);
    }

    if (caregiverId) {
      setValue('businessId', caregiverId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, caregiverId]);

  useEffect(() => {
    setValue('date', dateSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateSelected]);

  useEffect(() => {
    if (!startTime || !endTime) return;
    trigger('endTime');

    const totalTime = calculateTotalMinutes(startTime, endTime);
    setValue('totalTime', Number(totalTime));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime, endTime]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetClientList = () => {
    const params: IListQueryParamsClients = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
    };

    dispatch(getListClients(params))
      .unwrap()
      .then((res) => {
        const { responses } = res?.data;

        if (responses.length === DEFAULT_NUMBER_ZERO) return;

        const listClient = responses?.map((data) => ({
          label: data?.organizationName || getUserName(data?.firstName, data?.lastName),
          value: data.id,
        }));

        setOptionClient(listClient);
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const handleGetCaregiverList = () => {
    const params: IListQueryParams = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
    };

    dispatch(getListCaregiver(params))
      .unwrap()
      .then((res) => {
        const { responses }: IListDataResponse<ICaregiver[]> = res?.data;

        const listCaregiver = responses.map((data) => {
          return { label: getUserName(data.firstName, data.middleName, data.lastName), value: String(data.id) };
        });

        setOptionCaregiver(listCaregiver);
      })
      .catch((err) => {})
      .finally(() => {});
  };

  const handleClose = () => {
    onClose?.();
    reset(defaultValues);
  };

  const handleAddVisitNote = (data: IFormVisitNote) => {
    setIsLoading(true);

    const newVisitNote = removeEmptyObjects(data) as IFormVisitNote;

    dispatch(createVisitNote(newVisitNote))
      .unwrap()
      .then((res) => {
        handleClose();
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  //#endregion Handle Function

  return (
    <Modal isOpen={true} onClose={handleClose} title={t('daily_care_modal_add_visit_note_title')}>
      <form id='formVisitNoteComponent' className={cx('container')} onSubmit={handleSubmit(handleAddVisitNote)}>
        <div className={cx('contentModal')}>
          <div className={cx('twoColumn')}>
            {businessType === BusinessTypeEnum.CAREGIVER && (
              <Controller
                name='clientId'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseSelect
                    required
                    value={value}
                    options={optionClient}
                    label={t('daily_care_modal_add_visit_note_client_label')}
                    placeholder={t('common_select_placeholder')}
                    onChange={(optionSelected: IBaseOption) => {
                      onChange(optionSelected?.value);
                    }}
                    errorMessage={errors.clientId?.message}
                  />
                )}
              />
            )}

            {businessType === BusinessTypeEnum.CLIENT && (
              <Controller
                name='accountId'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseSelect
                    required
                    value={value}
                    options={optionCaregiver}
                    label={t('daily_care_modal_add_visit_note_caregiver_label')}
                    placeholder={t('common_select_placeholder')}
                    onChange={(optionSelected: IBaseOption) => {
                      onChange(optionSelected?.value);
                    }}
                    errorMessage={errors.accountId?.message}
                  />
                )}
              />
            )}

            <BaseDatePicker
              placeholderText={t('common_placeholder_select')}
              value={dateSelected}
              label={t('daily_care_modal_add_mileage_date_label')}
              disable
              required
            />
          </div>

          <div className={cx('viewTime')}>
            <Controller
              name={'startTime'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <SelectTime
                  required
                  label={t('daily_care_modal_add_visit_note_start_time_label')}
                  placeholder={t('common_placeholder_select')}
                  value={value || EMPTY_STRING}
                  onChange={onChange}
                  errorMessage={errors.startTime?.message}
                />
              )}
            />

            <Controller
              name={'endTime'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <SelectTime
                  required
                  label={t('daily_care_modal_add_visit_note_end_time_label')}
                  placeholder={t('common_placeholder_select')}
                  value={value || EMPTY_STRING}
                  onChange={onChange}
                  errorMessage={errors.endTime?.message}
                />
              )}
            />

            <Controller
              name={'totalTime'}
              control={control}
              render={({ field: { value } }) => (
                <BaseInput
                  disabled
                  id='totalTime'
                  label={t('daily_care_modal_add_visit_note_total_time_label')}
                  value={value ? `${value} ${MIN}` : EMPTY_STRING}
                />
              )}
            />
          </div>

          <div className={cx('twoColumn')}>
            <Controller
              name='service'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseSelect
                  value={value}
                  options={optionsServiceVisitNote}
                  label={t('daily_care_modal_add_visit_note_service_label')}
                  placeholder={t('common_select_placeholder')}
                  onChange={(optionSelected: IBaseOption) => {
                    onChange(optionSelected?.value);
                  }}
                />
              )}
            />

            <Controller
              name='location'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseSelect
                  value={value}
                  options={optionsLocationVisitNote}
                  label={t('daily_care_modal_add_visit_note_location_label')}
                  placeholder={t('common_select_placeholder')}
                  onChange={(optionSelected: IBaseOption) => {
                    onChange(optionSelected?.value);
                  }}
                />
              )}
            />
          </div>

          <div className={cx('twoColumn')}>
            <Controller
              name='billingRate'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseSelect
                  value={value}
                  options={optionsBillingRateVisitNote}
                  label={t('daily_care_modal_add_visit_note_billing_rate_label')}
                  placeholder={t('common_select_placeholder')}
                  onChange={(optionSelected: IBaseOption) => {
                    onChange(optionSelected?.value);
                  }}
                />
              )}
            />

            <Controller
              name='payRate'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseSelect
                  value={value}
                  options={optionsPayRateVisitNote}
                  label={t('daily_care_modal_add_visit_note_pay_rate_label')}
                  placeholder={t('common_select_placeholder')}
                  onChange={(optionSelected: IBaseOption) => {
                    onChange(optionSelected?.value);
                  }}
                />
              )}
            />
          </div>

          <Controller
            name='notes'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseTextarea
                id='notes'
                height={90}
                label={t('daily_care_modal_add_visit_note_visit_note_label')}
                value={value || EMPTY_STRING}
                onChange={onChange}
              />
            )}
          />
        </div>

        <div className={cx('formActions')}>
          <BaseButton text={t('common_cancel_label')} width={80} onClick={handleClose} type='button' />

          <BaseButton text={t('common_save_label')} typeStyle={ButtonTypeEnum.PRIMARY} width={80} type='submit' />
        </div>

        {isLoading && <Loading />}
      </form>
    </Modal>
  );
};

export default FormVisitNote;
