// Libs
import classNames from 'classnames/bind';
import { useState, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
// Components, Layouts, Pages
import {
  BaseButton,
  BaseDatePicker,
  ExpenseCreditTableDailyCare,
  FormExpenseCredit,
  FormMileage,
  FormVisitNote,
  Loading,
  MileageTableDailyCare,
  TaskTableDailyCare,
  VisitNoteTableDailyCare,
} from '~/components';
// Others
import { useAppSelector } from '~/redux/hooks';
import { BusinessTypeEnum, ButtonTypeEnum, TimeFormatEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './DailyCare.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const DailyCare = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const { caregiverId } = useParams();
  //#endregion Declare Hook

  //#region Selector
  const { isFetchingMileage, isFetchingListVisitNote, isFetchingListExpenseCredits, isFetchingTaskDailyCare } =
    useAppSelector((state) => state.dailyCareState);
  //#endregion Selector

  //#region Declare State
  const [isShowVisitNoteForm, setIsShowVisitNoteForm] = useState<boolean>(false);
  const [isShowMileageForm, setIsShowMileageForm] = useState<boolean>(false);
  const [isShowExpenseCreditForm, setIsShowExpenseCreditForm] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const dateSelected = useMemo(
    () => params?.dateSelected || moment(new Date()).format(TimeFormatEnum.YYYY_MM_DD),
    [params?.dateSelected]
  );
  //#endregion Declare State

  //#region Implement Hook
  const handleChangeDate = (date: string) => {
    setSearchParams({
      ...params,
      dateSelected: date,
    });
  };

  const handleAddVisitNote = () => {
    handleShowVisitNoteForm();
  };

  const handleAddMileage = () => {
    handleShowMileageForm();
  };

  const handleAddExpenseCredit = () => {
    handleShowExpenseCreditForm();
  };

  const handleShowVisitNoteForm = () => {
    setIsShowVisitNoteForm(!isShowVisitNoteForm);
  };

  const handleShowMileageForm = () => {
    setIsShowMileageForm(!isShowMileageForm);
  };

  const handleShowExpenseCreditForm = () => {
    setIsShowExpenseCreditForm(!isShowExpenseCreditForm);
  };
  //#endregion Handle Function

  return (
    <div id='dailyCareComponent' className={cx('dailyCareComponent')}>
      <div className={cx('bodyWrap')}>
        <div className={cx('header')}>
          <BaseDatePicker
            width={200}
            placeholderText={t('common_placeholder_select')}
            onDateSelected={handleChangeDate}
            value={dateSelected}
          />

          <div className={cx('btnGroup')}>
            <BaseButton
              typeStyle={ButtonTypeEnum.PRIMARY}
              iconLeft={icons.commonIconPlus}
              text={t('caregiver_profile_daily_care_add_visit_note_button')}
              onClick={handleAddVisitNote}
              width={132}
              height={36}
            />

            <BaseButton
              typeStyle={ButtonTypeEnum.PRIMARY}
              iconLeft={icons.commonIconPlus}
              text={t('caregiver_profile_daily_care_add_mileage_button')}
              onClick={handleAddMileage}
              width={132}
              height={36}
            />

            <BaseButton
              typeStyle={ButtonTypeEnum.PRIMARY}
              iconLeft={icons.commonIconPlus}
              text={t('caregiver_profile_daily_care_add_expense_credit_button')}
              onClick={handleAddExpenseCredit}
              width={170}
              height={36}
            />
          </div>
        </div>

        <div className={cx('tableWrap')}>
          <TaskTableDailyCare businessType={BusinessTypeEnum.CAREGIVER} dateSelected={dateSelected} />

          <VisitNoteTableDailyCare businessType={BusinessTypeEnum.CAREGIVER} dateSelected={dateSelected} />

          <MileageTableDailyCare
            businessId={caregiverId}
            businessType={BusinessTypeEnum.CAREGIVER}
            date={dateSelected}
          />

          <ExpenseCreditTableDailyCare businessType={BusinessTypeEnum.CAREGIVER} dateSelected={dateSelected} />
        </div>
      </div>

      {isShowVisitNoteForm && (
        <FormVisitNote
          businessType={BusinessTypeEnum.CAREGIVER}
          onClose={handleShowVisitNoteForm}
          dateSelected={dateSelected}
        />
      )}

      {isShowMileageForm && (
        <FormMileage
          dateSelected={dateSelected}
          businessType={BusinessTypeEnum.CAREGIVER}
          onClose={handleShowMileageForm}
        />
      )}

      {isShowExpenseCreditForm && (
        <FormExpenseCredit
          businessType={BusinessTypeEnum.CAREGIVER}
          dateSelected={dateSelected}
          onClose={handleShowExpenseCreditForm}
        />
      )}

      {(isFetchingMileage || isFetchingListVisitNote || isFetchingListExpenseCredits || isFetchingTaskDailyCare) && (
        <Loading />
      )}
    </div>
  );
};

export default DailyCare;
