import { urlApiStage } from '~/utils/constants/api';
import axiosClient from './axiosClient';
import { BaseResponse, IListDataResponse } from '~/utils/interface/common';
import { IFormStage, IFormUpdateStage, IListStage, IQueryParamsStage } from '~/utils/interface/stage';

const stageApi = {
  getListStage(params: IQueryParamsStage) {
    const url = `${urlApiStage.getList}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IListStage[]>>>(url, { params });
  },

  createStage(body: IFormStage) {
    const url = `${urlApiStage.createStage}`;
    return axiosClient.post<BaseResponse>(url, body);
  },

  updateStage({ stageId, body }: IFormUpdateStage) {
    const url = `${urlApiStage.update(stageId)}`;
    return axiosClient.put<BaseResponse>(url, body);
  },
};

export default stageApi;
