// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useSearchParams } from 'react-router-dom';
import { useState, useEffect, useMemo, useContext } from 'react';
// Components, Layouts, Pages
import { BasePagination, BaseTable, StatusLabel } from '~/components';
// Others
import { CLientStatusEnum, CRMEnum, TranslationEnum, ViewCLientStatusEnum } from '~/utils/enum';
import { ColumnTableType, IListDataResponse } from '~/utils/interface/common';
import { convertEnumToString, formatPhoneNumber, getUserName } from '~/utils/helper';
import { DEFAULT_CURRENT_PAGE, DEFAULT_NUMBER_RECORD_TO_FETCH, EMPTY_STRING } from '~/utils/constants/common';
import { useAppDispatch } from '~/redux/hooks';
import { IClientsProspectsReferred } from '~/utils/interface/crm/referralSource';
import { IQueryReferrals } from '~/utils/interface/crm/referral';
import { getListClientProspectByReferral } from '~/thunks/crm/referral/referralThunk';
// Styles, images, icons
import styles from './ClientsProspectsReferredTab.module.scss';
import { LoadingData } from '~/context';

type Props = {
  clientId: string;
};

const cx = classNames.bind(styles);

const columns = (t: TFunction<TranslationEnum.TRANSLATION>): ColumnTableType<IClientsProspectsReferred>[] => {
  return [
    {
      key: 'name',
      title: t('crm_referral_source_detail_name_title'),
      render(value, record, index) {
        return <>{getUserName(record?.firstName, record?.lastName) || EMPTY_STRING}</>;
      },
      width: '22%',
    },
    {
      key: 'referredBy',
      title: t('crm_referral_source_detail_referred_by_title'),
      render(value, record, index) {
        return (
          <>{getUserName(record?.referrer?.firstName, record?.referrer?.lastName) || record?.referrer?.organization}</>
        );
      },
      width: '21%',
    },
    {
      key: 'homePhone',
      title: t('crm_referral_source_detail_home_phone_title'),
      render(value, record, index) {
        return <>{record?.phoneNumber ? formatPhoneNumber(record?.phoneNumber) : EMPTY_STRING}</>;
      },
      width: '21%',
    },
    {
      key: 'type',
      title: t('crm_referral_source_detail_type_title'),
      render(value, record, index) {
        return <>{record.type ? convertEnumToString(record?.type) : EMPTY_STRING}</>;
      },
      width: '15%',
    },
    {
      key: 'status',
      title: t('crm_referral_source_detail_status_title'),
      render(value, record, index) {
        if (!record.status) {
          return EMPTY_STRING;
        }

        let status = EMPTY_STRING;

        switch (record.status) {
          case CLientStatusEnum.ACTIVE:
            status = ViewCLientStatusEnum.ACTIVATE;
            break;
          case CLientStatusEnum.DEACTIVATE:
            status = ViewCLientStatusEnum.DEACTIVATE;
            break;
          default:
            status = record.status;
        }

        return <StatusLabel bgOpacity={0.1} key={index} label={status} />;
      },
      width: '15%',
    },
  ];
};

const ClientsProspectsReferredTab = (props: Props) => {
  //#region Destructuring Props
  const { clientId } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(() => Number(params?.page ?? DEFAULT_CURRENT_PAGE), [params?.page]);
  const [listClientsProspectsReferred, setListClientsProspectsReferred] =
    useState<IListDataResponse<IClientsProspectsReferred[]>>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!clientId) return;

    const { tab, status, ...restParams } = params;

    const newParams: IQueryReferrals = {
      ...restParams,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      type: [CRMEnum.CLIENT, CRMEnum.PROSPECT],
      referralId: clientId,
    };

    handleGetListClientProspect(newParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);
  //#endregion Implement Hook

  //#region Handle Function
  const handlePaginationChange = (page: number) => {
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleGetListClientProspect = (params: IQueryReferrals) => {
    loading?.show();

    dispatch(getListClientProspectByReferral(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        setListClientsProspectsReferred(res.data);
      })
      .catch((err) => {})
      .finally(() => {
        loading?.hide();
      });
  };
  //#endregion Handle Function

  return (
    <div id='clientsProspectsReferredTabComponent' className={cx('clientsProspectsReferredTabComponent')}>
      <div className={cx('tableContainer')}>
        <BaseTable columns={columns(t)} dataSource={listClientsProspectsReferred?.responses || []} />
      </div>
      <div className={cx('paginationTable')}>
        <BasePagination
          onChange={handlePaginationChange}
          defaultCurrentPage={pageSelected}
          totalItems={listClientsProspectsReferred?.pagination.totalItems}
          totalPages={listClientsProspectsReferred?.pagination.totalPages}
        />
      </div>
    </div>
  );
};

export default ClientsProspectsReferredTab;
