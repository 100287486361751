// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import { GET_LIST_CAREGIVER_TRAINING } from '~/utils/constants/actionType';
import { IPayloadListCaregiverTraining } from '~/utils/interface/caregivers/training';
import trainingApi from '~/apis/caregivers/training';

export const getListCaregiverTraining = createAsyncThunk(
  GET_LIST_CAREGIVER_TRAINING,
  async (payload: IPayloadListCaregiverTraining, { rejectWithValue }) => {
    try {
      const res = await trainingApi.getListCaregiverTraining(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
