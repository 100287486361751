import { urlApiHr } from '~/utils/constants/api';
import axiosClient from './axiosClient';
import { BaseResponse, IListDataResponse } from '~/utils/interface/common';
import {
  IGetDetailUnavailability,
  IGetListUnavailability,
  IListQueryParamsHr,
  IPayloadChangeStatusUnavailability,
} from '~/utils/interface/hr';
import { IUnavailability } from '~/utils/interface/schedule';

const hrApi = {
  getListHr(params: IListQueryParamsHr) {
    const url = `${urlApiHr.getListHr}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IGetListUnavailability[]>>>(url, { params });
  },

  changeStatusUnavailability(payload: IPayloadChangeStatusUnavailability) {
    const { body, unavailabilityId } = payload;

    const url = `${urlApiHr.changeStatusUnavailability(unavailabilityId)}`;
    return axiosClient.put<BaseResponse>(url, body);
  },

  createUnavailabilityEvent(body: IUnavailability) {
    const url = `${urlApiHr.createUnavailabilityEvent}`;
    return axiosClient.post<BaseResponse>(url, body);
  },

  deleteUnavailability(unavailabilityId: string) {
    const url = `${urlApiHr.deleteUnavailability(unavailabilityId)}`;
    return axiosClient.delete<BaseResponse>(url);
  },

  getDetailUnavailability(unavailabilityId: string) {
    const url = `${urlApiHr.detailUnavailability(unavailabilityId)}`;
    return axiosClient.get<BaseResponse<IGetDetailUnavailability>>(url);
  },
};
export default hrApi;
