// Libs
import classNames from 'classnames/bind';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogPanel } from '@headlessui/react';
// Components, Layouts, Pages
import { Loading, Tab } from '~/components';
// Others
import { DIV_ELEMENT, EMPTY_STRING } from '~/utils/constants/common';
import { ITabItem } from '~/utils/interface/common';
import { useAppSelector } from '~/redux/hooks';
// Styles, images, icons
import styles from './EventScheduleModal.module.scss';

type ChildrenActions<T> = {
  value?: T | null;
};

type TTab<T> = {
  isShowTab: boolean;
  renderTab: (data: T | null, tab: string) => React.ReactNode;
  itemsTab: ITabItem[];
};

type FetchDataFn<T> = (id: string) => Promise<{ items: T | null }>;

type Props<T extends { id: string }> = {
  id?: string;
  isOpen: boolean;
  onClose: () => void;
  fetchData: FetchDataFn<T>;
  children?: (actions: ChildrenActions<T>) => React.ReactNode;
  headerGroup?: (data: T | null) => React.ReactNode;
  tab?: TTab<T>;
};

const cx = classNames.bind(styles);

const EventScheduleModal = <T extends { id: string }>(props: Props<T>) => {
  //#region Destructuring Props
  const { onClose, id, isOpen, children, fetchData, tab, headerGroup } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  const { isRefreshUnavailabilityNote } = useAppSelector((state) => state.scheduleState);
  //#endregion Selector

  //#region Declare State
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataSelected, setDataSelected] = useState<T | null>(null);
  const [currentTab, setCurrentTab] = useState<string>(EMPTY_STRING);
  //#endregion Declare State

  //#region Implement Hook
  const loadData = useCallback(async () => {
    try {
      if (!id) return;
      setIsLoading(true);
      const response = await fetchData(id);
      setDataSelected(response.items);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }, [fetchData, id]);

  useEffect(() => {
    if (!isOpen) return;
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, id, isRefreshUnavailabilityNote]);

  useEffect(() => {
    if (isOpen && tab?.isShowTab && tab.itemsTab.length > 0) {
      setCurrentTab(tab.itemsTab[0].tabParam);
    }
  }, [isOpen, tab?.isShowTab, tab?.itemsTab]);

  const renderTabContent = useMemo(() => {
    if (!isOpen) return null;
    return tab?.renderTab && tab?.renderTab(dataSelected, currentTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, dataSelected, isOpen, tab?.renderTab]);
  //#endregion Implement Hook

  //#region Handle Function

  const handleClose = () => {
    setCurrentTab(tab?.itemsTab[0]?.tabParam || EMPTY_STRING);
    setDataSelected(null);
    onClose && onClose();
  };

  const handleTabChange = (newTab: string) => {
    setCurrentTab(newTab);
  };
  //#endregion Handle Function

  return (
    <Dialog as={DIV_ELEMENT} open={isOpen} onClose={handleClose}>
      <div className={cx('container')}>
        <DialogPanel transition className={cx('modalEvent')}>
          <div id='eventScheduleModal' className={cx('eventScheduleModal')}>
            <div className={cx('mainBody')}>
              <div className={cx('headGroup', tab?.isShowTab && 'headGroupTab')}>
                {headerGroup && headerGroup(dataSelected)}

                {tab?.isShowTab && (
                  <div className={cx('navTabGroup')}>
                    <Tab items={tab?.itemsTab!} paddingBottom={16} enableUrlSync={false} onChange={handleTabChange} />
                  </div>
                )}
              </div>

              <div className={cx('content')}>
                {tab?.isShowTab
                  ? renderTabContent
                  : children &&
                    children({
                      value: dataSelected,
                    })}
              </div>
            </div>
          </div>

          {isLoading && <Loading />}
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default memo(EventScheduleModal) as <T extends { id: string }>(props: Props<T>) => JSX.Element;
