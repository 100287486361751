// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import dailyCareApi from '~/apis/dailyCare';
import {
  CREATE_VISIT_NOTE,
  CREATE_MILEAGE,
  GET_MILEAGE_LIST,
  GET_LIST_VISIT_NOTE,
  GET_TASK_LIST_DAILY_CARE,
  CREATE_EXPENSE_CREDITS,
  GET_LIST_EXPENSE_CREDITS,
} from '~/utils/constants/actionType';
import { IFormMileage } from '~/utils/interface/mileage';
import { IGetTasksDailyCareParams, IQueryDailyCareList } from '~/utils/interface/taskDailyCare';
import { IFormExpenseCredit, IListQueryParamsExpenseCredit } from '~/utils/interface/expenseCredit';
import { IFormVisitNote, IGetVisitNotesParams } from '~/utils/interface/visitNote';

export const createVisitNote = createAsyncThunk(
  CREATE_VISIT_NOTE,
  async (body: IFormVisitNote, { rejectWithValue }) => {
    try {
      const res = await dailyCareApi.createVisitNote(body);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListVisitNote = createAsyncThunk(
  GET_LIST_VISIT_NOTE,
  async (params: IGetVisitNotesParams, { rejectWithValue }) => {
    try {
      const res = await dailyCareApi.getListVisitNote(params);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createMileage = createAsyncThunk(CREATE_MILEAGE, async (body: IFormMileage, { rejectWithValue }) => {
  try {
    const res = await dailyCareApi.createMileage(body);
    return res.data.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getMileageList = createAsyncThunk(
  GET_MILEAGE_LIST,
  async (query: IQueryDailyCareList, { rejectWithValue }) => {
    try {
      const res = await dailyCareApi.getMileageList(query);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createExpenseCredits = createAsyncThunk(
  CREATE_EXPENSE_CREDITS,
  async (body: IFormExpenseCredit, { rejectWithValue }) => {
    try {
      const res = await dailyCareApi.createExpenseCredits(body);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListExpenseCredits = createAsyncThunk(
  GET_LIST_EXPENSE_CREDITS,
  async (params: IListQueryParamsExpenseCredit, { rejectWithValue }) => {
    try {
      const res = await dailyCareApi.getListExpenseCredits(params);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTaskListDailyCare = createAsyncThunk(
  GET_TASK_LIST_DAILY_CARE,
  async (params: IGetTasksDailyCareParams, { rejectWithValue }) => {
    try {
      const res = await dailyCareApi.getTaskListDailyCare(params);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
