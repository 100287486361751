// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import {
  changeStatusInvoice,
  createInvoice,
  getDetailInvoice,
  getListInvoice,
  getListInvoiceByClient,
  sendInvoice,
} from './invoiceThunk';

export interface invoiceState {
  isRefreshInvoiceList: boolean;
}

const initialState: invoiceState = {
  isRefreshInvoiceList: false,
};

const invoiceSlice = createSlice({
  name: 'invoiceState',
  initialState,
  reducers: {},
  extraReducers(builder) {
    // Get list invoice
    builder
      .addCase(getListInvoice.pending, (state) => {})
      .addCase(getListInvoice.fulfilled, (state) => {
        state.isRefreshInvoiceList = false;
      })
      .addCase(getListInvoice.rejected, (state) => {
        state.isRefreshInvoiceList = false;
      });

    // Create invoice
    builder
      .addCase(createInvoice.pending, (state) => {})
      .addCase(createInvoice.fulfilled, (state) => {
        state.isRefreshInvoiceList = true;
      })
      .addCase(createInvoice.rejected, (state) => {});

    // Get Detail Invoice
    builder
      .addCase(getDetailInvoice.pending, (state) => {})
      .addCase(getDetailInvoice.fulfilled, (state) => {})
      .addCase(getDetailInvoice.rejected, (state) => {});

    // Send invoice
    builder
      .addCase(sendInvoice.pending, (state) => {})
      .addCase(sendInvoice.fulfilled, (state) => {
        state.isRefreshInvoiceList = true;
      })
      .addCase(sendInvoice.rejected, (state) => {});

    // Change Status invoice
    builder
      .addCase(changeStatusInvoice.pending, (state) => {})
      .addCase(changeStatusInvoice.fulfilled, (state) => {
        state.isRefreshInvoiceList = true;
      })
      .addCase(changeStatusInvoice.rejected, (state) => {});

    // Get List Invoice By Client
    builder
      .addCase(getListInvoiceByClient.pending, (state) => {})
      .addCase(getListInvoiceByClient.fulfilled, (state) => {})
      .addCase(getListInvoiceByClient.rejected, (state) => {});
  },
});

// Actions
export const invoiceActions = invoiceSlice.actions;

// Reducer
const invoiceReducer = invoiceSlice.reducer;
export default invoiceReducer;
