// Libs
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useState } from 'react';
// Components, Layouts, Pages
import { BaseButton, BaseInput, BaseSelect, BaseTextarea, BaseUploadImage, PlacesAutocomplete } from '~/components';
// Others
import { ButtonTypeEnum, CRMEnum, KeyFormRequestApplicantEnum, StatusEnum } from '~/utils/enum';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_LIMIT_MAX_ITEM,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  NAVIGATE_BACK,
  RegExp,
} from '~/utils/constants/common';
import { IAddress, IBaseOption, IListQueryParams } from '~/utils/interface/common';
import { useAppDispatch } from '~/redux/hooks';
import { LoadingData } from '~/context';
import { initialValues, requestApplicantSchema } from './helper';
import { IFormRequestApplicant } from '~/utils/interface/applicant';
import { createFormData, formatPhoneNumber, getUserName, removeEmptyObjects } from '~/utils/helper';
import { getListLocations } from '~/thunks/crm/location/locationThunk';
import { getListCaregiverReferral } from '~/thunks/caregivers/referral/referralThunk';
import { IQueryCaregiverReferral } from '~/utils/interface/caregivers/referral';
import {
  createApplicant,
  editApplicant,
  getDetailRequestApplicant,
} from '~/thunks/caregivers/applicant/applicantThunk';
// Styles, images, icons
import styles from './FormRequestApplicant.module.scss';

type Props = {
  requestApplicantId?: string;
};

const cx = classNames.bind(styles);

const FormRequestApplicant = (props: Props) => {
  //#region Destructuring Props
  const { requestApplicantId } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);
  const {
    control,
    handleSubmit,
    trigger,
    setValue,
    reset,
    formState: { errors, isDirty },
  } = useForm<IFormRequestApplicant>({
    resolver: yupResolver(requestApplicantSchema(t)),
    defaultValues: initialValues,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [locations, setLocations] = useState<IBaseOption[]>([]);
  const [caregiverReferralList, setCaregiverReferralList] = useState<IBaseOption[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetListLocations();
    handleGetListReferral();
  }, []);

  useEffect(() => {
    if (!requestApplicantId) return;

    handleGetDetailRequestApplicant(requestApplicantId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestApplicantId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleSubmitForm = (data: IFormRequestApplicant) => {
    if (requestApplicantId) {
      handleEditRequestApplicant(data);
    } else {
      handleAddRequestApplicant(data);
    }
  };

  const handleAddRequestApplicant = async (data: IFormRequestApplicant) => {
    loading?.show();
    const newData = {
      ...data,
      profileImage:
        Array.isArray(data?.profileImage) && data?.profileImage?.length > DEFAULT_NUMBER_ZERO
          ? data?.profileImage
          : undefined,
      homePhone: data.homePhone?.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING),
      mobilePhone: data.mobilePhone?.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING),
      workPhone: data.workPhone?.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING),
    };

    const dataClean = removeEmptyObjects(newData) as IFormRequestApplicant;

    const formData = await createFormData(dataClean, KeyFormRequestApplicantEnum.PROFILE_IMAGE);
    dispatch(createApplicant(formData))
      .unwrap()
      .then((res) => {
        handleGoBack();
      })
      .catch((err) => {})
      .finally(() => loading?.hide());
  };

  const handleEditRequestApplicant = async (data: IFormRequestApplicant) => {
    if (!requestApplicantId) return;
    loading?.show();
    const newData = {
      ...data,
      profileImage:
        Array.isArray(data?.profileImage) && data?.profileImage?.length > DEFAULT_NUMBER_ZERO
          ? data?.profileImage
          : undefined,
      homePhone: data.homePhone?.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING),
      mobilePhone: data.mobilePhone?.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING),
      workPhone: data.workPhone?.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING),
    };

    const formData = await createFormData(newData, KeyFormRequestApplicantEnum.PROFILE_IMAGE);

    dispatch(editApplicant({ applicantId: requestApplicantId, body: formData }))
      .unwrap()
      .then((res) => {
        handleGoBack();
      })
      .catch((err) => {})
      .finally(() => loading?.hide());
  };

  const handleGetDetailRequestApplicant = (id: string) => {
    loading?.show();
    dispatch(getDetailRequestApplicant(id))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        const formattedData: IFormRequestApplicant = {
          profileImage: res.data?.avatarUrl ? [{ url: res?.data?.avatarUrl }] : undefined,
          firstName: res.data?.firstName || EMPTY_STRING,
          lastName: res.data?.lastName || EMPTY_STRING,
          middleName: res.data?.middleName || EMPTY_STRING,
          address: res.data?.address || EMPTY_STRING,
          city: res.data?.city || EMPTY_STRING,
          state: res.data?.state || EMPTY_STRING,
          postalCode: res.data?.postalCode || EMPTY_STRING,
          secondaryAddress: res.data?.secondaryAddress || EMPTY_STRING,
          email: res.data?.email || EMPTY_STRING,
          externalId: res.data?.externalId || EMPTY_STRING,
          locationIds: res.data?.locations?.map((location) => location.id) || [],
          mobilePhone: res.data?.mobilePhone || EMPTY_STRING,
          homePhone: res.data?.homePhone || EMPTY_STRING,
          workPhone: res.data?.workPhone || EMPTY_STRING,
          referrerId: res.data?.referrer?.id || EMPTY_STRING,
          ssn: res.data?.ssn || EMPTY_STRING,
          lat: res.data?.lat || 0,
          lng: res.data?.lng || 0,
          notes: res.data?.notes || EMPTY_STRING,
        };

        reset(formattedData);
      })
      .catch((err) => {})
      .finally(() => loading?.hide());
  };

  const handleChangeAddress = (address: IAddress) => {
    const fieldsToUpdate = [
      { key: 'address', value: address?.address || EMPTY_STRING },
      { key: 'city', value: address?.city || EMPTY_STRING },
      { key: 'state', value: address?.state || EMPTY_STRING },
      { key: 'postalCode', value: address?.zipCode || EMPTY_STRING },
    ] as const;

    fieldsToUpdate.forEach(({ key, value }) => {
      if (value) {
        setValue(key, value, { shouldDirty: true });
        trigger(key);
      }
    });

    setValue('lat', Number(address?.lat));
    setValue('lng', Number(address?.lng));
  };

  const handleGetListLocations = () => {
    const params: IListQueryParams = { page: DEFAULT_CURRENT_PAGE, limit: DEFAULT_LIMIT_MAX_ITEM };
    dispatch(getListLocations(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        const listLocation = res.data?.responses?.map((data) => ({
          label: data.name || EMPTY_STRING,
          value: data.id,
        }));
        setLocations(listLocation);
      })
      .catch((err) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const handleGetListReferral = () => {
    const params: IQueryCaregiverReferral = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
      status: StatusEnum.ACTIVE,
    };

    dispatch(getListCaregiverReferral(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        const listCaregiverReferral = res.data?.responses?.map((data) => ({
          label:
            data?.organizationName || getUserName(data?.firstName, data?.middleName, data?.lastName) || EMPTY_STRING,
          value: data.id,
        }));

        setCaregiverReferralList(listCaregiverReferral);
      })
      .catch((err) => {})
      .finally(() => {});
  };

  const handleGoBack = () => {
    navigate(NAVIGATE_BACK);
  };
  //#endregion Handle Function

  return (
    <form id='formRequestApplicant' className={cx('formRequestApplicant')} onSubmit={handleSubmit(handleSubmitForm)}>
      <div className={cx('formBody')}>
        <Controller
          name='profileImage'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseUploadImage
              label={t('form_request_applicant_profile_image_label')}
              textBtn={t('form_request_applicant_profile_image_content')}
              height={64}
              maxUpload={DEFAULT_NUMBER_ONE}
              defaultImageList={value ?? undefined}
              onChange={onChange}
              errorMessage={errors.profileImage?.message}
            />
          )}
        />

        <div className={cx('threeCol')}>
          <Controller
            name='firstName'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='firstName'
                label={t('form_request_applicant_first_name_label')}
                value={value || EMPTY_STRING}
                onChange={onChange}
                messageError={errors.firstName?.message}
                required
              />
            )}
          />

          <Controller
            name='middleName'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='middleName'
                label={t('form_request_applicant_middle_name_label')}
                value={value || EMPTY_STRING}
                onChange={onChange}
                messageError={errors.middleName?.message}
              />
            )}
          />

          <Controller
            name='lastName'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='lastName'
                label={t('form_request_applicant_last_name_label')}
                value={value || EMPTY_STRING}
                onChange={onChange}
                messageError={errors.lastName?.message}
                required
              />
            )}
          />
        </div>

        <div className={cx('twoCol')}>
          <Controller
            name='address'
            control={control}
            render={({ field: { value } }) => (
              <PlacesAutocomplete
                id='address'
                value={value || EMPTY_STRING}
                label={t('form_request_applicant_address_label')}
                onChange={(address) => handleChangeAddress(address)}
                messageError={errors.address?.message}
                required
              />
            )}
          />

          <Controller
            name='secondaryAddress'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='secondaryAddress'
                value={value || EMPTY_STRING}
                label={t('form_request_applicant_secondary_address_label')}
                onChange={onChange}
                messageError={errors.secondaryAddress?.message}
              />
            )}
          />
        </div>

        <div className={cx('threeCol')}>
          <Controller
            name='city'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='city'
                value={value || EMPTY_STRING}
                label={t('form_request_applicant_city_label')}
                onChange={onChange}
                messageError={errors.city?.message}
                required
              />
            )}
          />

          <Controller
            name='state'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='state'
                value={value || EMPTY_STRING}
                label={t('form_request_applicant_state_label')}
                onChange={onChange}
                messageError={errors.state?.message}
                required
              />
            )}
          />

          <Controller
            name='postalCode'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='postalCode'
                value={value || EMPTY_STRING}
                label={t('form_request_applicant_postal_code_label')}
                onChange={onChange}
                messageError={errors.postalCode?.message}
                required
              />
            )}
          />
        </div>

        <div className={cx('twoCol')}>
          <Controller
            name='email'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='email'
                value={value || EMPTY_STRING}
                label={t('form_request_applicant_email_label')}
                onChange={onChange}
                messageError={errors.email?.message}
                required
              />
            )}
          />

          <Controller
            name='externalId'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='externalId'
                value={value || EMPTY_STRING}
                label={t('form_request_applicant_external_id_label')}
                onChange={onChange}
                messageError={errors.externalId?.message}
                required
              />
            )}
          />
        </div>

        <Controller
          name='ssn'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseInput
              id='ssn'
              value={value || EMPTY_STRING}
              label={t('form_request_applicant_ssn_label')}
              onChange={onChange}
              messageError={errors.ssn?.message}
              required
            />
          )}
        />

        <div className={cx('threeCol')}>
          <Controller
            name='homePhone'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='homePhone'
                value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                label={t('form_request_applicant_home_phone_label')}
                onChange={(event) => {
                  const { value } = event.target;
                  const formattedValue = formatPhoneNumber(value);
                  onChange(formattedValue);
                }}
                messageError={errors.homePhone?.message}
              />
            )}
          />

          <Controller
            name='mobilePhone'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='mobilePhone'
                value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                label={t('form_request_applicant_mobile_phone_label')}
                onChange={(event) => {
                  const { value } = event.target;
                  const formattedValue = formatPhoneNumber(value);
                  onChange(formattedValue);
                }}
                messageError={errors.mobilePhone?.message}
                required
              />
            )}
          />

          <Controller
            name='workPhone'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='workPhone'
                value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                label={t('form_request_applicant_work_phone_label')}
                onChange={(event) => {
                  const { value } = event.target;
                  const formattedValue = formatPhoneNumber(value);
                  onChange(formattedValue);
                }}
                messageError={errors.workPhone?.message}
              />
            )}
          />
        </div>

        <div className={cx('twoCol')}>
          <Controller
            name='locationIds'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseSelect
                options={locations || []}
                label={t('form_request_applicant_location_label')}
                placeholder={t('common_select_placeholder')}
                value={value[0] || EMPTY_STRING}
                onChange={({ value }) => onChange([value])}
                errorMessage={errors.locationIds?.message}
                required
                mode='search'
              />
            )}
          />

          <Controller
            name='referrerId'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseSelect
                options={caregiverReferralList || []}
                label={t('form_request_applicant_referrer_label')}
                placeholder={t('common_select_placeholder')}
                mode='search'
                value={value || EMPTY_STRING}
                onChange={({ value }) => onChange(value)}
                errorMessage={errors.referrerId?.message}
                required
              />
            )}
          />
        </div>

        <Controller
          name='notes'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseTextarea
              id='notes'
              height={90}
              label={t('form_request_applicant_notes_label')}
              value={value || EMPTY_STRING}
              onChange={onChange}
              messageError={errors.notes?.message}
            />
          )}
        />
      </div>

      <div className={cx('formActions')}>
        <BaseButton text={t('common_go_back_label')} width={80} onClick={handleGoBack} type='button' />

        <BaseButton
          text={requestApplicantId ? t('common_update_label') : t('common_save_label')}
          typeStyle={ButtonTypeEnum.PRIMARY}
          width={80}
          type='submit'
          disabled={requestApplicantId ? !isDirty : false}
        />
      </div>
    </form>
  );
};

export default FormRequestApplicant;
