// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import caregiverListApi from '~/apis/caregiverList';
import { GET_CAREGIVER_LIST } from '~/utils/constants/actionType';
import { IListQueryParamsCaregiverList } from '~/utils/interface/caregiverList';

export const getListCaregiverList = createAsyncThunk(
  GET_CAREGIVER_LIST,
  async (payload: IListQueryParamsCaregiverList, { rejectWithValue }) => {
    try {
      const res = await caregiverListApi.getList(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
