// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { RootState } from '~/redux/store';
import {
  changeStatusReferred,
  createCaregiverReferral,
  getDetailReferrals,
  getListCaregiverApplicantReferred,
  getListCaregiverReferral,
  updateCaregiverReferral,
} from '~/thunks/caregivers/referral/referralThunk';
import { ICaregiverReferralDetail } from '~/utils/interface/caregivers/referral';

export interface CaregiverReferral {
  isRefreshCaregiverReferral: boolean;
  caregiverReferralDetail?: ICaregiverReferralDetail;
  isFetchingDetailReferral: boolean;
}

const initialState: CaregiverReferral = {
  isRefreshCaregiverReferral: false,
  isFetchingDetailReferral: false,
};

const caregiverReferralSlice = createSlice({
  name: 'caregiverReferralState',
  initialState: initialState,
  reducers: {
    setRefreshList(state, action) {
      state.isRefreshCaregiverReferral = action.payload;
    },
    resetCaregiverReferral(state) {
      state.caregiverReferralDetail = initialState.caregiverReferralDetail;
    },
  },
  extraReducers(builder) {
    // Get list Caregiver Referral
    builder
      .addCase(getListCaregiverReferral.pending, (state) => {})
      .addCase(getListCaregiverReferral.fulfilled, (state) => {
        state.isRefreshCaregiverReferral = false;
      })
      .addCase(getListCaregiverReferral.rejected, (state) => {
        state.isRefreshCaregiverReferral = false;
      });

    // Create Caregiver Referral
    builder
      .addCase(createCaregiverReferral.pending, (state) => {})
      .addCase(createCaregiverReferral.fulfilled, (state) => {})
      .addCase(createCaregiverReferral.rejected, (state) => {});

    // Get Detail Referrals
    builder
      .addCase(getDetailReferrals.pending, (state) => {
        state.isFetchingDetailReferral = true;
      })
      .addCase(getDetailReferrals.fulfilled, (state, action) => {
        state.caregiverReferralDetail = action.payload.data;
        state.isFetchingDetailReferral = false;
      })
      .addCase(getDetailReferrals.rejected, (state) => {
        state.isFetchingDetailReferral = false;
      });

    // Update Caregiver Referral
    builder
      .addCase(updateCaregiverReferral.pending, (state) => {})
      .addCase(updateCaregiverReferral.fulfilled, (state) => {})
      .addCase(updateCaregiverReferral.rejected, (state) => {});

    // Get List Caregiver Referred
    builder
      .addCase(getListCaregiverApplicantReferred.pending, (state) => {})
      .addCase(getListCaregiverApplicantReferred.fulfilled, (state) => {})
      .addCase(getListCaregiverApplicantReferred.rejected, (state) => {});

    // Get Change Status Caregiver Referred
    builder
      .addCase(changeStatusReferred.pending, (state) => {})
      .addCase(changeStatusReferred.fulfilled, (state) => {
        state.isRefreshCaregiverReferral = true;
      })
      .addCase(changeStatusReferred.rejected, (state) => {});
  },
});

// Actions
export const caregiverReferralActions = caregiverReferralSlice.actions;

// Selectors
export const selectIsRefreshCaregiverReferral = (state: RootState) =>
  state.caregiverReferralState.isRefreshCaregiverReferral;

// Reducer
const caregiverReferralReducer = caregiverReferralSlice.reducer;
export default caregiverReferralReducer;
