// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';

// Components, Layouts, Pages
import {
  BaseButton,
  BaseInput,
  BaseSelect,
  BaseSelectMultiple,
  BaseUploadImage,
  PlacesAutocomplete,
} from '~/components';

// Others
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_LIMIT_MAX_ITEM,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  NAVIGATE_BACK,
  RegExp,
} from '~/utils/constants/common';
import { IFormEmployees, IFormUpdateEmployee } from '~/utils/interface/employees';
import { defaultValues, schemaEmployees } from './helper';
import { IAddress, IBaseOption, IBaseOptionMultiple, IListQueryParams } from '~/utils/interface/common';
import { useAppDispatch } from '~/redux/hooks';
import { LoadingData } from '~/context';
import { getListLocations } from '~/thunks/crm/location/locationThunk';
import { optionsRoleEmployee, optionsRoleEmployeeSuperAdmin } from '~/mockData';
import { AccountRoleCodesEnum, ButtonTypeEnum, KeyFormEmployeeEnum, StorageEnum } from '~/utils/enum';
import { compareDataUpdate, createFormData, formatPhoneNumber } from '~/utils/helper';
import { createEmployee } from '~/thunks/employee/employeeThunk';
import { getDetailUser } from '~/thunks/user/userThunk';
import { updateEmployee } from '~/thunks/employee/employeeThunk';

// Styles, images, icons
import styles from './FormEmployees.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const FormEmployees = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { employeeId } = useParams();
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);
  const role = localStorage.getItem(StorageEnum.ROLE);

  const {
    control,
    handleSubmit,
    reset,
    trigger,
    setValue,
    formState: { errors, isDirty },
  } = useForm<IFormEmployees>({
    resolver: yupResolver(schemaEmployees(t)),
    defaultValues: defaultValues,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [listLocation, setListLocation] = useState<IBaseOptionMultiple[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  const roleOptions: IBaseOption[] = useMemo(() => {
    return role === AccountRoleCodesEnum.SUPER_ADMIN ? optionsRoleEmployeeSuperAdmin : optionsRoleEmployee;
  }, [role]);

  useEffect(() => {
    const paramsLocation: IListQueryParams = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
    };

    handleGetListLocation(paramsLocation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!employeeId) return;

    handleGetDetail(employeeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleSubmitFormEmployees = (data: IFormEmployees) => {
    if (employeeId) {
      handleEditEmployees(data);
    } else {
      handleCreateEmployees(data);
    }
  };

  const handleCreateEmployees = async (data: IFormEmployees) => {
    const compareData = compareDataUpdate(defaultValues, data);
    const formData = await createFormData(compareData, KeyFormEmployeeEnum.AVATAR_ACCOUNT);

    loading?.show();
    dispatch(createEmployee(formData))
      .unwrap()
      .then((res) => {
        handleBackToPreviousPage();
      })
      .catch((err) => {})
      .finally(() => loading?.hide());
  };

  const handleEditEmployees = async (data: IFormEmployees) => {
    if (!employeeId) return;
    loading?.show();
    const combineData: IFormEmployees = {
      ...data,
      avatarAccount:
        Array.isArray(data?.avatarAccount) && data?.avatarAccount?.length > DEFAULT_NUMBER_ZERO
          ? data?.avatarAccount
          : undefined,
      ...(employeeId && {
        isDeleteAvatar: !Array.isArray(data?.avatarAccount) || data?.avatarAccount?.length === DEFAULT_NUMBER_ZERO,
        phoneNumber: data?.phoneNumber?.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING),
      }),
    };
    const formData = await createFormData(combineData, KeyFormEmployeeEnum.AVATAR_ACCOUNT);

    const payload: IFormUpdateEmployee = {
      employeeId: employeeId,
      body: formData,
    };

    dispatch(updateEmployee(payload))
      .unwrap()
      .then((res) => {
        handleBackToPreviousPage();
      })
      .catch((err) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const handleChangeAddress = (address: IAddress) => {
    const fieldsToUpdate = [
      { key: 'address', value: address?.address || EMPTY_STRING },
      { key: 'city', value: address?.city || EMPTY_STRING },
      { key: 'state', value: address?.state || EMPTY_STRING },
      { key: 'zipCode', value: address?.zipCode || EMPTY_STRING },
    ] as const;

    fieldsToUpdate.forEach(({ key, value }) => {
      if (value) {
        setValue(key, value, { shouldDirty: true });
        trigger(key);
      }
    });

    setValue('lat', address?.lat);
    setValue('lng', address?.lng);
  };

  const handleGetListLocation = (params: IListQueryParams) => {
    dispatch(getListLocations(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        const { responses } = res.data;
        const listLocation = responses.map((location) => {
          return { label: location.name || EMPTY_STRING, value: location.id };
        });

        setListLocation(listLocation);
      })
      .catch((_err) => {})
      .finally(() => {});
  };

  const handleBackToPreviousPage = () => {
    navigate(NAVIGATE_BACK);
  };

  const handleGetDetail = (id: string) => {
    loading?.show();

    dispatch(getDetailUser(id))
      .unwrap()
      .then((res) => {
        const data = res?.data;
        if (!data) return;

        reset({
          avatarAccount: data?.avatarUrl ? [{ url: data?.avatarUrl }] : undefined,
          firstName: data?.firstName || EMPTY_STRING,
          lastName: data?.lastName || EMPTY_STRING,
          email: data?.email || EMPTY_STRING,
          role: data?.role || EMPTY_STRING,
          phoneNumber: data?.phoneNumber || EMPTY_STRING,
          address: data?.address || EMPTY_STRING,
          addressLine2: data?.addressLine2 || EMPTY_STRING,
          city: data?.city || EMPTY_STRING,
          state: data?.state || EMPTY_STRING,
          zipCode: data?.zipCode || EMPTY_STRING,
          location: data?.locations?.map((location) => location?.id) || [],
          lat: data?.lat || EMPTY_STRING,
          lng: data?.lng || EMPTY_STRING,
        });
      })
      .catch((err) => {})
      .finally(() => {
        loading?.hide();
      });
  };
  //#endregion Handle Function

  return (
    <div id='formEmployeesPage' className={cx('container')}>
      <BaseButton
        onClick={handleBackToPreviousPage}
        typeStyle={ButtonTypeEnum.ONLY_TEXT}
        iconLeft={icons.commonIconPrev}
        text={t('common_text_back')}
      />

      <div className={cx('header')}>
        <div className={cx('title')}>{employeeId ? t('form_employees_edit_title') : t('form_employees_add_title')}</div>
      </div>

      <div className={cx('body')}>
        <form className={cx('form')} onSubmit={handleSubmit(handleSubmitFormEmployees)}>
          <div className={cx('formBody')}>
            <Controller
              name='avatarAccount'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseUploadImage
                  label={t('form_employees_profile_image_label')}
                  textBtn={t('form_employees_profile_image_content')}
                  height={64}
                  maxUpload={DEFAULT_NUMBER_ONE}
                  defaultImageList={value ?? undefined}
                  onChange={onChange}
                  errorMessage={errors.avatarAccount?.message}
                />
              )}
            />

            <div className={cx('twoCol')}>
              <Controller
                name='firstName'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='firstName'
                    value={value}
                    label={t('form_employees_profile_first_name_label')}
                    onChange={onChange}
                    messageError={errors.firstName?.message}
                    required
                  />
                )}
              />

              <Controller
                name='lastName'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='lastName'
                    value={value}
                    label={t('form_employees_profile_last_name_label')}
                    onChange={onChange}
                    messageError={errors.lastName?.message}
                    required
                  />
                )}
              />
            </div>

            <div className={cx('twoCol')}>
              <Controller
                name='email'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='email'
                    value={value}
                    label={t('form_employees_profile_email_label')}
                    onChange={onChange}
                    messageError={errors.email?.message}
                    required
                  />
                )}
              />

              <Controller
                name='role'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseSelect
                    value={value}
                    options={roleOptions || []}
                    onChange={({ value }) => onChange(value)}
                    label={t('form_employees_profile_role_label')}
                    placeholder={t('common_placeholder_select')}
                    errorMessage={errors.role?.message}
                    required
                  />
                )}
              />
            </div>

            <div className={cx('twoCol')}>
              <Controller
                name='phoneNumber'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='phoneNumber'
                    value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                    label={t('form_employees_profile_phone_number_label')}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const { value } = event.target;
                      const formattedValue = formatPhoneNumber(value);
                      onChange(formattedValue);
                    }}
                    messageError={errors.phoneNumber?.message}
                  />
                )}
              />

              <Controller
                name='location'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseSelectMultiple
                    value={value || []}
                    options={listLocation || []}
                    label={t('form_employees_profile_location_label')}
                    placeholder={t('common_placeholder_select')}
                    onChange={onChange}
                    errorMessage={errors.location?.message}
                    required
                  />
                )}
              />
            </div>

            <div className={cx('twoCol')}>
              <Controller
                name='address'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <PlacesAutocomplete
                    id='address'
                    value={value}
                    label={t('form_employees_profile_address_label')}
                    onChange={(address) => handleChangeAddress(address)}
                    messageError={errors.address?.message}
                  />
                )}
              />

              <Controller
                name='addressLine2'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='addressLine2'
                    value={value}
                    label={t('form_employees_profile_address_line_2_label')}
                    onChange={onChange}
                    messageError={errors.addressLine2?.message}
                  />
                )}
              />
            </div>

            <div className={cx('threeCol')}>
              <Controller
                name='city'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='city'
                    value={value}
                    label={t('form_employees_profile_city_label')}
                    onChange={onChange}
                    messageError={errors.city?.message}
                  />
                )}
              />

              <Controller
                name='state'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='state'
                    value={value}
                    label={t('form_employees_profile_state_label')}
                    onChange={onChange}
                    messageError={errors.state?.message}
                  />
                )}
              />

              <Controller
                name='zipCode'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='zipCode'
                    value={value}
                    label={t('form_employees_profile_postal_code_label')}
                    onChange={onChange}
                    messageError={errors.zipCode?.message}
                  />
                )}
              />
            </div>
          </div>

          <div className={cx('formActions')}>
            <BaseButton text={t('common_go_back_label')} width={80} onClick={handleBackToPreviousPage} type='button' />
            <BaseButton
              text={employeeId ? t('common_update_label') : t('common_save_label')}
              typeStyle={ButtonTypeEnum.PRIMARY}
              width={80}
              type='submit'
              disabled={employeeId ? !isDirty : false}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormEmployees;
