// Libs
import classNames from 'classnames/bind';
import { TFunction } from 'i18next';
import { memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BaseTable } from '~/components';
// Others
import { ColumnTableType } from '~/utils/interface/common';
import { TimeFormatEnum, TranslationEnum } from '~/utils/enum';
import { convertDateToFormatTime, getUserName } from '~/utils/helper';
import { DEFAULT_CURRENT_PAGE, DEFAULT_LIMIT_MAX_ITEM, DOLLAR, EMPTY_STRING } from '~/utils/constants/common';
import { IExpenseCredit, IListQueryParamsExpenseCredit } from '~/utils/interface/expenseCredit';
import { getListExpenseCredits } from '~/thunks/dailyCare/dailyCareThunk';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
// Styles, images, icons
import styles from './ExpenseCreditTableDailyCare.module.scss';

type Props = {
  businessType: string;
  dateSelected: string;
};

const cx = classNames.bind(styles);

const columns = (t: TFunction<TranslationEnum.TRANSLATION>): ColumnTableType<IExpenseCredit>[] => {
  return [
    {
      key: 'date',
      title: t('caregiver_profile_daily_care_table_label_date'),
      dataIndex: 'date',
      render(value, record, index) {
        return <>{record?.date ? convertDateToFormatTime(record.date, TimeFormatEnum.MM_DD_YYYY) : EMPTY_STRING}</>;
      },
      width: '30%',
    },
    {
      key: 'amount',
      title: t('caregiver_profile_daily_care_table_label_amount'),
      dataIndex: 'amount',
      render(value, record, index) {
        return <>{record?.amount ? `${DOLLAR}${record.amount}` : EMPTY_STRING}</>;
      },
      width: '30%',
    },
    {
      key: 'client',
      title: t('caregiver_profile_daily_care_table_label_client'),
      dataIndex: 'client',
      render(value, record, index) {
        return <>{getUserName(record?.client?.firstName, record?.client?.lastName)}</>;
      },
      width: '20%',
    },
    {
      key: 'caregiver',
      title: t('caregiver_profile_daily_care_table_label_caregiver'),
      dataIndex: 'caregiver',
      render(value, record, index) {
        return <>{getUserName(record?.caregiver?.firstName, record?.caregiver?.lastName)}</>;
      },
      width: '20%',
    },
  ];
};

const ExpenseCreditTableDailyCare = (props: Props) => {
  //#region Destructuring Props
  const { businessType, dateSelected } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { clientId, caregiverId } = useParams();
  //#endregion Declare Hook

  //#region Selector
  const { isRefreshExpenseCredits } = useAppSelector((state) => state.dailyCareState);
  //#endregion Selector

  //#region Declare State
  const [isExpandTable, setIsExpandTable] = useState<boolean>(false);
  const [expenseCreditList, setExpenseCreditList] = useState<IExpenseCredit[]>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetListExpenseCredits(clientId || caregiverId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateSelected, clientId, caregiverId]);

  useEffect(() => {
    if (!isRefreshExpenseCredits) return;

    handleGetListExpenseCredits(clientId || caregiverId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshExpenseCredits]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleExpandTable = () => {
    setIsExpandTable(!isExpandTable);
  };

  const handleGetListExpenseCredits = (businessId?: string) => {
    if (!businessId) return;

    const newParams: IListQueryParamsExpenseCredit = {
      businessId: businessId,
      businessType: businessType,
      date: dateSelected,
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
    };

    dispatch(getListExpenseCredits(newParams))
      .unwrap()
      .then((res) => {
        const { responses } = res;

        setExpenseCreditList(responses);
      })
      .catch((error) => {})
      .finally(() => {});
  };
  //#endregion Handle Function

  return (
    <div id='expenseCreditTableDailyCareComponent' className={cx('container')}>
      <div className={cx('bodyWrap')}>
        <div className={cx('bodyTable')}>
          <BaseTable
            tableExpand
            title={t('caregiver_profile_daily_care_expense_credit_title')}
            columns={columns(t)}
            dataSource={expenseCreditList ?? []}
            isExpand={isExpandTable}
            handleExpandTable={handleExpandTable}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(ExpenseCreditTableDailyCare);
