// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { TimeClockEmployeeComponent } from '~/components';
// Others
import { AccountRoleCodesEnum, TimeClockQueryTypeEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './TimeClockEmployee.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const TimeClockEmployee = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return <TimeClockEmployeeComponent role={AccountRoleCodesEnum.EMPLOYEE} type={TimeClockQueryTypeEnum.EMPLOYEE} />;
};

export default TimeClockEmployee;
