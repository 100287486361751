// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
import hrApi from '~/apis/hr';
// Others
import {
  CHANGE_STATUS_UNAVAILABILITY,
  CREATE_UNAVAILABILITY_EVENT,
  DELETE_UNAVAILABILITY,
  GET_DETAIL_HR_UNAVAILABILITY,
  GET_LIST_HR,
} from '~/utils/constants/actionType';
import { IListQueryParamsHr, IPayloadChangeStatusUnavailability } from '~/utils/interface/hr';
import { IUnavailability } from '~/utils/interface/schedule';

export const getListHr = createAsyncThunk(GET_LIST_HR, async (payload: IListQueryParamsHr, { rejectWithValue }) => {
  try {
    const res = await hrApi.getListHr(payload);
    return { data: res.data.data, view: payload.view };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const changeStatusUnavailability = createAsyncThunk(
  CHANGE_STATUS_UNAVAILABILITY,
  async (payload: IPayloadChangeStatusUnavailability, { rejectWithValue }) => {
    try {
      const res = await hrApi.changeStatusUnavailability(payload);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createUnavailabilityEvent = createAsyncThunk(
  CREATE_UNAVAILABILITY_EVENT,
  async (body: IUnavailability, { rejectWithValue }) => {
    try {
      const res = await hrApi.createUnavailabilityEvent(body);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteUnavailability = createAsyncThunk(
  DELETE_UNAVAILABILITY,
  async (unavailabilityId: string, { rejectWithValue }) => {
    try {
      const res = await hrApi.deleteUnavailability(unavailabilityId);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDetailUnavailability = createAsyncThunk(
  GET_DETAIL_HR_UNAVAILABILITY,
  async (taskId: string, { rejectWithValue }) => {
    try {
      const res = await hrApi.getDetailUnavailability(taskId);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
