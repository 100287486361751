// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import {
  DELETE_DOCUMENT,
  DOWNLOAD_DOCUMENT,
  GET_DETAIL_DOCUMENT,
  GET_LIST_DOCUMENTS,
  UPLOAD_DOCUMENT,
  UPDATE_DOCUMENT,
  ASSIGN_TO_CONTACT,
} from '~/utils/constants/actionType';
import { IListQueryParamsDocument, IParamAssignToContact, IPayloadUpdateDocument } from '~/utils/interface/file';
import fileApi from '~/apis/file';

export const getListDocuments = createAsyncThunk(
  GET_LIST_DOCUMENTS,
  async (payload: IListQueryParamsDocument, { rejectWithValue }) => {
    try {
      const res = await fileApi.getListDocuments(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteDocument = createAsyncThunk(DELETE_DOCUMENT, async (fileId: string, { rejectWithValue }) => {
  try {
    const res = await fileApi.deleteDocument(fileId);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const downloadDocument = createAsyncThunk(DOWNLOAD_DOCUMENT, async (fileId: string, { rejectWithValue }) => {
  try {
    const res = await fileApi.downloadDocument(fileId);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateDocument = createAsyncThunk(
  UPDATE_DOCUMENT,
  async (payload: IPayloadUpdateDocument, { rejectWithValue }) => {
    try {
      const res = await fileApi.updateDocument(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDetailDocument = createAsyncThunk(GET_DETAIL_DOCUMENT, async (fileId: string, { rejectWithValue }) => {
  try {
    const res = await fileApi.getDetailDocument(fileId);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const uploadDocument = createAsyncThunk(UPLOAD_DOCUMENT, async (body: FormData, { rejectWithValue }) => {
  try {
    const res = await fileApi.uploadDocument(body);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const assignToContact = createAsyncThunk(
  ASSIGN_TO_CONTACT,
  async (param: IParamAssignToContact, { rejectWithValue }) => {
    try {
      const res = await fileApi.assignToContact(param);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
