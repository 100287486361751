// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useContext, useEffect, useMemo } from 'react';
// Components, Layouts, Pages
import {
  BaseButton,
  CheckTab,
  DetailActivities,
  ImageCircle,
  RequestApplicantInfoTab,
  SkillTab,
  StatusLabel,
  Tab,
} from '~/components';
// Others
import { ButtonTypeEnum, StatusEnum, TabQueryRequestApplicantDetailEnum, TypeCaregiverEnum } from '~/utils/enum';
import { EMPTY_STRING, NAVIGATE_BACK, QUERY_KEY_TAB, requestApplicantDetailTabs } from '~/utils/constants/common';
import { getUserName } from '~/utils/helper';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { RootState } from '~/redux/store';
import { LoadingData } from '~/context';
import { getDetailRequestApplicant } from '~/thunks/caregivers/applicant/applicantThunk';
import { applicantActions } from '~/thunks/caregivers/applicant/applicantSlice';
// Styles, images, icons
import styles from './RequestApplicantDetail.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const RequestApplicantDetail = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);
  const { requestApplicantId } = useParams();
  const [searchParams] = useSearchParams();
  const currentTab = searchParams.get(QUERY_KEY_TAB);
  //#endregion Declare Hook

  //#region Selector
  const { requestApplicantDetail } = useAppSelector((root: RootState) => root.applicantState);
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!requestApplicantId) return;

    handleGetDetailRequestApplicant(requestApplicantId);

    return () => {
      dispatch(applicantActions.setDataDetailRequestApplicant({}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestApplicantId]);
  //#endregion Implement Hook

  //#region Handle Function
  const renderTabContent = useMemo(() => {
    const tabToRender = currentTab || TabQueryRequestApplicantDetailEnum.INFO;
    switch (tabToRender) {
      case TabQueryRequestApplicantDetailEnum.INFO:
        return <RequestApplicantInfoTab />;

      case TabQueryRequestApplicantDetailEnum.SKILL:
        return <SkillTab />;

      case TabQueryRequestApplicantDetailEnum.CHECK:
        return <CheckTab typeRole={TypeCaregiverEnum.APPLICANT} />;

      case TabQueryRequestApplicantDetailEnum.ACTIVITIES:
        return <DetailActivities />;

      default:
        return <div className={cx('noDataAvailable')}>{t('common_empty_data')}</div>;
    }
  }, [currentTab, t, requestApplicantDetail]);

  const handleGetDetailRequestApplicant = (id: string) => {
    loading?.show();
    dispatch(getDetailRequestApplicant(id))
      .unwrap()
      .then((res) => {
        if (!res || !res.data) return;
      })
      .catch((err) => {})
      .finally(() => loading?.hide());
  };

  const handleBack = () => {
    navigate(NAVIGATE_BACK);
  };
  //#endregion Handle Function

  return (
    <div id='requestApplicantDetailTemplate' className={cx('container')}>
      <div className={cx('mainBody')}>
        <BaseButton
          onClick={handleBack}
          text={t('common_text_back')}
          typeStyle={ButtonTypeEnum.ONLY_TEXT}
          iconLeft={icons.commonIconPrev}
          height={17}
        />

        <section className={cx('headGroup')}>
          <div className={cx('basicInfoGroup')}>
            <ImageCircle
              imageUrl={requestApplicantDetail?.avatarUrl}
              firstName={requestApplicantDetail?.firstName}
              lastName={requestApplicantDetail?.lastName}
              height={91}
              width={91}
              fontSize={45}
            />

            <div className={cx('basicInfo')}>
              <div className={cx('nameGroup')}>
                <div className={cx('fullName')}>
                  {getUserName(
                    requestApplicantDetail?.firstName,
                    requestApplicantDetail?.middleName,
                    requestApplicantDetail?.lastName
                  )}
                </div>
              </div>

              {requestApplicantDetail?.status ? (
                <div className={cx('statusGroup')}>
                  <span>{t('contact_detail_status_label')}</span>
                  <span>
                    <StatusLabel
                      height={24}
                      label={
                        requestApplicantDetail?.status === StatusEnum.ACTIVE
                          ? t('common_status_active_title')
                          : requestApplicantDetail?.status === StatusEnum.INACTIVE
                          ? t('common_status_deactive_title')
                          : requestApplicantDetail?.status
                      }
                      type='text'
                    />
                  </span>
                </div>
              ) : (
                EMPTY_STRING
              )}
            </div>
          </div>

          <div className={cx('navTabGroup')}>
            <Tab items={requestApplicantDetailTabs} paddingBottom={30} />
          </div>
        </section>

        <section className={cx('tabContent')}>{renderTabContent}</section>
      </div>
    </div>
  );
};

export default RequestApplicantDetail;
