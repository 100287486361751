// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { TFunction } from 'i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
// Components, Layouts, Pages
import {
  BasePagination,
  BaseTable,
  ImageCircle,
  InputSearch,
  ModalUnderDevelopment,
  StatusLabel,
  ThreeDotTableOptions,
  ToolBar,
  ConfirmModal,
  FormEditTimeClockModal,
} from '~/components';
// Others
import {
  AccountRoleCodesEnum,
  ImageCircleTypeEnum,
  PageTimeClockEnum,
  StorageEnum,
  TimeClockQueryTypeEnum,
  TimeFormatEnum,
  TranslationEnum,
} from '~/utils/enum';
import { ColumnTableType, IPaginationResponse } from '~/utils/interface/common';
import { IListTimeClock, IQueryListTimeClock, ITimeClockResp } from '~/utils/interface/timeClock';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_LIMIT_PAGE,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  EMPTY_STRING,
  HOURS_UNIT,
} from '~/utils/constants/common';
import { convertTime, getUserName, convertMinuteToHours, convertDateToFormatTime } from '~/utils/helper';
import { deleteTimeClock, getListTimeClock } from '~/thunks/timeClock/timeClockThunk';
import useDebounce from '~/utils/customHook';
import { useAppDispatch } from '~/redux/hooks';
import { LoadingData } from '~/context';
import { selectIsRefreshTimeClockList, timeClockActions } from '~/thunks/timeClock/timeClockSlice';
import { adminRouteAbsolute, staffRouteAbsolute, superAdminRouteAbsolute } from '~/utils/constants/route';
// Styles, images, icons
import styles from './TimeClockCaregiver.module.scss';

type Props = {
  role: AccountRoleCodesEnum;
  type: TimeClockQueryTypeEnum;
};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction<TranslationEnum.TRANSLATION>,
  handleViewDetail: (id: string) => void,
  handleEdit: (id: string) => void,
  handleDelete: (id: string) => void,
  role?: AccountRoleCodesEnum
): ColumnTableType<IListTimeClock>[] => {
  return [
    {
      key: 'name',
      title: t('time_clock_caregiver_page_table_name_label'),
      render: (_, record) => {
        return !record?.account?.firstName && !record?.account?.lastName ? (
          <>{EMPTY_STRING}</>
        ) : (
          <>
            <ImageCircle
              type={record.account.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
              imageUrl={record.account.avatarUrl}
              firstName={record.account.firstName}
              lastName={record.account.lastName}
              width={24}
              height={24}
              fontSize={10}
              margin={'0 6px 0 0'}
            />
            <span>{getUserName(record.account.firstName, record.account.lastName)}</span>
          </>
        );
      },
    },
    {
      key: 'date',
      title: t('time_clock_caregiver_page_table_date_label'),
      render: (_, record) => {
        return (
          <>{record?.checkIn ? convertDateToFormatTime(record.checkIn, TimeFormatEnum.MM_DD_YYYY) : EMPTY_STRING}</>
        );
      },
    },
    {
      key: 'client',
      title: t('time_clock_caregiver_page_table_client_label'),
      render: (_, record) => {
        return (
          <span>
            {getUserName(
              record?.shift?.client?.firstName,
              record?.shift?.client?.middleName,
              record?.shift?.client?.lastName
            )}
          </span>
        );
      },
    },
    {
      key: 'hours',
      title: t('time_clock_caregiver_page_table_hours_label'),
      render: (_, record) => {
        return (
          <>{record?.duration ? `${convertMinuteToHours(Number(record.duration))} ${HOURS_UNIT}` : EMPTY_STRING}</>
        );
      },
    },
    {
      key: 'checkIn',
      title: t('time_clock_caregiver_page_table_check_in_label'),
      render: (_, record) => {
        return <>{record.checkIn ? convertTime(record.checkIn, TimeFormatEnum.HOUR_MINUTE_AM_PM) : EMPTY_STRING}</>;
      },
    },
    {
      key: 'checkOut',
      title: t('time_clock_caregiver_page_table_check_out_label'),
      render: (_, record) => {
        return <>{record.checkOut ? convertTime(record.checkOut, TimeFormatEnum.HOUR_MINUTE_AM_PM) : EMPTY_STRING}</>;
      },
    },
    {
      key: 'location',
      title: t('time_clock_caregiver_page_table_location_label'),
      render: (_, record) => {
        return <>{record?.location?.name || EMPTY_STRING}</>;
      },
    },
    {
      key: 'status',
      title: t('time_clock_caregiver_page_table_status_label'),
      render: (_, record) => {
        return <>{record.status ? <StatusLabel bgOpacity={0.1} label={record.status} /> : EMPTY_STRING}</>;
      },
    },
    {
      key: 'action',
      title: t('time_clock_caregiver_page_table_action_label'),
      render: (_, record) => {
        return (
          <ThreeDotTableOptions
            data={record}
            permissions={{
              isEdit: role === AccountRoleCodesEnum.SUPER_ADMIN,
              isDelete: false,
            }}
            onView={() => handleViewDetail(record.id)}
            onEdit={() => handleEdit(record.id)}
          />
        );
      },
      width: 50,
    },
  ];
};

const TimeClockCaregiver = (props: Props) => {
  //#region Destructuring Props
  const { role, type } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(() => Number(params?.page ?? DEFAULT_CURRENT_PAGE), [params?.page]);
  const textSearchParams = useMemo<string>(() => String(params?.textSearch || EMPTY_STRING), [params?.textSearch]);
  //#endregion Declare Hook

  //#region Selector
  const isRefreshTimeClockList = useSelector(selectIsRefreshTimeClockList);
  //#endregion Selector

  //#region Declare State
  const [timeClockList, setTimeClockList] = useState<IListTimeClock[]>([]);
  const [isShowDevelopment, setIsShowDevelopment] = useState<boolean>(false);
  const [timeClockId, setTimeClockId] = useState<string>(EMPTY_STRING);
  const [pagination, setPagination] = useState<IPaginationResponse>();
  const [searchKey, setSearchKey] = useState<string>(textSearchParams || EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey.trim(), DEFAULT_DELAY_TIME);
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false);
  const [isShowEditTimeClock, setIsShowEditTimeClock] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!textSearchParams) setSearchKey(EMPTY_STRING);
  }, [textSearchParams]);

  useEffect(() => {
    const newParams = {
      ...params,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      type: type,
    };

    loading?.show();
    handleGetListTimeClock(newParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!pagination) return;

    if (debouncedSearchKey) {
      setSearchParams({
        ...params,
        page: DEFAULT_CURRENT_PAGE.toString(),
        limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
        textSearch: debouncedSearchKey,
      });
    } else {
      const { textSearch, ...rest } = params;
      setSearchParams(rest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);

  useEffect(() => {
    if (!isRefreshTimeClockList) return;

    const newParams = {
      ...params,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      ...(debouncedSearchKey ? { textSearch: debouncedSearchKey } : {}),
      type: type,
    };

    handleGetListTimeClock(newParams);
    dispatch(timeClockActions.setRefreshList(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshTimeClockList]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value);
  };

  const handlePaginationChange = (page: number) => {
    setSearchParams({ ...params, page: page.toString(), limit: `${DEFAULT_LIMIT_PAGE}` });
  };

  const handleViewDetail = (id?: string) => {
    if (!id) return;

    const role = localStorage.getItem(StorageEnum.ROLE);

    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRouteAbsolute.timeClockCaregiver}/${id}`);
        return;
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.timeClockCaregiver}/${id}`);
        return;
      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(`${staffRouteAbsolute.timeClockCaregiver}/${id}`);
        return;
      default:
        return;
    }
  };

  const handleEdit = (id?: string) => {
    if (id) {
      setTimeClockId(id);
    }
    setIsShowEditTimeClock(!isShowEditTimeClock);
  };

  const handleOpenModalUnderDevelopment = () => {
    setIsShowDevelopment(!isShowDevelopment);
  };

  const handleGetListTimeClock = (params: IQueryListTimeClock) => {
    dispatch(getListTimeClock(params))
      .unwrap()
      .then((res) => {
        const { responses: listTimeClock, pagination }: ITimeClockResp = res?.data;
        setTimeClockList(listTimeClock);
        setPagination(pagination);
      })
      .catch((_error) => {})
      .finally(() => loading?.hide());
  };

  const handleDeleteTimeClock = () => {
    if (!timeClockId) return;

    return dispatch(deleteTimeClock(timeClockId))
      .unwrap()
      .then((_res) => {
        dispatch(timeClockActions.setRefreshList(true));
      })
      .catch((_error) => {})
      .finally(() => {
        loading?.hide();
        setIsShowConfirmDelete(false);
      });
  };

  const handleShowModalDelete = (id?: string) => {
    handleOpenModalUnderDevelopment();
    // setIsShowConfirmDelete(!isShowConfirmDelete);
    // setTimeClockId(id);
  };

  const handleTableRowClick = (data: IListTimeClock) => {
    if (!data || !data?.id) return;

    handleViewDetail(data?.id);
  };

  const handleVisibleEditTimeClock = () => {
    setTimeClockId(EMPTY_STRING);
    setIsShowEditTimeClock(!isShowEditTimeClock);
  };
  //#endregion Handle Function

  return (
    <div id='timeClockCaregiverPage' className={cx('container')}>
      <div className={cx('contentWrap')}>
        <div className={cx('headerToolbar')}>
          <ToolBar title={t('time_clock_caregiver_page_title')}>
            <InputSearch
              height={36}
              placeholder={t('common_placeholder_search_by_name')}
              onChange={handleChangeSearch}
              value={searchKey || EMPTY_STRING}
            />
          </ToolBar>
        </div>

        <div className={cx('content')}>
          <div className={cx('tableWrap')}>
            <BaseTable
              onClickRow={handleTableRowClick}
              columns={columns(t, handleViewDetail, handleEdit, handleShowModalDelete, role)}
              dataSource={timeClockList ?? []}
            />
          </div>

          <div className={cx('paginationTable')}>
            <BasePagination
              onChange={handlePaginationChange}
              defaultCurrentPage={pageSelected}
              totalItems={pagination?.totalItems}
              totalPages={pagination?.totalPages}
            />
          </div>
        </div>
      </div>

      {isShowDevelopment && <ModalUnderDevelopment onClose={handleOpenModalUnderDevelopment} />}

      {isShowEditTimeClock && (
        <FormEditTimeClockModal
          timeClockId={timeClockId}
          isOpen={isShowEditTimeClock}
          onClose={handleVisibleEditTimeClock}
          pageTimeClock={PageTimeClockEnum.CAREGIVER}
        />
      )}

      {/* <BaseDrawerModal isOpen={isShowTimeClockDetail} onClose={handleViewDetail}>
        <TimeClockDetail timeClockId={timeClockId} />
      </BaseDrawerModal> */}

      <ConfirmModal
        isOpen={isShowConfirmDelete}
        title={t('common_confirm_delete_title', {
          name: t('common_text_this_time_clock'),
        })}
        titleAction={t('common_delete_label')}
        onCancel={handleShowModalDelete}
        onAction={handleDeleteTimeClock}
        type='danger'
      />
    </div>
  );
};
export default TimeClockCaregiver;
