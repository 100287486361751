// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import timeClockApi from '~/apis/timeClock';
import {
  CREATE_TIME_CLOCK,
  GET_DETAIL_TIME_CLOCK,
  GET_LIST_TIME_CLOCK,
  UPDATE_TIME_CLOCK,
  DELETE_TIME_CLOCK,
  CHANGE_STATUS_TIME_CLOCK,
} from '~/utils/constants/actionType';
import {
  IChangeStatusTimeClockBody,
  ICreateTimeClockBody,
  IDataEditTimeClock,
  IQueryListTimeClock,
} from '~/utils/interface/timeClock';

export const getListTimeClock = createAsyncThunk(
  GET_LIST_TIME_CLOCK,
  async (payload: IQueryListTimeClock, { rejectWithValue }) => {
    try {
      const res = await timeClockApi.getListTimeClock(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createTimeClock = createAsyncThunk(
  CREATE_TIME_CLOCK,
  async (body: ICreateTimeClockBody, { rejectWithValue }) => {
    try {
      const res = await timeClockApi.createTimeClock(body);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDetailTimeClock = createAsyncThunk(
  GET_DETAIL_TIME_CLOCK,
  async (timeClockId: string, { rejectWithValue }) => {
    try {
      const res = await timeClockApi.getDetailTimeClock(timeClockId);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateTimeClock = createAsyncThunk(
  UPDATE_TIME_CLOCK,
  async (data: IDataEditTimeClock, { rejectWithValue }) => {
    try {
      const res = await timeClockApi.updateTimeClock(data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteTimeClock = createAsyncThunk(DELETE_TIME_CLOCK, async (timeClockId: string, { rejectWithValue }) => {
  try {
    const res = await timeClockApi.deleteTimeClock(timeClockId);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const changeStatusTimeClock = createAsyncThunk(
  CHANGE_STATUS_TIME_CLOCK,
  async (data: IChangeStatusTimeClockBody, { rejectWithValue }) => {
    try {
      const res = await timeClockApi.changeStatusTimeClock(data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
