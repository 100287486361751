// Libs
import { Document, Page, View } from '@react-pdf/renderer';
// Components, Layouts, Pages
import InvoiceLogoPdf from './components/logo/InvoiceLogoPdf';
import TitleInvoicePdf from './components/title/TitleInvoicePdf';
import TableInvoicePdf from './components/table/TableInvoicePdf';
import ContentInvoicePdf from './components/content/ContentInvoicePdf';
import TotalInvoicePdf from './components/total/TotalInvoicePdf';
// Others
import { IInvoiceDetail } from '~/utils/interface/invoice';
// Styles, images, icons
import { styles } from './styles';

type Props = {
  data: IInvoiceDetail;
};

const InvoicePdf = (props: Props) => {
  //#region Destructuring Props
  const { data } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.mainHeader}>
          <InvoiceLogoPdf />

          <TitleInvoicePdf data={data} />
        </View>

        <ContentInvoicePdf data={data} />

        <TableInvoicePdf data={data} />

        <TotalInvoicePdf total={data.total} />
      </Page>
    </Document>
  );
};

export default InvoicePdf;
