// Libs
import classNames from 'classnames/bind';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TFunction } from 'i18next';
import { useParams } from 'react-router-dom';
// Components, Layouts, Pages
import {
  BaseButton,
  BaseDatePicker,
  BaseInput,
  BaseSelect,
  BaseTextarea,
  CheckboxSingle,
  Loading,
  Modal,
  ModalUnderDevelopment,
} from '~/components';
// Others
import { useAppDispatch } from '~/redux/hooks';
import { EMPTY_STRING } from '~/utils/constants/common';
import { IBaseOption } from '~/utils/interface/common';
import { AuthorizationFrequencyEnum, ButtonTypeEnum, CRMEnum, InputTypeEnum, TypeRateEnum } from '~/utils/enum';
import {
  optionsAuthorizationFrequency,
  optionsAuthorizationPaidBy,
  optionsAuthorizationRate,
  optionsMockAuthorizationStatus,
} from '~/mockData';
import { IFormAuthorization, IPayRate, IPayloadEditAuthorization } from '~/utils/interface/rate';
import { createAuthorization, editAuthorization, getDetailAuthorization } from '~/thunks/rate/rateThunk';
import { removeEmptyObjects } from '~/utils/helper';
// Styles, images, icons
import styles from './FormAuthorizationModal.module.scss';

type Props = {
  taskId?: string;
  isOpen: boolean;
  payRate?: IPayRate;
  authorizationId?: string;
  onClose: () => void;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup
    .object()
    .shape({
      status: yup.string().required(t('common_message_required_error')),
      userRateId: yup.string().required(t('common_message_required_error')),
      fromDate: yup.string().required(t('common_message_required_error')),
      toDate: yup
        .string()
        .required(t('common_message_required_error'))
        .test('is-endDate-valid', t('error_from_date_less_than_to_date'), function (value) {
          const { fromDate } = this.parent;

          return !value || !fromDate || new Date(value) >= new Date(fromDate);
        }),
      authorize: yup.string().trim().required(t('common_message_required_error')),
      authorizeTypeRate: yup.string().required(t('common_message_required_error')),
      authorizeHourFrequency: yup.string().required(t('common_message_required_error')),
      paidBy: yup.string().required(t('common_message_required_error')),
      isPaysExpenses: yup.boolean().optional(),
      isDisplayUsage: yup.boolean().optional(),
      isSplitPayers: yup.boolean().optional(),
      isPaysMileage: yup.boolean().optional(),
      alternateMedicaidId: yup.string().trim().optional(),
      minimumMinutesBillTo: yup.number().optional(),
      isUseAgencyDefault: yup.boolean().optional(),
      notes: yup.string().optional(),
    })
    .required();
};

const defaultValues: IFormAuthorization = {
  status: EMPTY_STRING,
  userRateId: EMPTY_STRING,
  fromDate: EMPTY_STRING,
  toDate: EMPTY_STRING,
  authorize: EMPTY_STRING,
  authorizeTypeRate: TypeRateEnum.HOURLY,
  authorizeHourFrequency: AuthorizationFrequencyEnum.DAY,
  paidBy: EMPTY_STRING,
};

const FormAuthorizationModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, payRate, authorizationId, onClose } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { clientId } = useParams();
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    getValues,
    trigger,
    formState: { errors, isDirty },
  } = useForm<IFormAuthorization>({
    resolver: yupResolver(schema(t)),
    defaultValues: defaultValues,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isDevelopment, setIsDevelopment] = useState<boolean>(false);
  // const [initialTaskData, setInitialTaskData] = useState<IFormTask>(defaultValues);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataRate, setDataRate] = useState<IBaseOption[]>();
  //#endregion Declare State

  //#region Implement Hook
  const listPayrollRate = useMemo(() => {
    const type = getValues('authorizeTypeRate');
    return type === TypeRateEnum.HOURLY
      ? payRate?.payrollRatesHourly?.map((data) => ({
          label: data.name || EMPTY_STRING,
          value: data.id,
        }))
      : payRate?.payrollRatesPerVisit?.map((data) => ({
          label: data.name || EMPTY_STRING,
          value: data.id,
        })) || [];
  }, [payRate, watch('authorizeTypeRate'), getValues()]);

  useEffect(() => {
    setDataRate(listPayrollRate);
  }, [listPayrollRate]);

  useEffect(() => {
    handleGetDetailAuthorization();
  }, [authorizationId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleCloseDevelopment = () => {
    setIsDevelopment(!isDevelopment);
  };

  const handleSubmitForm = (data: IFormAuthorization) => {
    const newDataAuthorization = {
      ...data,
      userType: CRMEnum.CLIENT,
      userId: clientId,
    };

    if (authorizationId) {
      handleEditAuthorization(data);
    } else {
      handleCreateAuthorization(newDataAuthorization as IFormAuthorization);
    }
  };

  const handleCreateAuthorization = (data: IFormAuthorization) => {
    setIsLoading(true);

    dispatch(createAuthorization(data))
      .unwrap()
      .then((res) => {
        handleCloseFormAuthorization();
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleGetDetailAuthorization = () => {
    if (!authorizationId) return;
    setIsLoading(true);

    dispatch(getDetailAuthorization(authorizationId))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        const { id, userId, userType, ...detailAuthorization } = res.data;

        const formFormatted: IFormAuthorization = {
          ...detailAuthorization,
          ...{ authorize: detailAuthorization.authorize.toString() },
        };
        const dataDetail = removeEmptyObjects(formFormatted) as IFormAuthorization;

        reset(dataDetail);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleEditAuthorization = (data: IFormAuthorization) => {
    if (!authorizationId) return;
    setIsLoading(true);

    const payload: IPayloadEditAuthorization = {
      authorizationId: authorizationId,
      body: data,
    };

    dispatch(editAuthorization(payload))
      .unwrap()
      .then((res) => {
        handleCloseFormAuthorization();
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCloseFormAuthorization = () => {
    reset(defaultValues);
    onClose();
  };
  //#endregion Handle Function

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCloseFormAuthorization}
      title={t(authorizationId ? 'billing_policy_edit_authorization_title' : 'billing_policy_add_authorization_title')}
    >
      <FormProvider
        {...({
          control,
          watch,
          setValue,
          trigger,
          formState: { errors },
        } as UseFormReturn<IFormAuthorization>)}
      >
        <form id='formAuthorizationModalComponent' className={cx('formAuthorizationModalComponent')}>
          <div className={cx('contentModal')}>
            <div className={cx('twoCol')}>
              <Controller
                name='authId'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='authId'
                    value={value}
                    label={t('form_authorization_auth_id_title')}
                    onChange={onChange}
                    messageError={errors.authId?.message}
                  />
                )}
              />

              <Controller
                name='status'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseSelect
                    value={value || EMPTY_STRING}
                    options={optionsMockAuthorizationStatus}
                    required
                    label={t('form_authorization_status_title')}
                    placeholder={t('common_select_placeholder')}
                    onChange={(optionSelected: IBaseOption) => {
                      onChange(optionSelected?.value);
                    }}
                    errorMessage={errors.status?.message}
                  />
                )}
              />
            </div>

            <div className={cx('twoCol')}>
              <Controller
                name='fromDate'
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <BaseDatePicker
                    label={t('form_authorization_date_from_title')}
                    onDateSelected={onChange}
                    name='fromDate'
                    value={value || EMPTY_STRING}
                    placeholderText={t('common_placeholder_select')}
                    errorMessage={errors.fromDate?.message}
                    required
                  />
                )}
              />
              <Controller
                name='toDate'
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <BaseDatePicker
                    label={t('form_authorization_date_to_title')}
                    onDateSelected={onChange}
                    name='toDate'
                    value={value || EMPTY_STRING}
                    placeholderText={t('common_placeholder_select')}
                    errorMessage={errors.toDate?.message}
                    required
                  />
                )}
              />
            </div>

            <div className={cx('threeCol')}>
              <Controller
                name='authorize'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='authorize'
                    value={value}
                    label={t('form_authorization_authorize_title')}
                    onChange={onChange}
                    messageError={errors.authorize?.message}
                    required
                    type={InputTypeEnum.NUMBER}
                  />
                )}
              />
              <div className={cx('typeFrequencyContainer')}>
                <div className={cx('typeFrequency')}>
                  <Controller
                    name='authorizeTypeRate'
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <BaseSelect
                        value={value}
                        options={optionsAuthorizationRate}
                        required
                        placeholder={t('common_select_placeholder')}
                        onChange={(optionSelected: IBaseOption) => {
                          onChange(optionSelected?.value);
                        }}
                      />
                    )}
                  />
                  <span className={cx('separator')}>/</span>
                  <Controller
                    name='authorizeHourFrequency'
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <BaseSelect
                        value={value}
                        options={optionsAuthorizationFrequency}
                        required
                        placeholder={t('common_select_placeholder')}
                        onChange={(optionSelected: IBaseOption) => {
                          onChange(optionSelected?.value);
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className={cx('twoCol')}>
              <Controller
                name='paidBy'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseSelect
                    value={value}
                    options={optionsAuthorizationPaidBy}
                    required
                    label={t('form_authorization_paid_by_title')}
                    placeholder={t('common_select_placeholder')}
                    onChange={(optionSelected: IBaseOption) => {
                      onChange(optionSelected?.value);
                    }}
                    errorMessage={errors.paidBy?.message}
                  />
                )}
              />
              <Controller
                name='userRateId'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseSelect
                    value={value}
                    options={dataRate || []}
                    label={t('form_authorization_at_rate_title')}
                    required
                    placeholder={t('common_select_placeholder')}
                    onChange={(optionSelected: IBaseOption) => {
                      onChange(optionSelected?.value);
                    }}
                    errorMessage={errors.userRateId?.message}
                  />
                )}
              />
            </div>

            <div className={cx('twoCol')}>
              <Controller
                name='isPaysExpenses'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <CheckboxSingle
                    fontSize={12}
                    label={t('form_authorization_pays_expenses_title')}
                    value={value}
                    onChange={(checked: boolean, name?: string) => {
                      onChange(checked);
                    }}
                  />
                )}
              />
              <Controller
                name='isDisplayUsage'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <CheckboxSingle
                    fontSize={12}
                    label={t('form_authorization_display_usage_title')}
                    value={value}
                    onChange={(checked: boolean, name?: string) => {
                      onChange(checked);
                    }}
                  />
                )}
              />
            </div>

            <div className={cx('twoCol')}>
              <Controller
                name='alternateMedicaidId'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='alternateMedicaidId'
                    value={value}
                    label={t('form_authorization_alternate_medicaid_id_title')}
                    onChange={onChange}
                    messageError={errors.alternateMedicaidId?.message}
                  />
                )}
              />
              <div className={cx('twoCol')}>
                <Controller
                  name='minimumMinutesBillTo'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <BaseInput
                      id='authorize'
                      value={value}
                      label={t('form_authorization_minimum_minutes_title')}
                      onChange={(event) => {
                        let { value } = event.target;

                        if (!isNaN(Number(value))) {
                          onChange(Number(value));
                        }
                      }}
                      messageError={errors.minimumMinutesBillTo?.message}
                      type={InputTypeEnum.NUMBER}
                    />
                  )}
                />

                <div className={cx('isUseAgencyDefault')}>
                  <Controller
                    name='isUseAgencyDefault'
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <CheckboxSingle
                        fontSize={12}
                        label={t('form_authorization_use_agency_default_title')}
                        value={value}
                        onChange={(checked: boolean, name?: string) => {
                          onChange(checked);
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <Controller
              name='notes'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseTextarea
                  id='notes'
                  label={t('form_authorization_notes_title')}
                  value={value}
                  onChange={onChange}
                  height={100}
                  messageError={errors.notes?.message}
                />
              )}
            />
            <div className={cx('twoCol')}>
              <Controller
                name='isSplitPayers'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <CheckboxSingle
                    fontSize={12}
                    label={t('form_authorization_split_payers_title')}
                    value={value}
                    onChange={(checked: boolean, name?: string) => {
                      onChange(checked);
                    }}
                  />
                )}
              />
              <Controller
                name='isPaysMileage'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <CheckboxSingle
                    fontSize={12}
                    label={t('form_authorization_pays_mileage__title')}
                    value={value}
                    onChange={(checked: boolean, name?: string) => {
                      onChange(checked);
                    }}
                  />
                )}
              />
            </div>
          </div>

          <div className={cx('footerButton')}>
            <BaseButton
              type='button'
              text={t('common_cancel_label')}
              width={65}
              onClick={handleCloseFormAuthorization}
            />

            <BaseButton
              type='button'
              onClick={handleSubmit(handleSubmitForm)}
              text={t(authorizationId ? 'common_update_label' : 'common_save_label')}
              typeStyle={ButtonTypeEnum.PRIMARY}
              width={80}
              disabled={authorizationId ? !isDirty : false}
            />
          </div>
        </form>
      </FormProvider>
      {isDevelopment && <ModalUnderDevelopment onClose={handleCloseDevelopment} />}

      {isLoading && <Loading />}
    </Modal>
  );
};

export default FormAuthorizationModal;
