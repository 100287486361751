// Libs
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import classNames from 'classnames/bind';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
// Components, Layouts, Pages
import { BaseInput } from '~/components';
// Others
import { mockDataDefaultPosition } from '~/mockData';
import useUserLocation from '~/utils/hooks/useUserLocation';
import {
  DEFAULT_MAP_ZOOM,
  DEFAULT_NUMBER_ZERO,
  DEFAULT_STATUS_OK,
  EMPTY_STRING,
  HEIGHT_FULL,
  WIDTH_FULL,
} from '~/utils/constants/common';
import { IAddress, IPlaceGoogleMap, IPosition } from '~/utils/interface/common';
import { AddressTypesEnum, TimeFormatEnum } from '~/utils/enum';
import { convertTime } from '~/utils/helper';
// Styles, images, icons
import styles from './BaseGoogleMap.module.scss';

type Props = {
  title?: string;
  position?: IPosition | null;
  places?: IPlaceGoogleMap[] | null;
  width?: number | string;
  height?: number | string;
  triggerLocate?: boolean;
  onGetAddress?: (value: string) => void;
  onGetPosition?: (value: IPosition) => void;
  onGetInfoAddress?: (address: IAddress) => void;
};

const cx = classNames.bind(styles);

const BaseGoogleMap = (props: Props) => {
  //#region Destructuring Props
  const {
    position,
    places,
    width,
    height,
    triggerLocate = false,
    onGetAddress,
    onGetPosition,
    title,
    onGetInfoAddress,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const { userLocation, getUserLocation } = useUserLocation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [positionSelected, setPositionSelected] = useState<IPosition>();
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();
  const [options, setOptions] = useState<{ id: string; value: string }[]>([]);
  const [activeMarker, setActiveMarker] = useState<number | null>(null);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!triggerLocate) return;

    getUserLocation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerLocate]);

  useEffect(() => {
    if (!position) {
      const defaultPosition: IPosition =
        triggerLocate && userLocation
          ? { lat: userLocation?.latitude, lng: userLocation?.longitude }
          : mockDataDefaultPosition;

      setPositionSelected(defaultPosition);
      onGetPosition && onGetPosition(defaultPosition);
    }

    if (position) {
      setPositionSelected(position);
      onGetPosition && onGetPosition(position);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position, userLocation]);

  useEffect(() => {
    if (positionSelected) {
      getAddressFromCoordinates(positionSelected.lat, positionSelected.lng);
    }
  }, [positionSelected]);
  //#endregion Implement Hook

  //#region Handle Function
  const getAddressFromCoordinates = async (lat: number, lng: number) => {
    try {
      const results = await getGeocode({ location: { lat, lng } });
      if (results.length > DEFAULT_NUMBER_ZERO) {
        const addressComponents = results[DEFAULT_NUMBER_ZERO].address_components;

        let zipcode = EMPTY_STRING;
        let state = EMPTY_STRING;
        let city = EMPTY_STRING;
        let country = EMPTY_STRING;

        addressComponents.forEach((component) => {
          if (component.types.includes(AddressTypesEnum.POSTAL_CODE)) {
            zipcode = component.long_name;
          }
          if (component.types.includes(AddressTypesEnum.AREA_LEVEL_1)) {
            state = component.long_name;
          }
          if (component.types.includes(AddressTypesEnum.LOCALITY)) {
            city = component.long_name;
          }
          if (component.types.includes(AddressTypesEnum.COUNTRY)) {
            country = component.short_name;
          }
        });

        const addressFull: IAddress = {
          zipCode: zipcode,
          state: state,
          city: city,
          country: country,
        };

        onGetInfoAddress && onGetInfoAddress(addressFull);
      }
    } catch (error) {}
  };

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleSelect = async (address: string) => {
    setValue(address, false);
    onGetAddress && onGetAddress(address);
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    setPositionSelected({ lat, lng });
    onGetPosition && onGetPosition({ lat, lng });
  };

  const handleSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setValue(value);
    if (value === EMPTY_STRING) {
      clearSuggestions();
      setOptions([]);
      onGetAddress && onGetAddress(value);
      return;
    }
    onGetAddress && onGetAddress(value);

    if (status === DEFAULT_STATUS_OK) {
      const options = data.map(({ place_id: placeId, description }) => ({
        id: placeId,
        value: description,
      }));
      setOptions(options);
    }
  };

  const renderSuggestions = () =>
    options.map((suggestion) => {
      const { id, value } = suggestion;

      return (
        <li key={id} onClick={() => handleSelect(suggestion.value)} className={cx('itemLocation')}>
          {value}
        </li>
      );
    });

  const handleActiveMarker = (markerId: number) => {
    if (markerId === activeMarker) {
      return;
    }
    setActiveMarker(markerId);
  };

  const handleCloseMarker = () => {
    setActiveMarker(null);
  };

  const handleOnLoad = (map: google.maps.Map) => {
    if (!places) return;

    const bounds = new google.maps.LatLngBounds();
    places.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
  };
  //#endregion Handle Function

  return (
    <div id='baseGoogleMapComponent' className={cx('container')} style={{ width, height }}>
      {/* <div ref={ref} className={cx('locationInput')}>
        <BaseInput
          id='location'
          name='location'
          label={t('modal_add_time_clock_location_label')}
          placeholder={t('modal_add_time_clock_location_label')}
          onChange={handleSearch}
          value={value}
        />
        {status === DEFAULT_STATUS_OK && <ul className={cx('locationList')}>{renderSuggestions()}</ul>}
      </div> */}
      {title && <div className={cx('label')}>{title}</div>}

      <div className={cx('googleMapContainer')}>
        <GoogleMap
          {...(Array.isArray(places) && places?.length > DEFAULT_NUMBER_ZERO && { onLoad: handleOnLoad })}
          mapContainerStyle={{ width: WIDTH_FULL, height: HEIGHT_FULL }}
          center={positionSelected}
          zoom={DEFAULT_MAP_ZOOM}
          onClick={handleCloseMarker}
        >
          {positionSelected && !places && <Marker position={positionSelected} />}

          {Array.isArray(places) &&
            places?.length > DEFAULT_NUMBER_ZERO &&
            places.map(({ id, location, startTime, position }) => {
              return (
                <Marker key={id} position={position} onClick={() => handleActiveMarker(id)}>
                  {activeMarker === id && (
                    <InfoWindow onCloseClick={handleCloseMarker}>
                      <>
                        <p className={cx('startTime')}>
                          {startTime && convertTime(startTime, TimeFormatEnum.HOUR_MINUTE_AM_PM)}
                        </p>
                      </>
                    </InfoWindow>
                  )}
                </Marker>
              );
            })}
        </GoogleMap>
      </div>
    </div>
  );
};

export default BaseGoogleMap;
