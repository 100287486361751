// Libs
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { Fragment } from 'react/jsx-runtime';
// Components, Layouts, Pages
// Others
import { DEFAULT_CURRENCY, EMPTY_STRING } from '~/utils/constants/common';
import { CurrencyEnum, TimeFormatEnum } from '~/utils/enum';
import { convertDateToFormatTime, convertEnumToString, formatCurrency, getUserName } from '~/utils/helper';
import { IShiftInvoice } from '~/utils/interface/invoice';
// Styles, images, icons

type Props = {
  data: IShiftInvoice[];
};

const RowTableInvoicePdf = (props: Props) => {
  //#region Destructuring Props
  const { data } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const rows = data.map((item, index) => {
    return (
      <View style={[styles.row, { borderBottomWidth: index !== data.length - 1 ? 1 : 0 }]} key={index}>
        <View style={styles.col}>
          <Text>{item.endDate ? convertDateToFormatTime(item.endDate, TimeFormatEnum.MM_DD_YYYY) : EMPTY_STRING}</Text>
        </View>

        <View style={styles.caregiverCol}>
          <Text>{getUserName(item?.caregiver?.firstName, item?.caregiver?.lastName)}</Text>
        </View>

        <View style={styles.col}>
          <Text>{item.rate?.rateType ? convertEnumToString(item.rate?.rateType) : EMPTY_STRING}</Text>
        </View>

        <View style={styles.col}>
          <Text>{item.hours || EMPTY_STRING}</Text>
        </View>

        <View style={styles.col}>
          <Text>{item?.rate?.price ? formatCurrency(CurrencyEnum.USD, item?.rate?.price) : DEFAULT_CURRENCY}</Text>
        </View>

        <View style={[styles.col, styles.price]}>
          <Text>{item?.totalClient ? formatCurrency(CurrencyEnum.USD, item?.totalClient) : DEFAULT_CURRENCY}</Text>
        </View>
      </View>
    );
  });
  //#endregion Handle Function

  return <Fragment>{rows}</Fragment>;
};

export default RowTableInvoicePdf;

export const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: '#333333',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 'auto',
    width: '100%',
  },

  lastRow: {
    borderBottomWidth: 0,
  },

  caregiverCol: {
    flex: 0.25,
    borderRightColor: '#333333',
    borderRightWidth: 1,
    paddingLeft: 8,
    paddingRight: 8,
    alignItems: 'flex-start',
    justifyContent: 'center',
    height: 24,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  col: {
    flex: 0.15,
    borderRightColor: '#333333',
    borderRightWidth: 1,
    paddingRight: 8,
    paddingLeft: 8,
    height: 24,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  price: {
    textAlign: 'right',
    borderRightWidth: 0,
  },
});
