// Libs
import classNames from 'classnames/bind';
import { memo, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components, Layouts, Pages
import { BaseSelect, BaseInput, CheckboxSingle } from '~/components';

// Others
import { IFormPayrollRate } from '~/utils/interface/rate';
import { useAppSelector } from '~/redux/hooks';
import { getUserName } from '~/utils/helper';
import { EMPTY_STRING, OPTION_ROUNDING_METHOD } from '~/utils/constants/common';
import { InputTypeEnum } from '~/utils/enum';

// Styles, images, icons
import styles from './PayHoursCalculationSettings.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const PayHoursCalculationSettings = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    setValue,
    resetField,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext<IFormPayrollRate>();
  //#endregion Declare Hook

  //#region Selector
  const { caregiverDetail } = useAppSelector((state) => state.caregiverState);
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='payHoursCalculationSettingsComponent' className={cx('payHoursCalculationSettingsComponent')}>
      <div className={cx('content')}>
        <div className={cx('groupItem')}>
          <h1 className={cx('title')}>
            {t('settings_payroll_rate_completed_shifts_title', {
              userName: getUserName(caregiverDetail?.firstName, caregiverDetail?.lastName),
            })}
          </h1>

          <div className={cx('questionContent')}>{t('settings_payroll_rate_completed_shifts_content')}</div>

          <div className={cx('questionContent')}>
            {t('settings_payroll_rate_completed_shifts_the_selection_content')}
          </div>
        </div>

        <div className={cx('groupItem')}>
          <h1 className={cx('title')}>{t('settings_payroll_rate_calculate_pay_title')}</h1>
          <div className={cx('labelRoundingMethod')}>{t('settings_payroll_rate_rounding_method_title')}</div>

          <Controller
            name='payHour.roundingMethod'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseSelect
                value={value}
                options={OPTION_ROUNDING_METHOD}
                placeholder={t('common_select_placeholder')}
                width={600}
                onChange={({ value }) => {
                  onChange(value);
                }}
              />
            )}
          />
        </div>

        <div className={cx('twoCol')}>
          <Controller
            name='payHour.minimumMinutes'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='country'
                label={t('settings_payroll_rate_minimum_minutes_title')}
                value={value}
                onChange={onChange}
                type={InputTypeEnum.NUMBER}
                textAlign='right'
              />
            )}
          />

          <div className={cx('colHolidayRate')}>
            <Controller
              name='payHour.isDefaultMinimumMinutes'
              control={control}
              render={({ field: { value, onChange } }) => (
                <CheckboxSingle
                  label={t('settings_payroll_rate_holiday_rate_label')}
                  value={value}
                  onChange={(checked: boolean, name?: string) => {
                    onChange(checked);
                  }}
                />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(PayHoursCalculationSettings);
