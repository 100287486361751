// Libs
import classNames from 'classnames/bind';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
import { ClientScheduleTabAdminPage } from '~/pages';
import {
  AddFiles,
  BaseButton,
  CarePlanDayCenterClientTab,
  CarePlanHomeCareClientTab,
  CarePlanTab,
  DetailActivities,
  DetailContactTab,
  DocumentsTab,
  HomeSafetyAssessmentTab,
  ImageCircle,
  InfoClientTab,
  FormBillingPolicyTab,
  RelateDeletedModal,
  StatusLabel,
  Tab,
  DailyCareClientTab,
  BillingInvoiceTab,
} from '~/components';
// Others
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { getDetailClients } from '~/thunks/crm/clients/clientsThunk';
import { CODE_MESSAGE_CLIENT_DELETED, EMPTY_STRING, NAVIGATE_BACK, QUERY_KEY_TAB } from '~/utils/constants/common';
import {
  AddFileEnum,
  ButtonTypeEnum,
  CareTypeClientEnum,
  CLientStatusEnum,
  CRMEnum,
  ImageCircleTypeEnum,
  TabNameTransKeyEnum,
  TabQueryClientDetailEnum,
  TypeFilesDocumentEnum,
} from '~/utils/enum';
import { createFormData, getUserName } from '~/utils/helper';
import { IClientDetail } from '~/utils/interface/crm/clients';
import { ErrorData, ITabItem } from '~/utils/interface/common';
import { IUploadFileDocument } from '~/utils/interface/file';
import { uploadDocument } from '~/thunks/file/fileThunk';
import { fileActions } from '~/thunks/file/fileSlice';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './CrmClientDetail.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const CrmDetail = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clientId } = useParams();
  const [searchParams] = useSearchParams();
  const currentTab = searchParams.get(QUERY_KEY_TAB);
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [dataProfileClient, setDataProfileClient] = useState<IClientDetail>();
  const [isShowClientDeleted, setIsShowClientDeleted] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!clientId) return;

    handleGetDetailClient(clientId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  const clientDetailTabs: ITabItem[] = useMemo(() => {
    return [
      { tabName: TabNameTransKeyEnum.INFO, tabParam: TabQueryClientDetailEnum.INFO },
      { tabName: TabNameTransKeyEnum.DAILY_CARE, tabParam: TabQueryClientDetailEnum.DAILY_CARE },
      {
        tabName: TabNameTransKeyEnum.CARE_PLAN,
        tabParam: TabQueryClientDetailEnum.CARE_PLAN,
        disable: dataProfileClient?.careClientType === CareTypeClientEnum.TRANSPORTATION,
      },
      { tabName: TabNameTransKeyEnum.DOCUMENTS, tabParam: TabQueryClientDetailEnum.DOCUMENTS },
      { tabName: TabNameTransKeyEnum.SHIFT_SCHEDULE, tabParam: TabQueryClientDetailEnum.SHIFT_SCHEDULE },
      { tabName: TabNameTransKeyEnum.BILLING, tabParam: TabQueryClientDetailEnum.BILLING },
      { tabName: TabNameTransKeyEnum.BILLING_POLICY, tabParam: TabQueryClientDetailEnum.BILLING_POLICY },
      { tabName: TabNameTransKeyEnum.CONTACT, tabParam: TabQueryClientDetailEnum.CONTACT },
      { tabName: TabNameTransKeyEnum.ACTIVITIES, tabParam: TabQueryClientDetailEnum.ACTIVITIES },
      {
        tabName: TabNameTransKeyEnum.HOME_SAFETY_ASSESSMENT,
        tabParam: TabQueryClientDetailEnum.HOME_SAFETY_ASSESSMENT,
      },
    ];
  }, [dataProfileClient]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleBack = () => {
    navigate(NAVIGATE_BACK);
  };

  const handleGetDetailClient = (clientId: string) => {
    loading?.show();

    dispatch(getDetailClients(clientId))
      .unwrap()
      .then((res) => {
        const responses: IClientDetail = res?.data;
        setDataProfileClient(responses);
      })
      .catch((error) => {
        const { code } = error?.response?.data as ErrorData;

        if (code === CODE_MESSAGE_CLIENT_DELETED) setIsShowClientDeleted(!isShowClientDeleted);
      })
      .finally(() => {
        loading?.hide();
      });
  };

  const handleShowClientDeleted = () => {
    setIsShowClientDeleted(!isShowClientDeleted);

    navigate(NAVIGATE_BACK);
  };

  const handleUploadDocument = async (data: IUploadFileDocument) => {
    if (!dataProfileClient?.id) return;

    const dataCreate = { ...data, businessId: dataProfileClient?.id, type: TypeFilesDocumentEnum.CLIENT_DOCUMENT };

    const formData = await createFormData(dataCreate, AddFileEnum.FILES);

    loading?.show();

    dispatch(uploadDocument(formData))
      .unwrap()
      .then((response) => {
        dispatch(fileActions.setRefreshList(true));
      })
      .catch((error) => {})
      .finally(() => {
        loading?.hide();
      });
  };
  //#endregion Handle Function

  const renderCarePlanTab = (data?: IClientDetail) => {
    if (!data?.careClientType) return;

    switch (data?.careClientType) {
      case CareTypeClientEnum.DAY_CENTER:
        return <CarePlanDayCenterClientTab />;

      case CareTypeClientEnum.OTHER:
        return <CarePlanTab />;

      case CareTypeClientEnum.HOME_CARE:
        return <CarePlanHomeCareClientTab />;

      default:
        return <div className={cx('noDataAvailable')}>{t('common_empty_data')}</div>;
    }
  };

  const renderTabContent = useMemo(() => {
    const tabToRender = currentTab || TabQueryClientDetailEnum.INFO;
    switch (tabToRender) {
      case TabQueryClientDetailEnum.INFO:
        return <InfoClientTab />;

      case TabQueryClientDetailEnum.DAILY_CARE:
        return <DailyCareClientTab />;

      case TabQueryClientDetailEnum.CARE_PLAN:
        return renderCarePlanTab(dataProfileClient);

      case TabQueryClientDetailEnum.DOCUMENTS:
        return clientId && <DocumentsTab clientId={clientId} />;

      case dataProfileClient?.type === CRMEnum.CLIENT && TabQueryClientDetailEnum.SHIFT_SCHEDULE:
        return clientId && <ClientScheduleTabAdminPage />;

      case TabQueryClientDetailEnum.BILLING:
        return <BillingInvoiceTab />;

      case TabQueryClientDetailEnum.BILLING_POLICY:
        return <FormBillingPolicyTab />;

      case TabQueryClientDetailEnum.ACTIVITIES:
        return <DetailActivities />;

      case TabQueryClientDetailEnum.CONTACT:
        return <DetailContactTab />;

      case TabQueryClientDetailEnum.HOME_SAFETY_ASSESSMENT:
        return <HomeSafetyAssessmentTab />;

      default:
        // Handle Return Tab Later
        return <div className={cx('noDataAvailable')}>{t('common_empty_data')}</div>;
    }
  }, [currentTab, clientId, dataProfileClient, t]);

  return (
    <div
      id='clientDetailPage'
      className={cx('container', { documents: currentTab === TabQueryClientDetailEnum.DOCUMENTS })}
    >
      <div className={cx('mainBody', { documents: currentTab === TabQueryClientDetailEnum.DOCUMENTS })}>
        {dataProfileClient && (
          <>
            <BaseButton
              onClick={handleBack}
              text={t('common_text_back')}
              typeStyle={ButtonTypeEnum.ONLY_TEXT}
              iconLeft={icons.commonIconPrev}
              height={17}
            />

            <section className={cx('headGroup')}>
              <div className={cx('basicInfoGroup')}>
                <ImageCircle
                  type={dataProfileClient?.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
                  width={91}
                  height={91}
                  imageUrl={dataProfileClient?.avatarUrl}
                  fontSize={45}
                  firstName={dataProfileClient?.firstName}
                  lastName={dataProfileClient?.lastName}
                />

                <div className={cx('basicInfo')}>
                  <div className={cx('nameGroup')}>
                    <div className={cx('fullName')}>
                      {getUserName(
                        dataProfileClient?.firstName,
                        dataProfileClient?.middleName,
                        dataProfileClient?.lastName
                      )}
                    </div>
                    <p className={cx('statusGroup')}>
                      {t('client_detail_label_status')}
                      <span className={cx('value')}>
                        {dataProfileClient?.status ? (
                          <StatusLabel
                            height={24}
                            label={
                              dataProfileClient?.status === CLientStatusEnum.ACTIVE
                                ? t('common_status_active_title')
                                : dataProfileClient?.status === CLientStatusEnum.INACTIVE
                                ? t('common_status_deactive_title')
                                : dataProfileClient?.status
                            }
                            type='text'
                          />
                        ) : (
                          EMPTY_STRING
                        )}
                      </span>
                    </p>
                    <p className={cx('valueGroup')}>
                      {t('client_detail_label_referred_by')}
                      <span className={cx('value')}>
                        {dataProfileClient?.referrer?.organizationName
                          ? dataProfileClient?.referrer?.organizationName
                          : getUserName(
                              dataProfileClient?.referrer?.firstName,
                              dataProfileClient?.referrer?.middleName,
                              dataProfileClient?.referrer?.lastName
                            )}
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className={cx('navTabGroup')}>
                <Tab items={clientDetailTabs} paddingBottom={30} />
              </div>
            </section>

            <section className={cx('tabContent')}>{renderTabContent}</section>
          </>
        )}
      </div>

      {dataProfileClient && currentTab === TabQueryClientDetailEnum.DOCUMENTS && (
        <AddFiles title={t('add_document_title')} onSubmitFormSuccess={handleUploadDocument} />
      )}

      <RelateDeletedModal isOpen={isShowClientDeleted} onClose={handleShowClientDeleted} />
    </div>
  );
};

export default CrmDetail;
