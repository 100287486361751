// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useContext, useEffect, useMemo } from 'react';
// Components, Layouts, Pages
import {
  BaseButton,
  ClientProspectContactTab,
  ContactInfoTab,
  DocumentContactTab,
  ImageCircle,
  StatusLabel,
  Tab,
} from '~/components';
// Others
import { contactDetailTabs, EMPTY_STRING, NAVIGATE_BACK, QUERY_KEY_TAB } from '~/utils/constants/common';
import { LoadingData } from '~/context';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { getDetailContact } from '~/thunks/crm/contact/contactThunk';
import { RootState } from '~/redux/store';
import { getUserName } from '~/utils/helper';
import { contactActions } from '~/thunks/crm/contact/contactSlice';
import { ButtonTypeEnum, StatusEnum, TabQueryContactDetailEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './CrmContactDetail.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const CrmContactDetail = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { contactId } = useParams();
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const currentTab = searchParams.get(QUERY_KEY_TAB);
  //#endregion Declare Hook

  //#region Selector
  const { contactDetail } = useAppSelector((root: RootState) => root.contactState);
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!contactId) return;

    handleGetDetailContact(contactId);

    return () => {
      dispatch(contactActions.setDataDetailContact({}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactId]);
  //#endregion Implement Hook

  //#region Handle Function
  const renderTabContent = useMemo(() => {
    const tabToRender = currentTab || TabQueryContactDetailEnum.INFO;
    switch (tabToRender) {
      case TabQueryContactDetailEnum.INFO:
        return <ContactInfoTab />;

      case TabQueryContactDetailEnum.CLIENTS_PROSPECTS:
        return contactId && <ClientProspectContactTab contactId={contactId} />;

      case TabQueryContactDetailEnum.DOCUMENTS:
        return <DocumentContactTab />;

      default:
        return <div className={cx('noDataAvailable')}>{t('common_empty_data')}</div>;
    }
  }, [currentTab, t, contactDetail]);

  const handleGetDetailContact = (id: string) => {
    loading?.show();
    dispatch(getDetailContact(id))
      .unwrap()
      .then((res) => {
        if (!res || !res.data) return;
      })
      .catch((err) => {})
      .finally(() => loading?.hide());
  };

  const handleBack = () => {
    navigate(NAVIGATE_BACK);
  };
  //#endregion Handle Function

  return (
    <div id='crmContactDetail' className={cx('container')}>
      <div className={cx('mainBody')}>
        <>
          <BaseButton
            onClick={handleBack}
            text={t('common_text_back')}
            typeStyle={ButtonTypeEnum.ONLY_TEXT}
            iconLeft={icons.commonIconPrev}
            height={17}
          />

          <section className={cx('headGroup')}>
            <div className={cx('basicInfoGroup')}>
              <ImageCircle
                firstName={contactDetail?.organization || contactDetail?.firstName}
                lastName={contactDetail?.lastName}
                height={91}
                width={91}
                fontSize={45}
              />

              <div className={cx('basicInfo')}>
                <div className={cx('nameGroup')}>
                  <div className={cx('fullName')}>
                    {contactDetail?.organization || getUserName(contactDetail?.firstName, contactDetail?.lastName)}
                  </div>
                </div>

                {contactDetail?.status ? (
                  <div className={cx('statusGroup')}>
                    <span>{t('contact_detail_status_label')}</span>
                    <span>
                      <StatusLabel
                        height={24}
                        label={
                          contactDetail?.status === StatusEnum.ACTIVE
                            ? t('common_status_active_title')
                            : contactDetail?.status === StatusEnum.INACTIVE
                            ? t('common_status_deactive_title')
                            : contactDetail?.status
                        }
                        type='text'
                      />
                    </span>
                  </div>
                ) : (
                  EMPTY_STRING
                )}
              </div>
            </div>

            <div className={cx('navTabGroup')}>
              <Tab items={contactDetailTabs} paddingBottom={30} />
            </div>
          </section>

          <section className={cx('tabContent')}>{renderTabContent}</section>
        </>
      </div>
    </div>
  );
};

export default CrmContactDetail;
