// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Components, Layouts, Pages
import {
  BaseButton,
  BasePagination,
  BaseTable,
  FormAddCampaign,
  InputSearch,
  ModalUnderDevelopment,
  ThreeDotTableOptions,
  ToolBar,
} from '~/components';

// Others
import { ButtonTypeEnum, TimeFormatEnum, TranslationEnum } from '~/utils/enum';
import { ColumnTableType, IPaginationResponse } from '~/utils/interface/common';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_LIMIT_PAGE,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { ICampaign, IQueryParamsCampaign } from '~/utils/interface/crm/campaign';
import { convertDateToFormatTime } from '~/utils/helper';
import { useAppDispatch } from '~/redux/hooks';
import { LoadingData } from '~/context';
import { getListCampaign } from '~/thunks/crm/campaign/campaignThunk';
import useDebounce from '~/utils/customHook';
import { campaignActions, selectIsRefreshCampaignList } from '~/thunks/crm/campaign/campaignSlice';

// Styles, images, icons
import styles from './CrmCampaign.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction<TranslationEnum.TRANSLATION>,
  handleEditCampaign: (data: ICampaign) => void
): ColumnTableType<ICampaign>[] => {
  return [
    {
      key: 'name',
      title: t('crm_campaign_name_title'),
      render(value, record, index) {
        return <>{record.name ? record.name : EMPTY_STRING}</>;
      },
      width: '25%',
    },

    {
      key: 'subject',
      title: t('crm_campaign_name_subject_title'),
      render(value, record, index) {
        return <>{record.subject || EMPTY_STRING}</>;
      },
      width: '50%',
    },

    {
      key: 'emailSentTotal',
      title: t('crm_campaign_name_email_title'),
      render(value, record, index) {
        return <>{record.emailSentTotal ?? EMPTY_STRING}</>;
      },
      width: '15%',
    },

    {
      key: 'emailSentDate',
      title: t('crm_campaign_sent_date_title'),
      render(value, record, index) {
        return (
          <>
            {record.emailSentDate
              ? convertDateToFormatTime(record.emailSentDate, TimeFormatEnum.MM_DD_YYYY)
              : EMPTY_STRING}
          </>
        );
      },
      width: '20%',
    },

    {
      key: 'action',
      title: t('crm_contacts_table_title_action'),
      render(value, record, index) {
        return (
          <ThreeDotTableOptions
            data={record}
            permissions={{
              isView: false,
              isEdit: true,
              isDelete: false,
            }}
            onEdit={() => handleEditCampaign(record)}
          />
        );
      },
      width: 50,
    },
  ];
};
const CrmCampaignTemplate = () => {
  //#region Destructuring Props
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  const isRefreshStageList = useSelector(selectIsRefreshCampaignList);
  //#endregion Selector

  //#region Declare State
  const [isDevelopment, setIsDevelopment] = useState<boolean>(false);
  const [isShowModalAddCampaign, setIsShowModalAddCampaign] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const [campaignList, setCampaignList] = useState<ICampaign[]>([]);
  const [pagination, setPagination] = useState<IPaginationResponse>();
  const pageSelected = useMemo<number>(() => Number(params?.page ?? DEFAULT_CURRENT_PAGE), [params?.page]);
  const textSearchParams = useMemo<string>(() => String(params?.textSearch || EMPTY_STRING), [params?.textSearch]);
  const [searchKey, setSearchKey] = useState<string>(textSearchParams || EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey.trim() || EMPTY_STRING, DEFAULT_DELAY_TIME);
  const [selectCampaign, setSelectCampaign] = useState<ICampaign>();

  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const { tab, ...restParams } = params;

    const newParams = {
      ...restParams,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
    };

    handleGetListCampaign(newParams);
  }, [params]);

  useEffect(() => {
    if (!textSearchParams) setSearchKey(EMPTY_STRING);
  }, [textSearchParams]);

  useEffect(() => {
    if (!pagination) return;

    if (debouncedSearchKey) {
      setSearchParams({
        ...params,
        page: DEFAULT_CURRENT_PAGE.toString(),
        limit: DEFAULT_LIMIT_PAGE.toString(),
        textSearch: debouncedSearchKey,
      });
    } else {
      const { textSearch, ...rest } = params;
      setSearchParams(rest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);

  useEffect(() => {
    if (!isRefreshStageList) return;

    const { tab, ...restParams } = params;

    const newParams = {
      ...restParams,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      ...(debouncedSearchKey ? { textSearch: debouncedSearchKey } : {}),
    };

    handleGetListCampaign(newParams);
    dispatch(campaignActions.setRefreshList(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshStageList]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value);
  };

  const handleAddCampaignModal = () => {
    setIsShowModalAddCampaign(true);
  };

  const handleShowDevelopment = () => {
    setIsDevelopment(!isDevelopment);
  };

  const handlePaginationChange = (page: number) => {
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleGetListCampaign = (params: IQueryParamsCampaign) => {
    loading?.show();

    dispatch(getListCampaign(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        const { responses, pagination } = res?.data;
        setCampaignList(responses);
        setPagination(pagination);
      })
      .catch((error) => {})
      .finally(() => loading?.hide());
  };

  const handleCloseAddCampaign = () => {
    setIsShowModalAddCampaign(false);
    setSelectCampaign(undefined);
  };

  const handleEditCampaign = (data: ICampaign) => {
    if (!data.id) return;

    setSelectCampaign(data);
    setIsShowModalAddCampaign(true);
  };
  //#endregion Handle Function

  return (
    <div id='CrmCampaignTemplate' className={cx('container')}>
      <ToolBar
        title={
          <div className={cx('crmTitle')}>
            <span>{t('crm_campaign_title')}</span>
          </div>
        }
      >
        <InputSearch
          height={36}
          placeholder={t('common_placeholder_search_by_name')}
          onChange={handleChangeSearch}
          value={searchKey}
        />

        <BaseButton
          typeStyle={ButtonTypeEnum.PRIMARY}
          iconLeft={icons.commonIconPlus}
          text={t('crm_campaign_add_new_title')}
          onClick={handleAddCampaignModal}
          height={36}
        />
      </ToolBar>

      <div className={cx('content')}>
        <div className={cx('tableWrap')}>
          <BaseTable
            columns={columns(t, handleEditCampaign)}
            dataSource={campaignList ?? []}
            onClickRow={handleEditCampaign}
          />
        </div>

        <div className={cx('paginationTable')}>
          <BasePagination
            onChange={handlePaginationChange}
            defaultCurrentPage={pageSelected}
            totalItems={pagination?.totalItems}
            totalPages={pagination?.totalPages}
          />
        </div>
      </div>

      {isShowModalAddCampaign && (
        <FormAddCampaign
          isOpen={isShowModalAddCampaign}
          campaignId={selectCampaign?.id}
          onClose={handleCloseAddCampaign}
        />
      )}

      {isDevelopment && <ModalUnderDevelopment onClose={handleShowDevelopment} />}
    </div>
  );
};

export default CrmCampaignTemplate;
