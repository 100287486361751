import { urlApiTask } from '~/utils/constants/api';
import { BaseResponse, IListDataResponse, IQueryParams } from '~/utils/interface/common';
import axiosClient from '~/apis/axiosClient';
import {
  IPayloadUpdateTask,
  ITask,
  ITaskDetail,
  IFormTask,
  ISelectTask,
  IQueryListTaskSelect,
} from '~/utils/interface/task';

const taskApi = {
  getListTask(params: IQueryParams) {
    const url = `${urlApiTask.getListTask}`;
    return axiosClient.get<BaseResponse<IListDataResponse<ITask[]>>>(url, { params });
  },

  getDetailTask(taskId: string) {
    const url = `${urlApiTask.getTaskDetail(taskId)}`;
    return axiosClient.get<BaseResponse<ITaskDetail>>(url);
  },

  deleteTask(taskId: string) {
    const url = `${urlApiTask.deleteTask(taskId)}`;
    return axiosClient.delete<BaseResponse>(url);
  },

  createTask(body: IFormTask) {
    const url = `${urlApiTask.createTask}`;
    return axiosClient.post<BaseResponse>(url, body);
  },

  updateTask({ taskId, body }: IPayloadUpdateTask) {
    const url = `${urlApiTask.updateTask(taskId)}`;
    return axiosClient.put<BaseResponse<ITaskDetail>>(url, body);
  },

  getSelectTask(params: IQueryListTaskSelect) {
    const url = `${urlApiTask.getSelectTask}`;
    return axiosClient.get<BaseResponse<IListDataResponse<ISelectTask[]>>>(url, { params });
  },
};

export default taskApi;
