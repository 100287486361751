// Libs
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { TFunction } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import {
  Modal,
  BaseButton,
  BaseInput,
  BaseUploadImage,
  BaseTextarea,
  BaseSelect,
  BaseDatePicker,
  Loading,
} from '~/components';
// Others
import {
  ButtonTypeEnum,
  CRMEnum,
  ReferrerEnum,
  StorageEnum,
  UpdateClientModalEnum,
  UpdateClientNameModalEnum,
} from '~/utils/enum';
import { IBaseOption, IListDataResponse, IListQueryParams } from '~/utils/interface/common';
import { DEFAULT_CURRENT_PAGE, DEFAULT_LIMIT_MAX_ITEM, EMPTY_STRING } from '~/utils/constants/common';
import { mockDataState, mockOptionReferrer, mockOptionsTimezone } from '~/mockData';
import { compareDataUpdate, createFormData, getUserName } from '~/utils/helper';
import { useAppDispatch } from '~/redux/hooks';
import { clientActions } from '~/thunks/crm/clients/clientsSlice';
import { getDetailClients, updateClient } from '~/thunks/crm/clients/clientsThunk';
import { IFormUpdateClient } from '~/utils/interface/crm/clients';
import { IFormUpdateClientCrm } from '~/utils/interface/crm/clients';
import { CrmClientModalType } from '~/utils/type/common';
import { getListReferrals } from '~/thunks/crm/referral/referralThunk';
import { IListReferrals } from '~/utils/interface/crm/referral';
import { getListLocations } from '~/thunks/crm/location/locationThunk';
// Styles, images, icons
import styles from './CrmUpdateClientModal.module.scss';

type Props = {
  type: CrmClientModalType;
  clientId: string;
  isOpen: boolean;
  onClose: () => void;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup.object({
    firstName: yup.string().required(
      t('common_error_message_required', {
        message: UpdateClientModalEnum.FIRST_NAME,
      })
    ),
    lastName: yup.string().required(
      t('common_error_message_required', {
        message: UpdateClientModalEnum.LAST_NAME,
      })
    ),
    address: yup.string().required(
      t('common_error_message_required', {
        message: UpdateClientModalEnum.ADDRESS,
      })
    ),
    city: yup.string().required(
      t('common_error_message_required', {
        message: UpdateClientModalEnum.CITY,
      })
    ),
    state: yup.string().required(
      t('common_error_message_required', {
        message: UpdateClientModalEnum.STATE,
      })
    ),
    postalCode: yup.string().required(
      t('common_error_message_required', {
        message: UpdateClientModalEnum.POST_CODE,
      })
    ),
    mobilePhone: yup.string().required(
      t('common_error_message_required', {
        message: UpdateClientModalEnum.MOBILE_PHONE,
      })
    ),
    locationId: yup.string().required(
      t('common_error_message_required', {
        message: UpdateClientModalEnum.LOCATION,
      })
    ),
    referrerId: yup.string().required(
      t('common_error_message_required', {
        message: UpdateClientModalEnum.REFERRER,
      })
    ),
    timeZone: yup.string().required(
      t('common_error_message_required', {
        message: UpdateClientModalEnum.TIME_ZONE,
      })
    ),
  });
};

const DATA_DEFAULT: IFormUpdateClientCrm = {
  firstName: EMPTY_STRING,
  lastName: EMPTY_STRING,
  address: EMPTY_STRING,
  city: EMPTY_STRING,
  state: EMPTY_STRING,
  postalCode: EMPTY_STRING,
  mobilePhone: EMPTY_STRING,
  referrerId: EMPTY_STRING,
  locationId: EMPTY_STRING,
  timeZone: EMPTY_STRING,
};

const CrmUpdateClientModal = (props: Props) => {
  //#region Destructuring Props
  const { clientId, isOpen, onClose, type } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const accountId = localStorage.getItem(StorageEnum.USER_ID);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<IFormUpdateClientCrm>({
    resolver: yupResolver(schema(t)),
    defaultValues: DATA_DEFAULT,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [dataFormClientCrm, setDataFormClientCrm] = useState<IFormUpdateClientCrm>(DATA_DEFAULT);
  const [listReferralSelect, setListReferralSelect] = useState<IBaseOption[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [locations, setLocations] = useState<IBaseOption[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    fetchData();
  }, [clientId, isOpen]);

  const fetchData = async () => {
    if (!clientId || !isOpen) return;
    await handleGetReferral();
    await handleGetDetailClient(clientId);
  };

  useEffect(() => {
    handleGetListLocations();
  }, []);

  //#endregion Implement Hook

  //#region Handle Function
  const handleGetReferral = async () => {
    setIsLoading(true);
    await dispatch(getListReferrals({}))
      .unwrap()
      .then((res) => {
        const { responses, pagination }: IListDataResponse<IListReferrals[]> = res.data;

        const options = responses.map((data) => {
          return {
            label: data.organization ? data.organization : getUserName(data.firstName, data.middleName, data.lastName),
            value: data.id,
          };
        });

        setListReferralSelect([...mockOptionReferrer, ...options]);
      })
      .catch((error) => {});
  };

  const handleGetDetailClient = async (clientId: string) => {
    await dispatch(getDetailClients(clientId))
      .unwrap()
      .then((response) => {
        const { data } = response;
        const formattedData = {
          firstName: data.firstName || EMPTY_STRING,
          middleName: data.middleName || EMPTY_STRING,
          lastName: data.lastName || EMPTY_STRING,
          dob: data.dob || EMPTY_STRING,
          address: data.address || EMPTY_STRING,
          secondaryAddress: data.secondaryAddress || EMPTY_STRING,
          city: data.city || EMPTY_STRING,
          state: data.state || EMPTY_STRING,
          postalCode: data.postalCode || EMPTY_STRING,
          notes: data.notes || EMPTY_STRING,
          ssn: data.ssn || EMPTY_STRING,
          homePhone: data.homePhone || EMPTY_STRING,
          mobilePhone: data.mobilePhone || EMPTY_STRING,
          workPhone: data.workPhone || EMPTY_STRING,
          email: data.email || EMPTY_STRING,
          locationId: data?.location?.id || EMPTY_STRING,
          externalId: data.externalId || EMPTY_STRING,
          timeZone: data.timeZone || EMPTY_STRING,
          referrerId: data.referrer?.id || ReferrerEnum.None,
          avatarAccount: data.avatarUrl ? [{ url: data.avatarUrl }] : undefined,
        };
        reset(formattedData);

        setDataFormClientCrm(formattedData);
      })
      .catch((error) => {})
      .finally(() => setIsLoading(false));
  };

  const handleSave = async (data: IFormUpdateClientCrm) => {
    const dataChanges = compareDataUpdate(dataFormClientCrm, data) as IFormUpdateClientCrm;
    const updatedDataFormClientCrm: IFormUpdateClientCrm = { ...dataChanges };
    if (data.referrerId === ReferrerEnum.Self) {
      updatedDataFormClientCrm.referrerId = accountId || EMPTY_STRING;
    } else if (data.referrerId === ReferrerEnum.None) {
      updatedDataFormClientCrm.referrerId = ReferrerEnum.None.toUpperCase();
    }
    const formData = await createFormData(updatedDataFormClientCrm, UpdateClientNameModalEnum.AVATAR_ACCOUNT);

    setIsLoading(true);

    if (clientId) {
      const payload: IFormUpdateClient = {
        clientId: clientId,
        body: formData,
      };

      dispatch(updateClient(payload))
        .unwrap()
        .then((res) => {
          onClose && onClose();
          dispatch(clientActions.setRefreshList(true));
        })
        .catch((err) => {})
        .finally(() => setIsLoading(false));
    }
  };

  const renderTitleModal = (type: CrmClientModalType) => {
    switch (type) {
      case CRMEnum.CLIENT:
        return t('crm_edit_client_modal_title');
      case CRMEnum.PROSPECT:
        return t('crm_edit_prospect_modal_title');
      default:
        return EMPTY_STRING;
    }
  };

  const handleGetListLocations = () => {
    const params: IListQueryParams = { page: DEFAULT_CURRENT_PAGE, limit: DEFAULT_LIMIT_MAX_ITEM };
    dispatch(getListLocations(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        const listLocation = res.data?.responses?.map((data) => ({
          label: data.name || EMPTY_STRING,
          value: data.id,
        }));
        setLocations(listLocation);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };
  //#endregion Handle Function

  return (
    <Modal title={renderTitleModal(type)} isOpen={isOpen} onClose={onClose}>
      <form id='crmClientModalComponent' className={cx('crmClientModalComponent')} onSubmit={handleSubmit(handleSave)}>
        <div className={cx('contentModal')}>
          <Controller
            name={'avatarAccount'}
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseUploadImage
                label={t('crm_add_modal_profile_image_label')}
                textBtn={t('crm_add_modal_profile_image_placeholder')}
                height={64}
                defaultImageList={value}
                onChange={onChange}
              />
            )}
          />

          <div className={cx('threeCol')}>
            <Controller
              name='firstName'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='firstName'
                  label={t('crm_add_modal_first_name_label')}
                  //placeholder={t('crm_add_modal_first_name_label')}
                  value={value}
                  onChange={onChange}
                  messageError={errors.firstName?.message}
                  required
                />
              )}
            />

            <Controller
              name='middleName'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='middleName'
                  label={t('crm_add_modal_middle_name_label')}
                  //placeholder={t('crm_add_modal_middle_name_label')}
                  onChange={onChange}
                  value={value || EMPTY_STRING}
                />
              )}
            />

            <Controller
              name='lastName'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='lastName'
                  label={t('crm_add_modal_last_name_label')}
                  //placeholder={t('crm_add_modal_last_name_label')}
                  value={value || EMPTY_STRING}
                  onChange={onChange}
                  messageError={errors.lastName?.message}
                  required
                />
              )}
            />
          </div>

          {type === CRMEnum.CLIENT && (
            <Controller
              name='dob'
              control={control}
              render={({ field: { onChange, value } }) => (
                <BaseDatePicker
                  label={t('crm_add_modal_date_of_birth_label')}
                  placeholderText={t('common_select_placeholder')}
                  name='dob'
                  value={value || EMPTY_STRING}
                  onDateSelected={onChange}
                />
              )}
            />
          )}

          <div className={cx('twoCol')}>
            <Controller
              name='address'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='address'
                  label={t('crm_add_modal_address_label')}
                  //placeholder={t('crm_add_modal_address_label')}
                  value={value || EMPTY_STRING}
                  onChange={onChange}
                  messageError={errors.address?.message}
                  required
                />
              )}
            />

            <Controller
              name='secondaryAddress'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='secondaryAddress'
                  label={t('crm_add_modal_secondary_address_label')}
                  //placeholder={t('crm_add_modal_secondary_address_label')}
                  value={value || EMPTY_STRING}
                  onChange={onChange}
                />
              )}
            />
          </div>

          <div className={cx('threeCol')}>
            <Controller
              name='city'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='city'
                  label={t('crm_add_modal_city_label')}
                  //placeholder={t('crm_add_modal_city_label')}
                  value={value || EMPTY_STRING}
                  onChange={onChange}
                  messageError={errors.city?.message}
                  required
                />
              )}
            />

            <Controller
              name='state'
              control={control}
              render={({ field: { onChange, value } }) => (
                <BaseSelect
                  options={mockDataState || []}
                  label={t('crm_add_modal_state_label')}
                  placeholder={t('common_select_placeholder')}
                  onChange={onChange}
                  errorMessage={errors.state?.message}
                  value={value || EMPTY_STRING}
                  required
                />
              )}
            />

            <Controller
              name='postalCode'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='postalCode'
                  label={t('crm_add_modal_postal_code_label')}
                  //placeholder={t('crm_add_modal_postal_code_label')}
                  value={value || EMPTY_STRING}
                  onChange={onChange}
                  messageError={errors.postalCode?.message}
                  required={true}
                />
              )}
            />
          </div>

          <Controller
            name='notes'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseTextarea
                id='notes'
                label={t('crm_add_modal_notes_label')}
                //placeholder={t('crm_add_modal_notes_placeholder')}
                value={value || EMPTY_STRING}
                onChange={onChange}
              />
            )}
          />

          <Controller
            name='ssn'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='ssn'
                label={t('crm_add_modal_ssn_label')}
                //placeholder={t('crm_add_modal_ssn_label')}
                value={value || EMPTY_STRING}
                onChange={onChange}
              />
            )}
          />

          <div className={cx('twoCol')}>
            <Controller
              name='homePhone'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='homePhone'
                  label={t('crm_add_modal_home_phone_label')}
                  //placeholder={t('crm_add_modal_home_phone_label')}
                  value={value || EMPTY_STRING}
                  onChange={onChange}
                />
              )}
            />

            <Controller
              name='mobilePhone'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='mobilePhone'
                  label={t('crm_add_modal_mobile_phone_label')}
                  //placeholder={t('crm_add_modal_mobile_phone_label')}
                  value={value || EMPTY_STRING}
                  onChange={onChange}
                  messageError={errors.mobilePhone?.message}
                  required
                />
              )}
            />
          </div>

          <div className={cx('twoCol')}>
            <Controller
              name='workPhone'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='workPhone'
                  label={t('crm_add_modal_work_phone_label')}
                  //placeholder={t('crm_add_modal_work_phone_label')}
                  value={value || EMPTY_STRING}
                  onChange={onChange}
                />
              )}
            />

            <Controller
              name='email'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='email'
                  label={t('crm_add_modal_email_label')}
                  //placeholder={t('crm_add_modal_email_label')}
                  value={value || EMPTY_STRING}
                  onChange={onChange}
                />
              )}
            />
          </div>

          <div className={cx('twoCol')}>
            <Controller
              name='locationId'
              control={control}
              render={({ field: { onChange, value } }) => (
                <BaseSelect
                  options={locations || []}
                  label={t('crm_add_modal_location_label')}
                  placeholder={t('common_select_placeholder')}
                  onChange={({ value }) => onChange(value)}
                  value={value || EMPTY_STRING}
                  errorMessage={errors.locationId?.message}
                  required
                  mode='search'
                />
              )}
            />

            <Controller
              name='referrerId'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseSelect
                  options={listReferralSelect}
                  label={t('crm_add_modal_referrer_label')}
                  placeholder={t('common_select_placeholder')}
                  onChange={onChange}
                  value={value || EMPTY_STRING}
                  required
                  mode='search'
                />
              )}
            />
          </div>

          {type === CRMEnum.CLIENT ? (
            <div className={cx('twoCol')}>
              <Controller
                name='externalId'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='externalId'
                    label={t('crm_add_modal_external_id_label')}
                    //placeholder={t('crm_add_modal_external_id_label')}
                    value={value || EMPTY_STRING}
                    onChange={onChange}
                  />
                )}
              />

              <Controller
                name='timeZone'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <BaseSelect
                    options={mockOptionsTimezone || []}
                    label={t('crm_add_modal_time_zone_label')}
                    placeholder={t('common_select_placeholder')}
                    onChange={onChange}
                    value={value || EMPTY_STRING}
                    errorMessage={errors.timeZone?.message}
                    required
                  />
                )}
              />
            </div>
          ) : (
            <Controller
              name='timeZone'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseSelect
                  options={mockOptionsTimezone || []}
                  label={t('crm_add_modal_time_zone_label')}
                  placeholder={t('common_select_placeholder')}
                  onChange={onChange}
                  value={value || EMPTY_STRING}
                  errorMessage={errors.timeZone?.message}
                  required
                />
              )}
            />
          )}
        </div>
        <div className={cx('footerButton')}>
          {/* {clientId && (
            <BaseButton
              iconLeft={icons.commonIconTrash}
              typeStyle={ButtonTypeEnum.ONLY_TEXT}
              text={t('common_delete_label')}
              onClick={onClose}
              color={RED_FF0000}
            />
          )} */}
          <BaseButton text={t('common_cancel_label')} width={65} onClick={onClose} />
          <BaseButton
            type='submit'
            text={t('common_save_label')}
            typeStyle={ButtonTypeEnum.PRIMARY}
            width={80}
            disabled={clientId ? !isDirty : false}
          />
        </div>
      </form>

      {isLoading && <Loading />}
    </Modal>
  );
};

export default CrmUpdateClientModal;
