// Libs
import moment from 'moment';
import { Event } from 'react-big-calendar';
// Others
import { IEventSchedule } from '~/utils/interface/schedule';

export const prepareMonthEventsOpenShiftCalendar = (data: IEventSchedule[]): Event[] => {
  const monthEvents: Event[] = [];
  for (let dataEvent of data) {
    let monthEvent = monthEvents.find((monthEvent: Event) => {
      return moment(monthEvent.start).isSame(moment(dataEvent.startDate), 'date');
    });

    if (!monthEvent) {
      monthEvent = {
        start: moment(dataEvent.startDate).toDate(),
        end: moment(dataEvent.startDate).endOf('date').toDate(),
        resource: [],
      };
      monthEvents.push(monthEvent);
    }

    monthEvent.resource.push(dataEvent);
  }
  return monthEvents;
};

export const prepareOpenShiftEventsData = (data: IEventSchedule[]): Event[] => {
  return data.map((event) => {
    const startAt = moment(`${event.startDate} ${event.startTime}`, 'YYYY-MM-DD HH:mm:ss').toDate();
    const endAt = moment(`${event.startDate} ${event.endTime}`, 'YYYY-MM-DD HH:mm:ss').toDate();

    return {
      start: startAt,
      end: endAt,
      resource: [event],
    };
  });
};
