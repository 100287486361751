// Libs
import classNames from 'classnames/bind';
// Components, Layouts, Pages
import { CaregiverReferralFormTemplate } from '~/pages';
// Context
// Others
// Styles, images, icons
import styles from './CaregiverReferralForm.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const CaregiverReferralForm = (props: Props) => {
  //#region Destructuring Props

  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='caregiverReferralFormPage' className={cx('container')}>
      <CaregiverReferralFormTemplate />
    </div>
  );
};

export default CaregiverReferralForm;
