import { createAsyncThunk } from '@reduxjs/toolkit';
import contactApi from '~/apis/crm/contact';
import {
  CREATE_CONTACT,
  GET_DETAIL_CONTACT,
  CHANGE_STATUS_CONTACT,
  GET_LIST_CONTACT,
  UPDATE_CONTACT,
} from '~/utils/constants/actionType';
import {
  IFormContact,
  IListQueryParamsContact,
  IPayloadChangeStatusContact,
  IPayloadUpdateContact,
} from '~/utils/interface/crm/contact';

export const getListContact = createAsyncThunk(
  GET_LIST_CONTACT,
  async (payload: IListQueryParamsContact, { rejectWithValue }) => {
    try {
      const res = await contactApi.getListContact(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createContact = createAsyncThunk(CREATE_CONTACT, async (body: IFormContact, { rejectWithValue }) => {
  try {
    const res = await contactApi.createContact(body);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDetailContact = createAsyncThunk(GET_DETAIL_CONTACT, async (id: string, { rejectWithValue }) => {
  try {
    const res = await contactApi.getDetailContact(id);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const changeStatusContact = createAsyncThunk(
  CHANGE_STATUS_CONTACT,
  async (payload: IPayloadChangeStatusContact, { rejectWithValue }) => {
    try {
      const res = await contactApi.changeStatusContact(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateContact = createAsyncThunk(
  UPDATE_CONTACT,
  async (payload: IPayloadUpdateContact, { rejectWithValue }) => {
    try {
      const res = await contactApi.updateContact(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
