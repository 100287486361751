// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Components, Layouts, Pages
import {
  BaseButton,
  PayHoursCalculationBillingPolicy,
  BillRateBillingPolicy,
  TravelReimbursementBillingPolicy,
  InvoicingBillingPolicy,
  CareManagementBillingPolicy,
  OvertimeSettingBillingPolicy,
  AuthorizationBillingPolicy,
} from '~/components';

// Others
import { ButtonTypeEnum, CRMEnum, TypeRateEnum } from '~/utils/enum';
import {
  IBodyPayrollRate,
  IFormBillingPolicy,
  IFormPayrollRate,
  IListQueryPayroll,
  IPayRate,
  IPayrollRate,
} from '~/utils/interface/rate';
import { initialValues, billingPolicySchema } from '../helper';
import { LoadingData } from '~/context';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { DEFAULT_LIMIT_MAX_ITEM, DEFAULT_NUMBER_ONE, DEFAULT_NUMBER_ZERO } from '~/utils/constants/common';
import { getListPayroll, updatePayrollRate } from '~/thunks/rate/rateThunk';
import { rateActions } from '~/thunks/rate/rateSlice';

// Styles, images, icons
import styles from './FormBillingPolicy.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const FormBillingPolicy = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    watch,
    trigger,
    setValue,
    reset,
    resetField,
    getValues,
    formState: { errors, isDirty },
  } = useForm<IFormBillingPolicy>({
    resolver: yupResolver(billingPolicySchema(t)),
    defaultValues: initialValues,
  });
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const { clientId } = useParams();
  //#endregion Declare Hook

  //#region Selector
  const { isRefreshPayrollRates } = useAppSelector((state) => state.rateState);
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    return () => {
      dispatch(rateActions.resetPayroll());
    };
  }, []);

  useEffect(() => {
    fetchPayroll();
  }, [clientId]);

  useEffect(() => {
    if (isRefreshPayrollRates) {
      fetchPayroll();
    }
  }, [isRefreshPayrollRates]);
  //#endregion Implement Hook

  //#region Handle Function
  const fetchPayroll = () => {
    if (!clientId) return;
    loading?.show();

    const params: IListQueryPayroll = {
      page: DEFAULT_NUMBER_ONE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
      userType: CRMEnum.CLIENT,
      rateType: TypeRateEnum.HOURLY,
      userId: clientId,
    };

    [TypeRateEnum.HOURLY, TypeRateEnum.PER_VISIT].forEach((rateType) => handleGetListPayroll({ ...params, rateType }));
  };

  const handleGetListPayroll = (params: IListQueryPayroll) => {
    dispatch(getListPayroll(params))
      .unwrap()
      .then((res) => {
        const { data, rateType } = res;
        switch (rateType) {
          case TypeRateEnum.HOURLY:
            setValue('payRate.payrollRatesHourly', data.data.responses);
            break;
          case TypeRateEnum.PER_VISIT:
            setValue('payRate.payrollRatesPerVisit', data.data.responses);
            break;
        }

        const { careManagement, invoiceRate, overtime, payHour, travelRate } = data.data;
        careManagement && setValue('careManagement', careManagement);
        invoiceRate && setValue('invoiceRate', invoiceRate);
        overtime && setValue('overtime', overtime);
        travelRate && setValue('travelRate', travelRate);
        payHour && setValue('payHour', payHour);
      })
      .catch((err) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const getPayrollRates = (data: IFormPayrollRate): IPayrollRate[] => {
    const hourlyRates: IPayrollRate[] =
      data.payRate?.payrollRatesHourly?.map((item) => ({
        rateId: item.rateId.toString(),
        price: item.price || DEFAULT_NUMBER_ZERO,
      })) || [];

    const perVisitRates: IPayrollRate[] =
      data.payRate?.payrollRatesPerVisit?.map((item) => ({
        rateId: item.rateId.toString(),
        price: item.price || DEFAULT_NUMBER_ZERO,
      })) || [];

    return [...hourlyRates, ...perVisitRates];
  };

  const handleSubmitPayroll = async (data: IFormBillingPolicy) => {
    if (!clientId) return;
    loading?.show();

    try {
      const payload: IBodyPayrollRate = {
        userId: clientId,
        userType: CRMEnum.CLIENT,
        rates: getPayrollRates(data),
        careManagement: data.careManagement,
        overtime: data.overtime,
        payHour: data.payHour,
        travelRate: data.travelRate,
        invoiceRate: data.invoiceRate,
      };
      await dispatch(updatePayrollRate(payload)).unwrap();
    } catch {
    } finally {
      loading?.hide();
    }
  };

  //#endregion Handle Function

  return (
    <FormProvider
      {...({
        control,
        watch,
        setValue,
        trigger,
        reset,
        resetField,
        getValues,
        formState: { errors },
      } as UseFormReturn<IFormBillingPolicy>)}
    >
      <div id='formBillingPolicyTabComponent' className={cx('formBillingPolicyTabComponent')}>
        <form
          id='formBillingPolicyTabComponent'
          onSubmit={handleSubmit(handleSubmitPayroll)}
          className={cx('container')}
        >
          <div className={cx('formBody')}>
            <BillRateBillingPolicy />

            <CareManagementBillingPolicy />

            <InvoicingBillingPolicy />

            <TravelReimbursementBillingPolicy />

            <PayHoursCalculationBillingPolicy />

            <OvertimeSettingBillingPolicy />

            <AuthorizationBillingPolicy payRate={getValues().payRate} />
          </div>

          <div className={cx('footerButton')}>
            <BaseButton
              text={t('common_save_label')}
              type='submit'
              typeStyle={ButtonTypeEnum.PRIMARY}
              width={80}
              disabled={!isDirty}
            />
          </div>
        </form>
      </div>
    </FormProvider>
  );
};

export default FormBillingPolicy;
