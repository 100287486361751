// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import React, { useState, useMemo, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// Components, Layouts, Pages
import {
  BaseButton,
  BaseFilter,
  BasePagination,
  BaseSelect,
  BaseTable,
  InputSearch,
  ModalChangeStatus,
  ModalUnderDevelopment,
  ThreeDotTableOptions,
  ToolBar,
} from '~/components';

// Others
import { ColumnTableType, IListDataResponse, IPaginationResponse } from '~/utils/interface/common';
import { AccountRoleCodesEnum, ButtonTypeEnum, StatusFilterEnum, StorageEnum, TranslationEnum } from '~/utils/enum';
import { formatPhoneNumber, getUserName } from '~/utils/helper';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_LIMIT_PAGE,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  optionsStatusReferralSource,
  optionStatusReferral,
} from '~/utils/constants/common';
import { IFilterCaregiverReferral } from '~/utils/interface/caregiver';
import useDebounce from '~/utils/customHook';
import { adminRouteAbsolute, staffRouteAbsolute, superAdminRouteAbsolute } from '~/utils/constants/route';
import { LoadingData } from '~/context';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { changeStatusReferred, getListCaregiverReferral } from '~/thunks/caregivers/referral/referralThunk';
import { IListCaregiverReferral, IQueryCaregiverReferral } from '~/utils/interface/caregivers/referral';
import { RootState } from '~/redux/store';

// Styles, images, icons
import styles from './CaregiverReferralShowAll.module.scss';
import { icons } from '~/assets';

type Props = {
  role: string;
};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction<TranslationEnum.TRANSLATION>,
  handleEdit: (id: string) => void,
  handleView: (id: string) => void,
  handleChangeStatus: (record: IListCaregiverReferral) => void
): ColumnTableType<IListCaregiverReferral>[] => {
  return [
    {
      key: 'organizationName',
      title: t('caregiver_referral_show_all_organization_title'),
      dataIndex: 'organizationName',
      render(_, record) {
        return <>{record?.organizationName || EMPTY_STRING}</>;
      },
      width: '15%',
    },
    {
      key: 'name',
      title: t('caregiver_referral_show_all_name_title'),
      render(_, record) {
        return <>{getUserName(record?.firstName, record?.middleName, record?.lastName)}</>;
      },
      width: '13%',
    },
    {
      key: 'mobilePhone',
      title: t('caregiver_referral_show_all_phone_number_title'),
      dataIndex: 'mobilePhone',
      render(_, record) {
        return <>{record?.mobilePhone ? formatPhoneNumber(record?.mobilePhone) : EMPTY_STRING}</>;
      },
      width: '12%',
    },
    {
      key: 'address',
      title: t('caregiver_referral_show_all_address_title'),
      dataIndex: 'address',
      render(_, record) {
        return <>{record?.address}</>;
      },
      width: '18%',
    },
    {
      key: 'email',
      title: t('caregiver_referral_show_all_email_title'),
      dataIndex: 'email',
      render(_, record) {
        return <>{record?.email || EMPTY_STRING}</>;
      },
      width: '15%',
    },
    {
      key: 'totalApplicants',
      title: t('caregiver_referral_show_all_applicants_title'),
      dataIndex: 'totalApplicants',
      render(_, record) {
        return <>{record?.totalApplicants || DEFAULT_NUMBER_ZERO}</>;
      },
      width: '10%',
    },
    {
      key: 'totalCaregivers',
      title: t('caregiver_referral_show_all_caregivers_title'),
      dataIndex: 'totalCaregivers',
      render(_, record) {
        return <>{record?.totalCaregivers || DEFAULT_NUMBER_ZERO}</>;
      },
      width: '10%',
    },
    {
      key: 'action',
      title: t('caregiver_referral_show_all_action_title'),
      render(_, record) {
        return (
          <ThreeDotTableOptions
            data={record}
            permissions={{
              isEdit: true,
              isChangeStatus: true,
              isDelete: false,
            }}
            onEdit={() => handleEdit(record.id)}
            onView={() => handleView(record.id)}
            onChangeStatus={() => handleChangeStatus(record)}
          />
        );
      },
      width: 50,
    },
  ];
};

const CaregiverReferralShowAll = (props: Props) => {
  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const role = localStorage.getItem(StorageEnum.ROLE);
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  const { isRefreshCaregiverReferral } = useAppSelector((state: RootState) => state.caregiverReferralState);
  //#endregion Selector

  //#region Declare State
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const statusParams = useMemo<string>(() => String(params?.status || EMPTY_STRING), [params?.status]);
  const pageSelected = useMemo<number>(() => Number(params?.page ?? DEFAULT_CURRENT_PAGE), [params?.page]);
  const textSearchParams = useMemo<string>(() => String(params?.textSearch || EMPTY_STRING), [params?.textSearch]);
  const [searchKey, setSearchKey] = useState<string>(textSearchParams || EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey.trim() || EMPTY_STRING, DEFAULT_DELAY_TIME);
  const [pagination, setPagination] = useState<IPaginationResponse>();
  const [isShowUnderDevelopment, setIsShowUnderDevelopment] = useState<boolean>(false);
  const [caregiverList, setCaregiverList] = useState<IListCaregiverReferral[]>([]);
  const [isChangeStatus, setIsChangeStatus] = useState<boolean>(false);
  const [recordReferral, setRecordReferral] = useState<IListCaregiverReferral>();
  const [isLoadingChangeStatus, setIsLoadingChangeStatus] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const { status, ...restParams } = params;

    const newParams = {
      ...restParams,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      ...(params?.status === StatusFilterEnum.ALL ? {} : { status: status }),
    };

    handleGetListCaregiverReferral(newParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!isRefreshCaregiverReferral) return;
    const { status, ...restParams } = params;

    const newParams = {
      ...restParams,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      ...(params?.status === StatusFilterEnum.ALL ? {} : { status: status }),
    };

    handleGetListCaregiverReferral(newParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshCaregiverReferral]);

  useEffect(() => {
    if (!pagination) return;

    if (debouncedSearchKey) {
      setSearchParams({
        ...params,
        page: DEFAULT_CURRENT_PAGE.toString(),
        limit: DEFAULT_LIMIT_PAGE.toString(),
        textSearch: debouncedSearchKey,
      });
    } else {
      const { textSearch, ...rest } = params;
      setSearchParams(rest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);

  useEffect(() => {
    if (!textSearchParams) setSearchKey(EMPTY_STRING);
  }, [textSearchParams]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleSubmitFilter = (valueFilter: IFilterCaregiverReferral | undefined) => {
    if (!valueFilter) {
      return;
    }

    const { status, referralRole, ...restParams } = params;

    setSearchParams({
      ...restParams,
      page: DEFAULT_NUMBER_ONE.toString(),
      limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
      ...(valueFilter?.status === StatusFilterEnum.ALL ? {} : { status: valueFilter?.status }),
    });
  };

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value);
  };

  const handleAddEditReferral = () => {
    if (!role) return;

    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(superAdminRouteAbsolute.addCaregiverReferral);
        return;
      case AccountRoleCodesEnum.ADMIN:
        navigate(adminRouteAbsolute.addCaregiverReferral);
        return;

      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(staffRouteAbsolute.addCaregiverReferral);
        return;

      default:
        return;
    }
  };

  const handlePaginationChange = (page: number) => {
    setSearchParams({ ...params, page: page.toString(), limit: `${DEFAULT_LIMIT_PAGE}` });
  };

  const handleGetListCaregiverReferral = (payload: IQueryCaregiverReferral) => {
    loading?.show();

    dispatch(getListCaregiverReferral(payload))
      .unwrap()
      .then((res) => {
        const { responses, pagination }: IListDataResponse<IListCaregiverReferral[]> = res?.data;
        setCaregiverList(responses);
        setPagination(pagination);
      })
      .catch((err) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const handleClickUnderdevelopment = () => {
    setIsShowUnderDevelopment(!isShowUnderDevelopment);
  };

  const handleCaregiverReferralRowClick = (data: IListCaregiverReferral) => {
    if (!data) return;

    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.caregiverReferralDetail}/${data.id}`);
        break;

      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRouteAbsolute.caregiverReferralDetail}/${data.id}`);
        break;

      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(`${staffRouteAbsolute.caregiverReferralDetail}/${data.id}`);
        break;
    }
  };

  const handleEditReferral = (id: string) => {
    if (!id) return;

    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRouteAbsolute.editCaregiverReferral}/${id}`);
        return;
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.editCaregiverReferral}/${id}`);
        return;
      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(`${staffRouteAbsolute.editCaregiverReferral}/${id}`);
        return;
    }
  };

  const handleViewDetails = (id: string) => {
    if (!id) return;

    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.caregiverReferralDetail}/${id}`);
        break;

      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRouteAbsolute.caregiverReferralDetail}/${id}`);
        break;

      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(`${staffRouteAbsolute.caregiverReferralDetail}/${id}`);
        break;
    }
  };

  const handleCloseChangeStatus = () => {
    setIsChangeStatus(false);
    setRecordReferral(undefined);
  };

  const handleShowChangeStatus = (data: IListCaregiverReferral) => {
    if (data) setRecordReferral(data);

    setIsChangeStatus(true);
  };

  const handleChangeStatus = (status: string) => {
    if (!status || !recordReferral) return;

    setIsLoadingChangeStatus(true);

    const newData = {
      referralId: recordReferral?.id,
      body: {
        status: status as StatusFilterEnum,
      },
    };

    dispatch(changeStatusReferred(newData))
      .unwrap()
      .then((res) => {})
      .catch((err) => {})
      .finally(() => {
        handleCloseChangeStatus();
        setIsLoadingChangeStatus(false);
      });
  };
  //#endregion Handle Function

  return (
    <div id='caregiverReferralShowAllPage' className={cx('container')}>
      <div className={cx('headerToolBar')}>
        <ToolBar title={t('caregiver_referral_show_all_referral_title')}>
          <InputSearch height={36} placeholder={t('common_placeholder_search_by_name')} onChange={handleChangeSearch} />
          <BaseFilter<IFilterCaregiverReferral>
            defaultValue={{
              status: StatusFilterEnum.ALL,
            }}
            onApply={handleSubmitFilter}
            valueFilter={{
              status: statusParams || StatusFilterEnum.ALL,
            }}
          >
            {({ valueFilter, onChange }) => {
              return (
                <div className={cx('filterWrap')}>
                  <div className={cx('contentFilter')}>
                    <span className={cx('statusLabel')}>{t('crm_filter_referral_source_status_title')}</span>
                    <BaseSelect
                      width={175}
                      value={valueFilter?.status}
                      options={optionsStatusReferralSource}
                      onChange={(option) => {
                        onChange({
                          name: 'status',
                          value: option?.value.toString(),
                        });
                      }}
                    />
                  </div>
                </div>
              );
            }}
          </BaseFilter>
          <BaseButton
            typeStyle={ButtonTypeEnum.PRIMARY}
            iconLeft={icons.commonIconPlus}
            text={t('caregiver_referral_show_all_add_referral_button')}
            onClick={handleAddEditReferral}
            width={130}
            height={36}
          />
        </ToolBar>
      </div>

      <div className={cx('baseTable')}>
        <BaseTable
          columns={columns(t, handleEditReferral, handleViewDetails, handleShowChangeStatus)}
          dataSource={caregiverList || []}
          onClickRow={handleCaregiverReferralRowClick}
        />
      </div>

      <div className={cx('paginationTable')}>
        <BasePagination
          onChange={handlePaginationChange}
          defaultCurrentPage={pageSelected}
          totalItems={pagination?.totalItems}
          totalPages={pagination?.totalPages}
        />
      </div>

      {isChangeStatus && (
        <ModalChangeStatus
          isOpen={isChangeStatus}
          dataDefault={recordReferral?.status || EMPTY_STRING}
          dataOption={optionStatusReferral}
          onClose={handleCloseChangeStatus}
          onSubmit={handleChangeStatus}
          isLoading={isLoadingChangeStatus}
        />
      )}

      {isShowUnderDevelopment && <ModalUnderDevelopment onClose={handleClickUnderdevelopment} />}
    </div>
  );
};

export default CaregiverReferralShowAll;
