// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import {
  getListActivityByCaregiver,
  getListActivityByClient,
  getListActivityByApplicant,
  getListActivityByProspect,
} from './activityThunk';

export interface activityState {}

const initialState: activityState = {};

const activitySlice = createSlice({
  name: 'activityState',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    // Get List Activity By Client
    builder
      .addCase(getListActivityByClient.pending, (state) => {})
      .addCase(getListActivityByClient.fulfilled, (state) => {})
      .addCase(getListActivityByClient.rejected, (state) => {});

    // Get List Activity By Caregiver
    builder
      .addCase(getListActivityByCaregiver.pending, (state) => {})
      .addCase(getListActivityByCaregiver.fulfilled, (state) => {})
      .addCase(getListActivityByCaregiver.rejected, (state) => {});

    // Get List Activity By Applicant
    builder
      .addCase(getListActivityByApplicant.pending, (state) => {})
      .addCase(getListActivityByApplicant.fulfilled, (state) => {})
      .addCase(getListActivityByApplicant.rejected, (state) => {});

    // Get List Activity By Prospect
    builder
      .addCase(getListActivityByProspect.pending, (state) => {})
      .addCase(getListActivityByProspect.fulfilled, (state) => {})
      .addCase(getListActivityByProspect.rejected, (state) => {});
  },
});

// Actions
export const activityActions = activitySlice.actions;

// Selectors

// Reducer
const activityReducer = activitySlice.reducer;
export default activityReducer;
