// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { TFunction } from 'i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';

// Components, Layouts, Pages
import { BaseButton, BaseInput, Loading, Modal, ModalUnderDevelopment, RadioCrm } from '~/components';

// Others
import { ButtonTypeEnum, MasterDataTypeEnum } from '~/utils/enum';
import { ASTERISK_SYMBOL, EMPTY_STRING } from '~/utils/constants/common';
import { IFormStage, IFormUpdateStage, IListStage } from '~/utils/interface/stage';
import { createStage, updateStage } from '~/thunks/stage/stageThunk';
import { stageActions } from '~/thunks/stage/stageSlice';
import { useAppDispatch } from '~/redux/hooks';

// Styles, images, icons
import styles from './FormAddStageModal.module.scss';

type Props = {
  stageSelected: IListStage | null;
  setStageSelected: Dispatch<SetStateAction<IListStage | null>>;
  isOpen: boolean;
  onClose?: () => void;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup.object({
    name: yup.string().trim().required(t('common_required_field')),
    type: yup.string().trim().required(t('common_required_field')),
  });
};

const defaultValues: IFormStage = {
  name: EMPTY_STRING,
  type: MasterDataTypeEnum.STAGE_PROSPECT,
};

const FormAddStageModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, onClose, stageSelected, setStageSelected } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<IFormStage>({
    resolver: yupResolver(schema(t)),
    defaultValues: defaultValues,
  });
  //#endregion Declare Hook

  //#region Declare State
  const [isShowUnderDevelopment, setIsShowUnderDevelopment] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!stageSelected) return;

    handlePopulateForm(stageSelected);
  }, [stageSelected]);
  //#endregion Implement Hook

  //#region Handle Function
  const handlePopulateForm = (item: IListStage) => {
    reset({
      name: item.name,
      type: item.type,
    });
  };

  const handleClose = () => {
    onClose?.();
    reset(defaultValues);
    setStageSelected(null);
  };

  const handleSubmitStage = (data: IFormStage) => {
    if (stageSelected?.id) {
      handleEditStage(data);
    } else {
      handleCreateStage(data);
    }
  };

  const handleCreateStage = (data: IFormStage) => {
    setIsLoading(true);

    dispatch(createStage(data))
      .unwrap()
      .then((res) => {
        handleClose();
        dispatch(stageActions.setRefreshList(true));
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleEditStage = (data: IFormStage) => {
    const payload: IFormUpdateStage = {
      stageId: stageSelected?.id || EMPTY_STRING,
      body: data,
    };

    setIsLoading(true);

    dispatch(updateStage(payload))
      .unwrap()
      .then((res) => {
        handleClose();
        dispatch(stageActions.setRefreshList(true));
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleShowDevelopment = () => {
    setIsShowUnderDevelopment(!isShowUnderDevelopment);
  };
  //#endregion Handle Function

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={t(stageSelected?.id ? 'form_add_stage_modal_edit_title' : 'form_add_stage_modal_title')}
    >
      <form id='formRateModalComponent' className={cx('container')} onSubmit={handleSubmit(handleSubmitStage)}>
        <div className={cx('contentModal')}>
          <Controller
            name={'name'}
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id={'value'}
                height={34}
                label={t('form_add_stage_modal_stage_name_title')}
                onChange={onChange}
                value={value}
                messageError={errors.name?.message}
                required
              />
            )}
          />

          {!stageSelected?.id && (
            <div>
              <div className={cx('textLabel')}>
                {t('form_add_stage_modal_type_title')}
                <span className={cx('inputBaseLabelRequired')}>{ASTERISK_SYMBOL}</span>
              </div>
              <Controller
                name={'type'}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <div className={cx('radioGroup')}>
                    <RadioCrm
                      fontSize={12}
                      id={'prospect'}
                      name={'type'}
                      label={t('form_add_stage_modal_type_prospect_title')}
                      value={MasterDataTypeEnum.STAGE_PROSPECT}
                      checked={value === MasterDataTypeEnum.STAGE_PROSPECT}
                      onChange={onChange}
                    />

                    <RadioCrm
                      fontSize={12}
                      id={'applicant'}
                      name={'type'}
                      label={t('form_add_stage_modal_type_applicant_title')}
                      value={MasterDataTypeEnum.STAGE_APPLICANT}
                      checked={value === MasterDataTypeEnum.STAGE_APPLICANT}
                      onChange={onChange}
                    />
                  </div>
                )}
              />
            </div>
          )}
        </div>

        <div className={cx('footerModal')}>
          <BaseButton text={t('common_cancel_label')} width={65} onClick={handleClose} type='button' />
          <BaseButton
            text={t('common_save_label')}
            typeStyle={ButtonTypeEnum.PRIMARY}
            width={80}
            type='submit'
            disabled={stageSelected?.id ? !isDirty : false}
          />
        </div>
      </form>

      {isLoading && <Loading />}
      {isShowUnderDevelopment && <ModalUnderDevelopment onClose={handleShowDevelopment} />}
    </Modal>
  );
};

export default FormAddStageModal;
