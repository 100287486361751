// Libs
import { TFunction } from 'i18next';
import * as yup from 'yup';
// Others
import { DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
import { IFormPayrollRate } from '~/utils/interface/rate';

export const payRollSchema = (t: TFunction) => {
  return yup.object().shape({
    payRate: payRateSchema(t).optional(),
    travelRate: travelReimbursementSettingSchema(t).optional(),
    payHour: payHoursCalculationSettingsSchema(t).optional(),
    overtime: overtimeSettingsSchema(t).optional(),
  });
};

export const initialValues: IFormPayrollRate = {
  payRate: {
    payrollRatesHourly: [],
    payrollRatesPerVisit: [],
  },
  travelRate: {
    defaultTravelTimeRate: EMPTY_STRING,
    isDefaultTravelMileage: false,
    isHolidayRateApplied: false,
    travelMileageRate: DEFAULT_NUMBER_ZERO,
  },
  payHour: {
    isDefaultMinimumMinutes: false,
    minimumMinutes: DEFAULT_NUMBER_ZERO,
    roundingMethod: EMPTY_STRING,
  },
  overtime: {
    consecutiveDaysWeek: DEFAULT_NUMBER_ZERO,
    doubleHoursDay: DEFAULT_NUMBER_ZERO,
    doubleHoursLastDay: DEFAULT_NUMBER_ZERO,
    hoursDay: DEFAULT_NUMBER_ZERO,
    hoursWeek: DEFAULT_NUMBER_ZERO,
    method: EMPTY_STRING,
  },
};

export const payRateSchema = (t: TFunction) => {
  return yup.object({
    payrollRatesHourly: yup
      .array()
      .of(
        yup.object({
          id: yup.string().required(),
          name: yup.string().optional(),
          price: yup.number().optional(),
          rateId: yup.number().required(),
          rateType: yup.string().optional(),
          userType: yup.string().optional(),
        })
      )
      .optional(),
    payrollRatesPerVisit: yup
      .array()
      .of(
        yup.object({
          id: yup.string().required(),
          name: yup.string().optional(),
          price: yup.number().optional(),
          rateId: yup.number().required(),
          rateType: yup.string().optional(),
          userType: yup.string().optional(),
        })
      )
      .optional(),
  });
};

export const travelReimbursementSettingSchema = (t: TFunction) => {
  return yup.object({
    defaultTravelTimeRate: yup.string().trim().optional(),
    isDefaultTravelMileage: yup.boolean().optional(),
    isHolidayRateApplied: yup.boolean().optional(),
    travelMileageRate: yup.number().optional(),
  });
};

export const payHoursCalculationSettingsSchema = (t: TFunction) => {
  return yup.object({
    isDefaultMinimumMinutes: yup.boolean().optional(),
    minimumMinutes: yup.number().optional(),
    roundingMethod: yup.string().trim().optional(),
  });
};

export const overtimeSettingsSchema = (t: TFunction) => {
  return yup.object({
    consecutiveDaysWeek: yup.number().optional(),
    doubleHoursDay: yup.number().optional(),
    doubleHoursLastDay: yup.number().optional(),
    hoursDay: yup.number().optional(),
    hoursWeek: yup.number().optional(),
    method: yup.string().trim().optional(),
  });
};
