// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
// Components, Layouts, Pages
import { BaseButton, LabelValueField, ToolBar } from '~/components';
// Others
import { EMPTY_STRING, NAVIGATE_BACK } from '~/utils/constants/common';
import { ButtonTypeEnum, TimeFormatEnum } from '~/utils/enum';
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { getDetailInitialCall } from '~/thunks/crm/initialCall/initialCallThunk';
import { IInitialCallDetail } from '~/utils/interface/crm/initialCall';
import { convertDateToFormatTime, convertEnumToString, formatPhoneNumber, getUserName } from '~/utils/helper';
// Styles, images, icons
import styles from './InitialCallDetail.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const InitialCallDetail = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const { initialCallId } = useParams();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [initialCallDetail, setInitialCallDetail] = useState<IInitialCallDetail>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!initialCallId) return;

    handleGetDetailInitialCall(initialCallId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialCallId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetDetailInitialCall = (id: string) => {
    loading?.show();
    dispatch(getDetailInitialCall(id))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        setInitialCallDetail(res.data);
      })
      .catch((err) => {})
      .finally(() => loading?.hide());
  };
  const handleBack = () => {
    navigate(NAVIGATE_BACK);
  };
  //#endregion Handle Function

  return (
    <div id='initialCallDetailTemplate' className={cx('container')}>
      <BaseButton
        onClick={handleBack}
        text={t('common_text_back')}
        typeStyle={ButtonTypeEnum.ONLY_TEXT}
        iconLeft={icons.commonIconPrev}
        height={17}
      />

      <div className={cx('headerToolBar')}>
        <ToolBar title={t('initial_call_detail_title')} />
      </div>

      <div className={cx('bodyWrap')}>
        <div className={cx('twoCol')}>
          <div className={cx('callerInformationWrap')}>
            <div className={cx('title')}>{t('initial_call_detail_caller_information_title')}</div>

            <div className={cx('body')}>
              <LabelValueField
                label={t('initial_call_detail_caller_information_contact_type_label')}
                value={convertEnumToString(initialCallDetail?.caller?.relationship) || EMPTY_STRING}
              />

              <LabelValueField
                label={t('initial_call_detail_caller_information_name_label')}
                value={getUserName(initialCallDetail?.caller?.firstName, initialCallDetail?.caller?.lastName)}
              />

              <LabelValueField
                label={t('initial_call_detail_caller_information_organization_label')}
                value={initialCallDetail?.caller?.organization}
              />

              <LabelValueField
                label={t('initial_call_detail_caller_information_home_phone_label')}
                value={formatPhoneNumber(initialCallDetail?.caller?.homePhone)}
              />

              <LabelValueField
                label={t('initial_call_detail_caller_information_work_phone_label')}
                value={formatPhoneNumber(initialCallDetail?.caller?.workPhone)}
              />

              <LabelValueField
                label={t('initial_call_detail_caller_information_mobile_phone_label')}
                value={formatPhoneNumber(initialCallDetail?.caller?.mobilePhone)}
              />

              <LabelValueField
                label={t('initial_call_detail_caller_information_email_label')}
                value={initialCallDetail?.caller?.email}
              />

              <LabelValueField
                label={t('initial_call_detail_caller_information_address_label')}
                value={initialCallDetail?.caller?.address}
              />

              <LabelValueField
                label={t('initial_call_detail_caller_information_secondary_address_label')}
                value={initialCallDetail?.caller?.secondaryAddress}
              />

              <LabelValueField
                label={t('initial_call_detail_caller_information_city_label')}
                value={initialCallDetail?.caller?.city}
              />

              <LabelValueField
                label={t('initial_call_detail_caller_information_state_label')}
                value={initialCallDetail?.caller?.state}
              />

              <LabelValueField
                label={t('initial_call_detail_caller_information_postal_code_label')}
                value={initialCallDetail?.caller?.postalCode}
              />
            </div>
          </div>

          <div className={cx('prospectInformation')}>
            <div className={cx('title')}>{t('initial_call_detail_prospect_information_title')}</div>

            <div className={cx('body')}>
              <LabelValueField
                label={t('initial_call_detail_prospect_information_name_label')}
                value={getUserName(initialCallDetail?.prospect.firstName, initialCallDetail?.prospect.lastName)}
              />

              <LabelValueField
                label={t('initial_call_detail_prospect_information_home_phone_label')}
                value={formatPhoneNumber(initialCallDetail?.prospect?.homePhone)}
              />

              <LabelValueField
                label={t('initial_call_detail_prospect_information_work_phone_label')}
                value={formatPhoneNumber(initialCallDetail?.prospect?.workPhone)}
              />

              <LabelValueField
                label={t('initial_call_detail_prospect_information_mobile_phone_label')}
                value={formatPhoneNumber(initialCallDetail?.prospect?.mobilePhone)}
              />

              <LabelValueField
                label={t('initial_call_detail_prospect_information_email_label')}
                value={initialCallDetail?.prospect?.email}
              />

              <LabelValueField
                label={t('initial_call_detail_prospect_information_location_label')}
                value={initialCallDetail?.prospect?.location?.name || EMPTY_STRING}
              />

              <LabelValueField
                label={t('initial_call_detail_prospect_information_address_label')}
                value={initialCallDetail?.prospect?.address}
              />

              <LabelValueField
                label={t('initial_call_detail_prospect_information_secondary_address_label')}
                value={initialCallDetail?.prospect?.secondaryAddress}
              />

              <LabelValueField
                label={t('initial_call_detail_prospect_information_city_label')}
                value={initialCallDetail?.prospect?.city}
              />

              <LabelValueField
                label={t('initial_call_detail_prospect_information_state_label')}
                value={initialCallDetail?.prospect?.state}
              />

              <LabelValueField
                label={t('initial_call_detail_prospect_information_postal_code_label')}
                value={initialCallDetail?.prospect?.postalCode}
              />
            </div>
          </div>
        </div>

        <div className={cx('referralSourceWrap')}>
          <div className={cx('title')}>{t('initial_call_detail_referral_source_title')}</div>

          <div className={cx('body')}>
            <LabelValueField
              label={t('initial_call_detail_referral_source_name_label')}
              value={getUserName(
                initialCallDetail?.referralSource?.firstName,
                initialCallDetail?.referralSource?.lastName
              )}
            />

            <LabelValueField
              label={t('initial_call_detail_referral_source_organization_label')}
              value={initialCallDetail?.referralSource?.organization}
            />

            <LabelValueField
              label={t('initial_call_detail_referral_source_home_phone_label')}
              value={formatPhoneNumber(initialCallDetail?.referralSource?.homePhone)}
            />

            <LabelValueField
              label={t('initial_call_detail_referral_source_work_phone_label')}
              value={formatPhoneNumber(initialCallDetail?.referralSource?.workPhone)}
            />

            <LabelValueField
              label={t('initial_call_detail_referral_source_mobile_phone_label')}
              value={formatPhoneNumber(initialCallDetail?.referralSource?.mobilePhone)}
            />

            <LabelValueField
              label={t('initial_call_detail_referral_source_email_label')}
              value={initialCallDetail?.referralSource?.email}
            />

            <LabelValueField
              label={t('initial_call_detail_referral_source_address_label')}
              value={initialCallDetail?.referralSource?.address}
            />

            <LabelValueField
              label={t('initial_call_detail_referral_source_secondary_address_label')}
              value={initialCallDetail?.referralSource?.secondaryAddress}
            />

            <LabelValueField
              label={t('initial_call_detail_referral_source_city_label')}
              value={initialCallDetail?.referralSource?.city}
            />

            <LabelValueField
              label={t('initial_call_detail_referral_source_state_label')}
              value={initialCallDetail?.referralSource?.state}
            />

            <LabelValueField
              label={t('initial_call_detail_referral_source_postal_code_label')}
              value={initialCallDetail?.referralSource?.postalCode}
            />
          </div>
        </div>

        <div className={cx('prospectCareNeeds')}>
          <div className={cx('title')}>{t('initial_call_detail_prospect_care_needs_title')}</div>

          <div className={cx('body')}>
            <LabelValueField
              label={t('initial_call_detail_prospect_care_needs_care_goals_label')}
              value={initialCallDetail?.prospectCareNeed?.careGoals || EMPTY_STRING}
              isLongValue
            />

            <LabelValueField
              label={t('initial_call_detail_prospect_care_needs_initial_contact_label')}
              value={
                initialCallDetail?.prospectCareNeed?.initialContact
                  ? convertDateToFormatTime(
                      initialCallDetail?.prospectCareNeed?.initialContact,
                      TimeFormatEnum.MM_DD_YYYY
                    )
                  : EMPTY_STRING
              }
            />

            <LabelValueField
              label={t('initial_call_detail_prospect_care_needs_start_date_label')}
              value={
                initialCallDetail?.prospectCareNeed?.startDate
                  ? convertDateToFormatTime(initialCallDetail?.prospectCareNeed?.startDate, TimeFormatEnum.MM_DD_YYYY)
                  : EMPTY_STRING
              }
            />

            <LabelValueField
              label={t('initial_call_detail_prospect_care_needs_live_with_label')}
              value={initialCallDetail?.prospectCareNeed?.liveWith}
            />

            <LabelValueField
              label={t('initial_call_detail_prospect_care_needs_medical_conditions_label')}
              value={initialCallDetail?.prospectCareNeed?.medicalConditions}
              isLongValue
            />

            <LabelValueField
              label={t('initial_call_detail_prospect_care_needs_mental_behavior_conditions_label')}
              value={initialCallDetail?.prospectCareNeed?.mentalBehaviorConditions}
              isLongValue
            />

            <LabelValueField
              label={t('initial_call_detail_prospect_care_needs_ambulation_notes_label')}
              value={initialCallDetail?.prospectCareNeed?.ambulationNotes}
              isLongValue
            />

            <LabelValueField
              label={t('initial_call_detail_prospect_care_needs_needs_medication_label')}
              value={
                initialCallDetail?.prospectCareNeed?.isNeedsMedication ? t('common_label_yes') : t('common_label_no')
              }
            />

            <LabelValueField
              label={t('initial_call_detail_prospect_care_needs_medications_supplements_manages_label')}
              value={initialCallDetail?.prospectCareNeed?.medicationsSupplementsManages}
            />

            <LabelValueField
              label={t('initial_call_detail_prospect_care_needs_medications_label')}
              value={initialCallDetail?.prospectCareNeed?.medications}
              isLongValue
            />

            <LabelValueField
              label={t('initial_call_detail_prospect_care_needs_special_diet_label')}
              value={initialCallDetail?.prospectCareNeed?.specialDiet}
            />

            <LabelValueField
              label={t('initial_call_detail_prospect_care_needs_breakfast_label')}
              value={initialCallDetail?.prospectCareNeed?.breakfast}
            />

            <LabelValueField
              label={t('initial_call_detail_prospect_care_needs_lunch_label')}
              value={initialCallDetail?.prospectCareNeed?.lunch}
            />

            <LabelValueField
              label={t('initial_call_detail_prospect_care_needs_snack_label')}
              value={initialCallDetail?.prospectCareNeed?.snack}
            />

            <LabelValueField
              label={t('initial_call_detail_prospect_care_needs_dinner_label')}
              value={initialCallDetail?.prospectCareNeed?.dinner}
            />

            <LabelValueField
              label={t('initial_call_detail_prospect_care_needs_meal_notes_label')}
              value={initialCallDetail?.prospectCareNeed?.mealNotes}
              isLongValue
            />

            <LabelValueField
              label={t('initial_call_detail_prospect_care_needs_deriving_notes_label')}
              value={initialCallDetail?.prospectCareNeed?.derivingNotes}
              isLongValue
            />
          </div>
        </div>

        <div className={cx('logThisCall')}>
          <div className={cx('title')}>{t('initial_call_detail_log_this_call_title')}</div>

          <div className={cx('body')}>
            <LabelValueField
              label={t('initial_call_detail_log_this_call_care_date_label')}
              value={
                initialCallDetail?.callDate
                  ? convertDateToFormatTime(initialCallDetail?.callDate, TimeFormatEnum.MM_DD_YYYY)
                  : EMPTY_STRING
              }
            />

            <LabelValueField
              label={t('initial_call_detail_log_this_call_description_label')}
              value={initialCallDetail?.description}
              isLongValue
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InitialCallDetail;
