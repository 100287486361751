// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useLoadScript } from '@react-google-maps/api';
import { Link } from 'react-router-dom';

// Components, Layouts, Pages
import { BaseGoogleMap, LabelValueField } from '~/components';

// Others
import { DEFAULT_GG_MAP_LOAD_SCRIPT_LIB, EMPTY_STRING } from '~/utils/constants/common';
import { convertEnumToString, formatAddress, formatPhoneNumber, getUserName } from '~/utils/helper';
import { googleMapApiKey } from '~/utils/constants/env';
import { useAppSelector } from '~/redux/hooks';

// Styles, images, icons
import styles from './CaregiverReferralDetailInfoTab.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const CaregiverReferralDetailInfoTab = (props: Props) => {
  //#region Declare Hook
  const { t } = useTranslation();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapApiKey ?? EMPTY_STRING,
    libraries: DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  });
  //#endregion Declare Hook

  //#region Selector
  const { caregiverReferralDetail } = useAppSelector((state) => state.caregiverReferralState);
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='=caregiverReferralDetailInfoTabComponent' className={cx('container')}>
      <div className={cx('body')}>
        <div className={cx('infoDetailsWrap')}>
          <LabelValueField
            label={t('caregiver_referral_detail_info_tab_email_label')}
            value={caregiverReferralDetail?.email || EMPTY_STRING}
          />

          <LabelValueField
            label={t('caregiver_referral_detail_info_tab_home_phone_label')}
            value={
              caregiverReferralDetail?.homePhone ? formatPhoneNumber(caregiverReferralDetail?.homePhone) : EMPTY_STRING
            }
          />

          <LabelValueField
            label={t('caregiver_referral_detail_info_tab_mobile_phone_label')}
            value={
              caregiverReferralDetail?.mobilePhone
                ? formatPhoneNumber(caregiverReferralDetail?.mobilePhone)
                : EMPTY_STRING
            }
          />

          <LabelValueField
            label={t('caregiver_referral_detail_info_tab_work_phone_label')}
            value={
              caregiverReferralDetail?.workPhone ? formatPhoneNumber(caregiverReferralDetail?.workPhone) : EMPTY_STRING
            }
          />

          <LabelValueField
            label={t('caregiver_referral_detail_info_tab_fax_number_label')}
            value={
              caregiverReferralDetail?.faxNumber ? formatPhoneNumber(caregiverReferralDetail?.faxNumber) : EMPTY_STRING
            }
          />

          <LabelValueField
            label={t('caregiver_referral_detail_info_tab_type_label')}
            value={caregiverReferralDetail?.type ? convertEnumToString(caregiverReferralDetail.type) : EMPTY_STRING}
          />

          <LabelValueField
            label={t('caregiver_referral_detail_info_tab_owner_label')}
            value={
              getUserName(caregiverReferralDetail?.owner?.firstName, caregiverReferralDetail?.owner?.lastName) ||
              EMPTY_STRING
            }
          />

          <LabelValueField
            label={t('caregiver_referral_detail_info_tab_secondary_address_label')}
            value={caregiverReferralDetail?.secondaryAddress || EMPTY_STRING}
          />

          <LabelValueField
            label={t('caregiver_referral_detail_info_web_tab_web_address_label')}
            value={<Link to={caregiverReferralDetail?.webAddress || EMPTY_STRING} target='_blank' />}
          />
        </div>

        <div className={cx('mapSection')}>
          <div className={cx('viewAddress')}>
            <span className={cx('textLabel')}>{t('info_tab_title_address')}</span>
            <span className={cx('valueContent')}>
              <span className={cx('valueContent')}>{formatAddress({ ...caregiverReferralDetail })}</span>
            </span>
          </div>

          <div className={cx('mapViewContainer')}>
            {isLoaded && (
              <BaseGoogleMap
                position={
                  caregiverReferralDetail?.lat && caregiverReferralDetail?.lng
                    ? { lat: caregiverReferralDetail?.lat, lng: caregiverReferralDetail?.lng }
                    : undefined
                }
                triggerLocate
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaregiverReferralDetailInfoTab;
