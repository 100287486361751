// Libs
import classNames from 'classnames/bind';
import { TFunction } from 'i18next';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useParams } from 'react-router-dom';
// Components, Layouts, Pages
import { BaseTable } from '~/components';
// Others
import { TimeFormatEnum, TranslationEnum, BusinessTypeEnum } from '~/utils/enum';
import { DEFAULT_CURRENT_PAGE, DEFAULT_LIMIT_MAX_ITEM, EMPTY_STRING } from '~/utils/constants/common';
import { ColumnTableType } from '~/utils/interface/common';
import { IGetTasksDailyCareParams, IShiftDailyCare, ITaskDailyCare } from '~/utils/interface/taskDailyCare';
import { useAppDispatch } from '~/redux/hooks';
import { getTaskListDailyCare } from '~/thunks/dailyCare/dailyCareThunk';
import { TBusinessType } from '~/utils/type/common';
import { convertEnumToString, getUserName } from '~/utils/helper';
// Styles, images, icons
import styles from './TaskTableDailyCare.module.scss';

type Props = { businessType: TBusinessType; dateSelected: string };

const cx = classNames.bind(styles);

const columns = (t: TFunction<TranslationEnum.TRANSLATION>): ColumnTableType<ITaskDailyCare>[] => {
  return [
    {
      key: 'startTime',
      title: t('caregiver_profile_daily_care_table_label_time'),
      width: '30%',
      render(value, record, index) {
        return (
          <>
            {record?.startTime
              ? moment(record.startTime, TimeFormatEnum.HH_MM_SS).format(TimeFormatEnum.HOUR_MINUTE_A)
              : EMPTY_STRING}
          </>
        );
      },
    },
    {
      key: 'name',
      title: t('caregiver_profile_daily_care_table_label_task'),
      render(value, record, index) {
        return <>{record.name || EMPTY_STRING}</>;
      },
    },
  ];
};

const TaskTableDailyCare = (props: Props) => {
  //#region Destructuring Props
  const { businessType, dateSelected } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { clientId, caregiverId } = useParams();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [shiftsDailyCare, setShiftsDailyCare] = useState<IShiftDailyCare[]>([]);
  const [expandedShiftIds, setExpandedShiftIds] = useState<string[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetTaskListDailyCare(clientId || caregiverId);
  }, [dateSelected, clientId, caregiverId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleExpandTable = (shiftId: string) => {
    setExpandedShiftIds((prev) => (prev.includes(shiftId) ? prev.filter((id) => id !== shiftId) : [...prev, shiftId]));
  };

  const handleGetTaskListDailyCare = (businessId?: string) => {
    if (!businessId) return;

    const params: IGetTasksDailyCareParams = {
      businessId: businessId,
      businessType: businessType,
      date: dateSelected,
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
    };

    dispatch(getTaskListDailyCare(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        setShiftsDailyCare(res.data.responses || []);
      })
      .catch((err) => {})
      .finally(() => {});
  };

  const userNameOfShift = useCallback(
    (shiftItem: IShiftDailyCare) => {
      if (businessType === BusinessTypeEnum.CLIENT) {
        return getUserName(shiftItem.client?.firstName, shiftItem.client?.lastName);
      } else {
        return getUserName(shiftItem.caregiver?.firstName, shiftItem.caregiver?.lastName);
      }
    },
    [shiftsDailyCare]
  );
  //#endregion Handle Function

  return (
    <div id='taskTableDailyCareComponent' className={cx('container')}>
      <div className={cx('bodyWrap')}>
        {shiftsDailyCare.map((shiftItem) => {
          return (
            <div className={cx('bodyTable')} key={shiftItem.id}>
              <BaseTable
                tableExpand
                title={t(
                  `${moment(shiftItem?.startTime, TimeFormatEnum.HH_MM_SS).format(
                    TimeFormatEnum.HOUR_MINUTE_A
                  )} - ${userNameOfShift(shiftItem)} (${convertEnumToString(shiftItem.status)})`
                )}
                columns={columns(t)}
                dataSource={shiftItem.tasks || []}
                isExpand={expandedShiftIds.includes(shiftItem.id)}
                handleExpandTable={() => handleExpandTable(shiftItem.id)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default memo(TaskTableDailyCare);
