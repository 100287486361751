// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useMemo } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import { TFunction } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// Components, Layouts, Pages
import { BaseButton, BaseInput, Modal, BaseSelect, Loading, BaseGoogleMap, ModalUnderDevelopment } from '~/components';

// Context

// Others
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { IAddress, IBaseOption, IListDataResponse, IPosition } from '~/utils/interface/common';
import { IFormTimeClock } from '~/utils/interface/timeClock';
import { IQueryListTaskSelect, ISelectTask } from '~/utils/interface/task';
import { AccountRoleCodesEnum, BusinessTimeClockEnum, ButtonTypeEnum, FormTimeClockKeyEnum } from '~/utils/enum';
import { formatAddress, getUserName, removeEmptyObjects } from '~/utils/helper';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  DEFAULT_LIMIT_MAX_ITEM,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  GEOLOCATION_MAXIMUM_AGE,
  GEOLOCATION_TIMEOUT,
} from '~/utils/constants/common';
import { googleMapApiKey } from '~/utils/constants/env';
import { getSelectTask } from '~/thunks/task/taskThunk';
import { createTimeClock } from '~/thunks/timeClock/timeClockThunk';
import { timeClockActions } from '~/thunks/timeClock/timeClockSlice';
import { selectUserProfile } from '~/thunks/user/userSlice';

// Styles, images, icons
import styles from './FormTimeClockModal.module.scss';

type Props = {
  isOpen: boolean;
  role?: AccountRoleCodesEnum;
  onClose: () => void;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup.object({
    businessId: yup.string().optional(),
    businessType: yup.string().trim().required(t('common_required_field')),
  });
};

const FormTimeClockModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, role, onClose } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const userProfile = useAppSelector(selectUserProfile);
  const { t } = useTranslation();

  const defaultValues = useMemo(() => {
    return {
      businessType:
        userProfile.role !== AccountRoleCodesEnum.CAREGIVER ? BusinessTimeClockEnum.TASK : BusinessTimeClockEnum.SHIFT,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, isDirty },
  } = useForm<IFormTimeClock>({
    resolver: yupResolver(schema(t)),
    defaultValues: defaultValues,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [optionsTask, setOptionsTask] = useState<IBaseOption[]>([]);
  const [latitude, setLatitude] = useState<number>();
  const [longitude, setLongitude] = useState<number>();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapApiKey ?? EMPTY_STRING,
    libraries: DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUnderDevelopment, setIsUnderDevelopment] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!isLoaded) return;
    fetchCurrentLocation();
  }, [isLoaded]);

  useEffect(() => {
    handleGetListTask();
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListTask = () => {
    if (!userProfile?.id) return;

    const paramsObject: IQueryListTaskSelect = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
      assignees: [userProfile?.id ?? EMPTY_STRING],
    };

    dispatch(getSelectTask(paramsObject))
      .unwrap()
      .then((response) => {
        const { responses }: IListDataResponse<ISelectTask[]> = response?.data;
        if (!responses) return;

        setOptionsTask(convertListTaskOption(responses));
      })
      .catch((error) => {});
  };

  const handleOnSubmit = (data: IFormTimeClock) => {
    const newData: IFormTimeClock = {
      ...data,
      lat: latitude || DEFAULT_NUMBER_ZERO,
      lng: longitude || DEFAULT_NUMBER_ZERO,
      startTime: new Date().toISOString(),
    };

    const dataClean = removeEmptyObjects(newData) as IFormTimeClock;

    setIsLoading(true);
    dispatch(createTimeClock(dataClean))
      .unwrap()
      .then((res) => {
        dispatch(timeClockActions.setRefreshList(true));
        onClose();
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getInfoAddress = (value: IAddress) => {
    const { city, state, country, zipCode } = value;
    setValue('country', country, { shouldDirty: true });
    setValue('city', city, { shouldDirty: true });
    setValue('state', state, { shouldDirty: true });
    setValue('postalCode', zipCode, { shouldDirty: true });
    setValue('address', formatAddress(value), { shouldDirty: true });
  };

  const getPosition = (value: IPosition) => {
    if (!value) return;

    reset((data) => ({ ...data, lat: value.lat, lng: value.lng }));
  };

  const convertListTaskOption = (listTaskResp: ISelectTask[]): IBaseOption[] => {
    if (!Array.isArray(listTaskResp)) {
      return [];
    }

    return listTaskResp.map((task) => ({
      label: task?.description || EMPTY_STRING,
      value: task.id,
    }));
  };

  const handleCloseModal = () => {
    onClose();
    reset();
  };

  const fetchCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude);
          setLongitude(longitude);
        },
        (error) => {
          console.error('Error getting location:', error);
        },
        {
          enableHighAccuracy: true,
          timeout: GEOLOCATION_TIMEOUT,
          maximumAge: GEOLOCATION_MAXIMUM_AGE,
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  const handleCloseUnderDevelopment = () => {
    setIsUnderDevelopment(false);
  };
  //#endregion Handle Function

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t('modal_add_time_clock_title')}>
      <form id='addTimeClockComponent' className={cx('addTimeClockComponent')} onSubmit={handleSubmit(handleOnSubmit)}>
        <div className={cx('contentModal')}>
          <div className={cx('bodyModal')}>
            <div className={cx('twoCol')}>
              <BaseInput
                id={'name'}
                label={t('modal_add_time_clock_user_name_label')}
                value={getUserName(userProfile?.firstName, userProfile?.lastName)}
                disabled
              />

              <Controller
                name={FormTimeClockKeyEnum.BUSINESS_ID}
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <BaseSelect
                    name={name}
                    options={optionsTask}
                    label={t('modal_add_time_clock_task_name_label')}
                    placeholder={t('common_select_placeholder')}
                    onChange={() => onChange()}
                    value={value}
                    errorMessage={errors.businessId?.message}
                  />
                )}
              />
            </div>

            <div className={cx('mapView')}>
              {isLoaded && (
                <BaseGoogleMap
                  title={t('modal_add_time_clock_map_view_label')}
                  onGetPosition={getPosition}
                  onGetInfoAddress={getInfoAddress}
                  triggerLocate
                />
              )}
            </div>
          </div>

          <div className={cx('footerModal')}>
            <BaseButton text={t('common_cancel_label')} width={65} onClick={handleCloseModal} />
            <BaseButton
              text={t('modal_add_time_clock_check_in_btn')}
              typeStyle={ButtonTypeEnum.PRIMARY}
              type='submit'
            />
          </div>
        </div>
      </form>

      {isLoading && <Loading />}

      {isUnderDevelopment && <ModalUnderDevelopment onClose={handleCloseUnderDevelopment} />}
    </Modal>
  );
};

export default FormTimeClockModal;
