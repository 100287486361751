// Libs
import { TFunction } from 'i18next';
import * as yup from 'yup';
// Others
import { EMPTY_STRING, MAX_FILE_SIZE_FILE_20MB, RegExp } from '~/utils/constants/common';
import { IFormRequestApplicant } from '~/utils/interface/applicant';

export const requestApplicantSchema = (t: TFunction) => {
  return yup
    .object()
    .shape({
      profileImage: yup
        .array()
        .of(
          yup.object({
            file: yup
              .mixed<File>()
              .optional()
              .test('fileSize', t('add_caregiver_files_size_limit'), (value) => {
                if (!value) return true;
                return value instanceof File && value.size <= MAX_FILE_SIZE_FILE_20MB;
              }),
          })
        )
        .optional(),
      firstName: yup.string().trim().required(t('common_required_field')),
      lastName: yup.string().trim().required(t('common_required_field')),
      address: yup.string().trim().required(t('common_required_field')),
      city: yup.string().trim().required(t('common_required_field')),
      state: yup.string().trim().required(t('common_required_field')),
      postalCode: yup.string().trim().required(t('common_required_field')),
      mobilePhone: yup.string().trim().required(t('common_required_field')),
      ssn: yup.string().trim().required(t('common_required_field')),
      externalId: yup.string().trim().required(t('common_required_field')),
      locationIds: yup
        .array(yup.string().required(t('common_required_field')))
        .min(1, t('common_required_field'))
        .required(),
      referrerId: yup.string().trim().required(t('common_required_field')),
      email: yup
        .string()
        .trim()
        .required(t('common_required_field'))
        .test('isValidEmail', t('common_validate_invalid_email'), (value) => {
          if (value && !RegExp.EMAIL.test(value)) return false;
          return true;
        }),
    })
    .required();
};

export const initialValues: IFormRequestApplicant = {
  profileImage: [],
  email: EMPTY_STRING,
  firstName: EMPTY_STRING,
  middleName: EMPTY_STRING,
  lastName: EMPTY_STRING,
  locationIds: [],
  mobilePhone: EMPTY_STRING,
  homePhone: EMPTY_STRING,
  workPhone: EMPTY_STRING,
  address: EMPTY_STRING,
  secondaryAddress: EMPTY_STRING,
  city: EMPTY_STRING,
  state: EMPTY_STRING,
  postalCode: EMPTY_STRING,
  notes: EMPTY_STRING,
  referrerId: EMPTY_STRING,
  ssn: EMPTY_STRING,
  externalId: EMPTY_STRING,
};
