// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import moment from 'moment';
// Components, Layouts, Pages
import { BasePagination, BaseTable, StatusLabel } from '~/components';
// Others
import { ColumnTableType, IPaginationResponse } from '~/utils/interface/common';
import {
  AccountRoleCodesEnum,
  CurrencyEnum,
  DateFormatEnum,
  StorageEnum,
  TimeFormatEnum,
  TranslationEnum,
} from '~/utils/enum';
import { DEFAULT_CURRENT_PAGE, DEFAULT_NUMBER_RECORD_TO_FETCH, EMPTY_STRING } from '~/utils/constants/common';
import { convertDateToFormatTime, formatCurrency, generateInvoiceId } from '~/utils/helper';
import { IInvoices, IPayloadListInvoiceByClient, IQueryParamsInvoice } from '~/utils/interface/invoice';
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { adminRouteAbsolute, staffRouteAbsolute, superAdminRouteAbsolute } from '~/utils/constants/route';
import { getListInvoiceByClient } from '~/thunks/invoice/invoiceThunk';
// Styles, images, icons
import styles from './BillingInvoiceTab.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const columnsInvoice = ({ t }: { t: TFunction<TranslationEnum.TRANSLATION> }): ColumnTableType<IInvoices>[] => {
  return [
    {
      key: 'date',
      title: t('invoice_table_date_label'),
      render(value, record, index) {
        return (
          <>{record?.createdAt ? convertDateToFormatTime(record.createdAt, TimeFormatEnum.MM_DD_YYYY) : EMPTY_STRING}</>
        );
      },
    },
    {
      key: 'id',
      title: t('invoice_table_invoice_id_label'),
      render(value, record, index) {
        return <>{record.id ? generateInvoiceId(+record.id) : EMPTY_STRING}</>;
      },
    },
    {
      key: 'amount',
      title: t('invoice_table_amount_label'),
      render(value, record, index) {
        return <>{record?.total ? formatCurrency(CurrencyEnum.USD, record.total) : EMPTY_STRING}</>;
      },
    },
    {
      key: 'status',
      title: t('invoice_table_status_label'),
      render(value, record, index) {
        return (
          <div className={cx('buttonStatus')}>
            {record.status ? <StatusLabel borderRadius={100} label={record.status} bgOpacity={0.1} /> : EMPTY_STRING}
          </div>
        );
      },
    },
    {
      key: 'dueDate',
      title: t('invoice_table_due_date_label'),
      render(value, record, index) {
        return (
          <>{record?.dueDate ? convertDateToFormatTime(record.dueDate, TimeFormatEnum.MM_DD_YYYY) : EMPTY_STRING}</>
        );
      },
    },
    // {
    //   key: 'action',
    //   title: t('invoice_table_actions_label'),
    //   render(value, record, index) {
    //     const actions: MoreActionItem[] = [
    //       {
    //         label: t('common_action_view'),
    //         icon: icons.signInIconEyeShow,
    //         onClick: () => onViewDetail(record),
    //       },
    //     ];
    //     return <BaseMoreAction actions={actions} />;
    //   },
    //   width: 50,
    // },
  ];
};

const BillingInvoiceTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(() => Number(params?.page ?? DEFAULT_CURRENT_PAGE), [params?.page]);
  const role = localStorage.getItem(StorageEnum.ROLE);
  const navigate = useNavigate();
  const { clientId } = useParams();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [listInvoice, setListInvoice] = useState<IInvoices[]>([]);
  const [pagination, setPagination] = useState<IPaginationResponse>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!clientId) return;

    const { tab, status, ...restParams } = params;

    const newParams: IQueryParamsInvoice = {
      ...restParams,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      currentDate: moment().format(DateFormatEnum.YYYY_MM_DD),
    };

    const payload: IPayloadListInvoiceByClient = {
      clientId: clientId,
      params: newParams,
    };

    handleGetListInvoiceByClient(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListInvoiceByClient = (payload: IPayloadListInvoiceByClient) => {
    loading?.show();

    dispatch(getListInvoiceByClient(payload))
      .unwrap()
      .then((res) => {
        const { pagination, responses } = res;

        setListInvoice(responses);
        setPagination(pagination);
      })
      .catch((err) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const handleViewDetailInvoice = (record: IInvoices) => {
    if (!record) return;

    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRouteAbsolute.invoiceDetail}/${record.id}`);
        return;
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.invoiceDetail}/${record.id}`);
        return;
      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(`${staffRouteAbsolute.invoiceDetail}/${record.id}`);
        return;

      default:
        return;
    }
  };

  const handlePaginationChange = (page: number) => {
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleClickRow = (record: IInvoices) => {
    handleViewDetailInvoice(record);
  };
  //#endregion Handle Function

  return (
    <div id='billingInvoiceTabComponent' className={cx('billingInvoiceTab')}>
      <div className={cx('content')}>
        <div className={cx('tableWrap')}>
          <BaseTable
            columns={columnsInvoice({
              t,
            })}
            dataSource={listInvoice}
            onClickRow={handleClickRow}
          />
        </div>

        <div className={cx('paginationTable')}>
          <BasePagination
            onChange={handlePaginationChange}
            defaultCurrentPage={pageSelected || DEFAULT_CURRENT_PAGE}
            totalItems={pagination?.totalItems || DEFAULT_CURRENT_PAGE}
            totalPages={pagination?.totalPages || DEFAULT_CURRENT_PAGE}
          />
        </div>
      </div>
    </div>
  );
};

export default BillingInvoiceTab;
