// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
// Components, Layouts, Pages
import {
  BaseButton,
  CallerInformationInitialCall,
  LogCallInitialCall,
  ProspectCareNeedsInitialCall,
  ProspectInformationInitialCall,
  ReferralSourceInitialCall,
} from '~/components';
// Others
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { ButtonTypeEnum } from '~/utils/enum';
import { IFormInitialCall } from '~/utils/interface/crm/initialCall';
import { cleanPhoneNumbers, initialCallSchema, initialValues } from '../helper';
import { NAVIGATE_BACK } from '~/utils/constants/common';
import { removeEmptyObjects } from '~/utils/helper';
import { createInitialCall } from '~/thunks/crm/initialCall/initialCallThunk';
// Styles, images, icons
import styles from './FormInitialCall.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const FormInitialCall = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const loadingContext = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    watch,
    trigger,
    setValue,
    reset,
    resetField,
    getValues,
    formState: { errors },
  } = useForm<IFormInitialCall>({
    resolver: yupResolver(initialCallSchema(t)),
    defaultValues: initialValues,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleSubmitForm = (data: IFormInitialCall) => {
    loadingContext?.show();
    const newData: IFormInitialCall = {
      ...data,
      caller: cleanPhoneNumbers(data.caller),
      prospect: cleanPhoneNumbers(data.prospect),
      referralSource: cleanPhoneNumbers(data.referralSource),
    };

    const dataClean = removeEmptyObjects(newData) as IFormInitialCall;

    dispatch(createInitialCall(dataClean))
      .unwrap()
      .then((res) => {
        handleGoBack();
      })
      .catch((err) => {})
      .finally(() => loadingContext?.hide());
  };

  const handleGoBack = () => {
    navigate(NAVIGATE_BACK);
  };
  //#endregion Handle Function

  return (
    <FormProvider
      {...({
        control,
        watch,
        setValue,
        trigger,
        reset,
        resetField,
        getValues,
        formState: { errors },
      } as UseFormReturn<IFormInitialCall>)}
    >
      <form
        id='formInitialCallComponent'
        className={cx('formInitialCallComponent')}
        onSubmit={handleSubmit(handleSubmitForm)}
      >
        <div className={cx('formBody')}>
          <CallerInformationInitialCall />

          <ProspectInformationInitialCall />

          <ReferralSourceInitialCall />

          <ProspectCareNeedsInitialCall />

          <LogCallInitialCall />
        </div>
        <div className={cx('formActions')}>
          <BaseButton text={t('common_go_back_label')} width={80} onClick={handleGoBack} type='button' />

          <BaseButton text={t('common_save_label')} typeStyle={ButtonTypeEnum.PRIMARY} width={80} type='submit' />
        </div>
      </form>
    </FormProvider>
  );
};

export default FormInitialCall;
