import { urlApiEmailTemplate } from '~/utils/constants/api';
import {
  IEmailTemplate,
  IFormEmailTemplate,
  IPayloadUpdateEmailTemplate,
  IQueryParamsEmailTemplate,
} from '~/utils/interface/emailTemplate';
import axiosClient from './axiosClient';
import { BaseResponse, IListDataResponse } from '~/utils/interface/common';

const emailTemplateApi = {
  getListEmailTemplate(params: IQueryParamsEmailTemplate) {
    const url = `${urlApiEmailTemplate.getList}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IEmailTemplate[]>>>(url, { params });
  },

  createEmailTemplate(body: IFormEmailTemplate) {
    const url = `${urlApiEmailTemplate.createEmailTemplate}`;
    return axiosClient.post<BaseResponse>(url, body);
  },

  editEmailTemplate({ emailTemplateId, body }: IPayloadUpdateEmailTemplate) {
    const url = `${urlApiEmailTemplate.editEmailTemplate(emailTemplateId)}`;
    return axiosClient.put<BaseResponse>(url, body);
  },
};

export default emailTemplateApi;
