// Libs
import classNames from 'classnames/bind';
import { useFormContext } from 'react-hook-form';
import { memo, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { TFunction } from 'i18next';
// Components, Layouts, Pages
import {
  BaseButton,
  BasePagination,
  BaseTable,
  FormAuthorizationModal,
  StatusLabel,
  ThreeDotTableOptions,
} from '~/components';

// Others
import { IAuthorization, IFormBillingPolicy, IListQueryPayroll, IPayRate } from '~/utils/interface/rate';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_LIMIT_PAGE,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { ButtonTypeEnum, CRMEnum, StatusEnum, TimeFormatEnum, TranslationEnum } from '~/utils/enum';
import { ColumnTableType, IPaginationResponse, IQueryParams } from '~/utils/interface/common';
import { convertDateToFormatTime } from '~/utils/helper';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { getListAuthorization } from '~/thunks/rate/rateThunk';
import { LoadingData } from '~/context';

// Styles, images, icons
import styles from './AuthorizationBillingPolicy.module.scss';
import { icons } from '~/assets';

type Props = {
  payRate?: IPayRate;
};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction<TranslationEnum.TRANSLATION>,
  onEditAuthorization: (id: string) => void
): ColumnTableType<IAuthorization>[] => {
  return [
    {
      key: 'authId',
      dataIndex: 'authId',
      title: t('billing_policy_table_authorization_id_title'),
      render(value, record) {
        return <div className={cx('textTable')}>{record?.authId ? <span>{record?.authId}</span> : EMPTY_STRING}</div>;
      },
    },
    {
      key: 'fromDate',
      title: t('billing_policy_table_authorization_from_title'),
      render: (value, record) => {
        return (
          <>{record?.fromDate ? convertDateToFormatTime(record?.fromDate, TimeFormatEnum.MM_DD_YYYY) : EMPTY_STRING}</>
        );
      },
    },
    {
      key: 'toDate',
      title: t('billing_policy_table_authorization_to_title'),
      render: (value, record) => {
        return (
          <>{record?.toDate ? convertDateToFormatTime(record?.toDate, TimeFormatEnum.MM_DD_YYYY) : EMPTY_STRING}</>
        );
      },
    },
    {
      key: 'status',
      title: t('billing_policy_table_authorization_status_title'),
      render: (value, record, index) => {
        return (
          <div className={cx('buttonStatus')}>
            <StatusLabel
              borderRadius={100}
              label={
                record.status === StatusEnum.ACTIVE
                  ? t('common_status_active_title')
                  : record.status === StatusEnum.CLOSED
                  ? t('common_status_closed_title')
                  : record.status === StatusEnum.SUSPENDED
                  ? t('common_status_suspended_title')
                  : record.status === StatusEnum.DECEASED
                  ? t('common_status_deceased_title')
                  : record.status
              }
              bgOpacity={0.1}
            />
          </div>
        );
      },
    },
    {
      key: 'billRate',
      title: t('billing_policy_table_authorization_bill_rate_title'),
      render(value, record) {
        return <div className={cx('textTable')}>{record?.paidBy ? <span>{record?.paidBy}</span> : EMPTY_STRING}</div>;
      },
    },
    {
      key: 'action',
      title: t('common_text_action'),
      render(value, record, index) {
        return (
          <ThreeDotTableOptions
            data={record}
            permissions={{
              isEdit: true,
              isView: false,
              isDelete: false,
            }}
            onEdit={() => onEditAuthorization(record.id)}
          />
        );
      },
      width: 50,
    },
  ];
};

const AuthorizationBillingPolicy = (props: Props) => {
  //#region Destructuring Props
  const { payRate } = props;

  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loading = useContext(LoadingData);
  const { clientId } = useParams();
  const dispatch = useAppDispatch();

  const {
    control,
    formState: { errors },
  } = useFormContext<IFormBillingPolicy>();
  //#endregion Declare Hook

  //#region Selector
  const { isRefreshAuthorization } = useAppSelector((state) => state.rateState);

  //#endregion Selector

  //#region Declare State
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const [listTaskData, setListTaskData] = useState<IAuthorization[]>();
  const [pagination, setPagination] = useState<IPaginationResponse>();
  const pageSelected = useMemo<number>(() => Number(params?.page ?? DEFAULT_CURRENT_PAGE), [params?.page]);
  const [paramObject, setParamObject] = useState<IQueryParams>({
    page: pageSelected,
    limit: DEFAULT_LIMIT_PAGE,
  });
  const [isShowAuthorizationModal, setIsShowAuthorizationModal] = useState<boolean>(false);
  const [authorizationIdSelected, setAuthorizationIdSelected] = useState<string | undefined>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const newParams: IListQueryPayroll = {
      userId: clientId,
      userType: CRMEnum.CLIENT,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
    };

    handleGetListAuthorization(newParams);
  }, [params]);

  useEffect(() => {
    if (!isRefreshAuthorization) return;

    const newParams: IListQueryPayroll = {
      userId: clientId,
      userType: CRMEnum.CLIENT,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
    };

    handleGetListAuthorization(newParams);
  }, [isRefreshAuthorization]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleShowAuthorizationModal = () => {
    setIsShowAuthorizationModal(true);
  };

  const handleCloseAuthorizationModal = () => {
    setIsShowAuthorizationModal(false);
    setAuthorizationIdSelected(undefined);
  };

  const handlePaginationChange = (page: number) => {
    const newParamObject: IQueryParams = { ...paramObject, page };
    setParamObject(newParamObject);
    setSearchParams({ ...params, page: page.toString(), limit: `${DEFAULT_LIMIT_PAGE}` });
  };

  const handleGetListAuthorization = (payload: IListQueryPayroll) => {
    if (!clientId) return;
    loading?.show();

    dispatch(getListAuthorization(payload))
      .unwrap()
      .then((res) => {
        const { responses, pagination } = res?.data;
        setListTaskData(responses);
        setPagination(pagination);
      })
      .catch(() => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const handleShowEditAuthorization = (authorizationId: string) => {
    if (!authorizationId) return;

    setAuthorizationIdSelected(authorizationId);
    handleShowAuthorizationModal();
  };
  //#endregion Handle Function

  return (
    <div id='overtimeSettingsComponent' className={cx('overtimeSettingsComponent')}>
      <div className={cx('content')}>
        <div className={cx('headerContent')}>
          <h1 className={cx('title')}>{t('billing_policy_authorization_title')}</h1>
          <BaseButton
            type='button'
            typeStyle={ButtonTypeEnum.PRIMARY}
            iconLeft={icons.commonIconPlus}
            text={t('billing_policy_add_authorization_title')}
            onClick={handleShowAuthorizationModal}
          />
        </div>
        <div className={cx('contentTable')}>
          <div className={cx('tableWrap')}>
            <BaseTable columns={columns(t, handleShowEditAuthorization)} dataSource={listTaskData ?? []} />
          </div>
          <div className={cx('paginationTable')}>
            <BasePagination
              defaultCurrentPage={pageSelected}
              totalItems={pagination?.totalItems}
              totalPages={pagination?.totalPages}
              onChange={handlePaginationChange}
            />
          </div>
        </div>
      </div>

      {isShowAuthorizationModal && (
        <FormAuthorizationModal
          isOpen={isShowAuthorizationModal}
          onClose={handleCloseAuthorizationModal}
          payRate={payRate}
          authorizationId={authorizationIdSelected}
        />
      )}
    </div>
  );
};

export default memo(AuthorizationBillingPolicy);
