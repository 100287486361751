// Libs
import classNames from 'classnames/bind';
import * as yup from 'yup';
import { TFunction } from 'i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { memo, useEffect, useState } from 'react';
// Components, Layouts, Pages
import { BaseButton, BaseTextarea, Loading, ModalUnderDevelopment } from '~/components';
// Others
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { COMMA_SEPARATOR, DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
import { ButtonTypeEnum, TimeFormatEnum } from '~/utils/enum';
import { createUnavailabilityNote } from '~/thunks/schedule/scheduleThunk';
import { ICreateUnavailabilityNote, IDetailUnavailability, INoteUnavailability } from '~/utils/interface/schedule';
import { convertDateToFormatTime, getUserName } from '~/utils/helper';
// Styles, images, icons
import styles from './NotesEventTab.module.scss';

type Props = {
  detailUnavailability: IDetailUnavailability;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup.object({
    unavailabilityId: yup.string().required(),
    description: yup.string().required(),
  });
};

const defaultValues: ICreateUnavailabilityNote = {
  unavailabilityId: EMPTY_STRING,
  description: EMPTY_STRING,
};

const NotesEventTab = (props: Props) => {
  //#region Destructuring Props
  const { detailUnavailability } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<ICreateUnavailabilityNote>({
    resolver: yupResolver(schema(t)),
    defaultValues: defaultValues,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isOpenDevelopment, setIsOpenDevelopment] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (detailUnavailability.id) {
      setValue('unavailabilityId', detailUnavailability.id);
    }
  }, [detailUnavailability]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleCloseDevelopment = () => {
    setIsOpenDevelopment(false);
  };

  const handleSubmitForm = (data: ICreateUnavailabilityNote) => {
    const payload: ICreateUnavailabilityNote = {
      ...data,
      unavailabilityId: detailUnavailability.id || EMPTY_STRING,
      description: data.description,
    };

    handleAddUnavailabilityNote(payload);
  };

  const handleAddUnavailabilityNote = (params: ICreateUnavailabilityNote) => {
    setIsLoading(true);

    dispatch(createUnavailabilityNote(params))
      .unwrap()
      .then((res) => {
        reset({ unavailabilityId: detailUnavailability.id, description: EMPTY_STRING });
      })
      .catch((err) => {})
      .finally(() => setIsLoading(false));
  };

  const renderNoteDetails = (note: INoteUnavailability) => {
    return (
      <>
        <span className={cx('label')} />
        <span>
          "{note.description || EMPTY_STRING}"{COMMA_SEPARATOR}
          <span className={cx('noteContent')}>
            {` ${t('tab_notes_modal_created_by_label')} ${
              note?.createdBy ? getUserName(note?.firstName, note?.lastName) : EMPTY_STRING
            }${COMMA_SEPARATOR} ${
              note?.createdAt
                ? convertDateToFormatTime(note.createdAt, TimeFormatEnum.MM_DD_YYYY_HH_AM_PM)
                : EMPTY_STRING
            }`}
          </span>
        </span>
      </>
    );
  };
  //#endregion Handle Function

  return (
    <form id='notesEventTab' className={cx('notesEventTab')} onSubmit={handleSubmit(handleSubmitForm)}>
      <div className={cx('container')}>
        <p className={cx('tabNotesTitle')}>{t('tab_notes_title')}</p>
        <div className={cx('tabNotesContainer')}>
          <div className={cx('tabNotesContent')}>
            <div className={cx('noteListWrapper')}>
              {detailUnavailability?.notes &&
                detailUnavailability.notes.length > DEFAULT_NUMBER_ZERO &&
                detailUnavailability.notes.map((note, index) => (
                  <div key={index} className={cx('noteItem')}>
                    {renderNoteDetails(note)}
                  </div>
                ))}
            </div>

            <div className={cx('tabNotes')}>
              <Controller
                name='description'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseTextarea
                    id='description'
                    label={t('tab_notes_modal_notes_label')}
                    placeholder={t('tab_notes_modal_notes_placeholder')}
                    value={value || EMPTY_STRING}
                    onChange={onChange}
                    height={35}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={cx('border')}></div>

      <div className={cx('footerButton')}>
        <BaseButton
          text={t('common_save_label')}
          typeStyle={ButtonTypeEnum.PRIMARY}
          width={80}
          type='submit'
          disabled={!isValid}
        />
      </div>
      {isOpenDevelopment && <ModalUnderDevelopment onClose={handleCloseDevelopment} />}

      {isLoading && <Loading />}
    </form>
  );
};

export default memo(NotesEventTab);
