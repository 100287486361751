// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TFunction } from 'i18next';

// Components, Layouts, Pages
import {
  BaseButton,
  BasePagination,
  BaseTable,
  ModalUnderDevelopment,
  ThreeDotTableOptions,
  ToolBar,
} from '~/components';

// Others
import { AccountRoleCodesEnum, ButtonTypeEnum, StorageEnum, TimeFormatEnum, TranslationEnum } from '~/utils/enum';
import { adminRouteAbsolute, staffRouteAbsolute, superAdminRouteAbsolute } from '~/utils/constants/route';
import { ColumnTableType, IListDataResponse, IPaginationResponse } from '~/utils/interface/common';
import { IInitialCall, IListQueryParamsInitialCall } from '~/utils/interface/crm/initialCall';
import { DEFAULT_CURRENT_PAGE, DEFAULT_LIMIT_PAGE, DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
import { convertDateToFormatTime, getUserName } from '~/utils/helper';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { getListInitialCall } from '~/thunks/crm/initialCall/initialCallThunk';
import { LoadingData } from '~/context';

// Styles, images, icons
import styles from './CRMInitialCall.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction<TranslationEnum.TRANSLATION>,
  handleViewDetails: (id: string) => void
): ColumnTableType<IInitialCall>[] => {
  return [
    {
      key: 'caller',
      title: t('crm_initial_call_caller_name_title'),
      render(value, record, index) {
        return <>{getUserName(record?.caller?.firstName, record?.caller?.lastName)}</>;
      },
    },
    {
      key: 'prospect',
      title: t('crm_initial_call_prospect_name_title'),
      dataIndex: 'prospect',
      render(value, record, index) {
        return <>{getUserName(record.prospect?.firstName, record.prospect?.lastName)}</>;
      },
    },
    {
      key: 'referral',
      title: t('crm_initial_call_referral_name_title'),
      render(value, record, index) {
        return (
          <>
            {record.referral?.organization
              ? record.referral.organization
              : getUserName(record.referral?.firstName, record.referral?.lastName)}
          </>
        );
      },
    },
    {
      key: 'callDate',
      title: t('crm_initial_call_call_date_title'),
      dataIndex: 'callDate',
      render(value, record, index) {
        return (
          <>{record.callDate ? convertDateToFormatTime(record.callDate, TimeFormatEnum.MM_DD_YYYY) : EMPTY_STRING}</>
        );
      },
    },

    {
      key: 'action',
      title: t('common_text_action'),
      render(value, record, index) {
        return (
          <ThreeDotTableOptions
            permissions={{
              isEdit: false,
              isDelete: false,
            }}
            onView={() => handleViewDetails(record.id)}
          />
        );
      },
      width: 50,
    },
  ];
};

const CRMInitialCall = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);
  const role = localStorage.getItem(StorageEnum.ROLE);
  //#endregion Declare Hook

  //#region Selector
  const { isRefreshListInitialCall } = useAppSelector((state) => state.initialCallState);
  //#endregion Selector

  //#region Declare State
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(() => Number(params?.page ?? DEFAULT_CURRENT_PAGE), [params?.page]);
  const [pagination, setPagination] = useState<IPaginationResponse>();
  const [listInitialCall, setListInitialCall] = useState<IInitialCall[]>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const { ...restParams } = params;

    const newParams = {
      ...restParams,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_LIMIT_PAGE,
    };

    handleGetListInitialCall(newParams);
  }, [params]);

  useEffect(() => {
    if (!isRefreshListInitialCall) return;

    const { ...restParams } = params;

    const newParams = {
      ...restParams,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_LIMIT_PAGE,
    };

    handleGetListInitialCall(newParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshListInitialCall]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleAddInitialCall = () => {
    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(adminRouteAbsolute.addInitialCall);
        break;

      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(superAdminRouteAbsolute.addInitialCall);
        break;

      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(staffRouteAbsolute.addInitialCall);
        break;
    }
  };

  const handleClickUnderDevelop = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleViewDetails = (id: string) => {
    if (!id) return;
    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.crmInitialCall}/${id}`);
        break;

      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRouteAbsolute.crmInitialCall}/${id}`);
        break;

      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(`${staffRouteAbsolute.crmInitialCall}/${id}`);
        break;

      default:
        break;
    }
  };

  const handlePaginationChange = (page: number) => {
    setSearchParams({ ...params, page: page.toString(), limit: `${DEFAULT_LIMIT_PAGE}` });
  };

  const handleGetListInitialCall = (params: IListQueryParamsInitialCall) => {
    loading?.show();

    dispatch(getListInitialCall(params))
      .unwrap()
      .then((res) => {
        const { responses, pagination }: IListDataResponse<IInitialCall[]> = res?.data;

        if (!responses || responses.length === DEFAULT_NUMBER_ZERO) return;

        setListInitialCall(responses);
        setPagination(pagination);
      })
      .catch((error) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const handleClickRow = (record: IInitialCall) => {
    if (!record.id) return;
    handleViewDetails(record.id);
  };
  //#endregion Handle Function

  return (
    <div id='CRMInitialCallTemplate' className={cx('container')}>
      <ToolBar
        title={
          <div className={cx('crmTitle')}>
            <span>{t('crm_initial_call_title')}</span>
          </div>
        }
      >
        <BaseButton
          typeStyle={ButtonTypeEnum.PRIMARY}
          iconLeft={icons.commonIconPlus}
          text={t('crm_add_initial_call_title')}
          height={36}
          onClick={handleAddInitialCall}
        />
      </ToolBar>

      <div className={cx('content')}>
        <div className={cx('tableWrap')}>
          <BaseTable
            columns={columns(t, handleViewDetails)}
            dataSource={listInitialCall ?? []}
            onClickRow={handleClickRow}
          />
        </div>

        <div className={cx('paginationTable')}>
          <BasePagination
            onChange={handlePaginationChange}
            defaultCurrentPage={pageSelected}
            totalItems={pagination?.totalItems}
            totalPages={pagination?.totalPages}
          />
        </div>
      </div>

      {isShowModalUnderDevelopment && <ModalUnderDevelopment onClose={handleClickUnderDevelop} />}
    </div>
  );
};

export default CRMInitialCall;
