// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import skillApi from '~/apis/caregivers/skill';
import { CREATE_SKILL, EDIT_SKILL, GET_DETAIL_SKILL } from '~/utils/constants/actionType';
import { IFormSkill, IParamsGetDetailSkill } from '~/utils/interface/caregivers/skill';

export const createSkill = createAsyncThunk(CREATE_SKILL, async (body: IFormSkill, { rejectWithValue }) => {
  try {
    const res = await skillApi.createSkill(body);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDetailSkill = createAsyncThunk(
  GET_DETAIL_SKILL,
  async (params: IParamsGetDetailSkill, { rejectWithValue }) => {
    try {
      const res = await skillApi.getDetailSkill(params);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editSkill = createAsyncThunk(EDIT_SKILL, async (body: IFormSkill, { rejectWithValue }) => {
  try {
    const res = await skillApi.editSkill(body);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
