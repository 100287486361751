// Libs
import classNames from 'classnames/bind';
import moment from 'moment';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
import { BaseButton, BaseDatePicker, BasePagination, BaseTable, ToolBar } from '~/components';
// Others
import { ButtonTypeEnum, CurrencyEnum, TimeFormatEnum, ToastTypeEnum, TranslationEnum } from '~/utils/enum';
import { ColumnTableType, IPaginationResponse } from '~/utils/interface/common';
import {
  DEFAULT_CURRENCY,
  DEFAULT_CURRENT_PAGE,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { IParamsReportInvoice, IReportInvoice } from '~/utils/interface/report';
import { customToast, formatCurrency } from '~/utils/helper';
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { getReportInvoice } from '~/thunks/report/reportThunk';
// Styles, images, icons
import styles from './ReportInvoice.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const columns = ({ t }: { t: TFunction<TranslationEnum.TRANSLATION> }): ColumnTableType<IReportInvoice>[] => {
  return [
    {
      key: 'location',
      title: t('invoice_report_table_location_label'),
      render(_, record) {
        return record?.name || EMPTY_STRING;
      },
    },
    {
      key: 'totalInvoice',
      title: t('invoice_report_table_total_invoice_label'),
      render(_, record) {
        return record.totalInvoice ?? DEFAULT_NUMBER_ZERO;
      },
    },
    {
      key: 'totalAmount',
      title: t('invoice_report_table_total_amount_label'),
      render(_, record) {
        return record.amount ? formatCurrency(CurrencyEnum.USD, record.amount) : DEFAULT_CURRENCY;
      },
    },
  ];
};

const ReportInvoice = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(() => Number(params?.page ?? DEFAULT_CURRENT_PAGE), [params?.page]);
  const startDateParams = useMemo<string>(() => String(params?.startDate || EMPTY_STRING), [params?.startDate]);
  const endDateParams = useMemo<string>(() => String(params?.endDate || EMPTY_STRING), [params?.endDate]);
  const DEFAULT_END_DATE = useMemo(() => {
    return moment().subtract(1, 'day').format(TimeFormatEnum.YYYY_MM_DD);
  }, []);
  const DEFAULT_START_DATE = useMemo(() => {
    return moment(DEFAULT_END_DATE).subtract(7, 'days').format(TimeFormatEnum.YYYY_MM_DD);
  }, [DEFAULT_END_DATE]);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [reportInvoiceList, setReportInvoiceList] = useState<IReportInvoice[]>([]);
  const [pagination, setPagination] = useState<IPaginationResponse>();
  const [dateRange, setDateRange] = useState<{ startDate?: string; endDate?: string }>({
    startDate: DEFAULT_START_DATE,
    endDate: DEFAULT_END_DATE,
  });
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (dateRange.endDate && dateRange.startDate && moment(dateRange.endDate).isBefore(moment(dateRange.startDate))) {
      customToast(ToastTypeEnum.ERROR, t('error_end_date_after_start_date_invoice'));
      return;
    }

    const paramsReportInvoice: IParamsReportInvoice = {
      ...params,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      startDate: params.startDate || dateRange.startDate,
      endDate: params.endDate || dateRange.endDate,
    };

    handleGetListReportInvoice(paramsReportInvoice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListReportInvoice = (params: IParamsReportInvoice) => {
    loading?.show();

    dispatch(getReportInvoice(params))
      .unwrap()
      .then((res) => {
        const { responses, pagination } = res.data;

        if (!responses) return;

        setReportInvoiceList(responses);
        setPagination(pagination);
      })
      .catch(() => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const handleApply = () => {
    if (!dateRange) return;

    setSearchParams({
      ...params,
      page: DEFAULT_NUMBER_ONE.toString(),
      limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
      ...(dateRange.startDate ? { startDate: dateRange.startDate } : {}),
      ...(dateRange.endDate ? { endDate: dateRange.endDate } : {}),
    });
  };

  const handlePaginationChange = (page: number) => {
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };
  //#endregion Handle Function

  return (
    <div id='reportInvoicePage' className={cx('container')}>
      <ToolBar title={t('invoice_report_title')}>
        <div className={cx('controlWrap')}>
          <BaseDatePicker
            typeDatePicker='row'
            label={t('invoice_report_start_date_title')}
            placeholderText={t('common_placeholder_select')}
            value={startDateParams || dateRange.startDate}
            onDateSelected={(date) => {
              setDateRange((prev) => ({ ...prev, startDate: date }));
            }}
          />

          <BaseDatePicker
            typeDatePicker='row'
            label={t('invoice_report_end_date_title')}
            placeholderText={t('common_placeholder_select')}
            value={endDateParams || dateRange.endDate}
            onDateSelected={(date) => {
              setDateRange((prev) => ({ ...prev, endDate: date }));
            }}
          />
        </div>

        <BaseButton
          typeStyle={ButtonTypeEnum.PRIMARY}
          text={t('common_text_apply')}
          onClick={handleApply}
          width={75}
          height={36}
        />
      </ToolBar>

      <div className={cx('tableWrap')}>
        <BaseTable
          columns={columns({
            t,
          })}
          dataSource={reportInvoiceList ?? []}
        />
      </div>

      <div className={cx('paginationTable')}>
        <BasePagination
          onChange={handlePaginationChange}
          defaultCurrentPage={pageSelected}
          totalItems={pagination?.totalItems}
          totalPages={pagination?.totalPages}
        />
      </div>
    </div>
  );
};

export default ReportInvoice;
