// Libs
import classNames from 'classnames/bind';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
import {
  AddFiles,
  BaseButton,
  DetailActivities,
  DetailContactTab,
  DocumentsTab,
  ImageCircle,
  ProspectInfoTab,
  RelateDeletedModal,
  ShiftDocumentTab,
  StatusLabel,
  Tab,
} from '~/components';
// Others
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { getDetailClients } from '~/thunks/crm/clients/clientsThunk';
import {
  CODE_MESSAGE_CLIENT_DELETED,
  EMPTY_STRING,
  NAVIGATE_BACK,
  QUERY_KEY_TAB,
  prospectDetailTabs,
} from '~/utils/constants/common';
import {
  AddFileEnum,
  ButtonTypeEnum,
  CLientStatusEnum,
  CRMEnum,
  ImageCircleTypeEnum,
  TabQueryClientDetailEnum,
  TypeFilesDocumentEnum,
} from '~/utils/enum';
import { createFormData, getUserName } from '~/utils/helper';
import { IClientDetail } from '~/utils/interface/crm/clients';
import { ErrorData } from '~/utils/interface/common';
import { clientActions } from '~/thunks/crm/clients/clientsSlice';
import { IUploadFileDocument } from '~/utils/interface/file';
import { uploadDocument } from '~/thunks/file/fileThunk';
import { fileActions } from '~/thunks/file/fileSlice';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './CrmProspectDetail.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const CrmDetail = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clientId } = useParams();
  const [searchParams] = useSearchParams();
  const currentTab = searchParams.get(QUERY_KEY_TAB);
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [dataProfileClient, setDataProfileClient] = useState<IClientDetail>();
  const [isShowClientDeleted, setIsShowClientDeleted] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    return () => {
      dispatch(clientActions.resetClientDetail());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!clientId) return;

    handleGetDetailClient(clientId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleBack = () => {
    navigate(NAVIGATE_BACK);
  };

  const handleGetDetailClient = (clientId: string) => {
    loading?.show();

    dispatch(getDetailClients(clientId))
      .unwrap()
      .then((res) => {
        const responses: IClientDetail = res?.data;
        setDataProfileClient(responses);
      })
      .catch((error) => {
        const { code } = error?.response?.data as ErrorData;

        if (code === CODE_MESSAGE_CLIENT_DELETED) setIsShowClientDeleted(!isShowClientDeleted);
      })
      .finally(() => {
        loading?.hide();
      });
  };

  const handleShowClientDeleted = () => {
    setIsShowClientDeleted(!isShowClientDeleted);

    navigate(NAVIGATE_BACK);
  };
  //#endregion Handle Function

  const renderTabContent = useMemo(() => {
    const tabToRender = currentTab || TabQueryClientDetailEnum.INFO;
    switch (tabToRender) {
      case TabQueryClientDetailEnum.CARE_PLAN:
        // Handle Return Tab Later
        return <div className={cx('noDataAvailable')}>{t('common_empty_data')}</div>;
      case TabQueryClientDetailEnum.DOCUMENTS:
        return clientId && <DocumentsTab clientId={clientId} />;
      case dataProfileClient?.type === CRMEnum.CLIENT && TabQueryClientDetailEnum.SHIFT_SCHEDULE:
        return clientId && <ShiftDocumentTab clientId={clientId} data={dataProfileClient} />;
      case TabQueryClientDetailEnum.ACTIVITIES:
        // Handle Return Tab Later
        return <DetailActivities typeActivity='prospect' />;
      case TabQueryClientDetailEnum.CONTACT:
        return <DetailContactTab />;
      case TabQueryClientDetailEnum.INFO:
        return <ProspectInfoTab />;
      default:
        // Handle Return Tab Later
        return <div className={cx('noDataAvailable')}>{t('common_empty_data')}</div>;
    }
  }, [currentTab, clientId, dataProfileClient, t]);

  const handleUploadDocument = async (data: IUploadFileDocument) => {
    if (!dataProfileClient?.id) return;

    const dataCreate = { ...data, businessId: dataProfileClient?.id, type: TypeFilesDocumentEnum.CLIENT_DOCUMENT };

    const formData = await createFormData(dataCreate, AddFileEnum.FILES);

    loading?.show();

    dispatch(uploadDocument(formData))
      .unwrap()
      .then((response) => {
        dispatch(fileActions.setRefreshList(true));
      })
      .catch((error) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  return (
    <div
      id='clientDetailPage'
      className={cx('container', { documents: currentTab === TabQueryClientDetailEnum.DOCUMENTS })}
    >
      <div className={cx('mainBody', { documents: currentTab === TabQueryClientDetailEnum.DOCUMENTS })}>
        {dataProfileClient && (
          <>
            <BaseButton
              onClick={handleBack}
              text={t('common_text_back')}
              typeStyle={ButtonTypeEnum.ONLY_TEXT}
              iconLeft={icons.commonIconPrev}
              height={17}
            />

            <section className={cx('headGroup')}>
              <div className={cx('basicInfoGroup')}>
                <ImageCircle
                  type={dataProfileClient?.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
                  width={91}
                  height={91}
                  imageUrl={dataProfileClient?.avatarUrl}
                  fontSize={45}
                  firstName={dataProfileClient?.firstName}
                  lastName={dataProfileClient?.lastName}
                />

                <div className={cx('nameGroup')}>
                  <div className={cx('fullName')}>
                    {getUserName(
                      dataProfileClient?.firstName,
                      dataProfileClient?.middleName,
                      dataProfileClient?.lastName
                    )}
                  </div>
                  <div className={cx('valueGroup')}>
                    <span>{t('prospect_detail_label_status')}</span>
                    <span>
                      {dataProfileClient?.status ? (
                        <StatusLabel
                          height={24}
                          label={
                            dataProfileClient?.status === CLientStatusEnum.ACTIVE
                              ? t('common_status_active_title')
                              : dataProfileClient?.status === CLientStatusEnum.INACTIVE
                              ? t('common_status_deactive_title')
                              : dataProfileClient?.status
                          }
                          type='text'
                        />
                      ) : (
                        EMPTY_STRING
                      )}
                    </span>
                  </div>
                  <div className={cx('valueGroup')}>
                    <span>{t('prospect_detail_label_referred_by')}</span>
                    <span className={cx('value')}>
                      {dataProfileClient?.referrer?.organizationName ||
                        getUserName(
                          dataProfileClient?.referrer?.firstName,
                          dataProfileClient?.referrer?.middleName,
                          dataProfileClient?.referrer?.lastName
                        )}
                    </span>
                  </div>
                </div>
              </div>

              <div className={cx('navTabGroup')}>
                <Tab items={prospectDetailTabs} paddingBottom={30} />
              </div>
            </section>

            <section className={cx('tabContent')}>{renderTabContent}</section>
          </>
        )}
      </div>

      {dataProfileClient && currentTab === TabQueryClientDetailEnum.DOCUMENTS && (
        <AddFiles title={t('add_document_title')} onSubmitFormSuccess={handleUploadDocument} />
      )}

      <RelateDeletedModal isOpen={isShowClientDeleted} onClose={handleShowClientDeleted} />
    </div>
  );
};

export default CrmDetail;
