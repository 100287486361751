// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
// Components, Layouts, Pages
import {
  BaseButton,
  ImageCircle,
  ModalUnderDevelopment,
  ConfirmModal,
  ThreeDotTableOptions,
  SendEmailMultipleModal,
} from '~/components';
// Others
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { createConversation } from '~/thunks/conversation/conversationThunk';
import { ICreateConversationReq } from '~/utils/interface/conversation';
import { IFormSendMail } from '~/utils/interface/common';
import { EMPTY_STRING } from '~/utils/constants/common';
import { TEAL_24604D } from '~/utils/constants/color';
import {
  AccountRoleCodesEnum,
  ButtonTypeEnum,
  StatusEnum,
  ImageCircleTypeEnum,
  StatusTypeEnum,
  StorageEnum,
} from '~/utils/enum';
import { convertBooleanToString, getRoutesByRole, getUserName } from '~/utils/helper';
import {
  adminRoute,
  adminRouteAbsolute,
  staffRoute,
  staffRouteAbsolute,
  superAdminRoute,
  superAdminRouteAbsolute,
} from '~/utils/constants/route';
import { deleteCaregiver, sendEmailCaregivers } from '~/thunks/caregiver/caregiverThunk';
import { ICaregiver } from '~/utils/interface/caregiver';
import { caregiverActions } from '~/thunks/caregiver/caregiverSlice';
import { IFormUpdateStatusAccount } from '~/utils/interface/user';
import { changeStatusAccount } from '~/thunks/user/userThunk';
import { useTextOverflow } from '~/utils/customHook';
// Styles, images, icons
import styles from './CaregiverItem.module.scss';
import { icons } from '~/assets';

type Props = {
  data?: ICaregiver;
};

const cx = classNames.bind(styles);

const CaregiverItem = (props: Props) => {
  //#region Destructuring Props
  const { data } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loadingContext = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const role = localStorage.getItem(StorageEnum.ROLE);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false);
  const [isShowConfirmTerminated, setIsShowConfirmTerminated] = useState<boolean>(false);
  const [isDevelopment, setIsDevelopment] = useState<boolean>(false);
  const [isShowSendEmailModal, setIsShowSendEmailModal] = useState<boolean>(false);
  const [isLoadingSendEmail, setIsLoadingSendEmail] = useState<boolean>(false);
  const contentRefs = useRef<HTMLDivElement | null>(null);
  const overflowMap = useTextOverflow(contentRefs.current, [data]);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleMessage = () => {
    if (!data || !data.id) return;

    const payload: ICreateConversationReq = {
      accountIds: [Number(data.id)],
    };

    loadingContext?.show();

    dispatch(createConversation(payload))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        const { conversationId, id } = res.data;
        if (!id && !conversationId) return;

        const params = new URLSearchParams({ conversationId: conversationId || id });
        navigate(`${getRoutesByRole(role)?.messages ?? EMPTY_STRING}?${params.toString()}`);
      })
      .catch((error) => {})
      .finally(() => loadingContext?.hide());
  };

  const handleCall = () => {
    // TODO: Handle call
    setIsOpen(true);
  };

  const handleViewCaregiver = () => {
    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRoute.base}${superAdminRoute.caregiver}/${superAdminRoute.caregiverShowAll}/${data?.id}`);
        break;
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRoute.base}${adminRoute.caregiver}/${adminRoute.caregiverShowAll}/${data?.id}`);
        break;
      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(`${staffRoute.base}${staffRoute.caregiver}/${staffRoute.caregiverShowAll}/${data?.id}`);
        break;
    }
  };

  const handleEditCaregiver = () => {
    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRouteAbsolute.editCaregiver}/${data?.id}`);
        break;
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.editCaregiver}/${data?.id}`);
        break;

      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(`${staffRouteAbsolute.editCaregiver}/${data?.id}`);
        break;

      default:
        break;
    }
  };

  // const handleDeleteCaregiver = () => {
  //   if (!data || !data.id) return;

  //   return dispatch(deleteCaregiver(data.id))
  //     .unwrap()
  //     .then((response) => {
  //       dispatch(caregiverActions.setRefreshList(true));
  //       handleShowModalConfirmDelete();
  //     })
  //     .catch((error) => {})
  //     .finally(() => loadingContext?.hide());
  // };

  const handleClose = () => {
    setIsOpen(false);
  };

  // const handleShowModalConfirmDelete = () => {
  //   setIsShowConfirmDelete(!isShowConfirmDelete);
  // };

  const handleShowModalConfirmTerminated = () => {
    setIsShowConfirmTerminated(!isShowConfirmTerminated);
  };

  const handleTerminated = () => {
    if (data) {
      setIsShowConfirmTerminated(false);
      return handleChangeStatus(data);
    }

    setIsShowConfirmTerminated(false);
  };

  const handleChangeStatus = (record: ICaregiver) => {
    if (!record?.id) return;

    const payload: IFormUpdateStatusAccount = {
      clientId: record.id,
      body: {
        status: StatusEnum.INACTIVE,
      },
    };

    return dispatch(changeStatusAccount(payload))
      .unwrap()
      .then(() => {
        dispatch(caregiverActions.setRefreshList(true));
      })
      .catch((_err) => {})
      .finally(() => {
        loadingContext?.hide();
      });
  };

  const handleShowUnderDevelopment = () => {
    setIsDevelopment(false);
  };

  const handleShowSendEmail = () => {
    setIsShowSendEmailModal(true);
  };

  const handleCloseSendEmail = () => {
    setIsShowSendEmailModal(false);
  };

  const handleSendEmail = async (dataEmail: IFormSendMail) => {
    if (!data?.id) return;
    setIsLoadingSendEmail(true);

    const formData = new FormData();

    const formDataFields = {
      from: dataEmail?.from || EMPTY_STRING,
      to: JSON.stringify(dataEmail?.to || []),
      cc: JSON.stringify(dataEmail?.cc || []),
      bcc: JSON.stringify(dataEmail?.bcc || []),
      subject: dataEmail?.subject || EMPTY_STRING,
      content: dataEmail?.content || EMPTY_STRING,
    };

    Object.entries(formDataFields).forEach(([key, value]) => {
      if (value) {
        formData.append(key, value);
      }
    });

    if (dataEmail?.files) {
      dataEmail?.files?.forEach((file) => {
        file && formData.append('files', file);
      });
    }

    dispatch(sendEmailCaregivers(formData))
      .unwrap()
      .then((res) => {
        handleCloseSendEmail();
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoadingSendEmail(false);
      });
  };
  //#endregion Handle Function

  return (
    <div id='caregiverItemComponent' className={cx('container')} style={{ cursor: 'pointer' }}>
      <div className={cx('containerContent')} onClick={handleViewCaregiver}>
        <div className={cx('imageCircleWrap')}>
          <ImageCircle
            type={data?.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
            imageUrl={data?.avatarUrl}
            width={130}
            height={130}
            firstName={data?.firstName || EMPTY_STRING}
            lastName={data?.lastName || EMPTY_STRING}
            fontSize={36}
          />
        </div>

        <div className={cx('textGroup')}>
          {/* {data && convertBooleanToString(data?.isOnline) !== EMPTY_STRING ? (
            <div className={cx('statusWrap')}>
              <div className={cx('statusCircle', !data.isOnline && 'statusCircleInactive')} />
              <div className={cx('statusText', !data.isOnline && 'statusTextInactive')}>
                {t(data.isOnline ? StatusTypeEnum.ONLINE : StatusTypeEnum.OFFLINE)}
              </div>
            </div>
          ) : (
            <span className={cx('statusEmpty')}>{EMPTY_STRING}</span>
          )} */}
          <div
            data-tooltip-id={`name-${data?.id}`}
            ref={contentRefs}
            className={cx('nameCaregiver')}
            data-tooltip-place='top'
            data-tooltip-content={getUserName(data?.firstName, data?.middleName, data?.lastName) || EMPTY_STRING}
          >
            {getUserName(data?.firstName, data?.middleName, data?.lastName)}
          </div>
        </div>
      </div>

      <div className={cx('contact')}>
        <BaseButton
          iconLeft={icons.commonIconDropdownBorder}
          text={t('caregiver_item_message_label')}
          minWidth={96}
          onClick={handleMessage}
          borderColor={TEAL_24604D}
        />
        <BaseButton
          typeStyle={ButtonTypeEnum.PRIMARY}
          iconLeft={icons.commonIconCall}
          text={t('caregiver_item_call_label')}
          minWidth={96}
          onClick={handleCall}
        />
      </div>

      <div className={cx('threeDotWrap')}>
        <ThreeDotTableOptions
          onView={handleViewCaregiver}
          onEdit={handleEditCaregiver}
          onSendEmail={handleShowSendEmail}
          // onDelete={handleShowModalConfirmDelete}
          onTerminated={handleShowModalConfirmTerminated}
          permissions={{
            isView: true,
            isEdit: true,
            isDelete: false,
            isSendEmail: true,
            isTerminated: true,
          }}
        />
      </div>
      {isOpen && <ModalUnderDevelopment onClose={handleClose} />}

      {/* <ConfirmModal
        isOpen={isShowConfirmDelete}
        title={t('common_confirm_delete_title', {
          name: getUserName(data?.firstName, data?.lastName),
        })}
        titleAction={t('common_delete_label')}
        onCancel={handleShowModalConfirmDelete}
        onAction={handleDeleteCaregiver}
        type='danger'
      /> */}

      <ConfirmModal
        isOpen={isShowConfirmTerminated}
        title={t('common_confirm_terminated_person_title')}
        titleAction={t('common_action_terminated')}
        icon={icons.commonIconConfirmTerminated}
        onCancel={handleShowModalConfirmTerminated}
        onAction={handleTerminated}
        type='danger'
      />

      {isShowSendEmailModal && (
        <SendEmailMultipleModal
          isOpen={isShowSendEmailModal}
          onClose={handleCloseSendEmail}
          onSendEmail={handleSendEmail}
          isLoading={isLoadingSendEmail}
          defaultEmails={data?.email ? [data?.email] : []}
        />
      )}
      {isDevelopment && <ModalUnderDevelopment onClose={handleShowUnderDevelopment} />}

      {overflowMap.singleRef && <Tooltip id={`name-${data?.id}`} className={cx('tooltipText')} />}
    </div>
  );
};

export default CaregiverItem;
