// Libs
import classNames from 'classnames/bind';
import { Controller, useFormContext, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { memo } from 'react';
// Components, Layouts, Pages
import { BaseDatePicker, BaseInput, PlacesAutocomplete } from '~/components';
// Others
import { useAppDispatch } from '~/redux/hooks';
import { IFormSkill } from '~/utils/interface/caregivers/skill';
import { EMPTY_STRING } from '~/utils/constants/common';
import { formatPhoneNumber } from '~/utils/helper';
import { IAddress } from '~/utils/interface/common';
// Styles, images, icons
import styles from './EmploymentHistorySkill.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const EmploymentHistorySkill = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    control,
    setValue,
    resetField,
    getValues,
    formState: { errors },
  } = useFormContext<IFormSkill>();

  const {
    fields: employmentHistoryFields,
    append: appendEmploymentHistory,
    remove: removeEmploymentHistory,
  } = useFieldArray({
    control,
    name: 'employmentHistorySkill',
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleAddEmploymentHistoryField = () => {
    appendEmploymentHistory({
      employer: EMPTY_STRING,
      supervisor: EMPTY_STRING,
      phoneNumber: EMPTY_STRING,
      address: EMPTY_STRING,
      secondaryAddress: EMPTY_STRING,
      city: EMPTY_STRING,
      state: EMPTY_STRING,
      postalCode: EMPTY_STRING,
      dateEmployed: EMPTY_STRING,
      to: EMPTY_STRING,
    });
  };

  const handleChangeAddress = (index: number, address: IAddress) => {
    setValue(`employmentHistorySkill.${index}.address`, address?.address ?? EMPTY_STRING, {
      shouldDirty: true,
      shouldValidate: true,
    });
    address?.city &&
      setValue(`employmentHistorySkill.${index}.city`, address?.city ?? EMPTY_STRING, {
        shouldDirty: true,
        shouldValidate: true,
      });
    address?.state &&
      setValue(`employmentHistorySkill.${index}.state`, address?.state ?? EMPTY_STRING, {
        shouldDirty: true,
        shouldValidate: true,
      });
    address?.zipCode &&
      setValue(`employmentHistorySkill.${index}.postalCode`, address?.zipCode ?? EMPTY_STRING, {
        shouldDirty: true,
        shouldValidate: true,
      });
  };
  //#endregion Handle Function

  return (
    <div id='employmentHistorySkillComponent' className={cx('employmentHistorySkillContainer')}>
      <div className={cx('headTitle')}>{t('form_skill_employment_history_title')}</div>

      <div className={cx('bodyWrap')}>
        {employmentHistoryFields.map((employmentHistoryItem, index) => {
          return (
            <div className={cx('item')} key={employmentHistoryItem.id}>
              <div className={cx('itemCol')}>
                <div className={cx('threeCol')}>
                  <Controller
                    control={control}
                    name={`employmentHistorySkill.${index}.employer`}
                    render={({ field: { value, onChange } }) => (
                      <BaseInput
                        id={`employmentHistorySkill.${index}.employer`}
                        label={t('form_skill_employment_history_employer_title')}
                        value={value || EMPTY_STRING}
                        onChange={onChange}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`employmentHistorySkill.${index}.supervisor`}
                    render={({ field: { value, onChange } }) => (
                      <BaseInput
                        id={`employmentHistorySkill.${index}.supervisor`}
                        label={t('form_skill_employment_history_supervisor_title')}
                        value={value || EMPTY_STRING}
                        onChange={onChange}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={`employmentHistorySkill.${index}.phoneNumber`}
                    render={({ field: { value, onChange } }) => (
                      <BaseInput
                        id={`employmentHistorySkill.${index}.phoneNumber`}
                        label={t('form_skill_employment_history_phone_number_title')}
                        value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                        onChange={(event) => {
                          const { value } = event.target;
                          const formattedValue = formatPhoneNumber(value);
                          onChange(formattedValue);
                        }}
                      />
                    )}
                  />
                </div>

                <div className={cx('twoCol')}>
                  <Controller
                    name={`employmentHistorySkill.${index}.address`}
                    control={control}
                    render={({ field: { value } }) => (
                      <PlacesAutocomplete
                        id={`employmentHistorySkill.${index}.address`}
                        value={value || EMPTY_STRING}
                        label={t('form_skill_employment_history_address_title')}
                        onChange={(address) => handleChangeAddress(index, address)}
                      />
                    )}
                  />

                  <Controller
                    name={`employmentHistorySkill.${index}.secondaryAddress`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <BaseInput
                        id={`employmentHistorySkill.${index}.secondaryAddress`}
                        label={t('form_skill_employment_history_secondary_address_title')}
                        value={value || EMPTY_STRING}
                        onChange={onChange}
                      />
                    )}
                  />
                </div>

                <div className={cx('threeCol')}>
                  <Controller
                    name={`employmentHistorySkill.${index}.city`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <BaseInput
                        id={`employmentHistorySkill.${index}.city`}
                        label={t('form_skill_employment_history_city_title')}
                        value={value || EMPTY_STRING}
                        onChange={onChange}
                      />
                    )}
                  />

                  <Controller
                    name={`employmentHistorySkill.${index}.state`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <BaseInput
                        id={`employmentHistorySkill.${index}.state`}
                        value={value || EMPTY_STRING}
                        label={t('form_skill_employment_history_state_title')}
                        onChange={onChange}
                      />
                    )}
                  />

                  <Controller
                    name={`employmentHistorySkill.${index}.postalCode`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <BaseInput
                        id={`employmentHistorySkill.${index}.postalCode`}
                        label={t('form_skill_employment_history_postal_code_title')}
                        value={value || EMPTY_STRING}
                        onChange={onChange}
                      />
                    )}
                  />
                </div>

                <div className={cx('twoCol')}>
                  <Controller
                    name={`employmentHistorySkill.${index}.dateEmployed`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <BaseDatePicker
                        id={`employmentHistorySkill.${index}.dateEmployed`}
                        name={`employmentHistorySkill.${index}.dateEmployed`}
                        label={t('form_skill_employment_history_date_employed_title')}
                        onDateSelected={onChange}
                        value={value || EMPTY_STRING}
                        placeholderText={t('common_placeholder_select')}
                      />
                    )}
                  />

                  <Controller
                    name={`employmentHistorySkill.${index}.to`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <BaseDatePicker
                        id={`employmentHistorySkill.${index}.to`}
                        name={`employmentHistorySkill.${index}.to`}
                        label={t('form_skill_employment_history_to_title')}
                        onDateSelected={onChange}
                        value={value || EMPTY_STRING}
                        placeholderText={t('common_placeholder_select')}
                      />
                    )}
                  />
                </div>
              </div>

              <button type='button' onClick={() => removeEmploymentHistory(index)} className={cx('btnRemoveField')}>
                <img src={icons.commonIconRemoveField} alt={t('common_img_text_alt')} />
              </button>
            </div>
          );
        })}

        <button type='button' onClick={handleAddEmploymentHistoryField} className={cx('btnAddField')}>
          <img src={icons.commonIconAddField} alt={t('common_img_text_alt')} />
          <span className={cx('btnTextAdd')}>{t('form_skill_add_employment_history_title')}</span>
        </button>
      </div>
    </div>
  );
};

export default memo(EmploymentHistorySkill);
