// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { TFunction } from 'i18next';
import { useParams, useSearchParams } from 'react-router-dom';

// Components, Layouts, Pages
import { BasePagination, BaseTable, ConfirmModal, InputSearch, ModalUnderDevelopment, ToolBar } from '~/components';

// Others
import {
  A_ELEMENT,
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  OBJECT,
} from '~/utils/constants/common';
import { TimeFormatEnum, TranslationEnum, TypeFilesDocumentEnum } from '~/utils/enum';
import { ColumnTableType, IPaginationResponse } from '~/utils/interface/common';
import { convertDateToFormatTime, formatMimeType } from '~/utils/helper';
import { IListDocument, IListQueryParamsDocument } from '~/utils/interface/file';
import useDebounce from '~/utils/customHook';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { LoadingData } from '~/context';
import { fileActions, selectIsRefreshFileList } from '~/thunks/file/fileSlice';
import { deleteDocument, downloadDocument, getListDocuments } from '~/thunks/file/fileThunk';

// Styles, images, icons
import styles from './DocumentContactTab.module.scss';
import { icons } from '~/assets';

const cx = classNames.bind(styles);

type Props = {};

const columnsRecentlyDocument = (
  t: TFunction<TranslationEnum.TRANSLATION>,
  handleDownload: (record: IListDocument) => void,
  onDelete: (record: IListDocument) => void
): ColumnTableType<IListDocument>[] => {
  return [
    {
      key: 'name',
      title: t('crm_detail_document_contacts_tab_name_title'),
      render: (_, record: IListDocument) => {
        return <>{record?.name || EMPTY_STRING}</>;
      },
      width: '50%',
    },
    {
      key: 'updatedAt',
      title: t('crm_detail_document_contacts_tab_date_title'),
      dataIndex: 'updatedAt',
      render(_, record) {
        return (
          <>{record?.updatedAt ? convertDateToFormatTime(record.updatedAt, TimeFormatEnum.MM_DD_YYYY) : EMPTY_STRING}</>
        );
      },
      width: '30%',
    },
    {
      key: 'mimetype',
      title: t('crm_detail_document_contacts_tab_type_title'),
      dataIndex: 'mimetype',
      render(_, record) {
        return <>{record?.mimetype ? formatMimeType(record?.mimetype) : EMPTY_STRING}</>;
      },
      width: '20%',
    },
    {
      key: 'action',
      title: t('common_text_action'),
      render(_, record: IListDocument) {
        return (
          <div className={cx('btnAction')}>
            <button onClick={() => handleDownload(record)}>
              <img src={icons.commonIconDownload} alt={t('common_img_text_alt')} />
            </button>
            <button onClick={() => onDelete(record)}>
              <img src={icons.commonIconTrash} alt={t('common_img_text_alt')} />
            </button>
          </div>
        );
      },
      width: '10%',
    },
  ];
};

const DocumentContactTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const { contactId } = useParams();
  //#endregion Declare Hook

  //#region Selector
  const isRefreshDocumentList = useAppSelector(selectIsRefreshFileList);
  //#endregion Selector

  //#region Declare State
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const [pagination, setPagination] = useState<IPaginationResponse>();
  const pageSelected = useMemo<number>(() => Number(params?.page ?? DEFAULT_CURRENT_PAGE), [params?.page]);
  const [listDocumentList, setListDocumentList] = useState<IListDocument[]>([]);
  const textSearchParams = useMemo<string>(() => String(params?.textSearch || EMPTY_STRING), [params?.textSearch]);
  const [searchKey, setSearchKey] = useState<string>(textSearchParams || EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey.trim() || EMPTY_STRING, DEFAULT_DELAY_TIME);
  const [selectedDocument, setSelectedDocument] = useState<IListDocument>();
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!textSearchParams) setSearchKey(EMPTY_STRING);
  }, [textSearchParams]);

  useEffect(() => {
    const { tab, ...restParams } = params;

    const payload: IListQueryParamsDocument = {
      ...restParams,
      businessId: contactId,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      businessTypes: [TypeFilesDocumentEnum.CONTACT_DOCUMENT],
    };
    loading?.show();
    handleGetListDocument(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!pagination) return;

    if (debouncedSearchKey) {
      setSearchParams({
        ...params,
        page: DEFAULT_CURRENT_PAGE.toString(),
        limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
        textSearch: debouncedSearchKey,
      });
    } else {
      const { textSearch, ...rest } = params;
      setSearchParams(rest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);

  useEffect(() => {
    if (!isRefreshDocumentList) {
      return;
    }

    const { tab, ...restParams } = params;

    const payload: IListQueryParamsDocument = {
      ...restParams,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      businessId: contactId,
      businessTypes: [TypeFilesDocumentEnum.CONTACT_DOCUMENT],
      ...(debouncedSearchKey ? { textSearch: debouncedSearchKey } : {}),
    };

    handleGetListDocument(payload);
    dispatch(fileActions.setRefreshList(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshDocumentList]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleClickUnderDevelop = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handlePaginationChange = (page: number) => {
    if (!page) return;

    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleDownload = (fileInfo: IListDocument) => {
    if (!fileInfo) return;
    loading?.show();

    dispatch(downloadDocument(fileInfo.id))
      .unwrap()
      .then((response) => {
        const data: ArrayBuffer | undefined = response.data;
        const fileExtension = fileInfo.mimetype;

        if (data && typeof data === OBJECT && Object.keys(data).length > DEFAULT_NUMBER_ZERO) {
          const array: number[] = Object.values(data);
          const uint8Array: Uint8Array = new Uint8Array(array);
          const blob: Blob = new Blob([uint8Array], { type: fileExtension });

          const url: string = window.URL.createObjectURL(blob);
          const link: HTMLAnchorElement = document.createElement(A_ELEMENT);
          link.href = url;
          link.download = fileInfo.name || EMPTY_STRING;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          window.URL.revokeObjectURL(url);
        }

        dispatch(fileActions.setRefreshList(true));
      })
      .catch((error) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const handleShowModalDelete = (data?: IListDocument) => {
    if (data) {
      setSelectedDocument(data);
    }
    setIsShowConfirmDelete(!isShowConfirmDelete);
  };

  const handleDelete = () => {
    if (selectedDocument) {
      return dispatch(deleteDocument(selectedDocument?.id))
        .unwrap()
        .then((res) => {
          dispatch(fileActions.setRefreshList(true));
          setIsShowConfirmDelete(false);
        })
        .catch((error) => {})
        .finally(() => loading?.hide());
    }
  };

  const handleGetListDocument = (params: IListQueryParamsDocument) => {
    dispatch(getListDocuments(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        setListDocumentList(res.data.responses);
        setPagination(res.data.pagination);
      })
      .catch((err) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const handleChangeRecentlyDocumentSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value);
  };
  //#endregion Handle Function

  return (
    <div id='detailClientAndProspectTabComponent' className={cx('detailClientAndProspectComponent')}>
      <ToolBar fontSize={20} title={EMPTY_STRING}>
        <InputSearch
          placeholder={t('common_placeholder_search_by_name')}
          height={36}
          onChange={handleChangeRecentlyDocumentSearch}
          value={searchKey}
        />
      </ToolBar>
      <div className={cx('content')}>
        <div className={cx('tableWrap')}>
          <BaseTable
            columns={columnsRecentlyDocument(t, handleDownload, handleShowModalDelete)}
            dataSource={listDocumentList ?? []}
          />
        </div>

        <div className={cx('paginationTable')}>
          <BasePagination
            onChange={handlePaginationChange}
            defaultCurrentPage={pageSelected}
            totalItems={pagination?.totalItems}
            totalPages={pagination?.totalPages}
          />
        </div>
      </div>

      <ConfirmModal
        isOpen={isShowConfirmDelete}
        title={t('common_confirm_delete_title', {
          name: selectedDocument?.name,
        })}
        description={t('modal_confirm_delete_description')}
        titleAction={t('common_delete_label')}
        onCancel={handleShowModalDelete}
        onAction={handleDelete}
        type='danger'
      />

      {isShowModalUnderDevelopment && <ModalUnderDevelopment onClose={handleClickUnderDevelop} />}
    </div>
  );
};

export default DocumentContactTab;
