// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import {
  CONVERT_TO_CAREGIVER,
  CONVERT_TO_CLIENT,
  CHANGE_STAGE_APPLICANT,
  CREATE_APPLICANT,
  GET_DETAIL_APPLICANT,
  EDIT_APPLICANT,
  CHANGE_STATUS_APPLICANT,
} from '~/utils/constants/actionType';
import { GET_LIST_APPLICANT } from '~/utils/constants/actionType';
import { IListQueryParams } from '~/utils/interface/common';
import applicantApi from '~/apis/caregivers/applicant';
import { IChangeStage } from '~/utils/interface/applicant';
import { IChangeStatus } from '~/utils/interface/crm/clients';

export const createApplicant = createAsyncThunk(CREATE_APPLICANT, async (body: FormData, { rejectWithValue }) => {
  try {
    const res = await applicantApi.createApplicant(body);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getListApplicant = createAsyncThunk(
  GET_LIST_APPLICANT,
  async (payload: IListQueryParams, { rejectWithValue }) => {
    try {
      const res = await applicantApi.getList(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const convertToCaregiver = createAsyncThunk(
  CONVERT_TO_CAREGIVER,
  async (applicantId: string, { rejectWithValue }) => {
    try {
      const res = await applicantApi.convertToCaregiver(applicantId);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changeStageApplicant = createAsyncThunk(
  CHANGE_STAGE_APPLICANT,
  async ({ applicantId, body }: { applicantId: string; body: IChangeStage }, { rejectWithValue }) => {
    try {
      const res = await applicantApi.changeStageApplicant({ applicantId: applicantId, body: body });
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDetailRequestApplicant = createAsyncThunk(
  GET_DETAIL_APPLICANT,
  async (applicantId: string, { rejectWithValue }) => {
    try {
      const res = await applicantApi.getDetailRequestApplicant(applicantId);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editApplicant = createAsyncThunk(
  EDIT_APPLICANT,
  async ({ applicantId, body }: { applicantId: string; body: FormData }, { rejectWithValue }) => {
    try {
      const res = await applicantApi.editRequestApplicant({ applicantId, body });
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changeStatusApplicant = createAsyncThunk(
  CHANGE_STATUS_APPLICANT,
  async ({ applicantId, body }: { applicantId: string; body: IChangeStatus }, { rejectWithValue }) => {
    try {
      const res = await applicantApi.changeStatusApplicant({ applicantId: applicantId, body: body });
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
