import axiosClient from './axiosClient';
import { urlApiDailyCare } from '~/utils/constants/api';
import { BaseResponse, IListDataResponse } from '~/utils/interface/common';
import { IFormVisitNote, IVisitNote, IGetVisitNotesParams } from '~/utils/interface/visitNote';
import { IFormMileage, IMileage } from '~/utils/interface/mileage';
import { IQueryDailyCareList, IGetTasksDailyCareParams, IShiftDailyCare } from '~/utils/interface/taskDailyCare';
import { IExpenseCredit, IFormExpenseCredit, IListQueryParamsExpenseCredit } from '~/utils/interface/expenseCredit';

const dailyCareApi = {
  createVisitNote(body: IFormVisitNote) {
    const url = `${urlApiDailyCare.createVisitNote}`;
    return axiosClient.post<BaseResponse>(url, body);
  },

  getListVisitNote(params: IGetVisitNotesParams) {
    const url = `${urlApiDailyCare.getListVisitNote}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IVisitNote[]>>>(url, { params });
  },

  createMileage(body: IFormMileage) {
    const url = `${urlApiDailyCare.createMileage}`;
    return axiosClient.post<BaseResponse>(url, body);
  },

  getMileageList(query: IQueryDailyCareList) {
    const url = `${urlApiDailyCare.getMileageList}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IMileage[]>>>(url, { params: query });
  },

  createExpenseCredits(body: IFormExpenseCredit) {
    const url = `${urlApiDailyCare.createExpenseCredits}`;
    return axiosClient.post<BaseResponse>(url, body);
  },

  getListExpenseCredits(params: IListQueryParamsExpenseCredit) {
    const url = `${urlApiDailyCare.getListExpenseCredits}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IExpenseCredit[]>>>(url, { params });
  },

  getTaskListDailyCare(params: IGetTasksDailyCareParams) {
    const url = `${urlApiDailyCare.getTaskListDailyCare}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IShiftDailyCare[]>>>(url, { params });
  },
};
export default dailyCareApi;
