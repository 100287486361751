// Libs
import classNames from 'classnames/bind';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, useLoadScript } from '@react-google-maps/api';
// Components, Layouts, Pages
import { BaseInput } from '~/components';
// Others
import {
  DEFAULT_DELAY_SELECT_AUTOCOMPLETE,
  DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { IAddress } from '~/utils/interface/common';
import { googleMapApiKey } from '~/utils/constants/env';
import { TypeAddressEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './PlacesAutocomplete.module.scss';

type Props = {
  id: string;
  label?: string;
  value?: string;
  messageError?: string;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (value: IAddress) => void;
};

const cx = classNames.bind(styles);

const PlacesAutocomplete = (props: Props) => {
  //#region Destructuring Props
  const { id, label, value, messageError, required, placeholder, onChange, disabled } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapApiKey ?? EMPTY_STRING,
    libraries: DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [autoCompleteAddress, setAutoCompleteAddress] = useState<google.maps.places.Autocomplete>();
  const [inputValue, setInputValue] = useState<string>(value || EMPTY_STRING);
  const currentValue = useRef(value);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    setInputValue(value || EMPTY_STRING);
    currentValue.current = value;
  }, [value]);
  //#endregion Implement Hook

  //#region Handle Function
  const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
    setAutoCompleteAddress(autocomplete);
  };

  const onPlaceChanged = () => {
    if (!autoCompleteAddress) return;

    const place = autoCompleteAddress?.getPlace();

    const addressComponents = place.address_components || [];
    const getAddressComponent = (type: string) =>
      addressComponents.find((component) => component.types.includes(type))?.long_name || '';

    const fullAddress = place.formatted_address || '';
    const city = getAddressComponent(TypeAddressEnum.LOCALITY);
    const state = getAddressComponent(TypeAddressEnum.STATE);
    const zipCode = getAddressComponent(TypeAddressEnum.ZIP_CODE);
    const country = getAddressComponent(TypeAddressEnum.COUNTRY);

    const location = place.geometry?.location;
    const latitude = location?.lat();
    const longitude = location?.lng();

    const updatedAddress: IAddress = {
      address: `${fullAddress} `,
      city: city,
      state: state,
      country: country,
      zipCode: zipCode,
      lat: latitude,
      lng: longitude,
    };

    setInputValue(fullAddress);
    if (onChange) {
      onChange(updatedAddress);
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);
  };

  const handleBlur = () => {
    if (!inputValue) {
      if (onChange) {
        onChange({
          address: EMPTY_STRING,
        });
      }
      return;
    }

    setTimeout(() => {
      setInputValue(currentValue.current || EMPTY_STRING);
    }, DEFAULT_DELAY_SELECT_AUTOCOMPLETE);
  };
  //#endregion Handle Function

  return (
    isLoaded && (
      <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
        <BaseInput
          id={id}
          required={required}
          label={label}
          placeholder={placeholder ?? EMPTY_STRING}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleBlur}
          messageError={messageError}
          disabled={disabled}
        />
      </Autocomplete>
    )
  );
};

export default PlacesAutocomplete;
