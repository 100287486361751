// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import { CREATE_EMAIL_TEMPLATE, EDIT_EMAIL_TEMPLATE, GET_LIST_EMAIL_TEMPLATE } from '~/utils/constants/actionType';
import {
  IFormEmailTemplate,
  IPayloadUpdateEmailTemplate,
  IQueryParamsEmailTemplate,
} from '~/utils/interface/emailTemplate';
import emailTemplateApi from '~/apis/emailTemplate';

export const getListEmailTemplate = createAsyncThunk(
  GET_LIST_EMAIL_TEMPLATE,
  async (payload: IQueryParamsEmailTemplate, { rejectWithValue }) => {
    try {
      const res = await emailTemplateApi.getListEmailTemplate(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createEmailTemplate = createAsyncThunk(
  CREATE_EMAIL_TEMPLATE,
  async (payload: IFormEmailTemplate, { rejectWithValue }) => {
    try {
      const res = await emailTemplateApi.createEmailTemplate(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editEmailTemplate = createAsyncThunk(
  EDIT_EMAIL_TEMPLATE,
  async (payload: IPayloadUpdateEmailTemplate, { rejectWithValue }) => {
    try {
      const res = await emailTemplateApi.editEmailTemplate(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
