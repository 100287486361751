// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// Components, Layouts, Pages
import { LabelValueField, StatusLabel } from '~/components';
// Others
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_LIMIT_MAX_ITEM,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { convertTime } from '~/utils/helper';
import { StatusEnum, TimeFormatEnum } from '~/utils/enum';
import { getListCaregiverTraining } from '~/thunks/training/trainingThunk';
import { IPayloadListCaregiverTraining, ITrainingCaregiver } from '~/utils/interface/caregivers/training';
import { LoadingData } from '~/context';
import { RootState } from '~/redux/store';
// Styles, images, icons
import styles from './TrainingTab.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const TrainingTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { caregiverId } = useParams();
  const loading = useContext(LoadingData);
  //#endregion Declare Hook

  //#region Selector
  const { caregiverDetail } = useAppSelector((state: RootState) => state.caregiverState);
  const { isFetchingCaregiverTrainingList } = useAppSelector((state) => state.trainingState);
  //#endregion Selector

  //#region Declare State
  const [caregiverTraining, setCaregiverTraining] = useState<ITrainingCaregiver[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetListCaregiverTraining();
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListCaregiverTraining = () => {
    if (!caregiverId) return;
    loading?.show();

    const payload: IPayloadListCaregiverTraining = {
      caregiverId,
      params: {
        page: DEFAULT_CURRENT_PAGE,
        limit: DEFAULT_LIMIT_MAX_ITEM,
      },
    };

    dispatch(getListCaregiverTraining(payload))
      .unwrap()
      .then((res) => {
        setCaregiverTraining(res.data);
      })
      .catch((err) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const handleClickImage = (url?: string) => {
    if (!url) return;

    window.open(url);
  };
  //#endregion Handle Function

  return (
    <div id='trainingTabComponent' className={cx('trainingTabComponent', 'relative')}>
      {!isFetchingCaregiverTrainingList && (
        <div className={cx('trainingTab')}>
          {caregiverTraining.length === DEFAULT_NUMBER_ZERO && !caregiverDetail?.caregiverListId ? (
            <div className={cx('errorSection')}>
              <p className={cx('warningTitle')}>{t('common_error_warning')}</p>
              <p>{t('tab_training_caregiver_error_not_currently_title')}</p>
              <p>{t('tab_training_caregiver_please_contact_office_title')}</p>
            </div>
          ) : (
            <>
              <h3 className={cx('heading')}>{t('tab_training_caregiver_certificate_label')}</h3>

              {caregiverTraining?.map((item, index) => {
                return (
                  <>
                    <div key={index} className={cx('trainingWrap')}>
                      <div className={cx('infoTraining')}>
                        <LabelValueField
                          label={t('tab_training_caregiver_enroll_date_label')}
                          value={
                            item.enroll_date ? convertTime(item.enroll_date, TimeFormatEnum.MM_DD_YYYY) : EMPTY_STRING
                          }
                        />

                        <LabelValueField
                          label={t('tab_training_caregiver_due_date_label')}
                          value={item.due_date ? convertTime(item.due_date, TimeFormatEnum.MM_DD_YYYY) : EMPTY_STRING}
                        />

                        <LabelValueField
                          label={t('tab_training_caregiver_total_section_label')}
                          value={item.total_sections}
                        />

                        <LabelValueField
                          label={t('tab_training_caregiver_completed_section_label')}
                          value={item.completed_sections}
                        />

                        <LabelValueField
                          label={t('tab_training_caregiver_passed_label')}
                          value={
                            item.passed ? (
                              <StatusLabel label={StatusEnum.PASSED} type='text' />
                            ) : (
                              <StatusLabel label={StatusEnum.NOT_PASSED} type='text' />
                            )
                          }
                        />
                      </div>

                      {item.certificate_image_url && (
                        <div
                          className={cx('certification')}
                          onClick={() => handleClickImage(item.certificate_image_url)}
                        >
                          <img
                            className={cx('certificationImg')}
                            src={item.certificate_image_url}
                            alt={t('common_img_text_alt')}
                            loading='lazy'
                          />
                        </div>
                      )}
                    </div>

                    {index !== caregiverTraining.length - 1 && <div className={cx('line')}></div>}
                  </>
                );
              })}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default TrainingTab;
