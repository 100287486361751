// Libs
import classNames from 'classnames/bind';
import { TFunction } from 'i18next';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BaseTable } from '~/components';
// Others
import { ColumnTableType } from '~/utils/interface/common';
import { TimeFormatEnum, TranslationEnum } from '~/utils/enum';
import { convertDateToFormatTime, getUserName } from '~/utils/helper';
import { IQueryDailyCareList } from '~/utils/interface/taskDailyCare';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { getMileageList } from '~/thunks/dailyCare/dailyCareThunk';
import { RootState } from '~/redux/store';
import { IMileage } from '~/utils/interface/mileage';
import { DEFAULT_CURRENT_PAGE, DEFAULT_LIMIT_MAX_ITEM, EMPTY_STRING } from '~/utils/constants/common';
// Styles, images, icons
import styles from './MileageTableDailyCare.module.scss';

type Props = {
  businessType: string;
  businessId?: string;
  date: string;
};

const cx = classNames.bind(styles);

const columns = (t: TFunction<TranslationEnum.TRANSLATION>): ColumnTableType<IMileage>[] => {
  return [
    {
      key: 'date',
      title: t('caregiver_profile_daily_care_table_label_date'),
      dataIndex: 'date',
      render(value, record, index) {
        return <>{record?.date ? convertDateToFormatTime(record.date, TimeFormatEnum.MM_DD_YYYY) : EMPTY_STRING}</>;
      },
      width: '30%',
    },
    {
      key: 'miles',
      title: t('caregiver_profile_daily_care_table_label_miles'),
      dataIndex: 'miles',
      render(value, record, index) {
        return <>{record?.miles || EMPTY_STRING}</>;
      },
      width: '30%',
    },
    {
      key: 'client',
      title: t('caregiver_profile_daily_care_table_label_client'),
      dataIndex: 'client',
      render(value, record, index) {
        return <>{getUserName(record?.client?.firstName, record?.client?.lastName)}</>;
      },
      width: '20%',
    },
    {
      key: 'caregiver',
      title: t('caregiver_profile_daily_care_table_label_caregiver'),
      dataIndex: 'caregiver',
      render(value, record, index) {
        return <>{getUserName(record?.caregiver?.firstName, record?.caregiver?.lastName)}</>;
      },
      width: '20%',
    },
  ];
};

const MileageTableDailyCare = (props: Props) => {
  //#region Destructuring Props
  const { businessType, businessId, date } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  const { isRefreshMileage } = useAppSelector((state: RootState) => state.dailyCareState);
  //#endregion Selector

  //#region Declare State
  const [isExpandTable, setIsExpandTable] = useState<boolean>(false);
  const [mileageList, setMileageList] = useState<IMileage[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetMileageList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    if (!isRefreshMileage) return;

    handleGetMileageList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshMileage]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetMileageList = () => {
    if (!businessId) return;

    const queryParams: IQueryDailyCareList = {
      businessId: businessId,
      businessType: businessType,
      date: date,
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
    };

    dispatch(getMileageList(queryParams))
      .unwrap()
      .then((res) => {
        const { responses } = res.data;

        if (!responses) return;

        setMileageList(responses);
      })
      .catch((err) => {})
      .finally(() => {});
  };

  const handleExpandTable = () => {
    setIsExpandTable(!isExpandTable);
  };
  //#endregion Handle Function

  return (
    <div id='mileageTableDailyCareComponent' className={cx('container')}>
      <div className={cx('bodyWrap')}>
        <div className={cx('bodyTable')}>
          <BaseTable
            tableExpand
            title={t('caregiver_profile_daily_care_mileage_title')}
            columns={columns(t)}
            dataSource={mileageList}
            isExpand={isExpandTable}
            handleExpandTable={handleExpandTable}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(MileageTableDailyCare);
