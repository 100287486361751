// Libs
import { createSlice } from '@reduxjs/toolkit';

// Others
import {
  changeStatusUnavailability,
  createUnavailabilityEvent,
  getDetailUnavailability,
  getListHr,
  deleteUnavailability,
} from './hrThunk';

export interface hrState {
  isRefreshHrList: boolean;
}

const initialState: hrState = {
  isRefreshHrList: false,
};

const hrSlice = createSlice({
  name: 'hrState',
  initialState,
  reducers: {},
  extraReducers(builder) {
    // Get list hr
    builder
      .addCase(getListHr.pending, (state) => {})
      .addCase(getListHr.fulfilled, (state) => {
        state.isRefreshHrList = false;
      })
      .addCase(getListHr.rejected, (state) => {
        state.isRefreshHrList = false;
      });

    // Change Status Unavailability
    builder
      .addCase(changeStatusUnavailability.pending, (state) => {})
      .addCase(changeStatusUnavailability.fulfilled, (state) => {
        state.isRefreshHrList = true;
      })
      .addCase(changeStatusUnavailability.rejected, (state) => {});

    // Create Unavailability Event
    builder
      .addCase(createUnavailabilityEvent.pending, (state) => {})
      .addCase(createUnavailabilityEvent.fulfilled, (state) => {
        state.isRefreshHrList = true;
      })
      .addCase(createUnavailabilityEvent.rejected, (state) => {});

    // Delete Unavailability
    builder
      .addCase(deleteUnavailability.pending, (state) => {})
      .addCase(deleteUnavailability.fulfilled, (state) => {
        state.isRefreshHrList = true;
      })
      .addCase(deleteUnavailability.rejected, (state) => {});

    // Get Detail Unavailability
    builder
      .addCase(getDetailUnavailability.pending, (state) => {})
      .addCase(getDetailUnavailability.fulfilled, (state) => {})
      .addCase(getDetailUnavailability.rejected, (state) => {});
  },
});

// Actions
export const hrActions = hrSlice.actions;

// Reducer
const hrReducer = hrSlice.reducer;
export default hrReducer;
