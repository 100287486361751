// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
// Components, Layouts, Pages
import { ItemHomeSafetyAssessment } from '~/components';
// Others
import { DEFAULT_NUMBER_ONE, DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { IHomeSafetyAssessment, IParamsHomeSafetyAssessment } from '~/utils/interface/homeSafetyAssessment';
import { getListHomeSafetyAssessment } from '~/thunks/homeSafetyAssessment/homeSafetyAssessmentThunk';
// Styles, images, icons
import styles from './HomeSafetyAssessmentTab.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const HomeSafetyAssessmentTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const { clientId } = useParams();
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  const [assessmentTabSelected, setAssessmentTabSelected] = useState<IHomeSafetyAssessment>();
  const [homeSafetyAssessment, setHomeSafetyAssessment] = useState<IHomeSafetyAssessment[]>([]);
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  const assessmentSelected: IHomeSafetyAssessment = useMemo(() => {
    return assessmentTabSelected ?? homeSafetyAssessment[DEFAULT_NUMBER_ZERO];
  }, [assessmentTabSelected, homeSafetyAssessment]);

  useEffect(() => {
    if (!clientId) return;
    loading?.show();
    handleGetHomeSafetyAssessment(clientId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetHomeSafetyAssessment = (clientId: string) => {
    const params: IParamsHomeSafetyAssessment = {
      clientId,
    };
    dispatch(getListHomeSafetyAssessment(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        setHomeSafetyAssessment(res.data);
      })
      .catch((err) => {})
      .finally(() => loading?.hide());
  };
  //#endregion Handle Function

  return (
    <div id='homeSafetyAssessmentTabComponent' className={cx('container')}>
      {homeSafetyAssessment && homeSafetyAssessment.length > DEFAULT_NUMBER_ZERO ? (
        <div className={cx('homeSafetyAssessmentWrap')}>
          <div className={cx('assessmentMenuGroup')}>
            <div className={cx('assessmentTab')}>
              <div className={cx('assessmentMenuContainer')}>
                {homeSafetyAssessment.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className={cx(
                        'assessmentTextMenu',
                        assessmentSelected?.id === item.id && 'assessmentTextMenuSelected'
                      )}
                      onClick={() => setAssessmentTabSelected(item)}
                    >
                      {t(item.name || EMPTY_STRING)}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className={cx('assessmentContent')}>
            {assessmentSelected?.items.map((item, index) => {
              return (
                <ItemHomeSafetyAssessment
                  item={item}
                  index={index}
                  isLastItem={index === assessmentSelected?.items.length - DEFAULT_NUMBER_ONE}
                  onSuccess={handleGetHomeSafetyAssessment}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <div className={cx('noDataAvailable')}>{t('common_empty_data')}</div>
      )}
    </div>
  );
};

export default HomeSafetyAssessmentTab;
