// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// Components, Layouts, Pages
import { FormInitialCall } from '~/components';
// Others
import { NAVIGATE_BACK } from '~/utils/constants/common';
// Styles, images, icons
import styles from './FormInitialCall.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const FormInitialCallPage = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleBackToPreviousPage = () => {
    navigate(NAVIGATE_BACK);
  };
  //#endregion Handle Function

  return (
    <div id='formInitialCallPage' className={cx('container')}>
      <button className={cx('backTag')} onClick={handleBackToPreviousPage}>
        <img src={icons.commonIconPrev} alt={t('common_img_text_alt')} />
        <span className={cx('textBack')}>{t('common_text_back')}</span>
      </button>

      <div className={cx('header')}>
        <div className={cx('title')}>{t('form_initial_call_add_title')}</div>
      </div>

      <div className={cx('body')}>
        <FormInitialCall />
      </div>
    </div>
  );
};

export default FormInitialCallPage;
