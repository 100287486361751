// Libs
import classNames from 'classnames/bind';
import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { Loading } from '~/components';
// Others
import { DEFAULT_CURRENT_PAGE, DEFAULT_NUMBER_ZERO } from '~/utils/constants/common';
// Styles, images, icons
import styles from './InfinityScroll.module.scss';

type Children<T> = {
  data: T[];
};

type Props<T> = {
  fetchData: (page: number) => Promise<{ data: T[]; hasMore: boolean }>;
  children: (children: Children<T>) => React.ReactNode;
  loader?: React.ReactNode;
};

const cx = classNames.bind(styles);

const InfinityScroll = <T,>(props: Props<T>) => {
  //#region Destructuring Props
  const { fetchData, children, loader } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [data, setData] = useState<T[]>([]);
  const [page, setPage] = useState<number>(DEFAULT_CURRENT_PAGE);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  const loadData = useCallback(
    async (reset: boolean = false) => {
      try {
        reset && setIsLoading(true);
        const currentPage = reset ? DEFAULT_CURRENT_PAGE : page;
        const response = await fetchData(currentPage);

        setData((prev) => (reset ? response.data : [...prev, ...response.data]));
        setHasMore(response.hasMore);
        setPage(reset ? 2 : page + 1);
      } catch (error) {
      } finally {
        reset && setIsLoading(false);
      }
    },
    [fetchData, page]
  );

  useEffect(() => {
    loadData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='infinityScroll' className={cx('infinityScrollComponent')}>
      {data && data.length > DEFAULT_NUMBER_ZERO ? (
        <InfiniteScroll
          dataLength={data?.length}
          hasMore={hasMore}
          next={() => loadData(false)}
          loader={loader}
          height={'100%'}
          scrollableTarget='infinityScroll'
        >
          {children({ data: data })}
        </InfiniteScroll>
      ) : (
        <div className={cx('textNoData')}>{t('common_empty_data')}</div>
      )}

      {isLoading && <Loading />}
    </div>
  );
};

export default React.memo(InfinityScroll) as <T>(props: Props<T>) => JSX.Element;
