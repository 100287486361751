import { urlApiSchedule } from '~/utils/constants/api';
import axiosClient from './axiosClient';
import { BaseResponse, IListDataResponse } from '~/utils/interface/common';
import {
  IDetailSchedule,
  IPayloadEditSchedule,
  IParamsGetScheduleClient,
  IScheduleEvent,
  IParamGetOfficeSchedule,
  IOfficeSchedule,
  IGetSchedulesLiveViewParams,
  IScheduleLiveView,
  ITabNotes,
  IParamsGetNotes,
  ICreateShiftNote,
  IParamsGetScheduleCaregiver,
  IScheduleCaregiver,
  IScheduleClient,
  IParamsGetShiftScheduleClient,
  IDetailUnavailability,
  ICreateUnavailabilityNote,
  IParamsGetOpenShifts,
  IEventSchedule,
  IFormOfficeCalendar,
  IPayloadUpdateOfficeCalendar,
  IOfficeCalendarDetail,
  IOfficeCalendarDetailEvent,
} from '~/utils/interface/schedule';

const scheduleApi = {
  deleteSchedule(scheduleId: string) {
    const url = `${urlApiSchedule.deleteSchedule(scheduleId)}`;
    return axiosClient.delete<BaseResponse>(url);
  },

  getDetailSchedule(scheduleId: string) {
    const url = `${urlApiSchedule.getDetailSchedule(scheduleId)}`;
    return axiosClient.get<BaseResponse<IDetailSchedule>>(url);
  },

  updateSchedule({ scheduleId, body }: IPayloadEditSchedule) {
    const url = `${urlApiSchedule.updateSchedule(scheduleId)}`;
    return axiosClient.put<BaseResponse>(url, body);
  },

  getScheduleClient(params: IParamsGetScheduleClient) {
    const url = `${urlApiSchedule.getScheduleClient}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IScheduleEvent[]>>>(url, { params });
  },

  getOfficeSchedule(params: IParamGetOfficeSchedule) {
    const url = `${urlApiSchedule.getOfficeSchedule}`;
    return axiosClient.get<BaseResponse<IOfficeSchedule[]>>(url, { params });
  },

  getSchedulesLiveView(params: IGetSchedulesLiveViewParams) {
    const url = `${urlApiSchedule.getSchedulesLiveView}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IScheduleLiveView[]>>>(url, { params });
  },

  getListNoteSchedule(params: IParamsGetNotes) {
    const url = `${urlApiSchedule.getListNoteSchedule}`;
    return axiosClient.get<BaseResponse<IListDataResponse<ITabNotes[]>>>(url, { params });
  },

  createShiftNoteSchedule(body: ICreateShiftNote) {
    const url = `${urlApiSchedule.createShiftNote}`;
    return axiosClient.post<BaseResponse>(url, body);
  },

  getScheduleCaregiver(params: IParamsGetScheduleCaregiver) {
    const url = `${urlApiSchedule.getScheduleCaregiver}`;
    return axiosClient.get<BaseResponse<IScheduleCaregiver[]>>(url, { params });
  },

  getShiftScheduleClient(params: IParamsGetShiftScheduleClient) {
    const url = `${urlApiSchedule.getScheduleClient}`;
    return axiosClient.get<BaseResponse<IScheduleClient[]>>(url, { params });
  },

  getDetailUnavailability(id: string) {
    const url = `${urlApiSchedule.getDetailUnavailability(id)}`;
    return axiosClient.get<BaseResponse<IDetailUnavailability>>(url);
  },

  createUnavailabilityNote(body: ICreateUnavailabilityNote) {
    const url = `${urlApiSchedule.createUnavailabilityNote}`;
    return axiosClient.post<BaseResponse>(url, body);
  },

  getOpenShifts(params: IParamsGetOpenShifts) {
    const url = `${urlApiSchedule.getOpenShifts}`;
    return axiosClient.get<BaseResponse<IEventSchedule[]>>(url, { params });
  },

  createOfficeCalendar(body: IFormOfficeCalendar) {
    const url = `${urlApiSchedule.createOfficeCalendar}`;
    return axiosClient.post<BaseResponse>(url, body);
  },

  getDetailOfficeCalendar(scheduleId: string) {
    const url = `${urlApiSchedule.getOfficeCalendarDetail(scheduleId)}`;
    return axiosClient.get<BaseResponse<IOfficeCalendarDetail>>(url);
  },

  updateOfficeCalendar({ scheduleId, body }: IPayloadUpdateOfficeCalendar) {
    const url = `${urlApiSchedule.updateOfficeCalendar(scheduleId)}`;
    return axiosClient.put<BaseResponse>(url, body);
  },

  deleteOfficeCalendar(scheduleId: string) {
    const url = `${urlApiSchedule.deleteOfficeCalendar(scheduleId)}`;
    return axiosClient.delete<BaseResponse>(url);
  },

  getDetailOfficeCalendarEvent(scheduleId: string) {
    const url = `${urlApiSchedule.getDetailOfficeCalendar(scheduleId)}`;
    return axiosClient.get<BaseResponse<IOfficeCalendarDetailEvent>>(url);
  },
};

export default scheduleApi;
