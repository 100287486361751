// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
// Components, Layouts, Pages
import { LineChart } from '~/components';
// Others
import { IStatisticCard } from '~/utils/interface/dashboard';
import { DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
import { convertDateToDayOfWeek, formatCount } from '~/utils/helper';
import { GREEN_PEA700 } from '~/utils/constants/color';
// Styles, images, icons
import styles from './StatisticCard.module.scss';
import { icons } from '~/assets';

type Props = {
  title: string;
  // data: IStatisticCard;
  data: number;
};

const cx = classNames.bind(styles);

const StatisticCard = (props: Props) => {
  //#region Destructuring Props
  const { title, data } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  // const { lineChartData, categories } = useMemo(() => {
  //   if (data.chart && Array.isArray(data.chart)) {
  //     const chartData = data.chart.map((chartItem) => chartItem.total || 0);
  //     const chartCategories = data.chart.map((chartItem) => convertDateToDayOfWeek(chartItem.date));

  //     return {
  //       lineChartData: [{ data: chartData }],
  //       categories: chartCategories,
  //     };
  //   }

  //   return { lineChartData: [], categories: [] };
  // }, [data.chart]);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='statisticCardComponent' className={cx('statisticCardComponent')}>
      <div className={cx('headerStatisticCard')}>
        <div className={cx('headerRight')}>
          <h1 className={cx('titleStatisticCard')}>{title || EMPTY_STRING}</h1>
          <p className={cx('quantityStatisticCard')}>{data}</p>
          {/* <p className={cx('quantityStatisticCard')}>{data.total ? formatCount(data.total) : EMPTY_STRING}</p> */}
        </div>
        {/* <div className={cx('lineChart')}>
          {Object.keys(data).length > DEFAULT_NUMBER_ZERO && (
            // <LineChart data={lineChartData} categories={categories} colors={[GREEN_PEA700]} />
            <LineChart data={[]} categories={categories} colors={[GREEN_PEA700]} />
          )}
        </div> */}
      </div>
    </div>
  );
};

export default StatisticCard;
