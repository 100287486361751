// Libs
import classNames from 'classnames/bind';
import { Controller, useFormContext, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { memo } from 'react';
// Components, Layouts, Pages
import { BaseInput, BaseSelect, BaseTextarea } from '~/components';
// Others
import { IFormSkill } from '~/utils/interface/caregivers/skill';
import { EMPTY_STRING } from '~/utils/constants/common';
import { formatPhoneNumber } from '~/utils/helper';

// Styles, images, icons
import styles from './EmergencyContactsSkill.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const EmergencyContactsSkill = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IFormSkill>();

  const {
    fields: emergencyContactsFields,
    append: appendEmergencyContact,
    remove: removeEmergencyContact,
  } = useFieldArray({
    control,
    name: 'emergencyContactsSkill',
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleAddEmergencyContactsField = () => {
    appendEmergencyContact({
      name: EMPTY_STRING,
      relationship: EMPTY_STRING,
      phoneNumber: EMPTY_STRING,
      notes: EMPTY_STRING,
    });
  };
  //#endregion Handle Function

  return (
    <div id='emergencyContactsSkillComponent' className={cx('emergencyContactsSkillContainer')}>
      <div className={cx('headTitle')}>{t('form_skill_emergency_contacts_title')}</div>

      <div className={cx('bodyWrap')}>
        {emergencyContactsFields.map((emergencyContactsItem, index) => {
          return (
            <div className={cx('fiveCol')} key={emergencyContactsItem.id}>
              <Controller
                control={control}
                name={`emergencyContactsSkill.${index}.name`}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id={`emergencyContactsSkill.${index}.name`}
                    label={t('form_skill_emergency_name_title')}
                    value={value || EMPTY_STRING}
                    onChange={onChange}
                  />
                )}
              />

              <Controller
                control={control}
                name={`emergencyContactsSkill.${index}.relationship`}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id={`emergencyContactsSkill.${index}.relationship`}
                    label={t('form_skill_emergency_relationship_title')}
                    value={value || EMPTY_STRING}
                    onChange={onChange}
                  />
                )}
              />

              <Controller
                control={control}
                name={`emergencyContactsSkill.${index}.phoneNumber`}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id={`emergencyContactsSkill.${index}.phoneNumber`}
                    label={t('form_skill_emergency_phone_number_title')}
                    value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                    onChange={(event) => {
                      const { value } = event.target;
                      const formattedValue = formatPhoneNumber(value);
                      onChange(formattedValue);
                    }}
                  />
                )}
              />

              <div className={cx('noteSection')}>
                <div className={cx('noteField')}>
                  <Controller
                    control={control}
                    name={`emergencyContactsSkill.${index}.notes`}
                    render={({ field: { value, onChange } }) => (
                      <BaseTextarea
                        id={`emergencyContactsSkill.${index}.notes`}
                        label={t('form_skill_emergency_notes_title')}
                        value={value || EMPTY_STRING}
                        height={34}
                        onChange={onChange}
                      />
                    )}
                  />
                </div>

                <button type='button' onClick={() => removeEmergencyContact(index)} className={cx('btnRemoveField')}>
                  <img src={icons.commonIconRemoveField} alt={t('common_img_text_alt')} />
                </button>
              </div>
            </div>
          );
        })}

        <button type='button' onClick={handleAddEmergencyContactsField} className={cx('btnAddField')}>
          <img src={icons.commonIconAddField} alt={t('common_img_text_alt')} />
          <span className={cx('btnTextAdd')}>{t('form_skill_emergency_contacts_add_emergency_contacts_button')}</span>
        </button>
      </div>
    </div>
  );
};

export default memo(EmergencyContactsSkill);
