// Libs
import { TFunction } from 'i18next';
import * as yup from 'yup';
import { RegExp } from '~/utils/constants/common';
// Others
import { ContactFormEnum, ReferralRoleEnum } from '~/utils/enum';
import { IFormContact } from '~/utils/interface/crm/contact';

export const contactSchema = (t: TFunction) => {
  return yup
    .object()
    .shape({
      addType: yup.string().optional(),

      id: yup
        .string()
        .optional()
        .when('addType', {
          is: (value: string) => value === ContactFormEnum.SELECT_EXISTING,
          then: (schema) => schema.required(t('common_message_required_error')),
          otherwise: (schema) => schema.optional(),
        }),

      type: yup.string().optional(),

      firstName: yup
        .string()
        .optional()
        .when(['addType', 'type'], {
          is: (addType: string, type: string) =>
            addType === ContactFormEnum.ADD_NEW && type === ReferralRoleEnum.INDIVIDUAL,
          then: (schema) => schema.required(t('common_message_required_error')),
          otherwise: (schema) => schema.optional(),
        }),

      lastName: yup
        .string()
        .optional()
        .when(['addType', 'type'], {
          is: (addType: string, type: string) =>
            addType === ContactFormEnum.ADD_NEW && type === ReferralRoleEnum.INDIVIDUAL,
          then: (schema) => schema.required(t('common_message_required_error')),
          otherwise: (schema) => schema.optional(),
        }),

      organization: yup
        .string()
        .optional()
        .when(['addType', 'type'], {
          is: (addType: string, type: string) =>
            addType === ContactFormEnum.ADD_NEW && type === ReferralRoleEnum.ORGANIZATION,
          then: (schema) => schema.required(t('common_message_required_error')),
          otherwise: (schema) => schema.optional(),
        }),

      email: yup
        .string()
        .trim()
        .optional()
        .test('isValidEmail', t('common_validate_invalid_email'), (value) => {
          if (value && !RegExp.EMAIL.test(value)) return false;
          return true;
        }),

      clients: yup
        .array()
        .of(
          yup.object().shape({
            relationship: yup.string().required(t('common_message_required_error')),
            id: yup.string().required(t('common_message_required_error')),
          })
        )
        .optional(),
    })
    .required();
};

export const initialValues: IFormContact = {
  addType: ContactFormEnum.ADD_NEW,
  type: ReferralRoleEnum.INDIVIDUAL,
  clients: [],
};
