// Libs
import classNames from 'classnames/bind';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { memo } from 'react';
// Components, Layouts, Pages
// Others
import { useAppDispatch } from '~/redux/hooks';
import { IFormSkill } from '~/utils/interface/caregivers/skill';
// Styles, images, icons
import styles from './DisclaimersSignatures.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const DisclaimersSignatures = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    control,
    setValue,
    resetField,
    getValues,
    formState: { errors },
  } = useFormContext<IFormSkill>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function

  //#endregion Handle Function

  return (
    <div id='disclaimersSignaturesComponent' className={cx('disclaimersSignaturesContainer')}>
      <div className={cx('headTitle')}>{t('form_skill_disclaimers_signatures_title')}</div>
    </div>
  );
};

export default memo(DisclaimersSignatures);
