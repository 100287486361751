import { urlApiTimeClock } from '~/utils/constants/api';
import { BaseResponse } from '~/utils/interface/common';
import axiosClient from '~/apis/axiosClient';
import {
  ICreateTimeClockBody,
  IDataEditTimeClock,
  IDetailTimeClock,
  IChangeStatusTimeClockBody,
  IQueryListTimeClock,
  ITimeClockResp,
} from '~/utils/interface/timeClock';

const timeClockApi = {
  getListTimeClock(params: IQueryListTimeClock) {
    const url = `${urlApiTimeClock.getListTimeClock}`;
    return axiosClient.get<BaseResponse<ITimeClockResp>>(url, { params });
  },

  createTimeClock(body: ICreateTimeClockBody) {
    const url = `${urlApiTimeClock.createTimeClock}`;
    return axiosClient.post<BaseResponse>(url, body);
  },

  getDetailTimeClock(timeClockId: string) {
    const url = `${urlApiTimeClock.detail(timeClockId)}`;
    return axiosClient.get<BaseResponse<IDetailTimeClock>>(url);
  },

  updateTimeClock(data: IDataEditTimeClock) {
    const url = `${urlApiTimeClock.update(data.timeClockId)}`;
    return axiosClient.put<BaseResponse>(url, data.body);
  },

  deleteTimeClock(timeClockId: string) {
    const url = `${urlApiTimeClock.deleteTimeClock(timeClockId)}`;
    return axiosClient.delete<BaseResponse>(url);
  },

  changeStatusTimeClock(data: IChangeStatusTimeClockBody) {
    const url = `${urlApiTimeClock.changeStatus(data.timeClockId)}`;
    return axiosClient.put<BaseResponse>(url, data.body);
  },
};

export default timeClockApi;
