// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { RootState } from '~/redux/store';
import { createStage, getListStage, updateStage } from './stageThunk';

export interface StageState {
  isRefreshStageList: boolean;
}

const initialState: StageState = {
  isRefreshStageList: false,
};

const stageSlice = createSlice({
  name: 'stageState',
  initialState,
  reducers: {
    setRefreshList(state, action) {
      state.isRefreshStageList = action.payload;
    },
  },
  extraReducers(builder) {
    // Get list stage
    builder
      .addCase(getListStage.pending, (state) => {})
      .addCase(getListStage.fulfilled, (state) => {})
      .addCase(getListStage.rejected, (state) => {});

    // Create stage
    builder
      .addCase(createStage.pending, (state) => {})
      .addCase(createStage.fulfilled, (state) => {})
      .addCase(createStage.rejected, (state) => {});

    // Update Stage
    builder
      .addCase(updateStage.pending, (state) => {})
      .addCase(updateStage.fulfilled, (state) => {})
      .addCase(updateStage.rejected, (state) => {});
  },
});

// Actions
export const stageActions = stageSlice.actions;

// Selectors
export const selectIsRefreshStageList = (state: RootState) => state.stageState.isRefreshStageList;

// Reducer
const stageReducer = stageSlice.reducer;
export default stageReducer;
