import { urlApiInitialCall } from '~/utils/constants/api';
import axiosClient from '../axiosClient';
import { BaseResponse, IListDataResponse } from '~/utils/interface/common';
import {
  IInitialCall,
  IListQueryParamsInitialCall,
  IFormInitialCall,
  IInitialCallDetail,
} from '~/utils/interface/crm/initialCall';

const initialCallApi = {
  getListInitialCall(params: IListQueryParamsInitialCall) {
    const url = `${urlApiInitialCall.getListInitialCall}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IInitialCall[]>>>(url, { params });
  },

  createInitialCall(body: IFormInitialCall) {
    const url = `${urlApiInitialCall.create}`;
    return axiosClient.post<BaseResponse>(url, body);
  },

  getDetailInitialCall(id: string) {
    const url = `${urlApiInitialCall.getDetailInitialCall(id)}`;
    return axiosClient.get<BaseResponse<IInitialCallDetail>>(url);
  },
};

export default initialCallApi;
