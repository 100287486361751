// Libs
import classNames from 'classnames/bind';
import { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// Components, Layouts, Pages
import { BaseInput } from '~/components';

// Others
import { IFormBillingPolicy } from '~/utils/interface/rate';
import { useAppSelector } from '~/redux/hooks';
import { getUserName } from '~/utils/helper';
import { DOLLAR, EMPTY_STRING } from '~/utils/constants/common';
import { InputTypeEnum } from '~/utils/enum';

// Styles, images, icons
import styles from './CareManagementBillingPolicy.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const CareManagementBillingPolicy = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IFormBillingPolicy>();
  //#endregion Declare Hook

  //#region Selector
  const { clientDetail } = useAppSelector((state) => state.clientState);
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='careManagementBillingPolicyComponent' className={cx('careManagementBillingPolicyComponent')}>
      <div className={cx('content')}>
        <div className={cx('groupItem')}>
          <h1 className={cx('title')}>
            {t('billing_policy_care_management_title', {
              userName: getUserName(clientDetail?.firstName, clientDetail?.lastName),
            })}
          </h1>

          <div className={cx('groupItem')}>
            <span className={cx('contentValue')}>{t('billing_policy_care_management_enter_the_default_title')}</span>

            <div className={cx('groupCol')}>
              <span>{DOLLAR}</span>
              <Controller
                name='careManagement.rate'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='careManagement.rate'
                    value={value || EMPTY_STRING}
                    width={300}
                    type={InputTypeEnum.NUMBER}
                    onChange={onChange}
                    textAlign='right'
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(CareManagementBillingPolicy);
