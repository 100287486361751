// Libs
import classNames from 'classnames/bind';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TFunction } from 'i18next';
import { useState, ChangeEvent, useMemo, useEffect, useContext } from 'react';

// Components, Layouts, Pages
import { BaseButton, BaseInput, BaseSelect, BaseUploadImage, PlacesAutocomplete, RadioCrm } from '~/components';

// Others
import { LoadingData } from '~/context';
import {
  AccountRoleCodesEnum,
  ButtonTypeEnum,
  KeyFormRequestReferralEnum,
  ReferralRoleEnum,
  StatusEnum,
} from '~/utils/enum';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_LIMIT_MAX_ITEM,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  MAX_FILE_SIZE_FILE_20MB,
  MODE_SEARCH,
  NAVIGATE_BACK,
  RegExp,
  optionsReferralType,
} from '~/utils/constants/common';
import { IAddress, IBaseOption } from '~/utils/interface/common';
import { compareDataUpdate, createFormData, formatPhoneNumber, getUserName, removeEmptyObjects } from '~/utils/helper';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import {
  IFormCreateCaregiverReferral,
  IFormUpdateCaregiverReferral,
  IQueryCaregiverReferral,
} from '~/utils/interface/caregivers/referral';
import { getListUserByRoles } from '~/thunks/user/userThunk';
import { IGetUsersParams } from '~/utils/interface/user';
import {
  createCaregiverReferral,
  getDetailReferrals,
  getListCaregiverReferral,
  updateCaregiverReferral,
} from '~/thunks/caregivers/referral/referralThunk';

// Styles, images, icons
import styles from './CaregiverReferralForm.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup.object({
    profileImage: yup
      .array()
      .of(
        yup.object({
          file: yup
            .mixed<File>()
            .optional()
            .test('fileSize', t('caregiver_referral_form_files_size_limit'), (value) => {
              if (!value) return true;
              return value instanceof File && value.size <= MAX_FILE_SIZE_FILE_20MB;
            }),
        })
      )
      .optional(),
    firstName: yup
      .string()
      .optional()
      .when('role', {
        is: (value: string) => value === ReferralRoleEnum.INDIVIDUAL,
        then: (schema) => schema.required(t('common_message_required_error')),
        otherwise: (schema) => schema.optional(),
      }),
    lastName: yup
      .string()
      .optional()
      .when('role', {
        is: (value: string) => value === ReferralRoleEnum.INDIVIDUAL,
        then: (schema) => schema.required(t('common_message_required_error')),
        otherwise: (schema) => schema.optional(),
      }),
    organizationName: yup
      .string()
      .optional()
      .when('role', {
        is: (value: string) => value === ReferralRoleEnum.ORGANIZATION,
        then: (schema) => schema.required(t('common_message_required_error')),
        otherwise: (schema) => schema.optional(),
      }),
    address: yup.string().required(t('common_message_required_error')),
    city: yup.string().required(t('common_message_required_error')),
    state: yup.string().required(t('common_message_required_error')),
    postalCode: yup.string().required(t('common_message_required_error')),
    mobilePhone: yup.string().required(t('common_message_required_error')),
    email: yup
      .string()
      .trim()
      .optional()
      .test('isValidEmail', t('common_validate_invalid_email'), (value) => {
        if (value && !RegExp.EMAIL.test(value)) return false;
        return true;
      }),
    secondaryAddress: yup.string().trim().optional(),
    middleName: yup.string().trim().optional(),
    homePhone: yup.string().trim().optional(),
    workPhone: yup.string().trim().optional(),
    faxNumber: yup.string().trim().optional(),
    webAddress: yup.string().trim().optional(),
    ownerId: yup.string().trim().optional(),
    referralType: yup.string().trim().required(t('common_message_required_error')),
    role: yup.string().trim().optional(),
    lat: yup.number().optional(),
    lng: yup.number().optional(),
    organizationId: yup.string().optional(),
  });
};

const CaregiverReferralForm = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loadingContext = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const defaultValues: IFormCreateCaregiverReferral = useMemo(() => {
    return {
      profileImage: [],
      firstName: EMPTY_STRING,
      middleName: EMPTY_STRING,
      lastName: EMPTY_STRING,
      address: EMPTY_STRING,
      secondaryAddress: EMPTY_STRING,
      city: EMPTY_STRING,
      state: EMPTY_STRING,
      postalCode: EMPTY_STRING,
      mobilePhone: EMPTY_STRING,
      homePhone: EMPTY_STRING,
      workPhone: EMPTY_STRING,
      email: EMPTY_STRING,
      faxNumber: EMPTY_STRING,
      webAddress: EMPTY_STRING,
      role: EMPTY_STRING,
      ownerId: EMPTY_STRING,
      organizationName: EMPTY_STRING,
      organizationId: EMPTY_STRING,
      referralType: EMPTY_STRING,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors, isDirty },
  } = useForm<IFormCreateCaregiverReferral>({
    resolver: yupResolver(schema(t)),
    defaultValues: defaultValues,
  });
  const { caregiverReferralId } = useParams();
  //#endregion Declare Hook

  //#region Selector
  const { isFetchingDetailReferral } = useAppSelector((state) => state.caregiverReferralState);
  //#endregion Selector

  // //#region Declare State
  const [selectedCheckbox, setSelectedCheckbox] = useState<string>(ReferralRoleEnum.INDIVIDUAL);
  const [organizationList, setOrganizationList] = useState<IBaseOption[]>([]);
  const [ownerList, setOwnerList] = useState<IBaseOption[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const dataForm = getValues();

    const newDataForm: IFormCreateCaregiverReferral = {
      ...dataForm,
      role: selectedCheckbox,
    };

    reset(newDataForm);
  }, [selectedCheckbox]);

  useEffect(() => {
    handleGetUsersByRoles();
    handleGetListReferral();
    handleGetDetailReferral();
  }, []);

  useEffect(() => {
    if (selectedCheckbox === ReferralRoleEnum.INDIVIDUAL) {
      setValue('organizationName', EMPTY_STRING, { shouldDirty: true });
    } else {
      setValue('firstName', EMPTY_STRING, { shouldDirty: true });
      setValue('lastName', EMPTY_STRING, { shouldDirty: true });
      setValue('middleName', EMPTY_STRING, { shouldDirty: true });
      setValue('profileImage', [], { shouldDirty: true });
      setValue('organizationId', EMPTY_STRING, { shouldDirty: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCheckbox]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetUsersByRoles = () => {
    const params: IGetUsersParams = {
      roles: [AccountRoleCodesEnum.EMPLOYEE, AccountRoleCodesEnum.CAREGIVER],
      status: StatusEnum.ACTIVE,
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
    };

    dispatch(getListUserByRoles(params))
      .unwrap()
      .then((res) => {
        const listUser = res?.data.responses;
        if (listUser && listUser.length > DEFAULT_NUMBER_ZERO) {
          const newOwnerList = listUser?.map((user) => ({
            label: getUserName(user.firstName, user.lastName) || EMPTY_STRING,
            value: user.id,
          }));

          setOwnerList(newOwnerList);
        }
      })
      .catch((err) => {});
  };

  const handleSubmitForm = async (dataForm: IFormCreateCaregiverReferral) => {
    const newData: IFormCreateCaregiverReferral = {
      ...dataForm,
      profileImage:
        Array.isArray(dataForm?.profileImage) && dataForm?.profileImage?.length > DEFAULT_NUMBER_ZERO
          ? dataForm?.profileImage
          : undefined,
      homePhone: dataForm.homePhone?.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING),
      mobilePhone: dataForm.mobilePhone?.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING),
      workPhone: dataForm.workPhone?.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING),
      faxNumber: dataForm.faxNumber?.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING),
    };

    loadingContext?.show();

    if (caregiverReferralId) {
      return handleEditReferral(newData);
    }

    handleCreateReferral(newData);
  };

  const handleCreateReferral = async (newData: IFormCreateCaregiverReferral) => {
    const compareData = compareDataUpdate(defaultValues, newData);
    const formData = await createFormData(compareData, KeyFormRequestReferralEnum.PROFILE_IMAGE);

    dispatch(createCaregiverReferral(formData))
      .unwrap()
      .then(() => {
        handleGoBack();
      })
      .catch(() => {})
      .finally(() => {
        loadingContext?.hide();
      });
  };

  const handleEditReferral = async (newData: IFormCreateCaregiverReferral) => {
    if (!caregiverReferralId) return;

    loadingContext?.show();

    const formData = await createFormData(newData, KeyFormRequestReferralEnum.PROFILE_IMAGE);

    const payload: IFormUpdateCaregiverReferral = {
      caregiverReferralId: caregiverReferralId,
      body: formData,
    };

    dispatch(updateCaregiverReferral(payload))
      .unwrap()
      .then(() => {
        handleGoBack();
      })
      .catch(() => {})
      .finally(() => {
        loadingContext?.hide();
      });
  };

  const handleGoBack = () => {
    navigate(NAVIGATE_BACK);
  };

  const handleChangeAddress = (address: IAddress) => {
    setValue('address', address?.address ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    address?.city && setValue('city', address?.city ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    address?.state && setValue('state', address?.state ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    address?.zipCode &&
      setValue('postalCode', address?.zipCode ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });

    setValue('lat', address.lat);
    setValue('lng', address.lng);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCheckbox(event.target.value);
  };

  const handleGetListReferral = () => {
    const params: IQueryCaregiverReferral = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
      status: StatusEnum.ACTIVE,
      referralRole: ReferralRoleEnum.ORGANIZATION,
    };

    dispatch(getListCaregiverReferral(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        const listCaregiverReferral = res.data?.responses?.map((data) => ({
          label: data?.organizationName || EMPTY_STRING,
          value: data.id,
        }));

        setOrganizationList(listCaregiverReferral);
      })
      .catch((err) => {})
      .finally(() => {});
  };

  const handleGetDetailReferral = () => {
    if (!caregiverReferralId) return;
    loadingContext?.show();

    dispatch(getDetailReferrals(caregiverReferralId))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        const dataDetail = res.data;
        const dataUpdate: IFormCreateCaregiverReferral = {
          firstName: dataDetail.firstName,
          lastName: dataDetail.lastName,
          middleName: dataDetail.middleName,
          organizationName: dataDetail.organizationName,
          organizationId: dataDetail.organization?.id,
          address: dataDetail.address || EMPTY_STRING,
          secondaryAddress: dataDetail.secondaryAddress,
          city: dataDetail.city || EMPTY_STRING,
          state: dataDetail.state || EMPTY_STRING,
          postalCode: dataDetail.postalCode || EMPTY_STRING,
          homePhone: dataDetail.homePhone,
          mobilePhone: dataDetail.mobilePhone || EMPTY_STRING,
          workPhone: dataDetail.workPhone,
          email: dataDetail.email,
          faxNumber: dataDetail.faxNumber,
          webAddress: dataDetail.webAddress,
          lat: dataDetail.lat,
          lng: dataDetail.lng,
          ownerId: dataDetail.owner?.id,
          profileImage: [{ url: dataDetail.avatarUrl }],
          referralType: dataDetail.referralType || EMPTY_STRING,
          role: dataDetail.role,
        };

        const defaultFormUpdate = removeEmptyObjects(dataUpdate);

        setSelectedCheckbox(dataDetail.role || ReferralRoleEnum.INDIVIDUAL);
        reset(defaultFormUpdate);
      })
      .catch((err) => {})
      .finally(() => {
        loadingContext?.hide();
      });
  };
  //#endregion Handle Function

  return (
    <div id='caregiverReferralFormPage' className={cx('container')}>
      <BaseButton
        onClick={handleGoBack}
        typeStyle={ButtonTypeEnum.ONLY_TEXT}
        iconLeft={icons.commonIconPrev}
        text={t('common_text_back')}
      />
      <div className={cx('header')}>
        <div className={cx('title')}>
          {t(caregiverReferralId ? 'caregiver_referral_form_edit_title' : 'caregiver_referral_form_add_title')}
        </div>
      </div>

      {!isFetchingDetailReferral && (
        <div className={cx('body')}>
          <form className={cx('form')} onSubmit={handleSubmit(handleSubmitForm)}>
            <div className={cx('formContent')}>
              {!caregiverReferralId && (
                <Controller
                  name={'role'}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <div className={cx('referralRoleWrap')}>
                      <RadioCrm
                        labelClassName={cx('radioLabel')}
                        id='individual'
                        name='role'
                        value={ReferralRoleEnum.INDIVIDUAL}
                        checked={selectedCheckbox === ReferralRoleEnum.INDIVIDUAL}
                        onChange={handleRadioChange}
                        label={t('crm_add_new_referral_source_individual')}
                      />

                      <RadioCrm
                        labelClassName={cx('radioLabel')}
                        id='organization'
                        name='role'
                        value={ReferralRoleEnum.ORGANIZATION}
                        checked={selectedCheckbox === ReferralRoleEnum.ORGANIZATION}
                        onChange={handleRadioChange}
                        label={t('crm_add_new_referral_source_organization')}
                      />
                    </div>
                  )}
                />
              )}

              {selectedCheckbox === ReferralRoleEnum.INDIVIDUAL ? (
                <>
                  <Controller
                    name={'profileImage'}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <BaseUploadImage
                        label={t('crm_add_new_referral_source_profile_image_label')}
                        textBtn={t('crm_add_new_referral_source_profile_image_content')}
                        height={64}
                        maxUpload={DEFAULT_NUMBER_ONE}
                        defaultImageList={value ?? undefined}
                        onChange={onChange}
                        errorMessage={errors.profileImage?.message}
                      />
                    )}
                  />

                  <div className={cx('threeCol')}>
                    <Controller
                      name='firstName'
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <BaseInput
                          id='firstName'
                          label={t('crm_add_modal_first_name_label')}
                          value={value}
                          onChange={onChange}
                          messageError={errors.firstName?.message}
                          required
                        />
                      )}
                    />

                    <Controller
                      name='middleName'
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <BaseInput
                          id='middleName'
                          label={t('crm_add_modal_middle_name_label')}
                          onChange={onChange}
                          value={value || EMPTY_STRING}
                        />
                      )}
                    />

                    <Controller
                      name='lastName'
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <BaseInput
                          id='lastName'
                          label={t('crm_add_modal_last_name_label')}
                          value={value || EMPTY_STRING}
                          onChange={onChange}
                          messageError={errors.lastName?.message}
                          required
                        />
                      )}
                    />
                  </div>

                  <Controller
                    name='organizationId'
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <BaseSelect
                        options={organizationList ?? []}
                        mode={MODE_SEARCH}
                        label={t('crm_add_modal_organization_label')}
                        placeholder={t('common_select_placeholder')}
                        onChange={({ value }) => onChange(value)}
                        value={value || EMPTY_STRING}
                        errorMessage={errors.organizationId?.message}
                      />
                    )}
                  />
                </>
              ) : (
                <Controller
                  name='organizationName'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <BaseInput
                      id='organizationName'
                      label={t('crm_add_modal_organization_label')}
                      value={value || EMPTY_STRING}
                      onChange={onChange}
                      messageError={errors.organizationName?.message}
                      required
                    />
                  )}
                />
              )}

              <div className={cx('twoCol')}>
                <Controller
                  name='address'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <PlacesAutocomplete
                      id='address'
                      value={value || EMPTY_STRING}
                      label={t('crm_add_modal_address_label')}
                      onChange={(address) => handleChangeAddress(address)}
                      required
                      messageError={errors.address?.message}
                    />
                  )}
                />

                <Controller
                  name='secondaryAddress'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <BaseInput
                      id='secondaryAddress'
                      label={t('crm_add_modal_secondary_address_label')}
                      value={value || EMPTY_STRING}
                      onChange={onChange}
                    />
                  )}
                />
              </div>

              <div className={cx('threeCol')}>
                <Controller
                  name='city'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <BaseInput
                      id='city'
                      label={t('crm_add_modal_city_label')}
                      value={value || EMPTY_STRING}
                      onChange={onChange}
                      messageError={errors.city?.message}
                      required
                    />
                  )}
                />

                <Controller
                  name='state'
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <BaseInput
                      id='state'
                      value={value || EMPTY_STRING}
                      label={t('crm_add_modal_state_label')}
                      onChange={onChange}
                      required
                      messageError={errors.state?.message}
                    />
                  )}
                />

                <Controller
                  name='postalCode'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <BaseInput
                      id='postalCode'
                      label={t('crm_add_modal_postal_code_label')}
                      value={value || EMPTY_STRING}
                      onChange={onChange}
                      messageError={errors.postalCode?.message}
                      required
                    />
                  )}
                />
              </div>

              <div className={cx('twoCol')}>
                <Controller
                  name='homePhone'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <BaseInput
                      id='homePhone'
                      label={t('crm_add_modal_home_phone_label')}
                      value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const { value } = event.target;
                        const formattedValue = formatPhoneNumber(value);
                        onChange(formattedValue);
                      }}
                    />
                  )}
                />

                <Controller
                  name='mobilePhone'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <BaseInput
                      id='mobilePhone'
                      label={t('crm_add_modal_mobile_phone_label')}
                      value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const { value } = event.target;
                        const formattedValue = formatPhoneNumber(value);
                        onChange(formattedValue);
                      }}
                      messageError={errors.mobilePhone?.message}
                      required
                    />
                  )}
                />
              </div>

              <div className={cx('twoCol')}>
                <Controller
                  name='workPhone'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <BaseInput
                      id='workPhone'
                      label={t('crm_add_modal_work_phone_label')}
                      value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const { value } = event.target;
                        const formattedValue = formatPhoneNumber(value);
                        onChange(formattedValue);
                      }}
                    />
                  )}
                />

                <Controller
                  name='email'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <BaseInput
                      id='email'
                      label={t('crm_add_modal_email_label')}
                      value={value || EMPTY_STRING}
                      onChange={onChange}
                      messageError={errors.email?.message}
                    />
                  )}
                />
              </div>

              <div className={cx('twoCol')}>
                <Controller
                  name='faxNumber'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <BaseInput
                      id='faxNumber'
                      label={t('crm_add_modal_fax_number_label')}
                      value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const { value } = event.target;
                        const formattedValue = formatPhoneNumber(value);
                        onChange(formattedValue);
                      }}
                    />
                  )}
                />

                <Controller
                  name='webAddress'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <BaseInput
                      id='webAddress'
                      label={t('crm_add_modal_web_address_label')}
                      value={value || EMPTY_STRING}
                      onChange={onChange}
                      messageError={errors.webAddress?.message}
                    />
                  )}
                />
              </div>

              <div className={cx('twoCol')}>
                <Controller
                  name='referralType'
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <BaseSelect
                      options={optionsReferralType}
                      label={t('crm_add_modal_type_label')}
                      placeholder={t('common_select_placeholder')}
                      onChange={({ value }) => onChange(value)}
                      value={value || EMPTY_STRING}
                      errorMessage={errors.referralType?.message}
                      mode={MODE_SEARCH}
                      required
                    />
                  )}
                />

                <Controller
                  name='ownerId'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <BaseSelect
                      options={ownerList}
                      label={t('crm_add_modal_owner_label')}
                      placeholder={t('common_select_placeholder')}
                      onChange={({ value }) => onChange(value)}
                      value={value || EMPTY_STRING}
                      errorMessage={errors.ownerId?.message}
                      mode={MODE_SEARCH}
                    />
                  )}
                />
              </div>
            </div>

            <div className={cx('footerButton')}>
              <BaseButton text={t('common_go_back_label')} width={80} onClick={handleGoBack} type='button' />

              <BaseButton
                type='submit'
                text={t('common_save_label')}
                typeStyle={ButtonTypeEnum.PRIMARY}
                width={80}
                disabled={caregiverReferralId ? !isDirty : false}
              />
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default CaregiverReferralForm;
