import { urlApiTraining } from '~/utils/constants/api';
import axiosClient from '../axiosClient';
import { BaseResponse, IListDataResponse } from '~/utils/interface/common';
import { IPayloadListCaregiverTraining, ITrainingCaregiver } from '~/utils/interface/caregivers/training';

const trainingApi = {
  getListCaregiverTraining(payload: IPayloadListCaregiverTraining) {
    const { params, caregiverId } = payload;

    const url = `${urlApiTraining.getListCaregiverTraining(caregiverId)}`;
    return axiosClient.get<BaseResponse<ITrainingCaregiver[]>>(url, { params });
  },
};
export default trainingApi;
