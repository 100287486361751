// Libs
import { TFunction } from 'i18next';
import moment from 'moment';
import * as yup from 'yup';
// Others
import { IFormInitialCall } from '~/utils/interface/crm/initialCall';
import { EMPTY_STRING, RegExp } from '~/utils/constants/common';
import { DateFormatEnum, InitialCallFormEnum, ReferralRoleEnum } from '~/utils/enum';

export const initialCallSchema = (t: TFunction) => {
  return yup
    .object()
    .shape({
      caller: callerInitialCallSchema(t),
      prospect: prospectInitialCallSchema(t),
      referralSource: referralSourceInitialCallSchema(t),
      prospectCareNeed: prospectCareNeedsInitialCallSchema(t),
      callDate: yup.string().required(t('common_message_required_error')),
    })
    .required();
};

export const initialValues: IFormInitialCall = {
  caller: {
    addType: InitialCallFormEnum.ADD_NEW,
  },
  prospect: {
    addType: InitialCallFormEnum.ADD_NEW,
  },
  referralSource: {
    addType: InitialCallFormEnum.ADD_NEW,
    type: ReferralRoleEnum.INDIVIDUAL,
  },
  prospectCareNeed: {
    careGoals: EMPTY_STRING,
    initialContact: moment().format(DateFormatEnum.YYYY_MM_DD),
    isNeedsMedication: false,
  },
  callDate: EMPTY_STRING,
};

export const callerInitialCallSchema = (t: TFunction) => {
  return yup
    .object()
    .shape({
      addType: yup.string().required(t('common_message_required_error')),

      contactType: yup
        .string()
        .optional()
        .when('addType', {
          is: (value: string) => value === InitialCallFormEnum.ADD_NEW || value === InitialCallFormEnum.SELECT_EXISTING,
          then: (schema) => schema.required(t('common_message_required_error')),
          otherwise: (schema) => schema.optional(),
        }),

      firstName: yup
        .string()
        .optional()
        .when('addType', {
          is: (value: string) => value === InitialCallFormEnum.ADD_NEW,
          then: (schema) => schema.required(t('common_message_required_error')),
          otherwise: (schema) => schema.optional(),
        }),

      lastName: yup
        .string()
        .optional()
        .when('addType', {
          is: (value: string) => value === InitialCallFormEnum.ADD_NEW,
          then: (schema) => schema.required(t('common_message_required_error')),
          otherwise: (schema) => schema.optional(),
        }),

      email: yup
        .string()
        .trim()
        .optional()
        .test('isValidEmail', t('common_validate_invalid_email'), (value) => {
          if (value && !RegExp.EMAIL.test(value)) return false;
          return true;
        }),

      id: yup
        .string()
        .optional()
        .when('addType', {
          is: (value: string) => value === InitialCallFormEnum.SELECT_EXISTING,
          then: (schema) => schema.required(t('common_message_required_error')),
          otherwise: (schema) => schema.optional(),
        }),
    })
    .required();
};

export const prospectInitialCallSchema = (t: TFunction) => {
  return yup
    .object()
    .shape({
      addType: yup.string().required(t('common_message_required_error')),

      firstName: yup
        .string()
        .optional()
        .when('addType', {
          is: (value: string) => value === InitialCallFormEnum.ADD_NEW,
          then: (schema) => schema.required(t('common_message_required_error')),
          otherwise: (schema) => schema.optional(),
        }),

      lastName: yup
        .string()
        .optional()
        .when('addType', {
          is: (value: string) => value === InitialCallFormEnum.ADD_NEW,
          then: (schema) => schema.required(t('common_message_required_error')),
          otherwise: (schema) => schema.optional(),
        }),

      mobilePhone: yup
        .string()
        .optional()
        .when('addType', {
          is: (value: string) => value === InitialCallFormEnum.ADD_NEW,
          then: (schema) => schema.required(t('common_message_required_error')),
          otherwise: (schema) => schema.optional(),
        }),

      locationId: yup
        .string()
        .optional()
        .when('addType', {
          is: (value: string) => value === InitialCallFormEnum.ADD_NEW,
          then: (schema) => schema.required(t('common_message_required_error')),
          otherwise: (schema) => schema.optional(),
        }),

      address: yup
        .string()
        .optional()
        .when('addType', {
          is: (value: string) => value === InitialCallFormEnum.ADD_NEW,
          then: (schema) => schema.required(t('common_message_required_error')),
          otherwise: (schema) => schema.optional(),
        }),

      city: yup
        .string()
        .optional()
        .when('addType', {
          is: (value: string) => value === InitialCallFormEnum.ADD_NEW,
          then: (schema) => schema.required(t('common_message_required_error')),
          otherwise: (schema) => schema.optional(),
        }),

      state: yup
        .string()
        .optional()
        .when('addType', {
          is: (value: string) => value === InitialCallFormEnum.ADD_NEW,
          then: (schema) => schema.required(t('common_message_required_error')),
          otherwise: (schema) => schema.optional(),
        }),

      postalCode: yup
        .string()
        .optional()
        .when('addType', {
          is: (value: string) => value === InitialCallFormEnum.ADD_NEW,
          then: (schema) => schema.required(t('common_message_required_error')),
          otherwise: (schema) => schema.optional(),
        }),

      id: yup
        .string()
        .optional()
        .when('addType', {
          is: (value: string) => value === InitialCallFormEnum.SELECT_EXISTING,
          then: (schema) => schema.required(t('common_message_required_error')),
          otherwise: (schema) => schema.optional(),
        }),
      email: yup
        .string()
        .trim()
        .optional()
        .test('isValidEmail', t('common_validate_invalid_email'), (value) => {
          if (value && !RegExp.EMAIL.test(value)) return false;
          return true;
        }),
    })
    .required();
};

export const referralSourceInitialCallSchema = (t: TFunction) => {
  return yup
    .object()
    .shape({
      addType: yup.string().required(t('common_message_required_error')),

      type: yup.string().optional(),

      firstName: yup
        .string()
        .optional()
        .when(['addType', 'type'], {
          is: (addType: string, type: string) =>
            addType === InitialCallFormEnum.ADD_NEW && type === ReferralRoleEnum.INDIVIDUAL,
          then: (schema) => schema.required(t('common_message_required_error')),
          otherwise: (schema) => schema.optional(),
        }),

      lastName: yup
        .string()
        .optional()
        .when(['addType', 'type'], {
          is: (addType: string, type: string) =>
            addType === InitialCallFormEnum.ADD_NEW && type === ReferralRoleEnum.INDIVIDUAL,
          then: (schema) => schema.required(t('common_message_required_error')),
          otherwise: (schema) => schema.optional(),
        }),

      mobilePhone: yup
        .string()
        .optional()
        .when('addType', {
          is: (value: string) => value === InitialCallFormEnum.ADD_NEW,
          then: (schema) => schema.required(t('common_message_required_error')),
          otherwise: (schema) => schema.optional(),
        }),

      address: yup
        .string()
        .optional()
        .when('addType', {
          is: (value: string) => value === InitialCallFormEnum.ADD_NEW,
          then: (schema) => schema.required(t('common_message_required_error')),
          otherwise: (schema) => schema.optional(),
        }),

      city: yup
        .string()
        .optional()
        .when('addType', {
          is: (value: string) => value === InitialCallFormEnum.ADD_NEW,
          then: (schema) => schema.required(t('common_message_required_error')),
          otherwise: (schema) => schema.optional(),
        }),

      state: yup
        .string()
        .optional()
        .when('addType', {
          is: (value: string) => value === InitialCallFormEnum.ADD_NEW,
          then: (schema) => schema.required(t('common_message_required_error')),
          otherwise: (schema) => schema.optional(),
        }),

      postalCode: yup
        .string()
        .optional()
        .when('addType', {
          is: (value: string) => value === InitialCallFormEnum.ADD_NEW,
          then: (schema) => schema.required(t('common_message_required_error')),
          otherwise: (schema) => schema.optional(),
        }),

      organization: yup
        .string()
        .optional()
        .when('type', {
          is: (value: string) => value === ReferralRoleEnum.ORGANIZATION,
          then: (schema) => schema.required(t('common_message_required_error')),
          otherwise: (schema) => schema.optional(),
        }),

      email: yup
        .string()
        .trim()
        .optional()
        .test('isValidEmail', t('common_validate_invalid_email'), (value) => {
          if (value && !RegExp.EMAIL.test(value)) return false;
          return true;
        }),

      id: yup
        .string()
        .optional()
        .when('addType', {
          is: (value: string) => value === InitialCallFormEnum.SELECT_EXISTING,
          then: (schema) => schema.required(t('common_message_required_error')),
          otherwise: (schema) => schema.optional(),
        }),
    })
    .required();
};

export const prospectCareNeedsInitialCallSchema = (t: TFunction) => {
  return yup
    .object()
    .shape({
      careGoals: yup.string().required(t('common_message_required_error')),
    })
    .required();
};

/**
 * Clean phone numbers
 * @param data is data needs to be cleaned
 */
export const cleanPhoneNumbers = (data: any) => ({
  ...data,
  ...(data.workPhone && { workPhone: data.workPhone.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING) }),
  ...(data.homePhone && { homePhone: data.homePhone.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING) }),
  ...(data.mobilePhone && { mobilePhone: data.mobilePhone.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING) }),
});
