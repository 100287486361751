// Libs
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '~/redux/store';
// Others
import { createCampaign, editCampaign, getDetailCampaign, getListCampaign } from './campaignThunk';

export interface campaignState {
  isRefreshListCampaign: boolean;
}

const initialState: campaignState = {
  isRefreshListCampaign: false,
};

const campaignSlice = createSlice({
  name: 'campaignState',
  initialState,
  reducers: {
    setRefreshList(state, action) {
      state.isRefreshListCampaign = action.payload;
    },
  },
  extraReducers(builder) {
    // Get List Location
    builder
      .addCase(getListCampaign.pending, (state) => {})
      .addCase(getListCampaign.fulfilled, (state) => {})
      .addCase(getListCampaign.rejected, (state) => {});

    // Create campaign
    builder
      .addCase(createCampaign.pending, (state) => {})
      .addCase(createCampaign.fulfilled, (state) => {})
      .addCase(createCampaign.rejected, (state) => {});

    // Detail campaign
    builder
      .addCase(getDetailCampaign.pending, (state) => {})
      .addCase(getDetailCampaign.fulfilled, (state) => {})
      .addCase(getDetailCampaign.rejected, (state) => {});

    // Edit campaign
    builder
      .addCase(editCampaign.pending, (state) => {})
      .addCase(editCampaign.fulfilled, (state) => {})
      .addCase(editCampaign.rejected, (state) => {});
  },
});

// Actions
export const campaignActions = campaignSlice.actions;

// Selectors
export const selectIsRefreshCampaignList = (state: RootState) => state.campaignState.isRefreshListCampaign;

// Reducers
const campaignReducer = campaignSlice.reducer;
export default campaignReducer;
