// Libs
import classNames from 'classnames/bind';
import * as yup from 'yup';
import { TFunction } from 'i18next';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// Components, Layouts, Pages
import { BaseButton, BaseDatePicker, BaseInput, BaseSelect, Loading, Modal } from '~/components';
// Others
import { IBaseOption, IListDataResponse, IListQueryParams } from '~/utils/interface/common';
import { formatInputNumber, getUserName, removeEmptyObjects } from '~/utils/helper';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_LIMIT_MAX_ITEM,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { BusinessTypeEnum, ButtonTypeEnum } from '~/utils/enum';
import { IListQueryParamsClients } from '~/utils/interface/crm/clients';
import { useAppDispatch } from '~/redux/hooks';
import { getListClients } from '~/thunks/crm/clients/clientsThunk';
import { getListCaregiver } from '~/thunks/caregiver/caregiverThunk';
import { ICaregiver } from '~/mockData/mockInterface';
import { IFormMileage } from '~/utils/interface/mileage';
import { createMileage } from '~/thunks/dailyCare/dailyCareThunk';
// Styles, images, icons
import styles from './FormMileage.module.scss';

type Props = {
  businessType: BusinessTypeEnum;
  dateSelected: string;
  onClose?: () => void;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction, businessType: BusinessTypeEnum) => {
  return yup.object().shape({
    businessId: yup.string().trim().required(t('common_required_field')),
    businessType: yup.string().trim().required(t('common_required_field')),
    clientId: yup.string().trim().optional(),
    accountId: yup.string().trim().optional(),
    date: yup.string().trim().required(t('common_required_field')),
    miles: yup.string().trim().required(t('common_required_field')),
    description: yup.string().trim().optional(),
  });
};

const defaultValues: IFormMileage = {
  businessId: EMPTY_STRING,
  businessType: EMPTY_STRING,
  date: EMPTY_STRING,
  miles: EMPTY_STRING,
};

const FormMileage = (props: Props) => {
  //#region Destructuring Props
  const { dateSelected, businessType, onClose } = props;
  const { clientId, caregiverId } = useParams();
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IFormMileage>({
    resolver: yupResolver(schema(t, businessType)),
    defaultValues: defaultValues,
  });
  //#endregion Declare Hook

  //#region Declare Memo
  //#endregion Declare Memo

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [optionClient, setOptionClient] = useState<IBaseOption[]>([]);
  const [optionCaregiver, setOptionCaregiver] = useState<IBaseOption[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetClientList();
    handleGetCaregiverList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!dateSelected) return;

    setValue('date', dateSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateSelected]);

  useEffect(() => {
    setValue('businessType', businessType);

    if (clientId) {
      setValue('clientId', clientId);
      setValue('businessId', clientId);
      return;
    }

    if (caregiverId) {
      setValue('accountId', caregiverId);
      setValue('businessId', caregiverId);
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessType, clientId, caregiverId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetClientList = () => {
    const params: IListQueryParamsClients = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
    };

    dispatch(getListClients(params))
      .unwrap()
      .then((res) => {
        const { responses } = res?.data;

        if (responses.length === DEFAULT_NUMBER_ZERO) return;

        const listClient = responses?.map((data) => ({
          label: data?.organizationName || getUserName(data?.firstName, data?.lastName),
          value: data.id,
        }));

        setOptionClient(listClient);
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const handleGetCaregiverList = () => {
    const params: IListQueryParams = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
    };

    dispatch(getListCaregiver(params))
      .unwrap()
      .then((res) => {
        const { responses }: IListDataResponse<ICaregiver[]> = res?.data;

        const listCaregiver = responses.map((data) => {
          return { label: getUserName(data.firstName, data.middleName, data.lastName), value: String(data.id) };
        });

        setOptionCaregiver(listCaregiver);
      })
      .catch((err) => {})
      .finally(() => {});
  };

  const handleAddMileage = (data: IFormMileage) => {
    setIsLoading(true);

    const newMileage = removeEmptyObjects(data) as IFormMileage;

    dispatch(createMileage(newMileage))
      .unwrap()
      .then((res) => {
        handleClose();
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleClose = () => {
    onClose?.();
    reset(defaultValues);
  };
  //#endregion Handle Function

  return (
    <Modal isOpen={true} onClose={handleClose} title={t('daily_care_modal_add_mileage_title')}>
      <form id='formMileageComponent' className={cx('container')} onSubmit={handleSubmit(handleAddMileage)}>
        <div className={cx('contentModal')}>
          <div className={cx('twoColumn')}>
            <Controller
              name='date'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseDatePicker
                  id={'date'}
                  required
                  disable
                  value={value}
                  label={t('daily_care_modal_add_mileage_date_label')}
                  onDateSelected={onChange}
                  errorMessage={errors.date?.message}
                />
              )}
            />

            <Controller
              name='miles'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  required
                  id={'miles'}
                  value={value}
                  label={t('daily_care_modal_add_mileage_miles_label')}
                  onChange={(event) => onChange(formatInputNumber(event.target.value))}
                  messageError={errors.miles?.message}
                />
              )}
            />
          </div>

          <Controller
            name='description'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='description'
                label={t('daily_care_modal_add_mileage_description_label')}
                value={value}
                onChange={onChange}
              />
            )}
          />

          <div className={cx('twoColumn')}>
            <Controller
              name='clientId'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseSelect
                  disabled={businessType === BusinessTypeEnum.CLIENT}
                  value={value}
                  options={optionClient}
                  label={t('daily_care_modal_add_mileage_client_label')}
                  placeholder={t('common_select_placeholder')}
                  onChange={(optionSelected: IBaseOption) => {
                    onChange(optionSelected?.value);
                  }}
                />
              )}
            />

            <Controller
              name='accountId'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseSelect
                  disabled={businessType === BusinessTypeEnum.CAREGIVER}
                  value={value}
                  options={optionCaregiver}
                  label={t('daily_care_modal_add_mileage_caregiver_label')}
                  placeholder={t('common_select_placeholder')}
                  onChange={(optionSelected: IBaseOption) => {
                    onChange(optionSelected?.value);
                  }}
                />
              )}
            />
          </div>
        </div>

        <div className={cx('formActions')}>
          <BaseButton text={t('common_cancel_label')} width={80} onClick={handleClose} type='button' />

          <BaseButton text={t('common_save_label')} typeStyle={ButtonTypeEnum.PRIMARY} width={80} type='submit' />
        </div>

        {isLoading && <Loading />}
      </form>
    </Modal>
  );
};

export default FormMileage;
