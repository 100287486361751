// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TFunction } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// Components, Layouts, Pages
import {
  BaseButton,
  BaseTable,
  ButtonStatus,
  CareAlertsItem,
  CaregiverItem,
  CaregiverReferralItem,
  ImageCircle,
  ModalUnderDevelopment,
} from '~/components';
// Others
import { ButtonTypeEnum, ImageCircleTypeEnum, TimeFormatEnum, TranslationEnum, statusEnum } from '~/utils/enum';
import { ColumnTableType, IListDataResponse, IListQueryParams } from '~/utils/interface/common';
import {
  DEFAULT_NUMBER_LIMIT_APPLICANT,
  DEFAULT_NUMBER_LIMIT_CAREGIVER,
  DEFAULT_NUMBER_ZERO,
  defaultCurrentPage,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { convertDateToFormatTime, getRoutesByRole, getUserName } from '~/utils/helper';
import { caregiverReferralData } from '~/mockData';
import { ICaregiver } from '~/utils/interface/caregiver';
import { LoadingContext } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { getListCaregiver } from '~/thunks/caregiver/caregiverThunk';
import { IApplicant } from '~/utils/interface/applicant';
import { getListApplicant } from '~/thunks/caregivers/applicant/applicantThunk';
import { ICareAlerts } from '~/utils/interface/careAlert';
import { caregiverActions, selectIsRefreshCaregiverList } from '~/thunks/caregiver/caregiverSlice';
import { IMockListCareAlert } from '~/mockData/mockInterface';
// Styles, images, icons
import styles from './Caregiver.module.scss';
import { icons } from '~/assets';

type Props = {
  role: string;
};

const cx = classNames.bind(styles);

const columnsApplicant = (t: TFunction<TranslationEnum.TRANSLATION>): ColumnTableType<IApplicant>[] => {
  return [
    {
      key: 'name',
      title: t('caregiver_table_title_name'),
      render(_, record: IApplicant) {
        return !record.firstName && !record.lastName ? (
          <>{EMPTY_STRING}</>
        ) : (
          <>
            <ImageCircle
              type={record?.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
              imageUrl={record?.avatarUrl}
              firstName={record?.firstName}
              lastName={record?.lastName}
              width={24}
              height={24}
              fontSize={10}
              margin={'0 6px 0 0'}
            />
            <span>{getUserName(record?.firstName, record?.lastName)}</span>
          </>
        );
      },
    },
    {
      key: 'address',
      title: t('caregiver_table_title_address'),
      dataIndex: 'address',
      render(_, record: IApplicant) {
        return (
          <div className={cx('textBase')}>
            {record?.address ? <span>{record.address}</span> : <div>{EMPTY_STRING}</div>}
          </div>
        );
      },
    },
    {
      key: 'email',
      title: t('caregiver_table_title_email'),
      dataIndex: 'email',
      render(_, record: IApplicant) {
        return (
          <div className={cx('textBase')}>
            {record?.email ? <span>{record.email}</span> : <div>{EMPTY_STRING}</div>}
          </div>
        );
      },
    },
    {
      key: 'phoneNumber',
      title: t('caregiver_table_title_phone_number'),
      dataIndex: 'phoneNumber',
      render(_, record: IApplicant) {
        return (
          <div className={cx('textBase')}>
            {record?.phoneNumber ? <span>{record.phoneNumber}</span> : <div>{EMPTY_STRING}</div>}
          </div>
        );
      },
    },
    {
      key: 'status',
      title: t('caregiver_table_title_phone_status'),
      render(_) {
        return (
          <div className={cx('buttonBase')}>
            <ButtonStatus width={72} borderRadius={6} color='teal800' label={statusEnum.CONVERT} />
            <ButtonStatus width={72} borderRadius={6} color='gray300' label={statusEnum.REJECT} />
          </div>
        );
      },
      width: '12%',
    },
  ];
};

const columnsCareAlert = (t: TFunction<TranslationEnum.TRANSLATION>): ColumnTableType<IMockListCareAlert>[] => {
  return [
    {
      key: 'date',
      title: t('care_alert_date_label'),
      render(value, record, index) {
        return <>{record.date ? convertDateToFormatTime(record.date, TimeFormatEnum.MM_DD_YY) : EMPTY_STRING}</>;
      },
    },
    {
      key: 'time',
      title: t('care_alert_time_label'),
      render(value, record, index) {
        return (
          <>{record.time ? convertDateToFormatTime(record.time, TimeFormatEnum.HOUR_MINUTE_AM_PM) : EMPTY_STRING}</>
        );
      },
    },
    {
      key: 'alert',
      title: t('care_alert_alert_label'),
      render(value, record, index) {
        return <>{record.alert || EMPTY_STRING}</>;
      },
    },
  ];
};

const Caregiver = (props: Props) => {
  //#region Destructuring Props
  const { role } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loading = useContext(LoadingContext);
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  const isRefreshCaregiverList = useSelector(selectIsRefreshCaregiverList);
  //#endregion Selector

  //#region Declare State
  const [caregiverData, setCaregiverData] = useState<ICaregiver[]>([]);
  const [applicantData, setApplicantData] = useState<IApplicant[]>([]);
  const [careAlertData, setCareAlertData] = useState<ICareAlerts[]>([]);
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    loading?.show();

    const queryObjectCaregiver: IListQueryParams = {
      page: defaultCurrentPage,
      limit: DEFAULT_NUMBER_LIMIT_CAREGIVER,
    };

    const queryObjectApplicant: IListQueryParams = {
      page: defaultCurrentPage,
      limit: DEFAULT_NUMBER_LIMIT_APPLICANT,
    };

    handleGetCaregiver(queryObjectCaregiver);
    handleGetApplicant(queryObjectApplicant);
  }, []);

  useEffect(() => {
    if (!isRefreshCaregiverList) {
      return;
    }

    const queryObjectCaregiver: IListQueryParams = {
      page: defaultCurrentPage,
      limit: DEFAULT_NUMBER_LIMIT_CAREGIVER,
    };

    handleGetCaregiver(queryObjectCaregiver);
    dispatch(caregiverActions.setRefreshList(false));
  }, [isRefreshCaregiverList]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleNavigate = (route: string) => () => {
    navigate(route);
  };

  const handleGetCaregiver = (payload: IListQueryParams) => {
    dispatch(getListCaregiver(payload))
      .unwrap()
      .then((res) => {
        const { responses, pagination }: IListDataResponse<ICaregiver[]> = res?.data;
        setCaregiverData(responses);
        loading?.hide();
      })
      .catch((err) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const handleGetApplicant = (payload: IListQueryParams) => {
    dispatch(getListApplicant(payload))
      .unwrap()
      .then((res) => {
        const { responses, pagination }: IListDataResponse<IApplicant[]> = res?.data;
        setApplicantData(responses);
      })
      .catch((err) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const handleGetCareAlert = (payload: IListQueryParams) => {
    // Handle Get Care Alert Later
  };

  const handleClickUnderDevelop = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleApplicantRowClick = (data: IApplicant) => {
    setIsShowModalUnderDevelopment(true);
  };

  const handleCareAlertRowClick = (data: IMockListCareAlert) => {
    setIsShowModalUnderDevelopment(true);
  };
  //#endregion Handle Function

  return (
    <div id='caregiverPage' className={cx('caregiverContainer')}>
      <div className={cx('requestApplicantContentWrap')}>
        {/* UI Request Applicant */}
        <section className={cx('content')}>
          <div className={cx('headContent')}>
            <div className={cx('titleWrap')}>
              <span className={cx('titleContent')}>{t('caregiver_page_title_request_applicant')}</span>
            </div>

            <div className={cx('buttonGroup')}>
              <BaseButton
                text={t('common_text_view_all')}
                typeStyle={ButtonTypeEnum.ONLY_TEXT}
                iconRight={icons.commonIconNext}
                onClick={handleNavigate(getRoutesByRole(role).requestApplicant ?? EMPTY_STRING)}
              />
            </div>
          </div>

          <div className={cx('bodyWrap')}>
            <BaseTable
              columns={columnsApplicant(t)}
              dataSource={applicantData || []}
              onClickRow={handleApplicantRowClick}
            />
          </div>
        </section>

        {/* UI Caregiver */}
        <section className={cx('content')}>
          <div className={cx('headContent')}>
            <div className={cx('titleWrap')}>
              <span className={cx('titleContent')}>{t('caregiver_page_title_caregiver')}</span>
            </div>

            <div className={cx('buttonGroup')}>
              <BaseButton
                text={t('common_text_view_all')}
                typeStyle={ButtonTypeEnum.ONLY_TEXT}
                iconRight={icons.commonIconNext}
                onClick={handleNavigate(getRoutesByRole(role).caregiverShowAll ?? EMPTY_STRING)}
              />
            </div>
          </div>

          {caregiverData?.length > DEFAULT_NUMBER_ZERO ? (
            <div className={cx('bodyWrapCaregiver')}>
              {caregiverData.map((item, index) => (
                <CaregiverItem key={index} data={item} />
              ))}
            </div>
          ) : (
            <div className={cx('noData')}>{t('common_empty_data')}</div>
          )}
        </section>

        {/* UI Care Alerts */}
        <section className={cx('content')}>
          <div className={cx('headContent')}>
            <div className={cx('titleWrap')}>
              <span className={cx('titleContent')}>{t('caregiver_page_title_care_alerts')}</span>
            </div>

            <div className={cx('buttonGroup')}>
              <BaseButton
                text={t('common_text_view_all')}
                typeStyle={ButtonTypeEnum.ONLY_TEXT}
                iconRight={icons.commonIconNext}
                onClick={handleNavigate(getRoutesByRole(role).careAlertsShowAll ?? EMPTY_STRING)}
              />
            </div>
          </div>

          {/* {careAlertData?.length > DEFAULT_NUMBER_ZERO ? (
            <div className={cx('bodyWrapCareAlerts')}>
              {careAlertData.map((item, index) => (
                <CareAlertsItem key={index} data={item} />
              ))}
            </div>
          ) : (
            <div className={cx('noData')}>{t('common_empty_data')}</div>
          )} */}

          <div className={cx('bodyWrap')}>
            <BaseTable
              columns={columnsCareAlert(t)}
              dataSource={careAlertData || []}
              onClickRow={handleCareAlertRowClick}
            />
          </div>
        </section>

        {/* UI Caregiver Referral */}
        <section className={cx('content')}>
          <div className={cx('headContent')}>
            <div className={cx('titleWrap')}>
              <span className={cx('titleContent')}>{t('caregiver_page_title_caregiver_referral')}</span>
            </div>

            <div className={cx('buttonGroup')}>
              <BaseButton
                text={t('common_text_view_all')}
                typeStyle={ButtonTypeEnum.ONLY_TEXT}
                iconRight={icons.commonIconNext}
                onClick={handleNavigate(getRoutesByRole(role).caregiverReferralShowAll ?? EMPTY_STRING)}
              />
            </div>
          </div>

          {caregiverReferralData?.length > DEFAULT_NUMBER_ZERO ? (
            <div className={cx('bodyWrapCaregiverReferral')}>
              {caregiverReferralData.map((item, index) => (
                <CaregiverReferralItem key={index} data={item} />
              ))}
            </div>
          ) : (
            <div className={cx('noData')}>{t('common_empty_data')}</div>
          )}
        </section>
      </div>

      {isShowModalUnderDevelopment && <ModalUnderDevelopment onClose={handleClickUnderDevelop} />}
    </div>
  );
};

export default Caregiver;
