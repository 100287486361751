// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// Components, Layouts, Pages
// Others
import { AccountRoleCodesEnum, StorageEnum } from '~/utils/enum';
import { adminRoute, staffRoute, superAdminRoute } from '~/utils/constants/route';
// Styles, images, icons
import styles from './ExpiredInvoiceTotal.module.scss';
import { icons } from '~/assets';

type Props = {
  expiredInvoiceTotal: number;
};

const cx = classNames.bind(styles);

const ExpiredInvoiceTotal = (props: Props) => {
  //#region Destructuring Props
  const { expiredInvoiceTotal } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const role = localStorage.getItem(StorageEnum.ROLE);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleNavigatePageInvoice = () => {
    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRoute.base}${superAdminRoute.accounting}${superAdminRoute.invoice}`);
        return;
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRoute.base}${adminRoute.accounting}${adminRoute.invoice}`);
        return;
      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(`${staffRoute.base}${staffRoute.accounting}${staffRoute.invoice}`);
        return;
      default:
        return;
    }
  };
  //#endregion Handle Function

  return (
    <div id='expiredInvoiceTotalComponent' className={cx('invoiceExpired')}>
      <img src={icons.commonIconWarning} alt={t('common_img_text_alt')} className={cx('icon')} />

      <button onClick={handleNavigatePageInvoice} className={cx('invoiceExpiredText')}>
        {t('dashboard_invoice_expired_total', { total: expiredInvoiceTotal })}
      </button>
    </div>
  );
};

export default ExpiredInvoiceTotal;
