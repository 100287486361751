// Libs
import classNames from 'classnames/bind';
import { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components, Layouts, Pages
import { BaseSelect, BaseInput, CheckboxSingle } from '~/components';

// Others
import { IFormBillingPolicy } from '~/utils/interface/rate';
import { useAppSelector } from '~/redux/hooks';
import { EMPTY_STRING, OPTION_ROUNDING_METHOD } from '~/utils/constants/common';
import { InputTypeEnum } from '~/utils/enum';
import { getUserName } from '~/utils/helper';

// Styles, images, icons
import styles from './PayHoursCalculationBillingPolicy.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const PayHoursCalculationBillingPolicy = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    setValue,
    resetField,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext<IFormBillingPolicy>();
  //#endregion Declare Hook

  //#region Selector
  const { clientDetail } = useAppSelector((state) => state.clientState);
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='payHoursCalculationBillingPolicyComponent' className={cx('payHoursCalculationBillingPolicyComponent')}>
      <div className={cx('content')}>
        <div className={cx('groupItem')}>
          <h1 className={cx('title')}>
            {t('billing_policy_pay_hours_calculation_bill_hours_label', {
              userName: getUserName(clientDetail?.firstName, clientDetail?.lastName),
            })}
          </h1>

          <div className={cx('questionContent')}>{t('billing_policy_pay_hours_calculation_bill_hours_content')}</div>

          <div className={cx('questionContent')}>
            {t('billing_policy_pay_hours_calculation_bill_hours_selection_content')}
          </div>
        </div>

        <div className={cx('groupItem')}>
          <h1 className={cx('title')}>{t('billing_policy_pay_hours_calculation_bill_hours_calculate_pay_title')}</h1>
          <div className={cx('labelRoundingMethod')}>
            {t('billing_policy_pay_hours_calculation_bill_hours_rounding_method_title')}
          </div>

          <Controller
            name='payHour.roundingMethod'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseSelect
                value={value}
                options={OPTION_ROUNDING_METHOD}
                placeholder={t('common_select_placeholder')}
                width={600}
                onChange={({ value }) => {
                  onChange(value);
                }}
              />
            )}
          />
        </div>

        <div className={cx('twoCol')}>
          <Controller
            name='payHour.minimumMinutes'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='country'
                label={t('billing_policy_pay_hours_calculation_bill_hours_minimum_minutes_title')}
                value={value || EMPTY_STRING}
                onChange={onChange}
                type={InputTypeEnum.NUMBER}
                textAlign='right'
              />
            )}
          />

          <div className={cx('colHolidayRate')}>
            <Controller
              name='payHour.isDefaultMinimumMinutes'
              control={control}
              render={({ field: { value, onChange } }) => (
                <CheckboxSingle
                  label={t('billing_policy_pay_hours_calculation_bill_hours_agency_default_title')}
                  value={value}
                  onChange={(checked: boolean, name?: string) => {
                    onChange(checked);
                  }}
                />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(PayHoursCalculationBillingPolicy);
