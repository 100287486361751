// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useState, useEffect, useMemo, useContext } from 'react';

// Components, Layouts, Pages
import { BasePagination, BaseTable, StatusLabel } from '~/components';

// Others
import { CLientStatusEnum, TranslationEnum, ViewCLientStatusEnum } from '~/utils/enum';
import { ColumnTableType, IListDataResponse } from '~/utils/interface/common';
import { convertEnumToString, formatPhoneNumber, getUserName } from '~/utils/helper';
import { DEFAULT_CURRENT_PAGE, DEFAULT_NUMBER_RECORD_TO_FETCH, EMPTY_STRING } from '~/utils/constants/common';
import { IListCaregiverApplicant, IParamsGetCaregiverApplicant } from '~/utils/interface/caregivers/referral';
import { useAppDispatch } from '~/redux/hooks';
import { LoadingData } from '~/context';
import { useSearchParams } from 'react-router-dom';
import { getListCaregiverApplicantReferred } from '~/thunks/caregivers/referral/referralThunk';

// Styles, images, icons
import styles from './CaregiverApplicationReferredTab.module.scss';

type Props = { referralId: string };

const cx = classNames.bind(styles);

const columns = (t: TFunction<TranslationEnum.TRANSLATION>): ColumnTableType<IListCaregiverApplicant>[] => {
  return [
    {
      key: 'name',
      title: t('caregiver_application_referred_tab_detail_name_title'),
      render(value, record, index) {
        return <>{getUserName(record?.firstName, record?.lastName) || EMPTY_STRING}</>;
      },
      width: '22%',
    },
    {
      key: 'referrer',
      title: t('caregiver_application_referred_tab_detail_referred_by_title'),
      render(value, record, index) {
        return (
          <>{getUserName(record?.referrer?.firstName, record?.referrer?.lastName) || record?.referrer?.organization}</>
        );
      },
      width: '21%',
    },
    {
      key: 'mobilePhone',
      title: t('caregiver_application_referred_tab_detail_home_phone_title'),
      render(value, record, index) {
        return <>{record?.mobilePhone ? formatPhoneNumber(record?.mobilePhone) : EMPTY_STRING}</>;
      },
      width: '21%',
    },
    {
      key: 'type',
      title: t('caregiver_application_referred_tab_detail_type_title'),
      render(value, record, index) {
        return <>{record.type ? convertEnumToString(record?.type) : EMPTY_STRING}</>;
      },
      width: '15%',
    },
    {
      key: 'status',
      title: t('caregiver_application_referred_tab_detail_status_title'),
      render(value, record, index) {
        if (!record.status) {
          return EMPTY_STRING;
        }

        let status = EMPTY_STRING;

        switch (record.status) {
          case CLientStatusEnum.ACTIVE:
            status = ViewCLientStatusEnum.ACTIVATE;
            break;
          case CLientStatusEnum.DEACTIVATE:
            status = ViewCLientStatusEnum.DEACTIVATE;
            break;
          default:
            status = record.status;
        }

        return <StatusLabel bgOpacity={0.1} key={index} label={status} />;
      },
      width: '15%',
    },
  ];
};

const CaregiverApplicationReferredTab = (props: Props) => {
  //#region Destructuring Props
  const { referralId } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(() => Number(params?.page ?? DEFAULT_CURRENT_PAGE), [params?.page]);
  const [listCaregiverApplicantReferred, setListCaregiverApplicantReferred] =
    useState<IListDataResponse<IListCaregiverApplicant[]>>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!referralId) return;

    const { tab, status, ...restParams } = params;

    const newParams: IParamsGetCaregiverApplicant = {
      ...restParams,
      params: {
        page: Number(params.page) || DEFAULT_CURRENT_PAGE,
        limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      },
      referralId: referralId,
    };

    handleGetListCaregiverApplicant(newParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListCaregiverApplicant = (params: IParamsGetCaregiverApplicant) => {
    loading?.show();

    dispatch(getListCaregiverApplicantReferred(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        setListCaregiverApplicantReferred(res.data);
      })
      .catch((err) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const handlePaginationChange = (page: number) => {
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };
  //#endregion Handle Function

  return (
    <div id='caregiverApplicationReferredTabComponent' className={cx('container')}>
      <div className={cx('tableContainer')}>
        <BaseTable columns={columns(t)} dataSource={listCaregiverApplicantReferred?.responses || []} />
      </div>
      <div className={cx('paginationTable')}>
        <BasePagination
          onChange={handlePaginationChange}
          defaultCurrentPage={pageSelected}
          totalItems={listCaregiverApplicantReferred?.pagination.totalItems}
          totalPages={listCaregiverApplicantReferred?.pagination.totalPages}
        />
      </div>
    </div>
  );
};

export default CaregiverApplicationReferredTab;
