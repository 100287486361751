// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { TFunction } from 'i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm, useWatch } from 'react-hook-form';
import moment from 'moment';

// Components, Layouts, Pages
import { BaseButton, BaseDatePicker, BaseSelectMultiple, Loading, Modal, ModalUnderDevelopment } from '~/components';

// Others
import { ButtonTypeEnum, CRMEnum, DateFormatEnum } from '~/utils/enum';
import { DEFAULT_CURRENT_PAGE, DEFAULT_LIMIT_MAX_ITEM, EMPTY_STRING } from '~/utils/constants/common';
import { useAppDispatch } from '~/redux/hooks';
import { IFormInvoice } from '~/utils/interface/invoice';
import { IBaseOptionMultiple, IListQueryParams } from '~/utils/interface/common';
import { getListLocations } from '~/thunks/crm/location/locationThunk';
import { IParamsClientsLocations } from '~/utils/interface/crm/clients';
import { getListClientByLocations } from '~/thunks/crm/clients/clientsThunk';
import { getUserName } from '~/utils/helper';
import { createInvoice } from '~/thunks/invoice/invoiceThunk';

// Styles, images, icons
import styles from './FormAddInvoiceModal.module.scss';

type Props = {
  isOpen: boolean;
  onClose?: () => void;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup.object({
    startDate: yup
      .string()
      .trim()
      .required(t('common_required_field'))
      .test('is-past-date', t('error_start_date_past_invoice'), (value) =>
        moment(value, DateFormatEnum.YYYY_MM_DD).isSameOrBefore(moment(), 'day')
      ),
    endDate: yup
      .string()
      .trim()
      .required(t('common_required_field'))
      .test('is-past-date', t('error_end_date_past_invoice'), (value) =>
        moment(value, DateFormatEnum.YYYY_MM_DD).isSameOrBefore(moment(), 'day')
      )
      .test('is-after-startDate', t('error_end_date_after_start_date_invoice'), function (value) {
        const { startDate } = this.parent;
        return moment(value, DateFormatEnum.YYYY_MM_DD).isSameOrAfter(
          moment(startDate, DateFormatEnum.YYYY_MM_DD),
          'day'
        );
      }),
    dueDate: yup
      .string()
      .trim()
      .required(t('common_required_field'))
      .test('is-future-date', t('error_due_date_valid'), function (value) {
        if (!value) return false;
        return moment(value, DateFormatEnum.YYYY_MM_DD).isSameOrAfter(moment(), 'day');
      }),
    locationId: yup.string().trim().optional(),
    clientId: yup.string().trim().optional(),
  });
};

const defaultValues: IFormInvoice = {
  startDate: EMPTY_STRING,
  endDate: EMPTY_STRING,
  dueDate: moment().format(DateFormatEnum.YYYY_MM_DD),
  locationIds: [],
  clientIds: [],
};

const FormAddInvoiceModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, onClose } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    trigger,
    formState: { errors, isDirty },
  } = useForm<IFormInvoice>({
    resolver: yupResolver(schema(t)),
    defaultValues: defaultValues,
  });
  //#endregion Declare Hook

  //#region Declare State
  const [isShowUnderDevelopment, setIsShowUnderDevelopment] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [locations, setLocations] = useState<IBaseOptionMultiple[]>([]);
  const [optionClient, setOptionClient] = useState<IBaseOptionMultiple[]>();

  const locationIdsState = useWatch({
    control,
    name: 'locationIds',
  });
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetListLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleGetListClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationIdsState]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleClose = () => {
    onClose?.();
    reset(defaultValues);
  };

  const handleSubmitInvoice = (data: IFormInvoice) => {
    setIsLoading(true);

    dispatch(createInvoice(data))
      .unwrap()
      .then((res) => {
        handleClose();
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleShowDevelopment = () => {
    setIsShowUnderDevelopment(!isShowUnderDevelopment);
  };

  const handleGetListLocations = () => {
    const params: IListQueryParams = { page: DEFAULT_CURRENT_PAGE, limit: DEFAULT_LIMIT_MAX_ITEM };
    dispatch(getListLocations(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        const listLocation = res.data?.responses?.map((data) => {
          return { label: data.name || EMPTY_STRING, value: data.id };
        });
        setLocations(listLocation);
      })
      .catch((err) => {})
      .finally(() => {});
  };

  const handleGetListClients = () => {
    const params: IParamsClientsLocations = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
      locationIds: locationIdsState,
      type: CRMEnum.CLIENT,
    };

    dispatch(getListClientByLocations(params))
      .unwrap()
      .then((res) => {
        const { responses } = res;

        const listClient = responses.map((data) => {
          return { label: getUserName(data.firstName, data.lastName), value: data.id };
        });

        setOptionClient(listClient);
      })
      .catch((error) => {});
  };
  //#endregion Handle Function

  return (
    <Modal isOpen={isOpen} onClose={handleClose} title={t('form_add_invoice_label')}>
      <form id='formAddInvoiceModalComponent' className={cx('container')} onSubmit={handleSubmit(handleSubmitInvoice)}>
        <div className={cx('contentModal')}>
          <div className={cx('threeCol')}>
            <Controller
              name={'startDate'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseDatePicker
                  name={'startDate'}
                  label={t('form_add_invoice_start_date_label')}
                  placeholderText={t('common_placeholder_select')}
                  onDateSelected={(date) => {
                    onChange(date);
                    getValues('endDate') && trigger('endDate');
                  }}
                  value={value}
                  errorMessage={errors.startDate?.message}
                  required
                />
              )}
            />

            <Controller
              name={'endDate'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseDatePicker
                  name={'endDate'}
                  label={t('form_add_invoice_end_date_label')}
                  placeholderText={t('common_placeholder_select')}
                  onDateSelected={(date) => {
                    onChange(date);
                    getValues('startDate') && trigger('startDate');
                  }}
                  value={value}
                  errorMessage={errors.endDate?.message}
                  required
                />
              )}
            />

            <Controller
              name={'dueDate'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseDatePicker
                  name={'dueDate'}
                  label={t('form_add_invoice_due_date_label')}
                  placeholderText={t('common_placeholder_select')}
                  onDateSelected={onChange}
                  value={value || moment().format(DateFormatEnum.YYYY_MM_DD)}
                  errorMessage={errors.dueDate?.message}
                  required
                />
              )}
            />
          </div>

          <div className={cx('twoCol')}>
            <Controller
              name='locationIds'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseSelectMultiple
                  value={value}
                  options={locations ?? []}
                  label={t('form_add_invoice_location_label')}
                  placeholder={t('common_select_placeholder')}
                  onChange={onChange}
                  mode='search'
                />
              )}
            />

            <Controller
              name='clientIds'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseSelectMultiple
                  value={value}
                  options={optionClient ?? []}
                  label={t('form_add_invoice_client_label')}
                  placeholder={t('common_select_placeholder')}
                  onChange={onChange}
                  mode='search'
                />
              )}
            />
          </div>
        </div>

        <div className={cx('footerModal')}>
          <BaseButton text={t('common_cancel_label')} width={65} onClick={handleClose} type='button' />
          <BaseButton text={t('common_save_label')} typeStyle={ButtonTypeEnum.PRIMARY} width={80} type='submit' />
        </div>
      </form>

      {isLoading && <Loading />}
      {isShowUnderDevelopment && <ModalUnderDevelopment onClose={handleShowDevelopment} />}
    </Modal>
  );
};

export default FormAddInvoiceModal;
