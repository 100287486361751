// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import {
  CHANGE_STAGE_CLIENT,
  CHANGE_STATUS_CLIENT,
  CREATE_CARE_PLAN,
  CREATE_CLIENT,
  GET_DETAIL_CARE_PLAN,
  GET_DETAIL_CLIENT,
  GET_LIST_CLIENT,
  GET_LIST_CLIENT_BY_CONTACT,
  GET_LIST_CLIENT_BY_LOCATIONS,
  GET_LIST_RELATES,
  SEND_EMAIL_TO_CLIENT,
  SEND_TO_QUICK_BOCKS_CLIENT,
  UPDATE_CLIENT,
} from '~/utils/constants/actionType';
import {
  IFormUpdateClient,
  IQuerySendEmailToClient,
  IPayloadChangeStatusClient,
  IRelatesQueryParams,
  IListQueryParamsClients,
  IParamsGetListClientByContact,
  IParamsClientsLocations,
  IChangeStageProspect,
} from '~/utils/interface/crm/clients';
import clientsApi from '~/apis/crm/clients';
import { IPayloadCarePlan } from '~/utils/interface/crm/carePlan';

export const createClients = createAsyncThunk(CREATE_CLIENT, async (body: FormData, { rejectWithValue }) => {
  try {
    const res = await clientsApi.createClient(body);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getListClients = createAsyncThunk(
  GET_LIST_CLIENT,
  async (payload: IListQueryParamsClients, { rejectWithValue }) => {
    try {
      const res = await clientsApi.getListClient(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDetailClients = createAsyncThunk(GET_DETAIL_CLIENT, async (clientId: string, { rejectWithValue }) => {
  try {
    const res = await clientsApi.getDetailClient(clientId);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateClient = createAsyncThunk(UPDATE_CLIENT, async (body: IFormUpdateClient, { rejectWithValue }) => {
  try {
    const res = await clientsApi.updateClient(body);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getListRelates = createAsyncThunk(
  GET_LIST_RELATES,
  async (payload: IRelatesQueryParams, { rejectWithValue }) => {
    try {
      const res = await clientsApi.getListRelates(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendEmailToClient = createAsyncThunk(
  SEND_EMAIL_TO_CLIENT,
  async (payload: IQuerySendEmailToClient, { rejectWithValue }) => {
    try {
      const res = await clientsApi.sendEmailToClient(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changeStatusClient = createAsyncThunk(
  CHANGE_STATUS_CLIENT,
  async (payload: IPayloadChangeStatusClient, { rejectWithValue }) => {
    try {
      const res = await clientsApi.changeStatusClient(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changeStageClient = createAsyncThunk(
  CHANGE_STAGE_CLIENT,
  async ({ clientId, body }: { clientId: string; body: IChangeStageProspect }, { rejectWithValue }) => {
    try {
      const res = await clientsApi.changeStageProspect({ clientId: clientId, body: body });
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDetailCarePlan = createAsyncThunk(
  GET_DETAIL_CARE_PLAN,
  async (clientId: string, { rejectWithValue }) => {
    try {
      const res = await clientsApi.getDetailCarePlan(clientId);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createCarePlan = createAsyncThunk(
  CREATE_CARE_PLAN,
  async (payload: IPayloadCarePlan, { rejectWithValue }) => {
    try {
      const res = await clientsApi.createCarePlan(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListClientByContact = createAsyncThunk(
  GET_LIST_CLIENT_BY_CONTACT,
  async (payload: IParamsGetListClientByContact, { rejectWithValue }) => {
    try {
      const res = await clientsApi.getListClientByContact(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListClientByLocations = createAsyncThunk(
  GET_LIST_CLIENT_BY_LOCATIONS,
  async (payload: IParamsClientsLocations, { rejectWithValue }) => {
    try {
      const res = await clientsApi.getListClientByLocations(payload);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendToQuickBocksClient = createAsyncThunk(
  SEND_TO_QUICK_BOCKS_CLIENT,
  async (clientId: string, { rejectWithValue }) => {
    try {
      const res = await clientsApi.sendToQBClient(clientId);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
