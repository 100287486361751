import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '~/redux/store';
import { createInitialCall, getDetailInitialCall, getListInitialCall } from './initialCallThunk';

export interface InitialCallState {
  isRefreshListInitialCall: boolean;
}

const initialState: InitialCallState = {
  isRefreshListInitialCall: false,
};

const initialCallSlice = createSlice({
  name: 'initialCallState',
  initialState,
  reducers: {},

  extraReducers(builder) {
    // Get List Initial Call
    builder
      .addCase(getListInitialCall.pending, (state) => {})
      .addCase(getListInitialCall.fulfilled, (state) => {
        state.isRefreshListInitialCall = false;
      })
      .addCase(getListInitialCall.rejected, (state) => {});

    // Create initialCall
    builder
      .addCase(createInitialCall.pending, (state) => {})
      .addCase(createInitialCall.fulfilled, (state) => {})
      .addCase(createInitialCall.rejected, (state) => {});

    // Get Detail initialCall
    builder
      .addCase(getDetailInitialCall.pending, (state) => {})
      .addCase(getDetailInitialCall.fulfilled, (state) => {})
      .addCase(getDetailInitialCall.rejected, (state) => {});
  },
});

// Actions
export const initialCallActions = initialCallSlice.actions;

// Selectors
export const selectIsRefreshInitialCall = (state: RootState) => state.initialCallState.isRefreshListInitialCall;

// Reducer
const initialCallReducer = initialCallSlice.reducer;
export default initialCallReducer;
