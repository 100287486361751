// Libs
import classNames from 'classnames/bind';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, memo, useEffect, useState } from 'react';
// Components, Layouts, Pages
import { BaseSelect, BaseInput, PlacesAutocomplete } from '~/components';
// Others
import { IAddress, IBaseOption, IListDataResponse } from '~/utils/interface/common';
import { useAppDispatch } from '~/redux/hooks';
import { getListClients } from '~/thunks/crm/clients/clientsThunk';
import { IListClient, IListQueryParamsClients } from '~/utils/interface/crm/clients';
import { IFormInitialCall } from '~/utils/interface/crm/initialCall';
import {
  callerTypeInitialCallOptions,
  contactTypeCallerInformation,
  DEFAULT_CURRENT_PAGE,
  DEFAULT_LIMIT_MAX_ITEM,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { CRMEnum, InitialCallFormEnum, ReferralRoleEnum, StatusEnum } from '~/utils/enum';
import { formatPhoneNumber, getUserName } from '~/utils/helper';
import { IContact, IListQueryParamsContacts } from '~/utils/interface/crm/contact';
import { getListContact } from '~/thunks/crm/contact/contactThunk';

// Styles, images, icons
import styles from './CallerInformation.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const CallerInformationInitialCall = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    control,
    setValue,
    resetField,
    getValues,
    formState: { errors },
  } = useFormContext<IFormInitialCall>();

  const callerType = useWatch({ control: control, name: 'caller.addType' });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [referralsList, setReferralsList] = useState<IBaseOption[]>([]);
  const [existingContactList, setExistingContactList] = useState<IBaseOption[]>([]);
  //#endregion Declare State

  //#region Implement Hook

  useEffect(() => {
    if (callerType === InitialCallFormEnum.ADD_NEW && referralsList.length === DEFAULT_NUMBER_ZERO) {
      handleGetOrganization();
    }

    if (callerType === InitialCallFormEnum.SELECT_EXISTING && existingContactList.length === DEFAULT_NUMBER_ZERO) {
      handleGetExistingContact();
    }

    resetField('caller', {
      defaultValue: {
        addType: callerType,
        contactType: getValues('caller.contactType'),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callerType]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleChangeAddress = (address: IAddress) => {
    const fieldsToUpdate = [
      { key: 'caller.address', value: address?.address || EMPTY_STRING },
      { key: 'caller.city', value: address?.city || EMPTY_STRING },
      { key: 'caller.state', value: address?.state || EMPTY_STRING },
      { key: 'caller.postalCode', value: address?.zipCode || EMPTY_STRING },
    ] as const;

    fieldsToUpdate.forEach(({ key, value }) => {
      if (value) {
        setValue(key, value, { shouldDirty: true });
      }
    });

    setValue('caller.lat', Number(address?.lat));
    setValue('caller.lng', Number(address?.lng));
  };

  const handleGetExistingContact = () => {
    const params: IListQueryParamsContacts = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
    };

    dispatch(getListContact(params))
      .unwrap()
      .then((res) => {
        const { responses }: IListDataResponse<IContact[]> = res?.data;

        if (responses.length === 0) return;

        const optionContacts = responses.map((res) => {
          return {
            label: res?.organization ? res.organization : getUserName(res?.firstName, res?.lastName),
            value: res.id,
          };
        });

        setExistingContactList(optionContacts);
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const handleGetOrganization = () => {
    const params: IListQueryParamsClients = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
      type: [CRMEnum.REFERRAL],
      referralRole: ReferralRoleEnum.ORGANIZATION,
      status: StatusEnum.ACTIVE,
    };

    dispatch(getListClients(params))
      .unwrap()
      .then((res) => {
        const { responses }: IListDataResponse<IListClient[]> = res?.data;
        const optionReferral = responses.map((res) => {
          return {
            label: res.organizationName || EMPTY_STRING,
            value: res.id,
          };
        });

        setReferralsList(optionReferral);
      })
      .catch((error) => {})
      .finally(() => {});
  };
  //#endregion Handle Function

  return (
    <div id='callerInformationInitialCall' className={cx('callerInformationInitialCall')}>
      <div className={cx('headTitle')}>{t('form_initial_call_section_caller_information_title')}</div>

      <div className={cx('bodyWrap')}>
        <div className={cx('twoCol')}>
          <Controller
            control={control}
            name='caller.addType'
            render={({ field: { value, onChange } }) => (
              <BaseSelect
                placeholder={t('common_select_placeholder')}
                label={t('form_initial_call_section_caller_information_caller_type_label')}
                value={value || EMPTY_STRING}
                onChange={({ value }) => onChange(value)}
                options={callerTypeInitialCallOptions}
                errorMessage={errors.caller?.addType?.message}
                required
              />
            )}
          />

          {callerType === InitialCallFormEnum.SELECT_EXISTING && (
            <Controller
              control={control}
              name='caller.id'
              render={({ field: { value, onChange } }) => (
                <BaseSelect
                  placeholder={t('common_select_placeholder')}
                  label={t('form_initial_call_section_caller_information_existing_contact_label')}
                  options={existingContactList ?? []}
                  value={value || EMPTY_STRING}
                  onChange={({ value }) => onChange(value)}
                  required
                  errorMessage={errors?.caller?.id?.message}
                  mode='search'
                />
              )}
            />
          )}
        </div>

        {callerType !== InitialCallFormEnum.SELF && (
          <Controller
            control={control}
            name='caller.contactType'
            render={({ field: { value, onChange } }) => (
              <BaseSelect
                placeholder={t('common_select_placeholder')}
                label={t('form_initial_call_section_caller_information_contact_type_label')}
                options={contactTypeCallerInformation}
                value={value || EMPTY_STRING}
                onChange={({ value }) => onChange(value)}
                required
                errorMessage={errors?.caller?.contactType?.message}
              />
            )}
          />
        )}

        {callerType === InitialCallFormEnum.ADD_NEW && (
          <>
            <div className={cx('threeCol')}>
              <Controller
                control={control}
                name='caller.firstName'
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='caller.firstName'
                    label={t('form_initial_call_section_caller_information_first_name_label')}
                    value={value || EMPTY_STRING}
                    onChange={onChange}
                    messageError={errors.caller?.firstName?.message}
                    required
                  />
                )}
              />

              <Controller
                control={control}
                name='caller.lastName'
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='caller.lastName'
                    label={t('form_initial_call_section_caller_information_last_name_label')}
                    value={value || EMPTY_STRING}
                    onChange={onChange}
                    messageError={errors.caller?.lastName?.message}
                    required
                  />
                )}
              />

              <Controller
                control={control}
                name='caller.organizationId'
                render={({ field: { value, onChange } }) => (
                  <BaseSelect
                    placeholder={t('common_select_placeholder')}
                    label={t('form_initial_call_section_caller_information_organization_label')}
                    options={referralsList || []}
                    value={value || EMPTY_STRING}
                    onChange={({ value }) => onChange(value)}
                    mode='search'
                  />
                )}
              />
            </div>

            <div className={cx('threeCol')}>
              <Controller
                control={control}
                name='caller.homePhone'
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='caller.homePhone'
                    label={t('form_initial_call_section_caller_information_home_phone_label')}
                    value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const { value } = event.target;
                      const formattedValue = formatPhoneNumber(value);
                      onChange(formattedValue);
                    }}
                    messageError={errors.caller?.homePhone?.message}
                  />
                )}
              />

              <Controller
                control={control}
                name='caller.workPhone'
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='caller.workPhone'
                    label={t('form_initial_call_section_caller_information_work_phone_label')}
                    value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const { value } = event.target;
                      const formattedValue = formatPhoneNumber(value);
                      onChange(formattedValue);
                    }}
                    messageError={errors.caller?.workPhone?.message}
                  />
                )}
              />

              <Controller
                control={control}
                name='caller.mobilePhone'
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='caller.mobilePhone'
                    label={t('form_initial_call_section_caller_information_mobile_phone_label')}
                    value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const { value } = event.target;
                      const formattedValue = formatPhoneNumber(value);
                      onChange(formattedValue);
                    }}
                    messageError={errors.caller?.mobilePhone?.message}
                  />
                )}
              />
            </div>

            <div className={cx('threeCol')}>
              <Controller
                control={control}
                name='caller.email'
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='caller.email'
                    label={t('form_initial_call_section_caller_information_email_label')}
                    value={value || EMPTY_STRING}
                    onChange={onChange}
                    messageError={errors.caller?.email?.message}
                  />
                )}
              />

              <Controller
                name='caller.address'
                control={control}
                render={({ field: { value } }) => (
                  <PlacesAutocomplete
                    id='address'
                    value={value || EMPTY_STRING}
                    label={t('form_initial_call_section_caller_information_address_label')}
                    onChange={(address) => handleChangeAddress(address)}
                    messageError={errors.caller?.address?.message}
                  />
                )}
              />

              <Controller
                name='caller.secondaryAddress'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='secondaryAddress'
                    value={value || EMPTY_STRING}
                    label={t('form_initial_call_section_caller_information_secondary_address_label')}
                    onChange={onChange}
                    messageError={errors.caller?.secondaryAddress?.message}
                  />
                )}
              />
            </div>

            <div className={cx('threeCol')}>
              <Controller
                name='caller.city'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='caller.city'
                    value={value || EMPTY_STRING}
                    label={t('form_initial_call_section_caller_information_city_label')}
                    onChange={onChange}
                    messageError={errors.caller?.city?.message}
                  />
                )}
              />

              <Controller
                name='caller.state'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='caller.state'
                    value={value || EMPTY_STRING}
                    label={t('form_initial_call_section_caller_information_state_label')}
                    onChange={onChange}
                    messageError={errors.caller?.state?.message}
                  />
                )}
              />

              <Controller
                name='caller.postalCode'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='caller.postalCode'
                    value={value || EMPTY_STRING}
                    label={t('form_initial_call_section_caller_information_postal_code_label')}
                    onChange={onChange}
                    messageError={errors.caller?.postalCode?.message}
                  />
                )}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default memo(CallerInformationInitialCall);
