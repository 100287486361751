// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { TFunction } from 'i18next';
import { useSearchParams } from 'react-router-dom';

// Components, Layouts, Pages
import {
  BaseButton,
  BasePagination,
  BaseTable,
  FormAddEmailTemplateModal,
  ModalUnderDevelopment,
  ThreeDotTableOptions,
  ToolBar,
} from '~/components';

// Others
import { DEFAULT_CURRENT_PAGE, DEFAULT_NUMBER_RECORD_TO_FETCH, EMPTY_STRING } from '~/utils/constants/common';
import { AccountRoleCodesEnum, ButtonTypeEnum, StorageEnum, TimeFormatEnum, TranslationEnum } from '~/utils/enum';
import { ColumnTableType, IPaginationResponse } from '~/utils/interface/common';
import { IEmailTemplate, IQueryParamsEmailTemplate } from '~/utils/interface/emailTemplate';
import { convertDateToFormatTime } from '~/utils/helper';
import { LoadingData } from '~/context';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { getListEmailTemplate } from '~/thunks/emailTemplate/emailThunk';

// Styles, images, icons
import styles from './EmailTemplateTab.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction<TranslationEnum.TRANSLATION>,
  handleEditEmailTemplate: (data: IEmailTemplate) => void,
  handleDeleteEmailTemplate: (id: string) => void,
  role: string | null
): ColumnTableType<IEmailTemplate>[] => {
  return [
    {
      key: 'name',
      title: t('email_template_name_title'),
      dataIndex: 'name',
      render(value, record, index) {
        return <>{record.name ? record.name : EMPTY_STRING}</>;
      },
      width: '50%',
    },

    {
      key: 'createdAt',
      title: t('email_template_create_at_title'),
      dataIndex: 'createdAt',
      render(value, record, index) {
        return (
          <>{record.createdAt ? convertDateToFormatTime(record.createdAt, TimeFormatEnum.MM_DD_YYYY) : EMPTY_STRING}</>
        );
      },
      width: '40%',
    },
    ...(role === AccountRoleCodesEnum.SUPER_ADMIN
      ? [
          {
            key: 'action',
            title: t('common_text_action'),
            render: (value: string | undefined, record: IEmailTemplate) => {
              return (
                <ThreeDotTableOptions
                  data={record}
                  permissions={{
                    isView: false,
                    isDelete: false,
                  }}
                  onEdit={() => handleEditEmailTemplate(record)}
                  // onDelete={() => handleDeleteEmailTemplate(record.id)}
                />
              );
            },
            width: '10%',
          },
        ]
      : []),
  ];
};

const EmailTemplateTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const role = localStorage.getItem(StorageEnum.ROLE);
  //#endregion Declare Hook

  //#region Selector
  const { isRefreshEmailTemplateList } = useAppSelector((state) => state.emailTemplateState);

  //#endregion Selector

  //#region Declare State
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  const [isShowFormAddTemplateModal, setIsShowFormAddTemplateModal] = useState<boolean>(false);
  const [pagination, setPagination] = useState<IPaginationResponse>();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(() => Number(params?.page ?? DEFAULT_CURRENT_PAGE), [params?.page]);
  const [listEmailTemplate, setListEmailTemplate] = useState<IEmailTemplate[]>([]);
  const [emailTemplate, setEmailTemplate] = useState<IEmailTemplate | null>(null);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const { tab, ...restParams } = params;

    const newParams = {
      ...restParams,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
    };

    handleGetListEmailTemplate(newParams);
  }, [params]);

  useEffect(() => {
    if (!isRefreshEmailTemplateList) return;

    const { tab, ...restParams } = params;

    const newParams = {
      ...restParams,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
    };

    handleGetListEmailTemplate(newParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshEmailTemplateList]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleClickUnderDevelop = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handlePaginationChange = (page: number) => {
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleAddEmailTemplate = () => {
    setIsShowFormAddTemplateModal(true);
  };

  const handleEditEmailTemplate = (data: IEmailTemplate) => {
    if (role !== AccountRoleCodesEnum.SUPER_ADMIN) return;
    setEmailTemplate(data);
    setIsShowFormAddTemplateModal(true);
  };

  const handleDeleteEmailTemplate = (id: string) => {
    setIsShowModalUnderDevelopment(true);
  };

  const handleCloseAddStage = () => {
    setIsShowFormAddTemplateModal(false);
  };

  const handleGetListEmailTemplate = (params: IQueryParamsEmailTemplate) => {
    loading?.show();

    dispatch(getListEmailTemplate(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        const { responses, pagination } = res?.data;

        setListEmailTemplate(responses);
        setPagination(pagination);
      })
      .catch((error) => {})
      .finally(() => loading?.hide());
  };

  const handleOnClickRow = (item: IEmailTemplate) => {
    if (role === AccountRoleCodesEnum.SUPER_ADMIN) {
      setIsShowFormAddTemplateModal(true);
      setEmailTemplate(item);
    }
  };
  //#endregion Handle Function

  return (
    <div id='stageTabComponent' className={cx('container')}>
      <ToolBar title={t('email_template_tab_title')}>
        {role === AccountRoleCodesEnum.SUPER_ADMIN && (
          <BaseButton
            typeStyle={ButtonTypeEnum.PRIMARY}
            iconLeft={icons.commonIconPlus}
            text={t('email_template_tab_add_template_title')}
            onClick={handleAddEmailTemplate}
          />
        )}
      </ToolBar>

      <div className={cx('content')}>
        <div className={cx('tableWrap')}>
          <BaseTable
            columns={columns(t, handleEditEmailTemplate, handleDeleteEmailTemplate, role)}
            dataSource={listEmailTemplate ?? []}
            onClickRow={role === AccountRoleCodesEnum.SUPER_ADMIN ? handleOnClickRow : undefined}
          />
        </div>

        <div className={cx('paginationTable')}>
          <BasePagination
            onChange={handlePaginationChange}
            defaultCurrentPage={pageSelected}
            totalItems={pagination?.totalItems}
            totalPages={pagination?.totalPages}
          />
        </div>
      </div>

      {isShowFormAddTemplateModal && (
        <FormAddEmailTemplateModal
          isOpen={isShowFormAddTemplateModal}
          emailTemplate={emailTemplate}
          setEmailSelected={setEmailTemplate}
          onClose={handleCloseAddStage}
        />
      )}

      {isShowModalUnderDevelopment && <ModalUnderDevelopment onClose={handleClickUnderDevelop} />}
    </div>
  );
};

export default EmailTemplateTab;
