// Libs
import { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { TFunction } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
// Components, Layouts, Pages
import {
  BaseButton,
  BaseDatePicker,
  BaseInput,
  BaseSelect,
  BaseTextarea,
  BaseUploadImage,
  ModalUnderDevelopment,
  PlacesAutocomplete,
} from '~/components';
// Others
import { useAppDispatch } from '~/redux/hooks';
import { LoadingData } from '~/context';
import { createCaregiver, editCaregiver, getDetailCaregiver } from '~/thunks/caregiver/caregiverThunk';
import { getListLocations } from '~/thunks/crm/location/locationThunk';
import { IAddress, IBaseOption, IListQueryParams, IQueryBase } from '~/utils/interface/common';
import { IFormCaregiver } from '~/utils/interface/caregiver';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_LIMIT_MAX_ITEM,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  MAX_FILE_SIZE_FILE_20MB,
  linkToCaregiverListOptions,
  NAVIGATE_BACK,
  RegExp,
} from '~/utils/constants/common';
import {
  AddCaregiverNameModalEnum,
  ButtonTypeEnum,
  DateFormatEnum,
  LinkToCaregiverListEnum,
  ReferrerTypeEnum,
  StatusEnum,
} from '~/utils/enum';
import { createFormData, formatPhoneNumber, getUserName, removeEmptyObjects } from '~/utils/helper';
import { IQueryCaregiverReferral } from '~/utils/interface/caregivers/referral';
import { getListCaregiverReferral } from '~/thunks/caregivers/referral/referralThunk';
import { getListCaregiverList } from '~/thunks/caregiverList/caregiverListThunk';
import { ICaregiverList } from '~/utils/interface/caregiverList';
// Styles, images, icons
import styles from './AddCaregiver.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const schema = (t: TFunction, isEditMode: boolean) => {
  return yup.object({
    typeLinkCaregiverList: yup
      .string()
      .optional()
      .when([], {
        is: () => isEditMode !== true,
        then: (schema) =>
          schema.notOneOf(
            [EMPTY_STRING],
            t('common_error_message_required', {
              message: t('form_caregiver_type_link_caregiver_list_label'),
            })
          ),
        otherwise: (schema) => schema.optional(),
      }),
    caregiverListId: yup.string().when('typeLinkCaregiverList', {
      is: (value: string) => value === LinkToCaregiverListEnum.ADD_EXISTING && !isEditMode,
      then: (schema) =>
        schema.required(
          t('common_error_message_required', {
            message: t('add_caregiver_choose_caregiver_label'),
          })
        ),
      otherwise: (schema) => schema.optional(),
    }),

    profileImage: yup
      .array()
      .of(
        yup.object({
          file: yup
            .mixed<File>()
            .optional()
            .test('fileSize', t('add_caregiver_files_size_limit'), (value) => {
              if (!value) return true;
              return value instanceof File && value.size <= MAX_FILE_SIZE_FILE_20MB;
            }),
        })
      )
      .optional(),
    firstName: yup.string().trim().required(t('common_required_field')),
    middleName: yup.string().trim().optional(),
    lastName: yup.string().trim().required(t('common_required_field')),
    address: yup.string().trim().required(t('common_required_field')),
    secondaryAddress: yup.string().trim().optional(),
    city: yup.string().trim().required(t('common_required_field')),
    state: yup.string().trim().required(t('common_required_field')),
    postalCode: yup.string().trim().required(t('common_required_field')),
    notes: yup.string().trim().optional(),
    hireDate: yup.string().trim().required(t('common_required_field')),

    ssn: yup.string().trim().required(t('common_required_field')),
    homePhone: yup.string().trim().optional(),
    mobilePhone: yup.string().trim().required(t('common_required_field')),
    workPhone: yup.string().trim().optional(),
    email: yup
      .string()
      .trim()
      .required(t('common_required_field'))
      .test('isValidEmail', t('common_validate_invalid_email'), (value) => {
        if (value && !RegExp.EMAIL.test(value)) return false;
        return true;
      }),
    locationIds: yup
      .array(yup.string().required(t('common_required_field')))
      .min(1, t('common_required_field'))
      .required(),
    referrerId: yup.string().trim().required(t('common_required_field')),
    npi: yup.string().trim().optional(),
    externalId: yup.string().trim().required(t('common_required_field')),
    isBackgroundCheck: yup.boolean().optional(),
    isDeleteAvatar: yup.boolean().optional(),
  });
};

const defaultValues: IFormCaregiver = {
  typeLinkCaregiverList: LinkToCaregiverListEnum.NO,
  caregiverListId: EMPTY_STRING,
  profileImage: [],
  firstName: EMPTY_STRING,
  middleName: EMPTY_STRING,
  lastName: EMPTY_STRING,
  address: EMPTY_STRING,
  secondaryAddress: EMPTY_STRING,
  city: EMPTY_STRING,
  state: EMPTY_STRING,
  postalCode: EMPTY_STRING,
  notes: EMPTY_STRING,
  hireDate: moment().format(DateFormatEnum.YYYY_MM_DD),
  ssn: EMPTY_STRING,
  homePhone: EMPTY_STRING,
  mobilePhone: EMPTY_STRING,
  workPhone: EMPTY_STRING,
  email: EMPTY_STRING,
  locationIds: [],
  referrerId: EMPTY_STRING,
  npi: EMPTY_STRING,
  externalId: EMPTY_STRING,
  isBackgroundCheck: false,
  lat: EMPTY_STRING,
  lng: EMPTY_STRING,
};

const AddCaregiver = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loadingData = useContext(LoadingData);
  const { caregiverId } = useParams();
  const isEditMode = useMemo(() => !!caregiverId, [caregiverId]);
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useForm<IFormCaregiver>({
    resolver: yupResolver(schema(t, isEditMode)),
    defaultValues: defaultValues,
  });

  const typeLinkCaregiverList = watch('typeLinkCaregiverList');
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isUnderDevelopment, setIsUnderDevelopment] = useState<boolean>(false);
  const [locations, setLocations] = useState<IBaseOption[]>([]);
  const [caregiverReferralList, setCaregiverReferralList] = useState<IBaseOption[]>([]);
  const [isLoadingCaregiverList, setIsLoadingCaregiverList] = useState<boolean>(false);
  const [caregiverList, setCaregiverList] = useState<ICaregiverList[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  const optionCaregiverList: IBaseOption[] = useMemo(() => {
    const options: IBaseOption[] = caregiverList.map((item) => {
      return {
        label: getUserName(item.firstName, item.middleName, item.lastName) || EMPTY_STRING,
        value: item.id.toString(),
      };
    });
    return options;
  }, [caregiverList]);

  useEffect(() => {
    if (!typeLinkCaregiverList) return;

    if (typeLinkCaregiverList !== LinkToCaregiverListEnum.ADD_EXISTING) {
      setValue('caregiverListId', EMPTY_STRING, { shouldDirty: true });
      return;
    }
    handleGetListCaregiverList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeLinkCaregiverList]);

  useEffect(() => {
    handleGetListLocations();
    handleGetListReferral();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!caregiverId) return;

    handleGetCaregiverDetail(caregiverId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caregiverId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListCaregiverList = () => {
    const payload: IQueryBase = {
      page: DEFAULT_CURRENT_PAGE,
      limit: 100,
    };

    setIsLoadingCaregiverList(true);
    dispatch(getListCaregiverList(payload))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        const { responses } = res.data;
        setCaregiverList(responses);
      })
      .catch((err) => {})
      .finally(() => setIsLoadingCaregiverList(false));
  };

  const handleGetCaregiverDetail = (caregiverId: string) => {
    loadingData?.show();

    dispatch(getDetailCaregiver(caregiverId))
      .unwrap()
      .then((res) => {
        if (!res || !res?.data) return;

        const data = res?.data;

        reset({
          profileImage: data?.avatarUrl ? [{ url: data?.avatarUrl }] : undefined,
          firstName: data?.firstName || EMPTY_STRING,
          middleName: data?.middleName || EMPTY_STRING,
          lastName: data?.lastName || EMPTY_STRING,
          address: data?.address || EMPTY_STRING,
          secondaryAddress: data?.secondaryAddress || EMPTY_STRING,
          city: data?.city || EMPTY_STRING,
          state: data?.state || EMPTY_STRING,
          postalCode: data?.postalCode || EMPTY_STRING,
          notes: data?.notes || EMPTY_STRING,
          hireDate: data?.hireDate || moment().format(DateFormatEnum.YYYY_MM_DD),
          ssn: data?.ssn || EMPTY_STRING,
          homePhone: data?.homePhone || EMPTY_STRING,
          mobilePhone: data?.mobilePhone || EMPTY_STRING,
          workPhone: data?.workPhone || EMPTY_STRING,
          email: data?.email || EMPTY_STRING,
          locationIds: data?.locations?.map((location) => location?.id) || [],
          referrerId: data?.referrer.id,
          npi: data?.npi || EMPTY_STRING,
          externalId: data?.externalId || EMPTY_STRING,
          isBackgroundCheck: data?.isBackgroundCheck || false,
          caregiverListId: data?.caregiverListId || EMPTY_STRING,
          typeLinkCaregiverList: data?.caregiverListId
            ? LinkToCaregiverListEnum.ADD_EXISTING
            : LinkToCaregiverListEnum.NO,
        });
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleCloseUnderDevelopment = () => {
    setIsUnderDevelopment(false);
  };

  const handleBackToPreviousPage = () => {
    navigate(NAVIGATE_BACK);
  };

  const handleCreateCaregiver = async (data: IFormCaregiver) => {
    loadingData?.show();
    const { typeLinkCaregiverList, ...restData } = data;
    const newData = {
      ...restData,

      homePhone: restData.homePhone?.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING),
      mobilePhone: restData.mobilePhone?.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING),
      workPhone: restData.workPhone?.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING),
    };

    const dataClean = removeEmptyObjects(newData) as IFormCaregiver;
    const formData = await createFormData(dataClean, AddCaregiverNameModalEnum.PROFILE_IMAGE);

    dispatch(createCaregiver(formData))
      .unwrap()
      .then((res) => {
        handleResetFormCaregiver();

        handleBackToPreviousPage();
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleUpdateCaregiver = async (data: IFormCaregiver) => {
    if (!caregiverId) return;
    const { typeLinkCaregiverList, ...restData } = data;
    loadingData?.show();
    const newData = {
      ...restData,
      ...(caregiverId && {
        isDeleteAvatar:
          !Array.isArray(restData?.profileImage) || restData?.profileImage?.length === DEFAULT_NUMBER_ZERO,
      }),
      homePhone: restData.homePhone?.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING),
      mobilePhone: restData.mobilePhone?.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING),
      workPhone: restData.workPhone?.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING),
    };

    const formData = await createFormData(newData, AddCaregiverNameModalEnum.PROFILE_IMAGE);

    dispatch(editCaregiver({ caregiverId, body: formData }))
      .unwrap()
      .then((res) => {
        handleResetFormCaregiver();

        handleBackToPreviousPage();
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleSubmitFormCaregiver = async (data: IFormCaregiver) => {
    if (caregiverId) {
      handleUpdateCaregiver(data);
    } else {
      handleCreateCaregiver(data);
    }
  };

  const handleResetFormCaregiver = () => {
    reset(defaultValues);
  };

  const handleChangeAddress = (address: IAddress) => {
    setValue('address', address?.address ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    address?.city && setValue('city', address?.city ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    address?.state && setValue('state', address?.state ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    address?.zipCode &&
      setValue('postalCode', address?.zipCode ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    setValue('lat', address?.lat ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    setValue('lng', address?.lng ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
  };

  const handleGetListReferral = () => {
    const params: IQueryCaregiverReferral = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
      status: StatusEnum.ACTIVE,
    };

    dispatch(getListCaregiverReferral(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        const listCaregiverReferral = res.data?.responses?.map((data) => ({
          label:
            data?.organizationName || getUserName(data?.firstName, data?.middleName, data?.lastName) || EMPTY_STRING,
          value: data.id,
        }));

        setCaregiverReferralList(listCaregiverReferral);
      })
      .catch((err) => {})
      .finally(() => {});
  };

  const handleGetListLocations = () => {
    const params: IListQueryParams = { page: DEFAULT_CURRENT_PAGE, limit: DEFAULT_LIMIT_MAX_ITEM };
    dispatch(getListLocations(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        const listLocation = res.data?.responses?.map((data) => {
          return { label: data.name || EMPTY_STRING, value: data.id };
        });
        setLocations(listLocation);
      })
      .catch((err) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };
  //#endregion Handle Function

  return (
    <div id='addCaregiverPage' className={cx('container')}>
      <BaseButton
        onClick={handleBackToPreviousPage}
        typeStyle={ButtonTypeEnum.ONLY_TEXT}
        iconLeft={icons.commonIconPrev}
        text={t('common_text_back')}
      />

      <div className={cx('header')}>
        <div className={cx('title')}>
          {caregiverId ? t('caregiver_modal_edit_caregiver_title') : t('add_caregiver_title')}
        </div>
      </div>

      <div className={cx('body')}>
        <form className={cx('form')} onSubmit={handleSubmit(handleSubmitFormCaregiver)}>
          <div className={cx('formBody')}>
            <Controller
              name='profileImage'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseUploadImage
                  label={t('add_caregiver_profile_image_label')}
                  textBtn={t('add_caregiver_profile_image_content')}
                  height={64}
                  maxUpload={DEFAULT_NUMBER_ONE}
                  defaultImageList={value ?? undefined}
                  onChange={onChange}
                  errorMessage={errors.profileImage?.message}
                />
              )}
            />

            <div className={cx('threeCol')}>
              <Controller
                name='firstName'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='firstName'
                    value={value}
                    label={t('add_caregiver_first_name_label')}
                    onChange={onChange}
                    required
                    messageError={errors.firstName?.message}
                  />
                )}
              />

              <Controller
                name='middleName'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='middleName'
                    value={value}
                    label={t('add_caregiver_middle_name_label')}
                    onChange={onChange}
                    messageError={errors.middleName?.message}
                  />
                )}
              />

              <Controller
                name='lastName'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='lastName'
                    value={value}
                    label={t('add_caregiver_last_name_label')}
                    onChange={onChange}
                    required
                    messageError={errors.lastName?.message}
                  />
                )}
              />
            </div>

            <div className={cx('threeCol')}>
              <Controller
                name='hireDate'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseDatePicker
                    label={t('add_caregiver_hire_dates_label')}
                    onDateSelected={onChange}
                    value={value || EMPTY_STRING}
                    name={'hireDate'}
                    placeholderText={t('common_placeholder_select')}
                    required
                    errorMessage={errors.hireDate?.message}
                  />
                )}
              />

              <Controller
                name='email'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='email'
                    value={value}
                    label={t('add_caregiver_email_label')}
                    onChange={onChange}
                    messageError={errors.email?.message}
                    required
                  />
                )}
              />

              <Controller
                name='locationIds'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseSelect
                    value={value[0] || EMPTY_STRING}
                    options={locations || []}
                    label={t('add_caregiver_location_label')}
                    placeholder={t('common_select_placeholder')}
                    onChange={({ value }) => onChange([value])}
                    mode='search'
                    errorMessage={errors.locationIds?.message}
                    required
                  />
                )}
              />
            </div>

            <div className={cx('twoCol')}>
              <Controller
                name={'typeLinkCaregiverList'}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseSelect
                    label={t('form_caregiver_type_link_caregiver_list_label')}
                    placeholder={t('common_placeholder_select')}
                    value={value || EMPTY_STRING}
                    options={linkToCaregiverListOptions || []}
                    onChange={({ value }) => {
                      onChange(value);
                    }}
                    errorMessage={errors.typeLinkCaregiverList?.message}
                    required
                  />
                )}
              />

              {typeLinkCaregiverList === LinkToCaregiverListEnum.ADD_EXISTING && (
                <Controller
                  name='caregiverListId'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <BaseSelect
                      label={t('add_caregiver_choose_caregiver_label')}
                      placeholder={isLoadingCaregiverList ? t('common_text_loading') : t('common_placeholder_select')}
                      value={value || EMPTY_STRING}
                      options={optionCaregiverList || []}
                      onChange={(optionSelected: IBaseOption) => onChange(optionSelected.value)}
                      mode='search'
                      required
                      errorMessage={errors.caregiverListId?.message}
                    />
                  )}
                />
              )}
            </div>

            <div className={cx('twoCol')}>
              <Controller
                name='address'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <PlacesAutocomplete
                    id='address'
                    value={value}
                    label={t('add_caregiver_address_label')}
                    onChange={(address) => handleChangeAddress(address)}
                    messageError={errors.address?.message}
                    required
                  />
                )}
              />

              <Controller
                name='secondaryAddress'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='addressLine2'
                    value={value}
                    label={t('add_caregiver_address_line_2_label')}
                    onChange={onChange}
                    messageError={errors.secondaryAddress?.message}
                  />
                )}
              />
            </div>

            <div className={cx('threeCol')}>
              <Controller
                name='city'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='city'
                    value={value}
                    label={t('add_caregiver_city_label')}
                    onChange={onChange}
                    messageError={errors.city?.message}
                    required
                  />
                )}
              />

              <Controller
                name='state'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='state'
                    value={value}
                    label={t('add_caregiver_state_label')}
                    onChange={onChange}
                    messageError={errors.state?.message}
                    required
                  />
                )}
              />

              <Controller
                name='postalCode'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='postalCode'
                    value={value}
                    label={t('add_caregiver_postal_code_label')}
                    onChange={onChange}
                    messageError={errors.postalCode?.message}
                    required
                  />
                )}
              />
            </div>

            <div className={cx('threeCol')}>
              <Controller
                name='homePhone'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='homePhone'
                    value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                    label={t('add_caregiver_home_phone_label')}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const { value } = event.target;
                      const formattedValue = formatPhoneNumber(value);
                      onChange(formattedValue);
                    }}
                    messageError={errors.homePhone?.message}
                  />
                )}
              />

              <Controller
                name='mobilePhone'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='mobilePhone'
                    value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                    label={t('add_caregiver_mobile_phone_label')}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const { value } = event.target;
                      const formattedValue = formatPhoneNumber(value);
                      onChange(formattedValue);
                    }}
                    messageError={errors.mobilePhone?.message}
                    required
                  />
                )}
              />

              <Controller
                name='workPhone'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='workPhone'
                    value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                    label={t('add_caregiver_work_phone_label')}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const { value } = event.target;
                      const formattedValue = formatPhoneNumber(value);
                      onChange(formattedValue);
                    }}
                    messageError={errors.workPhone?.message}
                  />
                )}
              />
            </div>

            <div className={cx('twoCol')}>
              <Controller
                name='referrerId'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseSelect
                    value={value}
                    options={caregiverReferralList || []}
                    label={t('add_caregiver_referrer_label')}
                    placeholder={t('common_select_placeholder')}
                    onChange={(optionSelected: IBaseOption) => {
                      onChange(optionSelected?.value);
                    }}
                    errorMessage={errors.referrerId?.message}
                    mode='search'
                    required
                  />
                )}
              />

              <Controller
                name='npi'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='npi'
                    value={value}
                    label={t('add_caregiver_caregiver_npi_label')}
                    onChange={onChange}
                    messageError={errors.npi?.message}
                  />
                )}
              />

              <Controller
                name='externalId'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='externalId'
                    value={value}
                    label={t('add_caregiver_external_id_label')}
                    onChange={onChange}
                    messageError={errors.externalId?.message}
                    required
                  />
                )}
              />

              <Controller
                name='ssn'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='ssn'
                    value={value}
                    label={t('add_caregiver_ssn_label')}
                    onChange={onChange}
                    messageError={errors.ssn?.message}
                    required
                  />
                )}
              />
            </div>

            <Controller
              name='notes'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseTextarea
                  id='notes'
                  label={t('add_caregiver_notes_label')}
                  value={value}
                  onChange={onChange}
                  height={100}
                  messageError={errors.notes?.message}
                />
              )}
            />

            {/* <Controller
              name='isBackgroundCheck'
              control={control}
              render={({ field: { value, onChange } }) => (
                <CheckboxSingle
                  label={t('add_caregiver_background_label')}
                  value={value}
                  onChange={(checked: boolean, name?: string) => {
                    onChange(checked);
                  }}
                  messageError={errors.isBackgroundCheck?.message}
                />
              )}
            /> */}
          </div>

          <div className={cx('actions')}>
            <BaseButton text={t('common_go_back_label')} width={80} onClick={handleBackToPreviousPage} type='button' />

            <BaseButton
              text={caregiverId ? t('common_update_label') : t('common_save_label')}
              typeStyle={ButtonTypeEnum.PRIMARY}
              width={80}
              type='submit'
              disabled={caregiverId ? !isDirty : false}
            />
          </div>
        </form>
      </div>

      {isUnderDevelopment && <ModalUnderDevelopment onClose={handleCloseUnderDevelopment} />}
    </div>
  );
};

export default AddCaregiver;
