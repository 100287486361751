// Libs
import classNames from 'classnames/bind';
import { memo, useContext, useEffect } from 'react';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
// Components, Layouts, Pages
import { BaseButton, BaseTextarea, BaseUploadImage } from '~/components';
// Others
import {
  IBodyItemAssessment,
  IPayloadEditAnswer,
  IQuestionHomeSafetyAssessment,
} from '~/utils/interface/homeSafetyAssessment';
import { AnswerHomeAssessmentEnum, ButtonTypeEnum, TimeFormatEnum } from '~/utils/enum';
import {
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  MAX_FILE_SIZE_FILE_20MB,
} from '~/utils/constants/common';
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { convertDateToFormatTime, createFormData, getUserName } from '~/utils/helper';
import { editAnswer } from '~/thunks/homeSafetyAssessment/homeSafetyAssessmentThunk';
// Styles, images, icons
import styles from './ItemHomeSafetyAssessment.module.scss';

type Props = {
  item?: IQuestionHomeSafetyAssessment;
  index: number;
  isLastItem?: boolean;
  onSuccess?: (clientId: string) => void;
};

const cx = classNames.bind(styles);

const initialValues = {};

export const itemAssessmentSchema = (t: TFunction) => {
  return yup
    .object()
    .shape({
      answer: yup.string().optional(),
      notes: yup.string().trim().optional(),
      image: yup
        .array()
        .of(
          yup.object({
            file: yup
              .mixed<File>()
              .optional()
              .test('fileSize', t('common_errors_files_size_limit'), (value) => {
                if (!value) return true;
                return value instanceof File && value.size <= MAX_FILE_SIZE_FILE_20MB;
              }),
          })
        )
        .optional(),
    })
    .required();
};

const ItemHomeSafetyAssessment = (props: Props) => {
  //#region Destructuring Props
  const { item, index, isLastItem, onSuccess } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const { clientId } = useParams();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<IBodyItemAssessment>({
    resolver: yupResolver(itemAssessmentSchema(t)),
    defaultValues: initialValues,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!item) return;

    const formattedData: IBodyItemAssessment = {
      ...(item.answer ? { answer: item.answer } : {}),
      notes: item.notes || EMPTY_STRING,
      image: item?.imageUrl ? [{ url: item?.imageUrl }] : undefined,
    };

    reset(formattedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleSubmitItemHomeAssessment = async (data: IBodyItemAssessment) => {
    if (!clientId || !item?.id) return;
    const { notes, ...restData } = data;

    const combineData: IBodyItemAssessment = {
      ...restData,
      ...(data.notes ? { notes: data.notes } : {}),
      image: Array.isArray(data?.image) && data?.image?.length > DEFAULT_NUMBER_ZERO ? data?.image : undefined,
      isDeleteImage: !Array.isArray(data?.image) || data?.image?.length === DEFAULT_NUMBER_ZERO,
    };
    const formData = await createFormData(combineData, 'image');
    const payload: IPayloadEditAnswer = {
      clientId: clientId,
      questionId: item?.id,
      body: formData,
    };

    loading?.show();
    dispatch(editAnswer(payload))
      .then((res) => {
        onSuccess && onSuccess(clientId);
      })
      .catch((err) => {})
      .finally(() => loading?.hide());
  };
  //#endregion Handle Function

  return (
    <div id='itemHomeSafetyAssessmentComponent' className={cx('itemHomeSafetyAssessmentComponent')}>
      <form className={cx('itemHomeSafetyAssessment')} onSubmit={handleSubmit(handleSubmitItemHomeAssessment)}>
        <div className={cx('questionWrap')}>
          <h3 className={cx('question')}>{item?.question}</h3>

          {(item?.hasNotes || index > DEFAULT_NUMBER_ONE) && (
            <Controller
              name='notes'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseTextarea
                  id={`note-${index}`}
                  value={value}
                  width={500}
                  height={60}
                  onChange={onChange}
                  messageError={errors.notes?.message}
                />
              )}
            />
          )}

          {index > DEFAULT_NUMBER_ONE && item?.updatedAt && item?.updatedBy && (
            <div className={cx('notes')}>
              {t('home_safety_assessment_update_at_by_text', {
                userName: getUserName(
                  item?.updatedBy?.firstName,
                  item?.updatedBy?.middleName,
                  item?.updatedBy?.lastName
                ),
                date: convertDateToFormatTime(item?.updatedAt, TimeFormatEnum.MM_DD_YYYY),
                time: convertDateToFormatTime(item?.updatedAt, TimeFormatEnum.HOUR_MINUTE_AM_PM_NORMAL),
              })}
            </div>
          )}

          <div className={cx('buttonSubmit')}>
            <BaseButton
              text={t('common_save_label')}
              typeStyle={ButtonTypeEnum.PRIMARY}
              width={80}
              type='submit'
              disabled={!isDirty}
            />
          </div>
        </div>
        <div className={cx('answersWrap')}>
          <div className={cx('answers')}>
            <Controller
              name='answer'
              control={control}
              render={({ field: { value, onChange } }) => (
                <>
                  <div className={cx('actionYesNo', { reverse: isLastItem })}>
                    <button
                      onClick={() => onChange(AnswerHomeAssessmentEnum.YES)}
                      type='button'
                      className={cx(
                        'action',
                        'yes',
                        { lastItem: isLastItem },
                        { active: value === AnswerHomeAssessmentEnum.YES }
                      )}
                    >
                      {t('common_label_yes')}
                    </button>

                    <button
                      onClick={() => onChange(AnswerHomeAssessmentEnum.NO)}
                      type='button'
                      className={cx(
                        'action',
                        'no',
                        { lastItem: isLastItem },
                        { active: value === AnswerHomeAssessmentEnum.NO }
                      )}
                    >
                      {t('common_label_no')}
                    </button>
                  </div>

                  {item?.hasNA && (
                    <button
                      onClick={() => onChange(AnswerHomeAssessmentEnum.NA)}
                      type='button'
                      className={cx('action', 'noAvailable', { active: value === AnswerHomeAssessmentEnum.NA })}
                    >
                      {AnswerHomeAssessmentEnum.NA}
                    </button>
                  )}
                </>
              )}
            />
          </div>

          {item?.hasImage && (
            <div className={cx('actionUpload')}>
              <Controller
                name='image'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseUploadImage
                    id={`image-${index}`}
                    textBtn={t('common_label_upload')}
                    height={60}
                    maxUpload={DEFAULT_NUMBER_ONE}
                    defaultImageList={value ?? undefined}
                    onChange={onChange}
                    errorMessage={errors.image?.message}
                  />
                )}
              />
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default memo(ItemHomeSafetyAssessment);
