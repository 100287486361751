// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';
import { TFunction } from 'i18next';
import { useSearchParams } from 'react-router-dom';

// Components, Layouts, Pages
import {
  BaseButton,
  BaseFilter,
  BasePagination,
  BaseTable,
  FormRateModal,
  InputSearch,
  ModalUnderDevelopment,
  ThreeDotTableOptions,
  ToolBar,
} from '~/components';

// Others
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_FILTER_RATE,
  DEFAULT_LIMIT_PAGE,
  EMPTY_STRING,
} from '~/utils/constants/common';
import {
  AccountRoleCodesEnum,
  ButtonTypeEnum,
  FieldTypeRate,
  FilterParamTaskEnum,
  StorageEnum,
  TranslationEnum,
} from '~/utils/enum';
import { ColumnTableType, IFilterValueChange, IListDataResponse, IPaginationResponse } from '~/utils/interface/common';
import { IDefaultFilterRate, IListQueryParamsRate, IQueryListRate, IRate } from '~/utils/interface/rate';
import { convertEnumToString } from '~/utils/helper';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { getListRate } from '~/thunks/rate/rateThunk';
import { LoadingData } from '~/context';
import useDebounce from '~/utils/customHook';

// Styles, images, icons
import styles from './RateTab.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const columns = (t: TFunction<TranslationEnum.TRANSLATION>): ColumnTableType<IRate>[] => {
  return [
    {
      key: 'rateName',
      title: t('rate_tab_rate_name_label'),
      dataIndex: 'name',
      render(value, record, index) {
        return <>{record.name ? record.name : EMPTY_STRING}</>;
      },
      width: '50%',
    },
    {
      key: 'rateType',
      title: t('rate_tab_type_label'),
      dataIndex: 'rateType',
      render(value, record, index) {
        return <>{record.rateType ? convertEnumToString(record.rateType) : EMPTY_STRING}</>;
      },
      width: '20%',
    },
    {
      key: 'userType',
      title: t('rate_tab_user_type_label'),
      dataIndex: 'userType',
      render(value, record, index) {
        return <>{record.userType ? convertEnumToString(record.userType) : EMPTY_STRING}</>;
      },
      width: '20%',
    },
  ];
};

const RateTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const textSearchParams = useMemo<string>(() => String(params?.textSearch || EMPTY_STRING), [params?.textSearch]);
  const role = localStorage.getItem(StorageEnum.ROLE);
  //#endregion Declare Hook

  //#region Selector
  const { isRefreshRateList } = useAppSelector((state) => state.rateState);
  //#endregion Selector

  //#region Declare State
  const pageSelected = useMemo<number>(() => Number(params?.page ?? DEFAULT_CURRENT_PAGE), [params?.page]);
  const [pagination, setPagination] = useState<IPaginationResponse>();
  const [paramObject, setParamObject] = useState<IListQueryParamsRate>({
    page: pageSelected,
    limit: DEFAULT_LIMIT_PAGE,
  });

  const [rateList, setRateList] = useState<IRate[]>([]);
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  const [isShowFormRateModal, setIsShowFormRateModal] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>(EMPTY_STRING);
  const [hasInteracted, setHasInteracted] = useState<boolean>(false);
  const renderSearchKey = useMemo(() => {
    if (!hasInteracted && textSearchParams) {
      return textSearchParams;
    }

    if (hasInteracted && searchKey === EMPTY_STRING) {
      return EMPTY_STRING;
    }

    if (searchKey) {
      return searchKey;
    }

    return EMPTY_STRING;
  }, [searchKey, textSearchParams, hasInteracted]);
  const debouncedSearchKey = useDebounce<string>(renderSearchKey.trim() || EMPTY_STRING, DEFAULT_DELAY_TIME);
  const [filterParams, setFilterParams] = useState<IQueryListRate>(DEFAULT_FILTER_RATE);
  const fieldsText = useMemo(() => {
    return filterParams.fields
      ? filterParams.fields.map((field) => convertEnumToString(field)).join(', ')
      : EMPTY_STRING;
  }, [filterParams.fields]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const newParamObject: IListQueryParamsRate = {
      ...paramObject,
      ...(debouncedSearchKey ? { textSearch: debouncedSearchKey } : {}),
    };

    handleGetListRate(newParamObject);

    const newParam = debouncedSearchKey
      ? {
          ...params,
          page: paramObject.page!.toString(),
          limit: DEFAULT_LIMIT_PAGE.toString(),
          textSearch: debouncedSearchKey,
        }
      : { ...params, page: paramObject.page!.toString(), limit: DEFAULT_LIMIT_PAGE.toString() };

    setSearchParams(newParam);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramObject]);

  useEffect(() => {
    if (!pagination) return;
    setParamObject({
      ...(debouncedSearchKey ? { textSearch: debouncedSearchKey } : {}),
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_PAGE,
      ...(filterParams ? { ...filterParams } : {}),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);

  useEffect(() => {
    if (!isRefreshRateList) return;

    handleGetListRate(paramObject);
  }, [isRefreshRateList]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleClickUnderDevelop = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleSortBy = () => {
    // @TODO: Handle Sort By
    setIsShowModalUnderDevelopment(true);
  };

  const handleShowModalAddRate = () => {
    setIsShowFormRateModal(true);
  };

  const handleCloseFormRateModal = () => {
    setIsShowFormRateModal(false);
  };

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value);
    setHasInteracted(true);
  };

  const handlePaginationChange = (page: number) => {
    const newParamObject: IListQueryParamsRate = { ...paramObject, page };
    setParamObject(newParamObject);
    setSearchParams({ ...params, page: page.toString(), limit: `${DEFAULT_LIMIT_PAGE}` });
  };

  const handleGetListRate = (params: IListQueryParamsRate) => {
    loading?.show();
    dispatch(getListRate(params))
      .unwrap()
      .then((res) => {
        const { responses, pagination }: IListDataResponse<IRate[]> = res?.data;

        setRateList(responses);
        setPagination(pagination);
      })
      .catch((err) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const handleFilterChange = (appliedFilter: IDefaultFilterRate | undefined) => {
    if (!appliedFilter) {
      setFilterParams({});
      return;
    }

    setFilterParams({
      ...appliedFilter,
    });

    if (debouncedSearchKey)
      setParamObject({
        ...paramObject,
        ...appliedFilter,
      });
  };

  const handleCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>,
    onChange: (data: IFilterValueChange<IDefaultFilterRate>) => void,
    valueFilter: IDefaultFilterRate | undefined
  ) => {
    const { name, value, checked } = event.target;
    const newFields = {
      ...filterParams,
      fields: checked
        ? [...(valueFilter?.fields ?? []), value]
        : (valueFilter?.fields ?? []).filter((field) => field !== value),
    };

    if (name === FilterParamTaskEnum.FIELDS) {
      onChange({ name: FilterParamTaskEnum.FIELDS, value: newFields.fields });
    }
  };
  //#endregion Handle Function

  return (
    <div id='rateTabComponent' className={cx('container')}>
      <ToolBar title={EMPTY_STRING}>
        <InputSearch
          placeholder={t('rate_tab_rate_name_placeholder')}
          width={204}
          onChange={handleChangeSearch}
          value={textSearchParams}
        />
        {/* <InputSearch
          placeholder={t('tasks_text_search_placeholder', {
            params: fieldsText,
          })}
          width={185}
          onChange={handleChangeSearch}
          value={textSearchParams}
        /> */}
        {/* <BaseFilter<IDefaultFilterRate>
          onApply={handleFilterChange}
          defaultValue={DEFAULT_FILTER_RATE}
          valueFilter={filterParams}
        >
          {({ valueFilter, onChange }) => {
            return (
              <div className={cx('filterWrap')}>
                <div className={cx('filterItemCheckbox')}>
                  <input
                    id='description'
                    type='checkbox'
                    name='fields'
                    onChange={(e) => {
                      handleCheckboxChange(e, onChange, valueFilter);
                    }}
                    value={FieldTypeRate.NAME}
                    checked={valueFilter?.fields?.includes(FieldTypeRate.NAME) || false}
                  />
                  <label htmlFor='description' className={cx('labelCheckbox')}>
                    {t('rate_tab_btn_filter_name_label')}
                  </label>
                </div>

                <div className={cx('filterItemCheckbox')}>
                  <input
                    id='relates'
                    type='checkbox'
                    name='fields'
                    onChange={(e) => {
                      handleCheckboxChange(e, onChange, valueFilter);
                    }}
                    value={FieldTypeRate.RATE_TYPE}
                    checked={valueFilter?.fields?.includes(FieldTypeRate.RATE_TYPE) || false}
                  />
                  <label htmlFor='relates' className={cx('labelCheckbox')}>
                    {t('rate_tab_btn_filter_rate_type_label')}
                  </label>
                </div>

                <div className={cx('filterItemCheckbox')}>
                  <input
                    id='dueDate'
                    type='checkbox'
                    name='fields'
                    onChange={(e) => {
                      handleCheckboxChange(e, onChange, valueFilter);
                    }}
                    value={FieldTypeRate.USER_TYPE}
                    checked={valueFilter?.fields?.includes(FieldTypeRate.USER_TYPE) || false}
                  />
                  <label htmlFor='dueDate' className={cx('labelCheckbox')}>
                    {t('rate_tab_btn_filter_user_type_label')}
                  </label>
                </div>
              </div>
            );
          }}
        </BaseFilter> */}
        {role === AccountRoleCodesEnum.SUPER_ADMIN && (
          <BaseButton
            typeStyle={ButtonTypeEnum.PRIMARY}
            iconLeft={icons.commonIconPlus}
            text={t('rate_tab_add_rate_title')}
            onClick={handleShowModalAddRate}
          />
        )}
      </ToolBar>

      <div className={cx('content')}>
        <div className={cx('tableWrap')}>
          <BaseTable columns={columns(t)} dataSource={rateList ?? []} />
        </div>

        <div className={cx('paginationTable')}>
          <BasePagination
            onChange={handlePaginationChange}
            defaultCurrentPage={pageSelected}
            totalItems={pagination?.totalItems}
            totalPages={pagination?.totalPages}
          />
        </div>
      </div>
      {isShowFormRateModal && <FormRateModal isOpen={isShowFormRateModal} onClose={handleCloseFormRateModal} />}
      {isShowModalUnderDevelopment && <ModalUnderDevelopment onClose={handleClickUnderDevelop} />}
    </div>
  );
};

export default RateTab;
