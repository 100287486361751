// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { memo, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Components, Layouts, Pages
import { BaseInput } from '~/components';

// Others
import { IFormPayrollRate } from '~/utils/interface/rate';
import { useAppSelector } from '~/redux/hooks';
import { getUserName } from '~/utils/helper';
import { DEFAULT_NUMBER_ZERO, DOLLAR } from '~/utils/constants/common';
import { InputTypeEnum } from '~/utils/enum';

// Styles, images, icons
import styles from './PayRate.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const PayRate = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext<IFormPayrollRate>();
  //#endregion Declare Hook

  //#region Selector
  const { clientDetail } = useAppSelector((state) => state.clientState);
  //#endregion Selector

  //#region Declare State
  const payrollRatesHourly = useMemo(() => {
    return getValues('payRate.payrollRatesHourly') || [];
  }, [watch('payRate.payrollRatesHourly')]);
  const payrollRatesPerVisit = useMemo(() => {
    return getValues('payRate.payrollRatesPerVisit') || [];
  }, [watch('payRate.payrollRatesPerVisit')]);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='payRateComponent' className={cx('payRateComponent')}>
      <div className={cx('content')}>
        <h1 className={cx('title')}>
          {t('settings_payroll_rate_question_content', {
            userName: getUserName(clientDetail?.firstName, clientDetail?.lastName),
          })}
        </h1>
        <p className={cx('questionContent')}>{t('settings_payroll_rate_attention_content')}</p>

        <h1 className={cx('title')}>
          {t('settings_payroll_rate_hourly_title')} <span className={cx('titleDollar')}>{`(${DOLLAR})`}</span>
        </h1>
        <div className={cx('payRollList')}>
          {payrollRatesHourly.length > DEFAULT_NUMBER_ZERO ? (
            payrollRatesHourly.map((payrollHourly, index) => {
              return (
                <div key={payrollHourly.id} className={cx('itemPayRoll')}>
                  <div>{payrollHourly.name}</div>
                  <Controller
                    name={`payRate.payrollRatesHourly.${index}.price`}
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <BaseInput
                          id={`payrollRatesHourly.${index}.price`}
                          value={value}
                          type={InputTypeEnum.NUMBER}
                          textAlign='right'
                          onChange={onChange}
                          width={110}
                        />
                      );
                    }}
                  />
                </div>
              );
            })
          ) : (
            <div>{t('settings_payroll_rate_no_hourly_title')}</div>
          )}
        </div>

        <h1 className={cx('title')}>
          {t('settings_payroll_rate_per_visit_title')} <span className={cx('titleDollar')}>{`(${DOLLAR})`}</span>
        </h1>
        <div>
          <div className={cx('payRollList')}>
            {payrollRatesPerVisit.length > DEFAULT_NUMBER_ZERO ? (
              payrollRatesPerVisit.map((payrollPerVisit, index) => {
                return (
                  <div key={payrollPerVisit.id} className={cx('itemPayRoll')}>
                    <div>{payrollPerVisit.name}</div>
                    <Controller
                      name={`payRate.payrollRatesPerVisit.${index}.price`}
                      control={control}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <BaseInput
                            id={`payrollRatesPerVisit.${index}.price`}
                            value={value}
                            type={InputTypeEnum.NUMBER}
                            textAlign='right'
                            onChange={onChange}
                            width={110}
                          />
                        );
                      }}
                    />
                  </div>
                );
              })
            ) : (
              <div>{t('settings_payroll_rate_no_per_visit_title')}</div>
            )}
          </div>
        </div>

        <h1 className={cx('title')}>{t('settings_payroll_rate_live_in_title')}</h1>
        <div className={cx('payRollList')}>
          <div>{t('settings_payroll_rate_no_live_in_title')}</div>
        </div>
      </div>
    </div>
  );
};

export default memo(PayRate);
