// Libs
import { StyleSheet, Text, View } from '@react-pdf/renderer';
// Components, Layouts, Pages
// Others
import { IInvoiceDetail } from '~/utils/interface/invoice';
import { generateInvoiceId } from '~/utils/helper';
// Styles, images, icons

type Props = {
  data: IInvoiceDetail;
};

const TitleInvoicePdf = (props: Props) => {
  //#region Destructuring Props
  const { data } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <View style={styles.container}>
      <View style={{ justifyContent: 'center', alignItems: 'center' }}>
        <Text style={styles.title}>Invoice</Text>
        <Text style={[styles.title, styles.value]}> {data.id ? generateInvoiceId(+data.id) : ''}</Text>
      </View>
    </View>
  );
};

export default TitleInvoicePdf;

export const styles = StyleSheet.create({
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    textTransform: 'uppercase',
    color: '#24604D',
  },

  value: {
    color: '#FF0000',
  },
});
