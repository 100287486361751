// Libs
import classNames from 'classnames/bind';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BaseButton, FormRequestApplicant } from '~/components';
// Others
import { NAVIGATE_BACK } from '~/utils/constants/common';
import { ButtonTypeEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './FormRequestApplicant.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const FormRequestApplicantPage = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { requestApplicantId } = useParams();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleBackToPreviousPage = () => {
    navigate(NAVIGATE_BACK);
  };
  //#endregion Handle Function

  return (
    <div id='formRequestApplicant' className={cx('container')}>
      {/* <button className={cx('backTag')} onClick={handleBackToPreviousPage}>
        <img src={icons.commonIconPrev} alt={t('common_img_text_alt')} />
        <span className={cx('textBack')}>{t('common_text_back')}</span>
      </button> */}
      <BaseButton
        onClick={handleBackToPreviousPage}
        typeStyle={ButtonTypeEnum.ONLY_TEXT}
        iconLeft={icons.commonIconPrev}
        text={t('common_text_back')}
      />

      <div className={cx('header')}>
        <div className={cx('title')}>
          {requestApplicantId ? t('form_request_applicant_edit_title') : t('form_request_applicant_add_title')}
        </div>
      </div>

      <div className={cx('body')}>
        <FormRequestApplicant requestApplicantId={requestApplicantId} />
      </div>
    </div>
  );
};

export default FormRequestApplicantPage;
