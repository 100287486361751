// Libs
import classNames from 'classnames/bind';
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';
import { useContext, useState, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
// Components, Layouts, Pages
import { CaregiverScheduleTabAdminPage } from '~/pages';
import {
  Tab,
  DailyCare,
  SkillTab,
  Schedule,
  ImageCircle,
  FilesTab,
  PayrollTab,
  CheckTab,
  TrainingTab,
  AddFiles,
  ModalUnderDevelopment,
  DetailActivities,
  InfoTab,
} from '~/components';
// Others
import { DEFAULT_NUMBER_ZERO, EMPTY_STRING, QUERY_KEY_TAB, caregiverDetailTabs } from '~/utils/constants/common';
import {
  AccountRoleCodesEnum,
  AddFileEnum,
  ImageCircleTypeEnum,
  StorageEnum,
  TabQueryValueEnum,
  TypeCaregiverEnum,
  TypeFilesDocumentEnum,
} from '~/utils/enum';
import { useAppDispatch } from '~/redux/hooks';
import { getDetailCaregiver } from '~/thunks/caregiver/caregiverThunk';
import { LoadingData } from '~/context';
import { ICaregiverDetail } from '~/utils/interface/caregiver';
import { createFormData, getRoutesByRole, getUserName } from '~/utils/helper';
import { adminRouteAbsolute, staffRouteAbsolute, superAdminRouteAbsolute } from '~/utils/constants/route';
import { ICreateConversationReq } from '~/utils/interface/conversation';
import { IUploadFileDocument } from '~/utils/interface/file';
import { createConversation } from '~/thunks/conversation/conversationThunk';
import { useTextOverflow } from '~/utils/customHook';
import { uploadDocument } from '~/thunks/file/fileThunk';
import { fileActions } from '~/thunks/file/fileSlice';
// Styles, images, icons
import styles from './CaregiverDetail.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const CaregiverDetail = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const [searchParams] = useSearchParams();
  const currentTab = searchParams.get(QUERY_KEY_TAB);
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);
  const role = localStorage.getItem(StorageEnum.ROLE);
  const navigate = useNavigate();
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const { caregiverId } = useParams();
  const [caregiverDetail, setCaregiverDetail] = useState<ICaregiverDetail>();
  const [isOpenUnderDevelopmentModal, setIsOpenUnderDevelopmentModal] = useState<boolean>(false);
  const contentRefs = useRef<Map<string, HTMLDivElement | null>>(new Map());
  const overflowMap = useTextOverflow(contentRefs.current, [caregiverDetail]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetDetailCaregiver();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const renderTabContent = useMemo(() => {
    const tabToRender = currentTab || TabQueryValueEnum.INFO;
    switch (tabToRender) {
      case TabQueryValueEnum.DAILY_CARE:
        return <DailyCare />;
      case TabQueryValueEnum.SCHEDULE:
        return <CaregiverScheduleTabAdminPage />;
      case TabQueryValueEnum.ACTIVITIES:
        return <DetailActivities />;
      case TabQueryValueEnum.FILES:
        return <FilesTab />;
      case TabQueryValueEnum.SKILL:
        return <SkillTab />;
      case TabQueryValueEnum.PAYROLL:
        return <PayrollTab />;
      case TabQueryValueEnum.CHECK:
        return <CheckTab typeRole={TypeCaregiverEnum.CAREGIVER} />;
      case TabQueryValueEnum.TRAINING:
        return <TrainingTab />;
      case TabQueryValueEnum.INFO:
        return <InfoTab caregiverInfo={caregiverDetail} />;

      default:
        // Handle Return Tab Later
        return <div className={cx('noDataAvailable')}>{t('common_empty_data')}</div>;
    }
  }, [currentTab, caregiverId, t, caregiverDetail]);

  const handleGetDetailCaregiver = () => {
    if (!caregiverId) return;
    loading?.show();

    dispatch(getDetailCaregiver(caregiverId))
      .unwrap()
      .then((res) => {
        if (!res || !res.data) return;
        setCaregiverDetail(res.data);
      })
      .catch((err) => {})
      .finally(() => loading?.hide());
  };

  const handleBack = () => {
    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(superAdminRouteAbsolute.caregiverShowAll);
        break;
      case AccountRoleCodesEnum.ADMIN:
        navigate(adminRouteAbsolute.caregiverShowAll);
        break;
      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(staffRouteAbsolute.caregiverShowAll);
    }
  };

  const handleMessage = () => {
    if (!caregiverDetail || !caregiverDetail.id) return;

    const payload: ICreateConversationReq = {
      accountIds: [Number(caregiverDetail.id)],
    };

    loading?.show();

    dispatch(createConversation(payload))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        const { conversationId } = res.data;

        const params = new URLSearchParams({ conversationId: conversationId });
        navigate(`${getRoutesByRole(role)?.messages ?? EMPTY_STRING}?${params.toString()}`);
      })
      .catch((error) => {})
      .finally(() => loading?.hide());
  };

  const handleCall = () => {
    //ToDo : @khai handle logic Call
    setIsOpenUnderDevelopmentModal(!isOpenUnderDevelopmentModal);
  };

  const handleDocument = () => {
    //ToDo : @khai handle logic Document
    setIsOpenUnderDevelopmentModal(!isOpenUnderDevelopmentModal);
  };

  const handleUnderDevelopmentModal = () => {
    setIsOpenUnderDevelopmentModal(!isOpenUnderDevelopmentModal);
  };

  const handleRef = (key: string, el: HTMLDivElement | null) => {
    contentRefs.current.set(key, el);
  };

  const handleUploadFile = async (data: IUploadFileDocument) => {
    if (!caregiverId) return;

    const dataCreate = {
      ...data,
      businessId: caregiverId,
      type: TypeFilesDocumentEnum.CAREGIVER_DOCUMENT,
    };

    const formData = await createFormData(dataCreate, AddFileEnum.FILES);

    loading?.show();

    dispatch(uploadDocument(formData))
      .unwrap()
      .then((response) => {
        dispatch(fileActions.setRefreshList(true));
      })
      .catch((error) => {})
      .finally(() => {
        loading?.hide();
      });
  };
  //#endregion Handle Function

  return (
    <div id='caregiverDetailPage' className={cx('container', { files: currentTab === TabQueryValueEnum.FILES })}>
      <div
        className={cx('mainBody', {
          files: currentTab === TabQueryValueEnum.FILES,
        })}
      >
        <span className={cx('backTag')} onClick={handleBack}>
          <img src={icons.commonIconPrev} alt={t('common_img_text_alt')} />
          <span className={cx('textBack')}>{t('common_text_back')}</span>
        </span>

        <section className={cx('headGroup')}>
          <div className={cx('basicInfoGroup')}>
            <ImageCircle
              imageUrl={caregiverDetail?.avatarUrl}
              firstName={caregiverDetail?.firstName}
              lastName={caregiverDetail?.lastName}
              type={caregiverDetail?.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
              height={91}
              width={91}
            />

            <div className={cx('basicInfo')}>
              <div className={cx('nameGroup')}>
                <div>
                  {getUserName(caregiverDetail?.firstName, caregiverDetail?.middleName, caregiverDetail?.lastName)}
                </div>
                <div className={cx('icons')}>
                  <span className={cx('iconMessage')}>
                    <img src={icons.commonIconNewMessageGray} alt={t('common_img_text_alt')} onClick={handleMessage} />
                  </span>
                  {/* <span>
                    <img src={icons.commonIconPhoneGray} alt={t('common_img_text_alt')} onClick={handleCall} />
                  </span>
                  <span>
                    <img src={icons.commonIconDocumentGray} alt={t('common_img_text_alt')} onClick={handleDocument} />
                  </span> */}
                </div>
              </div>

              <div className={cx('referredBy')}>
                <span>{t('caregiver_detail_referred_by_label')}:</span>
                <span className={cx('value')}>
                  {caregiverDetail?.referrer?.organizationName ||
                    getUserName(
                      caregiverDetail?.referrer?.firstName,
                      caregiverDetail?.referrer?.middleName,
                      caregiverDetail?.referrer?.lastName
                    )}
                </span>
              </div>
            </div>
          </div>

          <div className={cx('navTabGroup')}>
            <Tab items={caregiverDetailTabs} paddingBottom={30} />
          </div>
        </section>

        <section className={cx('tabContent')}>{renderTabContent}</section>
      </div>

      {currentTab === TabQueryValueEnum.FILES && (
        <AddFiles title={t('add_files_title')} onSubmitFormSuccess={handleUploadFile} />
      )}

      {isOpenUnderDevelopmentModal && <ModalUnderDevelopment onClose={handleUnderDevelopmentModal} />}

      {Object.entries(overflowMap).map(([key, isOverflow]) => {
        return (
          isOverflow && (
            <Tooltip key={key} id={key} className={cx('tooltipText')}>
              {key === 'tags' ? (
                <div className={cx('tooltipTags')}>
                  {caregiverDetail?.tags && caregiverDetail?.tags.length > DEFAULT_NUMBER_ZERO
                    ? caregiverDetail?.tags.map((tag, index) => (
                        <span key={index} className={cx('tooltipTag')}>
                          {tag.value}
                          {index < caregiverDetail?.tags.length - 1 ? ', ' : ''}
                          {(index + 1) % 2 === 0 && <br />}
                        </span>
                      ))
                    : EMPTY_STRING}
                </div>
              ) : (
                <div>
                  {caregiverDetail?.referrer
                    ? getUserName(caregiverDetail.referrer.firstName, caregiverDetail.referrer.lastName)
                    : EMPTY_STRING}
                </div>
              )}
            </Tooltip>
          )
        );
      })}
    </div>
  );
};

export default CaregiverDetail;
