// Libs
import classNames from 'classnames/bind';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { memo } from 'react';
// Components, Layouts, Pages
import { BaseInput, BaseSelectMultiple } from '~/components';
// Others
import { IFormSkill } from '~/utils/interface/caregivers/skill';
import {
  EMPTY_STRING,
  optionsGeneralSkill,
  optionsOtherOrMiscSkill,
  optionsPetsSkill,
  optionsTransfersSkill,
} from '~/utils/constants/common';
import { InputTypeEnum } from '~/utils/enum';

// Styles, images, icons
import styles from './MatchCriteriaSkill.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const MatchCriteriaSkill = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IFormSkill>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='matchCriteriaSkillComponent' className={cx('matchCriteriaSkillContainer')}>
      <div className={cx('headTitle')}>{t('form_skill_match_criteria_title')}</div>

      <div className={cx('bodyWrap')}>
        <div className={cx('twoCol')}>
          <Controller
            control={control}
            name='matchCriteriaSkill.general'
            render={({ field: { value, onChange } }) => (
              <BaseSelectMultiple
                options={optionsGeneralSkill}
                value={value || []}
                onChange={onChange}
                placeholder={t('common_placeholder_select')}
                label={t('form_skill_match_criteria_general_label')}
              />
            )}
          />

          <Controller
            control={control}
            name='matchCriteriaSkill.transfers'
            render={({ field: { value, onChange } }) => (
              <BaseSelectMultiple
                options={optionsTransfersSkill}
                value={value || []}
                onChange={onChange}
                placeholder={t('common_placeholder_select')}
                label={t('form_skill_match_criteria_transfers_label')}
              />
            )}
          />
        </div>

        <div className={cx('threeCol')}>
          <Controller
            control={control}
            name='matchCriteriaSkill.pets'
            render={({ field: { value, onChange } }) => (
              <BaseSelectMultiple
                options={optionsPetsSkill}
                value={value || []}
                onChange={onChange}
                placeholder={t('common_placeholder_select')}
                label={t('form_skill_match_criteria_pets_label')}
              />
            )}
          />

          <Controller
            control={control}
            name='matchCriteriaSkill.otherMisc'
            render={({ field: { value, onChange } }) => (
              <BaseSelectMultiple
                options={optionsOtherOrMiscSkill}
                value={value || []}
                onChange={onChange}
                placeholder={t('common_placeholder_select')}
                label={t('form_skill_match_criteria_other_misc_label')}
              />
            )}
          />

          <Controller
            control={control}
            name='matchCriteriaSkill.maxClientWeight'
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='matchCriteriaSkill.maxClientWeight'
                value={value || EMPTY_STRING}
                onChange={(event) => {
                  let value = event.target.value;

                  onChange(Number(value));
                }}
                label={t('form_skill_match_criteria_max_weight_label')}
                type={InputTypeEnum.NUMBER}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(MatchCriteriaSkill);
