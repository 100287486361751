// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { Modal, TableDetailActivity } from '~/components';
// Others
import { IActivityByUser } from '~/utils/interface/activity';
import { ActivityTypeEnum, BusinessModelEnum, TimeFormatEnum } from '~/utils/enum';
import { EMPTY_STRING } from '~/utils/constants/common';
import { convertTime, getUserName } from '~/utils/helper';
// Styles, images, icons
import styles from './DetailActivityModal.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  detailActivity: IActivityByUser;
};

const cx = classNames.bind(styles);

const DetailActivityModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, onClose, detailActivity } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleCloseFormActivity = () => {
    onClose();
  };

  const renderTitle = (activityType: string) => {
    switch (activityType) {
      case ActivityTypeEnum.CREATE_TASK:
        return <div className={cx('contentName')}>{t('activities_created_task_title')}</div>;
      case ActivityTypeEnum.UPLOAD_DOCUMENT:
        return <div className={cx('contentName')}>{t('activities_upload_document_title')}</div>;
      case ActivityTypeEnum.SEND_EMAIL:
        return <div className={cx('contentName')}>{t('activities_sent_email_title')}</div>;
      case ActivityTypeEnum.CHANGE_STAGE:
      case ActivityTypeEnum.CREATE_APPLICANT:
        return <div className={cx('contentName')}>{t('activities_stage_changed_title')}</div>;
      case ActivityTypeEnum.CHANGE_STATUS:
        if (detailActivity.businessModel === BusinessModelEnum.SHIFT) {
          return <div className={cx('contentName')}>{t('activities_status_changed_shift_title')}</div>;
        }
        return <div className={cx('contentName')}>{t('activities_status_changed_title')}</div>;
      case ActivityTypeEnum.CREATE_SHIFT_NOTE:
        return <div className={cx('contentName')}>{t('activities_note_shift_created_title')}</div>;
      case ActivityTypeEnum.CREATE_SHIFT:
        return <div className={cx('contentName')}>{t('activities_created_shift_title')}</div>;
      case ActivityTypeEnum.CREATE_CLIENT:
        return <div className={cx('contentName')}>{t('activities_created_client_title')}</div>;
      case ActivityTypeEnum.CREATE_CAREGIVER:
        return <div className={cx('contentName')}>{t('activities_created_caregiver_title')}</div>;
      case ActivityTypeEnum.CHECK_OUT:
        return <div className={cx('contentName')}>{t('activities_check_out_title')}</div>;
      case ActivityTypeEnum.CHECK_IN:
        return <div className={cx('contentName')}>{t('activities_check_in_title')}</div>;
      case ActivityTypeEnum.CREATE_OPEN_SHIFT:
        return <div className={cx('contentName')}>{t('activities_create_open_shift_title')}</div>;
      case ActivityTypeEnum.APPROVED_OPEN_SHIFT:
        return <div className={cx('contentName')}>{t('activities_accepted_shift_title')}</div>;
      case ActivityTypeEnum.DECLINED_OPEN_SHIFT:
        return <div className={cx('contentName')}>{t('activities_declined_shift_title')}</div>;
      default:
        return <div className={cx('contentName')}>{EMPTY_STRING}</div>;
    }
  };
  //#endregion Handle Function

  return (
    <Modal isOpen={isOpen} onClose={handleCloseFormActivity} title={t('modal_activity_detail_title')}>
      <div id='detailActivityModalComponent' className={cx('container')}>
        <div className={cx('contentText')}>
          <h3 className={cx('title')}>{renderTitle(detailActivity.actionType)}</h3>
          <h3 className={cx('title')}>
            {t('activities_updated_by_label', {
              name: getUserName(detailActivity.createdBy?.firstName, detailActivity.createdBy?.lastName),
            })}
          </h3>
          <h3 className={cx('title')}>
            {t('activities_updated_at_label', {
              name: convertTime(detailActivity.createdAt, TimeFormatEnum.MM_DD_YYYY_HH_AM_PM),
            })}
          </h3>
        </div>

        <TableDetailActivity detailActivity={detailActivity} />
      </div>
    </Modal>
  );
};

export default DetailActivityModal;
