// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import caregiverReferralApi from '~/apis/caregivers/referral';
import {
  GET_LIST_CAREGIVER_REFERRAL,
  CREATE_CAREGIVER_REFERRAL,
  GET_CAREGIVER_REFERRAL_DETAIL,
  EDIT_CAREGIVER_REFERRAL,
  GET_CAREGIVERS_APPLICANTS,
  CHANGE_STATUS_CAREGIVERS_REFERRAL,
} from '~/utils/constants/actionType';
import { urlApiReferral } from '~/utils/constants/api';
import {
  IFormUpdateCaregiverReferral,
  IParamsGetCaregiverApplicant,
  IQueryCaregiverReferral,
} from '~/utils/interface/caregivers/referral';
import { IChangeStatus } from '~/utils/interface/crm/clients';

export const getListCaregiverReferral = createAsyncThunk(
  GET_LIST_CAREGIVER_REFERRAL,
  async (payload: IQueryCaregiverReferral, { rejectWithValue }) => {
    try {
      const res = await caregiverReferralApi.getListCaregiverReferral(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createCaregiverReferral = createAsyncThunk(
  CREATE_CAREGIVER_REFERRAL,
  async (body: FormData, { rejectWithValue }) => {
    try {
      const res = await caregiverReferralApi.createCaregiverReferral(body);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDetailReferrals = createAsyncThunk(
  GET_CAREGIVER_REFERRAL_DETAIL,
  async (referralId: string, { rejectWithValue }) => {
    try {
      const res = await caregiverReferralApi.getDetailReferrals(referralId);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateCaregiverReferral = createAsyncThunk(
  EDIT_CAREGIVER_REFERRAL,
  async (body: IFormUpdateCaregiverReferral, { rejectWithValue }) => {
    try {
      const res = await caregiverReferralApi.updateCaregiverReferral(body);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListCaregiverApplicantReferred = createAsyncThunk(
  GET_CAREGIVERS_APPLICANTS,
  async (params: IParamsGetCaregiverApplicant, { rejectWithValue }) => {
    try {
      const res = await caregiverReferralApi.getListCaregiverApplicantReferred(params);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changeStatusReferred = createAsyncThunk(
  CHANGE_STATUS_CAREGIVERS_REFERRAL,
  async ({ referralId, body }: { referralId: string; body: IChangeStatus }, { rejectWithValue }) => {
    try {
      const res = await caregiverReferralApi.changeStatusReferrals({ referralId: referralId, body: body });
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
