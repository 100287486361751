// Libs
import { StyleSheet, Text, View } from '@react-pdf/renderer';
// Components, Layouts, Pages
// Others
import { DEFAULT_CURRENCY } from '~/utils/constants/common';
import { CurrencyEnum } from '~/utils/enum';
import { formatCurrency } from '~/utils/helper';
// Styles, images, icons

type Props = {
  total?: number;
};

const TotalInvoicePdf = (props: Props) => {
  //#region Destructuring Props
  const { total } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <View style={styles.total}>
      <Text style={styles.titleTotal}>Total: </Text>

      <Text style={styles.valueTotal}>
        {total ? formatCurrency(CurrencyEnum.USD, Number(total)) : DEFAULT_CURRENCY}
      </Text>
    </View>
  );
};

export default TotalInvoicePdf;

export const styles = StyleSheet.create({
  total: {
    marginTop: 20,
    alignSelf: 'flex-end',
    flexDirection: 'row',
    marginRight: 8,
  },

  titleTotal: {
    fontSize: 12,
    color: '#333333',
  },

  valueTotal: {
    fontSize: 12,
    color: '#577970',
  },
});
