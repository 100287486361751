import { createAsyncThunk } from '@reduxjs/toolkit';
import rateApi from '~/apis/rates';
import {
  GET_LIST_PAYROLL,
  CREATE_RATE,
  GET_LIST_RATE,
  UPDATE_PAYROLL_RATES,
  GET_LIST_AUTHORIZATION,
  CREATE_AUTHORIZATION,
  GET_DETAIL_AUTHORIZATION,
  EDIT_AUTHORIZATION,
} from '~/utils/constants/actionType';
import {
  IBodyPayrollRate,
  IFormAuthorization,
  IFormRate,
  IListQueryParamsRate,
  IListQueryPayroll,
  IPayloadEditAuthorization,
} from '~/utils/interface/rate';

export const getListRate = createAsyncThunk(
  GET_LIST_RATE,
  async (payload: IListQueryParamsRate, { rejectWithValue }) => {
    try {
      const res = await rateApi.getListRate(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListPayroll = createAsyncThunk(
  GET_LIST_PAYROLL,
  async (payload: IListQueryPayroll, { rejectWithValue }) => {
    try {
      const res = await rateApi.getListPayroll(payload);
      return { data: res.data, userType: payload.userType, rateType: payload.rateType };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createRate = createAsyncThunk(CREATE_RATE, async (payload: IFormRate, { rejectWithValue }) => {
  try {
    const res = await rateApi.createRate(payload);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updatePayrollRate = createAsyncThunk(
  UPDATE_PAYROLL_RATES,
  async (payload: IBodyPayrollRate, { rejectWithValue }) => {
    try {
      const res = await rateApi.updatePayrollRate(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListAuthorization = createAsyncThunk(
  GET_LIST_AUTHORIZATION,
  async (payload: IListQueryPayroll, { rejectWithValue }) => {
    try {
      const res = await rateApi.getListAuthorization(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createAuthorization = createAsyncThunk(
  CREATE_AUTHORIZATION,
  async (body: IFormAuthorization, { rejectWithValue }) => {
    try {
      const res = await rateApi.createAuthorization(body);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDetailAuthorization = createAsyncThunk(
  GET_DETAIL_AUTHORIZATION,
  async (authorizationId: string, { rejectWithValue }) => {
    try {
      const res = await rateApi.getDetailAuthorization(authorizationId);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editAuthorization = createAsyncThunk(
  EDIT_AUTHORIZATION,
  async (payload: IPayloadEditAuthorization, { rejectWithValue }) => {
    try {
      const res = await rateApi.editAuthorization(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
