// Libs
import classNames from 'classnames/bind';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { memo, useEffect } from 'react';
// Components, Layouts, Pages
import { BaseSelect, BaseDatePicker, SelectTime, BaseTextarea, CheckboxSingle } from '~/components';
// Others
import {
  EMPTY_STRING,
  optionReasonUnavailable,
  VALUE_END_TIME_DAY,
  VALUE_START_TIME_DAY,
} from '~/utils/constants/common';
import { IFormOfficeCalendar } from '~/utils/interface/schedule';
// Styles, images, icons
import styles from './UnavailabilityForm.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const UnavailabilityForm = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<IFormOfficeCalendar>();
  const watchAllDay = useWatch({ control: control, name: 'allDay' });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!watchAllDay) {
      setValue('startTime', EMPTY_STRING);
      setValue('endTime', EMPTY_STRING);
      return;
    }

    setValue('startTime', VALUE_START_TIME_DAY);
    setValue('endTime', VALUE_END_TIME_DAY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAllDay]);
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='=unavailabilityForm' className={cx('container')}>
      <Controller
        name='allDay'
        control={control}
        render={({ field: { value, onChange } }) => (
          <CheckboxSingle
            name='allDay'
            label={t('form_unavailability_all_day_label')}
            value={value}
            onChange={onChange}
            fontSize={12}
          />
        )}
      />
      <div className={cx(!watchAllDay && 'twoCol')}>
        <Controller
          name='startDate'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseDatePicker
              label={t('form_unavailability_start_date_label')}
              placeholderText={t('common_select_placeholder')}
              value={value || EMPTY_STRING}
              onDateSelected={(date: string) => {
                onChange(date);
              }}
              errorMessage={errors.startDate?.message}
              required
            />
          )}
        />

        {!watchAllDay && (
          <Controller
            name='startTime'
            control={control}
            render={({ field: { value, onChange } }) => (
              <SelectTime
                label={t('form_unavailability_start_time_label')}
                placeholder={t('common_select_placeholder')}
                value={value || EMPTY_STRING}
                onChange={(date: string) => {
                  onChange(date);
                }}
                errorMessage={errors.startTime?.message}
                required
              />
            )}
          />
        )}
      </div>

      <div className={cx(!watchAllDay && 'twoCol')}>
        <Controller
          name='endDate'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseDatePicker
              label={t('form_unavailability_end_date_label')}
              placeholderText={t('common_select_placeholder')}
              value={value || EMPTY_STRING}
              onDateSelected={(date: string) => {
                onChange(date);
              }}
              errorMessage={errors.endDate?.message}
              required
            />
          )}
        />

        {!watchAllDay && (
          <Controller
            name='endTime'
            control={control}
            render={({ field: { value, onChange } }) => (
              <SelectTime
                label={t('form_unavailability_end_time_label')}
                placeholder={t('common_select_placeholder')}
                value={value || EMPTY_STRING}
                onChange={(date: string) => {
                  onChange(date);
                }}
                errorMessage={errors.endTime?.message}
                required
              />
            )}
          />
        )}
      </div>

      <Controller
        control={control}
        name='reason'
        render={({ field: { value, onChange } }) => (
          <BaseSelect
            label={t('form_unavailability_reason_label')}
            placeholder={t('common_select_placeholder')}
            options={optionReasonUnavailable || []}
            value={value || EMPTY_STRING}
            onChange={({ value }) => onChange(value)}
            errorMessage={errors.reason?.message}
            required
          />
        )}
      />

      <Controller
        control={control}
        name='note'
        render={({ field: { value, onChange } }) => (
          <BaseTextarea
            id='note'
            label={t('form_unavailability_note_label')}
            value={value}
            onChange={onChange}
            height={100}
          />
        )}
      />
    </div>
  );
};

export default memo(UnavailabilityForm);
