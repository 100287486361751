// Libs
import { TFunction } from 'i18next';
import * as yup from 'yup';
// Others
import { DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
import { IFormBillingPolicy } from '~/utils/interface/rate';

export const billingPolicySchema = (t: TFunction) => {
  return yup.object().shape({
    payRate: payRateSchema(t).optional(),
    careManagement: careManagementRateSchema(t).optional(),
    invoiceRate: invoicingRateSchema(t).optional(),
    travelRate: mileageTravelRateSchema(t).optional(),
    payHour: payHoursRatesSchema(t).optional(),
    overtime: overtimeRateSchema(t).optional(),
  });
};

export const initialValues: IFormBillingPolicy = {
  payRate: {
    payrollRatesHourly: [],
    payrollRatesPerVisit: [],
  },
  careManagement: {
    isDefault: false,
    method: EMPTY_STRING,
    rate: DEFAULT_NUMBER_ZERO,
  },
  invoiceRate: {
    notes: EMPTY_STRING,
    remittanceAddress: EMPTY_STRING,
    term: EMPTY_STRING,
  },
  travelRate: {
    defaultTravelTimeRate: EMPTY_STRING,
    isDefaultTravelMileage: false,
    isHolidayRateApplied: false,
    travelMileageRate: DEFAULT_NUMBER_ZERO,
  },
  payHour: {
    roundingMethod: EMPTY_STRING,
    minimumMinutes: DEFAULT_NUMBER_ZERO,
    isDefaultMinimumMinutes: false,
  },
  overtime: {
    hoursWeek: DEFAULT_NUMBER_ZERO,
    consecutiveDaysWeek: DEFAULT_NUMBER_ZERO,
    doubleHoursDay: DEFAULT_NUMBER_ZERO,
    doubleHoursLastDay: DEFAULT_NUMBER_ZERO,
    hoursDay: DEFAULT_NUMBER_ZERO,
    method: EMPTY_STRING,
  },
};

export const payRateSchema = (t: TFunction) => {
  return yup.object({
    payrollRatesHourly: yup
      .array()
      .of(
        yup.object({
          id: yup.string().required(),
          name: yup.string().optional(),
          price: yup.number().optional(),
          rateId: yup.number().required(),
          rateType: yup.string().optional(),
          userType: yup.string().optional(),
        })
      )
      .optional(),
    payrollRatesPerVisit: yup
      .array()
      .of(
        yup.object({
          id: yup.string().required(),
          name: yup.string().optional(),
          price: yup.number().optional(),
          rateId: yup.number().required(),
          rateType: yup.string().optional(),
          userType: yup.string().optional(),
        })
      )
      .optional(),
  });
};

export const invoicingRateSchema = (t: TFunction) => {
  return yup.object({
    notes: yup.string().trim().optional(),
    remittanceAddress: yup.string().optional(),
    term: yup.string().trim().optional(),
  });
};

export const mileageTravelRateSchema = (t: TFunction) => {
  return yup.object({
    defaultTravelTimeRate: yup.string().trim().optional(),
    isDefaultTravelMileage: yup.boolean().optional(),
    isHolidayRateApplied: yup.boolean().optional(),
    travelMileageRate: yup.number().optional(),
  });
};

export const careManagementRateSchema = (t: TFunction) => {
  return yup.object({
    isDefault: yup.boolean().optional(),
    method: yup.string().trim().optional(),
    rate: yup.number().optional(),
  });
};

export const payHoursRatesSchema = (t: TFunction) => {
  return yup.object({
    roundingMethod: yup.string().trim().optional(),
    minimumMinutes: yup.number().optional(),
    isDefaultMinimumMinutes: yup.boolean().optional(),
  });
};

export const overtimeRateSchema = (t: TFunction) => {
  return yup.object({
    hoursWeek: yup.number().optional(),
    consecutiveDaysWeek: yup.number().optional(),
    doubleHoursDay: yup.number().optional(),
    doubleHoursLastDay: yup.number().optional(),
    hoursDay: yup.number().optional(),
    method: yup.string().trim().optional(),
  });
};
