// Libs
import classNames from 'classnames/bind';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import {
  DEFAULT_UPLOAD_FILE_HEIGHT,
  DEFAULT_UPLOAD_FILE_LIMIT,
  DEFAULT_UPLOAD_FILE_LIST,
  DEFAULT_UPLOAD_FILE_WIDTH,
} from '~/utils/constants/component';
import { generateUniqueId } from '~/utils/helper';
import { IUploadFile } from '~/utils/interface/common';
import {
  ASTERISK_SYMBOL,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_ZERO,
  MAX_FILE_SIZE_BYTES,
} from '~/utils/constants/common';
// Styles, images, icons
import styles from './BaseUploadFile.module.scss';
import { icons } from '~/assets';

type Props = {
  height?: number | string;
  width?: number | string;
  label?: string;
  textBtn?: string;
  maxUpload?: number;
  errorMessage?: string;
  defaultFileList?: IUploadFile[];
  multiple?: boolean;
  required?: boolean;
  onChange?: (fileList: IUploadFile[]) => void;
  acceptFile?: string[];
  id?: string;
};

const cx = classNames.bind(styles);

const BaseUploadImage = (props: Props) => {
  //#region Destructuring Props
  const {
    width = DEFAULT_UPLOAD_FILE_WIDTH,
    height = DEFAULT_UPLOAD_FILE_HEIGHT,
    maxUpload,
    defaultFileList = DEFAULT_UPLOAD_FILE_LIST,
    label,
    textBtn,
    errorMessage,
    multiple = false,
    acceptFile,
    required,
    onChange,
    id,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [fileList, setFileList] = useState<IUploadFile[]>([]);
  const [uploadError, setUploadError] = useState<string | null>(null);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!defaultFileList?.length) {
      setFileList([]);
      return;
    }

    setFileList(defaultFileList);
  }, [defaultFileList]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleUploadChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (!files) return;

    const newFiles: IUploadFile[] = [];
    const errors: string[] = [];

    Array.from(files).map((file) => {
      if (file.size > MAX_FILE_SIZE_BYTES) {
        errors.push(
          t('error_upload_file_max_size', {
            fileName: file.name,
          })
        );
      } else {
        newFiles.push({
          id: generateUniqueId(),
          file: file,
          name: file.name,
        });
      }
    });

    if (errors.length > 0) {
      setUploadError(errors.join(' '));
    } else {
      setUploadError(null);
      const updatedFileList = [...fileList.slice(DEFAULT_NUMBER_ONE), ...newFiles];
      setFileList(updatedFileList);
      event.target.value = ''; // Reset DOM when user selects the same file in react
      onChange && onChange(updatedFileList);
    }
  };

  const handleRemoveImage = (indexRemove: number) => {
    const newImageList: IUploadFile[] = fileList.filter((_, index) => index !== indexRemove);

    onChange && onChange(newImageList);
    setFileList(newImageList);
    setUploadError(null);
  };
  //#endregion Handle Function

  return (
    <div id='baseUploadFileComponent' className={cx('container')} style={{ width }}>
      {label && (
        <div className={cx('label')}>
          {label} {required && <span className={cx('labelRequired')}>{ASTERISK_SYMBOL}</span>}
        </div>
      )}

      <div className={cx('uploadFileContainer')}>
        <label htmlFor={id} className={cx('btnUpload')} style={{ height: height }}>
          <img src={icons.commonIconUpload} alt={t('common_img_text_alt')} className={cx('uploadIcon')} />

          {textBtn ?? (
            <p>
              {t('common_component_upload_file_btn')}
              <span className={cx('browseBtn')}>{t('common_component_upload_browse')}</span>
            </p>
          )}

          <input
            id={id}
            type='file'
            accept={acceptFile?.join(',') || '*/*'}
            multiple={multiple}
            className={cx('inputUpload')}
            onChange={handleUploadChange}
          />
        </label>

        {fileList.length > DEFAULT_NUMBER_ZERO && (
          <div className={cx('uploadFile')}>
            {fileList.map((file: IUploadFile, index: number) => (
              <div key={index} className={cx('filePreviewWrap')} style={{ width }}>
                <img src={icons.commonIconDocsFile} alt={t('common_img_text_alt')} />
                <div className={cx('fileName')}>{file.name}</div>

                <div className={cx('iconClose')} onClick={() => handleRemoveImage(index)}>
                  <img src={icons.commonIconModalClose} alt={t('common_img_text_alt')} className={cx('iconCloseImg')} />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {errorMessage && <div className={cx('errMessage')}>{errorMessage}</div>}
      {uploadError && <div className={cx('errMessage')}>{uploadError}</div>}
    </div>
  );
};

export default BaseUploadImage;
