// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Location, useLocation, useNavigate, useParams } from 'react-router-dom';
// Components, Layouts, Pages
import { BaseButton, FormContact } from '~/components';
// Others
import { NAVIGATE_BACK } from '~/utils/constants/common';
import { ButtonTypeEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './FormContact.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const FormContactPage = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { contactId } = useParams();
  const { state: clientId }: Location<string> = useLocation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleBackToPreviousPage = () => {
    navigate(NAVIGATE_BACK);
  };
  //#endregion Handle Function

  return (
    <div id='formContactPage' className={cx('container')}>
      <BaseButton
        onClick={handleBackToPreviousPage}
        typeStyle={ButtonTypeEnum.ONLY_TEXT}
        iconLeft={icons.commonIconPrev}
        text={t('common_text_back')}
      />

      <div className={cx('header')}>
        <div className={cx('title')}>
          {contactId ? t('form_contact_edit_contact_title') : t('form_contact_add_contact_title')}
        </div>
      </div>

      <div className={cx('body')}>
        <FormContact contactId={contactId} clientId={clientId} />
      </div>
    </div>
  );
};

export default FormContactPage;
