// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
// Components, Layouts, Pages
import { ModalUnderDevelopment, RingCentralConfirmModal } from '~/components';
// Others
import { LoadingData, RingCentralContext } from '~/context';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { disconnectQuickBooks, getAuthUrl } from '~/thunks/quickBook/quickBookThunk';
import { userActions } from '~/thunks/user/userSlice';
import { IUserAccount } from '~/utils/interface/user';
import { MESSAGE_CONNECTED_QUICK_BOOK } from '~/utils/constants/common';
import { ConnectionStatusEnum, QuickBooksStatusEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './IntegrationTab.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const IntegrationTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loadingData = useContext(LoadingData);
  const userInfo: IUserAccount = useAppSelector((state) => state.userState.userProfile);
  const { ringCentralStatus, connect: connectRC, disconnect: disconnectRC } = useContext(RingCentralContext);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [openDisconnectConfirm, setOpenDisconnectConfirm] = useState<boolean>(false);
  const [isShowUnderDevelopment, setIsShowUnderDevelopment] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data === MESSAGE_CONNECTED_QUICK_BOOK) {
        dispatch(userActions.connectedQuickBook());
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const connectRingCentral = () => {
    connectRC && connectRC();
  };

  const disconnectRingCentral = () => {
    disconnectRC && disconnectRC();
  };

  const handleCloseDisconnectConfirm = () => {
    setOpenDisconnectConfirm(false);
  };

  const handleDisconnectConfirm = () => {
    setOpenDisconnectConfirm(false);
    disconnectRingCentral();
  };

  const handleOpenDisconnectConfirm = () => {
    setOpenDisconnectConfirm(true);
  };

  const handleConnectRingCentral = () => {
    if (ringCentralStatus === ConnectionStatusEnum.CONNECTING) return;

    connectRingCentral();

    // setIsShowUnderDevelopment(true);
  };

  const handleDisconnectRingCentral = () => {
    disconnectRingCentral();
  };

  const handleConnectQuickBooks = () => {
    loadingData?.show();

    dispatch(getAuthUrl())
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        const url = res.data.url;
        window.open(url, '_blank');
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleDisconnectQuickBooks = () => {
    loadingData?.show();

    dispatch(disconnectQuickBooks())
      .unwrap()
      .then((res) => {
        return dispatch(userActions.disconnectedQuickBook());
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleChangeStatusQuickBooks = () => {
    if (userInfo.quickBook.status === QuickBooksStatusEnum.CONNECTED) {
      handleDisconnectQuickBooks();
    } else {
      handleConnectQuickBooks();
    }

    // setIsShowUnderDevelopment(true);
  };

  const handleCloseUnderDevelopment = () => {
    setIsShowUnderDevelopment(false);
  };
  //#endregion Handle Function

  return (
    <div id='integrationTabComponent' className={cx('container')}>
      <div className={cx('body')}>
        <div className={cx('card')}>
          <div className={cx('cardHeader')}>
            <div className={cx('cardHeaderLogo')}>
              <img
                src={icons.integrationIconIntuitQuickBooksLogo}
                className={cx('cardHeaderLogoImg')}
                alt={t('common_img_text_alt')}
              />
            </div>
          </div>

          <div className={cx('cardBody')}>
            <div className={cx('cardTitle')}>{t('integration_quick_books_title')}</div>

            <div className={cx('cardDesc')}>{t('integration_quick_books_desc')}</div>
          </div>

          <div className={cx('cardActions')}>
            <button
              className={cx('cardActionsItem', {
                disconnected: userInfo?.quickBook?.status === QuickBooksStatusEnum.CONNECTED,
              })}
              onClick={handleChangeStatusQuickBooks}
            >
              {userInfo?.quickBook?.status === QuickBooksStatusEnum.CONNECTED
                ? t('common_btn_disconnect')
                : t('common_btn_connect_now')}
            </button>
          </div>
        </div>

        <div className={cx('card')}>
          <div className={cx('cardHeader')}>
            <div className={cx('cardHeaderLogo')}>
              <img
                src={icons.integrationIconRingCentralLogo}
                className={cx('cardHeaderLogoImg')}
                alt={t('common_img_text_alt')}
              />
            </div>
          </div>

          <div className={cx('cardBody')}>
            <div className={cx('cardTitle')}>{t('integration_ring_central_title')}</div>

            <div className={cx('cardDesc')}>{t('integration_ring_central_desc')}</div>
          </div>

          <div className={cx('cardActions')}>
            {ringCentralStatus === ConnectionStatusEnum.CONNECTED ? (
              <button className={cx('cardActionsItem', 'disconnected')} onClick={handleDisconnectRingCentral}>
                {t('common_btn_disconnect')}
              </button>
            ) : (
              <button
                className={cx('cardActionsItem', {
                  connecting: ringCentralStatus === ConnectionStatusEnum.CONNECTING,
                })}
                onClick={handleConnectRingCentral}
                disabled={ringCentralStatus === ConnectionStatusEnum.CONNECTING}
              >
                {t('common_btn_connect_now')}
              </button>
            )}
          </div>
        </div>
      </div>

      {openDisconnectConfirm && (
        <RingCentralConfirmModal
          isOpen={openDisconnectConfirm}
          title={t('ring_central_confirm_title')}
          description={t('ring_central_confirm_description')}
          confirm={t('ring_central_confirm_confirm')}
          onClose={handleCloseDisconnectConfirm}
          onConfirm={handleDisconnectConfirm}
        />
      )}

      {isShowUnderDevelopment && <ModalUnderDevelopment onClose={handleCloseUnderDevelopment} />}
    </div>
  );
};

export default IntegrationTab;
