// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
// Components, Layouts, Pages
import { BaseButton, ModalUnderDevelopment } from '~/components';
// Others
import { ButtonTypeEnum, TimeFormatEnum } from '~/utils/enum';
import { convertEnumToString, convertTime } from '~/utils/helper';
import { EMPTY_STRING } from '~/utils/constants/common';
import { IDetailUnavailability } from '~/utils/interface/schedule';
// Styles, images, icons
import styles from './SummaryEventTab.module.scss';

const cx = classNames.bind(styles);

type Props = {
  detailUnavailability: IDetailUnavailability;
  onDelete?: (id?: string) => void;
};

const SummaryEventTab = (props: Props) => {
  //#region Destructuring Props
  const { detailUnavailability, onDelete } = props;

  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleClickUnderDevelop = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const getRangeDate = () => {
    if (!detailUnavailability) {
      return EMPTY_STRING;
    }

    const startDate = `${detailUnavailability.startDate} ${detailUnavailability.startTime}`;
    const endDate = `${detailUnavailability.endDate} ${detailUnavailability.endTime}`;

    const rangeDate = `${convertTime(new Date(startDate), TimeFormatEnum.MM_DD_YYYY_HH_AM_PM)} - ${convertTime(
      new Date(endDate),
      TimeFormatEnum.MM_DD_YYYY_HH_AM_PM
    )}`;
    return rangeDate;
  };
  //#endregion Handle Function

  return (
    <div id='summaryTabComponent' className={cx('summaryTabComponent')}>
      <div className={cx('contentBody')}>
        <div className={cx('contentLabelValue')}>
          <div className={cx('contentLabel')}>{t('summary_schedule_form_dates_label')}</div>
          <div className={cx('contentValueDates')}>{getRangeDate()}</div>
        </div>

        <div className={cx('contentLabelValue')}>
          <div className={cx('contentLabel')}>{t('summary_schedule_form_reason_label')}</div>
          <div className={cx('contentValue')}>
            {detailUnavailability?.reason ? convertEnumToString(detailUnavailability?.reason) : EMPTY_STRING}
          </div>
        </div>

        <div className={cx('contentLabelValue')}>
          <div className={cx('contentLabel')}>{t('summary_schedule_form_status_label')}</div>
          <div className={cx('contentValue')}>
            {detailUnavailability?.status ? convertEnumToString(detailUnavailability?.status) : EMPTY_STRING}
          </div>
        </div>

        <div className={cx('contentLabelValue')}>
          <div className={cx('contentLabel')}>{t('summary_schedule_form_last_note_label')}</div>
          <div className={cx('contentValue')}>{detailUnavailability?.lastNote || EMPTY_STRING}</div>
        </div>
      </div>

      <div className={cx('line')} />

      <div className={cx('footerButton')}>
        <div className={cx('groupButton')}>
          {/* <BaseButton
            onClick={handleClickUnderDevelop}
            typeStyle={ButtonTypeEnum.PRIMARY}
            text={t('common_edit_label')}
            minWidth={80}
          /> */}

          {/* <BaseButton
            onClick={() => onDelete?.(detailUnavailability?.id)}
            typeStyle={ButtonTypeEnum.DELETE}
            text={t('common_delete_label')}
            minWidth={80}
          /> */}
        </div>
      </div>

      {isShowModalUnderDevelopment && <ModalUnderDevelopment onClose={handleClickUnderDevelop} />}
    </div>
  );
};

export default SummaryEventTab;
