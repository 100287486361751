// Libs
import classNames from 'classnames/bind';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
import { BaseButton, BaseFilter, BasePagination, BaseSelect, CaregiverItem, InputSearch, ToolBar } from '~/components';
// Others
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { caregiverActions, selectIsRefreshCaregiverList } from '~/thunks/caregiver/caregiverSlice';
import { getListCaregiver } from '~/thunks/caregiver/caregiverThunk';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_LIMIT_MAX_ITEM,
  DEFAULT_LIMIT_PAGE,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  optionAllCaregiver,
} from '~/utils/constants/common';
import useDebounce from '~/utils/customHook';
import { AccountRoleCodesEnum, ButtonTypeEnum, StatusFilterEnum } from '~/utils/enum';
import { ICaregiver, IFilterCaregiver } from '~/utils/interface/caregiver';
import { IBaseOption, IListDataResponse, IListQueryParams, IPaginationResponse } from '~/utils/interface/common';
import { adminRouteAbsolute, staffRouteAbsolute, superAdminRouteAbsolute } from '~/utils/constants/route';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './CaregiverShowAll.module.scss';
import { getListLocations } from '~/thunks/crm/location/locationThunk';

type Props = {
  role: string;
};

const cx = classNames.bind(styles);

const CaregiverShowAll = (props: Props) => {
  //#region Destructuring Props
  const { role } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(() => Number(params?.page ?? DEFAULT_CURRENT_PAGE), [params?.page]);
  const textSearchParams = useMemo<string>(() => String(params?.textSearch || EMPTY_STRING), [params?.textSearch]);
  const locationParams = useMemo<string>(() => String(params?.locationId || EMPTY_STRING), [params?.locationId]);
  //#endregion Declare Hook

  //#region Selector
  const isRefreshCaregiverList = useSelector(selectIsRefreshCaregiverList);
  //#endregion Selector

  //#region Declare State
  const [hasInteracted, setHasInteracted] = useState<boolean>(false);
  const [caregiverList, setCaregiverList] = useState<ICaregiver[]>([]);
  const [pagination, setPagination] = useState<IPaginationResponse>();
  const [searchKey, setSearchKey] = useState<string>(EMPTY_STRING);
  const [locations, setLocations] = useState<IBaseOption[]>([]);
  const renderSearchKey = useMemo(() => {
    if (!hasInteracted && textSearchParams) {
      return textSearchParams;
    }

    if (hasInteracted && searchKey === EMPTY_STRING) {
      return EMPTY_STRING;
    }

    if (searchKey) {
      return searchKey;
    }

    return EMPTY_STRING;
  }, [searchKey, textSearchParams, hasInteracted]);
  const debouncedSearchKey = useDebounce<string>(renderSearchKey.trim() || EMPTY_STRING, DEFAULT_DELAY_TIME);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetListLocations();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { locationId, ...restParams } = params;

    const newParams = {
      ...restParams,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      ...(params?.locationId === StatusFilterEnum.ALL ? {} : { locationId: locationId }),
    };

    handleGetListCaregiver(newParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!textSearchParams) setSearchKey(EMPTY_STRING);
  }, [textSearchParams]);

  useEffect(() => {
    if (!pagination) return;

    if (debouncedSearchKey) {
      setSearchParams({
        ...params,
        page: DEFAULT_CURRENT_PAGE.toString(),
        limit: DEFAULT_LIMIT_PAGE.toString(),
        textSearch: debouncedSearchKey,
      });
    } else {
      const { textSearch, ...rest } = params;
      setSearchParams(rest);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);

  useEffect(() => {
    if (!isRefreshCaregiverList) {
      return;
    }

    const { locationId, ...restParams } = params;

    const newParams = {
      ...restParams,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_LIMIT_PAGE,
      ...(debouncedSearchKey ? { textSearch: debouncedSearchKey } : {}),
      ...(params?.locationId === StatusFilterEnum.ALL ? {} : { locationId: locationId }),
    };

    handleGetListCaregiver(newParams);
    dispatch(caregiverActions.setRefreshList(false));
  }, [isRefreshCaregiverList]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListCaregiver = (payload: IListQueryParams) => {
    loading?.show();

    dispatch(getListCaregiver(payload))
      .unwrap()
      .then((res) => {
        const { responses, pagination }: IListDataResponse<ICaregiver[]> = res?.data;
        setCaregiverList(responses);
        setPagination(pagination);
      })
      .catch((err) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const handleGetListLocations = () => {
    const params: IListQueryParams = { page: DEFAULT_CURRENT_PAGE, limit: DEFAULT_LIMIT_MAX_ITEM };
    dispatch(getListLocations(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        const listLocation = res.data?.responses?.map((data) => {
          return { label: data.name || EMPTY_STRING, value: data.id };
        });
        setLocations([optionAllCaregiver, ...listLocation]);
      })
      .catch((err) => {});
  };

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value);
    setHasInteracted(true);
  };

  const handleAddCaregiver = () => {
    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRouteAbsolute.addCaregiver}`);
        return;

      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.addCaregiver}`);
        return;

      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(`${staffRouteAbsolute.addCaregiver}`);
        return;

      default:
        return;
    }
  };

  const handlePaginationChange = (page: number) => {
    setSearchParams({ ...params, page: page.toString(), limit: `${DEFAULT_LIMIT_PAGE}` });
  };

  const handleSubmitFilter = (valueFilter: IFilterCaregiver | undefined) => {
    if (!valueFilter) {
      return;
    }

    const { location, ...restParams } = params;

    setSearchParams({
      ...restParams,
      page: DEFAULT_NUMBER_ONE.toString(),
      limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
      ...(valueFilter?.locationId ? { locationId: valueFilter?.locationId } : {}),
    });
  };
  //#endregion Handle Function

  return (
    <div id='caregiverShowAllPage' className={cx('container')}>
      <div className={cx('headerToolBar')}>
        <ToolBar title={t('caregiver_show_all_caregiver_title')}>
          {/* <BaseButton iconLeft={icons.commonIconSort} text={t('common_text_filter')} width={67} height={36} /> */}
          <InputSearch
            height={36}
            placeholder={t('common_placeholder_search_by_name')}
            onChange={handleChangeSearch}
            value={searchKey}
          />
          <BaseFilter<IFilterCaregiver>
            defaultValue={{ locationId: StatusFilterEnum.ALL }}
            onApply={handleSubmitFilter}
            valueFilter={{
              locationId: locationParams || StatusFilterEnum.ALL,
            }}
          >
            {({ valueFilter, onChange }) => {
              return (
                <div className={cx('filterWrap')}>
                  <div className={cx('contentFilter')}>
                    <span className={cx('statusLabel')}>{t('caregiver_show_all_filter_location_label')}</span>
                    <BaseSelect
                      width={175}
                      value={valueFilter?.locationId}
                      placeholder={t('common_placeholder_select')}
                      options={locations}
                      onChange={(option) => {
                        onChange({
                          name: 'locationId',
                          value: option?.value.toString(),
                        });
                      }}
                      mode='search'
                    />
                  </div>
                </div>
              );
            }}
          </BaseFilter>
          <BaseButton
            typeStyle={ButtonTypeEnum.PRIMARY}
            iconLeft={icons.commonIconPlus}
            text={t('caregiver_show_all_add_caregiver_button')}
            onClick={handleAddCaregiver}
            width={130}
            height={36}
          />
        </ToolBar>
      </div>

      <div className={cx('bodyContent')}>
        {caregiverList?.length > DEFAULT_NUMBER_ZERO ? (
          <div className={cx('bodyWrapCaregiver')}>
            {caregiverList.map((item, index) => (
              <CaregiverItem key={index} data={item} />
            ))}
          </div>
        ) : (
          <div className={cx('noDataAvailable')}>{t('common_empty_data')}</div>
        )}
      </div>

      <div className={cx('pagination')}>
        <BasePagination
          onChange={handlePaginationChange}
          defaultCurrentPage={pageSelected}
          totalItems={pagination?.totalItems}
          totalPages={pagination?.totalPages}
        />
      </div>
    </div>
  );
};

export default CaregiverShowAll;
