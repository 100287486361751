import { urlApiSkill } from '~/utils/constants/api';
import { IFormSkill, IParamsGetDetailSkill } from '~/utils/interface/caregivers/skill';
import axiosClient from '../axiosClient';
import { BaseResponse } from '~/utils/interface/common';

const skillApi = {
  createSkill(body: IFormSkill) {
    const url = `${urlApiSkill.createSkill}`;
    return axiosClient.post<BaseResponse>(url, body);
  },

  getDetailSkill(params: IParamsGetDetailSkill) {
    const url = `${urlApiSkill.getDetailSkill}`;
    return axiosClient.get<BaseResponse<IFormSkill>>(url, { params });
  },

  editSkill(body: IFormSkill) {
    const url = `${urlApiSkill.editSkill}`;
    return axiosClient.put<BaseResponse>(url, body);
  },
};
export default skillApi;
