// Libs
import classNames from 'classnames/bind';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TFunction } from 'i18next';
import { useState, useEffect, ChangeEvent, useContext, useMemo } from 'react';
// Components, Layouts, Pages
import { BaseButton, BaseInput, BaseSelect, BaseTextarea, BaseUploadImage, PlacesAutocomplete } from '~/components';
// Others
import {
  ButtonTypeEnum,
  RelatesClientTypeEnum,
  CRMEnum,
  RadioCrmEnum,
  UpdateClientNameModalEnum,
  StatusEnum,
} from '~/utils/enum';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_LIMIT_MAX_ITEM,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  MAX_FILE_SIZE_FILE_20MB,
  MODE_SEARCH,
  NAVIGATE_BACK,
  RegExp,
} from '~/utils/constants/common';
import { mockOptionsTimezone } from '~/mockData';
import { IAddress, IBaseOption, IListQueryParams } from '~/utils/interface/common';
import { getDetailClients, getListClients, updateClient } from '~/thunks/crm/clients/clientsThunk';
import { createClients } from '~/thunks/crm/clients/clientsThunk';
import { LoadingData } from '~/context';
import { createFormData, formatPhoneNumber, getUserName } from '~/utils/helper';
import { getListLocations } from '~/thunks/crm/location/locationThunk';
import { IFormCreateProspectCrm } from '~/utils/interface/crm/prospect';
import { useAppDispatch } from '~/redux/hooks';
import { IFormUpdateClient } from '~/utils/interface/activity';
import { IListQueryParamsClients } from '~/utils/interface/crm/clients';
// Styles, images, icons
import styles from './CrmAddProspect.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup.object({
    profileClient: yup
      .array()
      .of(
        yup.object({
          file: yup
            .mixed<File>()
            .optional()
            .test('fileSize', t('crm_add_modal_files_size_limit'), (value) => {
              if (!value) return true;
              return value instanceof File && value.size <= MAX_FILE_SIZE_FILE_20MB;
            }),
        })
      )
      .optional(),
    firstName: yup.string().trim().required(t('common_message_required_error')),
    lastName: yup.string().trim().required(t('common_message_required_error')),
    address: yup.string().trim().required(t('common_message_required_error')),
    city: yup.string().trim().required(t('common_message_required_error')),
    state: yup.string().trim().required(t('common_message_required_error')),
    postalCode: yup.string().trim().required(t('common_message_required_error')),
    mobilePhone: yup.string().trim().required(t('common_message_required_error')),
    locationId: yup.string().trim().required(t('common_message_required_error')),
    referrerId: yup.string().trim().optional(),
    timeZone: yup.string().trim().required(t('common_message_required_error')),
    email: yup
      .string()
      .trim()
      .optional()
      .test('isValidEmail', t('common_validate_invalid_email'), (value) => {
        if (value && !RegExp.EMAIL.test(value)) return false;
        return true;
      }),
    middleName: yup.string().trim().optional(),
    secondaryAddress: yup.string().trim().optional(),
    notes: yup.string().trim().optional(),
    ssn: yup.string().trim().optional(),
    homePhone: yup.string().trim().optional(),
    workPhone: yup.string().trim().optional(),
    type: yup.mixed<RelatesClientTypeEnum>().optional(),
  });
};

const CrmAddProspect = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loadingContext = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const { prospectId } = useParams();

  const defaultValues: IFormCreateProspectCrm = useMemo(() => {
    return {
      profileClient: [],
      firstName: EMPTY_STRING,
      middleName: EMPTY_STRING,
      lastName: EMPTY_STRING,
      address: EMPTY_STRING,
      secondaryAddress: EMPTY_STRING,
      city: EMPTY_STRING,
      state: EMPTY_STRING,
      postalCode: EMPTY_STRING,
      mobilePhone: EMPTY_STRING,
      referrerId: EMPTY_STRING,
      locationId: EMPTY_STRING,
      timeZone: EMPTY_STRING,
      ssn: EMPTY_STRING,
      homePhone: EMPTY_STRING,
      workPhone: EMPTY_STRING,
      email: EMPTY_STRING,
      notes: EMPTY_STRING,
      type: RelatesClientTypeEnum.PROSPECT || EMPTY_STRING,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isDirty },
  } = useForm<IFormCreateProspectCrm>({
    resolver: yupResolver(schema(t)),
    defaultValues: defaultValues,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  // //#region Declare State
  const [locations, setLocations] = useState<IBaseOption[]>([]);
  const [referralList, setReferralList] = useState<IBaseOption[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetListLocations();
    handleGetListReferral();
  }, []);

  useEffect(() => {
    if (!prospectId) return;

    handleGetDetailClient(prospectId);
  }, [prospectId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleSubmitForm = async (dataForm: IFormCreateProspectCrm) => {
    const newData: IFormCreateProspectCrm = {
      ...dataForm,
      profileClient:
        Array.isArray(dataForm?.profileClient) && dataForm?.profileClient?.length > DEFAULT_NUMBER_ZERO
          ? dataForm?.profileClient
          : undefined,

      homePhone: dataForm.homePhone?.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING),
      mobilePhone: dataForm.mobilePhone?.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING),
      workPhone: dataForm.workPhone?.replace(RegExp.NON_DIGIT_REGEX, EMPTY_STRING),
    };

    const filterPayload: Partial<IFormCreateProspectCrm> = {};
    const filterEditPayload: Partial<IFormCreateProspectCrm> = {};

    Object.entries(newData).forEach(([key, value]) => {
      if (
        (value !== null && value !== undefined && value !== EMPTY_STRING && !Array.isArray(value)) ||
        (Array.isArray(value) && value?.length > DEFAULT_NUMBER_ZERO)
      ) {
        filterPayload[key as keyof IFormCreateProspectCrm] = value;
      }
      filterEditPayload[key as keyof IFormCreateProspectCrm] = value;
    });

    const formData = await createFormData(
      prospectId ? filterEditPayload : filterPayload,
      UpdateClientNameModalEnum.PROFILE_CLIENT
    );

    if (prospectId) {
      handleUpdateProspect(formData);
    } else {
      handleCreateProspect(formData);
    }
  };

  const handleCreateProspect = (formData: FormData) => {
    loadingContext?.show();

    dispatch(createClients(formData))
      .unwrap()
      .then((res) => {
        handleGoBack();
      })
      .catch((err) => {})
      .finally(() => loadingContext?.hide());
  };

  const handleGoBack = () => {
    navigate(NAVIGATE_BACK);
  };

  const handleChangeAddress = (address: IAddress) => {
    setValue('address', address?.address ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    address?.city && setValue('city', address?.city ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    address?.state && setValue('state', address?.state ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    address?.zipCode &&
      setValue('postalCode', address?.zipCode ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    setValue('lat', Number(address?.lat));
    setValue('lng', Number(address?.lng));
  };

  const handleGetListReferral = () => {
    const params: IListQueryParamsClients = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
      type: [CRMEnum.REFERRAL],
      status: StatusEnum.ACTIVE,
    };
    dispatch(getListClients(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        const listReferer = res.data?.responses?.map((data) => {
          if (data.type === CRMEnum.REFERRAL && data.referralRole === RadioCrmEnum.ORGANIZATION) {
            return { label: data?.organizationName || EMPTY_STRING, value: data.id };
          }
          return {
            label: getUserName(data.firstName, data.middleName, data.lastName),
            value: data.id,
          };
        });
        setReferralList(listReferer);
      })
      .catch((err) => {})
      .finally(() => {});
  };

  const handleGetListLocations = () => {
    const params: IListQueryParams = { page: DEFAULT_CURRENT_PAGE, limit: DEFAULT_LIMIT_MAX_ITEM };
    dispatch(getListLocations(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        const listLocation = res.data?.responses?.map((data) => ({
          label: data.name || EMPTY_STRING,
          value: data.id,
        }));
        setLocations(listLocation);
      })
      .catch((err) => {})
      .finally(() => {
        loadingContext?.hide();
      });
  };

  const handleGetDetailClient = async (clientId: string) => {
    loadingContext?.show();

    await dispatch(getDetailClients(clientId))
      .unwrap()
      .then((response) => {
        if (!response) return;

        const data = response.data;

        const formattedData = {
          firstName: data.firstName || EMPTY_STRING,
          middleName: data.middleName || EMPTY_STRING,
          lastName: data.lastName || EMPTY_STRING,
          address: data.address || EMPTY_STRING,
          secondaryAddress: data.secondaryAddress || EMPTY_STRING,
          city: data.city || EMPTY_STRING,
          state: data.state || EMPTY_STRING,
          postalCode: data.postalCode || EMPTY_STRING,
          notes: data.notes || EMPTY_STRING,
          ssn: data.ssn || EMPTY_STRING,
          homePhone: data.homePhone || EMPTY_STRING,
          mobilePhone: data.mobilePhone || EMPTY_STRING,
          workPhone: data.workPhone || EMPTY_STRING,
          email: data.email || EMPTY_STRING,
          locationId: data?.location?.id || EMPTY_STRING,
          timeZone: data.timeZone || EMPTY_STRING,
          referrerId: data.referrer?.id || EMPTY_STRING,
          profileClient: data.avatarUrl ? [{ url: data.avatarUrl }] : undefined,
        };

        reset(formattedData);
      })
      .catch((error) => {})
      .finally(() => loadingContext?.hide());
  };

  const handleUpdateProspect = (formData: FormData) => {
    if (!prospectId) return;

    loadingContext?.show();

    const payload: IFormUpdateClient = {
      clientId: prospectId,
      body: formData,
    };

    dispatch(updateClient(payload))
      .unwrap()
      .then((res) => {
        handleGoBack();
      })
      .catch((error) => {})
      .finally(() => {
        loadingContext?.hide();
      });
  };
  //#endregion Handle Function

  return (
    <div id='crmAddProspect' className={cx('container')}>
      <BaseButton
        onClick={handleGoBack}
        typeStyle={ButtonTypeEnum.ONLY_TEXT}
        iconLeft={icons.commonIconPrev}
        text={t('common_text_back')}
      />
      <div className={cx('header')}>
        <div className={cx('title')}>{t(prospectId ? 'crm_edit_prospect_title' : 'crm_add_prospect_title')}</div>
      </div>

      <div className={cx('body')}>
        <form className={cx('form')} onSubmit={handleSubmit(handleSubmitForm)}>
          <div className={cx('addProspectFormContent')}>
            <Controller
              name={'profileClient'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseUploadImage
                  label={t('crm_add_modal_profile_image_label')}
                  textBtn={t('crm_add_modal_profile_image_placeholder')}
                  height={64}
                  maxUpload={DEFAULT_NUMBER_ONE}
                  defaultImageList={value ?? undefined}
                  onChange={onChange}
                  errorMessage={errors.profileClient?.message}
                />
              )}
            />

            <div className={cx('threeCol')}>
              <Controller
                name='firstName'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='firstName'
                    label={t('crm_add_modal_first_name_label')}
                    value={value}
                    onChange={onChange}
                    messageError={errors.firstName?.message}
                    required
                  />
                )}
              />

              <Controller
                name='middleName'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='middleName'
                    label={t('crm_add_modal_middle_name_label')}
                    onChange={onChange}
                    value={value || EMPTY_STRING}
                  />
                )}
              />

              <Controller
                name='lastName'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='lastName'
                    label={t('crm_add_modal_last_name_label')}
                    value={value || EMPTY_STRING}
                    onChange={onChange}
                    messageError={errors.lastName?.message}
                    required
                  />
                )}
              />
            </div>

            <div className={cx('twoCol')}>
              <Controller
                name='address'
                control={control}
                render={({ field: { value } }) => (
                  <PlacesAutocomplete
                    id='address'
                    value={value || EMPTY_STRING}
                    label={t('crm_add_modal_address_label')}
                    onChange={(address) => handleChangeAddress(address)}
                    required
                    messageError={errors.address?.message}
                  />
                )}
              />

              <Controller
                name='secondaryAddress'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='secondaryAddress'
                    label={t('crm_add_modal_secondary_address_label')}
                    value={value || EMPTY_STRING}
                    onChange={onChange}
                  />
                )}
              />
            </div>

            <div className={cx('threeCol')}>
              <Controller
                name='city'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='city'
                    label={t('crm_add_modal_city_label')}
                    value={value || EMPTY_STRING}
                    onChange={onChange}
                    messageError={errors.city?.message}
                    required
                  />
                )}
              />

              <Controller
                name='state'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='state'
                    value={value || EMPTY_STRING}
                    label={t('crm_add_modal_state_label')}
                    onChange={onChange}
                    required
                    messageError={errors.state?.message}
                  />
                )}
              />

              <Controller
                name='postalCode'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='postalCode'
                    label={t('crm_add_modal_postal_code_label')}
                    value={value || EMPTY_STRING}
                    onChange={onChange}
                    messageError={errors.postalCode?.message}
                    required={true}
                  />
                )}
              />
            </div>

            <Controller
              name='notes'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseTextarea
                  id='notes'
                  label={t('crm_add_modal_notes_label')}
                  value={value || EMPTY_STRING}
                  onChange={onChange}
                />
              )}
            />

            <Controller
              name='ssn'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='ssn'
                  label={t('crm_add_modal_ssn_label')}
                  value={value || EMPTY_STRING}
                  onChange={onChange}
                />
              )}
            />

            <div className={cx('twoCol')}>
              <Controller
                name='homePhone'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='homePhone'
                    label={t('crm_add_modal_home_phone_label')}
                    value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const { value } = event.target;
                      const formattedValue = formatPhoneNumber(value);
                      onChange(formattedValue);
                    }}
                  />
                )}
              />

              <Controller
                name='mobilePhone'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='mobilePhone'
                    label={t('crm_add_modal_mobile_phone_label')}
                    value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const { value } = event.target;
                      const formattedValue = formatPhoneNumber(value);
                      onChange(formattedValue);
                    }}
                    messageError={errors.mobilePhone?.message}
                    required
                  />
                )}
              />
            </div>

            <div className={cx('twoCol')}>
              <Controller
                name='workPhone'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='workPhone'
                    label={t('crm_add_modal_work_phone_label')}
                    value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const { value } = event.target;
                      const formattedValue = formatPhoneNumber(value);
                      onChange(formattedValue);
                    }}
                  />
                )}
              />

              <Controller
                name='email'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id='email'
                    label={t('crm_add_modal_email_label')}
                    value={value || EMPTY_STRING}
                    onChange={onChange}
                    messageError={errors.email?.message}
                  />
                )}
              />
            </div>

            <div className={cx('twoCol')}>
              <Controller
                name='locationId'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseSelect
                    options={locations || []}
                    label={t('crm_add_modal_location_label')}
                    placeholder={t('common_select_placeholder')}
                    onChange={({ value }) => onChange(value)}
                    value={value || EMPTY_STRING}
                    mode='search'
                    errorMessage={errors.locationId?.message}
                    required
                  />
                )}
              />

              <Controller
                name='referrerId'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseSelect
                    options={referralList || []}
                    label={t('crm_add_modal_referrer_label')}
                    placeholder={t('common_select_placeholder')}
                    onChange={({ value }) => onChange(value)}
                    value={value || EMPTY_STRING}
                    mode={MODE_SEARCH}
                    errorMessage={errors.referrerId?.message}
                  />
                )}
              />
            </div>

            <Controller
              name='timeZone'
              control={control}
              render={({ field: { onChange, value } }) => (
                <BaseSelect
                  options={mockOptionsTimezone || []}
                  label={t('crm_add_modal_time_zone_label')}
                  placeholder={t('common_select_placeholder')}
                  onChange={({ value }) => onChange(value)}
                  value={value || EMPTY_STRING}
                  errorMessage={errors.timeZone?.message}
                  required
                />
              )}
            />
          </div>

          <div className={cx('footerButton')}>
            <BaseButton text={t('common_go_back_label')} width={80} onClick={handleGoBack} type='button' />

            <BaseButton
              type='submit'
              text={t(prospectId ? 'common_update_label' : 'common_save_label')}
              typeStyle={ButtonTypeEnum.PRIMARY}
              width={80}
              disabled={prospectId ? !isDirty : false}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CrmAddProspect;
