// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import { CREATE_CAMPAIGN, EDIT_CAMPAIGN, GET_DETAIL_CAMPAIGN, GET_LIST_CAMPAIGN } from '~/utils/constants/actionType';
import { IFormCreateCampaign, IFormUpdateCampaign, IQueryParamsCampaign } from '~/utils/interface/crm/campaign';
import campaignApi from '~/apis/crm/campaign';

export const getListCampaign = createAsyncThunk(
  GET_LIST_CAMPAIGN,
  async (payload: IQueryParamsCampaign, { rejectWithValue }) => {
    try {
      const res = await campaignApi.getListCampaign(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createCampaign = createAsyncThunk(
  CREATE_CAMPAIGN,
  async (payload: IFormCreateCampaign, { rejectWithValue }) => {
    try {
      const res = await campaignApi.createCampaign(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDetailCampaign = createAsyncThunk(GET_DETAIL_CAMPAIGN, async (id: string, { rejectWithValue }) => {
  try {
    const res = await campaignApi.getDetailCampaign(id);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const editCampaign = createAsyncThunk(
  EDIT_CAMPAIGN,
  async (payload: IFormUpdateCampaign, { rejectWithValue }) => {
    try {
      const res = await campaignApi.editCampaign(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
