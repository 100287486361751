// Libs
import { StyleSheet, Text, View } from '@react-pdf/renderer';
// Components, Layouts, Pages
// Others
// Styles, images, icons

type Props = {};

const HeaderTableInvoicePdf = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <View style={styles.container}>
      <View style={styles.col}>
        <Text>DATE</Text>
      </View>
      <View style={styles.caregiverCol}>
        <Text>CAREGIVER</Text>
      </View>
      <View style={styles.col}>
        <Text>TYPE RATE</Text>
      </View>
      <View style={styles.col}>
        <Text>HOURS</Text>
      </View>
      <View style={styles.col}>
        <Text>RATE</Text>
      </View>
      <View style={styles.col}>
        <Text>PRICE</Text>
      </View>
    </View>
  );
};

export default HeaderTableInvoicePdf;

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderBottomColor: '#333333',
    backgroundColor: '#333333',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    flexGrow: 1,
    color: 'white',
  },

  caregiverCol: {
    flex: 0.25,
    paddingRight: 8,
    paddingLeft: 8,
    height: 24,
    justifyContent: 'center',
  },

  col: {
    flex: 0.15,
    paddingRight: 8,
    paddingLeft: 8,
    height: 24,
    justifyContent: 'center',
  },
});
