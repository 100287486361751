// Libs
import classNames from 'classnames/bind';
import { Box, Chip, InputLabel, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
// Components, Layouts, Pages
// Others
import { DEFAULT_NUMBER_ZERO, DEFAULT_LIMIT_EMAIL, EMPTY_STRING, RegExp } from '~/utils/constants/common';
import { KeyboardEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './BaseInputEmailMultiple.module.scss';
import './BaseInputEmailMultiple.scss';
import { icons } from '~/assets';

type Props = {
  id: string;
  label?: string;
  required?: boolean;
  value?: string[];
  minHeight?: number | string;
  height?: number | string;
  width?: number | string;
  errorMessage?: string;
  maxEmail?: number;
  onChange?: (values: string[]) => void;
};

const cx = classNames.bind(styles);

const BaseInputEmailMultiple = (props: Props) => {
  //#region Destructuring Props
  const {
    id,
    height = 34,
    width = '100%',
    label,
    required,
    value = [],
    errorMessage,
    maxEmail = DEFAULT_LIMIT_EMAIL,
    minHeight,
    onChange,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [inputValue, setInputValue] = useState<string>(EMPTY_STRING);
  const [valueEmail, setValueEmail] = useState<string[]>(value);
  const [errorMessageEmail, setErrorMessageEmail] = useState<string | undefined>(EMPTY_STRING);
  //#endregion Declare State

  //#region Declare Memo
  //#endregion Declare Memo

  //#region Implement Hook
  useEffect(() => {
    setErrorMessageEmail(errorMessage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  useEffect(() => {
    if (value.length <= DEFAULT_NUMBER_ZERO) return;

    setValueEmail(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.length]);

  useEffect(() => {
    onChange && onChange(valueEmail);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueEmail]);
  //#endregion Implement Hook

  //#region Handle Function
  const isHasEmail = () => {
    return valueEmail.length > DEFAULT_NUMBER_ZERO;
  };

  const removeEmail = (emailRemove: string) => {
    setValueEmail(valueEmail.filter((email) => email !== emailRemove));
    setErrorMessageEmail(EMPTY_STRING);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === KeyboardEnum.SPACE || event.key === KeyboardEnum.TAB || event.key === KeyboardEnum.ENTER) {
      event.preventDefault();

      if (inputValue.trim() === EMPTY_STRING) {
        return;
      }

      if (!inputValue.match(RegExp.EMAIL)) {
        setErrorMessageEmail(t('common_validate_invalid_email'));
        return;
      }

      if (valueEmail.length >= maxEmail) {
        setErrorMessageEmail(
          t('common_validate_max_email', {
            max: maxEmail,
          })
        );
        return;
      }

      if (valueEmail.length < maxEmail) {
        addEmail(inputValue);
        setInputValue(EMPTY_STRING);
        setErrorMessageEmail(EMPTY_STRING);
      }
    }
  };

  const addEmail = (newEmail: string) => {
    if (newEmail && !valueEmail.includes(newEmail) && valueEmail.length < maxEmail) {
      setValueEmail([...valueEmail, newEmail]);
    }
  };
  //#endregion Handle Function

  return (
    <Box component={'div'} id='baseInputEmailMultiple' className={cx('container')} style={{ width }}>
      {label && <InputLabel required={required}>{label}</InputLabel>}

      <Box
        component={'div'}
        className={cx('inputContainer', minHeight && 'inputContainerMinHeight')}
        style={{ height: isHasEmail() ? 'auto' : height, minHeight: minHeight }}
      >
        <label
          htmlFor={id}
          className={cx('valueGroup', valueEmail.length > DEFAULT_NUMBER_ZERO && 'valueGroupHasEmail')}
        >
          <div className={cx('listGroup')}>
            {valueEmail.map((email, index) => (
              <Box key={index} className={cx('emailContainer')}>
                <Chip
                  classes={{
                    root: cx('itemEmail'),
                    colorDefault: cx('itemEmailDefault'),
                    deleteIcon: cx('containerDeleteIcon'),
                    icon: cx('icon'),
                  }}
                  label={email}
                  onDelete={() => removeEmail(email)}
                  deleteIcon={
                    <img
                      className={cx('deleteIcon')}
                      src={icons.commonIconModalClose}
                      alt={t('common_alt_img', { ns: 'common/base' })}
                    />
                  }
                ></Chip>
              </Box>
            ))}

            <TextField
              className={cx('textField', valueEmail.length > DEFAULT_NUMBER_ZERO && 'inputHasEmail')}
              type={'text'}
              autoComplete='off'
              slotProps={{
                input: {
                  id: id,
                  className: cx('textFieldInput'),
                  sx: { height },
                },
              }}
              value={inputValue || EMPTY_STRING}
              onChange={handleInputChange}
              onKeyDown={handleKeyPress}
            />
          </div>
        </label>
      </Box>

      {errorMessageEmail && <p className={cx('errorMessage')}>{errorMessageEmail}</p>}
    </Box>
  );
};

export default BaseInputEmailMultiple;
