// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TFunction } from 'i18next';

// Components, Layouts, Pages
import {
  ToolBar,
  InputSearch,
  BaseFilter,
  BasePagination,
  BaseSelect,
  BaseTable,
  ModalUnderDevelopment,
  ThreeDotTableOptions,
  BaseButton,
  ModalChangeStatus,
} from '~/components';

// Others
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { IFilterContactClient } from '~/utils/interface/crm/clients';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  EMPTY_STRING,
  OPTIONS_CONTACT_STATUS,
  optionsStatusContact,
} from '~/utils/constants/common';
import { AccountRoleCodesEnum, ButtonTypeEnum, StatusFilterEnum, StorageEnum, TranslationEnum } from '~/utils/enum';
import { IListContact, IListQueryParamsContact, IPayloadChangeStatusContact } from '~/utils/interface/crm/contact';
import { ColumnTableType, IListDataResponse, IPaginationResponse } from '~/utils/interface/common';
import { convertEnumToString, convertPermissionClient, getUserName } from '~/utils/helper';
import { adminRouteAbsolute, staffRouteAbsolute, superAdminRouteAbsolute } from '~/utils/constants/route';
import useDebounce from '~/utils/customHook';
import { changeStatusContact, getListContact } from '~/thunks/crm/contact/contactThunk';
import { contactActions, selectIsRefreshContact } from '~/thunks/crm/contact/contactSlice';

// Styles, images, icons
import styles from './DetailContactTab.module.scss';
import { icons } from '~/assets';

const cx = classNames.bind(styles);

const columns = (
  t: TFunction<TranslationEnum.TRANSLATION>,
  handleViewDetail: (id: string) => void,
  handleEditContact: (id: string) => void,
  handleDeleteContact: (id: string) => void,
  handleShowChangeStatusContact: (record: IListContact) => void
): ColumnTableType<IListContact>[] => {
  return [
    {
      key: 'name',
      title: t('crm_detail_contacts_tab_table_name_title'),
      render: (_, record) => {
        return <>{record?.organization || getUserName(record?.firstName, record?.lastName)}</>;
      },
    },

    {
      key: 'relationship',
      title: t('crm_detail_contacts_tab_table_relationship_title'),
      render: (_, record) => {
        return <>{convertEnumToString(record?.client?.relationship) || EMPTY_STRING}</>;
      },
    },

    {
      key: 'permissions',
      title: t('crm_detail_contacts_tab_table_permissions_title'),
      render: (_, record) => {
        return (
          <>
            {record?.client?.permissionClient
              ? convertPermissionClient(record?.client?.permissionClient)
              : EMPTY_STRING}
          </>
        );
      },
    },

    {
      key: 'action',
      title: t('common_text_action'),
      render(value, record, index) {
        return (
          <ThreeDotTableOptions
            data={record}
            permissions={{
              isView: true,
              isEdit: true,
              isChangeStatus: true,
              isDelete: true,
            }}
            onView={() => handleViewDetail(record.id)}
            onEdit={() => handleEditContact(record.id)}
            onDelete={() => handleDeleteContact(record.id)}
            onChangeStatus={() => handleShowChangeStatusContact(record)}
          />
        );
      },
      width: 50,
    },
  ];
};

const DetailContactTab = () => {
  //#region Destructuring Props
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const role = localStorage.getItem(StorageEnum.ROLE);
  const { clientId } = useParams();
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  const isRefreshContactList = useSelector(selectIsRefreshContact);
  //#endregion Selector

  //#region Declare State
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pagination, setPagination] = useState<IPaginationResponse>();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(() => Number(params?.page ?? DEFAULT_CURRENT_PAGE), [params?.page]);
  const textSearchParams = useMemo<string>(() => String(params?.textSearch || EMPTY_STRING), [params?.textSearch]);
  const statusParams = useMemo<string>(() => String(params?.status || EMPTY_STRING), [params?.status]);
  const [searchKey, setSearchKey] = useState<string>(textSearchParams || EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey.trim() || EMPTY_STRING, DEFAULT_DELAY_TIME);
  const [contactList, setContactList] = useState<IListContact[]>([]);
  const [isShowChangeStatus, setIsShowChangeStatus] = useState<boolean>(false);
  const [isLoadingChangeStatus, setIsLoadingChangeStatus] = useState<boolean>(false);
  const [contactSelected, setContactSelected] = useState<IListContact | null>(null);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!textSearchParams) setSearchKey(EMPTY_STRING);
  }, [textSearchParams]);

  useEffect(() => {
    if (!clientId) return;

    const { status, tab, ...restParams } = params;

    const newParams = {
      ...restParams,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      ...(params?.status === StatusFilterEnum.ALL ? {} : { status: status }),
      clientId: clientId,
    };

    handleGetListContact(newParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!pagination) return;

    if (debouncedSearchKey) {
      setSearchParams({
        ...params,
        page: DEFAULT_CURRENT_PAGE.toString(),
        limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
        textSearch: debouncedSearchKey,
      });
    } else {
      const { textSearch, ...rest } = params;
      setSearchParams(rest);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);

  useEffect(() => {
    if (!isRefreshContactList || !clientId) return;

    const { status, tab, ...restParams } = params;

    const newParams = {
      ...restParams,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      ...(debouncedSearchKey ? { textSearch: debouncedSearchKey } : {}),
      ...(params?.status === StatusFilterEnum.ALL ? {} : { status: status }),
      clientId: clientId,
    };

    handleGetListContact(newParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshContactList]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleClickUnderDevelop = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value);
  };

  const handleSubmitFilter = (valueFilter: IFilterContactClient | undefined) => {
    if (!valueFilter) {
      return;
    }
    const { status, ...restParams } = params;

    setSearchParams({
      ...restParams,
      page: DEFAULT_NUMBER_ONE.toString(),
      limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
      ...(valueFilter?.status === StatusFilterEnum.ALL ? {} : { status: valueFilter?.status }),
    });
  };

  const handlePaginationChange = (page: number) => {
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleViewDetails = (id: string) => {
    if (!id) return;

    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.crmContact}/${id}`);
        break;

      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRouteAbsolute.crmContact}/${id}`);
        break;

      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(`${staffRouteAbsolute.crmContact}/${id}`);
        break;
    }
  };

  const handleEditContact = (id: string) => {
    if (!id) return;
    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.editContact}/${id}`, {
          state: clientId,
        });
        break;

      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRouteAbsolute.editContact}/${id}`, {
          state: clientId,
        });
        break;

      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(`${staffRouteAbsolute.editContact}/${id}`, {
          state: clientId,
        });
        break;
    }
  };

  const handleDeleteContact = () => {
    setIsShowModalUnderDevelopment(true);
  };

  const handleAddContact = () => {
    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(adminRouteAbsolute.createContact, { state: clientId });
        break;

      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(superAdminRouteAbsolute.createContact, { state: clientId });
        break;
    }
  };

  const handleContactRowClick = (data: IListContact) => {
    if (!data || !data?.id) return;

    handleViewDetails(data?.id);
  };

  const handleGetListContact = (params: IListQueryParamsContact) => {
    loading?.show();

    dispatch(getListContact(params))
      .unwrap()
      .then((res) => {
        const { responses, pagination }: IListDataResponse<IListContact[]> = res?.data;
        setContactList(responses);
        setPagination(pagination);
      })
      .catch((error) => {})
      .finally(() => loading?.hide());
  };

  const handleShowChangeStatusContact = (record: IListContact) => {
    if (!record) return;
    setContactSelected(record);
    setIsShowChangeStatus(true);
  };

  const handleCloseChangeStatus = () => {
    setContactSelected(null);
    setIsShowChangeStatus(false);
  };

  const handleChangeStatus = (status: string) => {
    if (!contactSelected) return;

    setIsLoadingChangeStatus(true);

    const payloadChangeStatus: IPayloadChangeStatusContact = {
      contactId: contactSelected.id,
      body: {
        status: status,
      },
    };

    dispatch(changeStatusContact(payloadChangeStatus))
      .unwrap()
      .then(() => {
        dispatch(contactActions.setRefreshList(true));
        handleCloseChangeStatus();
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoadingChangeStatus(false);
      });
  };
  //#endregion Handle Function

  return (
    <div id='detailContactClientTabComponent' className={cx('detailContactsComponent')}>
      <ToolBar title={t('crm_detail_contacts_tab_title')} fontSize={20}>
        <InputSearch
          height={36}
          placeholder={t('common_placeholder_search_by_name')}
          onChange={handleChangeSearch}
          value={searchKey}
        />

        <BaseFilter<IFilterContactClient>
          defaultValue={{ status: StatusFilterEnum.ALL }}
          onApply={handleSubmitFilter}
          valueFilter={{ status: statusParams || StatusFilterEnum.ALL }}
        >
          {({ valueFilter, onChange }) => {
            return (
              <div className={cx('filterWrap')}>
                <div className={cx('contentFilter')}>
                  <span className={cx('statusLabel')}>{t('crm_detail_contacts_tab_filter_title')}</span>
                  <BaseSelect
                    value={valueFilter?.status}
                    options={optionsStatusContact}
                    onChange={(option) => {
                      onChange({
                        name: 'status',
                        value: option?.value.toString(),
                      });
                    }}
                  />
                </div>
              </div>
            );
          }}
        </BaseFilter>

        <BaseButton
          typeStyle={ButtonTypeEnum.PRIMARY}
          iconLeft={icons.commonIconPlus}
          text={t('crm_add_contacts_title')}
          height={36}
          onClick={handleAddContact}
        />
      </ToolBar>

      <div className={cx('content')}>
        <div className={cx('tableWrap')}>
          <BaseTable
            columns={columns(
              t,
              handleViewDetails,
              handleEditContact,
              handleDeleteContact,
              handleShowChangeStatusContact
            )}
            dataSource={contactList ?? []}
            onClickRow={handleContactRowClick}
          />
        </div>

        <div className={cx('paginationTable')}>
          <BasePagination
            onChange={handlePaginationChange}
            defaultCurrentPage={pageSelected}
            totalItems={pagination?.totalItems}
            totalPages={pagination?.totalPages}
          />
        </div>
      </div>

      {isShowChangeStatus && (
        <ModalChangeStatus
          isOpen={isShowChangeStatus}
          dataDefault={contactSelected?.status || EMPTY_STRING}
          dataOption={OPTIONS_CONTACT_STATUS}
          onClose={handleCloseChangeStatus}
          onSubmit={handleChangeStatus}
          isLoading={isLoadingChangeStatus}
        />
      )}

      {isShowModalUnderDevelopment && <ModalUnderDevelopment onClose={handleClickUnderDevelop} />}
    </div>
  );
};

export default DetailContactTab;
