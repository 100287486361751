// Libs
import classNames from 'classnames/bind';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogPanel } from '@headlessui/react';
// Components, Layouts, Pages
import { ImageCircle, Loading, SummaryTab, Tab, TabNotes } from '~/components';
// Others
import { DIV_ELEMENT, EMPTY_STRING, eventPopupTabs } from '~/utils/constants/common';
import { TabQueryEventEnum } from '~/utils/enum';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { formatPhoneNumber, getUserName } from '~/utils/helper';
import { CommonIconEmail, CommonIconPhoneNumber } from '~/assets/svgComponents';
import { CORDUROY_500 } from '~/utils/constants/color';
import { GOOGLE_MAPS_URL, BLANK_GOOGLE_MAP } from '~/utils/constants/common';
import { getDetailShift } from '~/thunks/schedule/scheduleThunk';
import { optionsStatusShiftSchedule } from '~/mockData';
import { scheduleActions } from '~/thunks/schedule/scheduleSlice';
// Styles, images, icons
import styles from './NewEventPopup.module.scss';
import { icons } from '~/assets';

type Props<T extends { id: string }> = {
  isOpen: boolean;
  onClose: () => void;
  data?: T | null;
};

const cx = classNames.bind(styles);

const NewEventPopup = <T extends { id: string }>(props: Props<T>) => {
  //#region Destructuring Props
  const { onClose, data, isOpen } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  const { detailShift, isFetchingShiftNote } = useAppSelector((state) => state.scheduleState);
  //#endregion Selector

  //#region Declare State
  const [currentTab, setCurrentTab] = useState<string>(TabQueryEventEnum.SUMMARY);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!data || !isOpen) return;
    handleGetDetail();
    setCurrentTab(TabQueryEventEnum.SUMMARY);

    return () => {
      dispatch(scheduleActions.resetShiftDetail());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, data]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetDetail = (hideLoading?: boolean) => {
    if (!data?.id) return;
    !hideLoading && setIsLoading(true);

    dispatch(getDetailShift(data.id))
      .unwrap()
      .then((res) => {})
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderTabContent = useMemo(() => {
    switch (currentTab) {
      case TabQueryEventEnum.SUMMARY:
        return <SummaryTab onRefresh={() => handleGetDetail(true)} onCloseEvent={onClose} />;
      case TabQueryEventEnum.NOTES:
        return <TabNotes shiftId={data?.id} />;

      default:
        return <div className={cx('noDataAvailable')}>{t('common_empty_data')}</div>;
    }
  }, [currentTab, t, data]);

  const handleMap = () => {
    if (!detailShift?.location?.address) return;

    const googleMapUrl = GOOGLE_MAPS_URL(detailShift?.location?.address);
    window.open(googleMapUrl, BLANK_GOOGLE_MAP);
  };

  const handleTabChange = (newTab: string) => {
    setCurrentTab(newTab);
  };

  const handleClose = () => {
    onClose && onClose();
  };

  const getStatusLabel = () => {
    const status = optionsStatusShiftSchedule.find((status) => status.value === detailShift?.status);
    return status?.label;
  };
  //#endregion Handle Function

  return (
    <Dialog as={DIV_ELEMENT} open={isOpen} onClose={handleClose}>
      <div className={cx('container')}>
        <DialogPanel transition className={cx('modalEvent')}>
          <div id='eventPopup' className={cx('eventPopup')}>
            <div className={cx('mainBody')}>
              <div className={cx('headGroup')}>
                <div className={cx('infoGroup')}>
                  <div className={cx('nameGroup')}>
                    <ImageCircle
                      firstName={detailShift?.client?.firstName}
                      lastName={detailShift?.client?.lastName}
                      imageUrl={detailShift?.client?.avatarUrl}
                      width={90}
                      height={90}
                      fontSize={45}
                    />

                    <div className={cx('firstLastName')}>
                      <div className={cx('name')}>
                        <span>{getUserName(detailShift?.client?.firstName, detailShift?.client?.lastName)}</span>
                        {detailShift?.status && <span className={cx('textSchedule')}>({getStatusLabel()})</span>}
                      </div>
                      <div className={cx('textInfoGroup')}>
                        <CommonIconPhoneNumber strokePath={CORDUROY_500} width={16} height={16} />
                        <span className={cx('textInfo')}>
                          {detailShift?.client?.mobilePhone
                            ? formatPhoneNumber(detailShift?.client?.mobilePhone)
                            : EMPTY_STRING}
                        </span>
                      </div>
                      {/* <div className={cx('textInfoGroup')}>
                        <CommonIconEmail strokePath={CORDUROY_500} width={16} height={16} />
                        <span className={cx('textInfo')}>{detailShift?.client?.email || EMPTY_STRING}</span>
                      </div> */}
                    </div>
                  </div>
                  <button className={cx('btnMap')} onClick={handleMap}>
                    <img className={cx('iconMap')} src={icons.commonIconMap} alt={t('common_img_text_alt')} />
                    {t('common_btn_map')}
                  </button>
                </div>
                <div className={cx('navTabGroup')}>
                  <Tab items={eventPopupTabs} paddingBottom={16} enableUrlSync={false} onChange={handleTabChange} />
                </div>
              </div>

              <div className={cx('tabContent')}>{renderTabContent}</div>
            </div>
          </div>

          {(isLoading || isFetchingShiftNote) && <Loading />}
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default NewEventPopup;
