// Libs
// Others
import { urlApiCrm } from '~/utils/constants/api';
import axiosClient from '~/apis/axiosClient';
import { BaseResponse, IListDataResponse } from '~/utils/interface/common';
import {
  IClientDetail,
  IListClient,
  IListQueryParamsClients,
  IQuerySendEmailToClient,
  IPayloadChangeStatusClient,
  IRelates,
  IRelatesQueryParams,
  IParamsGetListClientByContact,
  IListClientByContact,
  IListDataResponseClient,
  IParamsClientsLocations,
  IClient,
  IChangeStageProspect,
} from '~/utils/interface/crm/clients';
import { IFormUpdateClient } from '~/utils/interface/crm/clients';
import { CONTENT_TYPE_FORM_DATA } from '~/utils/constants/common';
import { IDetailCarePlan, IPayloadCarePlan } from '~/utils/interface/crm/carePlan';

const clientsApi = {
  getListClient(params: IListQueryParamsClients) {
    const url = `${urlApiCrm.getList}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IListClient[]>>>(url, { params });
  },

  createClient(body: FormData) {
    const url = `${urlApiCrm.createClient}`;
    return axiosClient.post<BaseResponse>(url, body, { headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA } });
  },

  getDetailClient(clientId: string) {
    const url = `${urlApiCrm.getDetailClient(clientId)}`;
    return axiosClient.get<BaseResponse<IClientDetail>>(url);
  },

  updateClient({ clientId, body }: IFormUpdateClient) {
    const url = `${urlApiCrm.updateClient(clientId)}`;
    return axiosClient.put<BaseResponse>(url, body, { headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA } });
  },

  uploadFileClients(body: FormData) {
    const url = `${urlApiCrm.uploadFileClients}`;
    return axiosClient.post<BaseResponse>(url, body, { headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA } });
  },

  getListRelates(params: IRelatesQueryParams) {
    const url = `${urlApiCrm.getListRelates}`;
    return axiosClient.get<BaseResponse<IRelates>>(url, { params });
  },

  sendEmailToClient({ clientId, body }: IQuerySendEmailToClient) {
    const url = `${urlApiCrm.sendEmailToClient(clientId)}`;
    return axiosClient.post<BaseResponse>(url, body, { headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA } });
  },

  changeStatusClient({ clientId, body }: IPayloadChangeStatusClient) {
    const url = `${urlApiCrm.changeStatusClient(clientId)}`;
    return axiosClient.put<BaseResponse>(url, body);
  },

  changeStageProspect({ clientId, body }: { clientId: string; body: IChangeStageProspect }) {
    const url = `${urlApiCrm.changeStageProspect(clientId)}`;
    return axiosClient.put<BaseResponse>(url, body);
  },

  getDetailCarePlan(clientId: string) {
    const url = `${urlApiCrm.getDetailCarePlan(clientId)}`;
    return axiosClient.get<BaseResponse<IDetailCarePlan>>(url);
  },

  createCarePlan({ clientId, body }: IPayloadCarePlan) {
    const url = `${urlApiCrm.createCarePlan(clientId)}`;
    return axiosClient.put<BaseResponse>(url, body);
  },

  getListClientByContact({ contactId, params }: IParamsGetListClientByContact) {
    const url = `${urlApiCrm.getListClientByContact(contactId)}`;
    return axiosClient.get<BaseResponse<IListDataResponseClient<IListClientByContact[]>>>(url, { params });
  },

  getListClientByLocations(params: IParamsClientsLocations) {
    const url = `${urlApiCrm.getListClientByLocations}`;
    return axiosClient.get<BaseResponse<IListDataResponseClient<IClient[]>>>(url, { params });
  },

  sendToQBClient(clientId: string) {
    const url = `${urlApiCrm.sendToQB(clientId)}`;
    return axiosClient.put(url);
  },
};

export default clientsApi;
