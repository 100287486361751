// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
// Components, Layouts, Pages
import { BaseSelect } from '~/components';
// Others
import { configDonutChartReport } from '~/utils/constants/configChart';
import { DEFAULT_HEIGHT_DONUT_CHART, DEFAULT_NUMBER_ZERO } from '~/utils/constants/common';
import { IBaseOption, IDonutChartColors } from '~/utils/interface/common';
// Styles, images, icons
import styles from './DonutChartReport.module.scss';
import { icons } from '~/assets';

type Props = {
  data: ApexOptions['series'];
  title: string;
  labels: string[];
  colors: IDonutChartColors[];
  height?: number;
  value?: string;
  onLocationChange?: (location: IBaseOption) => void;
  options?: IBaseOption[];
};

const cx = classNames.bind(styles);

const DonutChartReport = (props: Props) => {
  //#region Destructuring Props
  const { title, data, labels, colors, height = DEFAULT_HEIGHT_DONUT_CHART, value, onLocationChange, options } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook

  //#endregion Implement Hook

  //#region Handle Function
  const handleLocationSelect = (selectedLocation: IBaseOption) => {
    if (onLocationChange) {
      onLocationChange(selectedLocation);
    }
  };
  //#endregion Handle Function

  return (
    <div id='donutChartComponent' className={cx('container')}>
      <div className={cx('headerChart')}>
        <div className={cx('title')}>{title}</div>
        <BaseSelect
          options={options || []}
          icon={icons.commonIconArrowBottom}
          width={200}
          value={value}
          onChange={handleLocationSelect}
          placeholder={t('common_select_placeholder')}
          mode='search'
        />
      </div>

      <div className={cx('donutChartContainer')}>
        {data && data.length > DEFAULT_NUMBER_ZERO ? (
          <div className={cx('donutChart')}>
            <ReactApexChart
              type='donut'
              series={data}
              options={configDonutChartReport({
                labels: labels,
                colors: colors.map((color) => color.value),
              })}
              height={height}
            />
          </div>
        ) : (
          <div className={cx('noData')}>{t('common_empty_data')}</div>
        )}

        {data && data.length > DEFAULT_NUMBER_ZERO && (
          <div className={cx('footerChart')}>
            {labels.map((labelItem, index) => (
              <div key={index} className={cx('legendItem')}>
                <div className={cx('itemWrap')}>
                  <div className={cx('dotWrap', `${colors[index].name}`)}>
                    <div className={cx('dot')} />
                  </div>
                  <div className={cx('label')}>{labelItem}</div>
                </div>

                <div className={cx('label')}>{`${data[index]}`}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default DonutChartReport;
