// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TFunction } from 'i18next';
import { useEffect } from 'react';

// Components, Layouts, Pages
import {
  BaseButton,
  TextEditor,
  BaseInput,
  Modal,
  UploadFileSendMail,
  Loading,
  BaseInputEmailMultiple,
} from '~/components';

// Others
import { useAppSelector } from '~/redux/hooks';
import { ButtonTypeEnum, ModeTextEditorEnum } from '~/utils/enum';
import {
  ACCEPT_ALL_FILES,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  FILE_UPLOAD_LIMIT_50_MB,
  NEGATIVE_ONE,
  RegExp,
} from '~/utils/constants/common';
import { IFormSendMail } from '~/utils/interface/common';

// Styles, images, icons
import styles from './SendEmailMultipleModal.module.scss';

type Props = {
  isOpen: boolean;
  isLoading?: boolean;
  defaultEmails?: string[];
  onClose: () => void;
  onSendEmail: (data: IFormSendMail) => void;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup
    .object()
    .shape({
      from: yup
        .string()
        .email(t('common_validate_invalid_email'))
        .matches(RegExp.EMAIL, t('common_validate_invalid_email'))
        .required(t('form_send_mail_from_required')),
      to: yup
        .array()
        .of(
          yup
            .string()
            .required(t('form_send_mail_to_required'))
            .email(t('common_validate_invalid_email'))
            .matches(RegExp.EMAIL, t('common_validate_invalid_email'))
        )
        .required(t('form_send_mail_to_required'))
        .min(DEFAULT_NUMBER_ONE, t('form_send_mail_to_required')),

      cc: yup
        .array()
        .of(
          yup
            .string()
            .test('isValidEmail', t('common_validate_invalid_email'), (value) => {
              if (value && !RegExp.EMAIL.test(value)) return false;
              return true;
            })
            .defined()
        )
        .optional(),
      bcc: yup
        .array()
        .of(
          yup
            .string()
            .test('isValidEmail', t('common_validate_invalid_email'), (value) => {
              if (value && !RegExp.EMAIL.test(value)) return false;
              return true;
            })
            .defined()
        )
        .optional(),

      subject: yup
        .string()
        .transform((value) => value.trim())
        .required(t('form_send_mail_subject_required')),
      content: yup.string().required(t('form_send_mail_content_required')),
      files: yup
        .array()
        .of(
          yup
            .mixed<File>()
            .test('fileSize', t('form_send_mail_files_size_limit'), (value) => {
              if (value && value?.size <= FILE_UPLOAD_LIMIT_50_MB) return true;
              return false;
            })
            .defined()
        )
        .nullable()
        .optional(),
    })
    .required();
};

const defaultValues: IFormSendMail = {
  from: EMPTY_STRING,
  to: [],
  cc: [],
  bcc: [],
  subject: EMPTY_STRING,
  content: EMPTY_STRING,
  files: null,
};

const FormSendEmailModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, isLoading, defaultEmails, onClose, onSendEmail } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    trigger,
    formState: { errors },
  } = useForm<IFormSendMail>({
    resolver: yupResolver(schema(t)),
    defaultValues: defaultValues,
  });
  //#endregion Declare Hook

  //#region Selector
  const profile = useAppSelector((state) => state.userState.userProfile);
  //#endregion Selector

  //#region Implement Hook
  useEffect(() => {
    if (!profile || !profile?.email) return;

    setValue('from', profile?.email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(() => {
    if (!defaultEmails || defaultEmails.length <= DEFAULT_NUMBER_ZERO) return;

    setValue('to', defaultEmails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultEmails]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleCloseFormSendMail = () => {
    onClose();
    reset(defaultValues);
  };

  const handleSendEmail = async (data: IFormSendMail) => {
    onSendEmail(data);
  };
  //#endregion Handle Function

  return (
    <Modal isOpen={isOpen} onClose={handleCloseFormSendMail} title={t('form_send_mail_title')}>
      <form id='formSendEmailModalComponent' className={cx('formContainer')} onSubmit={handleSubmit(handleSendEmail)}>
        <div className={cx('formBody')}>
          <Controller
            name='from'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='from'
                value={value}
                label={t('form_send_mail_multiple_from_label')}
                onChange={onChange}
                required
                messageError={errors.from?.message}
              />
            )}
          />

          <div className={cx('threeCol')}>
            <Controller
              name='to'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInputEmailMultiple
                  id='to'
                  value={value}
                  label={t('form_send_mail_multiple_to_label')}
                  onChange={onChange}
                  required
                  errorMessage={errors.to?.message}
                />
              )}
            />

            <Controller
              name='cc'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInputEmailMultiple
                  id='cc'
                  value={value}
                  label={t('form_send_mail_multiple_cc_label')}
                  onChange={onChange}
                  errorMessage={errors.cc?.message}
                />
              )}
            />

            <Controller
              name='bcc'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInputEmailMultiple
                  id='bcc'
                  value={value}
                  label={t('form_send_mail_multiple_bcc_label')}
                  onChange={onChange}
                  errorMessage={errors.bcc?.message}
                />
              )}
            />
          </div>

          <Controller
            name='subject'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='subject'
                value={value}
                label={t('form_send_mail_multiple_subject_label')}
                onChange={onChange}
                required
                messageError={errors.subject?.message}
              />
            )}
          />

          <Controller
            name='content'
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextEditor
                mode={ModeTextEditorEnum.CREATE}
                height={200}
                label={t('form_send_mail_multiple_content_label')}
                value={value}
                onChange={(textEditor: string) => onChange(textEditor)}
                required
                errorMessage={errors.content?.message}
              />
            )}
          />

          <Controller
            name='files'
            control={control}
            render={({ field: { value, onChange } }) => {
              const errorMessages =
                errors.files && Array.isArray(errors.files) && errors.files.length > DEFAULT_NUMBER_ZERO
                  ? [...new Set(errors.files.filter((error) => error?.message).map((error) => error?.message))].join(
                      ', '
                    )
                  : EMPTY_STRING;

              const errorIndexes =
                errors.files && Array.isArray(errors.files) && errors.files.length > 0
                  ? errors.files
                      .map((error, index) => (error?.message ? index : NEGATIVE_ONE))
                      .filter((index) => index !== NEGATIVE_ONE)
                  : [];

              return (
                <UploadFileSendMail
                  multiple
                  accept={ACCEPT_ALL_FILES}
                  onFilesChange={(files: File[]) => {
                    onChange(files);
                    trigger('files');
                  }}
                  errorMessage={errorMessages}
                  errorIndex={errorIndexes}
                />
              );
            }}
          />
        </div>

        <div className={cx('formFooter')}>
          <BaseButton type='button' text={t('common_cancel_label')} width={65} onClick={handleCloseFormSendMail} />

          <BaseButton type='submit' text={t('common_save_label')} typeStyle={ButtonTypeEnum.PRIMARY} width={80} />
        </div>
      </form>

      {isLoading && <Loading />}
    </Modal>
  );
};

export default FormSendEmailModal;
