// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { TFunction } from 'i18next';
// Components, Layouts, Pages
import {
  BasePagination,
  BaseTable,
  ButtonStatus,
  ConfirmModal,
  ImageCircle,
  ModalUnderDevelopment,
  ThreeDotTableOptions,
  ToolBar,
} from '~/components';
// Others
import {
  AccountRoleCodesEnum,
  HrListTypeEnum,
  HrPageEnum,
  HrStatusEnum,
  ImageCircleTypeEnum,
  statusEnum,
  StorageEnum,
  TimeFormatEnum,
  TranslationEnum,
} from '~/utils/enum';
import { ColumnTableType, IPaginationResponse } from '~/utils/interface/common';
import { DEFAULT_CURRENT_PAGE, DEFAULT_LIMIT_PAGE, EMPTY_STRING } from '~/utils/constants/common';
import { convertDateToFormatTime, convertEnumToString, convertTime, getUserName } from '~/utils/helper';
import { IGetListUnavailability, IListQueryParamsHr, IPayloadChangeStatusUnavailability } from '~/utils/interface/hr';
import { useNavigate } from 'react-router-dom';
import { adminRouteAbsolute, staffRouteAbsolute, superAdminRouteAbsolute } from '~/utils/constants/route';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { LoadingData } from '~/context';
import { changeStatusUnavailability, deleteUnavailability, getListHr } from '~/thunks/hr/hrThunk';
// Styles, images, icons
import styles from './HrCaregiver.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const columnsApprovalRequest = (
  t: TFunction<TranslationEnum.TRANSLATION>,
  onApproved: (id: string) => void,
  onReject: (id: string) => void,
  handleViewDetail: (data: IGetListUnavailability) => void,
  handleDelete: (data: IGetListUnavailability) => void
): ColumnTableType<IGetListUnavailability>[] => {
  return [
    {
      key: 'createDate',
      title: t('hr_table_date_label'),
      render(value, record, index) {
        return (
          <>{record.createdAt ? convertDateToFormatTime(record.createdAt, TimeFormatEnum.MM_DD_YYYY) : EMPTY_STRING}</>
        );
      },
      width: '10%',
    },
    {
      key: 'name',
      title: t('hr_table_employee_name_label'),
      render: (_, record) => {
        return !record.caregiver?.firstName && !record.caregiver?.lastName ? (
          <>{EMPTY_STRING}</>
        ) : (
          <>
            <ImageCircle
              type={record.caregiver?.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
              imageUrl={record.caregiver?.avatarUrl}
              firstName={record.caregiver?.firstName}
              lastName={record.caregiver?.lastName}
              width={24}
              height={24}
              fontSize={10}
              margin={'0 6px 0 0'}
            />
            <span>{getUserName(record.caregiver?.firstName, record.caregiver?.lastName)}</span>
          </>
        );
      },
      width: '20%',
    },
    {
      key: 'startDate',
      title: t('hr_table_start_date'),
      dataIndex: 'startDate',
      render(value, record, index) {
        const startDate = `${record.startDate} ${record.startTime}`;

        return <>{`${convertTime(new Date(startDate), TimeFormatEnum.MM_DD_YYYY_HH_AM_PM)}`}</>;
      },
      width: '15%',
    },
    {
      key: 'endDate',
      title: t('hr_table_end_date'),
      dataIndex: 'endDate',
      render(value, record, index) {
        const endDate = `${record.endDate} ${record.endTime}`;

        return <>{`${convertTime(new Date(endDate), TimeFormatEnum.MM_DD_YYYY_HH_AM_PM)}`}</>;
      },
      width: '15%',
    },
    {
      key: 'reason',
      title: t('hr_table_history_reason_label'),
      dataIndex: 'reason',
      render(value, record, index) {
        return <>{convertEnumToString(record.reason)}</>;
      },
      width: '20%',
    },
    {
      key: 'status',
      title: EMPTY_STRING,
      render(value, record, index) {
        return (
          <div className={cx('buttonStatus')} onClick={(e) => e.stopPropagation()}>
            <ButtonStatus
              width={72}
              borderRadius={6}
              color='teal800'
              label={statusEnum.APPROVE}
              onClick={() => onApproved(record.id)}
            />
            <ButtonStatus
              width={72}
              borderRadius={6}
              color='red600'
              label={statusEnum.REJECT}
              onClick={() => onReject(record.id)}
            />
          </div>
        );
      },
    },
    {
      key: 'action',
      title: t('common_text_action'),
      render(value, record, index) {
        return (
          <ThreeDotTableOptions
            data={record}
            permissions={{
              isDelete: true,
              isView: true,
              isEdit: false,
            }}
            onView={() => handleViewDetail(record)}
            onDelete={() => handleDelete(record)}
          />
        );
      },
      width: 50,
    },
  ];
};

const columnsHistory = (
  t: TFunction<TranslationEnum.TRANSLATION>,
  handleViewDetail: (data: IGetListUnavailability) => void,
  handleDelete: (data: IGetListUnavailability) => void
): ColumnTableType<IGetListUnavailability>[] => {
  return [
    {
      key: 'createDate',
      title: t('hr_table_date_label'),
      render(value, record, index) {
        return (
          <>{record.createdAt ? convertDateToFormatTime(record.createdAt, TimeFormatEnum.MM_DD_YYYY) : EMPTY_STRING}</>
        );
      },
      width: '10%',
    },
    {
      key: 'name',
      title: t('hr_table_employee_name_label'),
      render: (_, record) => {
        return !record.caregiver?.firstName && !record.caregiver?.lastName ? (
          <>{EMPTY_STRING}</>
        ) : (
          <>
            <ImageCircle
              type={record.caregiver?.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
              imageUrl={record.caregiver?.avatarUrl}
              firstName={record.caregiver?.firstName}
              lastName={record.caregiver?.lastName}
              width={24}
              height={24}
              fontSize={10}
              margin={'0 6px 0 0'}
            />
            <span>{getUserName(record.caregiver?.firstName, record.caregiver?.lastName)}</span>
          </>
        );
      },
      width: '20%',
    },
    {
      key: 'startDate',
      title: t('hr_table_start_date'),
      dataIndex: 'startDate',
      render(value, record, index) {
        const startDate = `${record.startDate} ${record.startTime}`;

        return <>{`${convertTime(new Date(startDate), TimeFormatEnum.MM_DD_YYYY_HH_AM_PM)}`}</>;
      },
      width: '15%',
    },
    {
      key: 'endDate',
      title: t('hr_table_end_date'),
      dataIndex: 'endDate',
      render(value, record, index) {
        const endDate = `${record.endDate} ${record.endTime}`;

        return <>{`${convertTime(new Date(endDate), TimeFormatEnum.MM_DD_YYYY_HH_AM_PM)}`}</>;
      },
      width: '15%',
    },
    {
      key: 'reason',
      title: t('hr_table_history_reason_label'),
      dataIndex: 'reason',
      render(value, record, index) {
        return <>{convertEnumToString(record.reason)}</>;
      },
      width: '20%',
    },
    {
      key: 'status',
      title: t('hr_table_status_label'),
      dataIndex: 'status',
      width: '15%',
      render(value, record, index) {
        switch (record.status) {
          case HrStatusEnum.APPROVED:
            return <ButtonStatus width={85} borderRadius={6} color='greenPea700' label={record.status} />;

          case HrStatusEnum.REQUESTED:
            return <ButtonStatus width={85} borderRadius={6} color='finn900' label={record.status} />;

          case HrStatusEnum.DENIED:
            return (
              <ButtonStatus
                width={85}
                borderRadius={6}
                color='red600'
                label={record.status === HrStatusEnum.DENIED ? HrStatusEnum.REJECTED : EMPTY_STRING}
              />
            );

          default:
            return EMPTY_STRING;
        }
      },
    },
    {
      key: 'action',
      title: t('common_text_action'),
      render(value, record, index) {
        return (
          <ThreeDotTableOptions
            data={record}
            permissions={{
              isDelete: true,
              isView: true,
              isEdit: false,
            }}
            onView={() => handleViewDetail(record)}
            onDelete={() => handleDelete(record)}
          />
        );
      },
      width: 50,
    },
  ];
};

const HrCaregiver = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const role = localStorage.getItem(StorageEnum.ROLE);
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);
  //#endregion Declare Hook

  //#region Selector
  const { isRefreshHrList } = useAppSelector((state) => state.hrState);
  //#endregion Selector

  //#region Declare State
  const [isDevelopment, setIsDevelopment] = useState<boolean>(false);
  const [dataHR, setDataHr] = useState<IGetListUnavailability[]>();
  const [dataHistory, setDataHistory] = useState<IGetListUnavailability[]>();
  const [paginationHr, setPaginationHr] = useState<IPaginationResponse>();
  const [paginationHistory, setPaginationHistory] = useState<IPaginationResponse>();
  const [paramObjectHr, setParamObjectHr] = useState<IListQueryParamsHr>({
    page: DEFAULT_CURRENT_PAGE,
    limit: DEFAULT_LIMIT_PAGE,
  });
  const [paramObjectHistory, setParamObjectHistory] = useState<IListQueryParamsHr>({
    page: DEFAULT_CURRENT_PAGE,
    limit: DEFAULT_LIMIT_PAGE,
  });
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false);
  const [hrId, setHrId] = useState<string>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const paramsHr = {
      ...paramObjectHr,
      view: HrListTypeEnum.REQUEST,
    };

    handleGetListHr(paramsHr);
  }, [paramObjectHr]);

  useEffect(() => {
    const paramsHistory = {
      ...paramObjectHistory,
      view: HrListTypeEnum.HISTORY,
    };

    handleGetListHr(paramsHistory);
  }, [paramObjectHistory]);

  useEffect(() => {
    if (!isRefreshHrList) return;

    const paramsHr = {
      ...paramObjectHr,
      view: HrListTypeEnum.REQUEST,
    };

    const paramsHistory = {
      ...paramObjectHistory,
      view: HrListTypeEnum.HISTORY,
    };

    handleGetListHr(paramsHr);
    handleGetListHr(paramsHistory);
  }, [isRefreshHrList]);
  //#endregion Implement Hook

  //#region Handle Function
  // const handleChangeSearch = () => {
  //   // Handle Update Later
  // };

  // const handleAddUser = () => {
  //   // Handle Update Later
  //   handleShowDevelopment();
  // };

  const handlePaginationChangeApprovalRequest = (page: number) => {
    if (!page) return;

    const newParamObject: IListQueryParamsHr = { ...paramObjectHr, page };
    setParamObjectHr(newParamObject);
  };

  const handlePaginationChangeHistory = (page: number) => {
    if (!page) return;

    const newParamObject: IListQueryParamsHr = { ...paramObjectHistory, page };
    setParamObjectHistory(newParamObject);
  };

  const handleApproved = (unavailabilityId: string) => {
    if (!unavailabilityId) return;

    const payload: IPayloadChangeStatusUnavailability = {
      unavailabilityId: unavailabilityId,
      body: {
        status: HrStatusEnum.APPROVED,
      },
    };

    handleChangeStatusUnavailability(payload);
  };

  const handleRejected = (unavailabilityId: string) => {
    if (!unavailabilityId) return;

    const payload: IPayloadChangeStatusUnavailability = {
      unavailabilityId: unavailabilityId,
      body: {
        status: HrStatusEnum.DENIED,
      },
    };

    handleChangeStatusUnavailability(payload);
  };

  const handleChangeStatusUnavailability = (payload: IPayloadChangeStatusUnavailability) => {
    loading?.show();

    dispatch(changeStatusUnavailability(payload))
      .unwrap()
      .then((res) => {})
      .catch((err) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const handleShowDevelopment = () => {
    setIsDevelopment(!isDevelopment);
  };

  const handleViewDetail = (data: IGetListUnavailability) => {
    if (!data.id) return;
    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.hrCaregiverDetail}/${data.id}`);
        break;

      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRouteAbsolute.hrCaregiverDetail}/${data.id}`);
        break;

      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(`${staffRouteAbsolute.hrCaregiverDetail}/${data.id}`);
        break;
    }
  };

  const handleGetListHr = (params: IListQueryParamsHr) => {
    loading?.show();

    const newParams: IListQueryParamsHr = {
      ...params,
      type: HrPageEnum.CAREGIVER,
    };

    dispatch(getListHr(newParams))
      .unwrap()
      .then((res) => {
        const { data, view } = res;

        switch (view) {
          case HrListTypeEnum.REQUEST:
            setDataHr(data.responses);
            setPaginationHr(data.pagination);
            break;
          case HrListTypeEnum.HISTORY:
            setDataHistory(data.responses);
            setPaginationHistory(data.pagination);
            break;
        }
      })
      .catch((err) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const handleDelete = (item: IGetListUnavailability) => {
    if (item) {
      setHrId(item.id);
    }

    setIsShowConfirmDelete(true);
  };

  const handleCloseModalConfirmDelete = () => {
    setIsShowConfirmDelete(false);
    setIsDevelopment(false);
  };

  const handleDeleteHr = () => {
    if (!hrId) return;

    dispatch(deleteUnavailability(hrId))
      .unwrap()
      .then((res) => {})
      .catch((err) => {})
      .finally(() => {
        handleCloseModalConfirmDelete();
      });
  };
  //#endregion Handle Function

  return (
    <div id='hrPage' className={cx('container')}>
      <div className={cx('content')}>
        <div className={cx('approvalRequest')}>
          <ToolBar title={t('hr_approval_request_title')} fontSize={20} />

          <div className={cx('table')}>
            <BaseTable
              columns={columnsApprovalRequest(t, handleApproved, handleRejected, handleViewDetail, handleDelete)}
              dataSource={dataHR ?? []}
              onClickRow={handleViewDetail}
            />
          </div>
          <div className={cx('pagination')}>
            {
              <BasePagination
                onChange={handlePaginationChangeApprovalRequest}
                defaultCurrentPage={paginationHr?.page}
                totalItems={paginationHr?.totalItems}
                totalPages={paginationHr?.totalPages}
              />
            }
          </div>
        </div>

        <div className={cx('history')}>
          <ToolBar title={t('hr_history_title')} fontSize={20} />
          <div className={cx('table')}>
            <BaseTable
              columns={columnsHistory(t, handleViewDetail, handleDelete)}
              dataSource={dataHistory ?? []}
              onClickRow={handleViewDetail}
            />
          </div>
          <div className={cx('pagination')}>
            {
              <BasePagination
                onChange={handlePaginationChangeHistory}
                defaultCurrentPage={paginationHistory?.page}
                totalItems={paginationHistory?.totalItems}
                totalPages={paginationHistory?.totalPages}
              />
            }
          </div>
        </div>
      </div>

      <ConfirmModal
        isOpen={isShowConfirmDelete}
        title={t('common_confirm_delete_title')}
        titleAction={t('common_delete_label')}
        onCancel={handleCloseModalConfirmDelete}
        onAction={handleDeleteHr}
        type='danger'
      />

      {isDevelopment && <ModalUnderDevelopment onClose={handleShowDevelopment} />}
    </div>
  );
};

export default HrCaregiver;
