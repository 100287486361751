// Libs
import classNames from 'classnames/bind';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { memo } from 'react';

// Components, Layouts, Pages
import { BaseDatePicker, BaseInput, BaseTextarea, SelectTime } from '~/components';

// Others
import { useAppDispatch } from '~/redux/hooks';
import { IFormSkill } from '~/utils/interface/caregivers/skill';
import { EMPTY_STRING } from '~/utils/constants/common';
import { InputTypeEnum } from '~/utils/enum';

// Styles, images, icons
import styles from './HumanResourcesRecords.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const HumanResourcesRecords = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    control,
    setValue,
    resetField,
    getValues,
    formState: { errors },
  } = useFormContext<IFormSkill>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function

  //#endregion Handle Function

  return (
    <div id='humanResourcesRecordComponent' className={cx('humanResourcesRecordContainer')}>
      <div className={cx('headTitle')}>{t('form_skill_human_resources_records_title')}</div>

      <div className={cx('bodyWrap')}>
        <div className={cx('threeCol')}>
          <Controller
            name='humanResourcesRecordsSkill.hireDate'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseDatePicker
                label={t('form_skill_human_resources_records_hire_date_title')}
                onDateSelected={onChange}
                value={value || EMPTY_STRING}
                name={'humanResourcesRecordsSkill.hireDate'}
                placeholderText={t('common_placeholder_select')}
                errorMessage={errors?.humanResourcesRecordsSkill?.hireDate?.message}
              />
            )}
          />

          <Controller
            name='humanResourcesRecordsSkill.terminationDate'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseDatePicker
                label={t('form_skill_human_resources_records_termination_date_title')}
                onDateSelected={onChange}
                value={value || EMPTY_STRING}
                name={'humanResourcesRecordsSkill.terminationDate'}
                placeholderText={t('common_placeholder_select')}
                errorMessage={errors?.humanResourcesRecordsSkill?.terminationDate?.message}
              />
            )}
          />

          <Controller
            name='humanResourcesRecordsSkill.hoursWantedWeekly'
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <BaseInput
                id='humanResourcesRecordsSkill.hoursWantedWeekly'
                value={value || EMPTY_STRING}
                label={t('form_skill_human_resources_records_hours_wanted_weekly_title')}
                type={InputTypeEnum.NUMBER}
                onChange={(event) => {
                  let value = event.target.value;

                  onChange(Number(value));
                }}
                messageError={errors?.humanResourcesRecordsSkill?.hoursWantedWeekly?.message}
              />
            )}
          />
        </div>

        <Controller
          name='humanResourcesRecordsSkill.notes'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseTextarea
              id='humanResourcesRecordsSkill.notes'
              value={value || EMPTY_STRING}
              label={t('form_skill_human_resources_records_notes_title')}
              onChange={onChange}
              messageError={errors?.humanResourcesRecordsSkill?.notes?.message}
            />
          )}
        />
      </div>
    </div>
  );
};

export default memo(HumanResourcesRecords);
