// Libs
import classNames from 'classnames/bind';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { memo } from 'react';
// Components, Layouts, Pages
import { BaseDatePicker, BaseInput, BaseSelect, BaseSelectMultiple } from '~/components';
// Others
import { IFormSkill } from '~/utils/interface/caregivers/skill';
import { EMPTY_STRING } from '~/utils/constants/common';
import { InputTypeEnum } from '~/utils/enum';
import { genderDemographicOptions, languageDemographicOptions } from '~/mockData';

// Styles, images, icons
import styles from './DemographicsSkill.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const DemographicsSkill = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IFormSkill>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='demographicsSkillComponent' className={cx('demographicsSkillContainer')}>
      <div className={cx('headTitle')}>{t('form_skill_demographics_title')}</div>

      <div className={cx('bodyWrap')}>
        <div className={cx('twoCol')}>
          <Controller
            control={control}
            name='demographicsSkill.dob'
            render={({ field: { value, onChange } }) => (
              <BaseDatePicker
                id='demographics.dataOfBirth'
                label={t('form_skill_demographics_date_of_birth_label')}
                placeholderText={t('common_select_placeholder')}
                value={value || EMPTY_STRING}
                onDateSelected={onChange}
              />
            )}
          />

          <Controller
            control={control}
            name='demographicsSkill.languages'
            render={({ field: { value, onChange } }) => (
              <BaseSelectMultiple
                options={languageDemographicOptions}
                value={value || []}
                onChange={onChange}
                placeholder={t('common_placeholder_select')}
                label={t('form_skill_demographics_languages_label')}
              />
            )}
          />
        </div>

        <div className={cx('threeCol')}>
          <Controller
            control={control}
            name='demographicsSkill.gender'
            render={({ field: { value, onChange } }) => (
              <BaseSelect
                options={genderDemographicOptions}
                value={value || EMPTY_STRING}
                onChange={({ value }) => onChange(value)}
                placeholder={t('common_placeholder_select')}
                label={t('form_skill_demographics_gender_label')}
              />
            )}
          />

          <Controller
            control={control}
            name='demographicsSkill.height'
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='demographicsSkill.height'
                value={value || EMPTY_STRING}
                onChange={(event) => {
                  let value = event.target.value;

                  onChange(Number(value));
                }}
                label={t('form_skill_demographics_height_label')}
                type={InputTypeEnum.NUMBER}
              />
            )}
          />

          <Controller
            control={control}
            name='demographicsSkill.weight'
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='demographicsSkill.weight'
                value={value || EMPTY_STRING}
                onChange={(event) => {
                  let value = event.target.value;

                  onChange(Number(value));
                }}
                label={t('form_skill_demographics_weight_label')}
                type={InputTypeEnum.NUMBER}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(DemographicsSkill);
