// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { TFunction } from 'i18next';
import { useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
import { BasePagination, BaseTable, ModalUnderDevelopment } from '~/components';
// Others
import { IListClientByContact, IParamsGetListClientByContact } from '~/utils/interface/crm/clients';
import { DEFAULT_CURRENT_PAGE, DEFAULT_NUMBER_RECORD_TO_FETCH, EMPTY_STRING } from '~/utils/constants/common';
import { TranslationEnum } from '~/utils/enum';
import { ColumnTableType, IListDataResponse, IPaginationResponse } from '~/utils/interface/common';
import { convertEnumToString, formatPhoneNumber, getUserName } from '~/utils/helper';
import { useAppDispatch } from '~/redux/hooks';
import { LoadingData } from '~/context';
import { getListClientByContact } from '~/thunks/crm/clients/clientsThunk';
// Styles, images, icons
import styles from './ClientProspectContactTab.module.scss';
import { icons } from '~/assets';

const cx = classNames.bind(styles);

type Props = {
  contactId: string;
};

const columns = (t: TFunction<TranslationEnum.TRANSLATION>): ColumnTableType<IListClientByContact>[] => {
  return [
    {
      key: 'clientName',
      title: t('crm_detail_client_prospect_tab_table_client_name_title'),
      render: (_, record) => {
        return <>{getUserName(record?.firstName, record?.lastName)}</>;
      },
    },
    {
      key: 'type',
      title: t('crm_detail_client_prospect_contacts_tab_table_type_title'),
      dataIndex: 'type',
      render: (_, record) => {
        return <>{convertEnumToString(record.type) || EMPTY_STRING}</>;
      },
    },
    {
      key: 'homePhone',
      title: t('crm_detail_client_prospect_contacts_tab_table_home_phone_title'),
      dataIndex: 'homePhone',
      render: (_, record) => {
        return <>{record?.homePhone ? formatPhoneNumber(record?.homePhone) : EMPTY_STRING}</>;
      },
    },
    {
      key: 'relationship',
      title: t('crm_detail_client_prospect_contacts_tab_table_relationship_title'),
      dataIndex: 'relationship',
      render: (_, record) => {
        return <>{convertEnumToString(record.relationship) || EMPTY_STRING}</>;
      },
    },
    {
      key: 'permissionClient',
      title: t('crm_detail_client_prospect_contacts_tab_table_permissions_title'),
      dataIndex: 'permissionClient',
      render: (_, record) => {
        return (
          <>
            {record.permissionClient?.map((permissionClient) => convertEnumToString(permissionClient)).join(', ') ||
              EMPTY_STRING}
          </>
        );
      },
      width: '40%',
    },
  ];
};

const ClientProspectContactTab = (props: Props) => {
  //#region Destructuring Props
  const { contactId } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  const [listClient, setListClient] = useState<IListClientByContact[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pagination, setPagination] = useState<IPaginationResponse>();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(() => Number(params?.page ?? DEFAULT_CURRENT_PAGE), [params?.page]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!contactId) return;

    const payload: IParamsGetListClientByContact = {
      contactId: contactId,
      params: {
        page: Number(params.page) || DEFAULT_CURRENT_PAGE,
        limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      },
    };

    handleGetListClientByContact(payload);
  }, [contactId, params]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleClickUnderDevelop = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handlePaginationChange = (page: number) => {
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleGetListClientByContact = (payload: IParamsGetListClientByContact) => {
    loading?.show();

    dispatch(getListClientByContact(payload))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        const { responses, pagination } = res?.data;

        setListClient(responses);
        setPagination(pagination);
      })
      .catch((error) => {})
      .finally(() => {
        loading?.hide();
      });
  };
  //#endregion Handle Function

  return (
    <div id='detailClientAndProspectTabComponent' className={cx('detailClientAndProspectComponent')}>
      <div className={cx('content')}>
        <div className={cx('tableWrap')}>
          <BaseTable columns={columns(t)} dataSource={listClient ?? []} />
        </div>

        <div className={cx('paginationTable')}>
          <BasePagination
            onChange={handlePaginationChange}
            defaultCurrentPage={pageSelected}
            totalItems={pagination?.totalItems}
            totalPages={pagination?.totalPages}
          />
        </div>
      </div>

      {isShowModalUnderDevelopment && <ModalUnderDevelopment onClose={handleClickUnderDevelop} />}
    </div>
  );
};

export default ClientProspectContactTab;
