// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import { DEFAULT_NUMBER_ZERO, MAX_COL_NUMBER, MIN_WIDTH_NUMBER, PIXELS, WIDTH_FULL } from '~/utils/constants/common';
import { ColumnTableType } from '~/utils/interface/common';
// Styles, images, icons
import styles from './BaseTable.module.scss';
import { icons } from '~/assets';

type Props<T> = {
  title?: string;
  tableExpand?: boolean;
  isExpand?: boolean;
  columns: ColumnTableType<T>[];
  dataSource: T[];
  onClickRow?: (record: T) => void;
  handleExpandTable?: () => void;
};

const cx = classNames.bind(styles);

const BaseTable = <T extends Object>(props: Props<T>) => {
  //#region Destructuring Props
  const {
    title,
    isExpand = true,
    tableExpand = false,
    dataSource = [],
    columns,
    onClickRow,
    handleExpandTable,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleClickRow = (record: T) => {
    onClickRow && onClickRow(record);
  };

  const minWidth = columns?.length > MAX_COL_NUMBER ? `${columns?.length * MIN_WIDTH_NUMBER}${PIXELS}` : WIDTH_FULL;
  //#endregion Handle Function

  return (
    <div id='baseTableComponent' className={cx('baseTableComponent')}>
      {tableExpand && (
        <button onClick={handleExpandTable} className={cx('buttonExpand', { collapsed: !isExpand })}>
          <img
            src={icons.commonIconArrowBottomBlack}
            alt={t('common_img_text_alt')}
            className={cx('iconExpand', { rotate: isExpand })}
          />
          {title}
        </button>
      )}

      {isExpand && (
        <table style={{ minWidth }} className={cx('tableContainer')}>
          <thead className={cx('thead')}>
            <tr>
              {columns.map((column) => (
                <th className={cx('colTable')} style={{ width: column.width }} key={column.key}>
                  {column.title && column.title}
                </th>
              ))}
            </tr>
          </thead>

          <tbody className={cx('tbody')}>
            {dataSource?.length > DEFAULT_NUMBER_ZERO ? (
              dataSource.map((data, rowIndex: number) => (
                <tr key={rowIndex} className={cx('rowTableBody', { rowClickable: onClickRow })}>
                  {columns.map((column) => (
                    <td
                      key={column.key}
                      style={{ maxWidth: column.width, width: column.width }}
                      className={cx('colTableBody')}
                      onClick={() => column.key !== 'action' && handleClickRow(data)}
                    >
                      <div className={cx('cellContainer')}>
                        {column.render
                          ? column.render(data[column.dataIndex!], data, rowIndex)
                          : (data[column.dataIndex!] as React.ReactNode)}
                      </div>
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <>
                {tableExpand && isExpand ? (
                  <tr>
                    <td colSpan={columns.length}>
                      <div className={cx('noDataAvailableExpand')}>{t('common_empty_data')}</div>
                    </td>
                  </tr>
                ) : (
                  <td className={cx('noDataAvailable')}>{t('common_empty_data')}</td>
                )}
              </>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default BaseTable;
