// Libs
import classNames from 'classnames/bind';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useEffect, useState } from 'react';

// Components, Layouts, Pages
import { BaseSelect, PlacesAutocomplete, BaseInput } from '~/components';

// Others
import { useAppDispatch } from '~/redux/hooks';
import { IFormInitialCall } from '~/utils/interface/crm/initialCall';
import { DEFAULT_CURRENT_PAGE, DEFAULT_LIMIT_MAX_ITEM, EMPTY_STRING } from '~/utils/constants/common';
import { formatPhoneNumber } from '~/utils/helper';
import { IAddress, IBaseOption, IListQueryParams } from '~/utils/interface/common';
import { getListLocations } from '~/thunks/crm/location/locationThunk';

// Styles, images, icons
import styles from './ProspectInformation.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const ProspectInformationInitialCall = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<IFormInitialCall>();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [locationList, setLocationList] = useState<IBaseOption[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetListLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleChangeAddress = (address: IAddress) => {
    setValue('prospect.address', address?.address ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    address?.city &&
      setValue('prospect.city', address?.city ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    address?.state &&
      setValue('prospect.state', address?.state ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });
    address?.zipCode &&
      setValue('prospect.postalCode', address?.zipCode ?? EMPTY_STRING, { shouldDirty: true, shouldValidate: true });

    setValue('prospect.lat', Number(address?.lat));
    setValue('prospect.lng', Number(address?.lng));
  };

  const handleGetListLocations = () => {
    const params: IListQueryParams = { page: DEFAULT_CURRENT_PAGE, limit: DEFAULT_LIMIT_MAX_ITEM };
    dispatch(getListLocations(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        const listLocation = res.data?.responses?.map((data) => ({
          label: data.name || EMPTY_STRING,
          value: data.id,
        }));
        setLocationList(listLocation);
      })
      .catch((err) => {})
      .finally(() => {});
  };
  //#endregion Handle Function

  return (
    <div id='prospectInformationInitialCall' className={cx('prospectInformationInitialCall')}>
      <span className={cx('headTitle')}>{t('form_initial_call_section_prospect_information_title')}</span>
      <div className={cx('bodyWrap')}>
        <>
          <div className={cx('twoCol')}>
            <Controller
              name={'prospect.firstName'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='firstName'
                  label={t('form_initial_call_section_prospect_information_first_name_label')}
                  value={value || EMPTY_STRING}
                  onChange={onChange}
                  messageError={errors.prospect?.firstName?.message}
                  required
                />
              )}
            />
            <Controller
              name={'prospect.lastName'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='lastName'
                  label={t('form_initial_call_section_prospect_information_last_name_label')}
                  value={value || EMPTY_STRING}
                  onChange={onChange}
                  messageError={errors.prospect?.lastName?.message}
                  required
                />
              )}
            />
          </div>

          <div className={cx('threeCol')}>
            <Controller
              name='prospect.homePhone'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='homePhone'
                  label={t('form_initial_call_section_prospect_information_home_phone_label')}
                  value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const { value } = event.target;
                    const formattedValue = formatPhoneNumber(value);
                    onChange(formattedValue);
                  }}
                />
              )}
            />
            <Controller
              name='prospect.workPhone'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='workPhone'
                  label={t('form_initial_call_section_prospect_information_work_phone_label')}
                  value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const { value } = event.target;
                    const formattedValue = formatPhoneNumber(value);
                    onChange(formattedValue);
                  }}
                />
              )}
            />
            <Controller
              name='prospect.mobilePhone'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='mobilePhone'
                  label={t('form_initial_call_section_prospect_information_mobile_phone_label')}
                  value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const { value } = event.target;
                    const formattedValue = formatPhoneNumber(value);
                    onChange(formattedValue);
                  }}
                  messageError={errors.prospect?.mobilePhone?.message}
                  required
                />
              )}
            />
          </div>

          <div className={cx('twoCol')}>
            <Controller
              name={'prospect.email'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='email'
                  label={t('form_initial_call_section_prospect_information_email_label')}
                  value={value || EMPTY_STRING}
                  onChange={onChange}
                  messageError={errors.prospect?.email?.message}
                />
              )}
            />
            <Controller
              name={'prospect.locationId'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseSelect
                  options={locationList || []}
                  label={t('form_initial_call_section_prospect_information_location_label')}
                  placeholder={t('common_select_placeholder')}
                  onChange={({ value }) => onChange(value)}
                  value={value || EMPTY_STRING}
                  required
                  mode='search'
                  errorMessage={errors.prospect?.locationId?.message}
                />
              )}
            />
          </div>

          <div className={cx('twoCol')}>
            <Controller
              name='prospect.address'
              control={control}
              render={({ field: { value, onChange } }) => (
                <PlacesAutocomplete
                  id='address'
                  value={value || EMPTY_STRING}
                  label={t('form_initial_call_section_prospect_information_address_label')}
                  onChange={(address) => handleChangeAddress(address)}
                  required
                  messageError={errors.prospect?.address?.message}
                />
              )}
            />

            <Controller
              name='prospect.secondaryAddress'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='secondaryAddress'
                  label={t('form_initial_call_section_prospect_information_secondary_address_label')}
                  value={value || EMPTY_STRING}
                  onChange={onChange}
                />
              )}
            />
          </div>

          <div className={cx('threeCol')}>
            <Controller
              name='prospect.city'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='city'
                  label={t('form_initial_call_section_prospect_information_city_label')}
                  value={value || EMPTY_STRING}
                  onChange={onChange}
                  messageError={errors.prospect?.city?.message}
                  required
                />
              )}
            />

            <Controller
              name='prospect.state'
              control={control}
              render={({ field: { onChange, value } }) => (
                <BaseInput
                  id='state'
                  value={value || EMPTY_STRING}
                  label={t('form_initial_call_section_prospect_information_state_label')}
                  onChange={onChange}
                  required
                  messageError={errors.prospect?.state?.message}
                />
              )}
            />

            <Controller
              name='prospect.postalCode'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='postalCode'
                  label={t('form_initial_call_section_prospect_information_post_code_label')}
                  value={value || EMPTY_STRING}
                  onChange={onChange}
                  messageError={errors.prospect?.postalCode?.message}
                  required={true}
                />
              )}
            />
          </div>
        </>
      </div>
    </div>
  );
};

export default ProspectInformationInitialCall;
