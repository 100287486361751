import { urlApiCaregiverList } from '~/utils/constants/api';
import axiosClient from './axiosClient';
import { ICaregiverList, IListQueryParamsCaregiverList } from '~/utils/interface/caregiverList';
import { BaseResponse, IListDataResponse } from '~/utils/interface/common';

const caregiverListApi = {
  getList(params: IListQueryParamsCaregiverList) {
    const url = `${urlApiCaregiverList.getList}`;
    return axiosClient.get<BaseResponse<IListDataResponse<ICaregiverList[]>>>(url, { params });
  },
};

export default caregiverListApi;
