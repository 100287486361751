// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
// Components, Layouts, Pages
// Others
// Styles, images, icons
import styles from './Accounting.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const Accounting = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='accountingPage' className={cx('container')}>
      <Outlet />
    </div>
  );
};

export default Accounting;
