// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
// Components, Layouts, Pages
import { BaseButton, Loading } from '~/components';
import { ImageCircle } from '~/components';
// Others
import { AccountRoleCodesEnum, ButtonTypeEnum, ImageCircleTypeEnum, StorageEnum } from '~/utils/enum';
import { CODE_MESSAGE_EMPLOYEE_DELETED, COMMA_SEPARATOR, EMPTY_STRING } from '~/utils/constants/common';
import { GRAY_E9E9E9 } from '~/utils/constants/color';
import { useAppDispatch } from '~/redux/hooks';
import { getDetailUser } from '~/thunks/user/userThunk';
import { formatPhoneNumber, getRoleFromCode, getUserName } from '~/utils/helper';
import { IUserAccount } from '~/utils/interface/user';
import { createConversation } from '~/thunks/conversation/conversationThunk';
import { ICreateConversationReq } from '~/utils/interface/conversation';
import { adminRouteAbsolute, superAdminRouteAbsolute } from '~/utils/constants/route';
import { useTextOverflow } from '~/utils/customHook';
import { ErrorData } from '~/utils/interface/common';
// Styles, images, icons
import styles from './EmployeeDetailModalItem.module.scss';

type Props = {
  accountId: string;
  onEditInfo?: (id: string) => void;
};

const cx = classNames.bind(styles);

const EmployeeDetailModalItem = (props: Props) => {
  //#region Destructuring Props
  const { accountId, onEditInfo } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const role = localStorage.getItem(StorageEnum.ROLE);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [employeeDetail, setEmployeeDetail] = useState<IUserAccount>();
  const [codeMessage, setCodeMessage] = useState<number>();
  const contentRefs = useRef<Map<string, HTMLDivElement | null>>(new Map());
  const overflowMap = useTextOverflow(contentRefs.current, [employeeDetail]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!accountId) {
      return;
    }

    handleGetDetail(accountId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetDetail = (id: string) => {
    setIsLoading(true);

    dispatch(getDetailUser(id))
      .unwrap()
      .then((res) => {
        if (res?.data) setEmployeeDetail(res.data);
      })
      .catch((err) => {
        const { code } = err?.response?.data as ErrorData;
        setCodeMessage(code);
      })
      .finally(() => setIsLoading(false));
  };

  const handleEditInfo = (id: string) => {
    onEditInfo?.(id);
  };

  const handleMessage = () => {
    const payload: ICreateConversationReq = {
      accountIds: [Number(employeeDetail?.id)],
    };

    setIsLoading(true);
    dispatch(createConversation(payload))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        const { conversationId } = res.data;
        const params = new URLSearchParams({ conversationId: conversationId });

        switch (role) {
          case AccountRoleCodesEnum.SUPER_ADMIN:
            navigate(`${superAdminRouteAbsolute.messages}?${params.toString()}`);
            break;
          case AccountRoleCodesEnum.ADMIN:
            navigate(`${adminRouteAbsolute.messages}?${params.toString()}`);
            break;
        }
      })
      .catch((error) => {})
      .finally(() => setIsLoading(false));
  };

  const renderStateCountry = (data?: IUserAccount) => {
    if (!data) {
      return null;
    }

    if (data.state && data.country) {
      return `${data.state}${COMMA_SEPARATOR} ${data.country}`;
    }

    if (data.state && !data.country) {
      return data.state;
    }

    if (!data.state && data.country) {
      return data.country;
    }

    return EMPTY_STRING;
  };

  const handleRef = (key: string, el: HTMLDivElement | null) => {
    contentRefs.current.set(key, el);
  };
  //#endregion Handle Function

  return (
    <div id='employeeDetailModal' className={cx('container')}>
      {codeMessage && codeMessage === CODE_MESSAGE_EMPLOYEE_DELETED ? (
        <div className={cx('emptyData')}>{t('activity_assignee_task_deleted')}</div>
      ) : (
        <>
          <div className={cx('headContent')}>
            <div className={cx('imageCircleWrap')}>
              <ImageCircle
                type={employeeDetail?.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
                width={60}
                height={60}
                firstName={employeeDetail?.firstName || EMPTY_STRING}
                lastName={employeeDetail?.lastName || EMPTY_STRING}
                fontSize={16}
                imageUrl={employeeDetail?.avatarUrl}
              />
            </div>
            <div className={cx('headGroup')}>
              <div className={cx('headName')}>{getUserName(employeeDetail?.firstName, employeeDetail?.lastName)}</div>
              <div className={cx('headPosition')}>{getRoleFromCode(employeeDetail?.role) || EMPTY_STRING}</div>
            </div>
          </div>

          <div className={cx('headButton')}>
            <BaseButton
              typeStyle={ButtonTypeEnum.PRIMARY}
              text={t('employee_detail_item_edit_info_button')}
              minWidth={185}
              onClick={() => handleEditInfo(employeeDetail?.id!)}
            />
            <BaseButton
              text={t('employee_detail_item_message_button')}
              minWidth={185}
              borderColor={GRAY_E9E9E9}
              onClick={handleMessage}
            />
          </div>

          <div className={cx('contentBody')}>
            <div className={cx('contentWrap')}>
              <div className={cx('contentTitle')}>{t('employee_detail_item_contact_information_title')}</div>
              <div className={cx('contentGroup')}>
                <div className={cx('contentLabelValue')}>
                  <div className={cx('contentLabel')}>{`${t('employee_detail_item_phone_number_label')}:`}</div>
                  <div
                    ref={(el) => handleRef('phoneNumber', el)}
                    data-tooltip-id='phoneNumber'
                    data-tooltip-content={employeeDetail?.phoneNumber || EMPTY_STRING}
                    className={cx('contentValue')}
                  >
                    {employeeDetail?.phoneNumber ? formatPhoneNumber(employeeDetail?.phoneNumber) : EMPTY_STRING}
                  </div>
                </div>

                <div className={cx('contentLabelValue')}>
                  <div className={cx('contentLabel')}>{`${t(
                    'employee_detail_item_alternate_phone_number_label'
                  )}:`}</div>
                  <div
                    ref={(el) => handleRef('altPhoneNumber', el)}
                    data-tooltip-id='altPhoneNumber'
                    data-tooltip-content={employeeDetail?.altPhoneNumber || EMPTY_STRING}
                    className={cx('contentValue')}
                  >
                    {employeeDetail?.altPhoneNumber || EMPTY_STRING}
                  </div>
                </div>

                <div className={cx('contentLabelValue')}>
                  <div className={cx('contentLabel')}>{`${t('employee_detail_item_secondary_email_label')}:`}</div>
                  <div
                    data-tooltip-id='secondaryEmail'
                    ref={(el) => handleRef('secondaryEmail', el)}
                    data-tooltip-content={employeeDetail?.secondaryEmail || EMPTY_STRING}
                    className={cx('contentValue')}
                  >
                    {employeeDetail?.secondaryEmail || EMPTY_STRING}
                  </div>
                </div>
              </div>
            </div>

            <div className={cx('contentWrap')}>
              <div className={cx('contentTitle')}>{t('employee_detail_item_work_information_title')}</div>
              <div className={cx('contentGroup')}>
                <div className={cx('contentLabelValue')}>
                  <div className={cx('contentLabel')}>{`${t('employee_detail_item_employee_id_label')}:`}</div>
                  <div
                    data-tooltip-id='id'
                    ref={(el) => handleRef('id', el)}
                    data-tooltip-content={employeeDetail?.id || EMPTY_STRING}
                    className={cx('contentValue')}
                  >
                    {employeeDetail?.id || EMPTY_STRING}
                  </div>
                </div>

                <div className={cx('contentLabelValue')}>
                  <div className={cx('contentLabel')}>{`${t('employee_detail_item_department_label')}:`}</div>
                  <div
                    data-tooltip-id='department'
                    ref={(el) => handleRef('department', el)}
                    data-tooltip-content={employeeDetail?.department || EMPTY_STRING}
                    className={cx('contentValue')}
                  >
                    {employeeDetail?.department || EMPTY_STRING}
                  </div>
                </div>
              </div>
            </div>

            <div className={cx('contentWrapLastChild')}>
              <div className={cx('contentTitle')}>{t('employee_detail_item_personal_information_title')}</div>
              <div className={cx('contentGroup')}>
                <div className={cx('contentLabelValue')}>
                  <div className={cx('contentLabel')}>{`${t('employee_detail_item_first_name_label')}:`}</div>
                  <div
                    data-tooltip-id='firstName'
                    ref={(el) => handleRef('firstName', el)}
                    data-tooltip-content={employeeDetail?.firstName || EMPTY_STRING}
                    className={cx('contentValue')}
                  >
                    {employeeDetail?.firstName || EMPTY_STRING}
                  </div>
                </div>

                <div className={cx('contentLabelValue')}>
                  <div className={cx('contentLabel')}>{`${t('employee_detail_item_last_name_label')}:`}</div>
                  <div
                    data-tooltip-id='lastName'
                    ref={(el) => handleRef('lastName', el)}
                    data-tooltip-content={employeeDetail?.lastName || EMPTY_STRING}
                    className={cx('contentValue')}
                  >
                    {employeeDetail?.lastName || EMPTY_STRING}
                  </div>
                </div>

                <div className={cx('contentLabelValue')}>
                  <div className={cx('contentLabel')}>{`${t('employee_detail_item_gender_label')}:`}</div>
                  <div
                    data-tooltip-id='gender'
                    ref={(el) => handleRef('gender', el)}
                    data-tooltip-content={employeeDetail?.gender || EMPTY_STRING}
                    className={cx('contentValue')}
                  >
                    {employeeDetail?.gender || EMPTY_STRING}
                  </div>
                </div>

                <div className={cx('contentLabelValue')}>
                  <div className={cx('contentLabel')}>{`${t('employee_detail_item_address_label')}:`}</div>
                  <div
                    data-tooltip-id='address'
                    ref={(el) => handleRef('address', el)}
                    data-tooltip-content={employeeDetail?.address || EMPTY_STRING}
                    className={cx('contentValue')}
                  >
                    {employeeDetail?.address || EMPTY_STRING}
                  </div>
                </div>

                <div className={cx('contentLabelValue')}>
                  <div className={cx('contentLabel')}>{`${t('employee_detail_item_city_label')}:`}</div>
                  <div
                    data-tooltip-id='city'
                    ref={(el) => handleRef('city', el)}
                    data-tooltip-content={employeeDetail?.city || EMPTY_STRING}
                    className={cx('contentValue')}
                  >
                    {employeeDetail?.city || EMPTY_STRING}
                  </div>
                </div>

                <div className={cx('contentLabelValue')}>
                  <div className={cx('contentLabel')}>{`${t('employee_detail_item_state_country_label')}:`}</div>
                  <div
                    data-tooltip-id='state'
                    ref={(el) => handleRef('state', el)}
                    data-tooltip-content={renderStateCountry(employeeDetail)}
                    className={cx('contentValue')}
                  >
                    {renderStateCountry(employeeDetail)}
                  </div>
                </div>

                <div className={cx('contentLabelValue')}>
                  <div className={cx('contentLabel')}>{`${t('employee_detail_item_zip_code_label')}:`}</div>
                  <div
                    data-tooltip-id='zipCode'
                    ref={(el) => handleRef('zipCode', el)}
                    data-tooltip-content={employeeDetail?.zipCode || EMPTY_STRING}
                    className={cx('contentValue')}
                  >
                    {employeeDetail?.zipCode || EMPTY_STRING}
                  </div>
                </div>

                <div className={cx('contentLabelValue')}>
                  <div className={cx('contentLabel')}>{`${t('employee_detail_item_nationality_label')}:`}</div>
                  <div
                    data-tooltip-id='nationality'
                    ref={(el) => handleRef('nationality', el)}
                    data-tooltip-content={employeeDetail?.nationality || EMPTY_STRING}
                    className={cx('contentValue')}
                  >
                    {employeeDetail?.nationality || EMPTY_STRING}
                  </div>
                </div>

                <div className={cx('contentLabelValue')}>
                  <div className={cx('contentLabel')}>{`${t('employee_detail_item_nri_resident_label')}:`}</div>
                  <div
                    data-tooltip-id='nriResident'
                    ref={(el) => handleRef('nriResident', el)}
                    data-tooltip-content={employeeDetail?.nriResident || EMPTY_STRING}
                    className={cx('contentValue')}
                  >
                    {employeeDetail?.nriResident || EMPTY_STRING}
                  </div>
                </div>
              </div>

              {Object.entries(overflowMap).map(([key, isOverflow]) => {
                return isOverflow && <Tooltip key={key} id={key} className={cx('tooltipText')} />;
              })}
            </div>
          </div>

          {isLoading && <Loading />}
        </>
      )}
    </div>
  );
};

export default EmployeeDetailModalItem;
