// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { RootState } from '~/redux/store';
import { createMessage, getListConversation, createConversation } from './conversationThunk';
import { EMPTY_STRING } from '~/utils/constants/common';
export interface ConversationState {
  isRefreshConversationList: boolean;
  tempIdSendFile?: string;
  typeFileLoading?: string;
}

const initialState: ConversationState = {
  isRefreshConversationList: false,
  tempIdSendFile: EMPTY_STRING,
};

const conversationSlice = createSlice({
  name: 'conversationState',
  initialState: initialState,
  reducers: {
    setRefreshList(state, action) {
      state.isRefreshConversationList = action.payload;
    },
    setTempIdFile(state, action) {
      const { tempIdSendFile, typeFileLoading } = action.payload;

      state.tempIdSendFile = tempIdSendFile;
      state.typeFileLoading = typeFileLoading;
    },
  },
  extraReducers(builder) {
    // Get list Conversation
    builder
      .addCase(getListConversation.pending, (state) => {})
      .addCase(getListConversation.fulfilled, (state) => {})
      .addCase(getListConversation.rejected, (state) => {});

    // Create Conversation
    builder
      .addCase(createConversation.pending, (state) => {})
      .addCase(createConversation.fulfilled, (state) => {})
      .addCase(createConversation.rejected, (state) => {});
  },
});

// Actions
export const conversationActions = conversationSlice.actions;

// Selectors
export const selectIsRefreshConversationList = (state: RootState) => state.conversationState.isRefreshConversationList;

// Reducer
const conversationReducer = conversationSlice.reducer;
export default conversationReducer;
