// Libs
import { StyleSheet, View } from '@react-pdf/renderer';
// Components, Layouts, Pages
import HeaderTableInvoicePdf from './headerTable/HeaderTableInvoicePdf';
import RowTableInvoicePdf from './rowTable/RowTableInvoicePdf';
// Others
import { IInvoiceDetail } from '~/utils/interface/invoice';
// Styles, images, icons

type Props = {
  data: IInvoiceDetail;
};

const TableInvoicePdf = (props: Props) => {
  //#region Destructuring Props
  const { data } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <View style={styles.tableContainer}>
      <HeaderTableInvoicePdf />

      <RowTableInvoicePdf data={data.shifts || []} />
    </View>
  );
};

export default TableInvoicePdf;

export const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderWidth: 1,
    borderColor: '#333333',
  },
});
