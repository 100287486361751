// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useState, useEffect, useContext, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
import {
  BaseTable,
  ModalUnderDevelopment,
  ConfirmModal,
  UpdateDocumentModal,
  BasePagination,
  InputSearch,
} from '~/components';

// Others
import useDebounce from '~/utils/customHook';
import { LoadingData } from '~/context';
import { TimeFormatEnum, ToastTypeEnum, TranslationEnum, TypeFilesDocumentEnum } from '~/utils/enum';
import { IListDocument, IListQueryParamsDocument } from '~/utils/interface/file';
import { BaseResponse, ColumnTableType, ErrorData, IListDataResponse } from '~/utils/interface/common';
import { convertDateToFormatTime, customToast, formatMimeType, formatSizeFile } from '~/utils/helper';
import {
  EMPTY_STRING,
  A_ELEMENT,
  DEFAULT_CURRENT_PAGE,
  DEFAULT_NUMBER_ZERO,
  OBJECT,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  DEFAULT_DELAY_TIME,
} from '~/utils/constants/common';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { downloadDocument, deleteDocument, getListDocuments } from '~/thunks/file/fileThunk';
import { fileActions, selectIsRefreshFileList } from '~/thunks/file/fileSlice';
import { httpResponse } from '~/utils/constants/httpResponse';
// Styles, images, icons
import styles from './FilesTab.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction<TranslationEnum.TRANSLATION>,
  onDelete: (record: IListDocument) => void,
  onDownload: (record: IListDocument) => void
): ColumnTableType<IListDocument>[] => {
  return [
    {
      key: 'name',
      title: t('files_tab_caregiver_table_name_label'),
      dataIndex: 'name',
      render(value, record, index) {
        return <p>{record?.name}</p>;
      },
      width: '35%',
    },
    {
      key: 'updatedAt',
      title: t('files_tab_caregiver_table_updated_time_label'),
      dataIndex: 'updatedAt',
      render(value, record, index) {
        return (
          <>
            {record.updatedAt
              ? convertDateToFormatTime(record.updatedAt, TimeFormatEnum.MM_DD_YYYY_HH_AM_PM)
              : EMPTY_STRING}
          </>
        );
      },
    },
    {
      key: 'expirationDate',
      title: t('files_tab_caregiver_table_expiration_date_label'),
      dataIndex: 'expirationDate',
      render(value, record, index) {
        return (
          <>
            {record.expirationDate
              ? convertDateToFormatTime(record.expirationDate, TimeFormatEnum.MM_DD_YYYY)
              : EMPTY_STRING}
          </>
        );
      },
    },
    {
      key: 'type',
      title: t('files_tab_caregiver_table_type_label'),
      dataIndex: 'mimetype',
      render(value, record, index) {
        return <>{formatMimeType(record?.mimetype)}</>;
      },
      width: '18%',
    },
    {
      key: 'size',
      title: t('files_tab_caregiver_table_size_label'),
      dataIndex: 'size',
      render(value, record, index) {
        return <>{formatSizeFile(Number(record?.size))}</>;
      },
    },
    {
      key: 'action',
      title: t('files_tab_caregiver_table_action_label'),
      render(value, record, index) {
        return (
          <div className={cx('btnAction')}>
            <button onClick={() => onDownload(record)}>
              <img src={icons.commonIconDownload} alt={t('common_img_text_alt')} />
            </button>
            <button onClick={() => onDelete(record)}>
              <img src={icons.commonIconTrash} alt={t('common_img_text_alt')} />
            </button>
          </div>
        );
      },
      width: '5%',
    },
  ];
};

const FilesTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  const isRefreshListDocument = useAppSelector(selectIsRefreshFileList);
  //#endregion Selector

  //#region Declare State
  const { caregiverId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(() => Number(params?.page ?? DEFAULT_CURRENT_PAGE), [params?.page]);
  const [isShowDevelopment, setIsShowDevelopment] = useState<boolean>(false);
  const [isShowUpdateDocumentModal, setIsShowUpdateDocumentModal] = useState<boolean>(false);
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false);
  const [listDocumentResp, setListDocumentResp] = useState<BaseResponse<IListDataResponse<IListDocument[]>>>();
  const [selectedDocument, setSelectedDocument] = useState<IListDocument>();
  const [fileId, setFileId] = useState<string>(EMPTY_STRING);
  const textSearchParams = useMemo<string>(() => String(params?.textSearch || EMPTY_STRING), [params?.textSearch]);
  const [searchKey, setSearchKey] = useState<string>(textSearchParams || EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey.trim() || EMPTY_STRING, DEFAULT_DELAY_TIME);
  // const [isShowModalAssignToContact, setIsShowModalAssignToContact] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!textSearchParams) setSearchKey(EMPTY_STRING);
  }, [textSearchParams]);

  useEffect(() => {
    if (!caregiverId) return;

    const { tab, ...restParams } = params;

    const newParams = {
      ...restParams,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      businessId: caregiverId,
      businessTypes: [TypeFilesDocumentEnum.CAREGIVER_DOCUMENT],
    };

    loading?.show();
    handleGetListDocument(newParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!listDocumentResp?.data.pagination) return;

    if (debouncedSearchKey) {
      setSearchParams({
        ...params,
        page: DEFAULT_CURRENT_PAGE.toString(),
        limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
        textSearch: debouncedSearchKey,
      });
    } else {
      const { textSearch, ...rest } = params;
      setSearchParams(rest);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);

  useEffect(() => {
    if (!isRefreshListDocument || !caregiverId) return;

    const { status, tab, ...restParams } = params;

    const newParams = {
      ...restParams,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      ...(debouncedSearchKey ? { textSearch: debouncedSearchKey } : {}),
      businessId: caregiverId,
      businessTypes: [TypeFilesDocumentEnum.CAREGIVER_DOCUMENT],
    };

    handleGetListDocument(newParams);
    dispatch(fileActions.setRefreshList(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshListDocument]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListDocument = (params: IListQueryParamsDocument) => {
    dispatch(getListDocuments(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        setListDocumentResp(res);
      })
      .catch((err) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  // const handleAssignToContact = (record: IListDocument) => {
  //   setFileId(record.id);
  //   setIsShowModalAssignToContact(true);
  // };

  // const handleCloseModalAssignToContact = () => {
  //   setIsShowModalAssignToContact(false);
  // };

  const handleCloseEditModal = () => {
    setIsShowUpdateDocumentModal(false);
  };

  const handleDelete = (record?: IListDocument) => {
    setIsShowConfirmDelete(!isShowConfirmDelete);
    if (record) {
      setSelectedDocument(record);
    }
  };

  const handleDownLoad = (fileInfo: IListDocument) => {
    if (fileInfo) {
      loading?.show();

      dispatch(downloadDocument(fileInfo.id))
        .unwrap()
        .then((response) => {
          const data: ArrayBuffer | undefined = response.data;
          const fileExtension = fileInfo.mimetype;

          if (data && typeof data === OBJECT && Object.keys(data).length > DEFAULT_NUMBER_ZERO) {
            const array: number[] = Object.values(data);
            const uint8Array: Uint8Array = new Uint8Array(array);
            const blob: Blob = new Blob([uint8Array], { type: fileExtension });

            const url: string = window.URL.createObjectURL(blob);
            const link: HTMLAnchorElement = document.createElement(A_ELEMENT);
            link.href = url;
            link.download = fileInfo.name || EMPTY_STRING;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            window.URL.revokeObjectURL(url);
          }

          dispatch(fileActions.setRefreshList(true));
        })
        .catch((error) => {
          const { code, message } = error?.response?.data as ErrorData;

          const displayMessage = httpResponse[code] || message;

          // Handle show Toast here!
          customToast(ToastTypeEnum.ERROR, t(displayMessage));
        })
        .finally(() => {
          loading?.hide();
        });
    }
  };

  const handlePaginationChange = (page: number) => {
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleOpenModalUnderDevelopment = () => {
    setIsShowDevelopment(!isShowDevelopment);
  };

  const handleCloseModalConfirmDelete = () => {
    setIsShowConfirmDelete(false);
  };

  const handleConfirmDelete = () => {
    if (selectedDocument) {
      return dispatch(deleteDocument(selectedDocument?.id))
        .unwrap()
        .then((res) => {
          dispatch(fileActions.setRefreshList(true));
          handleCloseModalConfirmDelete();
        })
        .catch((error) => {})
        .finally(() => loading?.hide());
    }
  };

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value);
  };
  //#endregion Handle Function

  return (
    <div id='filesTabComponent' className={cx('filesTabComponent')}>
      <div className={cx('searchContainer')}>
        <InputSearch
          height={36}
          width={204}
          placeholder={t('common_placeholder_search_by_name')}
          onChange={handleChangeSearch}
          value={searchKey || EMPTY_STRING}
        />
      </div>
      <div className={cx('tableContainer')}>
        <BaseTable
          columns={columns(t, handleDelete, handleDownLoad)}
          dataSource={listDocumentResp?.data.responses || []}
        />
      </div>
      <div className={cx('paginationTable')}>
        <BasePagination
          onChange={handlePaginationChange}
          defaultCurrentPage={pageSelected}
          totalItems={listDocumentResp?.data.pagination.totalItems}
          totalPages={listDocumentResp?.data.pagination.totalPages}
        />
      </div>

      {isShowUpdateDocumentModal && (
        <UpdateDocumentModal fileId={fileId} isOpen={isShowUpdateDocumentModal} onClose={handleCloseEditModal} />
      )}

      {/* {isShowModalAssignToContact && (
        <ModalAssignToContact
          isOpen={isShowModalAssignToContact}
          fileId={fileId}
          onClose={handleCloseModalAssignToContact}
        />
      )} */}

      {isShowDevelopment && <ModalUnderDevelopment onClose={handleOpenModalUnderDevelopment} />}

      <ConfirmModal
        isOpen={isShowConfirmDelete}
        title={t('common_confirm_delete_title', {
          name: selectedDocument?.name,
        })}
        description={t('modal_confirm_delete_description')}
        titleAction={t('common_delete_label')}
        onCancel={handleDelete}
        onAction={handleConfirmDelete}
        type='danger'
      />
    </div>
  );
};

export default FilesTab;
