// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useLoadScript } from '@react-google-maps/api';
// Components, Layouts, Pages
import { LabelValueField, BaseGoogleMap } from '~/components';
// Others
import { DEFAULT_GG_MAP_LOAD_SCRIPT_LIB, EMPTY_STRING } from '~/utils/constants/common';
import { convertDateToFormatTime, formatAddress, formatPhoneNumber, getUserName } from '~/utils/helper';
import { googleMapApiKey } from '~/utils/constants/env';
import { useAppSelector } from '~/redux/hooks';
import { TimeFormatEnum } from '~/utils/enum';
import { RootState } from '~/redux/store';
// Styles, images, icons
import styles from './InfoClientTab.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const InfoTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapApiKey ?? EMPTY_STRING,
    libraries: DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  });
  //#endregion Declare Hook

  //#region Selector
  const { clientDetail } = useAppSelector((root: RootState) => root.clientState);
  //#endregion Selector

  //#region Declare State

  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='infoTabComponent' className={cx('infoTabComponent')}>
      <div className={cx('body')}>
        <div className={cx('infoDetailsWrap')}>
          <LabelValueField label={t('client_detail_info_email_label')} value={clientDetail?.email || EMPTY_STRING} />

          <LabelValueField
            label={t('client_detail_info_date_of_birth_label')}
            value={
              clientDetail?.dob ? convertDateToFormatTime(clientDetail.dob, TimeFormatEnum.MM_DD_YYYY) : EMPTY_STRING
            }
          />

          <LabelValueField
            label={t('client_detail_info_home_phone_label')}
            value={clientDetail?.homePhone ? formatPhoneNumber(clientDetail?.homePhone) : EMPTY_STRING}
          />

          <LabelValueField
            label={t('client_detail_info_mobile_phone_label')}
            value={clientDetail?.mobilePhone ? formatPhoneNumber(clientDetail?.mobilePhone) : EMPTY_STRING}
          />

          <LabelValueField
            label={t('client_detail_info_work_phone_label')}
            value={clientDetail?.workPhone ? formatPhoneNumber(clientDetail?.workPhone) : EMPTY_STRING}
          />

          <LabelValueField label={t('client_detail_info_location_label')} value={clientDetail?.location?.name} />

          <LabelValueField
            label={t('client_detail_info_referrer_label')}
            value={
              clientDetail?.referrer?.organizationName
                ? clientDetail?.referrer?.organizationName
                : getUserName(
                    clientDetail?.referrer?.firstName,
                    clientDetail?.referrer?.middleName,
                    clientDetail?.referrer?.lastName
                  )
            }
          />

          <LabelValueField
            label={t('client_detail_info_external_id_label')}
            value={clientDetail?.externalId || EMPTY_STRING}
          />

          <LabelValueField
            label={t('client_detail_info_time_zone_label')}
            value={clientDetail?.timeZone || EMPTY_STRING}
          />

          <LabelValueField
            label={t('prospect_tab_info_label_secondary_address')}
            value={clientDetail?.secondaryAddress || EMPTY_STRING}
          />

          <LabelValueField label={t('client_detail_info_ssn_label')} value={clientDetail?.ssn || EMPTY_STRING} />

          <LabelValueField
            label={t('client_detail_info_note_label')}
            value={clientDetail?.notes || EMPTY_STRING}
            isLongValue
          />
        </div>

        <div className={cx('mapSection')}>
          <div className={cx('viewAddress')}>
            <span className={cx('textLabel')}>{t('client_detail_info_address_label')}</span>
            <span className={cx('valueContent')}>
              <span className={cx('valueContent')}>{formatAddress({ ...clientDetail })}</span>
            </span>
          </div>

          <div className={cx('mapViewContainer')}>
            {isLoaded && (
              <BaseGoogleMap
                position={
                  clientDetail?.lat && clientDetail?.lng
                    ? { lat: clientDetail?.lat, lng: clientDetail?.lng }
                    : undefined
                }
                triggerLocate
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoTab;
