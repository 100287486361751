// Libs
import classNames from 'classnames/bind';
import { Controller, useFormContext } from 'react-hook-form';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

// Components, Layouts, Pages
import { BaseInput } from '~/components';

// Others
import { IFormBillingPolicy } from '~/utils/interface/rate';
import { EMPTY_STRING } from '~/utils/constants/common';

// Styles, images, icons
import styles from './OvertimeSettingBillingPolicy.module.scss';
import { InputTypeEnum } from '~/utils/enum';

type Props = {};

const cx = classNames.bind(styles);

const OvertimeSettingBillingPolicy = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    setValue,
    resetField,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext<IFormBillingPolicy>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='overtimeSettingsComponent' className={cx('overtimeSettingsComponent')}>
      <div className={cx('content')}>
        <div className={cx('groupItem')}>
          <h1 className={cx('title')}>{t('billing_policy_overtime_settings_title')}</h1>

          <p className={cx('questionContent')}>{t('billing_policy_overtime_settings_content_title')}</p>
        </div>

        <div className={cx('groupItem')}>
          <h1 className={cx('title')}>{t('billing_policy_overtime_settings_overtime_will_title')}</h1>

          <div className={cx('groupCol')}>
            <span>{t('billing_policy_overtime_settings_any_work_above_title')}</span>

            <Controller
              name='overtime.hoursDay'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='overtime.hoursDay'
                  value={value || EMPTY_STRING}
                  width={100}
                  onChange={onChange}
                  type={InputTypeEnum.NUMBER}
                  textAlign='right'
                />
              )}
            />

            <span>{t('billing_policy_overtime_settings_hours_in_a_given_day_title')}</span>
          </div>

          <div className={cx('groupCol')}>
            <span>{t('billing_policy_overtime_settings_any_work_above_title')}</span>

            <Controller
              name='overtime.hoursWeek'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='overtime.hoursWeek'
                  value={value || EMPTY_STRING}
                  width={100}
                  onChange={onChange}
                  type={InputTypeEnum.NUMBER}
                  textAlign='right'
                />
              )}
            />

            <span>{t('billing_policy_overtime_settings_hours_given_week_title')}</span>
          </div>

          <div className={cx('groupCol')}>
            <span>{t('billing_policy_overtime_settings_work_done_title')}</span>
            <span className={cx('tableLink')}>{t('billing_policy_overtime_settings_more_than_title')}</span>
            <Controller
              name='overtime.consecutiveDaysWeek'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='overtime.consecutiveDaysWeek'
                  value={value || EMPTY_STRING}
                  width={100}
                  onChange={onChange}
                  type={InputTypeEnum.NUMBER}
                  textAlign='right'
                />
              )}
            />
            <span>{t('billing_policy_overtime_settings_consecutive_days_title')}</span>
          </div>
        </div>

        <div className={cx('groupItem')}>
          <h1 className={cx('title')}>{t('billing_policy_overtime_settings_double_overtime_will_title')}</h1>

          <div className={cx('groupCol')}>
            <span>{t('billing_policy_overtime_settings_any_work_above_title')}</span>

            <Controller
              name='overtime.doubleHoursDay'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='overtime.doubleHoursDay'
                  value={value || EMPTY_STRING}
                  width={100}
                  onChange={onChange}
                  type={InputTypeEnum.NUMBER}
                  textAlign='right'
                />
              )}
            />

            <span>{t('billing_policy_overtime_settings_hours_in_a_given_day_title')}</span>
          </div>

          <div className={cx('groupCol')}>
            <span>{t('billing_policy_overtime_settings_work_done_over_title')}</span>

            <Controller
              name='overtime.doubleHoursLastDay'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseInput
                  id='overtime.doubleHoursLastDay'
                  value={value || EMPTY_STRING}
                  width={100}
                  onChange={onChange}
                  type={InputTypeEnum.NUMBER}
                  textAlign='right'
                />
              )}
            />

            <span>{t('billing_policy_overtime_settings_hours_in_day_title')}</span>
            <span>{t('billing_policy_overtime_settings_consecutive_day_worked_title')}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(OvertimeSettingBillingPolicy);
