// Libs
// Others
import axiosClient from '~/apis/axiosClient';
import { urlApiContact, urlApiCrm } from '~/utils/constants/api';
import { BaseResponse, IListDataResponse } from '~/utils/interface/common';
import {
  IContactDetail,
  IFormContact,
  IListContact,
  IListQueryParamsContact,
  IPayloadChangeStatusContact,
  IPayloadUpdateContact,
} from '~/utils/interface/crm/contact';

const contactApi = {
  getListContact(params: IListQueryParamsContact) {
    const url = `${urlApiContact.getList}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IListContact[]>>>(url, { params });
  },

  createContact(body: IFormContact) {
    const url = `${urlApiContact.createContact}`;
    return axiosClient.post<BaseResponse>(url, body);
  },

  getDetailContact(id: string) {
    const url = `${urlApiContact.getDetailContact(id)}`;
    return axiosClient.get<BaseResponse<IContactDetail>>(url);
  },

  changeStatusContact({ contactId, body }: IPayloadChangeStatusContact) {
    const url = `${urlApiContact.changeStatusContact(contactId)}`;
    return axiosClient.put<BaseResponse>(url, body);
  },

  updateContact({ contactId, body }: IPayloadUpdateContact) {
    const url = `${urlApiContact.updateContact(contactId)}`;
    return axiosClient.put<BaseResponse>(url, body);
  },
};

export default contactApi;
