// Libs
import { createContext, ReactNode, useEffect, useState } from 'react';
// Components, Layouts, Pages
// Others
import {
  RING_CENTRAL_TYPE_ACTIVE_CALL_NOTIFY,
  RING_CENTRAL_TYPE_CALL_END_NOTIFY,
  RING_CENTRAL_TYPE_CONNECTED_STATUS,
  RING_CENTRAL_TYPE_CONNECTING_STATUS,
  RING_CENTRAL_TYPE_CONNECTION_STATUS_NOTIFY,
  RING_CENTRAL_TYPE_DISCONNECTED_STATUS,
  RING_CENTRAL_TYPE_LOGIN_ADAPTER,
  RING_CENTRAL_TYPE_LOGIN_STATUS_NOTIFY,
  RING_CENTRAL_TYPE_LOGOUT_ADAPTER,
  RING_CENTRAL_TYPE_NEW_CALL_ADAPTER,
  RING_CENTRAL_TYPE_NEW_SMS_ADAPTER,
  RING_CENTRAL_TYPE_RINGING_TELEPHONY_STATUS,
  RING_CENTRAL_TYPE_WIDGET_FRAME_ADAPTER,
} from '~/utils/constants/common';
import {
  ringCentralClientId,
  ringCentralClientSecret,
  ringCentralEmbeddableUrl,
  ringCentralServer,
} from '~/utils/constants/env';
import { ConnectionStatusEnum } from '~/utils/enum';
// Styles, images, icons

type RingCentralContextType = {
  executeCall?: (phoneNumber: string) => void;
  sendSMS?: (phoneNumber: string) => void;
  connect?: () => void;
  disconnect?: () => void;
  ringCentralStatus: ConnectionStatusEnum;
  setIsJoinPage?: (isLogin: boolean) => void;
  setIsShowWidget?: (isShowWidget: boolean) => void;
  setMinimizedWidget?: (isMinimizedWidget: boolean) => void;
};

const RingCentralContext = createContext<RingCentralContextType>({
  ringCentralStatus: ConnectionStatusEnum.DISCONNECTED,
});

type RingCentralProviderProps = {
  children: ReactNode;
};

const RingCentralProvider = (props: RingCentralProviderProps) => {
  //#region Destructuring Props
  const { children } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [ringCentralStatus, setRingCentralStatus] = useState<ConnectionStatusEnum>(ConnectionStatusEnum.DISCONNECTED);
  const [isJoinPage, setIsJoinPage] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (isJoinPage) {
      const script = document.createElement('script');
      script.src = `${ringCentralEmbeddableUrl}?clientId=${ringCentralClientId}&clientSecret=${ringCentralClientSecret}&appServer=${ringCentralServer}`;
      const firstScript = document.getElementsByTagName('script')[0];
      firstScript?.parentNode?.insertBefore(script, firstScript);
      window.addEventListener('message', handleMessage);
      return;
    }

    window.removeEventListener('message', handleMessage);
    handleShowWidget(false);
    handleMinimizeWidget(false);
    return () => {
      window.removeEventListener('message', handleMessage);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isJoinPage]);
  //#endregion Implement Hook

  //#region Handle Function
  const postMessage = (message: Record<string, any>) => {
    const frame = document.querySelector<HTMLIFrameElement>(RING_CENTRAL_TYPE_WIDGET_FRAME_ADAPTER);
    frame?.contentWindow?.postMessage(message, '*');
  };

  const handleMessage = (event: MessageEvent) => {
    const data = event.data;
    if (!data) return;

    switch (data.type) {
      case RING_CENTRAL_TYPE_ACTIVE_CALL_NOTIFY:
        if (data.call.telephonyStatus === RING_CENTRAL_TYPE_RINGING_TELEPHONY_STATUS) {
          handleShowWidget(true);
          handleMinimizeWidget(true);
          return;
        }
        break;
      case RING_CENTRAL_TYPE_CALL_END_NOTIFY:
        break;
      case RING_CENTRAL_TYPE_CONNECTION_STATUS_NOTIFY:
        handleShowWidget(true);
        handleMinimizeWidget(false);
        switch (data?.connectionStatus) {
          case RING_CENTRAL_TYPE_CONNECTED_STATUS:
            setRingCentralStatus(ConnectionStatusEnum.CONNECTED);
            break;
          case RING_CENTRAL_TYPE_CONNECTING_STATUS:
            setRingCentralStatus(ConnectionStatusEnum.CONNECTING);
            break;
          case RING_CENTRAL_TYPE_DISCONNECTED_STATUS:
            setRingCentralStatus(ConnectionStatusEnum.DISCONNECTED);
            break;
        }
        break;
      case RING_CENTRAL_TYPE_LOGIN_STATUS_NOTIFY:
        setRingCentralStatus(data?.loggedIn ? ConnectionStatusEnum.CONNECTING : ConnectionStatusEnum.DISCONNECTED);
        break;
      default:
        break;
    }
  };

  const handleCall = (phoneNumber: string) => {
    if (ringCentralStatus !== ConnectionStatusEnum.CONNECTED) return;

    handleShowWidget(true);
    handleMinimizeWidget(true);
    postMessage({
      type: RING_CENTRAL_TYPE_NEW_CALL_ADAPTER,
      phoneNumber: phoneNumber,
      toCall: true,
    });
  };

  const handleLogin = () => {
    postMessage({
      type: RING_CENTRAL_TYPE_LOGIN_ADAPTER,
    });
  };

  const handleLogout = () => {
    if (ringCentralStatus !== ConnectionStatusEnum.CONNECTED) return;

    postMessage({
      type: RING_CENTRAL_TYPE_LOGOUT_ADAPTER,
    });
  };

  const handleSendSMS = (phoneNumber: string) => {
    if (ringCentralStatus !== ConnectionStatusEnum.CONNECTED) return;

    handleShowWidget(true);
    handleMinimizeWidget(true);
    postMessage({
      type: RING_CENTRAL_TYPE_NEW_SMS_ADAPTER,
      phoneNumber: phoneNumber,
      conversation: true,
    });
  };

  const handleShowWidget = (isShowWidget: boolean) => {
    (window as any)?.RCAdapter?.setClosed(!isShowWidget);
  };

  const handleMinimizeWidget = (isShowWidget: boolean) => {
    (window as any)?.RCAdapter?.setMinimized(!isShowWidget);
  };
  //#endregion Handle Function

  return (
    <RingCentralContext.Provider
      value={{
        ringCentralStatus,
        connect: handleLogin,
        disconnect: handleLogout,
        executeCall: handleCall,
        sendSMS: handleSendSMS,
        setIsJoinPage: setIsJoinPage,
        setIsShowWidget: handleShowWidget,
        setMinimizedWidget: handleMinimizeWidget,
      }}
    >
      {children}
    </RingCentralContext.Provider>
  );
};

export { RingCentralContext, RingCentralProvider };
