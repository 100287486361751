// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { RootState } from '~/redux/store';
import {
  createBackgroundReport,
  getListBackgroundReport,
  editBackgroundReport,
  deleteBackgroundReport,
} from './backgroundReportThunk';

export interface BackgroundReportState {
  isRefreshBackgroundReportList: boolean;
}

const initialState: BackgroundReportState = {
  isRefreshBackgroundReportList: false,
};

const backgroundReportSlice = createSlice({
  name: 'backgroundReportState',
  initialState: initialState,
  reducers: {},

  extraReducers(builder) {
    // Create Background Report
    builder
      .addCase(createBackgroundReport.pending, (state) => {})
      .addCase(createBackgroundReport.fulfilled, (state, _action) => {
        state.isRefreshBackgroundReportList = true;
      })
      .addCase(createBackgroundReport.rejected, (state) => {});

    // Get List Background Report
    builder
      .addCase(getListBackgroundReport.pending, (state) => {})
      .addCase(getListBackgroundReport.fulfilled, (state, _action) => {
        state.isRefreshBackgroundReportList = false;
      })
      .addCase(getListBackgroundReport.rejected, (state) => {
        state.isRefreshBackgroundReportList = false;
      });

    // Edit Background Report
    builder
      .addCase(editBackgroundReport.pending, (state) => {})
      .addCase(editBackgroundReport.fulfilled, (state, _action) => {
        state.isRefreshBackgroundReportList = true;
      })
      .addCase(editBackgroundReport.rejected, (state) => {});

    // Delete Background Report
    builder
      .addCase(deleteBackgroundReport.pending, (state) => {})
      .addCase(deleteBackgroundReport.fulfilled, (state, _action) => {
        state.isRefreshBackgroundReportList = true;
      })
      .addCase(deleteBackgroundReport.rejected, (state) => {});
  },
});

// Actions
export const backgroundReportActions = backgroundReportSlice.actions;

// Reducer
const backgroundReportReducer = backgroundReportSlice.reducer;

export default backgroundReportReducer;
