// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react/jsx-runtime';
import { Dialog, DialogPanel, Transition } from '@headlessui/react';
import { useState } from 'react';
// Components, Layouts, Pages
import { BaseButton, Loading } from '~/components';
// Others
import { ButtonTypeEnum } from '~/utils/enum';
import { TEAL950 } from '~/utils/constants/color';
import { DIV_ELEMENT } from '~/utils/constants/common';
// Styles, images, icons
import styles from './ConfirmModal.module.scss';
import { icons } from '~/assets';

type ConfirmModalType = 'default' | 'danger';

type Props = {
  isOpen: boolean;
  title: string;
  titleCancel?: string;
  titleAction?: string;
  icon?: string;
  type?: ConfirmModalType;
  onCancel: () => void;
  onAction: () => Promise<void> | void;
  description?: string;
};

const cx = classNames.bind(styles);

const ConfirmModal = (props: Props) => {
  //#region Destructuring Props
  const {
    isOpen,
    title,
    titleCancel,
    titleAction,
    icon = icons.commonIconConfirmDelete,
    onCancel,
    onAction,
    type = 'default',
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleAction = async () => {
    setIsLoading(true);
    await onAction();
    setIsLoading(false);
  };
  //#endregion Handle Function

  return (
    <Transition
      show={isOpen}
      enter='transition-opacity duration-300'
      enterFrom='opacity-0'
      enterTo='opacity-100'
      leave='transition-opacity duration-200'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'
    >
      <Dialog id='confirmModalComponent' className={cx('container')} as={DIV_ELEMENT} open={isOpen} onClose={onCancel}>
        <Transition.Child
          as={Fragment}
          enter='ease-in-out duration-300'
          enterFrom='opacity-0 scale-95'
          enterTo='opacity-100 scale-100'
          leave='ease-in-out duration-200'
          leaveFrom='opacity-100 scale-100'
          leaveTo='opacity-0 scale-95'
        >
          <DialogPanel className={cx('modal')}>
            <div className={cx('iconWrap', type)}>
              <img
                src={icon || icons.commonIconModalDelete}
                className={cx('icon', 'iconDelete')}
                alt={t('common_img_text_alt')}
              />
            </div>

            <div className={cx('modalBody')}>{title && <p className={cx('title')}>{title}</p>}</div>

            <div className={cx('actions')}>
              <BaseButton
                text={titleCancel ? titleCancel : t('common_cancel_label')}
                width={135}
                onClick={onCancel}
                color={TEAL950}
              />

              <BaseButton
                text={titleAction ? titleAction : t('common_button_save')}
                width={135}
                onClick={handleAction}
                typeStyle={type === 'danger' ? ButtonTypeEnum.DELETE : ButtonTypeEnum.PRIMARY}
              />
            </div>
          </DialogPanel>
        </Transition.Child>

        {isLoading && <Loading />}
      </Dialog>
    </Transition>
  );
};

export default ConfirmModal;
