import { urlApiHomeSafetyAssessment } from '~/utils/constants/api';
import {
  IHomeSafetyAssessment,
  IParamsHomeSafetyAssessment,
  IPayloadEditAnswer,
} from '~/utils/interface/homeSafetyAssessment';
import axiosClient from './axiosClient';
import { BaseResponse } from '~/utils/interface/common';
import { CONTENT_TYPE_FORM_DATA } from '~/utils/constants/common';

const homeSafetyAssessmentApi = {
  getListHomeSafetyAssessment(params: IParamsHomeSafetyAssessment) {
    const url = `${urlApiHomeSafetyAssessment.getHomeSafetyAssessment}`;
    return axiosClient.get<BaseResponse<IHomeSafetyAssessment[]>>(url, { params });
  },

  editAnswer(payload: IPayloadEditAnswer) {
    const { clientId, questionId, body } = payload;

    const url = `${urlApiHomeSafetyAssessment.editAnswer(clientId, questionId)}`;
    return axiosClient.put<BaseResponse>(url, body, { headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA } });
  },
};

export default homeSafetyAssessmentApi;
