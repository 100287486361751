// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router';

// Components, Layouts, Pages
import {
  BaseButton,
  OvertimeSettingsPayroll,
  PayHoursCalculationSettingsPayroll,
  PayRatePayroll,
  TravelReimbursementSettingPayroll,
} from '~/components';

// Others
import { ButtonTypeEnum, CRMEnum, TypeRateEnum, TypeUserEnum } from '~/utils/enum';
import { IBodyPayrollRate, IFormPayrollRate, IListQueryPayroll, IPayrollRate } from '~/utils/interface/rate';
import { initialValues, payRollSchema } from '../helper';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { LoadingData } from '~/context';
import { getListPayroll, updatePayrollRate } from '~/thunks/rate/rateThunk';
import { DEFAULT_LIMIT_MAX_ITEM, DEFAULT_NUMBER_ONE, DEFAULT_NUMBER_ZERO } from '~/utils/constants/common';
import { rateActions } from '~/thunks/rate/rateSlice';

// Styles, images, icons
import styles from './PayrollTab.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const PayrollTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    watch,
    trigger,
    setValue,
    reset,
    resetField,
    getValues,
    formState: { errors, isDirty },
  } = useForm<IFormPayrollRate>({
    resolver: yupResolver(payRollSchema(t)),
    defaultValues: initialValues,
  });
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const { caregiverId } = useParams();
  //#endregion Declare Hook

  //#region Selector
  const { isRefreshPayrollRates } = useAppSelector((state) => state.rateState);
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    return () => {
      dispatch(rateActions.resetPayroll());
    };
  }, []);

  useEffect(() => {
    fetchPayroll();
  }, [caregiverId]);

  useEffect(() => {
    if (isRefreshPayrollRates) {
      fetchPayroll();
    }
  }, [isRefreshPayrollRates]);
  //#endregion Implement Hook

  //#region Handle Function
  const fetchPayroll = () => {
    if (!caregiverId) return;
    loading?.show();

    const params: IListQueryPayroll = {
      page: DEFAULT_NUMBER_ONE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
      userType: TypeUserEnum.CAREGIVER,
      rateType: TypeRateEnum.HOURLY,
      userId: caregiverId,
    };

    [TypeRateEnum.HOURLY, TypeRateEnum.PER_VISIT].forEach((rateType) => handleGetListPayroll({ ...params, rateType }));
  };

  const handleGetListPayroll = (params: IListQueryPayroll) => {
    dispatch(getListPayroll(params))
      .unwrap()
      .then((res) => {
        const { data, rateType } = res;

        switch (rateType) {
          case TypeRateEnum.HOURLY:
            setValue('payRate.payrollRatesHourly', data.data.responses);
            break;
          case TypeRateEnum.PER_VISIT:
            setValue('payRate.payrollRatesPerVisit', data.data.responses);
            break;
        }

        const { overtime, payHour, travelRate } = data.data;
        overtime && setValue('overtime', overtime);
        travelRate && setValue('travelRate', travelRate);
        payHour && setValue('payHour', payHour);
      })
      .catch((err) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const getPayrollRates = (data: IFormPayrollRate): IPayrollRate[] => {
    const hourlyRates: IPayrollRate[] =
      data.payRate?.payrollRatesHourly?.map((item) => ({
        rateId: item.rateId.toString(),
        price: item.price || DEFAULT_NUMBER_ZERO,
      })) || [];

    const perVisitRates: IPayrollRate[] =
      data.payRate?.payrollRatesPerVisit?.map((item) => ({
        rateId: item.rateId.toString(),
        price: item.price || DEFAULT_NUMBER_ZERO,
      })) || [];

    return [...hourlyRates, ...perVisitRates];
  };

  const handleSubmitPayroll = async (data: IFormPayrollRate) => {
    if (!caregiverId) return;
    loading?.show();

    try {
      const payload: IBodyPayrollRate = {
        userId: caregiverId,
        userType: TypeUserEnum.CAREGIVER,
        rates: getPayrollRates(data),
        overtime: data.overtime,
        payHour: data.payHour,
        travelRate: data.travelRate,
      };
      await dispatch(updatePayrollRate(payload)).unwrap();
    } catch {
    } finally {
      loading?.hide();
    }
  };

  //#endregion Handle Function

  return (
    <FormProvider
      {...({
        control,
        watch,
        setValue,
        trigger,
        reset,
        resetField,
        getValues,
        formState: { errors },
      } as UseFormReturn<IFormPayrollRate>)}
    >
      <div id='payrollTabComponent' className={cx('payrollTabComponent')}>
        <form id='payrollTabComponent' className={cx('container')} onSubmit={handleSubmit(handleSubmitPayroll)}>
          <div className={cx('formBody')}>
            <PayRatePayroll />

            <TravelReimbursementSettingPayroll />

            <PayHoursCalculationSettingsPayroll />

            <OvertimeSettingsPayroll />
          </div>

          <div className={cx('footerButton')}>
            <BaseButton
              text={t('common_save_label')}
              type='submit'
              typeStyle={ButtonTypeEnum.PRIMARY}
              width={80}
              disabled={!isDirty}
            />
          </div>
        </form>
      </div>
    </FormProvider>
  );
};

export default PayrollTab;
