import { createAsyncThunk } from '@reduxjs/toolkit';
import initialCallApi from '~/apis/crm/initialCall';
import { CREATE_INITIAL_CALL, GET_DETAIL_INITIAL_CALL, GET_LIST_INITIAL_CALL } from '~/utils/constants/actionType';
import { IFormInitialCall, IListQueryParamsInitialCall } from '~/utils/interface/crm/initialCall';

export const getListInitialCall = createAsyncThunk(
  GET_LIST_INITIAL_CALL,
  async (payload: IListQueryParamsInitialCall, { rejectWithValue }) => {
    try {
      const res = await initialCallApi.getListInitialCall(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createInitialCall = createAsyncThunk(
  CREATE_INITIAL_CALL,
  async (payload: IFormInitialCall, { rejectWithValue }) => {
    try {
      const res = await initialCallApi.createInitialCall(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDetailInitialCall = createAsyncThunk(
  GET_DETAIL_INITIAL_CALL,
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await initialCallApi.getDetailInitialCall(id);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
