import { createAsyncThunk } from '@reduxjs/toolkit';
import backgroundReportsApi from '~/apis/backgroundReports';
import {
  CREATE_BACKGROUND_REPORT,
  DELETE_BACKGROUND_REPORT,
  GET_LIST_BACKGROUND_REPORT,
  EDIT_BACKGROUND_REPORT,
} from '~/utils/constants/actionType';
import {
  IFormAddEmploymentScreening,
  IQueryGetListBackgroundReport,
  IPayloadUpdateEmploymentScreening,
} from '~/utils/interface/backgroundReports';

export const createBackgroundReport = createAsyncThunk(
  CREATE_BACKGROUND_REPORT,
  async (payload: IFormAddEmploymentScreening, { rejectWithValue }) => {
    try {
      const res = await backgroundReportsApi.createBackgroundReports(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteBackgroundReport = createAsyncThunk(
  DELETE_BACKGROUND_REPORT,
  async (backgroundReportId: string, { rejectWithValue }) => {
    try {
      const res = await backgroundReportsApi.deleteBackgroundReports(backgroundReportId);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListBackgroundReport = createAsyncThunk(
  GET_LIST_BACKGROUND_REPORT,
  async (payload: IQueryGetListBackgroundReport, { rejectWithValue }) => {
    try {
      const res = await backgroundReportsApi.getListBackgroundReport(payload);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editBackgroundReport = createAsyncThunk(
  EDIT_BACKGROUND_REPORT,
  async (payload: IPayloadUpdateEmploymentScreening, { rejectWithValue }) => {
    try {
      const res = await backgroundReportsApi.editBackgroundReportsApi(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
