// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { editAnswer, getListHomeSafetyAssessment } from '~/thunks/homeSafetyAssessment/homeSafetyAssessmentThunk';

export interface HomeSafetyAssessmentState {}

const initialState: HomeSafetyAssessmentState = {};

const homeSafetyAssessmentSlice = createSlice({
  name: 'homeSafetyAssessmentState',
  initialState,
  reducers: {},
  extraReducers(builder) {
    // Get list home safety assessment
    builder
      .addCase(getListHomeSafetyAssessment.pending, (state) => {})
      .addCase(getListHomeSafetyAssessment.fulfilled, (state) => {})
      .addCase(getListHomeSafetyAssessment.rejected, (state) => {});

    // Edit answer
    builder
      .addCase(editAnswer.pending, (state) => {})
      .addCase(editAnswer.fulfilled, (state) => {})
      .addCase(editAnswer.rejected, (state) => {});
  },
});

// Actions
export const homeSafetyAssessmentActions = homeSafetyAssessmentSlice.actions;

// Reducer
const homeSafetyAssessmentReducer = homeSafetyAssessmentSlice.reducer;
export default homeSafetyAssessmentReducer;
