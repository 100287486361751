// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import taskApi from '~/apis/task';
import {
  GET_LIST_TASK,
  GET_TASK_DETAIL,
  DELETE_TASK,
  CREATE_TASK,
  UPDATE_TASK,
  GET_SELECT_TASK,
} from '~/utils/constants/actionType';
import { IQueryParams } from '~/utils/interface/common';
import { IFormTask, IQueryListTaskSelect } from '~/utils/interface/task';
import { IPayloadUpdateTask } from '~/utils/interface/task';

export const getListTask = createAsyncThunk(GET_LIST_TASK, async (payload: IQueryParams, { rejectWithValue }) => {
  try {
    const res = await taskApi.getListTask(payload);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getTaskDetail = createAsyncThunk(GET_TASK_DETAIL, async (taskId: string, { rejectWithValue }) => {
  try {
    const res = await taskApi.getDetailTask(taskId);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteTask = createAsyncThunk(DELETE_TASK, async (taskID: string, { rejectWithValue }) => {
  try {
    const res = await taskApi.deleteTask(taskID);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createTask = createAsyncThunk(CREATE_TASK, async (body: IFormTask, { rejectWithValue }) => {
  try {
    const res = await taskApi.createTask(body);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateTask = createAsyncThunk(UPDATE_TASK, async (payload: IPayloadUpdateTask, { rejectWithValue }) => {
  try {
    const res = await taskApi.updateTask(payload);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectTask = createAsyncThunk(
  GET_SELECT_TASK,
  async (payload: IQueryListTaskSelect, { rejectWithValue }) => {
    try {
      const res = await taskApi.getSelectTask(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
