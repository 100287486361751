// Libs
import classNames from 'classnames/bind';
import { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// Components, Layouts, Pages
import { BaseSelect, BaseTextarea } from '~/components';

// Others
import { IFormBillingPolicy } from '~/utils/interface/rate';
import { EMPTY_STRING, OPTION_INVOICE_TERM, OPTION_REMITTANCE_ADDRESS } from '~/utils/constants/common';

// Styles, images, icons
import styles from './InvoicingBillingPolicy.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const InvoicingBillingPolicy = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    setValue,
    resetField,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext<IFormBillingPolicy>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='invoicingBillingPolicyComponent' className={cx('invoicingBillingPolicyComponent')}>
      <div className={cx('content')}>
        <div className={cx('groupItem')}>
          <h1 className={cx('title')}>{t('billing_policy_invoicing_title')}</h1>

          <Controller
            name='invoiceRate.term'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseSelect
                value={value}
                label={t('billing_policy_invoicing_terms_title')}
                options={OPTION_INVOICE_TERM}
                placeholder={t('common_select_placeholder')}
                width={300}
                onChange={({ value }) => {
                  onChange(value);
                }}
              />
            )}
          />
        </div>

        <div className={cx('groupItem')}>
          <Controller
            name='invoiceRate.remittanceAddress'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseSelect
                value={value}
                label={t('billing_policy_invoicing_remittance_address_title')}
                options={OPTION_REMITTANCE_ADDRESS}
                placeholder={t('common_select_placeholder')}
                width={300}
                onChange={({ value }) => {
                  onChange(value);
                }}
              />
            )}
          />

          <span className={cx('contentValue')}>{t('billing_policy_invoicing_email_this_client_title')}</span>
        </div>

        <div className={cx('groupItem')}>
          <h1 className={cx('title')}>{t('billing_policy_invoicing_invoice_notes_title')}</h1>

          <Controller
            name='invoiceRate.notes'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseTextarea
                id='invoiceRate.notes'
                height={90}
                width={600}
                onChange={onChange}
                value={value || EMPTY_STRING}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(InvoicingBillingPolicy);
