// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useContext, useEffect, useMemo } from 'react';

// Components, Layouts, Pages
import {
  BaseButton,
  ImageCircle,
  Tab,
  CaregiverReferralDetailInfoTab,
  CaregiverApplicationReferredTab,
} from '~/components';

// Others
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { LoadingData } from '~/context';
import { caregiverReferralDetailTabs, NAVIGATE_BACK, QUERY_KEY_TAB } from '~/utils/constants/common';
import {
  ButtonTypeEnum,
  ImageCircleTypeEnum,
  ReferralRoleEnum,
  TabQueryCaregiverReferralDetailEnum,
  TabQueryReferralSourceDetailEnum,
} from '~/utils/enum';
import { getUserName } from '~/utils/helper';
import { getDetailReferrals } from '~/thunks/caregivers/referral/referralThunk';
import { caregiverReferralActions } from '~/thunks/caregivers/referral/referralSlice';

// Styles, images, icons
import styles from './CaregiverReferralDetails.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const CaregiverReferralDetails = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const currentTab = searchParams.get(QUERY_KEY_TAB);
  const loading = useContext(LoadingData);
  const { referralId } = useParams();
  //#endregion Declare Hook

  //#region Selector
  const { caregiverReferralDetail } = useAppSelector((state) => state.caregiverReferralState);
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    return () => {
      dispatch(caregiverReferralActions.resetCaregiverReferral());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!referralId) return;

    handleGetDetailClient(referralId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleBack = () => {
    navigate(NAVIGATE_BACK);
  };

  const handleGetDetailClient = (referralId: string) => {
    loading?.show();

    dispatch(getDetailReferrals(referralId))
      .unwrap()
      .then((res) => {})
      .catch((error) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const renderTabContent = useMemo(() => {
    if (!referralId) return <></>;

    const tabToRender = currentTab || TabQueryCaregiverReferralDetailEnum.INFO;
    switch (tabToRender) {
      case TabQueryCaregiverReferralDetailEnum.INFO:
        return <CaregiverReferralDetailInfoTab />;

      case TabQueryCaregiverReferralDetailEnum.CAREGIVER_APPLICANT_REFERRED:
        return <CaregiverApplicationReferredTab referralId={referralId} />;
      default:
        // Handle Return Tab Later
        return <div className={cx('noDataAvailable')}>{t('common_empty_data')}</div>;
    }
  }, [currentTab, referralId, caregiverReferralDetail, t]);
  //#endregion Handle Function

  return (
    <div id='crmReferralResourceDetailPage' className={cx('container')}>
      <div className={cx('mainBody')}>
        {caregiverReferralDetail && (
          <>
            <BaseButton
              onClick={handleBack}
              text={t('common_text_back')}
              typeStyle={ButtonTypeEnum.ONLY_TEXT}
              iconLeft={icons.commonIconPrev}
              height={17}
            />

            <section className={cx('headGroup')}>
              <div className={cx('basicInfoGroup')}>
                <ImageCircle
                  type={caregiverReferralDetail?.avatarUrl ? ImageCircleTypeEnum.IMAGE : ImageCircleTypeEnum.TEXT}
                  width={91}
                  height={91}
                  imageUrl={caregiverReferralDetail?.avatarUrl}
                  fontSize={45}
                  firstName={caregiverReferralDetail?.firstName || caregiverReferralDetail.organizationName}
                  lastName={caregiverReferralDetail?.lastName}
                />

                <div className={cx('basicInfo')}>
                  <div className={cx('nameGroup')}>
                    <div className={cx('fullName')}>
                      {caregiverReferralDetail.role === ReferralRoleEnum.ORGANIZATION
                        ? caregiverReferralDetail?.organizationName
                        : getUserName(
                            caregiverReferralDetail?.firstName,
                            caregiverReferralDetail?.middleName,
                            caregiverReferralDetail?.lastName
                          )}
                    </div>

                    {caregiverReferralDetail.role === ReferralRoleEnum.INDIVIDUAL && (
                      <p className={cx('valueGroup')}>
                        {t('info_tab_title_organization')}
                        <span className={cx('value')}>{caregiverReferralDetail?.organization?.name}</span>
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className={cx('navTabGroup')}>
                <Tab items={caregiverReferralDetailTabs} paddingBottom={30} />
              </div>
            </section>

            <section className={cx('tabContent')}>{renderTabContent}</section>
          </>
        )}
      </div>
    </div>
  );
};

export default CaregiverReferralDetails;
