// Libs
import classNames from 'classnames/bind';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Event } from 'react-big-calendar';
import moment from 'moment';
import { Tooltip } from 'react-tooltip';
import { useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
import {
  BaseButton,
  ModalUnderDevelopment,
  ToolBar,
  SelectStatus,
  NewMySchedule,
  NewScheduleAssignee,
  NewEventPopup,
  FormShiftScheduleModal,
} from '~/components';
// Context
// Others
import { optionsStatusShiftSchedule } from '~/mockData';
import { ButtonTypeEnum, DateFormatEnum, StatusEnum } from '~/utils/enum';
import { DEFAULT_NUMBER_ZERO, DEFAULT_VIEW_MODE } from '~/utils/constants/common';
import { TCalendarViewMode } from '~/utils/type/schedule';
import { LoadingData } from '~/context';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { RootState } from '~/redux/store';
import { scheduleActions } from '~/thunks/schedule/scheduleSlice';
import { IEventSchedule, IParamsGetOpenShifts, IScheduleClient } from '~/utils/interface/schedule';
import { IBaseOption } from '~/utils/interface/common';
import { getOpenShifts } from '~/thunks/schedule/scheduleThunk';
import { prepareMonthEventsOpenShiftCalendar, prepareOpenShiftEventsData } from './helper';
import { getUserName } from '~/utils/helper';
import { statusColor } from '~/utils/constants/color';
// Styles, images, icons
import styles from './OpenShiftsCalendar.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const OpenShiftsCalendar = (props: Props) => {
  //#region Destructuring Props
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const { isRefreshShiftSchedules } = useAppSelector((state: RootState) => state.scheduleState);

  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  const [openShiftsEvent, setOpenShiftsEvent] = useState<IEventSchedule[]>([]);
  const [calendarViewMode, setCalendarViewMode] = useState<TCalendarViewMode>(DEFAULT_VIEW_MODE);
  const [currentTime, setCurrentTime] = useState<string>(moment().format(DateFormatEnum.YYYY_MM_DD));
  const [isShowEvent, setIsShowEvent] = useState<boolean>(false);
  const [dataEvent, setDataEvent] = useState<IScheduleClient>();
  const [isOpenShiftModal, setIsOpenShiftModal] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!currentTime) return;
    const { status, ...restParams } = params;
    const startDate = moment(currentTime).startOf(calendarViewMode).format(DateFormatEnum.YYYY_MM_DD);
    const endDate = moment(currentTime).endOf(calendarViewMode).format(DateFormatEnum.YYYY_MM_DD);

    const paramsOpenShift: IParamsGetOpenShifts = {
      startDate: startDate,
      endDate: endDate,
      ...(params?.status === StatusEnum.SHOW_ALL ? {} : { status: params?.status }),
      ...restParams,
    };
    loading?.show();
    handleGetOpenShiftsCalendar(paramsOpenShift);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime, calendarViewMode, params]);

  useEffect(() => {
    if (!isRefreshShiftSchedules) return;

    const { status, ...restParams } = params;
    const startDate = moment(currentTime).startOf(calendarViewMode).format(DateFormatEnum.YYYY_MM_DD);
    const endDate = moment(currentTime).endOf(calendarViewMode).format(DateFormatEnum.YYYY_MM_DD);

    const paramsOpenShift: IParamsGetOpenShifts = {
      startDate: startDate,
      endDate: endDate,
      ...(params?.status === StatusEnum.SHOW_ALL ? {} : { status: params?.status }),
      ...restParams,
    };

    handleGetOpenShiftsCalendar(paramsOpenShift);
    dispatch(scheduleActions.setRefreshShiftSchedules(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshShiftSchedules]);

  const events: Event[] = useMemo(() => {
    switch (calendarViewMode) {
      case 'month':
        const preparedEvents = prepareMonthEventsOpenShiftCalendar(openShiftsEvent);
        return preparedEvents;

      case 'week':
      case 'day':
        const preparedEventWeekDay = prepareOpenShiftEventsData(openShiftsEvent);
        return preparedEventWeekDay;
    }
  }, [openShiftsEvent, calendarViewMode]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetOpenShiftsCalendar = (params: IParamsGetOpenShifts) => {
    dispatch(getOpenShifts(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        setOpenShiftsEvent(res.data);
      })
      .catch((_err) => {})
      .finally(() => loading?.hide());
  };

  const handleShowModalAddShift = () => {
    setIsOpenShiftModal(!isOpenShiftModal);
  };

  const handleClickUnderDevelop = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleTimeChange = (timeValue: string) => {
    if (!timeValue) return;
    setCurrentTime(timeValue);
  };

  const handleCalendarViewMode = (view: TCalendarViewMode) => {
    if (!view) return;
    setCalendarViewMode(view);
    setCurrentTime(moment().format(DateFormatEnum.YYYY_MM_DD));
  };

  const handleSelectEvent = (event: IScheduleClient) => {
    if (!event) return;

    setIsShowEvent(true);
    setDataEvent(event);
  };

  const handleShowEvent = () => {
    setIsShowEvent(!isShowEvent);
  };

  const handleFilterStatus = (data: IBaseOption) => {
    const { status, ...restParams } = params;

    setSearchParams({
      ...restParams,
      ...(data?.value === StatusEnum.SHOW_ALL ? {} : { status: data?.value.toString() }),
    });
  };
  //#endregion Handle Function

  return (
    <div id='openShiftsCalendarPage' className={cx('container')}>
      <ToolBar title={t('open_shifts_calendar_title')}>
        {/* <SelectStatus
          options={optionsStatusShiftSchedule}
          placeholder={t('common_select_placeholder')}
          width={204}
          height={36}
          value={params?.status || String(optionsStatusShiftSchedule[DEFAULT_NUMBER_ZERO].value)}
          onChange={handleFilterStatus}
        /> */}
        {/* <BaseButton iconLeft={icons.commonIconSort} text={t('common_text_filter')} width={67} onClick={handleSortBy} /> */}
        <BaseButton
          typeStyle={ButtonTypeEnum.PRIMARY}
          iconLeft={icons.commonIconPlus}
          text={t('open_shifts_calendar_add_schedule_title')}
          onClick={handleShowModalAddShift}
        />
      </ToolBar>

      <div className={cx('content')}>
        <NewMySchedule<IScheduleClient>
          events={events || []}
          date={currentTime}
          onTimeChange={handleTimeChange}
          childrenEvent={(event) => {
            const { status, client, startTime, endTime } = event;
            const formattedStartTime = moment(startTime, 'HH:mm:ss').format('h:mm A');
            const formattedEndTime = moment(endTime, 'HH:mm:ss').format('h:mm A');

            return (
              <div
                id='eventContainer'
                className={cx('eventContainer', calendarViewMode === 'month' && 'monthEvent')}
                style={{
                  borderWidth: 1,
                  backgroundColor: statusColor.SKY_300,
                }}
              >
                <div className={cx('eventWrap')}>
                  <div className={cx('assignees')}>
                    {client && (
                      <NewScheduleAssignee data={[client]} eventColor={statusColor.SKY_300} width={32} height={32} />
                    )}
                  </div>

                  <div className={cx('firstLastName')}>
                    <div
                      className={cx('eventName')}
                      data-tooltip-id={`client`}
                      data-tooltip-place='left'
                      data-tooltip-content={getUserName(client?.firstName, client?.lastName)}
                    >
                      {getUserName(client?.firstName, client?.lastName)}
                    </div>

                    <div className={cx('eventTime')}>
                      {startTime && endTime && `${formattedStartTime} - ${formattedEndTime}`}
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
          onSelectEvent={handleSelectEvent}
          onCalendarViewMode={handleCalendarViewMode}
        />
      </div>

      {isShowModalUnderDevelopment && <ModalUnderDevelopment onClose={handleClickUnderDevelop} />}

      {isOpenShiftModal && (
        <FormShiftScheduleModal isOpen={isOpenShiftModal} onClose={handleShowModalAddShift} isOpenShift />
      )}

      <Tooltip id={`client`} className={'tooltipWrap'} />

      <NewEventPopup isOpen={isShowEvent} onClose={handleShowEvent} data={dataEvent} />
    </div>
  );
};

export default OpenShiftsCalendar;
