// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { RootState } from '~/redux/store';
// Components, Layouts, Pages
import { BaseSelectMultiple, BaseSelect, BaseInput } from '~/components';
// Others
import { IFormContact } from '~/utils/interface/crm/contact';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_LIMIT_MAX_ITEM,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  permissionContact,
  relationshipContact,
} from '~/utils/constants/common';
import { IBaseOption } from '~/utils/interface/common';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { getDetailClients, getListClients } from '~/thunks/crm/clients/clientsThunk';
import { IListQueryParamsClients } from '~/utils/interface/crm/clients';
import { CRMEnum, StatusEnum } from '~/utils/enum';
import { getUserName } from '~/utils/helper';
// Styles, images, icons
import styles from './FieldClientContact.module.scss';
import { icons } from '~/assets';

type Props = {
  clientId?: string;
};

const cx = classNames.bind(styles);

const FieldClientContact = (props: Props) => {
  //#region Destructuring Props
  const { clientId } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<IFormContact>();

  const {
    fields: clientFields,
    append: appendClientContact,
    remove: removeClientContact,
  } = useFieldArray({
    control,
    name: 'clients',
  });

  const { clientDetail } = useAppSelector((root: RootState) => root.clientState);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [listClient, setListClient] = useState<IBaseOption[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetListClient();
  }, []);

  useEffect(() => {
    if (!clientId) return;

    if (!clientDetail || Object.keys(clientDetail).length === DEFAULT_NUMBER_ZERO) {
      handleGetDetailClient(clientId);
    }

    setValue(`clients.${DEFAULT_NUMBER_ZERO}.id`, clientDetail?.id || EMPTY_STRING, { shouldDirty: true });

    return () => {
      setValue(`clients.${DEFAULT_NUMBER_ZERO}.id`, EMPTY_STRING, { shouldDirty: true });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, clientDetail]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListClient = () => {
    const params: IListQueryParamsClients = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_LIMIT_MAX_ITEM,
      status: StatusEnum.ACTIVE,
      type: [CRMEnum.CLIENT, CRMEnum.PROSPECT],
    };
    dispatch(getListClients(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        const { responses } = res.data;
        const optionClient: IBaseOption[] = responses.map((res) => {
          return {
            label: getUserName(res.firstName, res.lastName),
            value: res.id,
          };
        });

        setListClient(optionClient);
      })
      .catch((err) => {})
      .finally(() => {});
  };

  const handleGetDetailClient = (id: string) => {
    dispatch(getDetailClients(id))
      .unwrap()
      .then((res) => {})
      .catch((err) => {})
      .finally(() => {});
  };
  //#endregion Handle Function

  return (
    <div id='clientContact' className={cx('clientContact')}>
      <div className={cx('headTitle')}>{t('form_contact_client_label')}</div>

      <div className={cx('fieldClientContactBody')}>
        {clientFields.map((_client, index) => {
          return (
            <div key={index} className={cx('fieldContent')}>
              <div className={cx('threeCol')}>
                {clientId ? (
                  <BaseInput
                    id='name'
                    disabled={true}
                    label={t('form_contact_name_label')}
                    value={getUserName(clientDetail?.firstName, clientDetail?.lastName)}
                  />
                ) : (
                  <Controller
                    control={control}
                    name={`clients.${index}.id`}
                    render={({ field: { value, onChange } }) => (
                      <BaseSelect
                        placeholder={t('common_select_placeholder')}
                        label={t('form_contact_name_label')}
                        options={listClient || []}
                        value={value || EMPTY_STRING}
                        onChange={({ value }) => onChange(value)}
                        required
                        errorMessage={errors.clients?.[index]?.id?.message}
                        disabled={!!clientId}
                        mode='search'
                      />
                    )}
                  />
                )}

                <Controller
                  control={control}
                  name={`clients.${index}.relationship`}
                  render={({ field: { value, onChange } }) => (
                    <BaseSelect
                      placeholder={t('common_select_placeholder')}
                      label={t('form_contact_relationship_label')}
                      options={relationshipContact || []}
                      value={value || EMPTY_STRING}
                      onChange={({ value }) => onChange(value)}
                      required
                      errorMessage={errors.clients?.[index]?.relationship?.message}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name={`clients.${index}.permissionClient`}
                  render={({ field: { value, onChange } }) => (
                    <BaseSelectMultiple
                      placeholder={t('common_select_placeholder')}
                      label={t('form_contact_permission_label')}
                      options={permissionContact || []}
                      value={value || []}
                      onChange={(value) => onChange(value)}
                      errorMessage={errors.clients?.[index]?.permissionClient?.message}
                    />
                  )}
                />
              </div>

              {!clientId && (
                <button type='button' onClick={() => removeClientContact(index)} className={cx('btnRemoveField')}>
                  <img src={icons.commonIconRemoveField} alt={t('common_img_text_alt')} />
                </button>
              )}
            </div>
          );
        })}

        {!clientId && (
          <button
            type='button'
            onClick={() => appendClientContact({ id: EMPTY_STRING, relationship: EMPTY_STRING, permissionClient: [] })}
            className={cx('btnAddField', 'group')}
          >
            <img src={icons.commonIconAddField} alt={t('common_img_text_alt')} className={cx('iconAddField')} />
            <span className={cx('btnTextAdd')}>{t('form_contact_btn_add_field_label')}</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default FieldClientContact;
