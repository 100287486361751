// Libs
import classNames from 'classnames/bind';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BaseSelect, BaseTextarea, BaseInput, BaseDatePicker } from '~/components';

// Others
import { IFormInitialCall } from '~/utils/interface/crm/initialCall';
import { EMPTY_STRING, OPTIONS_NEEDS_MEDICATION } from '~/utils/constants/common';

// Styles, images, icons
import styles from './ProspectCareNeeds.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const ProspectCareNeedsInitialCall = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IFormInitialCall>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='prospectCareNeedsInitialCall' className={cx('prospectCareNeedsInitialCall')}>
      <div className={cx('headTitle')}>{t('form_initial_call_section_prospect_care_needs_title')}</div>

      <div className={cx('bodyWrap')}>
        <Controller
          name='prospectCareNeed.careGoals'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseTextarea
              id='prospectCareNeed.careGoals'
              height={90}
              label={t('form_initial_call_section_prospect_care_needs_care_goals_title')}
              value={value || EMPTY_STRING}
              onChange={onChange}
              messageError={errors.prospectCareNeed?.careGoals?.message}
              required
            />
          )}
        />
        <div className={cx('threeCol')}>
          <Controller
            name='prospectCareNeed.initialContact'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseDatePicker
                id='prospectCareNeed.initialContact'
                label={t('form_initial_call_section_prospect_care_needs_initial_contact_title')}
                placeholderText={t('common_select_placeholder')}
                value={value || EMPTY_STRING}
                onDateSelected={onChange}
              />
            )}
          />

          <Controller
            name='prospectCareNeed.startDate'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseDatePicker
                id='prospectCareNeed.startDate'
                label={t('form_initial_call_section_prospect_care_needs_start_date_title')}
                value={value || EMPTY_STRING}
                placeholderText={t('common_select_placeholder')}
                onDateSelected={onChange}
              />
            )}
          />
          <Controller
            name='prospectCareNeed.liveWith'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='prospectCareNeed.liveWith'
                label={t('form_initial_call_section_prospect_care_needs_live_with_title')}
                value={value || EMPTY_STRING}
                onChange={onChange}
              />
            )}
          />
        </div>

        <Controller
          name='prospectCareNeed.medicalConditions'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseTextarea
              id='prospectCareNeed.medicalConditions'
              height={90}
              label={t('form_initial_call_section_prospect_care_needs_medical_conditions_title')}
              value={value || EMPTY_STRING}
              onChange={onChange}
            />
          )}
        />

        <Controller
          name='prospectCareNeed.mentalBehaviorConditions'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseTextarea
              id='prospectCareNeed.mentalBehaviorConditions'
              height={90}
              label={t('form_initial_call_section_prospect_care_needs_mental_behavior_conditions_title')}
              value={value || EMPTY_STRING}
              onChange={onChange}
            />
          )}
        />

        <Controller
          name='prospectCareNeed.ambulationNotes'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseTextarea
              id='prospectCareNeed.ambulationNotes'
              height={90}
              label={t('form_initial_call_section_prospect_care_needs_ambulation_notes_title')}
              value={value || EMPTY_STRING}
              onChange={onChange}
            />
          )}
        />

        <div className={cx('twoCol')}>
          <Controller
            name='prospectCareNeed.isNeedsMedication'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseSelect
                label={t('form_initial_call_section_prospect_care_needs_needs_medication_title')}
                placeholder={t('common_select_placeholder')}
                options={OPTIONS_NEEDS_MEDICATION}
                value={String(value)}
                onChange={({ value }) => onChange(Boolean(value))}
              />
            )}
          />
          <Controller
            name='prospectCareNeed.medicationsSupplementsManages'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='prospectCareNeed.medicationsSupplementsManages'
                label={t('form_initial_call_section_prospect_care_needs_medications_supplements_manages_title')}
                value={value || EMPTY_STRING}
                onChange={onChange}
              />
            )}
          />
        </div>

        <Controller
          name='prospectCareNeed.medications'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseTextarea
              id='prospectCareNeed.medications'
              height={90}
              label={t('form_initial_call_section_prospect_care_needs_medications_title')}
              value={value || EMPTY_STRING}
              onChange={onChange}
            />
          )}
        />

        <Controller
          name='prospectCareNeed.specialDiet'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseInput
              id='prospectCareNeed.specialDiet'
              label={t('form_initial_call_section_prospect_care_needs_special_diet_title')}
              value={value || EMPTY_STRING}
              onChange={onChange}
            />
          )}
        />

        <div className={cx('fourCol')}>
          <Controller
            name='prospectCareNeed.breakfast'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='prospectCareNeed.breakfast'
                label={t('form_initial_call_section_prospect_care_needs_breakfast_title')}
                value={value || EMPTY_STRING}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name='prospectCareNeed.lunch'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='prospectCareNeed.lunch'
                label={t('form_initial_call_section_prospect_care_needs_lunch_title')}
                value={value || EMPTY_STRING}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name='prospectCareNeed.snack'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='prospectCareNeed.snack'
                label={t('form_initial_call_section_prospect_care_needs_snack_title')}
                value={value || EMPTY_STRING}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name='prospectCareNeed.dinner'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='prospectCareNeed.dinner'
                label={t('form_initial_call_section_prospect_care_needs_dinner_title')}
                value={value || EMPTY_STRING}
                onChange={onChange}
              />
            )}
          />
        </div>

        <Controller
          name='prospectCareNeed.mealNotes'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseTextarea
              id='prospectCareNeed.mealNotes'
              height={90}
              label={t('form_initial_call_section_prospect_care_needs_meal_notes_title')}
              value={value || EMPTY_STRING}
              onChange={onChange}
            />
          )}
        />

        <Controller
          name='prospectCareNeed.derivingNotes'
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseTextarea
              id='prospectCareNeed.derivingNotes'
              height={90}
              label={t('form_initial_call_section_prospect_care_needs_deriving_notes_title')}
              value={value || EMPTY_STRING}
              onChange={onChange}
            />
          )}
        />
      </div>
    </div>
  );
};

export default ProspectCareNeedsInitialCall;
