// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// Components, Layouts, Pages
import { BaseButton, TimeClockDetail, ToolBar } from '~/components';
// Others
import { ButtonTypeEnum, TimeClockQueryTypeEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './TimeClockEmployeeDetail.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const TimeClockEmployeeDetailAdmin = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleGoBackHome = () => {
    navigate(-1);
  };
  //#endregion Handle Function

  return (
    <div id='timeClockDetail' className={cx('container')}>
      <BaseButton
        onClick={handleGoBackHome}
        typeStyle={ButtonTypeEnum.ONLY_TEXT}
        iconLeft={icons.commonIconPrev}
        text={t('common_text_back')}
      />
      <div className={cx('headerToolBar')}>
        <ToolBar title={t('time_clock_detail_label')}></ToolBar>
      </div>

      <div>{<TimeClockDetail type={TimeClockQueryTypeEnum.EMPLOYEE} />}</div>
    </div>
  );
};
export default TimeClockEmployeeDetailAdmin;
