// Libs
import classNames from 'classnames/bind';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { TFunction } from 'i18next';

// Components, Layouts, Pages
import { Modal, BaseSelect, BaseButton, Loading } from '~/components';

// Others
import { useAppDispatch } from '~/redux/hooks';
import { ButtonTypeEnum, StatusEnum } from '~/utils/enum';
import { IBaseOption } from '~/utils/interface/common';
import { IAssignToContact, IParamAssignToContact } from '~/utils/interface/file';
import { DEFAULT_CURRENT_PAGE, DEFAULT_LIMIT_MAX_ITEM, EMPTY_STRING } from '~/utils/constants/common';
import { IListQueryParamsContact } from '~/utils/interface/crm/contact';
import { getUserName } from '~/utils/helper';
import { getListContact } from '~/thunks/crm/contact/contactThunk';
import { assignToContact } from '~/thunks/file/fileThunk';

// Styles, images, icons
import styles from './ModalAssignToContact.module.scss';
import { useParams } from 'react-router-dom';

type Props = {
  fileId: string;
  isOpen: boolean;
  onClose: () => void;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup.object().shape({
    contact: yup.string().required(t('common_message_required_error')),
  });
};

const defaultValues: IAssignToContact = {
  contact: EMPTY_STRING,
};

const ModalAssignToContact = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, fileId, onClose } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { clientId } = useParams();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IAssignToContact>({
    resolver: yupResolver(schema(t)),
    defaultValues: defaultValues,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [listContact, setListContact] = useState<IBaseOption[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetListContact();
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleClose = () => {
    reset();
    onClose();
  };

  const handleGetListContact = () => {
    const params: IListQueryParamsContact = {
      limit: DEFAULT_LIMIT_MAX_ITEM,
      page: DEFAULT_CURRENT_PAGE,
      status: StatusEnum.ACTIVE,
      clientId: clientId,
    };
    setIsLoading(true);

    dispatch(getListContact(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        const listContact = res.data?.responses?.map((data) => ({
          label: data.organization || getUserName(data.firstName, data.lastName),
          value: data.id,
        }));
        setListContact(listContact);
      })
      .catch((error) => {})
      .finally(() => setIsLoading(false));
  };

  const handleAssignToContact = (data: IAssignToContact) => {
    if (!data?.contact || !fileId) return;

    const param: IParamAssignToContact = {
      contactId: data.contact,
      fileId: fileId,
    };
    setIsLoading(true);

    dispatch(assignToContact(param))
      .unwrap()
      .then((res) => {
        onClose && onClose();
      })
      .catch((error) => {})
      .finally(() => setIsLoading(false));
  };
  //#endregion Handle Function

  return (
    <div id='modalAssignToContact' className={cx('container')}>
      <Modal width={450} isOpen={isOpen} onClose={handleClose} title={t('form_assign_to_convert_title')}>
        <form className={cx('formContainer')} onSubmit={handleSubmit(handleAssignToContact)}>
          <div className={cx('contentModal')}>
            <Controller
              name='contact'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseSelect
                  value={value}
                  options={listContact || []}
                  label={t('form_assign_to_convert_contact_title')}
                  placeholder={t('common_select_placeholder')}
                  onChange={({ value }) => {
                    onChange(value);
                  }}
                  mode='search'
                  required
                  errorMessage={errors.contact?.message}
                />
              )}
            />
          </div>

          <div className={cx('footerModal')}>
            <BaseButton type='button' text={t('common_cancel_label')} onClick={handleClose} />
            <BaseButton type='submit' text={t('common_save_label')} typeStyle={ButtonTypeEnum.PRIMARY} width={80} />
          </div>
        </form>
        {isLoading && <Loading />}
      </Modal>
    </div>
  );
};

export default ModalAssignToContact;
