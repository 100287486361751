// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { RootState } from '~/redux/store';
import {
  changeStageApplicant,
  createApplicant,
  getListApplicant,
  convertToCaregiver,
  getDetailRequestApplicant,
  editApplicant,
  changeStatusApplicant,
} from '~/thunks/caregivers/applicant/applicantThunk';
import { IDetailRequestApplicant } from '~/utils/interface/applicant';

export interface ApplicantState {
  isRefreshApplicantList: boolean;
  requestApplicantDetail?: IDetailRequestApplicant;
}

const initialState: ApplicantState = {
  isRefreshApplicantList: false,
};

const applicantSlice = createSlice({
  name: 'applicantState',
  initialState: initialState,
  reducers: {
    setRefreshList(state, action) {
      state.isRefreshApplicantList = action.payload;
    },

    setDataDetailRequestApplicant(state, action) {
      state.requestApplicantDetail = action.payload;
    },
  },
  extraReducers(builder) {
    // Get list Applicant
    builder
      .addCase(getListApplicant.pending, (state) => {})
      .addCase(getListApplicant.fulfilled, (state) => {})
      .addCase(getListApplicant.rejected, (state) => {});

    // Create Applicant
    builder
      .addCase(createApplicant.pending, (state) => {})
      .addCase(createApplicant.fulfilled, (state) => {})
      .addCase(createApplicant.rejected, (state) => {});

    // Convert To Caregiver
    builder
      .addCase(convertToCaregiver.pending, (state) => {})
      .addCase(convertToCaregiver.fulfilled, (state) => {})
      .addCase(convertToCaregiver.rejected, (state) => {});

    // Change Stage Applicant
    builder
      .addCase(changeStageApplicant.pending, (state) => {})
      .addCase(changeStageApplicant.fulfilled, (state) => {})
      .addCase(changeStageApplicant.rejected, (state) => {});

    // Get Detail Request Applicant
    builder
      .addCase(getDetailRequestApplicant.pending, (state) => {})
      .addCase(getDetailRequestApplicant.fulfilled, (state, action) => {
        state.requestApplicantDetail = action.payload.data;
      })
      .addCase(getDetailRequestApplicant.rejected, (state) => {});

    // Edit Request Applicant
    builder
      .addCase(editApplicant.pending, (state) => {})
      .addCase(editApplicant.fulfilled, (state) => {})
      .addCase(editApplicant.rejected, (state) => {});

    // Change Status Applicant
    builder
      .addCase(changeStatusApplicant.pending, (state) => {})
      .addCase(changeStatusApplicant.fulfilled, (state) => {})
      .addCase(changeStatusApplicant.rejected, (state) => {});
  },
});

// Actions
export const applicantActions = applicantSlice.actions;

// Selectors
export const selectIsRefreshApplicantList = (state: RootState) => state.applicantState.isRefreshApplicantList;

// Reducer
const applicantReducer = applicantSlice.reducer;
export default applicantReducer;
