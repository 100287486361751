import { createAsyncThunk } from '@reduxjs/toolkit';
import shiftScheduleApi from '~/apis/crm/shiftSchedule';
import scheduleApi from '~/apis/schedule';
import {
  CREATE_SHIFT_SCHEDULE,
  GET_LIST_SCHEDULE,
  DELETE_SCHEDULE,
  GET_LIST_SHIFT_SCHEDULE,
  GET_DETAIL_SCHEDULE,
  UPDATE_SCHEDULE,
  GET_SCHEDULE_CLIENT,
  GET_OFFICE_SCHEDULE,
  GET_SCHEDULES_LIVE_VIEW,
  CREATE_SCHEDULE,
  GET_DETAIL_SHIFT,
  GET_LIST_NOTE_SCHEDULE,
  CREATE_SHIFT_NOTE_SCHEDULE,
  UPDATE_SHIFT_SCHEDULE,
  GET_SCHEDULE_CAREGIVER,
  GET_SHIFT_SCHEDULE_CLIENT,
  GET_DETAIL_UNAVAILABILITY,
  CREATE_UNAVAILABILITY_NOTE,
  CHANGE_STATUS_SHIFT_SCHEDULE,
  DELETE_SHIFT,
  GET_OPEN_SHIFTS,
  CREATE_OFFICE_CALENDAR,
  UPDATE_OFFICE_CALENDAR,
  DETAIL_OFFICE_CALENDAR,
  DELETE_OFFICE_CALENDAR,
  DETAIL_OFFICE_CALENDAR_EVENT,
} from '~/utils/constants/actionType';
import { IFormCreateSchedule, IQueryParamGetListSchedule } from '~/utils/interface/crm/shiftSchedule';
import {
  IPayloadUpdateShiftSchedule,
  ICreateShiftNote,
  IFormOfficeCalendar,
  ICreateUnavailabilityNote,
  IFormSchedule,
  IFormUpdateSchedule,
  IGetSchedulesLiveViewParams,
  IParamGetOfficeSchedule,
  IParamsGetNotes,
  IParamsGetOpenShifts,
  IParamsGetScheduleCaregiver,
  IParamsGetScheduleClient,
  IParamsGetShiftScheduleClient,
  IPayloadEditSchedule,
  IPayloadUpdateOfficeCalendar,
} from '~/utils/interface/schedule';

export const createSchedule = createAsyncThunk(
  CREATE_SCHEDULE,
  async (payload: IFormCreateSchedule, { rejectWithValue }) => {
    try {
      const res = await shiftScheduleApi.createSchedule(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListShiftSchedule = createAsyncThunk(
  GET_LIST_SHIFT_SCHEDULE,
  async (payload: IQueryParamGetListSchedule, { rejectWithValue }) => {
    try {
      const res = await shiftScheduleApi.getListShiftSchedule(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListSchedule = createAsyncThunk(
  GET_LIST_SCHEDULE,
  async (params: IQueryParamGetListSchedule, { rejectWithValue }) => {
    try {
      const res = await shiftScheduleApi.getListSchedule(params);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteSchedule = createAsyncThunk(DELETE_SCHEDULE, async (scheduleId: string, { rejectWithValue }) => {
  try {
    const res = await scheduleApi.deleteSchedule(scheduleId);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDetailSchedule = createAsyncThunk(
  GET_DETAIL_SCHEDULE,
  async (scheduleId: string, { rejectWithValue }) => {
    try {
      const res = await scheduleApi.getDetailSchedule(scheduleId);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateSchedule = createAsyncThunk(
  UPDATE_SCHEDULE,
  async (payload: IPayloadEditSchedule, { rejectWithValue }) => {
    try {
      const res = await scheduleApi.updateSchedule(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getScheduleClient = createAsyncThunk(
  GET_SCHEDULE_CLIENT,
  async (payload: IParamsGetScheduleClient, { rejectWithValue }) => {
    try {
      const res = await scheduleApi.getScheduleClient(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getOfficeSchedule = createAsyncThunk(
  GET_OFFICE_SCHEDULE,
  async (payload: IParamGetOfficeSchedule, { rejectWithValue }) => {
    try {
      const res = await scheduleApi.getOfficeSchedule(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSchedulesLiveView = createAsyncThunk(
  GET_SCHEDULES_LIVE_VIEW,
  async (payload: IGetSchedulesLiveViewParams, { rejectWithValue }) => {
    try {
      const res = await scheduleApi.getSchedulesLiveView(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createShiftSchedule = createAsyncThunk(
  CREATE_SHIFT_SCHEDULE,
  async (payload: IFormSchedule, { rejectWithValue }) => {
    try {
      const res = await shiftScheduleApi.createShiftSchedule(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changeStatusShiftSchedule = createAsyncThunk(
  CHANGE_STATUS_SHIFT_SCHEDULE,
  async (payload: IFormUpdateSchedule, { rejectWithValue }) => {
    try {
      const res = await shiftScheduleApi.changeStatusShiftSchedule(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDetailShift = createAsyncThunk(GET_DETAIL_SHIFT, async (shiftId: string, { rejectWithValue }) => {
  try {
    const res = await shiftScheduleApi.getDetailShift(shiftId);
    return res.data.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getListNoteSchedule = createAsyncThunk(
  GET_LIST_NOTE_SCHEDULE,
  async (payload: IParamsGetNotes, { rejectWithValue }) => {
    try {
      const res = await scheduleApi.getListNoteSchedule(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createShiftNoteSchedule = createAsyncThunk(
  CREATE_SHIFT_NOTE_SCHEDULE,
  async (payload: ICreateShiftNote, { rejectWithValue }) => {
    try {
      const res = await scheduleApi.createShiftNoteSchedule(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getScheduleCaregiver = createAsyncThunk(
  GET_SCHEDULE_CAREGIVER,
  async (payload: IParamsGetScheduleCaregiver, { rejectWithValue }) => {
    try {
      const res = await scheduleApi.getScheduleCaregiver(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getShiftScheduleClient = createAsyncThunk(
  GET_SHIFT_SCHEDULE_CLIENT,
  async (payload: IParamsGetShiftScheduleClient, { rejectWithValue }) => {
    try {
      const res = await scheduleApi.getShiftScheduleClient(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDetailUnavailability = createAsyncThunk(
  GET_DETAIL_UNAVAILABILITY,
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await scheduleApi.getDetailUnavailability(id);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createUnavailabilityNote = createAsyncThunk(
  CREATE_UNAVAILABILITY_NOTE,
  async (payload: ICreateUnavailabilityNote, { rejectWithValue }) => {
    try {
      const res = await scheduleApi.createUnavailabilityNote(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateShiftSchedule = createAsyncThunk(
  UPDATE_SHIFT_SCHEDULE,
  async (payload: IPayloadUpdateShiftSchedule, { rejectWithValue }) => {
    try {
      const res = await shiftScheduleApi.updateShiftSchedule(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteShift = createAsyncThunk(DELETE_SHIFT, async (shiftId: string, { rejectWithValue }) => {
  try {
    const res = await shiftScheduleApi.deleteShift(shiftId);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getOpenShifts = createAsyncThunk(
  GET_OPEN_SHIFTS,
  async (payload: IParamsGetOpenShifts, { rejectWithValue }) => {
    try {
      const res = await scheduleApi.getOpenShifts(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createOfficeCalendar = createAsyncThunk(
  CREATE_OFFICE_CALENDAR,
  async (body: IFormOfficeCalendar, { rejectWithValue }) => {
    try {
      const res = await scheduleApi.createOfficeCalendar(body);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDetailOfficeCalendar = createAsyncThunk(
  DETAIL_OFFICE_CALENDAR,
  async (scheduleId: string, { rejectWithValue }) => {
    try {
      const res = await scheduleApi.getDetailOfficeCalendar(scheduleId);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateOfficeCalendar = createAsyncThunk(
  UPDATE_OFFICE_CALENDAR,
  async (payload: IPayloadUpdateOfficeCalendar, { rejectWithValue }) => {
    try {
      const res = await scheduleApi.updateOfficeCalendar(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteOfficeCalendar = createAsyncThunk(
  DELETE_OFFICE_CALENDAR,
  async (scheduleId: string, { rejectWithValue }) => {
    try {
      const res = await scheduleApi.deleteOfficeCalendar(scheduleId);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDetailOfficeCalendarEvent = createAsyncThunk(
  DETAIL_OFFICE_CALENDAR_EVENT,
  async (scheduleId: string, { rejectWithValue }) => {
    try {
      const res = await scheduleApi.getDetailOfficeCalendarEvent(scheduleId);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
