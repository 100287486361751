// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TFunction } from 'i18next';
import * as yup from 'yup';
// Components, Layouts, Pages
import { BaseButton, BaseDatePicker, BaseInput, BaseUploadFile } from '~/components';
// Others
import { ALLOWED_FILE_TYPE, EMPTY_STRING } from '~/utils/constants/common';
import { ButtonTypeEnum } from '~/utils/enum';
import { IUploadFileDocument } from '~/utils/interface/file';
// Styles, images, icons
import styles from './AddFiles.module.scss';
import { icons } from '~/assets';

type Props = {
  title?: string;
  onSubmitFormSuccess: (data: IUploadFileDocument) => void;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup.object({
    name: yup.string().trim().required(t('common_message_required_error')),
    files: yup.array().min(1, t('common_message_required_error')).required(),
    expirationDate: yup.string().optional(),
  });
};

const AddFiles = (props: Props) => {
  //#region Destructuring Props
  const { title, onSubmitFormSuccess } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const defaultValues = useMemo(() => {
    return {
      name: EMPTY_STRING,
      files: [],
    };
  }, []);
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm<IUploadFileDocument>({
    resolver: yupResolver(schema(t)),
    defaultValues: defaultValues,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleUpload = (data: IUploadFileDocument) => {
    onSubmitFormSuccess(data);
    reset();
  };
  //#endregion Handle Function

  return (
    <div id='addFilesComponent' className={cx('addFilesComponent')}>
      <div className={cx('headTitle')}>
        <h3 className={cx('title')}>{title}</h3>
        <button>
          <img className={cx('icon')} src={icons.commonIconArrowBottom} alt={t('common_img_text_alt')} />
        </button>
      </div>

      <form className={cx('content')} onSubmit={handleSubmit(handleUpload)}>
        <Controller
          name='files'
          control={control}
          render={({ field: { name, value, onChange } }) => (
            <BaseUploadFile
              key={name}
              label={t('common_component_upload_files_label')}
              height={64}
              defaultFileList={value || []}
              onChange={(item) => {
                onChange(item);
                setValue('name', item?.[0]?.name || EMPTY_STRING, {
                  shouldDirty: true,
                });
              }}
              acceptFile={ALLOWED_FILE_TYPE}
              errorMessage={errors.files?.message}
              id='files'
              required
            />
          )}
        />

        <div className={cx('contentName')}>
          <Controller
            name='name'
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <BaseInput
                id={name}
                label={t('form_add_file_name_title')}
                value={value}
                messageError={errors.name?.message}
                onChange={onChange}
                required
              />
            )}
          />
        </div>

        {/* <div className={cx('twoCol')}> */}
        {/* <Controller
            name='name'
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <BaseInput
                id={name}
                label={t('form_add_file_name_title')}
                value={value}
                messageError={errors.name?.message}
                onChange={onChange}
                required
              />
            )}
          /> */}

        {/* <Controller
            name='expirationDate'
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <BaseDatePicker
                id={name}
                label={t('form_add_file_expiration_date_title')}
                onDateSelected={onChange}
                value={value}
                name={'expirationDate'}
                placeholderText={t('common_placeholder_select')}
                errorMessage={errors.expirationDate?.message}
              />
            )}
          /> */}
        {/* </div> */}
        <div className={cx('footerModal')}>
          <BaseButton type='submit' text={t('common_save_label')} typeStyle={ButtonTypeEnum.PRIMARY} width={80} />
        </div>
      </form>
    </div>
  );
};

export default AddFiles;
