// Libs
import classNames from 'classnames/bind';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components, Layouts, Pages
import { DonutChartReport, ToolBar } from '~/components';

// Others
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { getAllClientsByLocation } from '~/thunks/report/reportThunk';
import { IAllClientsByLocation, IQueryGetAllUserByLocation } from '~/utils/interface/report';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_LIMIT_MAX_ITEM,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { ColorEnum } from '~/utils/enum';
import { BLUE, FINN800, ORANGE } from '~/utils/constants/color';
import { IBaseOption, IListQueryParams } from '~/utils/interface/common';
import { getListLocations } from '~/thunks/crm/location/locationThunk';

// Styles, images, icons
import styles from './ReportingClient.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const ReportingClient = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loadingData = useContext(LoadingData);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [dataChart, setDataChart] = useState<IAllClientsByLocation>();
  const [location, setLocation] = useState<string>();
  const [locations, setLocations] = useState<IBaseOption[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetListLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!location) return;

    handleGetAllClientByLocation(location);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  //#endregion Implement Hook

  // //#region Handle Function
  const handleGetAllClientByLocation = (location: string) => {
    if (!location) return;

    const payload: IQueryGetAllUserByLocation = {
      locationId: location,
    };

    loadingData?.show();

    dispatch(getAllClientsByLocation(payload))
      .unwrap()
      .then((res) => {
        if (!res || !res?.data) return;

        setDataChart(res?.data);
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleLocationChange = (newLocation: IBaseOption) => {
    if (!newLocation) return;

    const { value } = newLocation;

    setLocation(String(value));
  };

  const dataChartConverted = () => {
    if (
      !dataChart ||
      (dataChart?.totalClientActive === DEFAULT_NUMBER_ZERO && dataChart?.totalClientInactive === DEFAULT_NUMBER_ZERO)
    )
      return [];

    const dataArr: number[] = Object.values(dataChart);

    return dataArr;
  };

  const handleGetListLocations = () => {
    const params: IListQueryParams = { page: DEFAULT_CURRENT_PAGE, limit: DEFAULT_LIMIT_MAX_ITEM };

    loadingData?.show();

    dispatch(getListLocations(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        const listLocation = res.data?.responses?.map((data) => ({
          label: data.name || EMPTY_STRING,
          value: data.id,
        }));
        setLocations(listLocation);

        if (listLocation.length > DEFAULT_NUMBER_ZERO) {
          setLocation(listLocation[DEFAULT_NUMBER_ZERO]?.value);
        }
      })
      .catch((err) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };
  //#endregion Handle Function

  return (
    <div id='reportingClientPage' className={cx('container')}>
      <div className={cx('mainBody')}>
        <ToolBar
          title={
            <div className={cx('crmTitle')}>
              <span>{t('client_report_tab_title')}</span>
            </div>
          }
        />
        <div className={cx('analyticsChartWrap')}>
          <DonutChartReport
            title={t('client_report_tab_title_donut_chart')}
            data={dataChartConverted()}
            value={location}
            onLocationChange={handleLocationChange}
            colors={[
              {
                name: ColorEnum.BLUE,
                value: BLUE,
              },
              {
                name: ColorEnum.ORANGE,
                value: ORANGE,
              },
              {
                name: ColorEnum.FINN,
                value: FINN800,
              },
            ]}
            labels={[
              t('client_report_tab_label_active'),
              t('client_report_tab_label_inactive'),
              t('client_report_tab_label_on_hold'),
            ]}
            height={300}
            options={locations}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportingClient;
