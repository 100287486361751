import { urlApiApplicant } from '~/utils/constants/api';
import { BaseResponse, IListQueryParams, IListDataResponse } from '~/utils/interface/common';
import axiosClient from '~/apis/axiosClient';
import { CONTENT_TYPE_FORM_DATA } from '~/utils/constants/common';
import { IApplicant, IChangeStage, IDetailRequestApplicant } from '~/utils/interface/applicant';
import { IChangeStatus } from '~/utils/interface/crm/clients';

const applicantApi = {
  getList(params: IListQueryParams) {
    const url = `${urlApiApplicant.getList}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IApplicant[]>>>(url, { params });
  },

  createApplicant(body: FormData) {
    const url = `${urlApiApplicant.createApplicant}`;
    return axiosClient.post<BaseResponse>(url, body, { headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA } });
  },

  convertToCaregiver(applicantId: string) {
    const url = `${urlApiApplicant.convertCaregiver(applicantId)}`;
    return axiosClient.put<BaseResponse>(url);
  },

  changeStageApplicant({ applicantId, body }: { applicantId: string; body: IChangeStage }) {
    const url = `${urlApiApplicant.changeStage(applicantId)}`;
    return axiosClient.put<BaseResponse>(url, body);
  },

  getDetailRequestApplicant(applicantId: string) {
    const url = `${urlApiApplicant.getDetailApplicant(applicantId)}`;
    return axiosClient.get<BaseResponse<IDetailRequestApplicant>>(url);
  },

  editRequestApplicant({ applicantId, body }: { applicantId: string; body: FormData }) {
    const url = `${urlApiApplicant.editApplicant(applicantId)}`;
    return axiosClient.put<BaseResponse>(url, body, { headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA } });
  },

  changeStatusApplicant({ applicantId, body }: { applicantId: string; body: IChangeStatus }) {
    const url = `${urlApiApplicant.changeStatus(applicantId)}`;
    return axiosClient.put<BaseResponse>(url, body);
  },
};

export default applicantApi;
