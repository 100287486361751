// Libs
import classNames from 'classnames/bind';
import { Controller, useFormContext, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { memo } from 'react';
// Components, Layouts, Pages
import { BaseInput } from '~/components';
// Others
import { useAppDispatch } from '~/redux/hooks';
import { IFormSkill } from '~/utils/interface/caregivers/skill';
import { EMPTY_STRING } from '~/utils/constants/common';
import { formatPhoneNumber } from '~/utils/helper';

// Styles, images, icons
import styles from './ProfessionalReferencesSkill.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const ProfessionalReferencesSkill = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    control,
    setValue,
    resetField,
    getValues,
    formState: { errors },
  } = useFormContext<IFormSkill>();

  const {
    fields: professionalReferencesFields,
    append: appendProfessionalReferences,
    remove: removeProfessionalReferences,
  } = useFieldArray({
    control,
    name: 'professionalReferencesSkill',
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleAddProfessionalReferencesField = () => {
    appendProfessionalReferences({
      name: EMPTY_STRING,
      phoneNumber: EMPTY_STRING,
    });
  };
  //#endregion Handle Function

  return (
    <div id='professionalReferencesSkillComponent' className={cx('professionalReferencesSkillContainer')}>
      <div className={cx('headTitle')}>{t('form_skill_professional_references_title')}</div>

      <div className={cx('bodyWrap')}>
        {professionalReferencesFields.map((professionalReferencesItem, index) => {
          return (
            <div className={cx('towCol')} key={professionalReferencesItem.id}>
              <Controller
                control={control}
                name={`professionalReferencesSkill.${index}.name`}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id={`professionalReferencesSkill.${index}.name`}
                    label={t('form_skill_professional_references_name_title')}
                    value={value || EMPTY_STRING}
                    onChange={onChange}
                  />
                )}
              />

              <div className={cx('phoneNumber')}>
                <div className={cx('viewPhoneNumber')}>
                  <Controller
                    control={control}
                    name={`professionalReferencesSkill.${index}.phoneNumber`}
                    render={({ field: { value, onChange } }) => (
                      <BaseInput
                        id={`professionalReferencesSkill.${index}.phoneNumber`}
                        label={t('form_skill_professional_references_phone_number_title')}
                        value={value ? formatPhoneNumber(value) : EMPTY_STRING}
                        onChange={(event) => {
                          const { value } = event.target;
                          const formattedValue = formatPhoneNumber(value);
                          onChange(formattedValue);
                        }}
                      />
                    )}
                  />
                </div>

                <button
                  type='button'
                  className={cx('btnRemoveField')}
                  onClick={() => removeProfessionalReferences(index)}
                >
                  <img src={icons.commonIconRemoveField} alt={t('common_img_text_alt')} />
                </button>
              </div>
            </div>
          );
        })}

        <button type='button' onClick={handleAddProfessionalReferencesField} className={cx('btnAddField')}>
          <img src={icons.commonIconAddField} alt={t('common_img_text_alt')} />
          <span className={cx('btnTextAdd')}>{t('form_skill_professional_references_add_title')}</span>
        </button>
      </div>
    </div>
  );
};

export default memo(ProfessionalReferencesSkill);
