// Libs
import classNames from 'classnames/bind';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BaseDatePicker, BaseTextarea } from '~/components';
// Others
import { IFormInitialCall } from '~/utils/interface/crm/initialCall';
import { EMPTY_STRING } from '~/utils/constants/common';
// Styles, images, icons
import styles from './LogCall.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const LogCallInitialCall = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IFormInitialCall>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='logCallInitialCall' className={cx('logCallInitialCall')}>
      <div className={cx('headTitle')}>{t('form_initial_call_section_log_this_call_title')}</div>

      <div className={cx('bodyWrap')}>
        <div className={cx('twoCol')}>
          <Controller
            name={'callDate'}
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseDatePicker
                id='callDate'
                placeholderText={t('common_placeholder_select')}
                label={t('form_initial_call_section_care_date_title')}
                onDateSelected={onChange}
                value={value || EMPTY_STRING}
                errorMessage={errors.callDate?.message}
                required
              />
            )}
          />
        </div>
        <Controller
          name={'description'}
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseTextarea
              id='description'
              height={90}
              label={t('form_initial_call_section_description_title')}
              onChange={onChange}
              value={value || EMPTY_STRING}
            />
          )}
        />
      </div>
    </div>
  );
};

export default LogCallInitialCall;
