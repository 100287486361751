// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Event } from 'react-big-calendar';
import moment from 'moment';
import { Tooltip } from 'react-tooltip';
import { useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
import {
  BaseButton,
  ModalUnderDevelopment,
  ToolBar,
  SelectStatus,
  NewMySchedule,
  FormOfficeCalendar,
  EventScheduleModal,
  SummaryEventTab,
  ConfirmModal,
} from '~/components';
// Context
// Others
import { ButtonTypeEnum, DateFormatEnum, EventStatusEnum, StatusEnum, TabQueryEventEnum } from '~/utils/enum';
import { selectAllTypesOptions } from '~/mockData';
import {
  DEFAULT_NUMBER_ZERO,
  DEFAULT_VIEW_MODE,
  EMPTY_STRING,
  eventPopupUnavailableTabs,
} from '~/utils/constants/common';
import { TCalendarViewMode } from '~/utils/type/schedule';
import { LoadingData } from '~/context';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { getDetailOfficeCalendarEvent, getOfficeSchedule } from '~/thunks/schedule/scheduleThunk';
import { prepareMonthEventsDataOfficeSchedule, prepareTimeEventsData } from './helper';
import { IDetailUnavailability, IOfficeSchedule, IParamGetOfficeSchedule } from '~/utils/interface/schedule';
import { handleBackgroundColor } from '~/components/specific/scheduleModules/helper';
import { scheduleActions } from '~/thunks/schedule/scheduleSlice';
import { IBaseOption } from '~/utils/interface/common';
import { getUserName } from '~/utils/helper';
import { deleteUnavailability } from '~/thunks/hr/hrThunk';
// Styles, images, icons
import styles from './OfficeCalendar.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const OfficeCalendar = (props: Props) => {
  //#region Destructuring Props
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const isRefreshOfficeSchedule = useAppSelector((state) => state.scheduleState.isRefreshOfficeSchedule);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  const [scheduleEvents, setScheduleEvents] = useState<IOfficeSchedule[]>([]);
  const [calendarViewMode, setCalendarViewMode] = useState<TCalendarViewMode>(DEFAULT_VIEW_MODE);
  const [currentTime, setCurrentTime] = useState<string>(moment().format(DateFormatEnum.YYYY_MM_DD));
  const [isShowEvent, setIsShowEvent] = useState<boolean>(false);
  const [scheduleSelected, setScheduleSelected] = useState<IOfficeSchedule>();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const [typeSelected, setTypeSelected] = useState<string>();
  const [isShowEventUnavailable, setIsShowEventUnavailable] = useState<boolean>(false);
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false);
  //#endregion Declare State
  //#region Implement Hook
  const events: Event[] = useMemo(() => {
    switch (calendarViewMode) {
      case 'month':
        const preparedEvents = prepareMonthEventsDataOfficeSchedule(scheduleEvents);
        return preparedEvents;

      case 'week':
      case 'day':
        const preparedEventWeekDay = prepareTimeEventsData(scheduleEvents);

        return preparedEventWeekDay;
    }
  }, [scheduleEvents, calendarViewMode]);

  useEffect(() => {
    loading?.show();
    const startDate = moment(currentTime).startOf(calendarViewMode).format(DateFormatEnum.YYYY_MM_DD);
    const endDate = moment(currentTime).endOf(calendarViewMode).format(DateFormatEnum.YYYY_MM_DD);
    const newParams: IParamGetOfficeSchedule = {
      startDate,
      endDate,
      view: calendarViewMode.toUpperCase(),
      ...(params?.type === StatusEnum.SHOW_ALL_TYPES ? {} : { type: params?.type }),
    };
    handleGetListOfficeCalendar(newParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime, calendarViewMode, params]);

  useEffect(() => {
    if (!isRefreshOfficeSchedule) return;

    const startDate = moment(currentTime).startOf(calendarViewMode).format(DateFormatEnum.YYYY_MM_DD);
    const endDate = moment(currentTime).endOf(calendarViewMode).format(DateFormatEnum.YYYY_MM_DD);
    const newParams: IParamGetOfficeSchedule = {
      startDate,
      endDate,
      view: calendarViewMode.toUpperCase(),
      ...(params?.type === StatusEnum.SHOW_ALL ? {} : { type: params?.status }),
    };
    handleGetListOfficeCalendar(newParams);
    dispatch(scheduleActions.setRefreshOfficeSchedule(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshOfficeSchedule]);

  useEffect(() => {
    const typeStatus: string = String(selectAllTypesOptions[DEFAULT_NUMBER_ZERO].value);

    setTypeSelected(params.type || typeStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.type]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleFilterStatus = (data: IBaseOption) => {
    const { status, ...restParams } = params;

    setSearchParams({
      ...restParams,
      ...(data?.value === StatusEnum.SHOW_ALL ? {} : { type: data?.value.toString() }),
    });
  };

  const handleGetListOfficeCalendar = (params: IParamGetOfficeSchedule) => {
    dispatch(getOfficeSchedule(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        setScheduleEvents(res.data);
      })
      .catch((_err) => {})
      .finally(() => loading?.hide());
  };

  const handleTimeChange = (timeValue: string) => {
    if (!timeValue) return;
    setCurrentTime(timeValue);
  };

  const handleCalendarViewMode = (view: TCalendarViewMode) => {
    if (!view) return;
    setCalendarViewMode(view);
    setCurrentTime(moment().format(DateFormatEnum.YYYY_MM_DD));
  };

  const handleSelectEvent = (event: IOfficeSchedule) => {
    if (!event) return;

    setScheduleSelected(event);
    if (event.type === EventStatusEnum.UNAVAILABILITY) {
      setIsShowEventUnavailable(true);
    } else {
      setIsShowEvent(true);
    }
  };

  const handleClickUnderDevelop = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleShowFormSchedule = () => {
    setIsShowEvent(true);
  };

  const handleCloseFormSchedule = () => {
    setIsShowEvent(false);
    setScheduleSelected(undefined);
  };

  const handleCloseEventUnavailable = () => {
    setIsShowEventUnavailable(false);
    setScheduleSelected(undefined);
  };

  const handleGetDetailUnavailable = useCallback(async (id: string) => {
    try {
      const res = await dispatch(getDetailOfficeCalendarEvent(id)).unwrap();
      const detailUnavailability = res.data;

      const dataDetail: IDetailUnavailability = {
        id: detailUnavailability.id,
        endDate: detailUnavailability.endDate || EMPTY_STRING,
        startDate: detailUnavailability.startDate || EMPTY_STRING,
        endTime: detailUnavailability.endTime || EMPTY_STRING,
        startTime: detailUnavailability.startTime || EMPTY_STRING,
        reason: detailUnavailability.reason || EMPTY_STRING,
        notes: detailUnavailability.notes,
        allDay: detailUnavailability.allDay,
        lastNote: detailUnavailability.lastNote,
        noteOfCaregiver: detailUnavailability.noteOfCaregiver,
        status: detailUnavailability.status,
      };

      return {
        items: dataDetail,
      };
    } catch (error) {
      return {
        items: null,
      };
    }
  }, []);

  const handleShowDeleteUnavailable = (id?: string) => {
    if (!id) return;

    setIsShowConfirmDelete(true);
  };

  const handleDeleteUnavailable = () => {
    if (!scheduleSelected?.unavailability?.id) return;
    loading?.show();

    dispatch(deleteUnavailability(scheduleSelected?.unavailability?.id))
      .unwrap()
      .then((res) => {})
      .catch((err) => {})
      .finally(() => {
        handleCloseModalConfirmDelete();
        setScheduleSelected(undefined);
        setIsShowEventUnavailable(false);

        dispatch(scheduleActions.setRefreshOfficeSchedule(true));

        loading?.show();
      });
  };

  const handleCloseModalConfirmDelete = () => {
    setIsShowConfirmDelete(false);
  };

  const renderTabUnavailability = (data: IDetailUnavailability | null, newTab: string) => {
    if (!data) return;
    switch (newTab) {
      case TabQueryEventEnum.SUMMARY:
        return <SummaryEventTab detailUnavailability={data} onDelete={handleShowDeleteUnavailable} />;

      default:
        return <div className={cx('noDataAvailable')}>{t('common_empty_data')}</div>;
    }
  };

  //#endregion Handle Function

  return (
    <div id='openShiftsCalendarPage' className={cx('container')}>
      <ToolBar title={t('page_office_calendar_title')}>
        <SelectStatus
          options={selectAllTypesOptions}
          placeholder={t('common_select_placeholder')}
          width={204}
          height={36}
          value={typeSelected}
          onChange={handleFilterStatus}
        />
        {/* <BaseButton iconLeft={icons.commonIconSort} text={t('common_text_filter')} width={67} onClick={handleSortBy} /> */}
        <BaseButton
          typeStyle={ButtonTypeEnum.PRIMARY}
          iconLeft={icons.commonIconPlus}
          text={t('schedule_tab_button_add_schedule')}
          onClick={handleShowFormSchedule}
        />
      </ToolBar>

      <div className={cx('content')}>
        <NewMySchedule<IOfficeSchedule>
          events={events || []}
          date={currentTime}
          onTimeChange={handleTimeChange}
          childrenEvent={(event) => {
            const { task, type, unavailability } = event;
            const backgroundColor = handleBackgroundColor({ label: type, bgOpacity: 0.1 });
            const color = handleBackgroundColor({ label: type });
            const eventName =
              task?.description ||
              t('page_office_calendar_unavailability_event_label', {
                userName: getUserName(unavailability?.firstName, unavailability?.lastName),
              });

            return (
              <div
                id='eventContainer'
                className={cx('eventContainer', calendarViewMode === 'month' && 'monthEvent')}
                style={{ borderColor: color, backgroundColor: backgroundColor }}
              >
                <div className={cx('eventWrap')}>
                  <div
                    className={cx('eventName')}
                    data-tooltip-id={`taskName`}
                    data-tooltip-place='top'
                    data-tooltip-content={eventName}
                  >
                    {eventName}
                  </div>
                </div>
              </div>
            );
          }}
          onSelectEvent={handleSelectEvent}
          onCalendarViewMode={handleCalendarViewMode}
        />
      </div>

      {isShowModalUnderDevelopment && <ModalUnderDevelopment onClose={handleClickUnderDevelop} />}

      <Tooltip id={`taskName`} className={cx('tooltipWrap')} />

      {isShowEvent && (
        <FormOfficeCalendar
          scheduleId={scheduleSelected?.id}
          taskOfficeId={scheduleSelected?.task?.id}
          isOpen={isShowEvent}
          onClose={handleCloseFormSchedule}
        />
      )}

      <EventScheduleModal
        isOpen={isShowEventUnavailable}
        onClose={handleCloseEventUnavailable}
        id={scheduleSelected?.id}
        fetchData={handleGetDetailUnavailable}
        headerGroup={() => {
          return (
            <div className={cx('eventModalTitle')}>
              {t('page_office_calendar_unavailability_event_label', {
                userName: getUserName(
                  scheduleSelected?.unavailability?.firstName,
                  scheduleSelected?.unavailability?.lastName
                ),
              })}
            </div>
          );
        }}
        tab={{
          isShowTab: true,
          itemsTab: eventPopupUnavailableTabs,
          renderTab: renderTabUnavailability,
        }}
      />

      <ConfirmModal
        isOpen={isShowConfirmDelete}
        title={t('common_confirm_delete_title')}
        titleAction={t('common_delete_label')}
        onCancel={handleCloseModalConfirmDelete}
        onAction={handleDeleteUnavailable}
        type='danger'
      />
    </div>
  );
};

export default OfficeCalendar;
