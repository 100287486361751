// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import { CREATE_STAGE, GET_LIST_STAGE, UPDATE_STAGE } from '~/utils/constants/actionType';
import { IFormUpdateStage, IQueryParamsStage } from '~/utils/interface/stage';
import { IFormStage } from '~/utils/interface/stage';
import stageApi from '~/apis/stage';

export const getListStage = createAsyncThunk(
  GET_LIST_STAGE,
  async (payload: IQueryParamsStage, { rejectWithValue }) => {
    try {
      const res = await stageApi.getListStage(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createStage = createAsyncThunk(CREATE_STAGE, async (payload: IFormStage, { rejectWithValue }) => {
  try {
    const res = await stageApi.createStage(payload);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateStage = createAsyncThunk(UPDATE_STAGE, async (body: IFormUpdateStage, { rejectWithValue }) => {
  try {
    const res = await stageApi.updateStage(body);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
