// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
// Components, Layouts, Pages
import {
  BaseButton,
  CertificationsCredentialsSkill,
  DemographicsSkill,
  EmergencyContactsSkill,
  EmploymentHistorySkill,
  ProfessionalReferencesSkill,
  DisclaimersSignatures,
  EducationTraining,
  HumanResourcesRecords,
  MatchCriteriaSkill,
} from '~/components';
// Others
import { BusinessTypeSkillEnum, ButtonTypeEnum } from '~/utils/enum';
import { IFormSkill, IParamsGetDetailSkill } from '~/utils/interface/caregivers/skill';
import { initialValues, skillSchema } from '../helper';
import { useAppDispatch } from '~/redux/hooks';
import { createSkill, editSkill, getDetailSkill } from '~/thunks/caregivers/skill/skillThunk';
import { LoadingData } from '~/context';
import { useContext, useEffect, useState } from 'react';
import { removeEmptyObjects } from '~/utils/helper';
import { DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
// Styles, images, icons
import styles from './SkillTab.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const SkillTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);
  const { caregiverId, requestApplicantId } = useParams();
  const {
    control,
    handleSubmit,
    watch,
    trigger,
    setValue,
    reset,
    resetField,
    getValues,
    formState: { errors, isDirty },
  } = useForm<IFormSkill>({
    resolver: yupResolver(skillSchema(t)),
    defaultValues: initialValues,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isEditSkill, setIsEditSkill] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetDetailSkill();
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleSubmitForm = (data: IFormSkill) => {
    if (isEditSkill) {
      handleEditSkill(data);
    } else {
      handleCreateSkill(data);
    }
  };

  const handleCreateSkill = (data: IFormSkill) => {
    loading?.show();

    const convertData: IFormSkill = {
      ...data,
      businessId: caregiverId || requestApplicantId,
      businessType: caregiverId ? BusinessTypeSkillEnum.CAREGIVER : BusinessTypeSkillEnum.APPLICANT,
    };

    const newData = removeEmptyObjects(convertData);

    dispatch(createSkill(newData as IFormSkill))
      .then((res) => {
        handleGetDetailSkill();
      })
      .catch((err) => {})
      .finally(() => loading?.hide());
  };

  const handleEditSkill = (data: IFormSkill) => {
    loading?.show();

    const convertData: IFormSkill = {
      ...data,
      businessId: caregiverId || requestApplicantId,
      businessType: caregiverId ? BusinessTypeSkillEnum.CAREGIVER : BusinessTypeSkillEnum.APPLICANT,
    };

    dispatch(editSkill(convertData))
      .then((res) => {
        handleGetDetailSkill();
      })
      .catch((err) => {})
      .finally(() => loading?.hide());
  };

  const handleGetDetailSkill = () => {
    loading?.show();

    const params: IParamsGetDetailSkill = {
      businessId: caregiverId || requestApplicantId || EMPTY_STRING,
      businessType: caregiverId ? BusinessTypeSkillEnum.CAREGIVER : BusinessTypeSkillEnum.APPLICANT,
    };

    dispatch(getDetailSkill(params))
      .unwrap()
      .then((res) => {
        if (!res || res.certificationsSkill.length === DEFAULT_NUMBER_ZERO) return;
        const newData = removeEmptyObjects(res);
        setIsEditSkill(Object.keys(newData).length !== DEFAULT_NUMBER_ZERO);

        reset({ ...res });
      })
      .catch((err) => {})
      .finally(() => loading?.hide());
  };
  //#endregion Handle Function

  return (
    <FormProvider
      {...({
        control,
        watch,
        setValue,
        trigger,
        reset,
        resetField,
        getValues,
        formState: { errors },
      } as UseFormReturn<IFormSkill>)}
    >
      <form id='skillTabComponent' className={cx('container')} onSubmit={handleSubmit(handleSubmitForm)}>
        <div className={cx('formBody')}>
          <MatchCriteriaSkill />

          <DemographicsSkill />

          <EmergencyContactsSkill />

          <CertificationsCredentialsSkill />

          <EducationTraining />

          <EmploymentHistorySkill />

          <ProfessionalReferencesSkill />

          {/* <DisclaimersSignatures /> */}

          <HumanResourcesRecords />
        </div>

        <div className={cx('formActions')}>
          <BaseButton
            text={t('common_save_label')}
            typeStyle={ButtonTypeEnum.PRIMARY}
            width={80}
            type='submit'
            disabled={isEditSkill && !isDirty}
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default SkillTab;
