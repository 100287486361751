// Libs
import classNames from 'classnames/bind';
import { Controller, useFormContext, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { memo } from 'react';
// Components, Layouts, Pages
import { BaseDatePicker, BaseInput, BaseTextarea, CheckboxSingle } from '~/components';
// Others
import { IFormSkill } from '~/utils/interface/caregivers/skill';
import { EMPTY_STRING } from '~/utils/constants/common';
import { TOWER_GREY } from '~/utils/constants/color';
import { initialCertificationsCredentials } from '../helper';

// Styles, images, icons
import styles from './CertificationsCredentialsSkill.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const CertificationsCredentialsSkill = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<IFormSkill>();

  const {
    fields: certificationsCredentialsFields,
    append: appendCertificationsCredentialsFields,
    remove: removeCertificationsCredentialsFields,
  } = useFieldArray({
    control,
    name: 'certificationsSkill',
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleAddCertificationsCredentialsField = () => {
    appendCertificationsCredentialsFields({
      type: EMPTY_STRING,
      expirationDate: EMPTY_STRING,
      alertDate: EMPTY_STRING,
      notes: EMPTY_STRING,
      active: false,
      alertCaregiver: false,
    });
  };
  //#endregion Handle Function

  return (
    <div id='certificationsCredentialsSkillComponent' className={cx('certificationsCredentialsSkillContainer')}>
      <div className={cx('headTitle')}>{t('form_skill_certifications_credentials_title')}</div>

      <div className={cx('bodyWrap')}>
        {certificationsCredentialsFields.map((item, index) => {
          return (
            <div className={cx('fiveCol')} key={item.id}>
              <Controller
                control={control}
                name={`certificationsSkill.${index}.type`}
                render={({ field: { value, onChange } }) => (
                  <BaseInput
                    id={`certificationsSkill.${index}.type`}
                    label={t('form_skill_certifications_credentials_type_label')}
                    value={value || EMPTY_STRING}
                    onChange={onChange}
                    disabled={index < initialCertificationsCredentials.length}
                    width={230}
                  />
                )}
              />

              <Controller
                control={control}
                name={`certificationsSkill.${index}.expirationDate`}
                render={({ field: { value, onChange } }) => (
                  <BaseDatePicker
                    id={`certificationsSkill.${index}.expirationDate`}
                    label={t('form_skill_certifications_credentials_expiration_date_label')}
                    placeholderText={t('common_select_placeholder')}
                    value={value || EMPTY_STRING}
                    onDateSelected={onChange}
                    width={150}
                  />
                )}
              />

              <Controller
                control={control}
                name={`certificationsSkill.${index}.alertDate`}
                render={({ field: { value, onChange } }) => (
                  <BaseDatePicker
                    id={`certificationsSkill.${index}.alertDate`}
                    label={t('form_skill_certifications_credentials_alert_date_label')}
                    placeholderText={t('common_select_placeholder')}
                    value={value || EMPTY_STRING}
                    onDateSelected={onChange}
                    width={150}
                  />
                )}
              />

              <div className={cx('noteField')}>
                <Controller
                  control={control}
                  name={`certificationsSkill.${index}.notes`}
                  render={({ field: { value, onChange } }) => (
                    <BaseTextarea
                      id={`certificationsSkill.${index}.notes`}
                      label={t('form_skill_certifications_credentials_notes_label')}
                      value={value || EMPTY_STRING}
                      height={34}
                      onChange={onChange}
                    />
                  )}
                />
              </div>

              <div className={cx('group')}>
                <div className={cx('groupField')}>
                  <Controller
                    control={control}
                    name={`certificationsSkill.${index}.active`}
                    render={({ field: { value, onChange } }) => (
                      <CheckboxSingle
                        label={t('form_skill_certifications_credentials_active_label')}
                        value={value}
                        onChange={(checked: boolean) => onChange(checked)}
                        fontSize={12}
                        colorLabel={TOWER_GREY}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={`certificationsSkill.${index}.alertCaregiver`}
                    render={({ field: { value, onChange } }) => (
                      <CheckboxSingle
                        label={t('form_skill_certifications_credentials_alert_caregiver_label')}
                        value={value}
                        onChange={(checked: boolean) => onChange(checked)}
                        fontSize={12}
                        colorLabel={TOWER_GREY}
                      />
                    )}
                  />
                </div>

                {index >= initialCertificationsCredentials.length ? (
                  <button
                    type='button'
                    onClick={() => removeCertificationsCredentialsFields(index)}
                    className={cx('btnRemoveField')}
                  >
                    <img src={icons.commonIconRemoveField} alt={t('common_img_text_alt')} />
                  </button>
                ) : (
                  <div className={cx('btnRemoveField')}></div>
                )}
              </div>
            </div>
          );
        })}

        <button type='button' onClick={handleAddCertificationsCredentialsField} className={cx('btnAddField')}>
          <img src={icons.commonIconAddField} alt={t('common_img_text_alt')} />
          <span className={cx('btnTextAdd')}>{t('form_skill_certifications_credentials_add_button')}</span>
        </button>
      </div>
    </div>
  );
};

export default memo(CertificationsCredentialsSkill);
