import { urlApiInvoice } from '~/utils/constants/api';
import axiosClient from './axiosClient';
import {
  IQueryParamsInvoice,
  IInvoices,
  IFormInvoice,
  IInvoiceDetail,
  IBodyChangeStatusInvoice,
  IPayloadListInvoiceByClient,
} from '~/utils/interface/invoice';
import { BaseResponse, IListDataResponse } from '~/utils/interface/common';
import { CONTENT_TYPE_FORM_DATA } from '~/utils/constants/common';

const invoiceApi = {
  createInvoice(body: IFormInvoice) {
    const url = `${urlApiInvoice.createInvoice}`;
    return axiosClient.post<BaseResponse>(url, body);
  },

  getListInvoice(params: IQueryParamsInvoice) {
    const url = `${urlApiInvoice.getListInvoice}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IInvoices[]>>>(url, { params });
  },

  getDetailInvoice({ id, currentDate }: { id: string; currentDate: string }) {
    const url = `${urlApiInvoice.getDetailInvoice(id)}`;
    return axiosClient.get<BaseResponse<IInvoiceDetail>>(url, { params: { currentDate: currentDate } });
  },

  sendInvoice(body: FormData) {
    const url = `${urlApiInvoice.sendInvoice}`;
    return axiosClient.post<BaseResponse>(url, body, {
      headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA },
    });
  },

  changeStatus({ invoiceId, body }: { invoiceId: string; body: IBodyChangeStatusInvoice }) {
    const url = `${urlApiInvoice.changeStatusInvoice(invoiceId)}`;
    return axiosClient.put<BaseResponse>(url, body);
  },

  getListInvoiceByClient(payload: IPayloadListInvoiceByClient) {
    const { clientId, params } = payload;

    const url = `${urlApiInvoice.getListInvoiceByClient(clientId)}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IInvoices[]>>>(url, { params });
  },
};

export default invoiceApi;
