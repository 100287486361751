import { urlApiRates } from '~/utils/constants/api';
import axiosClient from './axiosClient';
import { BaseResponse, IListDataResponse } from '~/utils/interface/common';
import {
  IListQueryParamsRate,
  IListQueryPayroll,
  IRate,
  IFormRate,
  IBodyPayrollRate,
  IPayrollByUser,
  IAuthorization,
  IFormAuthorization,
  IAuthorizationDetail,
  IPayloadEditAuthorization,
} from '~/utils/interface/rate';

const rateApi = {
  getListRate(params: IListQueryParamsRate) {
    const url = `${urlApiRates.getList}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IRate[]>>>(url, { params });
  },

  getListPayroll(params: IListQueryPayroll) {
    const url = `${urlApiRates.getListPayroll}`;
    return axiosClient.get<BaseResponse<IPayrollByUser>>(url, { params });
  },

  createRate(body: IFormRate) {
    const url = `${urlApiRates.createRate}`;
    return axiosClient.post<BaseResponse>(url, body);
  },

  updatePayrollRate(body: IBodyPayrollRate) {
    const url = `${urlApiRates.updatePayrollRate}`;
    return axiosClient.put<BaseResponse>(url, body);
  },

  getListAuthorization(params: IListQueryPayroll) {
    const url = `${urlApiRates.getListAuthorization}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IAuthorization[]>>>(url, { params });
  },

  createAuthorization(body: IFormAuthorization) {
    const url = `${urlApiRates.createAuthorization}`;
    return axiosClient.post<BaseResponse>(url, body);
  },

  getDetailAuthorization(authorizationId: string) {
    const url = `${urlApiRates.getDetailAuthorization(authorizationId)}`;
    return axiosClient.get<BaseResponse<IAuthorizationDetail>>(url);
  },

  editAuthorization(payload: IPayloadEditAuthorization) {
    const { authorizationId, body } = payload;

    const url = `${urlApiRates.editAuthorization(authorizationId)}`;
    return axiosClient.put<BaseResponse>(url, body);
  },
};
export default rateApi;
