// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TFunction } from 'i18next';
import { useContext, useEffect, useState } from 'react';
// Components, Layouts, Pages
import {
  BaseButton,
  TextEditor,
  BaseInput,
  Modal,
  Loading,
  BaseInputEmailMultiple,
  BaseSelect,
  RadioCrm,
} from '~/components';
// Others
import { LoadingData } from '~/context';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { ButtonTypeEnum, ModeTextEditorEnum, TypeSendEmailEnum } from '~/utils/enum';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_LIMIT_MAX_ITEM,
  EMPTY_STRING,
  EMPTY_STRING_TEXT_EDIT,
  MODE_SEARCH,
  RegExp,
} from '~/utils/constants/common';
import { IFormCampaign, IFormCreateCampaign, IFormUpdateCampaign } from '~/utils/interface/crm/campaign';
import { IBaseOption } from '~/utils/interface/common';
import { IQueryParamsEmailTemplate } from '~/utils/interface/emailTemplate';
import { getListEmailTemplate } from '~/thunks/emailTemplate/emailThunk';
import { createCampaign, editCampaign, getDetailCampaign } from '~/thunks/crm/campaign/campaignThunk';
import { campaignActions } from '~/thunks/crm/campaign/campaignSlice';
// Styles, images, icons
import styles from './FormAddCampaign.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  campaignId?: string;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup
    .object()
    .shape({
      name: yup.string().trim().required(t('common_required_field')),
      from: yup
        .string()
        .required(t('common_required_field'))
        .email(t('common_validate_invalid_email'))
        .matches(RegExp.EMAIL, t('common_validate_invalid_email')),
      to: yup.array().when('isSendEmail', {
        is: (sendMail: string) => sendMail === TypeSendEmailEnum.YES,
        then: (schema) => schema.min(1, t('common_message_required_error')),
        otherwise: (schema) => schema.default([]).optional(),
      }),
      subject: yup
        .string()
        .transform((value) => value.trim())
        .required(t('common_required_field')),
      content: yup.string().required(t('common_required_field')),
      isSendEmail: yup.string().optional(),
    })
    .required();
};

const defaultValues: IFormCampaign = {
  name: EMPTY_STRING,
  from: EMPTY_STRING,
  subject: EMPTY_STRING,
  content: EMPTY_STRING,
  to: [],
  isSendEmail: TypeSendEmailEnum.NO,
};

const FormAddCampaignModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, onClose, campaignId } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    clearErrors,
    formState: { isDirty, errors },
  } = useForm<IFormCampaign>({
    resolver: yupResolver(schema(t)),
    defaultValues: defaultValues,
  });
  const isSendEmail = useWatch({
    control,
    name: 'isSendEmail',
  });
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  const profile = useAppSelector((state) => state.userState.userProfile);
  //#endregion Selector

  //#region Declare State
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [listTemplateEmail, setListTemplateEmail] = useState<IBaseOption[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!campaignId) return;

    handleGetDetailCampaign(campaignId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  useEffect(() => {
    if (!profile || !profile?.email) return;

    setValue('from', profile?.email);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(() => {
    handleGetListEmailTemplate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetDetailCampaign = (id: string) => {
    setIsLoading(true);

    dispatch(getDetailCampaign(id))
      .unwrap()
      .then((res) => {
        const { data } = res;

        if (!data) return;

        const newData: IFormCampaign = {
          name: data?.name,
          from: data?.from,
          subject: data?.subject,
          content: data?.content,
          to: data?.to || [],
          isSendEmail: TypeSendEmailEnum.NO,
        };

        reset(newData);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSubmitForm = (data: IFormCampaign) => {
    const newData: IFormCreateCampaign = {
      ...data,
      isSendEmail: data.isSendEmail === TypeSendEmailEnum.YES ? true : false,
    };

    if (campaignId) {
      handleEditCampaign(newData);
    } else {
      handleCreateCampaign(newData);
    }
  };

  const handleCreateCampaign = (data: IFormCreateCampaign) => {
    setIsLoading(true);

    dispatch(createCampaign(data))
      .unwrap()
      .then((res) => {
        handleCloseForm();
        dispatch(campaignActions.setRefreshList(true));
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleEditCampaign = (data: IFormCreateCampaign) => {
    if (!campaignId) return;

    setIsLoading(true);

    const dataUpdate: IFormUpdateCampaign = {
      id: campaignId,
      body: data,
    };

    dispatch(editCampaign(dataUpdate))
      .unwrap()
      .then((res) => {
        handleCloseForm();
        dispatch(campaignActions.setRefreshList(true));
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCloseForm = () => {
    onClose();
    reset(defaultValues);
  };

  const handleChangeTemplate = (value: string) => {
    setValue('content', value || EMPTY_STRING_TEXT_EDIT);
  };

  const handleGetListEmailTemplate = () => {
    const params: IQueryParamsEmailTemplate = { page: DEFAULT_CURRENT_PAGE, limit: DEFAULT_LIMIT_MAX_ITEM };

    dispatch(getListEmailTemplate(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        const listEmailTemplate = res.data?.responses?.map((data) => ({
          label: data.name || EMPTY_STRING,
          value: data.content || EMPTY_STRING,
          id: data.id,
        }));

        setListTemplateEmail(listEmailTemplate);
      })
      .catch((error) => {})
      .finally(() => loading?.hide());
  };
  //#endregion Handle Function

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCloseForm}
      title={t(campaignId ? 'form_add_campaign_edit_title' : 'form_add_campaign_title')}
    >
      <form
        id='formAddCampaignModalComponent'
        className={cx('formContainer')}
        onSubmit={handleSubmit(handleSubmitForm)}
      >
        <div className={cx('formBody')}>
          <Controller
            name='name'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='name'
                value={value}
                label={t('form_add_campaign_name_title')}
                onChange={onChange}
                required
                messageError={errors.name?.message}
              />
            )}
          />

          <Controller
            name='from'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='from'
                value={value}
                label={t('form_add_campaign_from_title')}
                onChange={onChange}
                required
                messageError={errors.from?.message}
              />
            )}
          />

          <Controller
            name='to'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInputEmailMultiple
                id='to'
                value={value}
                label={t('form_add_campaign_to_title')}
                onChange={onChange}
                errorMessage={errors.to?.message}
                minHeight={100}
                required={isSendEmail === TypeSendEmailEnum.YES}
              />
            )}
          />

          <Controller
            name='subject'
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='subject'
                value={value}
                label={t('form_add_campaign_subject_title')}
                onChange={onChange}
                required
                messageError={errors.subject?.message}
              />
            )}
          />

          <BaseSelect
            options={listTemplateEmail || []}
            mode={MODE_SEARCH}
            label={t('form_add_campaign_template_title')}
            placeholder={t('common_select_placeholder')}
            onChange={({ value }) => handleChangeTemplate(value.toString())}
          />

          <Controller
            name='content'
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextEditor
                mode={ModeTextEditorEnum.CREATE}
                height={200}
                value={value}
                label={t('form_add_campaign_content_title')}
                onChange={(textEditor: string) => onChange(textEditor)}
                required
                errorMessage={errors.content?.message}
              />
            )}
          />

          <div>
            <div className={cx('textLabel')}>{t('form_add_campaign_send_title')}</div>
            <Controller
              name='isSendEmail'
              control={control}
              render={({ field: { value, onChange } }) => (
                <div className={cx('radioGroup')}>
                  <RadioCrm
                    id={TypeSendEmailEnum.NO}
                    name='isSendEmail'
                    fontSize={12}
                    label={t('form_add_campaign_no_title')}
                    value={TypeSendEmailEnum.NO}
                    checked={value === TypeSendEmailEnum.NO}
                    onChange={(event) => {
                      errors.to?.message && clearErrors('to');
                      onChange(event.target.value);
                    }}
                  />

                  <RadioCrm
                    id={TypeSendEmailEnum.YES}
                    fontSize={12}
                    name='isSendEmail'
                    label={t('form_add_campaign_yes_title')}
                    value={TypeSendEmailEnum.YES}
                    checked={value === TypeSendEmailEnum.YES}
                    onChange={(event) => {
                      errors.to?.message && clearErrors('to');
                      onChange(event.target.value);
                    }}
                  />
                </div>
              )}
            />
          </div>
        </div>

        <div className={cx('formFooter')}>
          <BaseButton type='button' text={t('common_cancel_label')} width={65} onClick={handleCloseForm} />

          <BaseButton
            type='submit'
            text={campaignId ? t('common_update_label') : t('common_save_label')}
            typeStyle={ButtonTypeEnum.PRIMARY}
            width={80}
            disabled={campaignId ? !isDirty : false}
          />
        </div>
      </form>

      {isLoading && <Loading />}
    </Modal>
  );
};

export default FormAddCampaignModal;
