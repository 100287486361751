// Libs
import classNames from 'classnames/bind';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { memo } from 'react';

// Components, Layouts, Pages
import { BaseInput, CheckboxSingle } from '~/components';

// Others
import { useAppDispatch } from '~/redux/hooks';
import { IFormSkill } from '~/utils/interface/caregivers/skill';
import { TOWER_GREY } from '~/utils/constants/color';
import { EMPTY_STRING } from '~/utils/constants/common';

// Styles, images, icons
import styles from './EducationTraining.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const EducationTraining = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    control,
    setValue,
    resetField,
    getValues,
    formState: { errors },
  } = useFormContext<IFormSkill>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function

  //#endregion Handle Function

  return (
    <div id='educationTrainingComponent' className={cx('educationTrainingContainer')}>
      <div className={cx('headTitle')}>{t('form_skill_education_training_title')}</div>

      <div className={cx('bodyWrap')}>
        <div className={cx('twoCheckBox')}>
          <Controller
            name='educationAndTrainingSkill.isHighSchool'
            control={control}
            render={({ field: { value, onChange } }) => (
              <CheckboxSingle
                label={t('form_skill_education_training_high_school_title')}
                value={value}
                onChange={(checked: boolean, name?: string) => {
                  onChange(checked);
                }}
                fontSize={12}
                colorLabel={TOWER_GREY}
                messageError={errors?.educationAndTrainingSkill?.isHighSchool?.message}
              />
            )}
          />

          <Controller
            name='educationAndTrainingSkill.isCollege'
            control={control}
            render={({ field: { value, onChange } }) => (
              <CheckboxSingle
                label={t('form_skill_education_training_college_title')}
                value={value}
                onChange={(checked: boolean, name?: string) => {
                  onChange(checked);
                }}
                fontSize={12}
                colorLabel={TOWER_GREY}
                messageError={errors?.educationAndTrainingSkill?.isCollege?.message}
              />
            )}
          />
        </div>

        <div className={cx('twoCol')}>
          <Controller
            control={control}
            name='educationAndTrainingSkill.school'
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='educationAndTrainingSkill.school'
                value={value || EMPTY_STRING}
                label={t('form_skill_education_training_school_title')}
                onChange={onChange}
                messageError={errors?.educationAndTrainingSkill?.school?.message}
              />
            )}
          />

          <Controller
            control={control}
            name='educationAndTrainingSkill.degreeReceived'
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id='educationAndTrainingSkill.degreeReceived'
                value={value || EMPTY_STRING}
                label={t('form_skill_education_training_degree_received_title')}
                onChange={onChange}
                messageError={errors?.educationAndTrainingSkill?.degreeReceived?.message}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(EducationTraining);
