// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import {
  CREATE_REFERRALS,
  GET_LIST_CLIENT_PROSPECT_REFERRALS,
  GET_LIST_REFERRALS,
  UPDATE_REFERRALS,
} from '~/utils/constants/actionType';
import { IQueryReferrals } from '~/utils/interface/crm/referral';
import referralApi from '~/apis/crm/referral';
import { IFormUpdateReferrals } from '~/utils/interface/crm/referralSource';

export const getListReferrals = createAsyncThunk(
  GET_LIST_REFERRALS,
  async (payload: IQueryReferrals, { rejectWithValue }) => {
    try {
      const res = await referralApi.getListReferrals(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createReferrals = createAsyncThunk(CREATE_REFERRALS, async (payload: FormData, { rejectWithValue }) => {
  try {
    const res = await referralApi.createReferrals(payload);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateReferrals = createAsyncThunk(
  UPDATE_REFERRALS,
  async (payload: IFormUpdateReferrals, { rejectWithValue }) => {
    try {
      const res = await referralApi.updateReferrals(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListClientProspectByReferral = createAsyncThunk(
  GET_LIST_CLIENT_PROSPECT_REFERRALS,
  async (payload: IQueryReferrals, { rejectWithValue }) => {
    try {
      const res = await referralApi.getListClientProspectByReferral(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
