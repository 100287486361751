// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { getListCaregiverList } from '~/thunks/caregiverList/caregiverListThunk';

export interface CaregiverListState {}

const initialState: CaregiverListState = {};

const caregiverListSlice = createSlice({
  name: 'caregiverListState',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    // Get list Caregiver
    builder
      .addCase(getListCaregiverList.pending, (state) => {})
      .addCase(getListCaregiverList.fulfilled, (state) => {})
      .addCase(getListCaregiverList.rejected, (state) => {});
  },
});

// Actions
export const caregiverListActions = caregiverListSlice.actions;

// Selectors

// Reducer
const caregiverListReducer = caregiverListSlice.reducer;
export default caregiverListReducer;
