// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { TFunction } from 'i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';

// Components, Layouts, Pages
import { BaseButton, BaseInput, Loading, Modal, ModalUnderDevelopment, TextEditor } from '~/components';

// Others
import { ButtonTypeEnum, ModeTextEditorEnum } from '~/utils/enum';
import { EMPTY_STRING } from '~/utils/constants/common';
import { IEmailTemplate, IFormEmailTemplate, IPayloadUpdateEmailTemplate } from '~/utils/interface/emailTemplate';
import { useAppDispatch } from '~/redux/hooks';
import { createEmailTemplate, editEmailTemplate } from '~/thunks/emailTemplate/emailThunk';

// Styles, images, icons
import styles from './FormAddEmailTemplateModal.module.scss';

type Props = {
  emailTemplate?: IEmailTemplate | null;
  setEmailSelected: Dispatch<SetStateAction<IEmailTemplate | null>>;
  isOpen: boolean;
  onClose?: () => void;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup.object({
    name: yup.string().trim().required(t('common_required_field')),
    content: yup.string().trim().optional(),
  });
};

const defaultValues: IFormEmailTemplate = {
  name: EMPTY_STRING,
  content: EMPTY_STRING,
};

const FormAddEmailTemplateModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, onClose, emailTemplate, setEmailSelected } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<IFormEmailTemplate>({
    resolver: yupResolver(schema(t)),
    defaultValues: defaultValues,
  });
  //#endregion Declare Hook

  //#region Declare State
  const [isShowUnderDevelopment, setIsShowUnderDevelopment] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (emailTemplate) {
      reset({ name: emailTemplate.name, content: emailTemplate.content });
    }
  }, [emailTemplate]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleClose = () => {
    onClose?.();
    reset(defaultValues);
    setEmailSelected(null);
  };

  const handleSubmitTemplate = (data: IFormEmailTemplate) => {
    if (emailTemplate) {
      handleEditTemplate(data);
    } else {
      handleCreateTemplate(data);
    }
  };

  const handleCreateTemplate = (data: IFormEmailTemplate) => {
    setIsLoading(true);

    dispatch(createEmailTemplate(data))
      .unwrap()
      .then((res) => {
        handleClose();
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleEditTemplate = (data: IFormEmailTemplate) => {
    setIsLoading(true);
    const payload: IPayloadUpdateEmailTemplate = {
      emailTemplateId: emailTemplate?.id || EMPTY_STRING,
      body: data,
    };

    dispatch(editEmailTemplate(payload))
      .unwrap()
      .then((res) => {
        handleClose();
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleShowDevelopment = () => {
    setIsShowUnderDevelopment(!isShowUnderDevelopment);
  };
  //#endregion Handle Function

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={t(emailTemplate ? 'form_add_email_edit_template_title' : 'form_add_email_template_title')}
    >
      <form id='formRateModalComponent' className={cx('container')} onSubmit={handleSubmit(handleSubmitTemplate)}>
        <div className={cx('contentModal')}>
          <Controller
            name={'name'}
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseInput
                id={'name'}
                height={34}
                label={t('form_add_email_template_name_title')}
                onChange={onChange}
                value={value}
                messageError={errors.name?.message}
                required
              />
            )}
          />

          <Controller
            name='content'
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextEditor
                mode={ModeTextEditorEnum.CREATE}
                height={200}
                label={t('form_add_email_template_content_title')}
                value={value}
                onChange={(textEditor: string) => onChange(textEditor)}
              />
            )}
          />
        </div>

        <div className={cx('footerModal')}>
          <BaseButton text={t('common_cancel_label')} width={65} onClick={handleClose} type='button' />
          <BaseButton
            text={t('common_save_label')}
            typeStyle={ButtonTypeEnum.PRIMARY}
            width={80}
            type='submit'
            disabled={emailTemplate ? !isDirty : false}
          />
        </div>
      </form>

      {isLoading && <Loading />}

      {isShowUnderDevelopment && <ModalUnderDevelopment onClose={handleShowDevelopment} />}
    </Modal>
  );
};

export default FormAddEmailTemplateModal;
