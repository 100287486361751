// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

// Components, Layouts, Pages
import { BaseButton, Loading, Modal } from '~/components';

// Others
import { useAppDispatch } from '~/redux/hooks';
import { ButtonTypeEnum } from '~/utils/enum';
import { convertToClient } from '~/thunks/crm/prospect/prospectThunk';
import { clientActions } from '~/thunks/crm/clients/clientsSlice';

// Styles, images, icons
import styles from './ConvertToClientModal.module.scss';
import { icons } from '~/assets';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  prospectId?: string;
};

const cx = classNames.bind(styles);

const ConvertToClientModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, onClose, prospectId } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleClose = () => {
    onClose();
  };

  const handleSubmitForm = () => {
    setIsLoading(true);

    if (!prospectId) return;

    dispatch(convertToClient(prospectId))
      .unwrap()
      .then((res) => {
        dispatch(clientActions.setRefreshList(true));
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
        onClose();
      });
  };
  //#endregion Handle Function

  return (
    <Modal isOpen={isOpen} onClose={handleClose} width={340}>
      <div className={cx('contentModal')}>
        <div className={cx('icon')}>
          <img src={icons.crmIconUserExchangeInformation} alt={t('common_img_text_alt')} width={70} height={70} />
        </div>

        <span className={cx('text')}>{t('convert_to_client_modal_text_title')}</span>

        <div className={cx('footerButton')}>
          <BaseButton type='button' text={t('common_cancel_label')} width={135} onClick={handleClose} />

          <BaseButton
            onClick={handleSubmitForm}
            text={t('convert_to_client_modal_convert_button')}
            typeStyle={ButtonTypeEnum.PRIMARY}
            width={135}
          />
        </div>
      </div>

      {isLoading && <Loading />}
    </Modal>
  );
};

export default ConvertToClientModal;
