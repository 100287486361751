// Libs
// Others
import { urlApiUser } from '~/utils/constants/api';
import axiosClient from '~/apis/axiosClient';
import { BaseResponse, IAddAssignee, IListDataResponse, IListQueryParams } from '~/utils/interface/common';
import { IFormUpdateStatusAccount, IUserAccount, IGetUsersParams, IListUser } from '~/utils/interface/user';

const userApi = {
  getDetail(accountId: string) {
    const url = `${urlApiUser.getDetail(accountId)}`;
    return axiosClient.get<BaseResponse<IUserAccount>>(url);
  },

  getDetailProfile: () => {
    const url = `${urlApiUser.getProfile}`;
    return axiosClient.get<BaseResponse<IUserAccount>>(url);
  },

  changeStatusAccount({ clientId, body }: IFormUpdateStatusAccount) {
    const url = `${urlApiUser.changeStatusAccount(clientId)}`;
    return axiosClient.put<BaseResponse>(url, body);
  },

  getListAssignee(params: IListQueryParams) {
    const url = `${urlApiUser.getListAssignee}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IAddAssignee[]>>>(url, {
      params,
    });
  },

  deleteUser(accountId: string) {
    const url = `${urlApiUser.deleteUser(accountId)}`;
    return axiosClient.delete<BaseResponse<IUserAccount>>(url);
  },

  getListUserByRoles(params: IGetUsersParams) {
    const url = `${urlApiUser.getListUserByRoles}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IListUser[]>>>(url, { params });
  },
};

export default userApi;
