// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { pdf } from '@react-pdf/renderer';
// Components, Layouts, Pages
import {
  BaseButton,
  BaseFilter,
  BaseMoreAction,
  BasePagination,
  BaseSelect,
  BaseTable,
  FormAddInvoiceModal,
  ImageCircle,
  InputSearch,
  InvoicePdf,
  ModalChangeStatus,
  ModalUnderDevelopment,
  StatusLabel,
  ToolBar,
} from '~/components';
// Others
import { ColumnTableType, IPaginationResponse, MoreActionItem } from '~/utils/interface/common';
import {
  AccountRoleCodesEnum,
  ButtonTypeEnum,
  CurrencyEnum,
  DateFormatEnum,
  StatusEnum,
  StatusFilterEnum,
  StorageEnum,
  TimeFormatEnum,
  TranslationEnum,
} from '~/utils/enum';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_INVOICE_FILE_NAME_PDF,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  EMPTY_STRING,
  externalLinkInvoice,
  OPTIONS_STATUS_INVOICE,
  optionsStatusInvoice,
} from '~/utils/constants/common';
import { convertDateToFormatTime, formatCurrency, generateInvoiceId, getUserName } from '~/utils/helper';
import { IFilterInvoice, IInvoiceDetail, IInvoices, IQueryParamsInvoice } from '~/utils/interface/invoice';
import { LoadingData } from '~/context';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { adminRouteAbsolute, staffRouteAbsolute, superAdminRouteAbsolute } from '~/utils/constants/route';
import useDebounce from '~/utils/customHook';
import { changeStatusInvoice, getDetailInvoice, getListInvoice, sendInvoice } from '~/thunks/invoice/invoiceThunk';
// Styles, images, icons
import styles from './Invoice.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const columnsInvoice = ({
  t,
  onViewDetail,
  onSendInvoice,
  onSendPayment,
  onChangeStatus,
  onDelete,
}: {
  t: TFunction<TranslationEnum.TRANSLATION>;
  onViewDetail: (record: IInvoices) => void;
  onSendInvoice: (record: IInvoices) => void;
  onSendPayment: (record: IInvoices) => void;
  onChangeStatus: (record: IInvoices) => void;
  onDelete: (record: IInvoices) => void;
}): ColumnTableType<IInvoices>[] => {
  return [
    {
      key: 'date',
      title: t('invoice_table_date_label'),
      render(value, record, index) {
        return (
          <>{record?.createdAt ? convertDateToFormatTime(record.createdAt, TimeFormatEnum.MM_DD_YYYY) : EMPTY_STRING}</>
        );
      },
    },
    {
      key: 'id',
      title: t('invoice_table_invoice_id_label'),
      render(value, record, index) {
        return <>{record.id ? generateInvoiceId(+record.id) : EMPTY_STRING}</>;
      },
    },
    {
      key: 'client',
      title: t('invoice_table_client_label'),
      dataIndex: 'client',
      render: (_, record) => {
        return !record?.client ? (
          <>{EMPTY_STRING}</>
        ) : (
          <>
            <ImageCircle
              imageUrl={record?.client?.avatarUrl}
              firstName={record?.client?.firstName}
              lastName={record?.client?.lastName}
              width={24}
              height={24}
              fontSize={12}
              margin={'0 6px 0 0'}
            />
            <span>{getUserName(record?.client?.firstName, record?.client?.lastName)}</span>
          </>
        );
      },
    },
    {
      key: 'amount',
      title: t('invoice_table_amount_label'),
      render(value, record, index) {
        return <>{record?.total ? formatCurrency(CurrencyEnum.USD, record.total) : EMPTY_STRING}</>;
      },
    },
    {
      key: 'status',
      title: t('invoice_table_status_label'),
      render(value, record, index) {
        return (
          <div className={cx('buttonStatus')}>
            {record.status ? <StatusLabel borderRadius={100} label={record.status} bgOpacity={0.1} /> : EMPTY_STRING}
          </div>
        );
      },
    },
    {
      key: 'dueDate',
      title: t('invoice_table_due_date_label'),
      render(value, record, index) {
        return (
          <>{record?.dueDate ? convertDateToFormatTime(record.dueDate, TimeFormatEnum.MM_DD_YYYY) : EMPTY_STRING}</>
        );
      },
    },
    {
      key: 'action',
      title: t('invoice_table_actions_label'),
      render(value, record, index) {
        const actions: MoreActionItem[] = [
          {
            label: t('common_action_view'),
            icon: icons.signInIconEyeShow,
            onClick: () => onViewDetail(record),
          },
          ...(record.status !== StatusEnum.PAID
            ? [
                {
                  label: t('common_action_send_invoice'),
                  icon: icons.commonIconSend,
                  onClick: () => onSendInvoice(record),
                },
                {
                  label: t('common_action_send_payment'),
                  icon: icons.commonIconSend,
                  onClick: () => onSendPayment(record),
                },
                {
                  label: t('common_action_change_status'),
                  icon: icons.commonIconChangeStatus,
                  onClick: () => onChangeStatus(record),
                },
              ]
            : []),
          // {
          //   label: t('common_delete_label'),
          //   icon: icons.commonIconTrash,
          //   onClick: () => onDelete(record),
          //   hasOtherColor: true,
          // },
        ];
        return <BaseMoreAction actions={actions} />;
      },
      width: 50,
    },
  ];
};

const AccountingInvoice = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const statusParams = useMemo<string>(() => String(params?.status || EMPTY_STRING), [params?.status]);
  const pageSelected = useMemo<number>(() => Number(params?.page ?? DEFAULT_CURRENT_PAGE), [params?.page]);
  const role = localStorage.getItem(StorageEnum.ROLE);
  const navigate = useNavigate();
  //#endregion Declare Hook

  //#region Selector
  const { isRefreshInvoiceList } = useAppSelector((state) => state.invoiceState);
  //#endregion Selector

  //#region Declare State
  const [listInvoice, setListInvoice] = useState<IInvoices[]>([]);
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  const [pagination, setPagination] = useState<IPaginationResponse>();
  const [isShowModalAddInvoice, setIsShowModalAddInvoice] = useState<boolean>(false);
  const [invoiceSelected, setInvoiceSelected] = useState<IInvoices | null>(null);
  const [isShowChangeStatus, setIsShowChangeStatus] = useState<boolean>(false);
  const textSearchParams = useMemo<string>(() => String(params?.textSearch || EMPTY_STRING), [params?.textSearch]);
  const [searchKey, setSearchKey] = useState<string>(textSearchParams || EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey.trim() || EMPTY_STRING, DEFAULT_DELAY_TIME);
  const [isLoadingChangeStatus, setIsLoadingChangeStatus] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const { status, ...restParams } = params;

    const newParams: IQueryParamsInvoice = {
      ...restParams,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      currentDate: moment().format(DateFormatEnum.YYYY_MM_DD),
      ...(params?.status === StatusFilterEnum.ALL ? {} : { status: status }),
    };

    handleGetListInvoice(newParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!textSearchParams) setSearchKey(EMPTY_STRING);
  }, [textSearchParams]);

  useEffect(() => {
    if (!pagination) return;

    if (debouncedSearchKey) {
      setSearchParams({
        ...params,
        page: DEFAULT_CURRENT_PAGE.toString(),
        limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
        textSearch: debouncedSearchKey,
      });
    } else {
      const { textSearch, ...rest } = params;
      setSearchParams(rest);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);

  useEffect(() => {
    if (!isRefreshInvoiceList) return;

    const { status, ...restParams } = params;

    const newParams: IQueryParamsInvoice = {
      ...restParams,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      currentDate: moment().format(DateFormatEnum.YYYY_MM_DD),
      ...(debouncedSearchKey ? { textSearch: debouncedSearchKey } : {}),
      ...(params?.status === StatusFilterEnum.ALL ? {} : { status: status }),
    };

    handleGetListInvoice(newParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshInvoiceList]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListInvoice = (params: IQueryParamsInvoice) => {
    loading?.show();

    dispatch(getListInvoice(params))
      .unwrap()
      .then((res) => {
        const { pagination, responses } = res;

        setListInvoice(responses);
        setPagination(pagination);
      })
      .catch((err) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const handleViewDetailInvoice = (record: IInvoices) => {
    if (!record) return;

    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(`${superAdminRouteAbsolute.invoiceDetail}/${record.id}`);
        return;
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.invoiceDetail}/${record.id}`);
        return;
      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(`${staffRouteAbsolute.invoiceDetail}/${record.id}`);
        return;

      default:
        return;
    }
  };

  const handleSendInvoice = async (record: IInvoices) => {
    try {
      loading?.show();
      const { data } = await dispatch(
        getDetailInvoice({ id: String(record.id), currentDate: moment().format(DateFormatEnum.YYYY_MM_DD) })
      ).unwrap();

      const pdfBlob = await generatePdfBlob(data);

      if (pdfBlob) {
        const formData = new FormData();
        formData.append('files', pdfBlob, DEFAULT_INVOICE_FILE_NAME_PDF);
        formData.append('invoiceId', String(record.id));

        await dispatch(sendInvoice(formData))
          .unwrap()
          .then((res) => {})
          .catch((err) => {})
          .finally(() => loading?.hide());
      }
    } catch (error) {
    } finally {
      loading?.hide();
    }
  };

  const generatePdfBlob = async (dataPDF: IInvoiceDetail): Promise<Blob | null> => {
    try {
      return await pdf(<InvoicePdf data={dataPDF} />).toBlob();
    } catch (error) {
      return null;
    }
  };

  const handleSendPayment = (record: IInvoices) => {
    window.open(externalLinkInvoice(record?.client?.quickBookCustomerId || EMPTY_STRING));
  };

  const handleSentPayment = (record: IInvoices) => {
    if (!record.id) return;
    const payload = {
      invoiceId: record?.id,
      body: {
        status: StatusEnum.SENT_PAYMENT,
      },
    };
    dispatch(changeStatusInvoice(payload))
      .unwrap()
      .then((res) => {})
      .catch(() => {})
      .finally(() => {});
  };

  const handleRecordPayment = (record: IInvoices) => {
    if (!record.id) return;
    const payload = {
      invoiceId: record?.id,
      body: {
        status: StatusEnum.PAID,
      },
    };
    dispatch(changeStatusInvoice(payload))
      .unwrap()
      .then((res) => {})
      .catch(() => {})
      .finally(() => {});
  };

  const handleDelete = (record: IInvoices) => {
    // Handle Logic Later
    setIsShowModalUnderDevelopment(true);
  };

  const handlePaginationChange = (page: number) => {
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleAddInvoice = () => {
    setIsShowModalAddInvoice(true);
  };

  const handleCloseAddInvoice = () => {
    setIsShowModalAddInvoice(false);
  };

  const handleCloseUnderDevelopment = () => {
    setIsShowModalUnderDevelopment(false);
  };

  const handleSubmitFilter = (valueFilter: IFilterInvoice | undefined) => {
    if (!valueFilter) {
      return;
    }

    const { status, ...restParams } = params;

    setSearchParams({
      ...restParams,
      page: DEFAULT_NUMBER_ONE.toString(),
      limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
      ...(valueFilter?.status === StatusFilterEnum.ALL ? {} : { status: valueFilter?.status }),
    });
  };

  const handleClickRow = (record: IInvoices) => {
    handleViewDetailInvoice(record);
  };

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value);
  };

  const handleShowChangeStatus = (record: IInvoices) => {
    if (!record) return;

    setInvoiceSelected(record);
    setIsShowChangeStatus(true);
  };

  const handleCloseChangeStatus = () => {
    setInvoiceSelected(null);
    setIsShowChangeStatus(false);
  };

  const handleChangeStatus = (status: string) => {
    if (!invoiceSelected?.id) return;

    setIsLoadingChangeStatus(true);
    const payload = {
      invoiceId: invoiceSelected?.id,
      body: {
        status: status,
      },
    };

    dispatch(changeStatusInvoice(payload))
      .unwrap()
      .then((res) => {
        handleCloseChangeStatus();
      })
      .catch(() => {})
      .finally(() => {
        setIsLoadingChangeStatus(false);
      });
  };
  //#endregion Handle Function

  return (
    <div id='accountingInvoice' className={cx('accountingInvoice')}>
      <ToolBar title={t('menu_invoice')}>
        <InputSearch
          height={36}
          placeholder={t('invoice_search_client_name_placeholder')}
          onChange={handleChangeSearch}
          value={searchKey}
        />
        <BaseFilter<IFilterInvoice>
          defaultValue={{ status: StatusFilterEnum.ALL }}
          onApply={handleSubmitFilter}
          valueFilter={{ status: statusParams || StatusFilterEnum.ALL }}
        >
          {({ valueFilter, onChange }) => {
            return (
              <div className={cx('filterWrap')}>
                <div className={cx('contentFilter')}>
                  <span className={cx('statusLabel')}>{t('crm_filter_client_status_title')}</span>
                  <BaseSelect
                    value={valueFilter?.status}
                    options={optionsStatusInvoice}
                    onChange={(option) => {
                      onChange({
                        name: 'status',
                        value: option?.value.toString(),
                      });
                    }}
                  />
                </div>
              </div>
            );
          }}
        </BaseFilter>

        <BaseButton
          text={t('invoice_add_invoice_label')}
          typeStyle={ButtonTypeEnum.PRIMARY}
          iconLeft={icons.commonIconPlus}
          height={36}
          onClick={handleAddInvoice}
        />
      </ToolBar>

      <div className={cx('content')}>
        <div className={cx('tableWrap')}>
          <BaseTable
            columns={columnsInvoice({
              t,
              onViewDetail: handleViewDetailInvoice,
              onSendInvoice: handleSendInvoice,
              onSendPayment: handleSendPayment,
              onChangeStatus: handleShowChangeStatus,
              onDelete: handleDelete,
            })}
            dataSource={listInvoice}
            onClickRow={handleClickRow}
          />
        </div>

        <div className={cx('paginationTable')}>
          <BasePagination
            onChange={handlePaginationChange}
            defaultCurrentPage={pageSelected || DEFAULT_CURRENT_PAGE}
            totalItems={pagination?.totalItems || DEFAULT_CURRENT_PAGE}
            totalPages={pagination?.totalPages || DEFAULT_CURRENT_PAGE}
          />
        </div>
      </div>

      {isShowModalAddInvoice && <FormAddInvoiceModal isOpen={isShowModalAddInvoice} onClose={handleCloseAddInvoice} />}

      {isShowChangeStatus && (
        <ModalChangeStatus
          isOpen={isShowChangeStatus}
          dataDefault={invoiceSelected?.status || EMPTY_STRING}
          dataOption={OPTIONS_STATUS_INVOICE}
          onClose={handleCloseChangeStatus}
          onSubmit={handleChangeStatus}
          isLoading={isLoadingChangeStatus}
        />
      )}

      {isShowModalUnderDevelopment && <ModalUnderDevelopment onClose={handleCloseUnderDevelopment} />}
    </div>
  );
};

export default AccountingInvoice;
