// Libs
import { StyleSheet, Text, View } from '@react-pdf/renderer';
// Components, Layouts, Pages
// Others
import { IInvoiceDetail } from '~/utils/interface/invoice';
import { EMPTY_STRING } from '~/utils/constants/common';
import { TimeFormatEnum } from '~/utils/enum';
import { convertDateToFormatTime, formatPhoneNumber, getUserName } from '~/utils/helper';
// Styles, images, icons

type Props = {
  data: IInvoiceDetail;
};

const ContentInvoicePdf = (props: Props) => {
  //#region Destructuring Props
  const { data } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const getBillingPeriod = () => {
    if (data.startDate && !data.endDate) {
      return convertDateToFormatTime(data.startDate, TimeFormatEnum.MM_DD_YYYY);
    }

    if (data.endDate && !data.startDate) {
      return convertDateToFormatTime(data.endDate, TimeFormatEnum.MM_DD_YYYY);
    }

    if (data.startDate && data.endDate) {
      return `${convertDateToFormatTime(data.startDate, TimeFormatEnum.MM_DD_YYYY)} - ${convertDateToFormatTime(
        data.endDate,
        TimeFormatEnum.MM_DD_YYYY
      )}`;
    }
  };
  //#endregion Handle Function

  return (
    <View style={styles.mainInvoice}>
      <View style={{ fontSize: 14 }}>
        <Text>INVOICE TO</Text>
      </View>

      <View style={styles.row}>
        <View style={{ flexDirection: 'column', gap: 20, flex: 0.5 }}>
          <View style={styles.column}>
            <Text>
              Client: <Text style={styles.value}>{getUserName(data.client?.firstName, data.client?.lastName)}</Text>
            </Text>
          </View>

          <View style={styles.column}>
            <Text>
              Phone:{' '}
              <Text style={styles.value}>
                {data.client?.mobilePhone ? formatPhoneNumber(data.client?.mobilePhone) : EMPTY_STRING}
              </Text>
            </Text>
          </View>

          <View style={styles.column}>
            <Text style={{ flex: 1 }}>
              Address: <Text style={styles.value}>{data.client?.address}</Text>
            </Text>
          </View>
        </View>

        <View style={{ flexDirection: 'column', gap: 20, flex: 0.5 }}>
          <View style={styles.column}>
            <Text>
              Created Date:{' '}
              <Text style={styles.value}>
                {data.createdAt ? convertDateToFormatTime(data.createdAt, TimeFormatEnum.MM_DD_YYYY) : EMPTY_STRING}
              </Text>
            </Text>
          </View>

          <View style={styles.column}>
            <Text>
              Billing Period: <Text style={styles.value}>{getBillingPeriod()}</Text>
            </Text>
          </View>

          <View style={styles.column}>
            <Text>
              Due Date:{' '}
              <Text style={styles.value}>
                {data.dueDate ? convertDateToFormatTime(data.dueDate, TimeFormatEnum.MM_DD_YYYY) : EMPTY_STRING}
              </Text>
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default ContentInvoicePdf;

export const styles = StyleSheet.create({
  mainInvoice: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },

  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 20,
    width: '100%',
  },

  column: {
    flex: 1,
    flexDirection: 'row',
  },

  value: {
    color: '#577970',
  },
});
