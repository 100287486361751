import { urlApiBackgroundReports } from '~/utils/constants/api';
import {
  IBackgroundReport,
  IFormAddEmploymentScreening,
  IPayloadUpdateEmploymentScreening,
  IQueryGetListBackgroundReport,
} from '~/utils/interface/backgroundReports';
import { BaseResponse, IListDataResponse } from '~/utils/interface/common';
import axiosClient from './axiosClient';

const backgroundReportsApi = {
  createBackgroundReports(body: IFormAddEmploymentScreening) {
    const url = `${urlApiBackgroundReports.createBackgroundReports}`;
    return axiosClient.post<BaseResponse>(url, body);
  },

  getListBackgroundReport(params: IQueryGetListBackgroundReport) {
    const url = `${urlApiBackgroundReports.getListBackgroundReport}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IBackgroundReport[]>>>(url, { params });
  },

  editBackgroundReportsApi({ backgroundReportId, body }: IPayloadUpdateEmploymentScreening) {
    const url = `${urlApiBackgroundReports.editBackgroundReports(backgroundReportId)}`;
    return axiosClient.put<BaseResponse>(url, body);
  },

  deleteBackgroundReports(backgroundReportId: string) {
    const url = `${urlApiBackgroundReports.deleteBackgroundReports(backgroundReportId)}`;
    return axiosClient.delete<BaseResponse>(url);
  },
};
export default backgroundReportsApi;
