// Libs
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate, useParams } from 'react-router-dom';
// Components, Layouts, Pages
import { BaseButton, LabelValueField, StatusLabel, ToolBar } from '~/components';
// Others
import { useAppDispatch } from '~/redux/hooks';
import { COMMA_SEPARATOR, DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
import { convertDateToFormatTime, convertEnumToString, convertTime, getUserName } from '~/utils/helper';
import { AccountRoleCodesEnum, ButtonTypeEnum, HrStatusEnum, StorageEnum, TimeFormatEnum } from '~/utils/enum';
import { IGetDetailUnavailability, IUnavailabilityNote } from '~/utils/interface/hr';
import { LoadingData } from '~/context';
import { getDetailUnavailability } from '~/thunks/hr/hrThunk';
// Styles, images, icons
import styles from './HrDetail.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const HrDetail = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const role = localStorage.getItem(StorageEnum.ROLE);
  const navigate = useNavigate();
  const loading = useContext(LoadingData);
  const { hrId } = useParams();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [hrDetail, setHrDetail] = useState<IGetDetailUnavailability>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!hrId) return;

    handleGetDetailHr(hrId);
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleBack = () => {
    switch (role) {
      case AccountRoleCodesEnum.SUPER_ADMIN:
        navigate(-1);
        break;
      case AccountRoleCodesEnum.ADMIN:
        navigate(-1);
        break;

      case AccountRoleCodesEnum.EMPLOYEE:
        navigate(-1);
        break;
    }
  };

  const handleGetDetailHr = (id: string) => {
    loading?.show();

    dispatch(getDetailUnavailability(id))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        setHrDetail(res.data);
      })
      .catch((err) => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const renderNoteDetails = (note: IUnavailabilityNote) => {
    return (
      <>
        <span className={cx('label')} />
        <span>
          "{note.description || EMPTY_STRING}"{COMMA_SEPARATOR}
          <span className={cx('noteContent')}>
            {` ${t('tab_notes_modal_created_by_label')} ${getUserName(
              note.firstName,
              note.lastName
            )}${COMMA_SEPARATOR} ${
              note?.createdAt
                ? convertDateToFormatTime(note.createdAt, TimeFormatEnum.MM_DD_YYYY_HH_AM_PM)
                : EMPTY_STRING
            }`}
          </span>
        </span>
      </>
    );
  };
  //#endregion Handle Function

  return (
    <div id='hrDetailComponent' className={cx('hrDetailComponent')}>
      <BaseButton
        onClick={handleBack}
        text={t('common_text_back')}
        typeStyle={ButtonTypeEnum.ONLY_TEXT}
        iconLeft={icons.commonIconPrev}
        height={17}
      />

      <div className={cx('headerToolBar')}>
        <ToolBar title={t('detail_hr_title')}></ToolBar>
      </div>

      <div className={cx('bodyWarp')}>
        <div className={cx('infoDetailsWrap')}>
          <LabelValueField
            label={t('detail_hr_create_date_title')}
            value={hrDetail?.createdAt ? convertTime(hrDetail?.createdAt, TimeFormatEnum.MM_DD_YYYY) : EMPTY_STRING}
          />
          <LabelValueField
            label={t('detail_hr_name_title')}
            value={
              hrDetail?.employee
                ? getUserName(hrDetail.employee?.firstName, hrDetail.employee?.middleName, hrDetail.employee?.lastName)
                : getUserName(
                    hrDetail?.caregiver?.firstName,
                    hrDetail?.caregiver?.middleName,
                    hrDetail?.caregiver?.lastName
                  )
            }
          />

          <LabelValueField
            label={t('detail_hr_start_date_title')}
            value={
              hrDetail?.startDate && hrDetail?.startTime
                ? `${convertTime(
                    new Date(`${hrDetail.startDate} ${hrDetail.startTime}`),
                    TimeFormatEnum.MM_DD_YYYY_HH_AM_PM
                  )}`
                : EMPTY_STRING
            }
          />

          <LabelValueField
            label={t('detail_hr_end_date_title')}
            value={
              hrDetail?.endDate && hrDetail?.endTime
                ? `${convertTime(
                    new Date(`${hrDetail.endDate} ${hrDetail.endTime}`),
                    TimeFormatEnum.MM_DD_YYYY_HH_AM_PM
                  )} `
                : EMPTY_STRING
            }
          />

          <LabelValueField label={t('detail_hr_reason_title')} value={convertEnumToString(hrDetail?.reason)} />

          <LabelValueField
            label={t('detail_hr_status_title')}
            value={
              hrDetail?.status ? (
                <StatusLabel
                  bgOpacity={0.1}
                  label={hrDetail?.status === HrStatusEnum.DENIED ? HrStatusEnum.REJECTED : hrDetail?.status}
                />
              ) : (
                EMPTY_STRING
              )
            }
          />

          <LabelValueField
            label={t('detail_hr_note_title')}
            value={
              hrDetail &&
              hrDetail.notes?.length > DEFAULT_NUMBER_ZERO &&
              hrDetail?.notes?.map((note, index) => (
                <div key={index} className={cx('noteItem')}>
                  {renderNoteDetails(note)}
                </div>
              ))
            }
          />
        </div>
      </div>
    </div>
  );
};

export default HrDetail;
