// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { TFunction } from 'i18next';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Components, Layouts, Pages
import {
  BaseButton,
  BaseFilter,
  BasePagination,
  BaseSelect,
  BaseTable,
  FormAddStageModal,
  InputSearch,
  ModalUnderDevelopment,
  ThreeDotTableOptions,
  ToolBar,
} from '~/components';

// Others
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_LIMIT_PAGE,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  EMPTY_STRING,
  OPTIONS_TYPE_STAGE,
} from '~/utils/constants/common';
import { AccountRoleCodesEnum, ButtonTypeEnum, StorageEnum, TranslationEnum, TypeStageFilterEnum } from '~/utils/enum';
import { ColumnTableType, IListQueryParams, IPaginationResponse } from '~/utils/interface/common';
import { convertEnumToString } from '~/utils/helper';
import { IFilterStage, IListStage } from '~/utils/interface/stage';
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { getListStage } from '~/thunks/stage/stageThunk';
import useDebounce from '~/utils/customHook';
import { selectIsRefreshStageList, stageActions } from '~/thunks/stage/stageSlice';

// Styles, images, icons
import styles from './StageTab.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction<TranslationEnum.TRANSLATION>,
  handleEditStage: (item: IListStage) => void,
  handleDeleteStage: (id: string) => void,
  role: string | null
): ColumnTableType<IListStage>[] => {
  return [
    {
      key: 'name',
      title: t('stage_tab_name_title'),
      dataIndex: 'name',
      render(value, record, index) {
        return <>{record.name ? record.name : EMPTY_STRING}</>;
      },
      width: '50%',
    },
    {
      key: 'type',
      title: t('stage_tab_type_title'),
      dataIndex: 'type',
      render(value, record, index) {
        return <>{record.type ? convertEnumToString(record.type) : EMPTY_STRING}</>;
      },
      width: '40%',
    },
    ...(role === AccountRoleCodesEnum.SUPER_ADMIN
      ? [
          {
            key: 'action',
            title: t('common_text_action'),
            render: (value: string | undefined, record: IListStage, index: number) => {
              return (
                <ThreeDotTableOptions
                  data={record}
                  permissions={{
                    isView: false,
                    isDelete: false,
                  }}
                  onEdit={() => handleEditStage(record)}
                  // onDelete={() => handleDeleteStage(record.id)}
                />
              );
            },
            width: '10%',
          },
        ]
      : []),
  ];
};

const StageTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const role = localStorage.getItem(StorageEnum.ROLE);
  //#endregion Declare Hook

  //#region Selector
  const isRefreshStageList = useSelector(selectIsRefreshStageList);
  //#endregion Selector

  //#region Declare State
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pagination, setPagination] = useState<IPaginationResponse>();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(() => Number(params?.page ?? DEFAULT_CURRENT_PAGE), [params?.page]);
  const [isShowFormAddState, setIsShowFormAddState] = useState<boolean>(false);
  const [stageList, setStageList] = useState<IListStage[]>([]);
  const [stageSelected, setStageSelected] = useState<IListStage | null>(null);
  const typeParams = useMemo<string>(() => String(params?.type || EMPTY_STRING), [params?.type]);
  const textSearchParams = useMemo<string>(() => String(params?.textSearch || EMPTY_STRING), [params?.textSearch]);
  const [searchKey, setSearchKey] = useState<string>(textSearchParams || EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey.trim() || EMPTY_STRING, DEFAULT_DELAY_TIME);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const { tab, type, ...restParams } = params;

    const newParams = {
      ...restParams,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      ...(params?.type === TypeStageFilterEnum.ALL ? {} : { type: type }),
    };

    handleGetListStage(newParams);
  }, [params]);

  useEffect(() => {
    if (!textSearchParams) setSearchKey(EMPTY_STRING);
  }, [textSearchParams]);

  useEffect(() => {
    if (!pagination) return;

    if (debouncedSearchKey) {
      setSearchParams({
        ...params,
        page: DEFAULT_CURRENT_PAGE.toString(),
        limit: DEFAULT_LIMIT_PAGE.toString(),
        textSearch: debouncedSearchKey,
      });
    } else {
      const { textSearch, ...rest } = params;
      setSearchParams(rest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);

  useEffect(() => {
    if (!isRefreshStageList) return;

    const { tab, type, ...restParams } = params;

    const newParams = {
      ...restParams,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      ...(debouncedSearchKey ? { textSearch: debouncedSearchKey } : {}),
      ...(params?.type === TypeStageFilterEnum.ALL ? {} : { type: type }),
    };

    handleGetListStage(newParams);
    dispatch(stageActions.setRefreshList(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshStageList]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleClickUnderDevelop = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value);
  };

  const handlePaginationChange = (page: number) => {
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleAddStage = () => {
    setIsShowFormAddState(true);
  };

  const handleCloseAddStage = () => {
    setIsShowFormAddState(false);
  };

  const handleEditStage = (item: IListStage) => {
    if (role === AccountRoleCodesEnum.SUPER_ADMIN) {
      setIsShowFormAddState(true);
      setStageSelected(item);
    }
  };

  const handleDeleteState = (id: string) => {
    setIsShowModalUnderDevelopment(true);
  };

  const handleSubmitFilter = (valueFilter: IFilterStage | undefined) => {
    if (!valueFilter) {
      return;
    }

    const { type, ...restParams } = params;

    setSearchParams({
      ...restParams,
      page: DEFAULT_NUMBER_ONE.toString(),
      limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
      ...(valueFilter?.type === TypeStageFilterEnum.ALL ? {} : { type: valueFilter?.type }),
    });
  };

  const handleGetListStage = (params: IListQueryParams) => {
    loading?.show();

    dispatch(getListStage(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        const { responses, pagination } = res?.data;

        setStageList(responses);
        setPagination(pagination);
      })
      .catch((error) => {})
      .finally(() => loading?.hide());
  };

  const handleOnClickRow = (item: IListStage) => {
    if (role === AccountRoleCodesEnum.SUPER_ADMIN) {
      setIsShowFormAddState(true);
      setStageSelected(item);
    }
  };
  //#endregion Handle Function

  return (
    <div id='stageTabComponent' className={cx('container')}>
      <ToolBar title={t('stage_tab_title')}>
        <InputSearch
          placeholder={t('stage_tab_name_placeholder')}
          width={204}
          onChange={handleChangeSearch}
          value={searchKey}
        />

        <BaseFilter<IFilterStage>
          defaultValue={{
            type: TypeStageFilterEnum.ALL,
          }}
          onApply={handleSubmitFilter}
          valueFilter={{
            type: typeParams || TypeStageFilterEnum.ALL,
          }}
        >
          {({ valueFilter, onChange }) => {
            return (
              <div className={cx('filterWrap')}>
                <div className={cx('contentFilter')}>
                  <span className={cx('statusLabel')}>{t('crm_filter_referral_source_type_title')}</span>
                  <BaseSelect
                    width={175}
                    value={valueFilter?.type}
                    options={OPTIONS_TYPE_STAGE}
                    onChange={(option) => {
                      onChange({
                        name: 'type',
                        value: option?.value.toString(),
                      });
                    }}
                  />
                </div>
              </div>
            );
          }}
        </BaseFilter>

        {role === AccountRoleCodesEnum.SUPER_ADMIN && (
          <BaseButton
            typeStyle={ButtonTypeEnum.PRIMARY}
            iconLeft={icons.commonIconPlus}
            text={t('stage_tab_add_stage_title')}
            onClick={handleAddStage}
          />
        )}
      </ToolBar>

      <div className={cx('content')}>
        <div className={cx('tableWrap')}>
          <BaseTable
            columns={columns(t, handleEditStage, handleDeleteState, role)}
            dataSource={stageList ?? []}
            onClickRow={role === AccountRoleCodesEnum.SUPER_ADMIN ? handleOnClickRow : undefined}
          />
        </div>

        <div className={cx('paginationTable')}>
          <BasePagination
            onChange={handlePaginationChange}
            defaultCurrentPage={pageSelected}
            totalItems={pagination?.totalItems}
            totalPages={pagination?.totalPages}
          />
        </div>
      </div>

      {isShowFormAddState && (
        <FormAddStageModal
          isOpen={isShowFormAddState}
          stageSelected={stageSelected}
          setStageSelected={setStageSelected}
          onClose={handleCloseAddStage}
        />
      )}

      {isShowModalUnderDevelopment && <ModalUnderDevelopment onClose={handleClickUnderDevelop} />}
    </div>
  );
};

export default StageTab;
