// common
import commonIconNext from '~/assets/icons/common_icon_next.svg';
import commonIconPrev from '~/assets/icons/common_icon_prev.svg';
import commonIconSort from '~/assets/icons/common_icon_sort.svg';
import commonIconPlus from '~/assets/icons/common_icon_plus.svg';
import commonIconSearch from '~/assets/icons/common_icon_search.svg';
import commonIconSearchService from '~/assets/icons/common_icon_search_service.svg';
import commonNotification from '~/assets/icons/common_notification.svg';
import commonMessage from '~/assets/icons/common_message.svg';
import commonIconThreeDotVertical from '~/assets/icons/common_icon_three_dot_vertical.svg';
import commonIconClock from '~/assets/icons/common_icon_clock.svg';
import commonIconCalculator from '~/assets/icons/common_icon_calculator.svg';
import commonIconEdit from '~/assets/icons/common_icon_edit.svg';
import commonIconTrash from '~/assets/icons/common_icon_trash.svg';
import commonIconCall from '~/assets/icons/common_icon_call.svg';
import commonIconDropdownBorder from '~/assets/icons/common_icon_dropdown_border.svg';
import commonIconThreeDotHorizontal from '~/assets/icons/common_icon_three_dot_horizontal.svg';
import commonIconArrowBottom from '~/assets/icons/common_icon_arrow_bottom.svg';
import commonIconShowPassword from '~/assets/icons/common_icon_show_password.svg';
import commonIconHiddenPassword from '~/assets/icons/common_icon_hidden_password.svg';
import commonIconModalClose from '~/assets/icons/common_icon_modal_close.svg';
import commonIconStar from '~/assets/icons/common_icon_star.svg';
import commonIconLineArrowTop from '~/assets/icons/common_icon_line_arrow_top.svg';
import commonIconLineArrowBottom from '~/assets/icons/common_icon_line_arrow_bottom.svg';
import commonIconUpload from '~/assets/icons/common_icon_upload.svg';
import commonIconPaginationChevron from './common_icon_pagination_chevron.svg';
import commonIconMessageStatus from './common_icon_message_status.svg';
import commonIconSendMessage from './common_icon_send_message.svg';
import commonIconLock from './common_icon_lock.svg';
import commonIconLogout from './common_icon_logout.svg';
import commonIconArrowBottomWhite from './common_icon_arrow_bottom_white.svg';
import commonIconArrowBottomBlack from './common_icon_arrow_bottom_black.svg';
import commonIconArrowTopWhite from './common_icon_arrow_top_white.svg';
import commonIconPdfFile from './common_icon_pdf_file.svg';
import commonIconDocsFile from './common_icon_docs_file.svg';
import commonIconSharedFolder from './common_icon_shared_folder.svg';
import commonIconCertificationsFile from './common_icon_certification.svg';
import commonIconFingerprints from './common_icon_fingerprints.svg';
import commonIconDeactivate from './common_icon_deactivate.svg';
import commonIconFolder from './common_icon_folder.svg';
import commonIconGallery from './common_icon_gallery.svg';
import commonIconDownload from './common_icon_download.svg';
import commonIconCalendar from './common_icon_calendar.svg';
import commonIconView from './common_icon_view.svg';
import commonIconBreak from './common_icon_break.svg';
import commonIconAdd from './common_icon_add.svg';
import commonIconTimePicker from '~/assets/icons/common_icon_time_picker.svg';
import commonIconTick from './common_icon_tick.svg';
import commonIconModalDelete from '~/assets/icons/common_icon_modal_delete.svg';
import commonIconEmailGray from '~/assets/icons/common_icon_email_gray.svg';
import commonIconEmailActivities from '~/assets/icons/common_icon_email_activities.svg';
import commonIconPhoneGray from '~/assets/icons/common_icon_phone_gray.svg';
import commonIconDocumentGray from '~/assets/icons/common_icon_document_gray.svg';
import commonIconMap from '~/assets/icons/common_icon_map.svg';
import commonIconPrint from '~/assets/icons/common_icon_print.svg';
import commonIconArrowMenu from '~/assets/icons/common_icon_arrow_menu.svg';
import commonIconNote from './common_icon_note.svg';
import commonIconDocsActivities from './common_icon_docs_activities.svg';
import commonIconNewMessage from '~/assets/icons/common_icon_new_message.svg';
import commonIconVideoDefault from '~/assets/icons/common_icon_video_default.svg';
import commonIconNewMessageGray from '~/assets/icons/common_icon_new_message_gray.svg';
import commonIconSettings from '~/assets/icons/common_icon_settings.svg';
import commonIconConfirmTerminated from '~/assets/icons/common_icon_confirm_terminated.svg';
import commonIconConfirmDelete from '~/assets/icons/common_icon_confirm_delete.svg';
import commonIconRemoveField from '~/assets/icons/common_icon_remove_field.svg';
import commonIconAddField from '~/assets/icons/common_icon_add_field.svg';
import commonIconVideo from '~/assets/icons/common_icon_video.svg';
import commonIconVideoGray from '~/assets/icons/common_icon_video_gray.svg';
import commonIconChangeStatus from '~/assets/icons/common_icon_change_status.svg';
import commonIconAssignToContact from '~/assets/icons/common_icon_assign_to_contact.svg';
import commonIconPaymentRecord from '~/assets/icons/common_icon_payment_record.svg';
import commonIconNoteActivity from '~/assets/icons/common_icon_note_activity.svg';
import commonIconWarning from './common_icon_warning.svg';
import commonIconSchedule from './common_icon_schedule.svg';
import commonIconCalendarRepo from './common_icon_calendar_repo.svg';

// Input Message
import inputMessageIconAdd from './input_message_icon_add.svg';
import inputMessageIconVoiceChat from './input_message_icon_voice_chat.svg';

// Crm
import crmIconPlus from './crm_icon_plus.svg';
import crmIconSendMessage from './crm_icon_send_message.svg';
import crmIconDeactivate from './crm_icon_deactivate.svg';
import crmIconCall from '~/assets/icons/crm_icon_call.svg';
import crmIconSms from '~/assets/icons/crm_icon_sms.svg';
import crmIconSendEmail from '~/assets/icons/crm_icon_send_email.svg';
import crmIconUserExchangeInformation from '~/assets/icons/crm_icon_user_exchange_information.svg';

// Task
import taskIconPdfFile from './task_icon_pdf_file.svg';

// Sign In
import signInIconEmail from './sign_in_icon_email.svg';
import signInIconPassword from './sign_in_icon_password.svg';
import signInIconEyeHide from './sign_in_icon_eye_hide.svg';
import signInIconEyeShow from './sign_in_icon_eye_show.svg';
import signInIconCheckboxCheck from './sign_in_icon_checkbox_check.svg';
import signInIconCheckboxUncheck from './sign_in_icon_checkbox_uncheck.svg';

// Ring Central
import ringCentralIconConnect from './ring_central_icon_connect.svg';
import ringCentralIconConnecting from './ring_central_icon_connecting.svg';
import ringCentralIconWarning from './ring_central_icon_warning.svg';
import ringCentralIconDisconnect from './ring_central_icon_disconnect.svg';

// Integration
import integrationIconIntuitQuickBooksLogo from './integration_icon_intuit_quickbooks_logo.svg';
import integrationIconRingCentralLogo from './integration_icon_ring_central_logo.svg';

// Actions
import commonIconSend from './actions/common_icon_send.svg';

const icons = {
  // common
  commonIconNext,
  commonIconPrev,
  commonIconSort,
  commonIconPlus,
  commonIconSearch,
  commonIconSearchService,
  commonNotification,
  commonMessage,
  commonIconThreeDotVertical,
  commonIconClock,
  commonIconCalculator,
  commonIconEdit,
  commonIconTrash,
  commonIconCall,
  commonIconDropdownBorder,
  commonIconThreeDotHorizontal,
  commonIconArrowBottom,
  commonIconShowPassword,
  commonIconHiddenPassword,
  commonIconModalClose,
  commonIconStar,
  commonIconLineArrowTop,
  commonIconLineArrowBottom,
  commonIconUpload,
  commonIconPaginationChevron,
  commonIconMessageStatus,
  commonIconSendMessage,
  commonIconLock,
  commonIconLogout,
  commonIconArrowBottomWhite,
  commonIconArrowTopWhite,
  commonIconArrowBottomBlack,
  commonIconPdfFile,
  commonIconDocsFile,
  commonIconSharedFolder,
  commonIconCertificationsFile,
  commonIconFingerprints,
  commonIconDeactivate,
  commonIconFolder,
  commonIconGallery,
  commonIconDownload,
  commonIconCalendar,
  commonIconView,
  commonIconBreak,
  commonIconAdd,
  commonIconEmailGray,
  commonIconPhoneGray,
  commonIconDocumentGray,
  commonIconMap,
  commonIconPrint,
  commonIconArrowMenu,
  commonIconNote,
  commonIconEmailActivities,
  commonIconDocsActivities,
  commonIconNewMessage,
  commonIconVideoDefault,
  commonIconNewMessageGray,
  commonIconSettings,
  commonIconConfirmTerminated,
  commonIconConfirmDelete,
  commonIconRemoveField,
  commonIconAddField,
  commonIconVideo,
  commonIconVideoGray,
  commonIconChangeStatus,
  commonIconAssignToContact,
  commonIconPaymentRecord,
  commonIconNoteActivity,
  commonIconWarning,
  commonIconSchedule,
  commonIconCalendarRepo,

  // Task
  taskIconPdfFile,
  commonIconTimePicker,
  commonIconTick,
  commonIconModalDelete,

  // Sign In
  signInIconEmail,
  signInIconPassword,
  signInIconEyeHide,
  signInIconEyeShow,
  signInIconCheckboxCheck,
  signInIconCheckboxUncheck,

  // Input Message
  inputMessageIconAdd,
  inputMessageIconVoiceChat,

  // Crm
  crmIconPlus,
  crmIconSendMessage,
  crmIconDeactivate,
  crmIconCall,
  crmIconSms,
  crmIconSendEmail,
  crmIconUserExchangeInformation,

  // Ring Central
  ringCentralIconConnect,
  ringCentralIconConnecting,
  ringCentralIconWarning,
  ringCentralIconDisconnect,

  // Integration
  integrationIconIntuitQuickBooksLogo,
  integrationIconRingCentralLogo,

  // Actions
  commonIconSend,
};

export default icons;
