// Apis
export const urlApiAuth = {
  login: 'auth/login',
  refreshToken: 'auth/refresh-token',
  forgotPassword: 'auth/forgot-password',
  newPassword: 'auth/new-password',
  changePassword: 'users/change-password',
  verifyCode: 'auth/verify-code',
};

export const urlApiEmployee = {
  getList: 'employees',
  createEmployee: 'employees/new',
  updateEmployee: (employeeId: string) => `employees/${employeeId}`,
};

export const urlApiCaregiver = {
  getList: 'caregivers',
  createCaregiver: 'caregivers/new',
  editCaregiver: (id: string) => `caregivers/${id}`,
  getDetail: (id: string) => `caregivers/${id}`,
  deleteCaregiver: (accountId: string) => `caregivers/${accountId}`,
  getProfileTags: 'master-data',
  getCaregiversByLocation: 'caregivers/by-location',
  createCaregiverReferral: 'caregivers/new',
  sendEmail: `emails/send`,
};

export const urlApiUser = {
  getDetail: (accountId: string) => `users/${accountId}`,
  getProfile: 'users/profile',
  changeStatusAccount: (accountId: string) => `users/${accountId}/change-status`,
  getListAssignee: 'users/assignee',
  deleteUser: (accountId: string) => `users/${accountId}`,
  getListUserByRoles: `users/by-roles`,
};

export const urlApiApplicant = {
  getList: 'applicants',
  createApplicant: 'applicants/new',
  convertCaregiver: (applicantId: string) => `applicants/${applicantId}/convert`,
  changeStage: (applicantId: string) => `applicants/${applicantId}/change-stage`,
  getDetailApplicant: (applicantId: string) => `applicants/${applicantId}`,
  editApplicant: (applicantId: string) => `applicants/${applicantId}`,
  changeStatus: (applicantId: string) => `applicants/${applicantId}/change-status`,
};

export const urlApiConversation = {
  getList: 'conversations',
  create: 'conversations/new',
  getDetail: (conversationId: string) => `conversations/${conversationId}/messages`,
  createMessage: (conversationId: string) => `conversations/${conversationId}/messages/new`,
};

export const urlApiCareAlert = {
  getList: 'care-alerts',
};

export const urlApiCrm = {
  getList: 'clients',
  createClient: 'clients/new',
  changeStatusClient: (clientId: string) => `clients/${clientId}/change-status`,
  changeStageProspect: (clientId: string) => `clients/${clientId}/change-stage`,
  getDetailClient: (clientId: string) => `clients/${clientId}`,
  updateClient: (clientId: string) => `clients/${clientId}`,
  uploadFileClients: 'file/documents/new',
  getListRelates: 'clients/options/relates',
  getListActivityByClient: (clientId: string) => `activities/client/${clientId}`,
  sendEmailToClient: (clientId: string) => `clients/${clientId}/send-email`,
  getDetailCarePlan: (clientId: string) => `clients/${clientId}/care-plan`,
  createCarePlan: (clientId: string) => `clients/${clientId}/care-plan`,
  convertToClient: (prospectId: string) => `clients/${prospectId}/convert`,
  getListClientByContact: (contactId: string) => `clients/contacts/${contactId}`,
  getListClientByLocations: 'clients/by-locations',
  sendToQB: (clientId: string) => `clients/${clientId}/send-to-quick-books`,
};

export const urlApiReferral = {
  getListReferrals: 'referrals',
  createReferrals: 'referrals/new',
  updateReferrals: (caregiverReferralId: string) => `referrals/${caregiverReferralId}`,
  getDetailReferrals: (referralId: string) => `referrals/${referralId}`,
  getListCaregiverApplicantReferred: (referrerId: string) => `/referrals/${referrerId}/caregivers-applicants`,
  changeStatusReferrals: (referrerId: string) => `/referrals/${referrerId}/change-status`,
};

export const urlApiSchedule = {
  createSchedule: 'schedules/new',
  getListShiftSchedule: 'schedules',
  getListSchedule: 'schedules',
  getScheduleClient: 'schedules/client',
  getScheduleCaregiver: 'schedules/caregiver',
  getOfficeSchedule: 'schedules/calendars/office',
  getSchedulesLiveView: '/schedules/live-view',
  deleteSchedule: (scheduleId: string) => `schedules/${scheduleId}`,
  getDetailSchedule: (scheduleId: string) => `schedules/${scheduleId}`,
  updateSchedule: (scheduleId: string) => `schedules/${scheduleId}`,
  createShiftSchedule: '/shifts/new',
  changeStatusShiftSchedule: (shiftId: string) => `/shifts/${shiftId}/change-status`,
  getDetailShiftSchedule: (shiftId: string) => `/shifts/${shiftId}`,
  getListNoteSchedule: '/shifts/note',
  createShiftNote: '/shifts/note/new',
  getDetailUnavailability: (id: string) => `/unavailabilities/${id}`,
  createUnavailabilityNote: '/unavailabilities/note/new',
  updateShiftSchedule: (shiftId: string) => `/shifts/${shiftId}`,
  deleteShift: (shiftId: string) => `/shifts/${shiftId}`,
  getOpenShifts: '/schedules/open-shifts',
  createOfficeCalendar: 'tasks/new',
  getOfficeCalendarDetail: (scheduleId: string) => `tasks/${scheduleId}`,
  updateOfficeCalendar: (scheduleId: string) => `tasks/${scheduleId}`,
  deleteOfficeCalendar: (scheduleId: string) => `tasks/${scheduleId}`,
  getDetailOfficeCalendar: (scheduleId: string) => `schedules/${scheduleId}/calendars/office`,
};

export const urlApiFile = {
  getListDocuments: `file`,
  deleteDocument: (fileId: string) => `file/${fileId}`,
  downloadDocument: (fileId: string) => `file/download/${fileId}`,
  uploadDocument: `file/new`,
  updateDocument: (fileId: string) => `file/${fileId}`,
  getDetailDocument: (fileId: string) => `file/${fileId}`,
  assignToContact: (fileId: string, contactId: string) => `file/${fileId}/assign/${contactId}`,
};

export const urlApiTask = {
  getListTask: 'tasks',
  getSelectTask: 'tasks/select',
  createTask: 'tasks/new',
  getTaskDetail: (taskId: string) => `tasks/${taskId}`,
  deleteTask: (taskId: string) => `tasks/${taskId}`,
  updateTask: (taskId: string) => `tasks/${taskId}`,
};

export const urlApiTimeClock = {
  getListTimeClock: 'time-clocks',
  createTimeClock: 'time-clocks/new',
  detail: (timeClockId: string) => `time-clocks/${timeClockId}`,
  update: (timeClockId: string) => `time-clocks/${timeClockId}`,
  deleteTimeClock: (timeClockId: string) => `time-clocks/${timeClockId}`,
  changeStatus: (timeClockId: string) => `time-clocks/${timeClockId}/change-status`,
};

export const urlApiDashboard = {
  statistics: 'statistics/summary',
};

export const urlApiActivity = {
  getListActivityByClient: (userId: string) => `activities/client/${userId}`,
  getListActivityByCaregiver: (userId: string) => `activities/caregiver/${userId}`,
  getListActivityByApplicant: (userId: string) => `activities/applicant/${userId}`,
  getListActivityByProspect: (userId: string) => `activities/prospect/${userId}`,
};

export const urlApiQuickBook = {
  getAuthUrl: 'quick-books/auth-url',
  getToken: 'quick-books/token',
  disconnect: 'quick-books/disconnect',
  getListCustomer: 'quick-books/customers',
};

export const urlApiNotifications = {
  getList: 'notifications',
  markedRead: (notificationId: string) => `notifications/${notificationId}/read`,
};

export const urlApiReport = {
  getLocationsCaregiverInReport: '/reports/caregivers/check-in-locations',
  getAllCaregiversByLocation: '/reports/caregivers/by-location',
  getAllClientsByLocation: '/reports/clients/by-location',
  getReportInvoice: '/locations/invoice-reports',
};

export const urlApiRates = {
  getList: '/rates',
  getListPayroll: '/rates/by-user',
  createRate: 'rates/new',
  updatePayrollRate: '/rates/by-user',
  getListAuthorization: '/authorizations',
  createAuthorization: '/authorizations/new',
  getDetailAuthorization: (authorizationId: string) => `/authorizations/${authorizationId}`,
  editAuthorization: (authorizationId: string) => `/authorizations/${authorizationId}`,
};

export const urlApiLocation = {
  createLocation: 'locations/new',
  getListLocation: 'locations',
  getDetailLocation: (locationId: string) => `locations/${locationId}`,
};

export const urlApiInitialCall = {
  getListInitialCall: 'initial-calls',
  create: 'initial-calls/new',
  getDetailInitialCall: (id: string) => `initial-calls/${id}`,
};

export const urlApiContact = {
  getList: 'contacts',
  getDetailContact: (id: string) => `contacts/${id}`,
  createContact: 'contacts/new',
  changeStatusContact: (contactId: string) => `contacts/${contactId}/change-status`,
  updateContact: (contactId: string) => `contacts/${contactId}`,
};

export const urlApiStage = {
  getList: 'stages',
  createStage: 'stages/new',
  update: (stageId: string) => `stages/${stageId}`,
};

export const urlApiEmailTemplate = {
  getList: 'email-templates',
  createEmailTemplate: 'email-templates/new',
  editEmailTemplate: (id: string) => `email-templates/${id}`,
};

export const urlApiCampaign = {
  getList: 'campaigns',
  createCampaign: 'campaigns/new',
  getDetailCampaign: (id: string) => `campaigns/${id}`,
  editCampaign: (id: string) => `campaigns/${id}`,
};

export const urlApiInvoice = {
  createInvoice: 'invoices/new',
  getListInvoice: 'invoices',
  getDetailInvoice: (id: string) => `invoices/${id}`,
  sendInvoice: 'invoices/send-email',
  changeStatusInvoice: (id: string) => `invoices/${id}/change-status`,
  getListInvoiceByClient: (clientId: string) => `invoices/clients/${clientId}`,
};

export const urlApiBackgroundReports = {
  createBackgroundReports: 'background-reports/new',
  getListBackgroundReport: 'background-reports',
  editBackgroundReports: (backgroundReportId: string) => `background-reports/${backgroundReportId}`,
  deleteBackgroundReports: (backgroundId: string) => `background-reports/${backgroundId}`,
};

export const urlApiSkill = {
  createSkill: 'skills/new',
  getDetailSkill: 'skills',
  editSkill: 'skills',
};

export const urlApiCaregiverList = {
  getList: 'caregiver-lists',
};

export const urlApiTraining = {
  getListCaregiverTraining: (caregiverId: string) => `training/caregivers/${caregiverId}`,
};

export const urlApiHr = {
  getListHr: 'unavailabilities',
  changeStatusUnavailability: (unavailabilityId: string) => `unavailabilities/${unavailabilityId}/change-status`,
  createUnavailabilityEvent: 'unavailabilities/new',
  deleteUnavailability: (unavailabilityId: string) => `unavailabilities/${unavailabilityId}`,
  detailUnavailability: (unavailabilityId: string) => `unavailabilities/${unavailabilityId}`,
};

export const urlApiHomeSafetyAssessment = {
  getHomeSafetyAssessment: 'home-assessments',
  editAnswer: (clientId: string, questionId: string) => `home-assessments/clients/${clientId}/questions/${questionId}`,
};

export const urlApiDailyCare = {
  createVisitNote: 'visit-notes/new',
  getListVisitNote: 'visit-notes',
  createMileage: 'mileages/new',
  getMileageList: 'mileages',
  createExpenseCredits: 'expense-credits/new',
  getListExpenseCredits: 'expense-credits',
  getTaskListDailyCare: 'shifts/tasks',
};
