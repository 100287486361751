// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Components, Layouts, Pages
import { BaseButton, ModalUnderDevelopment } from '~/components';
// Others
import { EMPTY_STRING, RegExp } from '~/utils/constants/common';
import { IForgotPasswordPayload } from '~/utils/interface/auth';
import { ButtonTypeEnum, ForgotPasswordNameEnum } from '~/utils/enum';
import { validateWithRegex } from '~/utils/helper';
import { LoadingContext } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { forgotPassword } from '~/thunks/auth/authThunk';
import { authRouteAbsolute } from '~/utils/constants/route';
// Styles, images, icons
import styles from './ForgotPassword.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const ForgotPassword = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loading = useContext(LoadingContext);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [formValues, setFormValues] = useState<IForgotPasswordPayload>({
    email: EMPTY_STRING,
  });
  const [messageError, setMessageError] = useState<string>(EMPTY_STRING);
  const [isDevelopment, setIsDevelopment] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFormValues((prevState) => ({ ...prevState, [name]: value }));

    setMessageError(EMPTY_STRING);
  };

  const handleSend = () => {
    const trimmedEmail = formValues.email.trim();

    const updateFormValue = {
      email: trimmedEmail,
    };

    if (!trimmedEmail) {
      setMessageError(t('forgot_password_email_required'));
    } else if (!validateWithRegex(trimmedEmail, RegExp.EMAIL)) {
      setMessageError(t('forgot_password_email_invalid'));
    } else {
      setMessageError(EMPTY_STRING);
    }

    if (trimmedEmail && validateWithRegex(trimmedEmail, RegExp.EMAIL)) {
      loading?.show();

      dispatch(forgotPassword(updateFormValue))
        .unwrap()
        .then((res) => {
          if (!res) return;

          navigate(`${authRouteAbsolute.verifyCode}`, { state: updateFormValue });
        })
        .catch((err) => {})
        .finally(() => {
          loading?.hide();
        });
    }
  };

  // TODO: Remove later
  const handleCloseModalDevelopment = () => {
    setIsDevelopment(false);
  };
  //#endregion Handle Function

  return (
    <div id='forgotPasswordComponent' className={cx('container')}>
      <h3 className={cx('title')}>{t('forgot_password_title')}</h3>
      <p className={cx('desc')}>{t('forgot_password_desc')}</p>

      <div className={cx('formForgotPassword')}>
        <div className={cx('email')}>
          <label htmlFor={ForgotPasswordNameEnum.EMAIL} className={cx('label')}>
            {t('forgot_password_label_email')}
          </label>

          <div className={cx('inputGroup')}>
            <img src={icons.signInIconEmail} alt={t('common_img_text_alt')} className={cx('inputPrefix')} />

            <input
              name={ForgotPasswordNameEnum.EMAIL}
              type='text'
              id={ForgotPasswordNameEnum.EMAIL}
              // placeholder={t('forgot_password_placeholder_email')}
              className={cx('input')}
              value={formValues.email}
              onChange={handleChangeInput}
            />
          </div>

          {messageError && <p className={cx('errorMessage')}>{messageError}</p>}
        </div>

        <BaseButton
          text={t('common_send_label')}
          width={'100%'}
          typeStyle={ButtonTypeEnum.PRIMARY}
          onClick={handleSend}
        />
      </div>

      {isDevelopment && <ModalUnderDevelopment onClose={handleCloseModalDevelopment} />}
    </div>
  );
};

export default ForgotPassword;
