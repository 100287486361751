import { Libraries } from '@react-google-maps/api';
import { ViewsProps } from 'react-big-calendar';
import { icons } from '~/assets';
import {
  FieldTypeEmployeesEnum,
  FieldTypeEnum,
  TabNameTransKeyEnum,
  TabQueryClientDetailEnum,
  TabQueryEventEnum,
  TabQuerySettingsEnum,
  TabQueryTaskDetailEnum,
  TabQueryValueEnum,
  FieldTypeRate,
  CLientStatusEnum,
  TabQueryReferralSourceDetailEnum,
  ContactTypeEnum,
  InitialCallFormEnum,
  ReferralRoleFilterReferralSourceEnum,
  ContactPermissionTypeEnum,
  ContactFormEnum,
  TabQueryContactDetailEnum,
  StatusFilterEnum,
  TypeStageFilterEnum,
  TabQueryRequestApplicantDetailEnum,
  StatusEnum,
  TabQueryCaregiverReferralDetailEnum,
  GeneralSkillEnum,
  TransfersSkillEnum,
  PetsSkillEnum,
  OtherOrMiscSkillEnum,
  LinkToQuickBookCustomerEnum,
  InvoiceTermEnum,
  InvoiceRemittanceAddressEnum,
  PayHourRateEnum,
  OvertimeMethodRateEnum,
  TypeCareAlertEnum,
  TimelineAlertEnum,
  LinkToCaregiverListEnum,
} from '~/utils/enum';
import { IBaseOption, IBaseOptionMultiple, ITabItem } from '~/utils/interface/common';
import { IDefaultFilterTask } from '~/utils/interface/task';
import { IDefaultFilterEmployees } from '~/utils/interface/employees';
import { IDefaultFilterRate } from '~/utils/interface/rate';

export const Languages = ['en'];

// Default Value
export const DEFAULT_DOT_TABLE_OPTION = {
  isDeactivate: false,
  isDelete: true,
  isEdit: true,
  isSendMessage: false,
  isView: true,
};
export const DEFAULT_MIN_YEAR = 1900;
export const MAX_COL_NUMBER = 10;
export const MIN_WIDTH_NUMBER = 150;
export const DEFAULT_NUMBER_ZERO = 0;
export const DEFAULT_NUMBER_ONE = 1;
export const COMMA_SEPARATOR = ',';
export const DEFAULT_NUMBER_RECORD_TO_FETCH = 10;
export const DEFAULT_NUMBER_LIMIT_CAREGIVER = 5;
export const DEFAULT_NUMBER_LIMIT_APPLICANT = 3;
export const DEFAULT_NUMBER_LIMIT_CARE_ALERT = 3;
export const EMPTY_VALUE = '--';
export const EMPTY_STRING = '';
export const EMPTY_STRING_TEXT_EDIT = '<p></p>';
export const QUERY_KEY_TAB = 'tab';
export const HEIGHT_FULL = '100%';
export const WIDTH_FULL = '100%';
export const MAX_VISIBLE_IMAGE = 4;
export const MIN_VISIBLE_IMAGE = 0;
export const AND = '&';
export const PLUS = '+';
export const ASTERISK_SYMBOL = '*';
export const AT_SYMBOL = '@';
export const DOLLAR = '$';
export const DEFAULT_WIDTH_DONUT_CHART = 240;
export const DEFAULT_HEIGHT_DONUT_CHART = 240;
export const DEFAULT_DELAY_TIME = 1000;
export const DEFAULT_TIMEOUT_RECONNECT_WSS = 1000;
export const DEFAULT_TIMEOUT_SEARCH = 1000;
export const CODE_CLOSE_WSS_LOGOUT = 4999;
export const REASON_CLOSE_WSS_LOGOUT = 'LOGOUT';
export const LABEL = 'LABEL';
export const timeCloseToast = 3000;
export const CONTENT_TYPE_FORM_DATA = 'multipart/form-data';
export const DEBOUNCE_SEEN_MESSAGE_DELAY = 1000;
export const DEFAULT_CATEGORIES_YEAR = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Spt',
  'Oct',
  'Nov',
  'Dec',
];
export const POSSESSIVE_SUFFIX = "'s";
export const DIV_ELEMENT = 'div';
export const ATTRIBUTE_ID = 'id';
export const HIDDEN = 'hidden';
export const UNSET = 'unset';
export const MODE_SEARCH = 'search';
export const WRAPPER_ID = 'wrapperId';
export const PIXELS = 'px';
export const ENTER_KEY = 'Enter';
export const DEFAULT_UPLOAD_ACCEPT_IMAGE = 'image/*';
export const DEFAULT_UPLOAD_ACCEPT_FILE = '*/*';
export const RANDOM_RANGE = 1000;
export const DEFAULT_HEIGHT_INPUT_MESSAGE = 18;
export const DEFAULT_PASSWORD_MIN = 8;
export const CURRENT = 'current';
export const DEFAULT_ID_ACTIVE_ADD_CRM = '1';
export const MAX_FILE_SIZE_MB = 5;
export const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;
export const MAX_FILE_SIZE_FILE_20MB = 20 * 1024 * 1024;
export const VALID_HEX = /^#?([a-fA-F\d]{2})([a-fA-F\d]{2})([a-fA-F\d]{2})$/;
export const NUMBER_BINARY_SYSTEM_FILE = 1024;
export const LIMIT_TAG_ASSIGNEE = 3;
export const PLUS_CHARACTER = '+';
export const DEFAULT_HEIGHT_ASSIGNEE_MODAL = 375;
export const MS_IN_SECOND = 1000;
export const SECONDS_IN_MINUTE = 60;
export const MINUTES_IN_HOUR = 60;
export const HOURS_UNIT = 'hrs';
export const SECOND = 's';
export const HOUR = 'hour';
export const MIN = 'min';
export const DEFAULT_MAP_ZOOM = 17;
export const DEFAULT_STATUS_OK = 'OK';
export const OBJECT = 'object';
export const A_ELEMENT = 'a';
export const TIME_CLOSE_TOAST = 4000;
export const TIME_OUT_SHOW_TOAST = 1000;
export const TIME_OUT_SHOW_TOAST_ERROR = 200;
export const TIME_OUT_SHOW_TOAST_SUCCESS = 1000;
export const DEFAULT_HEIGHT_SUB_MENU = 17;
export const DEFAULT_BORDER_ASSIGNEE = 4;
export const DEFAULT_VALUE_EMPTY_RICH_EDITOR = '<p><br></p>';
export const ACCEPT_UPLOAD_DOCUMENT_PURCHASE_ORDER = '.pdf, .jpg, .jpeg, .png';
export const ACCEPT_UPLOAD_DOCUMENT_MESSAGE =
  'video/mp4, audio/mpeg, image/png, image/jpeg, image/jpg, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/pdf';
export const ACCEPT_ALL_FILES = '*/*';
export const FILE_IMAGE_TYPE = 'image/';
export const FILE_VIDEO_TYPE = 'video/';
export const FILE_DOCUMENT_TYPE = 'application/';
export const FILE_UPLOAD_LIMIT_50_MB = 52428800;
export const NEGATIVE_ONE = -1;
export const NAVIGATE_BACK = -1;
export const MAX_LENGTH_NOTIFICATION = 99;
export const NEW_NOTIFICATION_THRESHOLD = 10;
export const DEFAULT_PADDING_OPTION_SELECT = 28;
export const PADDING_OPTION_SELECT_MULTIPLE = 34;
export const CODE_MESSAGE_EMPLOYEE_DELETED = 13015;
export const CODE_MESSAGE_CLIENT_DELETED = 21013;
export const CODE_MESSAGE_TASK_DELETED = 24021;
export const DEFAULT_MAX_TAG = 5;
export const MAX_FILE_SIZE = 50 * 1024 * 1024;
export const DEFAULT_INPUT_HEIGHT = 34;
export const DEFAULT_LIMIT_EMAIL = 100;
export const DEFAULT_CONTAINER_WIDTH = 'fit-content';
export const VALUE_START_TIME_DAY = '00:00';
export const VALUE_END_TIME_DAY = '23:59';
export const DEFAULT_CURRENCY = '$0.00';
export const DEFAULT_INVOICE_FILE_NAME_PDF = 'documentInvoice.pdf';
export const SPACE = ' ';

export const LINE_CHART_CATEGORIES = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const DEFAULT_QUALITY_IMAGE = 0.6;
export const DEFAULT_MAX_HEIGHT_IMAGE = 720;
export const DEFAULT_MAX_WIDTH_IMAGE = 1280;

export const NUMBER_COLUMNS_VERIFY = 6;
export const DEFAULT_DELAY_SELECT_AUTOCOMPLETE = 100;

export const MESSAGE_CONNECTED_QUICK_BOOK = 'MESSAGE_CONNECTED_QUICK_BOOK';

export const SECONDS_IN_A_MINUTE = 60;
export const HOURS_IN_A_DAY = 24;
export const ONE_SECOND = 1;

export const FORMAT_NUMBER = {
  numberThree: 3,
  numberFour: 4,
  numberSix: 6,
  numberSeven: 7,
  numberTen: 10,
};

// Schedule
export const DEFAULT_SCHEDULE_VIEWS: ViewsProps = { month: true, week: true, day: true };
export const DEFAULT_VIEW_MODE = 'month';
export const DEFAULT_SCHEDULE_EVENT_COLOR = '#E1F9F1';
export const SCHEDULE_MODE_FULL = 'full';
export const SCHEDULE_MODE_MYSELF = 'my';
export const SCHEDULE_MODE_CLIENTS = 'clients';
export const SCHEDULE_MODE_CAREGIVER = 'caregiver';

// Pagination
export const defaultCurrentPage = 1;
export const DEFAULT_CURRENT_PAGE = 1;
export const DEFAULT_LIMIT_PAGE = 10;
export const MAX_VISIBLE_PAGE = 4;
export const SYMBOL_THREE_DOTS = '•••';
export const DEFAULT_TOTAL_PAGE = 0;
export const DEFAULT_TOTAL_ITEM = 0;
export const DEFAULT_LIMIT_MESSAGE = 20;
export const DEFAULT_LIMIT_MAX_ITEM = 1000;
export const DEFAULT_LIMIT_PAGE_SCHEDULE = 100;
export const DEFAULT_SHORT_LIMIT_PAGE = 5;
export const DEFAULT_SHORT_LIMIT_PAGE_CARE_ALERT = 3;
export const DEFAULT_MAX_ITEM_RECENTLY_DOCUMENT = 50;

// Tabs
export const caregiverDetailTabs: ITabItem[] = [
  { tabName: TabNameTransKeyEnum.INFO, tabParam: TabQueryValueEnum.INFO },
  { tabName: TabNameTransKeyEnum.DAILY_CARE, tabParam: TabQueryValueEnum.DAILY_CARE },
  { tabName: TabNameTransKeyEnum.SCHEDULE, tabParam: TabQueryValueEnum.SCHEDULE },
  { tabName: TabNameTransKeyEnum.ACTIVITIES, tabParam: TabQueryValueEnum.ACTIVITIES },
  { tabName: TabNameTransKeyEnum.FILES, tabParam: TabQueryValueEnum.FILES },
  { tabName: TabNameTransKeyEnum.SKILL, tabParam: TabQueryValueEnum.SKILL },
  { tabName: TabNameTransKeyEnum.PAYROLL, tabParam: TabQueryValueEnum.PAYROLL },
  { tabName: TabNameTransKeyEnum.CHECK, tabParam: TabQueryValueEnum.CHECK },
  { tabName: TabNameTransKeyEnum.TRAINING, tabParam: TabQueryValueEnum.TRAINING },
];

export const clientDetailTabs: ITabItem[] = [
  { tabName: TabNameTransKeyEnum.CARE_PLAN, tabParam: TabQueryClientDetailEnum.CARE_PLAN },
  { tabName: TabNameTransKeyEnum.DOCUMENTS, tabParam: TabQueryClientDetailEnum.DOCUMENTS },
  { tabName: TabNameTransKeyEnum.SHIFT_SCHEDULE, tabParam: TabQueryClientDetailEnum.SHIFT_SCHEDULE },
  { tabName: TabNameTransKeyEnum.ACTIVITIES, tabParam: TabQueryClientDetailEnum.ACTIVITIES },
];

export const prospectDetailTabs: ITabItem[] = [
  { tabName: TabNameTransKeyEnum.INFO, tabParam: TabQueryClientDetailEnum.INFO },
  { tabName: TabNameTransKeyEnum.CONTACT, tabParam: TabQueryClientDetailEnum.CONTACT },
  // { tabName: TabNameTransKeyEnum.CARE_PLAN, tabParam: TabQueryClientDetailEnum.CARE_PLAN },
  { tabName: TabNameTransKeyEnum.DOCUMENTS, tabParam: TabQueryClientDetailEnum.DOCUMENTS },
  { tabName: TabNameTransKeyEnum.ACTIVITIES, tabParam: TabQueryClientDetailEnum.ACTIVITIES },
];

export const taskDetailTabs: ITabItem[] = [
  { tabName: TabNameTransKeyEnum.COMMENTS, tabParam: TabQueryTaskDetailEnum.COMMENTS },
  { tabName: TabNameTransKeyEnum.ACTIVITY, tabParam: TabQueryTaskDetailEnum.ACTIVITY },
];

export const eventPopupTabs: ITabItem[] = [
  { tabName: TabNameTransKeyEnum.SUMMARY, tabParam: TabQueryEventEnum.SUMMARY },
  { tabName: TabNameTransKeyEnum.NOTES, tabParam: TabQueryEventEnum.NOTES },
];

export const eventPopupUnavailableTabs: ITabItem[] = [
  { tabName: TabNameTransKeyEnum.SUMMARY, tabParam: TabQueryEventEnum.SUMMARY },
  // { tabName: TabNameTransKeyEnum.NOTES, tabParam: TabQueryEventEnum.NOTES },
];

export const settingsTabs: ITabItem[] = [
  { tabName: TabNameTransKeyEnum.INTEGRATION, tabParam: TabQuerySettingsEnum.INTEGRATION },
];

export const settingsSuperAdminTabs: ITabItem[] = [
  { tabName: TabNameTransKeyEnum.INTEGRATION, tabParam: TabQuerySettingsEnum.INTEGRATION },
  { tabName: TabNameTransKeyEnum.RATE, tabParam: TabQuerySettingsEnum.RATE },
  { tabName: TabNameTransKeyEnum.STAGE, tabParam: TabQuerySettingsEnum.STAGE },
  { tabName: TabNameTransKeyEnum.EMAIL_TEMPLATE, tabParam: TabQuerySettingsEnum.EMAIL_TEMPLATE },
];

export const referralSourceDetailTabs: ITabItem[] = [
  {
    tabName: TabNameTransKeyEnum.INFO,
    tabParam: TabQueryReferralSourceDetailEnum.INFO,
  },
  {
    tabName: TabNameTransKeyEnum.CLIENTS_PROSPECTS_REFERRED,
    tabParam: TabQueryReferralSourceDetailEnum.CLIENTS_PROSPECTS_REFERRED,
  },
];

export const caregiverReferralDetailTabs: ITabItem[] = [
  {
    tabName: TabNameTransKeyEnum.INFO,
    tabParam: TabQueryCaregiverReferralDetailEnum.INFO,
  },
  {
    tabName: TabNameTransKeyEnum.CAREGIVER_APPLICANT_REFERRAL,
    tabParam: TabQueryCaregiverReferralDetailEnum.CAREGIVER_APPLICANT_REFERRED,
  },
];

export const contactDetailTabs: ITabItem[] = [
  {
    tabName: TabNameTransKeyEnum.INFO,
    tabParam: TabQueryContactDetailEnum.INFO,
  },
  {
    tabName: TabNameTransKeyEnum.CLIENTS_PROSPECTS,
    tabParam: TabQueryContactDetailEnum.CLIENTS_PROSPECTS,
  },
  {
    tabName: TabNameTransKeyEnum.DOCUMENTS,
    tabParam: TabQueryContactDetailEnum.DOCUMENTS,
  },
];

export const requestApplicantDetailTabs: ITabItem[] = [
  {
    tabName: TabNameTransKeyEnum.INFO,
    tabParam: TabQueryRequestApplicantDetailEnum.INFO,
  },
  {
    tabName: TabNameTransKeyEnum.SKILL,
    tabParam: TabQueryRequestApplicantDetailEnum.SKILL,
  },
  {
    tabName: TabNameTransKeyEnum.CHECK,
    tabParam: TabQueryRequestApplicantDetailEnum.CHECK,
  },
  {
    tabName: TabNameTransKeyEnum.ACTIVITIES,
    tabParam: TabQueryRequestApplicantDetailEnum.ACTIVITIES,
  },
];

export const COUNT_FORMATS = [
  {
    letter: '',
    limit: 1e3,
  },
  {
    letter: 'K',
    limit: 1e6,
  },
  {
    letter: 'M',
    limit: 1e9,
  },
  {
    letter: 'B',
    limit: 1e12,
  },
  {
    letter: 'T',
    limit: 1e15,
  },
];

export const planText = {
  IN_HOME: 'IN-HOME',
  TRANSPORTATION: 'TRANSPORTATION',
  DAY_CENTER: 'DAY CENTER',
};

export const MONTHS_THREE_CHARACTER = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const DEFAULT_VALUE_BUTTON_ADD_CRM = 'crm_add_client_title';

// Regex
export const RegExp = {
  EMAIL: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  ONLY_DIGITS_REGEX: /^\d+$/,
  ISO_8601: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?Z?$/,
  NUMBER: /^[0-9]*$/,
  NUMBER_MAX_LENGTH_SIX: /^[0-9]{6}$/,
  TEXT: /\D/g,
  NON_DIGIT_REGEX: /[^\d]/g,
};

// Login Type
export const LoginType = {
  STANDARD: 'STANDARD',
};

export const fileTypeIcons: { [key: string]: string } = {
  docx: icons.commonIconDocsFile,
  pdf: icons.commonIconPdfFile,
  default: icons.commonIconSharedFolder,
};

export const fileTypeTaskIcons: { [key: string]: string } = {
  // Update Later
  docx: icons.commonIconDocsFile,
  txt: icons.commonIconDocsFile,
  pdf: icons.taskIconPdfFile,
  default: icons.commonIconSharedFolder,
};

export const ALLOWED_FILE_TYPE = [
  '.pdf',
  '.xlsx',
  '.xls',
  '.xlsm',
  '.xltx',
  '.xltm',
  '.xlam',
  '.xlsb',
  '.docx',
  '.doc',
  '.dotx',
  '.docm',
  '.dotm',
  '.pptx',
  '.ppt',
  '.pot',
  '.pps',
  '.ppa',
  '.potx',
  '.ppsx',
  '.ppam',
  '.pptm',
  '.potm',
  '.ppsm',
];

// Google Map
export const DEFAULT_GG_MAP_LOAD_SCRIPT_LIB: Libraries = ['places'];
export const GEOLOCATION_TIMEOUT = 5000;
export const GEOLOCATION_MAXIMUM_AGE = 0;
export const GOOGLE_MAPS_URL = (address: string) => {
  const formattedAddress = address.replace(/\s+/g, '+');
  return `https://www.google.com/maps?q=${formattedAddress}`;
};
export const BLANK_GOOGLE_MAP = '_blank';

export const DEFAULT_FILTER_TASK: IDefaultFilterTask = {
  fields: [FieldTypeEnum.ASSIGNEE],
};

// Options Rich Editor
export const toolbarOptionsRichEditor = [
  [
    { header: [1, 2, 3, 4, 5, 6, false] },
    'bold',
    'italic',
    'underline',
    'strike',
    {
      color: [
        '#000000',
        '#e60000',
        '#ff9900',
        '#ffff00',
        '#008a00',
        '#0066cc',
        '#9933ff',
        '#ffffff',
        '#facccc',
        '#ffebcc',
        '#ffffcc',
        '#cce8cc',
        '#cce0f5',
        '#ebd6ff',
        '#bbbbbb',
        '#f06666',
        '#ffc266',
        '#ffff66',
        '#66b966',
        '#66a3e0',
        '#c285ff',
        '#888888',
        '#a10000',
        '#b26b00',
        '#b2b200',
        '#006100',
        '#0047b2',
        '#6b24b2',
        '#444444',
        '#5c0000',
        '#663d00',
        '#666600',
        '#003700',
        '#002966',
        '#3d1466',
      ],
    },
    { list: 'ordered' },
    { list: 'bullet' },
    { indent: '-1' },
    { indent: '+1' },
    { script: 'sub' },
    { script: 'super' },
    'link',
    'image',
  ],
];

export const TIME_MEAL_CARE_PLAN = [
  {
    label: 'care_plan_meal_meal_time_breakfast_label',
    value: 'BREAKFAST',
  },
  {
    label: 'care_plan_meal_meal_time_lunch_label',
    value: 'LUNCH',
  },
  {
    label: 'care_plan_meal_meal_time_dinner_label',
    value: 'DINNER',
  },
  {
    label: 'care_plan_meal_meal_time_snacks_label',
    value: 'SNACKS',
  },
];

export const TIME_OPTIONS = [
  '00:00',
  // '00:15',
  '00:30',
  // '00:45',
  '01:00',
  // '01:15',
  '01:30',
  // '01:45',
  '02:00',
  // '02:15',
  '02:30',
  // '02:45',
  '03:00',
  // '03:15',
  '03:30',
  // '03:45',
  '04:00',
  // '04:15',
  '04:30',
  // '04:45',
  '05:00',
  // '05:15',
  '05:30',
  // '05:45',
  '06:00',
  // '06:15',
  '06:30',
  // '06:45',
  '07:00',
  // '07:15',
  '07:30',
  // '07:45',
  '08:00',
  // '08:15',
  '08:30',
  // '08:45',
  '09:00',
  // '09:15',
  '09:30',
  // '09:45',
  '10:00',
  // '10:15',
  '10:30',
  // '10:45',
  '11:00',
  // '11:15',
  '11:30',
  // '11:45',
  '12:00',
  // '12:15',
  '12:30',
  // '12:45',
  '13:00',
  // '13:15',
  '13:30',
  // '13:45',
  '14:00',
  // '14:15',
  '14:30',
  // '14:45',
  '15:00',
  // '15:15',
  '15:30',
  // '15:45',
  '16:00',
  // '16:15',
  '16:30',
  // '16:45',
  '17:00',
  // '17:15',
  '17:30',
  // '17:45',
  '18:00',
  // '18:15',
  '18:30',
  // '18:45',
  '19:00',
  // '19:15',
  '19:30',
  // '19:45',
  '20:00',
  // '20:15',
  '20:30',
  // '20:45',
  '21:00',
  // '21:15',
  '21:30',
  // '21:45',
  '22:00',
  // '22:15',
  '22:30',
  // '22:45',
  '23:00',
  // '23:15',
  '23:30',
  // '23:45',
];

export const DEFAULT_FILTER_EMPLOYEES: IDefaultFilterEmployees = {
  fields: [FieldTypeEmployeesEnum.NAME],
};

export const DEFAULT_FILTER_RATE: IDefaultFilterRate = {
  fields: [FieldTypeRate.NAME],
};

export const OPTIONS_STATUS_CLIENT = [
  { value: CLientStatusEnum.ACTIVATE, label: 'Active' },
  { value: CLientStatusEnum.ON_HOLD, label: 'On Hold' },
  { value: CLientStatusEnum.TERMINATED, label: 'Terminated' },
];

export const OPTIONS_CHANGE_STATUS = [
  { value: CLientStatusEnum.ACTIVATE, label: 'Active' },
  { value: CLientStatusEnum.DEACTIVATE, label: 'Inactive' },
];

export const OPTIONS_REFERRAL_STATUS = [
  { value: CLientStatusEnum.ACTIVATE, label: 'Active' },
  { value: CLientStatusEnum.DEACTIVATE, label: 'Inactive' },
];

export const OPTIONS_CONTACT_STATUS = [
  { value: CLientStatusEnum.ACTIVATE, label: 'Active' },
  { value: CLientStatusEnum.DEACTIVATE, label: 'Inactive' },
];
// Ring Central Start
/// Message
export const RING_CENTRAL_TYPE_ACTIVE_CALL_NOTIFY = 'rc-active-call-notify';
export const RING_CENTRAL_TYPE_CALL_END_NOTIFY = 'rc-call-end-notify';
export const RING_CENTRAL_TYPE_CONNECTION_STATUS_NOTIFY = 'rc-webphone-connection-status-notify';
export const RING_CENTRAL_TYPE_LOGIN_STATUS_NOTIFY = 'rc-login-status-notify';

/// Status
export const RING_CENTRAL_TYPE_CONNECTED_STATUS = 'connectionStatus-connected';
export const RING_CENTRAL_TYPE_CONNECTING_STATUS = 'connectionStatus-connecting';
export const RING_CENTRAL_TYPE_DISCONNECTED_STATUS = 'connectionStatus-disconnected';
export const RING_CENTRAL_TYPE_RINGING_TELEPHONY_STATUS = 'Ringing';

/// Adapter
export const RING_CENTRAL_TYPE_REGISTER_SERVICE_ADAPTER = 'rc-adapter-register-third-party-service';
export const RING_CENTRAL_TYPE_NEW_CALL_ADAPTER = 'rc-adapter-new-call';
export const RING_CENTRAL_TYPE_LOGIN_ADAPTER = 'rc-adapter-login';
export const RING_CENTRAL_TYPE_LOGOUT_ADAPTER = 'rc-adapter-logout';
export const RING_CENTRAL_TYPE_NEW_SMS_ADAPTER = 'rc-adapter-new-sms';
export const RING_CENTRAL_TYPE_WIDGET_FRAME_ADAPTER = '#rc-widget-adapter-frame';

// Invoice
export const externalLinkInvoice = (qbId: string) =>
  `https://sandbox.qbo.intuit.com/app/paymentrequests?nameId=${qbId}&newPaymentRequest=true`;

// Initial Call
export const prospectTypeInitialCallOptions = [
  {
    label: 'Add New',
    value: InitialCallFormEnum.ADD_NEW,
  },
  {
    label: 'Select Existing',
    value: InitialCallFormEnum.SELECT_EXISTING,
  },
];

export const OPTIONS_NEEDS_MEDICATION = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
];

export const callerTypeInitialCallOptions = [
  {
    label: 'Add New',
    value: InitialCallFormEnum.ADD_NEW,
  },
  {
    label: 'Select Existing',
    value: InitialCallFormEnum.SELECT_EXISTING,
  },
  // {
  //   label: 'Self',
  //   value: InitialCallFormEnum.SELF,
  // },
];

export const contactTypeCallerInformation = [
  {
    label: 'Family',
    value: ContactTypeEnum.FAMILY,
  },
  {
    label: 'Friend',
    value: ContactTypeEnum.FRIEND,
  },
  {
    label: 'Insurance Company',
    value: ContactTypeEnum.INSURANCE_COMPANY,
  },
  {
    label: 'Medical Professional',
    value: ContactTypeEnum.MEDICAL_PROFESSIONAL,
  },
  {
    label: 'Neighbor',
    value: ContactTypeEnum.NEIGHBOR,
  },
  {
    label: 'Other',
    value: ContactTypeEnum.OTHER,
  },
];

export const referralTypeInitialCallOptions = [
  {
    label: 'Add New',
    value: InitialCallFormEnum.ADD_NEW,
  },
  {
    label: 'Select Existing',
    value: InitialCallFormEnum.SELECT_EXISTING,
  },
  // {
  //   label: 'Same as Seller',
  //   value: InitialCallFormEnum.SELF,
  // },
];

export const OPTIONS_Role_REFERRAL_SOURCE: IBaseOption[] = [
  {
    label: 'All',
    value: ReferralRoleFilterReferralSourceEnum.ALL,
  },
  {
    label: 'Individual',
    value: ReferralRoleFilterReferralSourceEnum.INDIVIDUAL,
  },
  {
    label: 'Organization',
    value: ReferralRoleFilterReferralSourceEnum.ORGANIZATION,
  },
];
// End Initial Call

export const relationshipContact = [
  {
    label: 'Family',
    value: ContactTypeEnum.FAMILY,
  },
  {
    label: 'Friend',
    value: ContactTypeEnum.FRIEND,
  },
  {
    label: 'Insurance Company',
    value: ContactTypeEnum.INSURANCE_COMPANY,
  },
  {
    label: 'Medical Professional',
    value: ContactTypeEnum.MEDICAL_PROFESSIONAL,
  },
  {
    label: 'Neighbor',
    value: ContactTypeEnum.NEIGHBOR,
  },
  {
    label: 'Other',
    value: ContactTypeEnum.OTHER,
  },
];

export const permissionContact = [
  {
    label: 'Primary',
    value: ContactPermissionTypeEnum.PRIMARY,
  },
  {
    label: 'Emergency',
    value: ContactPermissionTypeEnum.EMERGENCY,
  },
  {
    label: 'POA',
    value: ContactPermissionTypeEnum.POA,
  },
  {
    label: 'Payer',
    value: ContactPermissionTypeEnum.PAYER,
  },
  {
    label: 'E-Payer',
    value: ContactPermissionTypeEnum.E_PAYER,
  },
  {
    label: 'Email Invoices',
    value: ContactPermissionTypeEnum.EMAIL_INVOICES,
  },
  {
    label: 'Print on Care Plan',
    value: ContactPermissionTypeEnum.PRINT_ON_CARE_PLAN,
  },
];

export const selectContactOption = [
  {
    label: 'Add New',
    value: ContactFormEnum.ADD_NEW,
  },
  {
    label: 'Select Existing',
    value: ContactFormEnum.SELECT_EXISTING,
  },
];

export const optionsStatusClient: IBaseOption[] = [
  {
    label: 'All',
    value: StatusFilterEnum.ALL,
  },
  {
    label: 'Active',
    value: StatusFilterEnum.ACTIVATE,
  },
  {
    label: 'On Hold',
    value: StatusFilterEnum.ON_HOLD,
  },
  {
    label: 'Terminated',
    value: StatusFilterEnum.TERMINATED,
  },
];

export const optionsStatusReferralSource: IBaseOption[] = [
  {
    label: 'All',
    value: StatusFilterEnum.ALL,
  },
  {
    label: 'Active',
    value: StatusFilterEnum.ACTIVATE,
  },
  {
    label: 'Inactive',
    value: StatusFilterEnum.DEACTIVATE,
  },
];

export const optionsStatusProspect: IBaseOption[] = [
  {
    label: 'All',
    value: StatusFilterEnum.ALL,
  },
  {
    label: 'Active',
    value: StatusFilterEnum.ACTIVATE,
  },
  {
    label: 'Inactive',
    value: StatusFilterEnum.DEACTIVATE,
  },
];

export const optionsStatusContact: IBaseOption[] = [
  {
    label: 'All',
    value: StatusFilterEnum.ALL,
  },
  {
    label: 'Active',
    value: StatusFilterEnum.ACTIVATE,
  },
  {
    label: 'Inactive',
    value: StatusFilterEnum.DEACTIVATE,
  },
];

// Stage
export const OPTIONS_TYPE_STAGE: IBaseOption[] = [
  {
    label: 'All',
    value: TypeStageFilterEnum.ALL,
  },
  {
    label: 'Prospect',
    value: TypeStageFilterEnum.PROSPECT,
  },
  {
    label: 'Applicant',
    value: TypeStageFilterEnum.APPLICANT,
  },
];

export const optionsStatusRequestApplicant = [
  {
    label: 'All',
    value: StatusFilterEnum.ALL,
  },
  {
    label: 'Active',
    value: StatusFilterEnum.ACTIVATE,
  },
  {
    label: 'Inactive',
    value: StatusFilterEnum.DEACTIVATE,
  },
];

export const optionsReferralType: IBaseOption[] = [
  {
    value: 'Adult Day Care',
    label: 'Adult Day Care',
  },
  {
    value: 'Advertising',
    label: 'Advertising',
  },
  {
    value: 'Affiliated Org. (Corp HQ, Peer Agency, etc)',
    label: 'Affiliated Org. (Corp HQ, Peer Agency, etc)',
  },
  {
    value: 'Alz Association or Support Group',
    label: 'Alz Association or Support Group',
  },
  {
    value: 'Area Office on Aging',
    label: 'Area Office on Aging',
  },
  {
    value: 'Assisted Living',
    label: 'Assisted Living',
  },
  {
    value: 'Branding-Sign/Building/Vehicle',
    label: 'Branding-Sign/Building/Vehicle',
  },
  {
    value: 'Caregivers',
    label: 'Caregivers',
  },
  {
    value: 'CCRC',
    label: 'CCRC',
  },
  {
    value: 'Client',
    label: 'Client',
  },
  {
    value: 'Community Health Program',
    label: 'Community Health Program',
  },
  {
    value: 'Community Long Term Care',
    label: 'Community Long Term Care',
  },
  {
    value: 'Community Senior Centers',
    label: 'Community Senior Centers',
  },
  {
    value: 'DDSN Case Management Services',
    label: 'DDSN Case Management Services',
  },
  {
    value: 'DDSN Day Activity Programs',
    label: 'DDSN Day Activity Programs',
  },
  {
    value: 'DDSN Residential Programs',
    label: 'DDSN Residential Programs',
  },
  {
    value: 'DDSN Workshop Program',
    label: 'DDSN Workshop Program',
  },
  {
    value: 'Dialysis Centers',
    label: 'Dialysis Centers',
  },
  {
    value: 'DME',
    label: 'DME',
  },
  {
    value: 'DSS',
    label: 'DSS',
  },
  {
    value: 'Educational',
    label: 'Educational',
  },
  {
    value: 'Elder Law Attny',
    label: 'Elder Law Attny',
  },
  {
    value: 'Elite Client',
    label: 'Elite Client',
  },
  {
    value: 'Elite Day Centers',
    label: 'Elite Day Centers',
  },
  {
    value: 'Elite Employee',
    label: 'Elite Employee',
  },
  {
    value: 'Elite Website Inquiry',
    label: 'Elite Website Inquiry',
  },
  {
    value: 'Fiduciary',
    label: 'Fiduciary',
  },
  {
    value: 'GCM',
    label: 'GCM',
  },
  {
    value: 'Home Care Agency',
    label: 'Home Care Agency',
  },
  {
    value: 'Home Care Internet Referral Banks',
    label: 'Home Care Internet Referral Banks',
  },
  {
    value: 'Home Healthcare',
    label: 'Home Healthcare',
  },
  {
    value: 'Hospice',
    label: 'Hospice',
  },
  {
    value: 'Hospital',
    label: 'Hospital',
  },
  {
    value: 'Hospital Specialty Floor (LTAC/Geropsych)',
    label: 'Hospital Specialty Floor (LTAC/Geropsych)',
  },
  {
    value: 'Independent Community',
    label: 'Independent Community',
  },
  {
    value: 'Insurance',
    label: 'Insurance',
  },
  {
    value: 'Internet',
    label: 'Internet',
  },
  {
    value: 'Low Income Housing',
    label: 'Low Income Housing',
  },
  {
    value: 'LTC Insurance Agency',
    label: 'LTC Insurance Agency',
  },
  {
    value: 'Medical/Surgical Center',
    label: 'Medical/Surgical Center',
  },
  {
    value: 'Pharmacy',
    label: 'Pharmacy',
  },
  {
    value: 'Physician',
    label: 'Physician',
  },
  {
    value: 'Place of Worship',
    label: 'Place of Worship',
  },
  {
    value: 'Rehab/Nursing Home',
    label: 'Rehab/Nursing Home',
  },
  {
    value: 'VA Hospitals',
    label: 'VA Hospitals',
  },
  {
    value: 'VA Outpatient Clinics',
    label: 'VA Outpatient Clinics',
  },
  {
    value: 'Veterans Affairs',
    label: 'Veterans Affairs',
  },
  {
    value: 'WOM',
    label: 'WOM',
  },
];

export const OPTION_STATUS_EMPLOYMENT = [
  { value: StatusEnum.IN_PROGRESS, label: 'In Progress' },
  { value: StatusEnum.COMPLETED, label: 'Completed' },
  { value: StatusEnum.NOT_COMPLETED, label: 'Not Completed' },
];

export const optionStatusRequestApplicant = [
  { value: StatusEnum.ACTIVE, label: 'Active' },
  { value: StatusEnum.INACTIVE, label: 'Inactive' },
];

export const optionsStatusInvoice: IBaseOption[] = [
  {
    label: 'All',
    value: StatusFilterEnum.ALL,
  },
  {
    label: 'Open',
    value: StatusFilterEnum.OPEN,
  },
  {
    label: 'Sent Invoice',
    value: StatusFilterEnum.SENT_INVOICE,
  },
  {
    label: 'Sent Payment',
    value: StatusFilterEnum.SENT_PAYMENT,
  },
  {
    label: 'Paid',
    value: StatusFilterEnum.PAID,
  },
  {
    label: 'Due Date',
    value: StatusFilterEnum.DUE_DATE,
  },
];

export const optionAllCaregiver: IBaseOption = {
  label: 'All',
  value: StatusFilterEnum.ALL,
};

// Skill Options
export const optionsGeneralSkill: IBaseOptionMultiple[] = [
  {
    label: 'Dementia Experience',
    value: GeneralSkillEnum.DEMENTIA_EXPERIENCE,
  },
  {
    label: 'Hospice Experience',
    value: GeneralSkillEnum.HOSPICE_EXPERIENCE,
  },
  {
    label: 'Incontinence Experience',
    value: GeneralSkillEnum.INCONTINENCE_EXPERIENCE,
  },
  {
    label: 'Insured Automobile',
    value: GeneralSkillEnum.INSURED_AUTOMOBILE,
  },
  {
    label: 'Smokes',
    value: GeneralSkillEnum.SMOKES,
  },
];

export const optionsTransfersSkill: IBaseOptionMultiple[] = [
  {
    label: 'Gait Belt Experience',
    value: TransfersSkillEnum.GAIT_BELT_EXPERIENCE,
  },
  {
    label: 'Hoyer Lift Experience',
    value: TransfersSkillEnum.HOYER_LIFT_EXPERIENCE,
  },
];

export const optionsPetsSkill: IBaseOptionMultiple[] = [
  {
    label: 'OK with Cats',
    value: PetsSkillEnum.OK_WITH_CATS,
  },
  {
    label: 'OK with Dogs',
    value: PetsSkillEnum.OK_WITH_DOGS,
  },
];

export const optionsOtherOrMiscSkill: IBaseOptionMultiple[] = [
  {
    label: 'Client Specific',
    value: OtherOrMiscSkillEnum.CLIENT_SPECIFIC,
  },
  {
    label: 'CLTC',
    value: OtherOrMiscSkillEnum.CLTC,
  },
  {
    label: 'EP',
    value: OtherOrMiscSkillEnum.EP,
  },
];

export const linkToQuickBookCustomerOptions: IBaseOption[] = [
  {
    label: 'No',
    value: LinkToQuickBookCustomerEnum.NO,
  },
  {
    label: 'Add New',
    value: LinkToQuickBookCustomerEnum.ADD_NEW,
  },
  {
    label: 'Add Existing',
    value: LinkToQuickBookCustomerEnum.ADD_EXISTING,
  },
];

export const optionReasonUnavailable: IBaseOption[] = [
  {
    value: 'UNAVAILABLE',
    label: 'Unavailable',
  },
  {
    value: 'VACATION_DAY',
    label: 'Vacation Day',
  },
  {
    value: 'SICK_DAY',
    label: 'Sick Day',
  },
  {
    value: 'TRAINING',
    label: 'Training',
  },
  {
    value: 'SCHOOL',
    label: 'School',
  },
  {
    value: 'PERSONAL',
    label: 'Personal',
  },
  {
    value: 'OTHER_JOB',
    label: 'Other Job',
  },
  {
    value: 'WORK_RELATED_INJURY',
    label: 'Work-Related Injury',
  },
  {
    value: 'FAMILY_MEDICAL_LEAVE',
    label: 'Family Medical Leave',
  },
  {
    value: 'DISABILITY',
    label: 'Disability',
  },
  {
    value: 'OTHER',
    label: 'Other',
  },
];

export const OPTION_ROUNDING_METHOD: IBaseOption[] = [
  {
    label: 'Agency Setting (Official (Rounded) Time)',
    value: PayHourRateEnum.AGENCY_SETTING,
  },
  {
    label: 'Official (Rounded) Time',
    value: PayHourRateEnum.OFFICIAL_TIME,
  },
  {
    label: 'Actual Time',
    value: PayHourRateEnum.ACTUAL_TIME,
  },
  {
    label: 'Scheduled Time',
    value: PayHourRateEnum.SCHEDULED_TIME,
  },
];

export const optionStatusReferral = [
  { value: StatusEnum.ACTIVE, label: 'Active' },
  { value: StatusEnum.INACTIVE, label: 'Inactive' },
];

export const OPTION_INVOICE_TERM: IBaseOption[] = [
  {
    label: 'Agency Default',
    value: InvoiceTermEnum.AGENCY_DEFAULT,
  },
  {
    label: 'Due upon receipt',
    value: InvoiceTermEnum.DUE_UPON_RECEIPT,
  },
  {
    label: 'Net 7',
    value: InvoiceTermEnum.NET_7,
  },
  {
    label: 'Net 10',
    value: InvoiceTermEnum.NET_7,
  },
  {
    label: 'Net 15',
    value: InvoiceTermEnum.NET_15,
  },
  {
    label: 'Net 30',
    value: InvoiceTermEnum.NET_30,
  },
  {
    label: 'Net 45',
    value: InvoiceTermEnum.NET_45,
  },
  {
    label: 'Net 60',
    value: InvoiceTermEnum.NET_60,
  },
];

export const OPTION_REMITTANCE_ADDRESS: IBaseOption[] = [
  {
    label: 'Agency Default',
    value: InvoiceRemittanceAddressEnum.AGENCY_DEFAULT,
  },
  {
    label: 'Elite Home Care LLC',
    value: InvoiceRemittanceAddressEnum.ELITE_HOME_CARE_LLC,
  },
  {
    label: 'Elite Day Center of Greenville',
    value: InvoiceRemittanceAddressEnum.ELITE_DAY_CENTER_OF_GREENVILLE,
  },
];

export const OPTION_OVERTIME_METHOD: IBaseOption[] = [
  {
    label: 'Use Agency default',
    value: OvertimeMethodRateEnum.AGENCY_DEFAULT,
  },
  {
    label: 'Straight Time',
    value: OvertimeMethodRateEnum.STRAIGHT_TIME,
  },
  {
    label: 'Weighted Average',
    value: OvertimeMethodRateEnum.WEIGHTED_AVERAGE,
  },
  {
    label: 'Blended Average',
    value: OvertimeMethodRateEnum.BLENDED_AVERAGE,
  },
];

export const optionFilterTypeCareAlert = [
  {
    label: 'All Alerts',
    value: TypeCareAlertEnum.ALL_ALERTS,
  },
  {
    label: 'Missed Clock-In',
    value: TypeCareAlertEnum.MISSED_CLOCK_INS,
  },
  {
    label: 'Late Clock-in',
    value: TypeCareAlertEnum.LATE_CLOCK_INS,
  },
  {
    label: 'Update Shift',
    value: TypeCareAlertEnum.UPDATE_SHIFT,
  },
  {
    label: 'Note Shift',
    value: TypeCareAlertEnum.NOTE_SHIFT,
  },
  {
    label: 'Birthday',
    value: TypeCareAlertEnum.BIRTHDAY,
  },
  {
    label: 'No Shift',
    value: TypeCareAlertEnum.CLIENT_NO_SHIFT,
  },
];

export const optionTimeCareAlert = [
  {
    label: 'Last 2 Hours',
    value: TimelineAlertEnum.LAST_TWO_HOURS,
  },
  {
    label: 'Last 24 Hours',
    value: TimelineAlertEnum.LAST_TWELVE_FOURS_HOURS,
  },
];

export const optionTimeHomeCareAlert = [
  {
    label: 'Last 2 Hours',
    value: TimelineAlertEnum.LAST_TWO_HOURS,
  },
];

export const linkToCaregiverListOptions: IBaseOption[] = [
  {
    label: 'No',
    value: LinkToCaregiverListEnum.NO,
  },
  {
    label: 'Add Existing',
    value: LinkToCaregiverListEnum.ADD_EXISTING,
  },
];

export const OPTIONS_STATUS_INVOICE = [
  { value: StatusEnum.SENT_PAYMENT, label: 'Sent Payment' },
  { value: StatusEnum.PAID, label: 'Paid' },
];
