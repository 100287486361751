// Libs
import { Image, StyleSheet, View } from '@react-pdf/renderer';
// Components, Layouts, Pages
// Others
// Styles, images, icons
import { images } from '~/assets';

type Props = {};

const InvoiceLogoPdf = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <View>
      <Image style={styles.logo} src={images.signInImageLogoFull} />
    </View>
  );
};

export default InvoiceLogoPdf;

export const styles = StyleSheet.create({
  logo: { width: 150, height: 75 },
});
